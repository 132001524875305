import React, {
  FunctionComponent,
  useState,
  useContext,
  useEffect,
} from "react";
import { Snackbar } from "@material-ui/core";
import moment from "moment";
import { AnalyticsContext } from "~/analytics";
import Controller from "./Controller";
import SlideTransitionComponent from "~/components/SlideTransitionComponent/SlideTransitionComponent";

type FeedbackProps = {
  stageId: string;
  lastEventEndTime?: string;
  isDemo?: boolean;
  isSample?: boolean;
  previouslySubmittedFeedback?: boolean;
  id: string;
};

const Feedback: FunctionComponent<FeedbackProps> = ({
  lastEventEndTime,
  stageId,
  previouslySubmittedFeedback,
  isDemo = false,
  isSample = false,
}) => {
  const hasLastInterviewEnded =
    lastEventEndTime && moment(lastEventEndTime).isBefore(moment(new Date()));
  const initOpen = false;
  const [isOpen, setOpen] = useState<boolean>(initOpen);
  useEffect(() => {
    setOpen(
      (open) =>
        open || (!!hasLastInterviewEnded && !previouslySubmittedFeedback)
    );
  }, [hasLastInterviewEnded, previouslySubmittedFeedback]);

  const analytics = useContext(AnalyticsContext);
  const dismissFeedback = (): void => {
    analytics.track("Feedback Dismissed");
    setOpen(false);
  };
  if (isSample) {
    return (
      <Controller
        isSample={isSample}
        dismissFeedback={(): void => {}}
        isDemo
        stageId={stageId}
      />
    );
  }
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={isOpen}
      TransitionComponent={SlideTransitionComponent}
    >
      <div>
        <Controller
          dismissFeedback={dismissFeedback}
          stageId={stageId}
          isDemo={isDemo}
          isSample={isSample}
        />
      </div>
    </Snackbar>
  );
};

export default Feedback;
