import React, { useState } from "react";
import _ from "lodash";

import { Grid, Link, Typography, makeStyles } from "@material-ui/core";
import DOMPurify from "dompurify";
import { VisibilityOff } from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/pro-light-svg-icons";
import { ResourceCustomTheme } from "~/styles/config";

import { GuideTemplateForEditing_guideTemplate_stageTemplateInstallations_eventTemplates as EventTemplate } from "~/schemaTypes";

import InterviewTemplateModal from "./InterviewTemplateModal";
import useAnalytics from "~/react-hooks/useAnalytics";

const useInterviewStyles = makeStyles<ResourceCustomTheme, { hidden: boolean }>(
  (theme) => ({
    root: {
      marginTop: "0.6rem",
      "&:hover $editButton": {
        visibility: "visible",
      },
    },
    description: {
      color: theme.colors.KarlGray(800),
      maxHeight: "2rem",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      "& span[data-id]": theme.mixins.placeholderStyles(theme),
    },
    title: {
      whiteSpace: "break-spaces",
      wordBreak: "break-all",
      marginLeft: "0.4rem",
      "& span[data-id]": theme.mixins.placeholderStyles(theme),
    },
    atsInterviewName: {
      marginLeft: "0.4rem",
      color: theme.colors.KarlGray(800),
    },
    visibilityIcon: {
      marginBottom: -2,
      marginRight: 0,
      height: 16,
      width: 16,
    },
    editButton: {
      [theme.breakpoints.up("md")]: {
        position: "absolute",
        right: 0,
        width: "3rem",
        backgroundColor: "white",
        visibility: "collapse",
      },
    },
  })
);

type InterviewRowProps = {
  interview: EventTemplate;
  onEdit(): void;
};

const InterviewRow: React.FC<InterviewRowProps> = ({ interview, onEdit }) => {
  const [analytics] = useAnalytics();
  const styles = useInterviewStyles({ hidden: interview.hidden });
  const sanitizedTitle = DOMPurify.sanitize(interview.title, {
    ALLOWED_TAGS: ["span"],
  });
  const sanitizedDescription = DOMPurify.sanitize(interview.description, {
    FORBID_TAGS: ["br"],
  });
  return (
    <Grid container className={styles.root} spacing={1}>
      <Grid item xs={12} md>
        <Typography className={styles.titleContainer}>
          {interview.hidden ? (
            <VisibilityOff fontSize="small" className={styles.visibilityIcon} />
          ) : (
            <FontAwesomeIcon icon={faCalendarAlt} size="lg" />
          )}
          <strong
            className={styles.title}
            dangerouslySetInnerHTML={{
              __html: sanitizedTitle,
            }}
          />
          <strong>{interview.hidden ? " (hidden)" : ""}</strong>
          {interview.atsEventTemplateName !== interview.title && (
            <span className={styles.atsInterviewName}>
              ({interview.atsEventTemplateName})
            </span>
          )}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md
        className={styles.description}
        dangerouslySetInnerHTML={{
          __html: sanitizedDescription,
        }}
      />
      <Grid item xs={12} md={2} className={styles.editButton}>
        <Link
          component="button"
          underline="always"
          onClick={() => {
            analytics.track("Edit Interview Kit Clicked");
            onEdit();
          }}
        >
          Edit
        </Link>
      </Grid>
    </Grid>
  );
};

type InterviewKitProps = {
  interviews: EventTemplate[];
};

const useStyles = makeStyles(() => ({
  root: {
    marginTop: "1rem",
  },
  edit: {
    textAlign: "end",
  },
}));

const InterviewKit: React.FC<InterviewKitProps> = ({ interviews }) => {
  const [open, setOpen] = useState(false);
  const [selectedInterview, setSelectedInterview] = useState<EventTemplate>();
  const styles = useStyles();
  const sortedInterviews = _.orderBy(interviews, "position");

  return (
    <div className={styles.root}>
      <Grid container justify="space-between">
        <Grid item xs>
          <Typography>
            <strong>Interview Descriptions</strong>
          </Typography>
        </Grid>
      </Grid>
      {_.map(sortedInterviews, (interview) => (
        <InterviewRow
          key={interview.id}
          interview={interview}
          onEdit={() => {
            setSelectedInterview(interview);
            setOpen(true);
          }}
        />
      ))}
      {selectedInterview && (
        <InterviewTemplateModal
          open={open}
          onClose={() => {
            setSelectedInterview(undefined);
            setOpen(false);
          }}
          interview={selectedInterview}
        />
      )}
    </div>
  );
};

export default InterviewKit;
