import { ModuleEnum, ModuleDisplayTypeEnum } from "@resource/common";

type ModuleDisplayInfoType = {
  [key in ModuleEnum]: {
    emoji: string;
    header: string;
    description: string;
    displayType: string;
  };
};

const ModuleDisplayInfo: ModuleDisplayInfoType = Object.freeze({
  // Deprecated
  [ModuleEnum.Expectations]: {
    emoji: "",
    header: "Expectations",
    description: "",
    displayType: ModuleDisplayTypeEnum.InfoCards,
  },
  // Deprecated
  [ModuleEnum.PhoneScreenHeader]: {
    emoji: "",
    header: "Greeting",
    description: "",
    displayType: ModuleDisplayTypeEnum.InfoCards,
  },
  [ModuleEnum.GreetingHeader]: {
    emoji: "👋",
    header: "Greeting",
    description: "",
    displayType: ModuleDisplayTypeEnum.GreetingHeader,
  },
  [ModuleEnum.Time]: {
    emoji: "⏰",
    header: "Time + Calendar Link",
    description: "",
    displayType: ModuleDisplayTypeEnum.Time,
  },
  [ModuleEnum.Location]: {
    emoji: "📍",
    header: "Interview Location",
    description: "",
    displayType: ModuleDisplayTypeEnum.Location,
  },
  [ModuleEnum.Mission]: {
    emoji: "🙏",
    header: "Our Mission",
    description: "",
    displayType: ModuleDisplayTypeEnum.Mission,
  },
  [ModuleEnum.CandidatePostInterviewFeedback]: {
    emoji: "🗣",
    header: "Interview Feedback",
    description:
      "Candidates provide simple feedback about their last interivew",
    displayType: ModuleDisplayTypeEnum.CandidatePostInterviewFeedback,
  },
  [ModuleEnum.Links]: {
    emoji: "📎",
    header: "Additional Info",
    description: "Link to helpful articles and company pages",
    displayType: ModuleDisplayTypeEnum.Links,
  },
  [ModuleEnum.Values]: {
    emoji: "💚",
    header: "Our Values",
    description: "",
    displayType: ModuleDisplayTypeEnum.InfoCards,
  },
  [ModuleEnum.TeamPhoto]: {
    emoji: "📷",
    header: "Team Photo",
    description: "Show candidates your culture!",
    displayType: ModuleDisplayTypeEnum.TeamPhoto,
  },
  [ModuleEnum.Media]: {
    emoji: "📹",
    header: "Culture Video",
    description: "Drop in a YouTube link to show candidates your culture",
    displayType: ModuleDisplayTypeEnum.Media,
  },
  [ModuleEnum.Logistics]: {
    emoji: "🚗",
    header: "Logistics",
    description: "Help candidates know what to expect before they arrive",
    displayType: ModuleDisplayTypeEnum.InfoCards,
  },
  [ModuleEnum.Chat]: {
    emoji: "💬",
    header: "In-Guide Chat",
    description:
      "A super simple way for candidates to ask questions via their Guide",
    displayType: ModuleDisplayTypeEnum.Chat,
  },
  [ModuleEnum.InterviewScheduler]: {
    emoji: "📅",
    header: "In-Guide Interview Scheduler",
    description:
      "Let candidates choose from available times - you'll be notified an their guide will update automatically!",
    displayType: ModuleDisplayTypeEnum.InterviewScheduler,
  },
  [ModuleEnum.Schedule]: {
    emoji: "📅",
    header: "Interview Schedule",
    description:
      "Overview the interviews and people of your interview schedule.",
    displayType: ModuleDisplayTypeEnum.Schedule,
  },
  [ModuleEnum.InterviewTeam]: {
    emoji: "👨‍👩‍🧒👱‍♀️",
    header: "Interview Team",
    description: "Showcase your people!",
    displayType: ModuleDisplayTypeEnum.InterviewTeam,
  },
  [ModuleEnum.RichText]: {
    emoji: "✍️",
    header: "Custom Text",
    description:
      "Get creative! Use this module to add any extra information you want to share with your candidate.",
    displayType: ModuleDisplayTypeEnum.RichText,
  },
});

export default ModuleDisplayInfo;
