import _ from "lodash";
import React from "react";
import {
  DialogContent,
  Button,
  DialogActions,
  makeStyles,
  Grid,
} from "@material-ui/core";
import { strings } from "@resource/common";
import { useMutation, useReactiveVar } from "@apollo/client";
import {
  BulkEditStageTemplateInstallations,
  BulkEditStageTemplateInstallationsVariables,
  GetGuideTemplatesForBulkEdit_guide_template as GuideTemplate,
} from "~/schemaTypesHasura";
import { useFlashMessage } from "~/components/FlashMessage/FlashMessage";
import HASURA_BULK_EDIT_STAGE_TEMPLATE_INSTALLATIONS from "./HASURA_BULK_EDIT_STAGE_TEMPLATE_INSTALLATIONS@hasura";
import { currentOrganizationId } from "~/cache";
import {
  StageEditsDispatchType,
  ActionEnum,
  StageEditsStateType,
  BulkStageEditMode,
} from "./types";
import ConfirmationButton from "~/components/ConfirmationButton/ConfirmationButton";
import { ResourceCustomTheme } from "~/styles/config";
import ConfirmStageEdits from "./ConfirmStageEdits";

const useStyles = makeStyles((theme: ResourceCustomTheme) => ({
  root: {},
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  chip: {
    backgroundColor: theme.colors.RoyalPurple(100),
    color: theme.colors.RoyalPurple(),
    marginBottom: theme.spacing(2),
  },
}));

type ConfirmEditsProps = {
  mode: BulkStageEditMode;
  state: StageEditsStateType;
  dispatch: React.Dispatch<StageEditsDispatchType>;
  guideTemplates: GuideTemplate[];
};

const ConfirmEdits: React.FC<ConfirmEditsProps> = ({
  mode,
  state,
  dispatch,
  guideTemplates,
}) => {
  const classes = useStyles();

  const currentOrgId = useReactiveVar(currentOrganizationId);

  const { setContent } = useFlashMessage();

  const [bulkEditStageTemplateInstallations, { loading: saving }] = useMutation<
    BulkEditStageTemplateInstallations,
    BulkEditStageTemplateInstallationsVariables
  >(HASURA_BULK_EDIT_STAGE_TEMPLATE_INSTALLATIONS);

  const onSubmit = async () => {
    if (!state.stageIds.length) {
      console.error("Submitting without selected stages shouldn't be possible");
      return;
    }

    // eslint-disable-next-line no-underscore-dangle
    let _set = {};
    if (mode === BulkStageEditMode.Add) {
      _set = {
        ..._set,
        activatedAt: new Date(),
      };
    } else if (mode === BulkStageEditMode.Remove) {
      _set = {
        ..._set,
        activatedAt: null,
      };
    }
    if (state.stageTemplate) {
      _set = {
        ..._set,
        stageTemplateId: state.stageTemplate.id,
      };
    }
    if (state.emailAction) {
      _set = {
        ..._set,
        journeyMetadata: {
          afterSteps: [
            {
              type: "ACTION",
              data: {
                type: "EMAILTOCANDIDATE",
                cc: state.emailAction.cc,
                bcc: state.emailAction.bcc,
                emailTemplateId: state.emailAction.id,
              },
            },
          ],
        },
      };
    }
    if (state.stageNameOverride) {
      _set = {
        ..._set,
        displayName: state.stageNameOverride.displayName,
      };
    }

    const res = await bulkEditStageTemplateInstallations({
      variables: {
        where: {
          ...(currentOrgId
            ? {
                stage_template: {
                  organizationId: {
                    _eq: currentOrgId,
                  },
                },
              }
            : {}),
          guideTemplateId: {
            _in: _.map(guideTemplates, "id"),
          },
          atsStageId: {
            _in: state.stageIds,
          },
        },
        _set,
      },
    });

    const numUpdated =
      res.data?.update_stage_template_installation?.returning.length || 0;

    if (numUpdated > 0) {
      let confirmationText = "";
      switch (mode) {
        case BulkStageEditMode.Add:
          confirmationText = "added";
          break;
        case BulkStageEditMode.Edit:
          confirmationText = "applied your changes to";
          break;
        case BulkStageEditMode.Remove:
          confirmationText = "removed";
          break;
        default:
          break;
      }

      setContent({
        content: `🎉 Successfully ${confirmationText} ${strings.pluralize(
          "stage",
          numUpdated
        )}`,
        autoHideDuration: 5000,
        severity: "success",
      });
    } else {
      setContent({
        content:
          "It looks like we couldn't apply your changes. Check your filters and try again.",
        autoHideDuration: 5000,
        severity: "error",
      });
    }

    dispatch({
      type: ActionEnum.Finish,
    });
  };

  return (
    <>
      <DialogContent dividers>
        <ConfirmStageEdits mode={mode} data={state} />
      </DialogContent>

      <DialogActions>
        <Grid container>
          <Grid item xs={12} className={classes.actions}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                dispatch({ type: ActionEnum.Back });
              }}
              style={{ marginLeft: 10 }}
            >
              Back
            </Button>
            <ConfirmationButton
              disableTimer
              color="primary"
              disabled={saving}
              variant="contained"
              onClick={onSubmit}
              style={{ marginLeft: 10 }}
            >
              Confirm
            </ConfirmationButton>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};

export default ConfirmEdits;
