import React from "react";
import _ from "lodash";
import { Dialog, DialogProps } from "@material-ui/core";

import DialogTitle from "./DialogTitle/DialogTitle";

type DialogWithCloseProps = DialogProps & {
  closeButton?: boolean;
  onClose?: () => void;
  title?: string;
};

const DialogWithClose: React.FC<DialogWithCloseProps> = (props) => {
  const { closeButton = true, children, onClose = () => {}, title } = props;
  const dialogProps = _.omit(props, ["closeButton", "children", "title"]);
  return (
    <Dialog {...dialogProps}>
      {closeButton && <DialogTitle onClose={onClose}>{title}</DialogTitle>}
      {children}
    </Dialog>
  );
};

export default DialogWithClose;
