import cx from "classnames";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";
import { Button, Fab, Typography, Fade } from "@material-ui/core";
import { FeatureFlagEnum } from "@resource/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { useFlags } from "@resource/client-ffs";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

import {
  ArchiveStageTemplate,
  ArchiveStageTemplateVariables,
  CreateStageTemplate,
  CreateStageTemplateVariables,
  GetOrgStageTemplates,
  UnarchiveStageTemplate,
  UnarchiveStageTemplateVariables,
} from "~/schemaTypes";
import Loading from "~/components/Loading/Loading";
import useAnalytics from "~/react-hooks/useAnalytics";
import LoadingModal from "~/components/LoadingModal/LoadingModal";
import { useFlashMessage } from "~/components/FlashMessage/FlashMessage";

import { TemplateLabelEnum } from "../TemplatesEnum";
import TemplateCard from "../TemplateCard/TemplateCard";
import styles from "../Templates.module.scss";
import GET_ORG_STAGE_TEMPLATES from "./GET_ORG_STAGE_TEMPLATES";
import NewStageTemplateModal from "./NewStageTemplateModal";

type StageTemplatesProps = Record<string, unknown>;

const Templates: React.FC<StageTemplatesProps> = () => {
  const location = useLocation();
  const { new: createNew } = queryString.parse(location.search);
  const triggerNewModal = createNew === "true";
  const history = useHistory();
  if (triggerNewModal) {
    history.replace(location.pathname);
  }

  const {
    [_.camelCase(
      FeatureFlagEnum.GuideTemplatesEditor
    )]: guideTemplatesEditorFlag,
  } = useFlags();
  const [analytics] = useAnalytics();
  const [showLoadingModal, setShowLoadingModal] = useState<boolean>(false);
  const [showNewTemplateModal, setShowNewTemplateModal] = useState<boolean>(
    triggerNewModal
  );
  const { setContent } = useFlashMessage();
  const {
    [_.camelCase(
      FeatureFlagEnum.DuplicateStageTemplate
    )]: duplicateStageTemplateFlag,
  } = useFlags();
  const {
    data: organizationData,
    loading: orgDataLoading,
    error: orgDataError,
  } = useQuery<GetOrgStageTemplates>(GET_ORG_STAGE_TEMPLATES);
  const [addStageTemplateMutate] = useMutation<
    CreateStageTemplate,
    CreateStageTemplateVariables
  >(
    gql`
      mutation CreateStageTemplate($input: CreateStageTemplateInput!) {
        createStageTemplate(input: $input) {
          stageTemplate {
            id
            name
            modules {
              id
              type
              data
            }
            updatedAt
            createdBy
          }
        }
      }
    `,
    {
      refetchQueries: ["GetOrgStageTemplates"],
    }
  );

  const [archiveTemplateMutate] = useMutation<
    ArchiveStageTemplate,
    ArchiveStageTemplateVariables
  >(
    gql`
      mutation ArchiveStageTemplate($input: ArchiveStageTemplateInput!) {
        archiveStageTemplate(input: $input) {
          success
        }
      }
    `,
    {
      refetchQueries: ["GetOrgStageTemplates"],
    }
  );

  const [unarchiveTemplateMutate] = useMutation<
    UnarchiveStageTemplate,
    UnarchiveStageTemplateVariables
  >(
    gql`
      mutation UnarchiveStageTemplate($input: UnarchiveStageTemplateInput!) {
        unarchiveStageTemplate(input: $input) {
          success
          stageTemplate {
            id
            name
            modules {
              id
              type
              data
            }
            updatedAt
            createdBy
          }
        }
      }
    `,
    {
      refetchQueries: ["GetOrgStageTemplates"],
    }
  );

  const addNewTemplate = async ({
    copyFromStageTemplateId,
    name,
  }: {
    copyFromStageTemplateId?: string;
    name?: string;
  }): Promise<void> => {
    let response;
    setShowLoadingModal(true);
    try {
      response = await addStageTemplateMutate({
        variables: {
          input: {
            copyFromStageTemplateId,
            name,
          },
        },
      });
      if (analytics) {
        analytics.track("Add Stage Template Clicked");
      }
    } finally {
      setShowLoadingModal(false);
      setShowNewTemplateModal(false);
    }
    const stageTemplateId =
      response?.data?.createStageTemplate?.stageTemplate?.id;
    if (guideTemplatesEditorFlag) {
      history.push(`/templates/stages/${stageTemplateId}`);
    } else {
      history.push(`/templates/${stageTemplateId}`);
    }
  };

  const unarchiveStageTemplate = async (id: string): Promise<void> => {
    try {
      await unarchiveTemplateMutate({
        variables: {
          input: {
            id,
          },
        },
      });
      if (analytics) {
        analytics.track("Stage Template Unarchived");
      }
      setContent({
        content: "Guide unarchived",
        severity: "success",
      });
    } catch (err) {
      setContent({
        content: "Could not unarchive guide",
        severity: "error",
      });
    }
  };

  const archiveStageTemplate = async (id: string): Promise<void> => {
    await archiveTemplateMutate({
      variables: {
        input: {
          id,
        },
      },
    });
    if (analytics) {
      analytics.track("Stage Template Archived");
    }
    const action = (
      <Button
        color="inherit"
        size="small"
        onClick={() => unarchiveStageTemplate(id)}
      >
        UNDO
      </Button>
    );
    setContent({
      action,
      content: "Guide Archived",
      severity: "success",
    });
  };

  useEffect(() => {
    analytics.page("Stage Templates");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (orgDataLoading) {
    return <Loading />;
  }

  return (
    <>
      <Fade in timeout={1000}>
        <div className={cx(styles.cont)}>
          <LoadingModal open={showLoadingModal} />
          <div className={styles.headerCont}>
            <Typography className={styles.headerText} variant="h2">
              {guideTemplatesEditorFlag ? "" : "Guide Templates"}
            </Typography>
            <Fab
              color="primary"
              aria-label="add"
              size="medium"
              onClick={() => {
                if (duplicateStageTemplateFlag) {
                  setShowNewTemplateModal(true);
                } else {
                  addNewTemplate({});
                }
              }}
            >
              <FontAwesomeIcon icon={faPlus} size="lg" />
            </Fab>
          </div>
          <div className={cx(styles.templatesContainer)}>
            {orgDataError ? (
              <Typography variant="h4">
                There was an unexpected error loading your guides, please
                refresh the page or contact support if the problem persists.
              </Typography>
            ) : (
              <>
                {organizationData?.currentUserV2?.organization?.stageTemplates.map(
                  ({ id, name, updatedAt, createdBy }) => (
                    <TemplateCard
                      key={id}
                      id={id}
                      name={name}
                      onArchive={() => archiveStageTemplate(id)}
                      archiveTooltip="Deleting this guide will make it impossible to edit any versions of this guide sent to candidates"
                      updatedAt={updatedAt}
                      createdBy={createdBy}
                      onClick={() => {
                        if (guideTemplatesEditorFlag) {
                          history.push(`/templates/stages/${id}`);
                        } else {
                          history.push(`/templates/${id}`);
                        }
                      }}
                      templateLabel={TemplateLabelEnum.Stage}
                    />
                  )
                )}
              </>
            )}
          </div>
        </div>
      </Fade>
      <NewStageTemplateModal
        open={showNewTemplateModal}
        onClose={() => setShowNewTemplateModal(false)}
        onSubmit={addNewTemplate}
      />
    </>
  );
};

export default Templates;
