import React, { useCallback, useState } from "react";
import cx from "classnames";
import { Typography, Card, Button } from "@material-ui/core";
import { faCheckCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  IntegrationEnum,
  SlackIntegrationForCustomer_currentUserV2_organization_customer_integration_SlackIntegrationV2 as SlackIntegration,
} from "~/schemaTypes";
import slackIconLg from "~/icons/slack-icon-lg.svg";
import styles from "./SlackInterviewersHelper.module.scss";
import { Auth0Provider, useAuth0 } from "~/react-auth0";
import {
  useSlackIntegration,
  useUpdateSlack,
} from "~/apollo-hooks/use-slack-integration";
import { useFlashMessage } from "../FlashMessage/FlashMessage";
import Loading from "../Loading/Loading";
import useAnalytics from "~/react-hooks/useAnalytics";

type SlackInterviewersHelperProps = {
  onConnected?(): void;
};

const SlackInterviewersHelper: React.FC<SlackInterviewersHelperProps> = ({
  onConnected,
}) => {
  const { data } = useSlackIntegration();
  const { updateSlack } = useUpdateSlack();
  const { loginWithPopup, loading: auth0Loading } = useAuth0();
  const { setContent } = useFlashMessage();
  const [loading, setLoading] = useState<boolean>(false);
  const [analytics] = useAnalytics();
  const integration = data?.currentUserV2?.organization.customer.integration as
    | SlackIntegration
    | null
    | undefined;

  const handleConnect = useCallback(async () => {
    setLoading(true);
    try {
      const user = await loginWithPopup({
        connection: "slack",
      });
      if (user) {
        analytics.track("Slack Connected");
        await updateSlack({
          variables: {
            input: {
              auth0SlackUserId: user.sub,
            },
            typeInput: {
              type: IntegrationEnum.SLACK,
            },
          },
          refetchQueries: ["AllInterviewersWithDetails"],
        });
        setContent({
          content:
            "Slack was successfully connected! You can add Interviewers now ✨.",
          severity: "success",
        });
        if (onConnected) {
          onConnected();
        }
      }
    } finally {
      setLoading(false);
    }
  }, [setContent, loginWithPopup, updateSlack, analytics, onConnected]);

  const unifiedLoading = loading || auth0Loading;

  return (
    <Card
      className={cx(styles.integrationsCard, {
        [styles.connected]: integration?.connected,
      })}
      elevation={1}
    >
      <>
        <div className={styles.brand}>
          <img alt="Slack logo" className={styles.icon} src={slackIconLg} />
          <Typography className={styles.description} variant="body1">
            The Guide Slack integration uses basic Interviewer profiles to
            automatically populate Interviewer{" "}
            <span role="img" aria-label="wave">
              👋
            </span>{" "}
            names,{" "}
            <span role="img" aria-label="camera">
              📸
            </span>{" "}
            photos, and{" "}
            <span role="img" aria-label="human">
              👨‍💼
            </span>{" "}
            titles.
          </Typography>
        </div>
        {integration?.connected && (
          <Button className={styles.button} variant="outlined" disabled>
            <FontAwesomeIcon
              className={styles.buttonIcon}
              icon={faCheckCircle}
            />{" "}
            Installed
          </Button>
        )}
        {!integration?.connected && (
          <Button
            type="button"
            className={styles.addToSlack}
            onClick={(e) => {
              analytics.track("Connect To Slack Clicked");
              e.preventDefault();
              handleConnect();
            }}
          >
            <img
              alt="Add to Slack"
              height="40"
              width="139"
              src="https://platform.slack-edge.com/img/add_to_slack.png"
              srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
            />
          </Button>
        )}
        {!integration?.connected && unifiedLoading && <Loading inlineStyling />}
        <Typography variant="subtitle2" className={styles.subtext}>
          (no admin permissions needed)
        </Typography>
      </>
    </Card>
  );
};

const Wrapped: React.FC<SlackInterviewersHelperProps> = (props) => (
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_TENANT_DOMAIN!}
    timeout="1000"
    client_id={process.env.REACT_APP_AUTH0_GUIDES_CLIENT_ID!}
    connection="slack"
  >
    <SlackInterviewersHelper {...props} />
  </Auth0Provider>
);

export default Wrapped;
