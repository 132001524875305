import React from "react";
import { Typography, Fade } from "@material-ui/core";
import Interviewers from "./Interviewers";

const InterviewersInSideBar: React.FC = () => {
  return (
    <Fade in timeout={1000}>
      <div>
        <Typography variant="h2">Team</Typography>
        <Interviewers />
      </div>
    </Fade>
  );
};

export default InterviewersInSideBar;
