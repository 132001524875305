import React, { FunctionComponent } from "react";
import moment from "moment";
import { Typography, Avatar, IconButton } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import styles from "./ScheduleExpandedEvent.module.scss";

type ScheduleEventInterviewer = {
  firstName: string;
  lastName: string;
  title: string;
  imageUrl: string;
  linkedIn: string;
};

export type ScheduleExpandedEventProps = {
  id: string;
  title: string;
  startTime: Date;
  endTime: Date;
  description: Date;
  hidden: boolean;
  interviewers: ScheduleEventInterviewer[];
};

// TODO: Andrew or Kevin, handle the no interviewer case
const ScheduleExpandedEvent: FunctionComponent<ScheduleExpandedEventProps> = ({
  title,
  startTime,
  description,
  interviewers,
  hidden,
}) => {
  const [interviewer] = interviewers;
  return !hidden ? (
    <div className={styles.scheduleExpandedEventContainer}>
      <div className={styles.text}>
        <Typography className={styles.time} variant="body1">
          {moment(startTime).format("h:mm a")}
        </Typography>
        <Typography className={styles.title} variant="h6">
          {title}
        </Typography>
        <Typography className={styles.description} variant="body1">
          {description}
        </Typography>
      </div>
      {!!interviewer && (
        <div className={styles.avatarCont}>
          <Avatar
            style={{
              color: "#1f1440",
              backgroundColor: "#efeef1",
              fontSize: "2rem",
            }}
            className={styles.avatar}
            src={interviewer.imageUrl}
          >
            {interviewer.firstName[0]}
            {interviewer.lastName[0]}
          </Avatar>
          <div className={styles.avatarText}>
            <div className={styles.nameCont}>
              <Typography className={styles.header} variant="h6">
                {interviewer.firstName} {interviewer.lastName}
              </Typography>
              {interviewer.linkedIn && (
                <IconButton
                  className={styles.iconButton}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={interviewer.linkedIn}
                >
                  <FontAwesomeIcon icon={faLinkedin} />
                </IconButton>
              )}
            </div>
            <Typography variant="body1">{interviewer.title}</Typography>
          </div>
          {interviewer.linkedIn && (
            <IconButton
              className={styles.iconButton}
              target="_blank"
              rel="noopener noreferrer"
              href={interviewer.linkedIn}
            >
              <FontAwesomeIcon icon={faLinkedin} />
            </IconButton>
          )}
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};
export default ScheduleExpandedEvent;
