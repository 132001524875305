import React, { useContext, useState } from "react";

type CurrentlyViewingGuideContextProps = {
  currentlyViewingGuideId?: string;
  setCurrentlyViewingGuideId(id: string): void;
};

export const CurrentlyViewingGuideContext = React.createContext<
  CurrentlyViewingGuideContextProps
>({
  currentlyViewingGuideId: "",
  setCurrentlyViewingGuideId(): void {},
});

export const useCurrentlyViewingGuide = () =>
  useContext<CurrentlyViewingGuideContextProps>(CurrentlyViewingGuideContext);

export const CurrentlyViewingGuideProvider: React.FC = ({ children }) => {
  const [currentlyViewingGuideId, setCurrentlyViewingGuideId] = useState<
    string
  >("");

  return (
    <CurrentlyViewingGuideContext.Provider
      value={{
        currentlyViewingGuideId,
        setCurrentlyViewingGuideId,
      }}
    >
      {children}
    </CurrentlyViewingGuideContext.Provider>
  );
};
