import gql from "graphql-tag";

export default gql`
  mutation UpdateValuesForStageTemplateModuleInstallation(
    $input: UpdateValuesInstallationForStageTemplateInput!
  ) {
    updateValuesInstallationForStageTemplate(input: $input) {
      success
      stageTemplate {
        id
        name
        updatedAt
      }
    }
  }
`;
