import _ from "lodash";
import React, { useState } from "react";
import cx from "classnames";
import { Typography, Switch, Button } from "@material-ui/core";
import styles from "./logistics-form-row.module.scss";

import { LogisticGroup } from "./EditLogistics";

type LogisticsFormRowProps = {
  logisticGroup: LogisticGroup;
  selectedLogisticId?: string | null;
  setSelectedLogisticId: (newSelectedLogisticId: string | null) => void;
};

const LogisticsFormRow: React.FC<LogisticsFormRowProps> = ({
  logisticGroup,
  selectedLogisticId,
  setSelectedLogisticId,
}) => {
  const [checked, setChecked] = useState(!!selectedLogisticId);

  const handleSwitchToggle = () => {
    setChecked(!checked);

    if (!checked) {
      setSelectedLogisticId(logisticGroup.options[0].id);
    } else {
      setSelectedLogisticId(null);
    }
  };
  const handleClick = (logisticId: string): void => {
    setSelectedLogisticId(logisticId);
  };

  // Button is purple when selected, default color when not
  const getButtonColor = (logisticId: string): "primary" | "default" =>
    selectedLogisticId === logisticId ? "primary" : "default";

  return (
    <>
      <div className={cx(styles.topBar)}>
        <Typography variant="h6" style={{ marginTop: "10px" }}>
          {logisticGroup.name}
        </Typography>
        <Switch checked={checked} onChange={handleSwitchToggle} />
      </div>
      {checked && (
        <div className={cx(styles.buttonRow)}>
          {_.map(logisticGroup.options, (option) => (
            <div key={option.id} className={cx(styles.buttonContainer)}>
              <Button
                color={getButtonColor(option.id)}
                className={cx(styles.logisticButton)}
                onClick={() => handleClick(option.id)}
                disabled={!checked}
                variant="outlined"
              >
                <div className={cx(styles.buttonContents)}>
                  <Typography variant="caption">{option.emoji}</Typography>
                  <Typography variant="caption">{option.title}</Typography>
                </div>
              </Button>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default LogisticsFormRow;
