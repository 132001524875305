import React, { FunctionComponent } from "react";
import { makeStyles } from "@material-ui/core";
import acmeLogo from "~/icons/Acme-corp.png";

import { ResourceCustomTheme } from "~/styles/config";

const useStyles = makeStyles((_theme: ResourceCustomTheme) => ({
  logoCont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "40px 0px 10px 0px",
    height: 50,
  },
  logo: {
    maxHeight: 50,
    maxWidth: 110,
  },
}));

type CompanyLogoProps = {
  companyLogoUrl?: string | null;
  companyName?: string | null;
};

const CompanyLogo: FunctionComponent<CompanyLogoProps> = ({
  companyLogoUrl,
  companyName,
}) => {
  const classes = useStyles();

  return companyLogoUrl && companyName ? (
    <div className={classes.logoCont}>
      <img
        src={companyLogoUrl || ""}
        alt={`${companyName} Logo`}
        className={classes.logo}
      />
    </div>
  ) : (
    <div className={classes.logoCont}>
      <img src={acmeLogo} alt="Example Logo" className={classes.logo} />
    </div>
  );
};

export default CompanyLogo;
