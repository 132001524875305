import React, { useState } from "react";

import { gql, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { Typography } from "@material-ui/core";
import IntegrationCard from "../Integrations/IntegrationCard";
import { IntegrationsPageIntegrationFragment } from "../Integrations/ALL_INTEGRATIONS_QUERY";
import CoderPadIntegrationModal from "./CoderPadIntegrationModal";

import {
  IntegrationEnum,
  DisconnectCoderPadIntegrationForCustomer,
  FetchAllCustomerIntegrations_currentUserV2_organization_customer_integrations,
} from "~/schemaTypes";

import coderPadIconLg from "~/icons/coderpad-icon-lg.svg";

type CoderPadIntegrationCardProps = {
  integration: FetchAllCustomerIntegrations_currentUserV2_organization_customer_integrations;
};

const CustomerFragment = gql`
  fragment customerFragmentForCoderPad on Customer {
    id
    integration(input: $typeInput) {
      ...IntegrationsPageIntegration
      ... on CoderPadIntegration {
        apiKeySuffix
      }
    }
  }
  ${IntegrationsPageIntegrationFragment}
`;

const DISCONNECT_CODERPAD_INTEGRATION_FOR_CUSTOMER = gql`
  mutation DisconnectCoderPadIntegrationForCustomer(
    $typeInput: IntegrationByTypeInput!
  ) {
    disconnectCustomerCoderPadIntegration {
      success
      message
      code
      customer {
        ...customerFragmentForCoderPad
      }
    }
  }
  ${CustomerFragment}
`;

const CoderPadIntegrationCard: React.FC<CoderPadIntegrationCardProps> = ({
  integration,
}) => {
  const [open, setOpen] = useState(false);
  const [disconnect, { loading: disconnectSaving }] = useMutation<
    DisconnectCoderPadIntegrationForCustomer
  >(DISCONNECT_CODERPAD_INTEGRATION_FOR_CUSTOMER);

  const onDisconnect = async () => {
    try {
      await disconnect({
        variables: {
          typeInput: {
            type: IntegrationEnum.CODERPAD,
          },
        },
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const onConnect = () => setOpen(true);
  const onClose = () => setOpen(false);

  return (
    <>
      <IntegrationCard
        icon={coderPadIconLg}
        label="CoderPad"
        connected={integration.connected}
        onDisconnect={onDisconnect}
        disconnectSaving={disconnectSaving}
        onConnect={onConnect}
      >
        <Typography>
          Automatically generate a Coderpad links for individual interviews.
        </Typography>
      </IntegrationCard>
      <CoderPadIntegrationModal open={open} onClose={onClose} />
    </>
  );
};

export default CoderPadIntegrationCard;
