import React, { Suspense } from "react";
import _ from "lodash";

import { FeatureFlagEnum, EVENT_PLACEHOLDERS, retry } from "@resource/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFlags } from "@resource/client-ffs";
import { faCalendarAlt } from "@fortawesome/pro-light-svg-icons";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Loading from "~/components/Loading/Loading";

const RichTextEditor = React.lazy(() =>
  retry(() => import("~/components/RichTextEditor/RichTextEditor"))
);

const useStyles = makeStyles(() => ({
  hideContainer: {
    textAlign: "right",
  },
  checkbox: {
    padding: 0,
  },
  title: {
    marginLeft: "0.4rem",
    whiteSpace: "break-spaces",
    wordBreak: "break-all",
  },
  titleContainer: {
    display: "flex",
  },
}));

type InterviewTemplateProps = {
  atsInterviewName: string;
  title: string;
  description: string;
  hidden: boolean;
  setTitle(title: string): void;
  setDescription(description: string): void;
  setHidden(hidden: boolean): void;
};

const InterviewTemplate: React.FC<InterviewTemplateProps> = ({
  atsInterviewName,
  title,
  description,
  hidden,
  setTitle,
  setDescription,
  setHidden,
}) => {
  const styles = useStyles();
  const {
    [_.camelCase(
      FeatureFlagEnum.InterviewTemplateRichContent
    )]: interviewTemplateRichContentFlag,
  } = useFlags();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8} className={styles.titleContainer}>
        <Typography>
          <FontAwesomeIcon icon={faCalendarAlt} size="lg" />
          <strong className={styles.title}>{atsInterviewName}</strong>
        </Typography>
      </Grid>
      <Grid item xs={12} md={4} className={styles.hideContainer}>
        <FormControlLabel
          control={
            <Checkbox
              className={styles.checkbox}
              color="primary"
              checked={hidden}
              onChange={(e) => {
                setHidden(e.target.checked);
              }}
            />
          }
          label="Hide Interview"
        />
      </Grid>
      <Grid item xs={12}>
        {interviewTemplateRichContentFlag ? (
          <Suspense fallback={<Loading />}>
            <RichTextEditor
              label="Interview Name Override"
              toolbar="top"
              placeholders={EVENT_PLACEHOLDERS}
              toolbarFormats={{
                placeholder: true,
              }}
              trimEmpty
              onChange={setTitle}
              value={title}
            />
          </Suspense>
        ) : (
          <TextField
            fullWidth
            label="Interview Name Override"
            onChange={(e) => setTitle(e.target.value)}
            value={title}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        {interviewTemplateRichContentFlag ? (
          <Suspense fallback={<Loading />}>
            <RichTextEditor
              label="Description"
              toolbar="top"
              onChange={setDescription}
              placeholders={EVENT_PLACEHOLDERS}
              trimEmpty
              height={240}
              value={description}
            />
          </Suspense>
        ) : (
          <TextField
            fullWidth
            multiline
            rows={4}
            label="Description"
            onChange={(e) => setDescription(e.target.value)}
            value={description}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default InterviewTemplate;
