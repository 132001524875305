import cx from "classnames";
import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { InputBase, Typography } from "@material-ui/core";
import { retry } from "@resource/common";
import { Alert, AlertTitle } from "@material-ui/lab";
import {
  generateGreenhouseIframeUrl,
  parseGreenhouseUrl,
} from "~/utils/greenhouse";

import GH_CANDIDATE_QUERY from "../../queries/GH_CANDIDATE_QUERY";
import {
  greenhouseCandidate,
  greenhouseCandidateVariables,
} from "~/schemaTypes";

import styles from "./CarbonDioxide.module.scss";

const ReactJson = React.lazy(() => retry(() => import("react-json-view")));

/**
 * React Json component does not except className as a prop type. If you want to
 * make any stylistic changes to said component, update this json object.
 */

const reactJsonStyles = {
  alignSelf: "center",
  marginTop: 20,
  width: "40%",
};

type CarbonDioxideProps = Record<string, unknown>;

/**
 * Renders an an input element where valid greenhouse candidate urls can be pasted. Doing so
 * pulls the greenhouse data for the candidate and renders the guide to the right of the screen.
 *
 * TODO v2:
 * - add border around json package
 * - add request for admin to switch user in app.resource.io
 *    - use dynamic url REACT_APP_DASHBOARD_URL
 *    - it can be staging
 * - extra: beautify json render component
 */
const CarbonDioxide: React.FC<CarbonDioxideProps> = () => {
  const [greenhouseUrl, setGreenhouseUrl] = useState("");

  const { applicationId, candidateId } = parseGreenhouseUrl(greenhouseUrl);
  const iframeUrl = generateGreenhouseIframeUrl({ applicationId, candidateId });
  const invalidUrl = !iframeUrl && greenhouseUrl;

  const { data: { greenhouseCandidate: candidateData = "{}" } = {} } = useQuery<
    greenhouseCandidate,
    greenhouseCandidateVariables
  >(GH_CANDIDATE_QUERY, {
    skip: !iframeUrl,
    variables: {
      where: {
        candidateId,
        applicationId,
      },
    },
  });
  return (
    <div className={cx(styles.adminSetupContainer)}>
      <div className={cx(styles.inputContainer)}>
        <Typography variant="h3">Carbon Dioxide</Typography>
        <div className={cx(styles.urlInputContainer)}>
          <InputBase
            value={greenhouseUrl}
            onChange={(e) => setGreenhouseUrl(e.target.value)}
            className={cx(styles.urlInput)}
            placeholder="Paste Candidate Greenhouse URL"
            inputProps={{ "aria-label": "paste candidate url" }}
          />
          {invalidUrl && (
            <Alert severity="error">
              <AlertTitle>Invalid URL</AlertTitle>
              The Greenhouse Url that you provided is not valid. Please input
              another.
            </Alert>
          )}
        </div>
        {candidateData && (
          <ReactJson
            displayObjectSize
            style={reactJsonStyles}
            src={JSON.parse(candidateData)}
            shouldCollapse={(field): boolean => field.name !== "root"}
          />
        )}
      </div>
      {iframeUrl && (
        <iframe
          className={cx(styles.testGuideContainer)}
          title="test guide"
          src={iframeUrl}
        />
      )}
    </div>
  );
};

export default CarbonDioxide;
