import _ from "lodash";
import React, { useEffect } from "react";
import {
  Typography,
  makeStyles,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useSubscription } from "@apollo/client";
import { Controller, Control, FieldErrors } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage } from "@hookform/error-message";
import { faExternalLinkAlt } from "@fortawesome/pro-light-svg-icons";
import STAGE_TEMPLATE_SUBSCRIPTION from "./STAGE_TEMPLATES_SUBSCRIPTION@hasura";
import useAnalytics from "~/react-hooks/useAnalytics";
import {
  StageTemplatePickerSubscription,
  StageTemplatePickerSubscriptionVariables,
} from "~/schemaTypesHasura";
import { ResourceCustomTheme } from "~/styles/config";

const CreateNewEnum = "create-new";
const useStyles = makeStyles((theme: ResourceCustomTheme) => ({
  root: {},
  link: {
    textDecoration: "underline",
  },
  formControlContainer: {
    display: "flex",
    alignItems: "flex-start",
  },
  formControl: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

type StageTemplatePickerProps = {
  control: Control;
  errors: FieldErrors;
  currentValue?: string;
  name?: string;
};

const StageTemplatePicker: React.FC<StageTemplatePickerProps> = ({
  control,
  errors,
  currentValue,
  name = "stageTemplateId",
}) => {
  const classes = useStyles();
  const [analytics] = useAnalytics();

  useEffect(() => {
    if (currentValue === CreateNewEnum) {
      window.open("/templates/stages/?new=true", "_blank");
      analytics.track("Create New Stage Template Clicked");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);

  const { data: stageTemplateData } = useSubscription<
    StageTemplatePickerSubscription,
    StageTemplatePickerSubscriptionVariables
  >(STAGE_TEMPLATE_SUBSCRIPTION);
  const stageTemplates = stageTemplateData?.stage_template;
  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel htmlFor="stage-template">Stage Template</InputLabel>
      <Controller
        as={
          <Select
            inputProps={{ id: "stage-template" }}
            label="Stage Template"
            error={!!errors[name]}
          >
            {_.map(stageTemplates, ({ id, name: stageTemplateName }) => (
              <MenuItem key={id} value={id}>
                {stageTemplateName}
              </MenuItem>
            ))}
            <MenuItem key="create-new" value="create-new">
              <strong>Create New Stage Template</strong>
            </MenuItem>
          </Select>
        }
        name={name}
        rules={{
          required: true,
          validate: (f) => f !== CreateNewEnum,
        }}
        control={control}
      />
      <Typography variant="subtitle1" color="error">
        <ErrorMessage
          errors={errors}
          name={name}
          message="Please select a Stage Template"
        />
      </Typography>
      <Link to="/templates/stages" target="_blank">
        <Typography color="primary" variant="body1">
          <span className={classes.link}>View my Stage Templates</span>{" "}
          <FontAwesomeIcon size="1x" icon={faExternalLinkAlt} />
        </Typography>
      </Link>
    </FormControl>
  );
};

export default StageTemplatePicker;
