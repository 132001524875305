import React from "react";
import _ from "lodash";
import { Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import {
  StageTemplateUpdateEmailTemplate,
  StageTemplateUpdateEmailTemplateVariables,
  StageTemplateUpdateEmailTemplate_updateStageTemplate_emailTemplate as EmailTemplate,
} from "~/schemaTypes";

type EmailTemplateSelectProps = {
  stageTemplateId?: string;
  allEmailTemplates: EmailTemplate[];
  emailTemplateId?: string;
};

const STAGE_TEMPLATE_MUTATION = gql`
  mutation StageTemplateUpdateEmailTemplate($input: stageTemplateUpdateInput!) {
    updateStageTemplate(input: $input) {
      id
      emailTemplate {
        id
        name
      }
    }
  }
`;

const EmailTemplateSelect = ({
  stageTemplateId,
  allEmailTemplates,
  emailTemplateId,
}: EmailTemplateSelectProps) => {
  const [updateStageTemplate] = useMutation<
    StageTemplateUpdateEmailTemplate,
    StageTemplateUpdateEmailTemplateVariables
  >(STAGE_TEMPLATE_MUTATION);
  const getTemplateMenuItems = () => {
    return _.map(allEmailTemplates, ({ name, id }) => {
      return (
        <MenuItem key={id} value={id}>
          {name}
        </MenuItem>
      );
    });
  };

  const generateOptimisticResponse = (
    emailTemplate: EmailTemplate
  ): StageTemplateUpdateEmailTemplate => ({
    updateStageTemplate: {
      id: stageTemplateId!,
      emailTemplate,
      __typename: "StageTemplate",
    },
  });

  const handleSelect = async (selectedEmailTemplateId: string) => {
    const emailTemplate = allEmailTemplates.find(
      ({ id }) => id === selectedEmailTemplateId
    );
    await updateStageTemplate({
      variables: {
        input: {
          id: stageTemplateId!,
          emailTemplateId: selectedEmailTemplateId,
        },
      },
      optimisticResponse: generateOptimisticResponse(emailTemplate!),
    });
  };
  return (
    <FormControl id="emailTemplate" variant="outlined" style={{ width: "48%" }}>
      <InputLabel shrink>Email Template</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        label="Email Template"
        value={emailTemplateId || ""}
        onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
          handleSelect(e.target.value as string)
        }
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        }}
      >
        {getTemplateMenuItems()}
      </Select>
    </FormControl>
  );
};

export default EmailTemplateSelect;
