import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";

import _ from "lodash";
import { faSearch } from "@fortawesome/pro-light-svg-icons";
import { FeatureFlagEnum } from "@resource/common";
import { useFlags } from "@resource/client-ffs";
import {
  useAsyncDebounce,
  useFilters,
  useGlobalFilter,
  useTable,
  useSortBy,
  useRowSelect,
  UseTableCellProps,
} from "react-table";
import { Link } from "react-router-dom";
import {
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  InputAdornment,
  TextField,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { GetOrganizationRoleJourneys_get_current_user_v2_currentOrganization_guide_templates as GuideTemplate } from "~/schemaTypesHasura";
import { ResourceCustomTheme } from "~/styles/config";
import AutocompleteColumnFilter from "./AutocompleteFilter";

const useStyles = makeStyles<ResourceCustomTheme>((theme) =>
  createStyles({
    chipsContainers: {
      height: "2rem",
      display: "flex",
      alignItems: "center",
    },
    currentFilterList: {
      display: "flex",
      listStyle: "none",
      flexWrap: "wrap",
      padding: theme.spacing(0.5),
      margin: 0,
    },
    chip: {
      margin: theme.spacing(0.5),
    },
    filterButton: {
      fontSize: "1rem",
    },
    filterContainer: {
      textAlign: "right",
    },
    tableContainer: {
      overflowY: "scroll",
      maxHeight: "50rem",
      height: "50rem",
    },
    noJourneys: {
      textAlign: "center",
      marginTop: "1rem",
    },
    cell: {
      "& > a:focus": {
        outline: "none",
      },
    },
    cellLink: {
      width: "100%",
      display: "block",
    },
    light: {
      color: theme.colors.MidnightBlue(200),
    },
  })
);

const useGlobalFilterStyles = makeStyles<ResourceCustomTheme>((theme) =>
  createStyles({
    searchIcon: {
      color: theme.colors.KarlGray(900),
    },
  })
);

const GlobalFilter = ({
  filters,
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) => {
  const styles = useGlobalFilterStyles();
  const [showInputAdornment, setShowInputAdornment] = useState(!globalFilter);
  const setAsyncSetGlobalFilter = useAsyncDebounce(setGlobalFilter, 300);

  return (
    <TextField
      fullWidth
      type="search"
      defaultValue={globalFilter || ""}
      onChange={(e) => {
        setAsyncSetGlobalFilter(e.target.value || "");
        setShowInputAdornment(!e.target.value);
      }}
      InputProps={
        showInputAdornment
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <FontAwesomeIcon
                    icon={faSearch}
                    size="sm"
                    className={styles.searchIcon}
                  />
                </InputAdornment>
              ),
            }
          : {}
      }
      placeholder={`Filter ${
        filters.length ? `${preGlobalFilteredRows.length} ` : ""
      }journeys by Name or Req Id `}
    />
  );
};

const TemplateCell = (table: UseTableCellProps<GuideTemplate>) => {
  const reqId = table.row?.original?.job_role?.reqId;
  return (
    <>
      <Typography variant="body1">{table.value}</Typography>
      {reqId && <Typography variant="subtitle2">Req ID: {reqId}</Typography>}
    </>
  );
};

const ConfiguredStagesCell = (table: UseTableCellProps<GuideTemplate>) => {
  const classes = useStyles();
  const numActivated = _.filter(
    table.row?.original?.stage_template_installations_aggregate.nodes,
    "activatedAt"
  ).length;
  return (
    <>
      <Typography variant="body1">
        {numActivated} <span className={classes.light}>of {table.value}</span>
      </Typography>
    </>
  );
};

type OnFetchProps = {
  departments: string[];
  offices: string[];
  search: string;
};

type JourneyTableProps = {
  offices: string[];
  departments: string[];
  guideTemplates: GuideTemplate[];
  loading: boolean;
  onSelectionChange(selectedRowIds: string[]): void;
  onFetchData(props: OnFetchProps): void;
};

const JourneyTable: React.FC<JourneyTableProps> = ({
  offices,
  departments,
  guideTemplates,
  loading,
  onSelectionChange,
  onFetchData,
}) => {
  const styles = useStyles();
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: ({ name }) => name,
        Cell: TemplateCell,
        Filter: () => null,
      },
      {
        Header: "Stages Added",
        id: "stage_count",
        accessor: ({ stage_template_installations_aggregate }) =>
          stage_template_installations_aggregate.aggregate.count,
        Cell: ConfiguredStagesCell,
        Filter: () => null,
      },
      {
        Header: "Guides Sent",
        id: "sent_guide_count",
        accessor: ({ guides_aggregate }) =>
          guides_aggregate.aggregate.count || "-",
        Filter: () => null,
      },
      {
        Header: "Department",
        accessor: ({ job_role }) => job_role.department ?? "-",
        id: "department",
        Filter: AutocompleteColumnFilter,
        filter: "includesSome",
        allOptions: departments,
        sample: _.sample(departments),
      },
      {
        Header: "Office",
        accessor: ({ job_role }) => job_role.office ?? "-",
        id: "office",
        Filter: AutocompleteColumnFilter,
        filter: "includesSome",
        allOptions: offices,
        sample: _.sample(offices),
      },
    ],
    [departments, offices]
  );
  const {
    [_.camelCase(
      FeatureFlagEnum.BulkEditInterviewKits
    )]: bulkEditInterviewKitsFlag,
  } = useFlags();

  const data = useMemo(() => guideTemplates, [guideTemplates]);

  const useSelectColumnHook = bulkEditInterviewKitsFlag
    ? (hooks) => {
        hooks.allColumns.push((columns_) => [
          // Let's make a column for selection
          {
            id: "selection",
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox.  Pagination is a problem since this will select all
            // rows even though not all rows are on the current page.  The solution should
            // be server side pagination.  For one, the clients should not download all
            // rows in most cases.  The client should only download data for the current page.
            // In that case, getToggleAllRowsSelectedProps works fine.
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <Checkbox color="primary" {...getToggleAllRowsSelectedProps()} />
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <Checkbox color="primary" {...row.getToggleRowSelectedProps()} />
            ),
          },
          ...columns_,
        ]);
      }
    : () => {};

  const {
    allColumns,
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter,
    setGlobalFilter,
    preGlobalFilteredRows,
    state: { sortBy, filters, globalFilter, selectedRowIds },
  } = useTable<GuideTemplate>(
    {
      columns,
      data,
      manualGlobalFilter: true,
      manualFilters: true,
      autoResetFilters: false,
      autoResetGlobalFilters: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useRowSelect,
    useSelectColumnHook
  );

  const hasFilters = !!globalFilter || !!filters.length;

  useEffect(() => {
    const selectedFlatRowIds = _.chain(selectedRowIds)
      .pickBy(_.identity)
      .keys()
      .value();
    const matchingGuideTemplateIds = _.chain(guideTemplates)
      .filter((_guideTemplate, index) =>
        _.includes(selectedFlatRowIds, index.toString())
      )
      .map("id")
      .value();
    onSelectionChange(matchingGuideTemplateIds);
  }, [selectedRowIds, onSelectionChange, guideTemplates]);

  useLayoutEffect(() => {
    const filteredDepartments = _.find(filters, { id: "department" })?.value;
    const filteredOffices = _.find(filters, { id: "office" })?.value;
    const search = globalFilter;

    onFetchData({
      departments: filteredDepartments,
      offices: filteredOffices,
      search,
    });
  }, [onFetchData, sortBy, filters, globalFilter]);

  const removeChip = (chip, id) => {
    const existingValues = _.find(filters, { id })?.value;
    const newValues = _.without(existingValues, chip);
    if (newValues.length) {
      setFilter(id, newValues);
    } else {
      setFilter(id, undefined);
    }
  };

  return (
    <TableContainer className={styles.tableContainer}>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={6} md={4}>
          <GlobalFilter
            filters={filters}
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={5} className={styles.chipsContainers}>
          <ul className={styles.currentFilterList}>
            {_.flatMap(filters, ({ id, value }) =>
              _.map(value, (v) => (
                <li key={`${id}-${v}`}>
                  <Chip
                    label={`${_.capitalize(id)}: ${v}`}
                    onDelete={() => removeChip(v, id)}
                    className={styles.chip}
                    color="primary"
                  />
                </li>
              ))
            )}
          </ul>
          {loading && <CircularProgress size="2rem" />}
        </Grid>
        <Grid item xs={12} sm={6} md={3} className={styles.filterContainer}>
          {_.chain(allColumns)
            .filter((column) => column.canFilter)
            .map((column) => (
              <React.Fragment key={column.id}>
                {column.render("Filter")}
              </React.Fragment>
            ))
            .value()}
        </Grid>
      </Grid>
      <Table {...getTableProps()}>
        <TableHead>
          {_.map(headerGroups, (headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {_.map(headerGroup.headers, (column, index) => (
                <TableCell
                  className={styles.cell}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  {...(bulkEditInterviewKitsFlag && index === 0
                    ? { padding: "checkbox" }
                    : {})}
                >
                  {column.render("Header")}
                  {column.canSort && (
                    <TableSortLabel
                      active={column.isSorted}
                      direction={column.isSortedDesc ? "desc" : "asc"}
                    />
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {_.map(rows, (row) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                {_.map(row.cells, (cell, index) => (
                  <TableCell
                    className={styles.cell}
                    {...cell.getCellProps()}
                    {...(bulkEditInterviewKitsFlag && index === 0
                      ? { padding: "checkbox" }
                      : {})}
                  >
                    {index !== 0 || !bulkEditInterviewKitsFlag ? (
                      <Link
                        tabIndex={index === 1 ? 0 : -1}
                        className={styles.cellLink}
                        to={`/journeys/${row.original.id}`}
                      >
                        {cell.render("Cell")}
                      </Link>
                    ) : (
                      cell.render("Cell")
                    )}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {!rows.length && !loading && (
        <Typography className={styles.noJourneys} variant="body2">
          {hasFilters
            ? "No matching Journeys"
            : "Integrate with Greenhouse to see your Role Journeys"}
        </Typography>
      )}
    </TableContainer>
  );
};

export default JourneyTable;
