import _ from "lodash";
import React, { useMemo } from "react";
import {
  DialogContent,
  Chip,
  Typography,
  Button,
  DialogActions,
  makeStyles,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Link,
} from "@material-ui/core";
import { strings } from "@resource/common";
import { useQuery } from "@apollo/client";
import Loading from "~/components/Loading/Loading";
import {
  InterviewEditsDispatchType,
  GreenhouseJobStage,
  ActionEnum,
} from "./types";
import { ResourceCustomTheme } from "~/styles/config";
import HASURA_GET_JOB_ROLE_GREENHOUSE_DATA from "./HASURA_GET_JOB_ROLE_GREENHOUSE_DATA@hasura";
import {
  GetJobRoleGreenhouseData,
  GetJobRoleGreenhouseDataVariables,
} from "~/schemaTypesHasura";

const useGroupedStageCellStyles = makeStyles((_theme: ResourceCustomTheme) => ({
  root: {},
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  linkCell: {
    textAlign: "right",
  },
  link: {
    textDecoration: "underline",
  },
}));

type GroupedStageCellProps = {
  stages: GreenhouseJobStage[];
  name: string;
  onSelectInterview(selection: {
    numStages: number;
    ids: string[];
    name: string;
  }): void;
};

const GroupedStageCell: React.FC<GroupedStageCellProps> = ({
  name,
  stages,
  onSelectInterview,
}) => {
  const classes = useGroupedStageCellStyles();
  const interviewMapping = useMemo(() => {
    return _.chain(stages).map("interviews").flatten().groupBy("name").value();
  }, [stages]);

  return (
    <TableRow key={name}>
      <TableCell>
        <Typography>{name}</Typography>
        {stages.length > 1 && (
          <Typography variant="subtitle2">
            Present in {stages.length} stages
          </Typography>
        )}
      </TableCell>
      <TableCell>
        <Table>
          <TableBody>
            {_.map(interviewMapping, (interviews, interviewName) => {
              return (
                <TableRow key={interviewName}>
                  <TableCell>
                    <Typography variant="body1">{interviewName}</Typography>
                  </TableCell>
                  <TableCell className={classes.linkCell}>
                    <Button
                      color="primary"
                      className={classes.link}
                      component={Link}
                      onClick={() =>
                        onSelectInterview({
                          numStages: stages.length,
                          ids: _.map(interviews, "id"),
                          name: interviewName,
                        })
                      }
                    >
                      Select {interviews.length} interview
                      {interviews.length > 1 ? "s" : ""}
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableCell>
    </TableRow>
  );
};

const useStyles = makeStyles((theme: ResourceCustomTheme) => ({
  root: {},
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  chip: {
    backgroundColor: theme.colors.RoyalPurple(100),
    color: theme.colors.RoyalPurple(),
    marginBottom: theme.spacing(2),
  },
}));

type SelectInterviewsProps = {
  dispatch: React.Dispatch<InterviewEditsDispatchType>;
  jobRoleIds: string[];
};

const SelectInterviews: React.FC<SelectInterviewsProps> = ({
  dispatch,
  jobRoleIds,
}) => {
  const classes = useStyles();

  const onSelectInterview = ({ numStages, ids, name }) => {
    dispatch({
      type: ActionEnum.SetInterviews,
      interviewSelection: {
        numStages,
        ids,
        name,
      },
    });
    dispatch({ type: ActionEnum.Next });
  };

  const { data: jobRoleData, loading } = useQuery<
    GetJobRoleGreenhouseData,
    GetJobRoleGreenhouseDataVariables
  >(HASURA_GET_JOB_ROLE_GREENHOUSE_DATA, {
    variables: {
      where: {
        id: {
          _in: jobRoleIds,
        },
        greenhouseJobStagesData: {
          _is_null: false,
        },
      },
    },
  });

  const stageMapping = useMemo(() => {
    return (_.chain(jobRoleData?.job_role)
      .map("greenhouseJobStagesData")
      .compact()
      .flatten()
      .filter((s) => {
        return !!s.interviews.length;
      })
      .groupBy("name")
      .value() as unknown) as Record<string, GreenhouseJobStage[]>;
  }, [jobRoleData]);

  return loading ? (
    <Loading />
  ) : (
    <>
      <DialogContent dividers>
        <Chip
          className={classes.chip}
          color="primary"
          variant="default"
          label={`Viewing stages across ${strings.pluralize(
            "Role Journey",
            jobRoleIds.length ?? 0
          )}`}
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Grouped Stages</TableCell>
              <TableCell>Grouped Interviews</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_.map(stageMapping, (stages, name) => {
              return (
                <GroupedStageCell
                  key={name}
                  stages={stages}
                  name={name}
                  onSelectInterview={onSelectInterview}
                />
              );
            })}
          </TableBody>
        </Table>
      </DialogContent>

      <DialogActions>
        <Grid container>
          <Grid item xs={12} className={classes.actions}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                dispatch({
                  type: ActionEnum.Cancel,
                });
              }}
              style={{ marginLeft: 10 }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};

export default SelectInterviews;
