import React, { FunctionComponent, useEffect } from "react";
import { Typography, Tabs, Tab, Fade } from "@material-ui/core";
import {
  useLocation,
  useRouteMatch,
  Switch,
  Route,
  Redirect,
} from "react-router";
import { Link } from "react-router-dom";
import { Auth0Provider } from "~/react-auth0";

import Company from "./Company/Company";

import styles from "./Settings.module.scss";
import Integrations from "./Integrations/Integrations";
import Interviewers from "./Interviewers/Interviewers";

type SettingsProps = Record<string, never>;

const Settings: FunctionComponent<SettingsProps> = () => {
  const location = useLocation();
  const { url } = useRouteMatch();

  // HACK: There's a bug with how material calculates tab underlines, we
  // just have to force this update.
  //
  // See: https://github.com/mui-org/material-ui/issues/9337#issuecomment-413789329
  useEffect(() => {
    window.dispatchEvent(new CustomEvent("resize"));
  }, [location]);

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_TENANT_DOMAIN!}
      client_id={process.env.REACT_APP_AUTH0_GUIDES_CLIENT_ID!}
      connection="slack"
      redirect_uri={window.location.origin}
      onRedirectCallback={() => {}}
    >
      <Fade in timeout={500}>
        <div>
          <div className={styles.settingsContainer}>
            <div className={styles.headerCont}>
              <Typography className={styles.headerText} variant="h2">
                Settings
              </Typography>
              <Tabs value={location.pathname}>
                <Tab
                  value={`${url}/company/`}
                  label="Your Org"
                  component={Link}
                  to={`${url}/company/`}
                />
                <Tab
                  value={`${url}/integrations/`}
                  label="Integrations"
                  component={Link}
                  to={`${url}/integrations/`}
                />
              </Tabs>
            </div>
            <div className={styles.routesCont}>
              <Switch>
                {/* For now routes must be defined here and in index.tsx && GuidesHomepage */}
                <Route path={`${url}/company/`} component={Company} exact />
                <Route
                  path={`${url}/integrations/`}
                  component={Integrations}
                  exact
                />
                <Route
                  path={`${url}/interviewers/`}
                  component={Interviewers}
                  exact
                />
                <Redirect from={`${url}`} to={`${url}/company/`} />
              </Switch>
            </div>
          </div>
        </div>
      </Fade>
    </Auth0Provider>
  );
};

export default Settings;
