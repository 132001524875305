export type Category = {
  description: string;
  id: string;
  emoji: string;
};

const categories: Array<Category> = [
  {
    description: "Interviewer",
    id: "interviewer",
    emoji: "✍️",
  },
  {
    description: "Technical difficulties",
    id: "technical-difficulties",
    emoji: "☎️",
  },
  {
    description: "Interview format",
    id: "interview-format",
    emoji: "📝",
  },
  {
    description: "Timeliness",
    id: "timeliness",
    emoji: "⏰",
  },
  {
    description: "Interview questions",
    id: "interview-questions",
    emoji: "🤔",
  },
  {
    description: "Interpersonal skills",
    id: "interpersonal-skills",
    emoji: "🤝",
  },
];

export default categories;
