import gql from "graphql-tag";

export default gql`
  subscription StageTemplatePickerSubscription($currentOrganizationId: uuid)
    @hasura {
    currentOrganizationId @client @export(as: "currentOrganizationId")
    stage_template(
      where: {
        archivedAt: { _is_null: true }
        organizationId: { _eq: $currentOrganizationId }
      }
    ) {
      id
      name
    }
  }
`;
