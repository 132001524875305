import React from "react";
import {
  Typography,
  makeStyles,
  Stepper,
  Step,
  StepLabel,
} from "@material-ui/core";

import { ResourceCustomTheme } from "~/styles/config";

const useStyles = makeStyles((_theme: ResourceCustomTheme) => ({
  root: {},
}));

type ProgressStepperProps = {
  steps: string[];
  activeStep: number;
};

const ProgressStepper: React.FC<ProgressStepperProps> = ({
  steps,
  activeStep,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          return (
            <Step
              key={label}
              color="primary"
              completed={index < activeStep}
              disabled
            >
              <StepLabel>
                <Typography variant="subtitle1">{label}</Typography>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
};

export default ProgressStepper;
