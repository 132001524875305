import React, { FunctionComponent } from "react";
import moment from "moment";
import { Typography } from "@material-ui/core";
import ScheduleExpandedEvent, {
  ScheduleExpandedEventProps,
} from "./ScheduleExpandedEvent/ScheduleExpandedEvent";

import styles from "./ScheduleExpanded.module.scss";

type ScheduleExpandedProps = {
  events: ScheduleExpandedEventProps[];
};

const ScheduleExpanded: FunctionComponent<ScheduleExpandedProps> = ({
  events,
}) =>
  events.filter(({ hidden }) => !hidden).length > 0 ? (
    <div className={styles.scheduleExpandedContainer}>
      <Typography variant="h4">
        🗓 {moment(events[0]?.startTime).format("MMMM Do, YYYY")}
      </Typography>
      {events.map(
        ({
          id,
          title,
          startTime,
          endTime,
          description,
          interviewers,
          hidden,
        }) => (
          <ScheduleExpandedEvent
            key={id}
            id={id}
            title={title}
            startTime={startTime}
            endTime={endTime}
            description={description}
            interviewers={interviewers}
            hidden={hidden}
          />
        )
      )}
    </div>
  ) : (
    <></>
  );

export default ScheduleExpanded;
