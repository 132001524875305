import _ from "lodash";
import {
  ModuleEnum,
  replacePlaceholderValues,
  strings,
} from "@resource/common";
import {
  generateGreeting,
  generateTime,
  generateMedia,
  generateSchedule,
  generateLogistics,
  generateCandidateInterviewFeedback,
  generateInterviewTeam,
  generateValues,
  generateLinks,
  generateLocation,
  generateMission,
  generateRichText,
} from "./index";
import {
  defaultRoleName,
  defaultCompanyName,
  defaultCandidateName,
} from "./defaults";
import { Overrides, StageModule, StageTemplateModule } from "./types";

export default ({
  modules,
  overrides,
}: {
  modules: StageTemplateModule[];
  overrides?: Overrides;
}): StageModule[] => {
  return _.compact(
    _.map(modules, ({ type, data }) => {
      switch (type) {
        case ModuleEnum.GreetingHeader:
          return generateGreeting(
            _.merge(
              {},
              {
                ...(overrides?.candidateName
                  ? {
                      header: `Hi ${
                        strings.splitName(overrides.candidateName).firstName
                      }`,
                    }
                  : {}),
                subheader: overrides?.roleName,
              }
            )
          );
        case ModuleEnum.Time:
          // eslint-disable-next-line no-case-declarations
          const timeOverrides =
            overrides?.interviewStartTime ||
            overrides?.interviewDuration ||
            overrides?.interviewDate
              ? {
                  ...data,
                  events: [],
                }
              : data;
          return generateTime(
            _.merge({ header: data.header }, timeOverrides, overrides)
          );
        case ModuleEnum.Location:
          return generateLocation(_.merge({}, data, overrides));
        case ModuleEnum.Schedule:
          return generateSchedule(_.merge({}, data, overrides));
        case ModuleEnum.Logistics:
          return generateLogistics(
            _.merge(
              {},
              {
                logistics: _.map(data.logistics, (i) => ({
                  id: i.id,
                  logisticGroupId: i.logisticGroupId,
                  icon: i.emoji,
                  title: i.title,
                  description: i.description,
                })),
                header: data.header,
              },
              overrides
            )
          );
        case ModuleEnum.TeamPhoto:
          return generateMedia(
            _.merge(
              {},
              {
                mediaLink: data.photoUrl,
                header: data.header,
              },
              overrides
            )
          );
        case ModuleEnum.Media:
          return generateMedia(_.merge({}, data, overrides));
        case ModuleEnum.Mission:
          return generateMission(
            _.merge(
              {},
              {
                ...data,
                missionStatement: data.description,
              },
              overrides
            )
          );
        case ModuleEnum.Values:
          return generateValues(
            _.merge(
              {},
              {
                infoArray: _.map(data.items, (i) => ({
                  id: i.id,
                  title: i.primaryText,
                  description: i.secondaryText,
                })),
              },
              data,
              overrides
            )
          );
        case ModuleEnum.Links:
          return generateLinks(
            _.merge(
              {},
              {
                defaultImage: data.defaultImage,
                links: _.map(data.items, (i) => ({
                  id: i.id,
                  url: i.secondaryText,
                  label: i.primaryText,
                })),
              },
              data,
              overrides
            )
          );
        case ModuleEnum.InterviewTeam:
          return generateInterviewTeam(_.assign({}, data, overrides));
        case ModuleEnum.CandidatePostInterviewFeedback:
          return generateCandidateInterviewFeedback(
            _.merge({}, data, overrides)
          );
        case ModuleEnum.RichText:
          // eslint-disable-next-line no-case-declarations
          const mergedData = _.merge({}, data, {
            rawHtml: overrides?.customDetails,
          });
          mergedData.rawHtml = replacePlaceholderValues(
            mergedData.rawHtml || "",
            {
              "candidate-first-name": strings.splitName(
                overrides?.candidateName || defaultCandidateName
              ).firstName,
              "company-name": overrides?.companyName || defaultCompanyName,
              "job-role-name": overrides?.roleName || defaultRoleName,
            }
          );
          return generateRichText(mergedData);
        default:
          return null;
      }
    })
  );
};
