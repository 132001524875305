import _ from "lodash";
import React, { useEffect } from "react";
import cx from "classnames";
import { useQuery, useSubscription, useMutation } from "@apollo/client";
import { faExternalLinkAlt } from "@fortawesome/pro-light-svg-icons";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Grid,
  InputLabel,
  FormControl,
  Button,
  CircularProgress,
  Select,
  MenuItem,
  makeStyles,
  Typography,
  DialogContent,
  Dialog,
  DialogActions,
} from "@material-ui/core";
import ghIcon from "~/assets/icons/gh-icon-white.svg";
import guideIcon from "~/assets/icons/guide-icon-white.svg";
import sendIcon from "~/assets/icons/send-icon-white.svg";
import DialogTitle from "~/components/Dialog/DialogTitle/DialogTitle";
import {
  GuideTemplateForEditing,
  GuideTemplateForEditingVariables,
} from "~/schemaTypes";
import {
  AddGreenhouseStageTemplateSubscription,
  AddGreenhouseEmailTemplateSubscription,
  AddGreenhouseStageTrigger,
  AddGreenhouseStageTriggerVariables,
} from "~/schemaTypesHasura";
import Loading from "~/components/Loading/Loading";
import GUIDE_TEMPLATE_FOR_EDITING from "../GUIDE_TEMPLATE_FOR_EDITING";
import {
  STAGE_TEMPLATE_SUBSCRIPTION,
  EMAIL_TEMPLATE_SUBSCRIPTION,
} from "./ADD_GREENHOUSE_STAGE_TEMPLATE_SUBSCRIPTION@hasura";
import ADD_GREENHOUSE_STAGE_TRIGGER from "./ADD_GREENHOUSE_STAGE_TRIGGER@hasura";
import useAnalytics from "~/react-hooks/useAnalytics";

import { ResourceCustomTheme } from "~/styles/config";

const CreateNewEnum = "create-new";

const useStyles = makeStyles((theme: ResourceCustomTheme) => ({
  root: {},
  formControl: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  select: {},
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  subtext: {
    marginTop: theme.spacing(2),
    color: theme.colors.MidnightBlue(200),
  },
  link: {
    textDecoration: "underline",
  },
  formControlContainer: {
    display: "flex",
    alignItems: "flex-start",
  },
  iconContainer: {
    flexGrow: 0,
    flexShrink: 0,
    borderRadius: 4,
    marginRight: theme.spacing(1),
    width: 36,
    height: 36,
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  ghContainer: {
    backgroundColor: theme.colors.GreenhouseGreen,
  },
  guideContainer: {
    backgroundColor: theme.colors.MidnightBlue(),
  },
  sendContainer: {
    backgroundColor: theme.colors.RoyalPurple(),
  },
  icon: {
    height: "70%",
  },
}));

type ActivateStageTemplateInstallationModalProps = {
  guideTemplateId: string;
  open: boolean;
  onCancel: () => void;
  onClose: (installationId?: string) => void;
};

const ActivateStageTemplateInstallationModal: React.FC<ActivateStageTemplateInstallationModalProps> = ({
  guideTemplateId,
  open,
  onCancel,
  onClose,
}) => {
  const [analytics] = useAnalytics();
  const classes = useStyles();
  const { control, reset, errors, handleSubmit, watch } = useForm({
    defaultValues: {
      stageTemplateId: "",
      emailTemplateId: "",
      atsJobStageId: "",
    },
  });

  const [
    addGreenhouseStageTrigger,
    { loading: addingStageTrigger },
  ] = useMutation<
    AddGreenhouseStageTrigger,
    AddGreenhouseStageTriggerVariables
  >(ADD_GREENHOUSE_STAGE_TRIGGER);

  const { data: stageTemplateData } = useSubscription<
    AddGreenhouseStageTemplateSubscription
  >(STAGE_TEMPLATE_SUBSCRIPTION);

  const { data: emailTemplateData } = useSubscription<
    AddGreenhouseEmailTemplateSubscription
  >(EMAIL_TEMPLATE_SUBSCRIPTION);

  const { data: guideTemplateData, loading } = useQuery<
    GuideTemplateForEditing,
    GuideTemplateForEditingVariables
  >(GUIDE_TEMPLATE_FOR_EDITING, {
    variables: {
      input: {
        id: guideTemplateId,
      },
    },
  });
  const stageTemplates = stageTemplateData?.stage_template;
  const emailTemplates = emailTemplateData?.email_template_v2;
  const stageTemplateInstallations =
    guideTemplateData?.guideTemplate?.stageTemplateInstallations || [];
  const activatedStageTemplateInstallations = _.filter(
    stageTemplateInstallations,
    (sti) => !!sti.activatedAt
  );
  const jobStages =
    guideTemplateData?.guideTemplate?.jobRole?.greenhouseJobStages || [];
  const availableJobStages = _.differenceWith(
    jobStages,
    activatedStageTemplateInstallations,
    (stage, installation) => stage.id === installation.atsStageId
  );
  const firstJobStageId = _.first(availableJobStages)?.id;

  const availableStageTemplates = _.differenceWith(
    stageTemplates,
    activatedStageTemplateInstallations,
    (template, installation) => template.id === installation.stageTemplate.id
  );
  useEffect(() => {
    if (firstJobStageId) {
      reset({ atsJobStageId: firstJobStageId });
    }
  }, [firstJobStageId, reset]);

  const onSubmit = async (data) => {
    analytics.track("Add Greenhouse Stage Trigger Clicked", {
      addedEmailAction: !!data.emailTemplateId,
    });
    const selectedJobStage = _.find(jobStages, { id: data.atsJobStageId });
    const selectedStageTemplate = _.find(stageTemplates, {
      id: data.stageTemplateId,
    });
    const selectedEmailTemplate = _.find(emailTemplates, {
      id: data.emailTemplateId,
    });

    if (!selectedJobStage || !selectedStageTemplate) {
      console.error(
        `Something went wrong, couldn't find the selected job stage or stage template`
      );
      return;
    }

    const res = await addGreenhouseStageTrigger({
      variables: {
        input: {
          atsJobStageId: selectedJobStage.id,
          guideTemplateId,
          stageTemplateId: selectedStageTemplate.id,
          emailTemplateId: selectedEmailTemplate?.id,
        },
      },
      refetchQueries: ["GuideTemplateForEditing"],
      awaitRefetchQueries: true,
    });

    onClose(res.data?.addGreenhouseStageTrigger.stageTemplateInstallation?.id);
  };

  const watchEmailTemplate = watch("emailTemplateId");
  const watchStageTemplate = watch("stageTemplateId");

  useEffect(() => {
    if (watchEmailTemplate === CreateNewEnum) {
      window.open("/templates/emails/?new=true", "_blank");
      analytics.track("Create New Email Template Clicked");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchEmailTemplate, reset]);

  useEffect(() => {
    if (watchStageTemplate === CreateNewEnum) {
      window.open("/templates/stages/?new=true", "_blank");
      analytics.track("Create New Stage Template Clicked");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchStageTemplate, reset]);

  return (
    <Dialog fullWidth open={open} onClose={onCancel}>
      {loading ? (
        <Loading />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle onClose={onCancel}>Add Stage to Journey</DialogTitle>
          <DialogContent>
            <div className={classes.formControlContainer}>
              <div className={cx(classes.iconContainer, classes.ghContainer)}>
                <img
                  src={ghIcon}
                  className={classes.icon}
                  alt="Greenhouse Logo"
                />
              </div>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="ats-job-stage">
                  Greenhouse Stage
                </InputLabel>
                <Controller
                  defaultValue={firstJobStageId}
                  as={
                    <Select
                      inputProps={{ id: "ats-job-stage" }}
                      variant="outlined"
                      className={classes.select}
                      label="Greenhouse Stage"
                      fullWidth
                      error={!!errors.atsJobStageId}
                    >
                      {_.map(availableJobStages, ({ id, name }) => (
                        <MenuItem key={id} value={id}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                  name="atsJobStageId"
                  rules={{ required: true }}
                  control={control}
                />
              </FormControl>
            </div>
            <Typography variant="body1" className={classes.subtext}>
              What content should we show?
            </Typography>
            <div className={classes.formControlContainer}>
              <div
                className={cx(classes.iconContainer, classes.guideContainer)}
              >
                <img
                  src={guideIcon}
                  className={classes.icon}
                  alt="Guide Logo"
                />
              </div>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="stage-template">Stage Template</InputLabel>
                <Controller
                  as={
                    <Select
                      inputProps={{ id: "stage-template" }}
                      className={classes.select}
                      label="Stage Template"
                      error={!!errors.stageTemplateId}
                    >
                      {_.map(availableStageTemplates, ({ id, name }) => (
                        <MenuItem key={id} value={id}>
                          {name}
                        </MenuItem>
                      ))}
                      <MenuItem key="create-new" value="create-new">
                        <strong>Create New Stage Template</strong>
                      </MenuItem>
                    </Select>
                  }
                  name="stageTemplateId"
                  rules={{
                    required: true,
                    validate: (f) => f !== CreateNewEnum,
                  }}
                  control={control}
                />
                <Link to="/templates/stages" target="_blank">
                  <Typography color="primary" variant="body1">
                    <span className={classes.link}>
                      View my Stage Templates
                    </span>{" "}
                    <FontAwesomeIcon size="1x" icon={faExternalLinkAlt} />
                  </Typography>
                </Link>
              </FormControl>
            </div>
            <Typography variant="body1" className={classes.subtext}>
              What email should we send? (optional)
            </Typography>
            <div className={classes.formControlContainer}>
              <div className={cx(classes.iconContainer, classes.sendContainer)}>
                <img
                  src={sendIcon}
                  className={classes.icon}
                  style={{ height: "60%" }}
                  alt="Send Email Action"
                />
              </div>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="email-template">Email Template</InputLabel>
                <Controller
                  as={
                    <Select
                      inputProps={{ id: "email-template" }}
                      variant="outlined"
                      className={classes.select}
                      label="Email Template"
                      fullWidth
                      error={!!errors.emailTemplateId}
                    >
                      {_.map(emailTemplates, ({ id, name }) => (
                        <MenuItem key={id} value={id}>
                          {name}
                        </MenuItem>
                      ))}
                      <MenuItem key="create-new" value="create-new">
                        <strong>Create New Email Template</strong>
                      </MenuItem>
                    </Select>
                  }
                  rules={{ validate: (f) => f !== CreateNewEnum }}
                  name="emailTemplateId"
                  control={control}
                />

                <Link to="/templates/emails" target="_blank">
                  <Typography color="primary" variant="body1">
                    <span className={classes.link}>
                      View my Email Templates
                    </span>{" "}
                    <FontAwesomeIcon size="1x" icon={faExternalLinkAlt} />
                  </Typography>
                </Link>
              </FormControl>
            </div>
          </DialogContent>
          <DialogActions>
            <Grid container>
              <Grid item xs={12} className={classes.actions}>
                {addingStageTrigger && <CircularProgress size="30px" />}
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={onCancel}
                  style={{ marginLeft: 10 }}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  disabled={addingStageTrigger}
                  variant="contained"
                  type="submit"
                  style={{ marginLeft: 10 }}
                >
                  <span>Add Stage to Journey</span>
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      )}
    </Dialog>
  );
};

export default ActivateStageTemplateInstallationModal;
