import { gql } from "@apollo/client";

const HASURA_GET_GUIDE_TEMPLATES_FOR_BULK_EDIT = gql`
  query GetGuideTemplatesForBulkEdit($guideTemplateIds: [uuid!]!) @hasura {
    guide_template(
      where: {
        id: { _in: $guideTemplateIds }
        job_role: { greenhouseJobStagesData: { _is_null: false } }
      }
    ) {
      id
      name
      stage_template_installations {
        id
        activatedAt
        atsStageId
      }
      job_role {
        id
        name
        atsJobId
        greenhouseJobStagesData
        department: greenhouseData(path: "$.departments.[0].name")
        office: greenhouseData(path: "$.offices.[0].name")
        reqId: greenhouseData(path: "$.requisition_id")
      }
      updatedAt
    }
  }
`;

export default HASURA_GET_GUIDE_TEMPLATES_FOR_BULK_EDIT;
