import gql from "graphql-tag";

const GET_ORG_EMAIL_TEMPLATES = gql`
  query GetOrgEmailTemplates {
    currentUserV2 {
      id
      organization {
        id
        emailTemplates {
          id
          name
          updatedAt
          createdBy
        }
      }
    }
  }
`;

export default GET_ORG_EMAIL_TEMPLATES;
