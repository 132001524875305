import React, { FunctionComponent, ReactNode } from "react";
import _ from "lodash";
import cx from "classnames";
import { Card, Link, Typography } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import {
  faHomeAlt,
  faCog,
  faGasPump,
  faSignOutAlt,
  faEnvelope,
  faCommentAltLines,
} from "@fortawesome/pro-light-svg-icons";
import { faRoute, faUsers } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RoleEnum, FeatureFlagEnum } from "@resource/common";
import { useFlags } from "@resource/client-ffs";
import TemplateIcon from "~/assets/icons/template-icon.tsx";
import logo from "~/assets/images/logo.svg";

import styles from "./Sidebar.module.scss";
import useAnalytics from "~/react-hooks/useAnalytics";

import { useAuth0 } from "~/react-auth0";
import { CurrentUser_currentUserV2 as CurrentUser } from "~/schemaTypes";

type SidebarProps = {
  currentUser?: CurrentUser | null;
};

type NavbarLink = {
  icon: ReactNode;
  url: string;
  text?: string;
  onClick?(): void;
  analyticsEvent: string;
  exact?: boolean;
  hide: () => boolean;
};

const Sidebar: FunctionComponent<SidebarProps> = ({ currentUser }) => {
  const [analytics] = useAnalytics();
  const auth0 = useAuth0();
  const {
    [_.camelCase(FeatureFlagEnum.FeedbackPage)]: FeedbackPageFlag,
    [_.camelCase(
      FeatureFlagEnum.GuideTemplatesEditor
    )]: guideTemplatesEditorFlag,
    [_.camelCase(FeatureFlagEnum.RoleJourneysPage)]: roleJourneysPageFlag,
  } = useFlags();

  const navLinks: NavbarLink[] = [
    {
      icon: (
        <FontAwesomeIcon className={styles.icon} icon={faHomeAlt} fixedWidth />
      ),
      url: "/",
      exact: true,
      text: "Activity",
      analyticsEvent: "NavBar Button Guides Clicked",
      hide: (): boolean => false,
    },
    ...(roleJourneysPageFlag
      ? [
          {
            icon: (
              <FontAwesomeIcon
                className={styles.icon}
                style={{ height: "32px", width: "32px" }}
                icon={faRoute}
                fixedWidth
              />
            ),
            url: "/journeys",
            text: "Journeys",
            analyticsEvent: "NavBar Button Journeys Clicked",
            hide: (): boolean => false,
          },
        ]
      : []),
    guideTemplatesEditorFlag
      ? {
          icon: <TemplateIcon className={styles.templatesIcon} />,
          url: "/templates",
          text: "Templates",
          analyticsEvent: "NavBar Button Templates Clicked",
          hide: (): boolean => false,
        }
      : {
          icon: <TemplateIcon className={styles.templatesIcon} />,
          url: "/templates",
          text: "Guide Templates",
          analyticsEvent: "NavBar Button Guide Templates Clicked",
          hide: (): boolean => false,
        },
    {
      icon: (
        <FontAwesomeIcon className={styles.icon} icon={faEnvelope} fixedWidth />
      ),
      url: "/email-templates",
      text: "Email Templates",
      analyticsEvent: "NavBar Button Email Templates Clicked",
      hide: (): boolean => guideTemplatesEditorFlag,
    },
    {
      icon: (
        <FontAwesomeIcon
          className={styles.icon}
          style={{ height: "32px", width: "32px" }}
          icon={faUsers}
          fixedWidth
        />
      ),
      url: "/interviewers",
      text: "Team",
      analyticsEvent: "Interviewers In NavBar Clicked",
      hide: (): boolean => false,
    },
    {
      icon: (
        <FontAwesomeIcon
          className={styles.icon}
          icon={faCommentAltLines}
          fixedWidth
        />
      ),
      url: "/feedback",
      text: "Feedback",
      analyticsEvent: "NavBar Button Feedback Clicked",
      hide: (): boolean => !FeedbackPageFlag,
    },
    {
      icon: (
        <FontAwesomeIcon className={styles.icon} icon={faGasPump} fixedWidth />
      ),
      url: "/co2",
      text: "CO2",
      analyticsEvent: "CO2 NBav Clicked",
      hide: (): boolean => !currentUser?.roles.includes(RoleEnum.Staff),
    },
  ];

  const bottomNavLinks: NavbarLink[] = [
    {
      icon: <FontAwesomeIcon className={styles.icon} icon={faCog} fixedWidth />,
      url: "/settings",
      text: "Settings",
      analyticsEvent: "NavBar Button Company Clicked",
      hide: (): boolean => false,
    },
    ...(currentUser
      ? [
          {
            icon: (
              <FontAwesomeIcon
                className={styles.icon}
                icon={faSignOutAlt}
                fixedWidth
              />
            ),
            url: "/logout",
            text: "Sign Out",
            analyticsEvent: "NavBar Button Logout Clicked",
            async onClick() {
              await auth0.logout();
            },
            hide: (): boolean => false,
          },
        ]
      : []),
  ];

  return (
    <Card className={styles.navbar}>
      <div className={styles.topNavSection}>
        <Link
          className={styles.navItem}
          component={NavLink}
          to="/"
          onClick={() => {
            if (analytics) {
              analytics.track("NavBar Button Resource Logo Clicked");
            }
          }}
        >
          <img
            className={styles.logo}
            src={logo}
            alt="Resource logo"
            title="Resource logo"
          />
        </Link>
        {navLinks.map(
          ({ icon, url, text, hide, exact = false, analyticsEvent }, index) =>
            hide() ? null : (
              <Link
                key={index}
                className={cx(styles.navItem)}
                activeClassName={styles.active}
                exact={exact}
                component={NavLink}
                to={url}
                onClick={() => {
                  if (analytics) {
                    analytics.track(analyticsEvent);
                  }
                }}
              >
                {icon}
                <Typography className={styles.text} variant="subtitle2">
                  {text}
                </Typography>
              </Link>
            )
        )}
      </div>
      <div className={styles.bottomNavSection}>
        {bottomNavLinks.map(
          (
            { icon, url, text, exact = false, hide, onClick, analyticsEvent },
            index
          ) =>
            hide() ? null : (
              <Link
                key={index}
                className={cx(styles.navItem, { [styles.noText]: !text })}
                activeClassName={styles.active}
                component={NavLink}
                exact={exact}
                to={url}
                onClick={(e: React.MouseEvent) => {
                  if (analytics) {
                    analytics.track(analyticsEvent);
                  }
                  if (onClick) {
                    e.preventDefault();
                    onClick();
                  }
                }}
              >
                {icon}
                {text && (
                  <Typography className={styles.text} variant="subtitle2">
                    {text}
                  </Typography>
                )}
              </Link>
            )
        )}
      </div>
    </Card>
  );
};

export default Sidebar;
