import React, { FunctionComponent } from "react";
import { Typography } from "@material-ui/core";
import styles from "./InterviewScheduler.module.scss";

type InterviewSchedulerProps = {
  stageId: string;
};

const InterviewScheduler: FunctionComponent<InterviewSchedulerProps> = () => {
  return (
    <div className={styles.container}>
      <Typography variant="h4">Interview Scheduler coming soon</Typography>
    </div>
  );
};

export default InterviewScheduler;
