import React, { FunctionComponent } from "react";

import styles from "./Image.module.scss";

type ImageProps = {
  imageUrl: string;
  imageAlt?: string;
};

const Image: FunctionComponent<ImageProps> = ({ imageUrl, imageAlt = "" }) => (
  <img className={styles.image} src={imageUrl} alt={imageAlt} />
);

export default Image;
