import _ from "lodash";
import React, { FunctionComponent, useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import EDIT_LOGISTICS_MUTATION from "./EDIT_LOGISTICS_MUTATION";
import LogisticsFormRow from "./LogisticsFormRow";
import { EditModulesGlobalProps } from "../EditModuleContainer";
import styles from "./logistics-form-row.module.scss";

export type Logistic = {
  id: string;
  emoji: string;
  title: string;
  description?: string;
  logisticGroupId: string;
};

export type LogisticGroup = {
  id: string;
  name: string;
  options: Logistic[];
};

export type LogisticGroupState = {
  logisticGroupId: string;
  selectedLogisticId?: string | null;
};

type EditLogisticsProps = {
  availableLogisticGroups: LogisticGroup[];
  customLogisticGroups: LogisticGroup[];
  logistics: Logistic[];
  header?: string;
  moduleId: string;
};

const EditLogistics: FunctionComponent<
  EditLogisticsProps & EditModulesGlobalProps
> = ({
  setHandleSaveMutation,
  setHandleSaveMutationInput,
  availableLogisticGroups,
  customLogisticGroups,
  logistics,
  header,
  moduleId,
}) => {
  // Simplify the custom groups, pulling the title from the single logistic for the group
  const mappedCustomGroups = _.map(customLogisticGroups, (group) => ({
    ...group,
    name: _.first(group.options)?.title || group.name,
  }));
  const allAvailableGroups = _.concat(
    availableLogisticGroups,
    mappedCustomGroups
  );

  const [headerOverride, setHeaderOverride] = useState<string>(
    header || "Logistics"
  );
  const [logisticGroupStateArray, setLogisticGroupStateArray] = useState<
    LogisticGroupState[]
  >(
    _.map(allAvailableGroups, (group) => ({
      logisticGroupId: group.id,
      selectedLogisticId: _.find(
        logistics,
        (l) => l.logisticGroupId === group.id
      )?.id,
    }))
  );

  setHandleSaveMutation(EDIT_LOGISTICS_MUTATION);

  useEffect(() => {
    setHandleSaveMutationInput({
      input: {
        logisticIds: _.compact(
          _.map(logisticGroupStateArray, "selectedLogisticId")
        ),
        header: headerOverride,
        moduleId,
      },
    });
  }, [
    logisticGroupStateArray,
    setHandleSaveMutationInput,
    moduleId,
    headerOverride,
  ]);

  return (
    <div className={styles.logisticCont}>
      <TextField
        fullWidth
        value={headerOverride}
        onChange={(e) => setHeaderOverride(e.target.value)}
        style={{ marginBottom: 6 }}
        label="Header"
      />
      {_.map(
        logisticGroupStateArray,
        ({ logisticGroupId: currentLogisticGroupId, selectedLogisticId }) => (
          <LogisticsFormRow
            key={currentLogisticGroupId}
            logisticGroup={
              _.find(allAvailableGroups, { id: currentLogisticGroupId })!
            }
            selectedLogisticId={selectedLogisticId}
            setSelectedLogisticId={(
              newSelectedLogisticId: string | null
            ): void => {
              const updatedState = _.map(
                logisticGroupStateArray,
                (groupState) => ({
                  logisticGroupId: groupState.logisticGroupId,
                  selectedLogisticId:
                    groupState.logisticGroupId === currentLogisticGroupId
                      ? newSelectedLogisticId
                      : groupState.selectedLogisticId,
                })
              );
              setLogisticGroupStateArray(updatedState);
            }}
          />
        )
      )}
    </div>
  );
};
export default EditLogistics;
