import cx from "classnames";
import React, { useEffect, useState } from "react";
import sharedStyles from "~/styles/EditorWithPreview.module.scss";
import styles from "./StageTemplateEditorContainer.module.scss";
import StageTemplateEditor from "~/components/StageTemplateEditor/StageTemplateEditor";
import ModulePresenter from "~/components/ModulePresenter/ModulePresenter";
import { useStageTemplate } from "./use-stage-template";
import { StageModule } from "~/utils/module-generators/types";
import generateModulesFromStageTemplate from "~/utils/module-generators/generate-modules-from-stage-template-modules";
import { InlineCompanyEditorContextProvider } from "~/pages/utils/InlineCompanyEditorContextProvider";

export type StageTemplateEditorContainerProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  match: any;
};

const StageTemplateEditorContainer: React.FC<StageTemplateEditorContainerProps> = ({
  match,
}) => {
  const { stageTemplate } = useStageTemplate({
    stageTemplateId: match.params.stageTemplateId,
  });
  const [modules, setModules] = useState<StageModule[]>([]);

  useEffect(() => {
    if (stageTemplate) {
      setModules(
        generateModulesFromStageTemplate({
          modules: stageTemplate.modules,
        })
      );
    }
  }, [setModules, stageTemplate]);

  return (
    <div className={sharedStyles.gridContainer}>
      <div className={cx(styles.editor, sharedStyles.editor)}>
        <InlineCompanyEditorContextProvider>
          <StageTemplateEditor stageTemplateId={match.params.stageTemplateId} />
        </InlineCompanyEditorContextProvider>
      </div>
      <div className={sharedStyles.preview}>
        <ModulePresenter modules={modules} createdFromATS />
      </div>
    </div>
  );
};

export default StageTemplateEditorContainer;
