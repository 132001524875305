import React, { FunctionComponent } from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { sanitizeHtml } from "~/utils";

import styles from "./Mission.module.scss";

const useStyles = makeStyles({
  mission: {
    "& a": {
      textDecoration: "underline",
    },
  },
});

type MissionProps = {
  header?: string;
  description?: string;
};

const Mission: FunctionComponent<MissionProps> = ({ header, description }) => {
  const classes = useStyles();
  return (
    <div className={styles.missionContainer}>
      {header && description && (
        <Typography
          style={{
            marginTop: "20px",
          }}
          variant="h4"
        >
          {header}
        </Typography>
      )}
      {description && (
        <Typography variant="body1">
          <span
            className={classes.mission}
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(description),
            }}
          />
        </Typography>
      )}
    </div>
  );
};

export default Mission;
