import _ from "lodash";
import React, { useReducer, Reducer } from "react";
import { makeStyles } from "@material-ui/core";
import ProgressStepper from "../ProgressStepper";
import Dialog from "~/components/Dialog/Dialog";
import DialogTitle from "~/components/Dialog/DialogTitle/DialogTitle";
import { ResourceCustomTheme } from "~/styles/config";
import {
  InterviewEditsStateType,
  InterviewEditsDispatchType,
  ActionEnum,
} from "./types";
import SelectInterviews from "./SelectInterviews";
import EditInterviews from "./EditInterviews";

import ConfirmInterviewEdits from "./ConfirmInterviewEdits";

// eslint-disable-next-line no-shadow
enum ProgressStepEnum {
  Cancel = -1,
  SetInterviews = 0,
  SetEdits = 1,
  ConfirmEdits = 2,
  CloseModal = 3,
}

const useStyles = makeStyles((_theme: ResourceCustomTheme) => ({
  root: {},
}));

type BulkEditInterviewKitsModalProps = {
  jobRoleIds: string[];
  open: boolean;
  onCancel: () => void;
  onClose: () => void;
  onExited: () => void;
};

const BulkEditInterviewKitsModal: React.FC<BulkEditInterviewKitsModalProps> = ({
  jobRoleIds,
  open,
  onCancel,
  onExited,
  onClose,
}) => {
  const classes = useStyles();

  const reducer = (
    state: InterviewEditsStateType,
    action: InterviewEditsDispatchType
  ): InterviewEditsStateType => {
    switch (action.type) {
      case ActionEnum.SetInterviews:
        return {
          ...state,
          interviewIds: action.interviewSelection?.ids ?? [],
          interviewName: action.interviewSelection?.name ?? "",
          numStages: action.interviewSelection?.numStages ?? 0,
        };
      case ActionEnum.SetEdits:
        return {
          ...state,
          ..._.omit(action, "type"),
        };
      case ActionEnum.Next:
        return {
          ...state,
          activeStepIndex: state.activeStepIndex + 1,
        };
      case ActionEnum.Back:
        return {
          ...state,
          activeStepIndex: state.activeStepIndex - 1,
        };
      case ActionEnum.Cancel:
        onCancel();
        return state;
      case ActionEnum.Finish:
        onClose();
        return state;
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer<
    Reducer<InterviewEditsStateType, InterviewEditsDispatchType>
  >(reducer, {
    interviewIds: [],
    interviewName: "",
    numStages: 0,
    titleOverride: "",
    descriptionOverride: "",
    hiddenOverride: false,
    activeStepIndex: 0,
  });

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      onExited={onExited}
      maxWidth="md"
      scroll="paper"
      title="Edit Interview Descriptions"
      fullWidth
      className={classes.root}
    >
      <DialogTitle onClose={onCancel}>
        <ProgressStepper
          steps={[
            "Select Interviews",
            "Edit Interview Descriptions",
            "Confirm Edits",
          ]}
          activeStep={state.activeStepIndex}
        />
      </DialogTitle>
      {(() => {
        let ToRender;
        switch (state.activeStepIndex) {
          case ProgressStepEnum.SetInterviews:
            ToRender = (
              <SelectInterviews dispatch={dispatch} jobRoleIds={jobRoleIds} />
            );
            break;
          case ProgressStepEnum.SetEdits:
            ToRender = <EditInterviews state={state} dispatch={dispatch} />;
            break;
          case ProgressStepEnum.ConfirmEdits:
            ToRender = (
              <ConfirmInterviewEdits state={state} dispatch={dispatch} />
            );
            break;

          default:
            break;
        }
        return <>{ToRender}</>;
      })()}
    </Dialog>
  );
};

export default BulkEditInterviewKitsModal;
