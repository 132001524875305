/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Route, RouteProps, Redirect, useLocation } from "react-router-dom";
import { CurrentUser_currentUserV2 as Auth } from "~/schemaTypes";
import { useFlashMessage } from "../FlashMessage/FlashMessage";

type UserProps = {
  auth?: Auth | null;
};

const PrivateRoute: React.FC<UserProps & RouteProps> = ({ auth, ...rest }) => {
  const { setContent } = useFlashMessage();
  const location = useLocation();

  if (!auth?.id) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { referrer: rest.location?.pathname },
        }}
      />
    );
  }
  if (
    auth &&
    !auth.onboardingComplete &&
    !location.pathname.startsWith("/settings/integrations")
  ) {
    setTimeout(() => {
      setContent({
        content: "A Greenhouse Harvest API Key is required to use Guide.",
        severity: "error",
      });
    }, 500);
    return (
      <Redirect
        to={{
          pathname: "/settings/integrations",
        }}
      />
    );
  }
  return <Route {...rest} />;
};

export default PrivateRoute;
