import React, { FunctionComponent, useState, useEffect } from "react";
import _ from "lodash";
import { Typography, TextField } from "@material-ui/core";
import { EditModulesGlobalProps } from "../EditModuleContainer";
import EDIT_LINKS_MUTATION from "./EDIT_LINKS_MUTATION";
import EditLinksForm, {
  DraftLinkItem,
} from "~/components/EditLinksForm/EditLinksForm";

type EditLinksProps = {
  links?: DraftLinkItem[];
  header?: string;
  moduleId: string;
};

const EditLinks: FunctionComponent<EditLinksProps & EditModulesGlobalProps> = ({
  setShouldSave,
  setHandleSaveMutation,
  setHandleSaveMutationInput,
  links: initialLinks,
  header,
  moduleId,
}) => {
  const [error, setError] = useState<string | undefined>();
  const [headerOverride, setHeaderOverride] = useState<string>(
    header || "More Info"
  );
  const [links, setLinks] = useState<DraftLinkItem[]>(initialLinks || []);

  setHandleSaveMutation(EDIT_LINKS_MUTATION);

  useEffect(() => {
    const shouldSave = () => {
      const validLinks = _.filter(links, (i) => !!(i.label && i.url));
      if (!links?.length) {
        setError(
          "Looks like you didn't add any links! Please remove this module from your Guide instead."
        );
        return false;
      }
      if (links.length && !validLinks.length) {
        setError("Please add a title and URL for each link");
        return false;
      }

      setError(undefined);
      return true;
    };
    if (setShouldSave) {
      setShouldSave(() => shouldSave);
    }
  }, [links, setShouldSave]);

  useEffect(() => {
    setHandleSaveMutationInput({
      input: {
        header: headerOverride,
        links,
        moduleId,
      },
    });
  }, [setHandleSaveMutationInput, moduleId, links, headerOverride]);

  return (
    <div>
      <TextField
        fullWidth
        value={headerOverride}
        onChange={(e) => setHeaderOverride(e.target.value)}
        style={{ marginBottom: 6 }}
        label="Header"
      />
      <EditLinksForm links={links} setLinks={setLinks} />
      {error && (
        <Typography variant="body1" color="error">
          {error}
        </Typography>
      )}
    </div>
  );
};

export default EditLinks;
