import _ from "lodash";
import React, { FunctionComponent } from "react";
import {
  Stepper,
  Step,
  StepButton,
  StepLabel,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/pro-light-svg-icons";

import useAnalytics from "~/react-hooks/useAnalytics";
import { FetchGuide_guide_stages } from "~/schemaTypes";
import { ResourceCustomTheme } from "~/styles/config";

type StageStepperProps = {
  stages: FetchGuide_guide_stages[];
  currentStageId: string;
  selectedStageId: string;
  onSelectStage(stageId: string): void;
};

const useStyles = makeStyles((theme: ResourceCustomTheme) => ({
  warning: {
    position: "relative",
    overflow: "inherit",
    "&:after": {
      animation: "3s infinite linear",
      position: "absolute",
      content: '""',
      top: "-10px",
      right: "-10px",
      height: "10px",
      width: "10px",
      borderRadius: "50%",
      background: theme.colors.BananaYellow,
    },
  },
}));

const StageStepper: FunctionComponent<StageStepperProps> = ({
  currentStageId,
  selectedStageId,
  onSelectStage,
  stages,
}) => {
  const classes = useStyles();
  const [analytics] = useAnalytics();
  const sortedStages = _(stages).sortBy("position").value();
  const selectedStageIndex = _.findIndex(sortedStages, { id: selectedStageId });
  const currentStageIndex = _.findIndex(sortedStages, { id: currentStageId });
  return (
    <Stepper activeStep={selectedStageIndex} nonLinear>
      {_.map(sortedStages, ({ id, needsFeedback, title }, index: number) => (
        <Tooltip
          key={id}
          title="Unlock this stage when you pass your previous interview!"
          disableFocusListener={index <= currentStageIndex}
          disableHoverListener={index <= currentStageIndex}
          disableTouchListener={index <= currentStageIndex}
        >
          <Step
            key={id}
            completed={index <= currentStageIndex}
            disabled={index > currentStageIndex}
          >
            <StepButton
              onClick={() => {
                if (analytics) {
                  analytics.track("Interview Process Stage Clicked", {
                    isCurrentStage: index === currentStageIndex,
                  });
                }
                onSelectStage(id);
              }}
            >
              <StepLabel
                classes={
                  needsFeedback
                    ? {
                        iconContainer: classes.warning,
                      }
                    : {}
                }
              >
                {index > currentStageIndex && (
                  <FontAwesomeIcon
                    icon={faLock}
                    style={{
                      marginRight: "5px",
                    }}
                    size="xs"
                  />
                )}
                {title}
              </StepLabel>
            </StepButton>
          </Step>
        </Tooltip>
      ))}
    </Stepper>
  );
};

export default StageStepper;
