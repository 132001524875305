import _ from "lodash";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { strings, replacePlaceholderValues } from "@resource/common";
import {
  Card,
  ThemeProvider,
  Fade,
  Typography,
  makeStyles,
  createStyles,
} from "@material-ui/core";

import CompanyLogo from "~/components/CompanyLogo";
import generateEvent from "~/utils/generators/generate-event";
import ModuleContainer from "~/components/modules/ModuleContainer/ModuleContainer";
import StageTemplateInstallationStepper from "~/components/StageTemplateInstallationStepper/StageTemplateInstallationStepper";
import { SampleGuideHeader } from "../ModulePresenterHeader/ModulePresenterHeader";
import generateModulesFromStageTemplate from "~/utils/module-generators/generate-modules-from-stage-template-modules";

import {
  GuideTemplateForEditing_guideTemplate_stageTemplateInstallations as StageTemplateInstallation,
  GuideTemplateForEditing_currentUserV2_organization as Organization,
} from "~/schemaTypes";
import {
  themeCreator,
  resourceRed,
  ResourceCustomTheme,
} from "~/styles/config";
import { defaultInterviewers } from "~/utils/module-generators/defaults";

const useStyles = makeStyles<ResourceCustomTheme>((theme) =>
  createStyles({
    root: {},
    centered: {
      display: "flex",
      alignContent: "center",
    },
    moduleCont: {
      maxWidth: 470,
      marginRight: "auto",
      marginLeft: "auto",
      marginTop: 50,
      minWidth: 420,
      marginBottom: "20%",
      "& span[data-id]": theme.mixins.placeholderStyles(theme),
    },
    card: {
      padding: "0px 25px",
      paddingBottom: 25,
      "&:not(:last-child)": {
        marginBottom: 20,
      },
    },
  })
);

type ModulePresenterProps = {
  onSelectInstallation(installation: StageTemplateInstallation): void;
  stageTemplateInstallations: StageTemplateInstallation[];
  organization: Organization;
  loading?: boolean;
  ModulePresenterHeader?: React.ReactNode | null;
  viewingStageTemplateInstallation: StageTemplateInstallation;
  roleNameOverride?: string;
  interviewProcessEnabled?: boolean;
};

const GuideTemplateModulePresenter: React.FC<ModulePresenterProps> = ({
  ModulePresenterHeader = <SampleGuideHeader />,
  roleNameOverride,
  onSelectInstallation,
  organization,
  stageTemplateInstallations,
  viewingStageTemplateInstallation,
  interviewProcessEnabled,
}) => {
  const classes = useStyles();

  const [fadeIn, setFadeIn] = useState<boolean>(true);

  const [
    currentlyViewingStageTemplateInstallation,
    setCurrentlyViewingStageTemplateInstallation,
  ] = useState<StageTemplateInstallation>(viewingStageTemplateInstallation);

  useEffect(() => {
    if (
      viewingStageTemplateInstallation.id !==
      currentlyViewingStageTemplateInstallation.id
    ) {
      setFadeIn(false);
      setTimeout(() => {
        setCurrentlyViewingStageTemplateInstallation(
          viewingStageTemplateInstallation
        );
        setFadeIn(true);
      }, 200);
    } else {
      setCurrentlyViewingStageTemplateInstallation(
        viewingStageTemplateInstallation
      );
    }
  }, [
    viewingStageTemplateInstallation,
    currentlyViewingStageTemplateInstallation,
    setCurrentlyViewingStageTemplateInstallation,
  ]);
  const startTime = moment().add(1, "day");
  const modules = currentlyViewingStageTemplateInstallation
    ? generateModulesFromStageTemplate({
        modules:
          currentlyViewingStageTemplateInstallation.stageTemplate.modules,
        overrides: {
          roleName: roleNameOverride,
          candidateName: "Richard",
          companyName: organization.name,
          events: _.map(
            _.sortBy(
              currentlyViewingStageTemplateInstallation.eventTemplates,
              "position"
            ),
            (template) => {
              const interviewers = _.sampleSize(
                defaultInterviewers,
                _.random(1, 2)
              );
              const allInterviewersFirstNames = _.chain(interviewers)
                .map("firstName")
                .thru(strings.joinWithFinalSeparator)
                .value();
              const allInterviewersFullNames = _.chain(interviewers)
                .map(({ firstName, lastName }) =>
                  strings.joinNames(firstName, lastName)
                )
                .thru(strings.joinWithFinalSeparator)
                .value();
              const replacements = {
                "all-interviewers-full-names": allInterviewersFullNames,
                "all-interviewers-first-names": allInterviewersFirstNames,
                "candidate-first-name": "Richard",
                "first-interviewer-first-name": interviewers[0].firstName,
                "first-interviewer-full-name": strings.joinNames(
                  interviewers[0].firstName,
                  interviewers[0].lastName
                ),
                "first-interviewer-title": interviewers[0].title,
              };
              const renderedTitle = replacePlaceholderValues(
                template.title,
                replacements
              );
              const renderedDescription = replacePlaceholderValues(
                template.description,
                replacements
              );
              return generateEvent({
                title: renderedTitle,
                description: renderedDescription,
                startTime: startTime.add(30, "minutes").toDate(),
                hidden: template.hidden,
                interviewers,
              });
            }
          ),
        },
      })
    : [];
  const { theme } = organization;

  return (
    <div className={classes.moduleCont}>
      {ModulePresenterHeader}
      <Card className={classes.card}>
        <CompanyLogo
          companyName={organization.name}
          companyLogoUrl={organization.companyLogoUrl || null}
        />
        {interviewProcessEnabled && (
          <ThemeProvider
            theme={themeCreator({
              primaryColorHex:
                theme && theme.primaryColor !== "#ffffff"
                  ? theme.primaryColor
                  : "#79728C",
              secondaryColorHex:
                theme && theme.secondaryColor !== "#ffffff"
                  ? theme.secondaryColor
                  : resourceRed,
            })}
          >
            <StageTemplateInstallationStepper
              onSelectInstallation={onSelectInstallation}
              selectedStageTemplateInstallation={
                currentlyViewingStageTemplateInstallation
              }
              stageTemplateInstallations={stageTemplateInstallations}
            />
          </ThemeProvider>
        )}
        {stageTemplateInstallations.length ? (
          <Fade in={fadeIn}>
            <div>
              {_.map(modules, (module, index: number) => (
                <ModuleContainer
                  key={`${module.id}-${index}`}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...{
                    ...module,
                    guideId: "fake-guide-id",
                    createdFromATS: true,
                    stageId: currentlyViewingStageTemplateInstallation.id,
                  }}
                />
              ))}
            </div>
          </Fade>
        ) : (
          <div className={classes.centered}>
            <Typography variant="h5">No stages visible.</Typography>
          </div>
        )}
      </Card>
    </div>
  );
};

export default GuideTemplateModulePresenter;
