import _ from "lodash";
import React from "react";
import { useQuery } from "@apollo/client";
import { Grid } from "@material-ui/core";

import { useFlags } from "@resource/client-ffs";
import { FeatureFlagEnum } from "@resource/common";
import { FetchAllCustomerIntegrations, IntegrationEnum } from "~/schemaTypes";
import Loading from "~/components/Loading/Loading";
import ALL_INTEGRATIONS_QUERY from "./ALL_INTEGRATIONS_QUERY";

import GreenhouseIntegrationCard from "../GreenhouseIntegration/GreenhouseIntegrationCard";
import SlackIntegrationCard from "../SlackIntegration/SlackIntegrationCard";
import ZoomIntegrationCard from "../ZoomIntegration/ZoomIntegrationCard";
import CoderPadIntegrationCard from "../CoderPadIntegration/CoderPadIntegrationCard";

const IntegrationMapping = {
  [IntegrationEnum.SLACK]: SlackIntegrationCard,
  [IntegrationEnum.ZOOM]: ZoomIntegrationCard,
  [IntegrationEnum.GREENHOUSE]: GreenhouseIntegrationCard,
  [IntegrationEnum.CODERPAD]: CoderPadIntegrationCard,
};

type IntegrationsProps = Record<string, never>;

const Integrations: React.FC<IntegrationsProps> = () => {
  const { loading: integrationsLoading, data: currentUser } = useQuery<
    FetchAllCustomerIntegrations
  >(ALL_INTEGRATIONS_QUERY);

  const {
    addTechScreenLink: coderPadIntegrationFlag,
    [_.camelCase(FeatureFlagEnum.AddZoomLink)]: zoomIntegrationFlag,
  } = useFlags();

  const integrations =
    currentUser?.currentUserV2?.organization.customer.integrations;
  const enabledIntegrations = _.filter(integrations, (integration) => {
    switch (integration.type) {
      case IntegrationEnum.SLACK:
        return true;
      case IntegrationEnum.GREENHOUSE:
        return true;
      case IntegrationEnum.ZOOM:
        return !!zoomIntegrationFlag;
      case IntegrationEnum.CODERPAD:
        return !!coderPadIntegrationFlag;
      default:
        return false;
    }
  });
  const IntegrationComponents = _(enabledIntegrations)
    .orderBy(["connected", "type"], ["desc", "asc"])
    .map((integration) => {
      const Integration: React.FC = () =>
        React.createElement(IntegrationMapping[integration.type], {
          integration,
        });
      return Integration;
    })
    .value();

  return integrationsLoading ? (
    <Loading />
  ) : (
    <>
      <Grid container spacing={2}>
        {_.map(IntegrationComponents, (IntegrationComponent, index) => (
          <Grid item xs={12} md={6} lg={3} key={index}>
            <IntegrationComponent />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Integrations;
