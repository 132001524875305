const BasePathEnum = {
  Home: "/",
  CO2: "/co2",
  Guide: "/guide",
  Templates: "/templates",
  Edit: "/edit",
  Settings: "/settings",
  Create: "/create",
  Send: "/send",
  Interviewers: "/interviewers",
  EmailTemplates: "/email-templates",
  Feedback: "/feedback",
};

export default BasePathEnum;
