import React, { FunctionComponent, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useQuery } from "@apollo/client";
import { useFlashMessage } from "~/components/FlashMessage/FlashMessage";
import GET_ORG_STAGE_TEMPLATES from "./GET_ORG_STAGE_TEMPLATES";
import {
  GetOrgStageTemplates,
  GetOrgStageTemplates_currentUserV2_organization_stageTemplates as StageTemplate,
} from "~/schemaTypes";

const useStyles = makeStyles({
  root: {},
  field: {
    width: 450,
    marginBottom: 20,
  },
});

type NewStageTemplateModalProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: ({
    copyFromStageTemplateId,
    name,
  }: {
    copyFromStageTemplateId?: string;
    name: string;
  }) => void;
};

const NewStageTemplateModal: FunctionComponent<NewStageTemplateModalProps> = ({
  open,
  onClose,
  onSubmit,
}) => {
  const { data, loading } = useQuery<GetOrgStageTemplates>(
    GET_ORG_STAGE_TEMPLATES
  );
  const classes = useStyles();
  const { setContent } = useFlashMessage();
  const [name, setName] = useState<string>("");
  const [
    selectedStageTemplate,
    setSelectedStageTemplate,
  ] = useState<StageTemplate | null>(null);

  const handleOnSubmit = () => {
    if (!name) {
      setContent({
        content: "Please provide a name for the new Stage Template",
        severity: "error",
      });
      return;
    }
    onSubmit({
      copyFromStageTemplateId: selectedStageTemplate?.id,
      name,
    });
  };

  const onClosePanel = () => {
    setName("");
    setSelectedStageTemplate(null);
    onClose();
  };

  const onChange = (
    _event: React.ChangeEvent<Record<string, unknown>>,
    value: StageTemplate | null | string
  ) => {
    if (typeof value === "string") {
      return;
    }
    const stageTemplate = value as StageTemplate | null;
    setSelectedStageTemplate(stageTemplate);
    if (stageTemplate) {
      setName(`Copy of ${stageTemplate.name}`);
    } else {
      setName("");
    }
  };

  if (loading) {
    return null;
  }

  const stageTemplates =
    data?.currentUserV2?.organization?.stageTemplates || [];

  return (
    <Dialog open={open} onClose={onClosePanel}>
      <DialogTitle disableTypography>
        <Typography variant="h4">New Stage Template</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Copy from an existing Stage Template?
        </DialogContentText>
        <Autocomplete
          className={classes.field}
          clearOnBlur
          freeSolo
          getOptionLabel={(stageTemplate: StageTemplate) => stageTemplate.name}
          onChange={onChange}
          options={stageTemplates}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search for a Stage Template"
              variant="outlined"
            />
          )}
        />
        <TextField
          className={classes.field}
          autoFocus
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="eg. Engineering Onsite Template"
          label="Stage Template Name"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClosePanel} color="primary">
          Cancel
        </Button>
        <Button
          disabled={!name}
          onClick={handleOnSubmit}
          color="primary"
          variant="contained"
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewStageTemplateModal;
