import _ from "lodash";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  useReactiveVar,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { GraphQLError } from "graphql";
import { onError } from "@apollo/client/link/error";
import { hasDirectives, getMainDefinition } from "@apollo/client/utilities";
import { WebSocketLink } from "@apollo/client/link/ws";
import React from "react";
import * as Sentry from "@sentry/react";

import { createUploadLink } from "apollo-upload-client";

import { Auth0Client } from "@auth0/auth0-spa-js";
import { useCurrentlyViewingGuide } from "./CurrentlyViewingGuideProvider";
import {
  getCurrentState,
  setFlashMessageContent,
} from "~/components/FlashMessage/FlashMessage";
import { cache, currentOrganizationId } from "~/cache";

const DEFAULT_MESSAGE =
  "Uh-oh. Something went wrong. Maybe try doing that again?";

// Set of operations we just ignore completely, and don't even show to users
// when error handling
const BLACKLISTED_OPERATIONS = ["GetLinkPreview"];
const BLACKLISTED_ERROR_CODES = ["NOT_FOUND"];
const SENTRY_BLACKLISTED_ERROR_CODES = ["UNAUTHENTICATED", "FORBIDDEN"];

const errorLink = onError(
  ({ response, operation, graphQLErrors, networkError }) => {
    if (BLACKLISTED_OPERATIONS.includes(operation.operationName)) {
      return;
    }

    // Just completely ignore some errors, like NOT_FOUND errors
    const filteredGraphQLErrors = _.reject(graphQLErrors, (graphQLError) => {
      return _.includes(
        BLACKLISTED_ERROR_CODES,
        graphQLError?.extensions?.code
      );
    });

    let message: string | undefined;
    if (filteredGraphQLErrors) {
      // Some errors we'll show to users, but not send to sentry, like auth stuff
      const sentryGraphQLErrors = _.reject(
        filteredGraphQLErrors,
        (graphQLError) => {
          return _.includes(
            SENTRY_BLACKLISTED_ERROR_CODES,
            graphQLError?.extensions?.code
          );
        }
      );
      Sentry.withScope((scope) => {
        scope.setTags({
          operationName: operation.operationName,
        });
        scope.setExtras({
          data: response?.data,
        });
        _.forEach(sentryGraphQLErrors, (error: GraphQLError) => {
          const finalError = _.isError(error)
            ? error
            : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              new Error(error.message!);
          _.assign(finalError, error);
          Sentry.captureException(finalError);
        });
      });
      message = _(filteredGraphQLErrors)
        .filter((error) => !!error.extensions)
        .map((error: GraphQLError) => {
          switch (error.extensions!.code) {
            case "GRAPHQL_VALIDATION_FAILED":
              return "Input was not valid";
            case "UNAUTHENTICATED":
              return "You have to be logged in to do that";
            case "FORBIDDEN":
              return "You don't have permission to do that";
            case "BAD_USER_INPUT":
              return "That input didn't seem to be valid";
            case "PASSTHROUGH":
              return error.message;
            default:
              return DEFAULT_MESSAGE;
          }
        })
        .join("\n");
    } else if (networkError) {
      Sentry.captureException(networkError);
      message = DEFAULT_MESSAGE;
    }
    const { content, isOpen, severity } = getCurrentState();
    // Only Show these errors if there isn't already an error message being shown
    if (
      message &&
      !(content && _.includes(message, content)) &&
      (!isOpen || severity !== "error")
    ) {
      setFlashMessageContent({
        content: message,
        severity: "error",
      });
    }
  }
);

type AuthorizedApolloProviderProps = {
  auth0Client: Auth0Client;
  children: React.ReactElement;
};

const AuthorizedApolloProvider: React.FC<AuthorizedApolloProviderProps> = ({
  auth0Client,
  children,
}) => {
  const currentOrgId = useReactiveVar(currentOrganizationId);
  const { currentlyViewingGuideId } = useCurrentlyViewingGuide();

  const authLink = setContext(async () => {
    let token: string | undefined;
    if (auth0Client && (await auth0Client.isAuthenticated())) {
      try {
        token = await auth0Client.getTokenSilently();
      } catch (err) {
        Sentry.captureException(err);
      }
    }
    return {
      headers: {
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
        ...(currentlyViewingGuideId
          ? {
              "x-hasura-guide-id": currentlyViewingGuideId,
            }
          : {}),
        ...(currentOrgId ? { "x-current-organization-id": currentOrgId } : {}),
      },
    };
  });

  const uploadHttpLink = createUploadLink({
    uri: process.env.REACT_APP_GRAPHQL_API_URL,
    credentials: "include",
  });

  const hasuraHttpLink = createUploadLink({
    uri: process.env.REACT_APP_HASURA_HTTP_URL,
    credentials: "include",
  });

  const websocketLink = new WebSocketLink({
    uri: process.env.REACT_APP_HASURA_WS_URL,
    options: {
      lazy: true,
      reconnect: true,
      timeout: 30000,
      connectionParams: async () => {
        let token: string | undefined;
        if (auth0Client && (await auth0Client.isAuthenticated())) {
          try {
            token = await auth0Client.getTokenSilently();
          } catch (err) {
            Sentry.captureException(err);
          }
        }

        return {
          headers: {
            ...(token ? { Authorization: `Bearer ${token}` } : {}),
            ...(currentlyViewingGuideId
              ? {
                  "x-hasura-guide-id": currentlyViewingGuideId,
                }
              : {}),
            ...(currentOrgId
              ? { "x-current-organization-id": currentOrgId }
              : {}),
          },
        };
      },
    },
  });

  const apolloClient = new ApolloClient({
    name: "42-web",
    version: process.env.REACT_APP_COMMIT_REF,
    link: ApolloLink.from([
      errorLink,
      authLink,
      ApolloLink.split(
        ({ query }) => hasDirectives(["hasura"], query),
        ApolloLink.split(
          // split based on operation type
          ({ query }) => {
            const definition = getMainDefinition(query);
            const { kind, operation } = definition as {
              kind: string;
              operation?: string;
            };
            return (
              kind === "OperationDefinition" && operation === "subscription"
            );
          },
          websocketLink,
          hasuraHttpLink
        ),
        uploadHttpLink
      ),
    ]),
    cache,
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default AuthorizedApolloProvider;
