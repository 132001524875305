import React, { FunctionComponent } from "react";
import _ from "lodash";
import {
  Typography,
  Card,
  IconButton,
  Tooltip,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import cx from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFlags } from "@resource/client-ffs";
import {
  faPencil,
  faTrash,
  faGripLinesVertical,
} from "@fortawesome/pro-light-svg-icons";

import {
  FeatureFlagEnum,
  ModuleEnum,
  RemovableModules,
} from "@resource/common";
import { GlobalEditModuleProps } from "~/components/types";
import ModuleDisplayInfo from "~/components/utils/module-display-info";

import styles from "./EditModuleCard.module.scss";
import useAnalytics from "~/react-hooks/useAnalytics";
import { ResourceCustomTheme } from "~/styles/config";

const useStyles = makeStyles<ResourceCustomTheme>((theme) => ({
  light: {
    color: theme.colors.KarlGray(800),
  },
}));

const CustomTooltip = withStyles(() => ({
  tooltip: {
    maxWidth: "150px",
  },
}))(Tooltip);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DataType = Record<string, any>;

type ModuleProps = {
  id: string;
  type: ModuleEnum;
  data: DataType;
  isDraggable?: boolean;
  isBeingDragged?: boolean;
  highlighted: boolean;
  canEdit: boolean;
};

const EditModuleCard: FunctionComponent<
  GlobalEditModuleProps & ModuleProps
> = ({
  setEditModuleOpen,
  setSelectedModule,
  removeModule,
  id,
  type,
  data,
  isDraggable,
  isBeingDragged,
  highlighted = false,
  canEdit,
}) => {
  const classes = useStyles();
  const enumType: ModuleEnum = ModuleEnum[type];
  const [analytics] = useAnalytics();
  const displayInfo = ModuleDisplayInfo[type];
  const {
    [_.camelCase(
      FeatureFlagEnum.AllowRemoveAllStageTemplateModules
    )]: allowRemoveAllStageTemplateModulesFlag,
  } = useFlags();

  const deleteDisabled =
    !allowRemoveAllStageTemplateModulesFlag &&
    !RemovableModules.includes(enumType);

  const handleEditButtonClick = (): void => {
    analytics.track("Edit Module Button Clicked", {
      moduleType: type,
    });

    setSelectedModule({
      id,
      type,
      data,
    });
    setEditModuleOpen(true);
  };
  return (
    <Card
      className={cx(styles.moduleCardContainer, {
        [styles.highlighted]: highlighted,
      })}
      elevation={1}
      style={{
        transform: isBeingDragged ? "rotate(-2deg)" : "none",
      }}
    >
      <div className={cx(styles.dragHeader)}>
        {isDraggable && (
          <FontAwesomeIcon
            icon={faGripLinesVertical}
            style={{ marginRight: "10px" }}
            size="2x"
          />
        )}
        <Typography>
          {displayInfo?.emoji} {displayInfo?.header}{" "}
          <span className={classes.light}>
            {data?.header && data?.header !== displayInfo?.header
              ? `(${data.header})`
              : ""}
          </span>
        </Typography>
      </div>
      <div className={styles.controlsCont}>
        <CustomTooltip
          disableFocusListener={canEdit}
          disableHoverListener={canEdit}
          disableTouchListener={canEdit}
          title="This component is customized each time you send a Guide"
        >
          <div>
            <IconButton
              className={styles.controlsButton}
              disabled={!canEdit}
              onClick={handleEditButtonClick}
            >
              <FontAwesomeIcon
                className={styles.controlsIcon}
                icon={faPencil}
              />
            </IconButton>
          </div>
        </CustomTooltip>
        <CustomTooltip
          disableHoverListener={!deleteDisabled}
          disableFocusListener={!deleteDisabled}
          disableTouchListener={!deleteDisabled}
          title="Essential Guide components are not removable"
        >
          <div>
            <IconButton
              className={styles.controlsButton}
              onClick={(): Promise<void> => removeModule(id, type)}
              disabled={deleteDisabled}
            >
              <FontAwesomeIcon
                className={cx(styles.controlsIcon, styles.delete)}
                icon={faTrash}
              />
            </IconButton>
          </div>
        </CustomTooltip>
      </div>
    </Card>
  );
};

export default EditModuleCard;
