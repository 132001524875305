import gql from "graphql-tag";

export default gql`
  fragment InterviewerDetailsFragment on InterviewerV2 {
    id
    firstName
    lastName
    title
    linkedInUrl
    websiteUrls
    imageUrl
    biography
    atsUserId
    slackUserUserId
  }
`;
