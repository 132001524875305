import React, { ReactNode } from "react";
import { Typography } from "@material-ui/core";
import styles from "./description-divider.module.scss";

const DescriptionDivider = ({
  text,
}: {
  text?: ReactNode | null;
}): JSX.Element => (
  <div className={styles.descriptionWrapper}>
    {text && <Typography className={styles.description}>{text}</Typography>}
  </div>
);

export default DescriptionDivider;
