import React from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

import Dialog from "~/components/Dialog/Dialog";

type DesctructiveActionPromptProps = {
  onAccept: () => void;
  open: boolean;
  onClose: () => void;
  bodyText: string;
  cancelLabel?: string;
  titleLabel?: string;
  confirmLabel?: string;
};
const PromptForUnsavedChanges: React.FC<DesctructiveActionPromptProps> = ({
  open,
  onClose,
  onAccept,
  cancelLabel = "Cancel",
  confirmLabel = "Confirm",
  titleLabel = "Are you sure?",
  bodyText,
}) => {
  return (
    <Dialog open={open} closeButton={false}>
      <DialogTitle>{titleLabel}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {bodyText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" autoFocus color="primary">
          {cancelLabel}
        </Button>
        <Button onClick={onAccept} variant="contained" color="secondary">
          {confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PromptForUnsavedChanges;
