import React, { Suspense } from "react";
import { retry, STAGE_PLACEHOLDERS } from "@resource/common";
import Loading from "~/components/Loading/Loading";
import useAnalytics from "~/react-hooks/useAnalytics";

const RichTextEditor = React.lazy(() =>
  retry(() => import("~/components/RichTextEditor/RichTextEditor"))
);

type EditRichTextEditorProps = {
  text: string;
  setText: (text: string) => void;
};

const EditRichTextEditor: React.FC<EditRichTextEditorProps> = ({
  text,
  setText,
}) => {
  const [analytics] = useAnalytics();
  return (
    <div>
      <Suspense fallback={<Loading />}>
        <RichTextEditor
          onBlur={(): void => {
            analytics.track("Rich Text Edited");
          }}
          placeholder="Add the schedule or anything else useful for the candidate"
          placeholders={STAGE_PLACEHOLDERS}
          value={text}
          onChange={setText}
        />
      </Suspense>
    </div>
  );
};

export default EditRichTextEditor;
