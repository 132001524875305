/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Suspense, useContext } from "react";
import _ from "lodash";
import { TextField } from "@material-ui/core";
import AsyncSelect from "react-select/async";
import cx from "classnames";

import { LocationTypeEnum, retry } from "@resource/common";
import { google } from "google-maps";

import { ValueType } from "react-select/src/types";
import Loading from "~/components/Loading/Loading";
import styles from "./location-form.module.scss";
import { AnalyticsContext } from "../../analytics";
import { getStaticMapsUrl, ImageSize, ZoomLevel } from "~/utils/image-utils";

const RichTextEditor = React.lazy(() =>
  retry(() => import("~/components/RichTextEditor/RichTextEditor"))
);

const asyncSelectStyles = {
  indicatorSeparator: (): any => {},
  // indicatorSeparator: () => null, // removes the "stick"
  dropdownIndicator: (defaultStyles: any): any => ({
    ...defaultStyles,
    display: "none", // your changes to the arrow
  }),
  container: (defaultStyles: any): any => ({
    ...defaultStyles,
    margin: "20px 0px 20px 0px",
  }),
  valueContainer: (defaultStyles: any): any => ({
    ...defaultStyles,
    padding: "10px 10px 10px 10px",
    fontSize: "16px",
  }),
};

export type EditLocationFormState = {
  name: string;
  arrivalInstructionsQuill: string;
  coordinates: string;
  address: string;
};

type LocationFormProps = {
  formState: EditLocationFormState;
  setFormState: React.Dispatch<React.SetStateAction<EditLocationFormState>>;
};

type GoogleAutocompletePrediction = {
  value: google.maps.places.QueryAutocompletePrediction;
  label: string;
};

interface LoadOptionsCallback {
  (options: GoogleAutocompletePrediction[]): void;
}

const loadOptions = (inputValue: string, cb: LoadOptionsCallback): void => {
  const service = new window.google.maps.places.AutocompleteService() as google.maps.places.AutocompleteService;
  service.getQueryPredictions({ input: inputValue }, (res) => {
    const options = _.map(res, (prediction) => ({
      value: prediction,
      label: prediction.description,
    }));
    cb(options);
  });
};

const coordinatesFromPlaceResult = (
  addy: google.maps.places.PlaceResult
): string | null => {
  const lat = addy.geometry?.location.lat;
  const lng = addy.geometry?.location.lng;
  return lat && lng ? `${lat()},${lng()}` : null;
};

const LocationForm: React.FC<LocationFormProps> = ({
  formState,
  setFormState,
}) => {
  const analytics = useContext(AnalyticsContext);
  const isRemote = formState.name === LocationTypeEnum.REMOTE;
  const arrivalInstructionsPlaceholder = isRemote
    ? "Any special instructions for having a smooth remote experience?"
    : "Any special instructions for getting here smoothly?";

  return (
    <>
      {!isRemote && (
        <TextField
          label="Title"
          variant="outlined"
          placeholder="Location name"
          value={formState.name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
            const { value: name } = e.target;
            setFormState((prevFormState: EditLocationFormState) => ({
              ...prevFormState,
              name,
            }));
          }}
          fullWidth
        />
      )}
      {!isRemote && (
        <>
          <AsyncSelect
            styles={asyncSelectStyles}
            placeholder={formState.address || "Address"}
            onChange={(val: ValueType<GoogleAutocompletePrediction>): void => {
              analytics.track("Address Changed");
              const service = new window.google.maps.places.PlacesService(
                document.createElement("div")
              ) as google.maps.places.PlacesService;
              if ((val as GoogleAutocompletePrediction).value) {
                const placeId = (val as GoogleAutocompletePrediction).value
                  .place_id;
                service.getDetails(
                  { placeId },
                  (res: google.maps.places.PlaceResult): void => {
                    "";

                    setFormState((prevFormState: EditLocationFormState) => ({
                      ...prevFormState,
                      address: res.formatted_address || "",
                      coordinates: coordinatesFromPlaceResult(res) || "",
                    }));
                  }
                );
              }
            }}
            loadOptions={loadOptions}
          />
        </>
      )}
      {formState.coordinates && (
        <img
          src={getStaticMapsUrl(
            formState.coordinates?.split(",") || [],
            ImageSize.Landscape,
            ZoomLevel.CloseRange
          )}
          className={cx(styles.map)}
          alt="Map"
        />
      )}
      <Suspense fallback={<Loading />}>
        <div
          className={cx(styles.quillEditorContainer)}
          onBlur={() => {
            analytics.track("Arrival Instructions Edited");
          }}
        >
          <RichTextEditor
            toolbar="top"
            label="Special Instructions"
            placeholder={arrivalInstructionsPlaceholder}
            value={formState.arrivalInstructionsQuill}
            onChange={(html: string): void => {
              setFormState((prevFormState) => ({
                ...prevFormState,
                arrivalInstructionsQuill: html,
              }));
            }}
          />
        </div>
      </Suspense>
    </>
  );
};

export default LocationForm;
