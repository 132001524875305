import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import cx from "classnames";

type DropzoneProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUpload: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rootProps: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  InnerComponent: any;
  dragActiveClassName: string;
  className: string;
  accept?: string | string[]; // list of file types to reject
  maxSize?: number;
  multiple?: boolean;
};

const Dropzone = ({
  onUpload,
  rootProps,
  InnerComponent,
  className,
  dragActiveClassName,
  accept,
  maxSize,
  multiple,
}: DropzoneProps) => {
  const onDrop = useCallback(
    async (acceptedFiles, rejectedFiles) => {
      await onUpload(acceptedFiles, rejectedFiles);
    },
    [onUpload]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    maxSize,
    multiple,
  });

  return (
    <div
      className={cx(className, {
        [dragActiveClassName]: isDragActive,
      })}
      {...rootProps}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <InnerComponent />
    </div>
  );
};
export default Dropzone;
