import React from "react";
import { Dialog } from "@material-ui/core";
import Loading from "../Loading/Loading";

const LoadingModal = ({ open }: { open: boolean }) => (
  <Dialog open={open} fullScreen>
    <Loading />
  </Dialog>
);

export default LoadingModal;
