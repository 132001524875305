/* eslint-disable react/jsx-props-no-spreading */
import React, { FunctionComponent, Suspense } from "react";

import { ModuleDisplayTypeEnum, retry } from "@resource/common";
import {
  InfoChips,
  InfoCards,
  PhoneScreenHeader,
  ScheduleExpanded,
  Mission,
  HeaderGreeting,
  Time,
  Location,
  Schedule,
  Links,
  Media,
  Feedback,
  InterviewScheduler,
  InterviewTeam,
  RichText,
} from "~/components/modules";

const StreamChat = React.lazy(() =>
  retry(() => import("~/components/modules/StreamChat/StreamChat"))
);

interface ModuleContainerProps {
  id: string;
  displayType: string;
  stageId: string;
  guideId: string;
  createdFromATS: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
}

const ModuleContainer: FunctionComponent<ModuleContainerProps> = ({
  id,
  displayType,
  createdFromATS,
  guideId,
  stageId,
  data,
}) => {
  let module;
  if (data.disabled) {
    return null;
  }

  switch (displayType) {
    case ModuleDisplayTypeEnum.InfoChips:
      module = <InfoChips key={id} {...data} />;
      break;

    case ModuleDisplayTypeEnum.InfoCards:
      module = <InfoCards key={id} {...data} />;
      break;

    case ModuleDisplayTypeEnum.PhoneScreenHeader:
      module = <PhoneScreenHeader key={id} {...data} />;
      break;

    case ModuleDisplayTypeEnum.ScheduleExpanded:
      module = <ScheduleExpanded key={id} {...data} />;
      break;

    case ModuleDisplayTypeEnum.Mission:
      module = <Mission key={id} {...data} />;
      break;

    case ModuleDisplayTypeEnum.GreetingHeader:
      module = <HeaderGreeting key={id} {...data} />;
      break;

    case ModuleDisplayTypeEnum.Time:
      module = <Time key={id} guideId={guideId} {...data} />;
      break;

    case ModuleDisplayTypeEnum.Location:
      module = <Location key={id} {...data} />;
      break;

    case ModuleDisplayTypeEnum.Schedule:
      module = createdFromATS ? <Schedule key={id} {...data} /> : <div />;
      break;

    case ModuleDisplayTypeEnum.Links:
      module = <Links key={id} {...data} />;
      break;
    case ModuleDisplayTypeEnum.InterviewTeam:
      module = <InterviewTeam key={id} {...data} />;
      break;

    case ModuleDisplayTypeEnum.TeamPhoto:
      module = (
        <Media
          key={id}
          {...{
            ...data,
            mediaLink: data.photoUrl,
          }}
        />
      );
      break;

    case ModuleDisplayTypeEnum.Media:
      module = <Media key={id} {...data} />;
      break;

    case ModuleDisplayTypeEnum.CandidatePostInterviewFeedback:
      module = <Feedback key={id} stageId={stageId} {...data} />;
      break;

    case ModuleDisplayTypeEnum.Chat:
      module = <StreamChat key={id} guideId={guideId} {...data} />;
      break;

    case ModuleDisplayTypeEnum.InterviewScheduler:
      module = <InterviewScheduler key={id} guideId={guideId} {...data} />;
      break;

    case ModuleDisplayTypeEnum.RichText:
      module = <RichText key={id} {...data} />;
      break;

    default:
      module = <div />;
      break;
  }

  return <Suspense fallback={<div />}>{module}</Suspense>;
};

export default ModuleContainer;
