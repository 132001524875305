import { gql } from "@apollo/client";

const HASURA_GET_EMAIL_TEMPLATE_CONTENT = gql`
  query GetEmailTemplateContent($id: uuid) @hasura {
    email_template_v2(where: { id: { _eq: $id } }) {
      id
      name
      subject
      body
    }
  }
`;

export default HASURA_GET_EMAIL_TEMPLATE_CONTENT;
