import React, { useEffect, useState, useMemo } from "react";
import _ from "lodash";
import {
  Typography,
  Tooltip,
  makeStyles,
  Checkbox,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import cx from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-light-svg-icons";
import { useAsyncDebounce } from "react-table";
import { GetGuideTemplatesForBulkEdit_guide_template as GuideTemplate } from "~/schemaTypesHasura";
import ghIcon from "~/assets/icons/gh-icon-white.svg";
import { GreenhouseJobStage, BulkStageEditMode } from "./types";
import { ResourceCustomTheme } from "~/styles/config";

const useStyles = makeStyles((theme: ResourceCustomTheme) => ({
  root: {},
  stages: {},
  stage: {
    display: "flex",
    cursor: "pointer",
    marginLeft: 20,
    alignItems: "center",
    "&:focus": {
      outline: "none",
    },
  },
  searchIcon: {
    searchIcon: {
      color: theme.colors.KarlGray(900),
    },
  },
  selectAll: {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    marginTop: theme.spacing(2),
    "&:focus": {
      outline: "none",
    },
  },
  role: {
    display: "flex",
    alignItems: "center",
    "&:focus": {
      outline: "none",
    },
  },
  iconContainer: {
    flexGrow: 0,
    flexShrink: 0,
    borderRadius: 4,
    marginRight: theme.spacing(1),
    width: 18,
    height: 18,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    height: "70%",
  },
  ghContainer: {
    backgroundColor: theme.colors.GreenhouseGreen,
  },
  roleContainer: {
    marginLeft: 12,
  },
  disabled: {
    color: theme.colors.MidnightBlue(200),
    cursor: "default",
  },
  contextLabel: {
    fontSize: 14,
    color: theme.colors.MidnightBlue(300),
    marginLeft: 14,
    marginBottom: theme.spacing(1),
  },
  checkboxDisabled: {
    cursor: "default",
    color: theme.colors.MidnightBlue(200),
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

const StageNameFilter = ({ stageNameFilter, setStageNameFilter }) => {
  const styles = useStyles();
  const [showInputAdornment, setShowInputAdornment] = useState(
    !stageNameFilter
  );
  const setAsyncSetStageNameFilter = useAsyncDebounce(setStageNameFilter, 300);
  return (
    <TextField
      fullWidth
      type="search"
      defaultValue={stageNameFilter || ""}
      onChange={(e) => {
        setAsyncSetStageNameFilter(e.target.value || "");
        setShowInputAdornment(!e.target.value);
      }}
      InputProps={
        showInputAdornment
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <FontAwesomeIcon
                    icon={faSearch}
                    size="sm"
                    className={styles.searchIcon}
                  />
                </InputAdornment>
              ),
            }
          : {}
      }
      placeholder="Filter by Greenhouse stage name"
    />
  );
};

type JobsStagePickerProps = {
  selectedStageIds: string[];
  setSelectedStageIds(stageIds: string[]): void;
  guideTemplates: GuideTemplate[];
  mode: BulkStageEditMode;
};

type GreenhouseJobStageWithGuideConfiguration = GreenhouseJobStage & {
  stageConfiguredInGuide: boolean;
};

type MappedGuideTemplate = {
  id: string;
  name: string;
  greenhouseStages: GreenhouseJobStageWithGuideConfiguration[];
};

const isAvailable = (
  stage: GreenhouseJobStageWithGuideConfiguration,
  mode: BulkStageEditMode
): boolean => {
  switch (mode) {
    case BulkStageEditMode.Add:
      return !stage.stageConfiguredInGuide;
    case BulkStageEditMode.Remove:
      return !!stage.stageConfiguredInGuide;
    case BulkStageEditMode.Edit:
      return !!stage.stageConfiguredInGuide;
    default:
      return true;
  }
};

const JobsStagePicker: React.FC<JobsStagePickerProps> = ({
  selectedStageIds,
  setSelectedStageIds,
  guideTemplates,
  mode,
}) => {
  const classes = useStyles();
  const [filteredStages, setFilteredStages] = useState<
    GreenhouseJobStageWithGuideConfiguration[]
  >();
  const [stageNameFilter, setStageNameFilter] = useState("");

  const guideTemplatesMapped: MappedGuideTemplate[] = useMemo(() => {
    return _.map(guideTemplates, (guideTemplate) => {
      const stages = (guideTemplate.job_role?.greenhouseJobStagesData ||
        []) as GreenhouseJobStage[];

      const mappedStages = _.map(stages, (stage) => {
        return {
          ...stage,
          stageConfiguredInGuide: !!_.find(
            guideTemplate.stage_template_installations,
            (i) => i.atsStageId === stage.id && !!i.activatedAt
          ),
        };
      });

      return {
        id: guideTemplate.id,
        name: guideTemplate.job_role?.name ?? guideTemplate.name,
        greenhouseStages: mappedStages,
      };
    });
  }, [guideTemplates]);

  const allStages = useMemo(
    () =>
      (_.chain(guideTemplatesMapped)
        .map("greenhouseStages")
        .compact()
        .flatten()
        .value() as unknown) as GreenhouseJobStageWithGuideConfiguration[],
    [guideTemplatesMapped]
  );

  const allAvailableStages = useMemo(
    () => _.filter(allStages, (stage) => isAvailable(stage, mode)),
    [allStages, mode]
  );

  const filteredAvailableStages = useMemo(
    () =>
      filteredStages
        ? _.filter(filteredStages, (stage) => isAvailable(stage, mode))
        : undefined,
    [filteredStages, mode]
  );

  const onSelectStage = (stage) => {
    setSelectedStageIds([...selectedStageIds, stage.id]);
  };
  const onDeselectStage = (stage) => {
    setSelectedStageIds(_.without(selectedStageIds, stage.id));
  };

  const onSelectAll = () => {
    const stagesToSelect = filteredAvailableStages ?? allAvailableStages ?? [];
    setSelectedStageIds(_.union(selectedStageIds, _.map(stagesToSelect, "id")));
  };

  const onDeselectAll = () => {
    const stagesToDeselect =
      filteredAvailableStages ?? allAvailableStages ?? [];
    setSelectedStageIds(
      _.difference(selectedStageIds, _.map(stagesToDeselect, "id"))
    );
  };

  useEffect(() => {
    if (stageNameFilter) {
      setFilteredStages(
        _.filter(allStages, (stage) => {
          return _.includes(
            (stage.name ?? "").toLowerCase(),
            stageNameFilter.toLowerCase()
          );
        })
      );
    } else {
      setFilteredStages(undefined);
    }
  }, [allStages, stageNameFilter]);

  const [allStagesSelected, allFilteredStagesSelected] = useMemo(() => {
    const visible =
      _.intersection(_.map(allAvailableStages, "id"), selectedStageIds)
        .length === allAvailableStages.length;

    const filtered =
      _.intersection(_.map(filteredAvailableStages, "id"), selectedStageIds)
        .length === filteredAvailableStages?.length;

    return [visible, filtered];
  }, [allAvailableStages, filteredAvailableStages, selectedStageIds]);

  const toggleSelectAll = () => {
    if (filteredStages ? allFilteredStagesSelected : allStagesSelected) {
      onDeselectAll();
    } else {
      onSelectAll();
    }
  };
  let tooltipLabel = "";
  switch (mode) {
    case BulkStageEditMode.Add:
      tooltipLabel = "This stage has not been configured yet";
      break;
    case BulkStageEditMode.Edit:
      tooltipLabel = "Only configured stages can be edited";
      break;
    case BulkStageEditMode.Remove:
      tooltipLabel = "Only configured stages can be removed";
      break;
    default:
      break;
  }

  return (
    <div className={classes.root}>
      <StageNameFilter
        stageNameFilter={stageNameFilter}
        setStageNameFilter={setStageNameFilter}
      />
      <div
        role="button"
        tabIndex={0}
        className={cx(classes.selectAll, {
          [classes.disabled]: !allAvailableStages.length,
        })}
        onKeyUp={toggleSelectAll}
        onClick={toggleSelectAll}
      >
        <Checkbox
          color="primary"
          disabled={!allAvailableStages.length}
          onChange={toggleSelectAll}
          indeterminate={
            !!selectedStageIds.length &&
            selectedStageIds.length !== allAvailableStages.length
          }
          checked={allStagesSelected && !!allAvailableStages.length}
        />
        <Typography
          variant="body1"
          className={cx({
            [classes.disabled]: !allAvailableStages.length,
          })}
        >
          <strong>Select All</strong>
        </Typography>
      </div>
      {_.map(guideTemplatesMapped, (guideTemplate) => {
        const filtered = filteredStages
          ? _.intersectionBy(
              guideTemplate.greenhouseStages,
              filteredStages,
              "id"
            )
          : guideTemplate.greenhouseStages;

        return !filtered.length ? null : (
          <div key={guideTemplate.id} className={classes.roleContainer}>
            <div className={classes.role}>
              <div className={cx(classes.iconContainer, classes.ghContainer)}>
                <img
                  src={ghIcon}
                  className={classes.icon}
                  alt="Greenhouse Logo"
                />
              </div>
              <Typography>{guideTemplate.name}</Typography>
            </div>
            <div className={classes.stages}>
              {_.map(filtered, (stage) => {
                const isSelected = _.includes(selectedStageIds, stage.id);
                const disabled = !isAvailable(stage, mode);
                const handleToggleStage = () => {
                  if (disabled) {
                    return;
                  }
                  if (isSelected) {
                    onDeselectStage(stage);
                  } else {
                    onSelectStage(stage);
                  }
                };

                return (
                  <div
                    tabIndex={0}
                    role="button"
                    onKeyUp={handleToggleStage}
                    className={cx(classes.stage, {
                      [classes.disabled]: disabled,
                    })}
                    onClick={handleToggleStage}
                  >
                    <Tooltip
                      title={tooltipLabel}
                      disableFocusListener={!disabled}
                      disableHoverListener={!disabled}
                      disableTouchListener={!disabled}
                    >
                      <Checkbox
                        className={cx({
                          [classes.checkboxDisabled]: disabled,
                        })}
                        disableRipple
                        color="primary"
                        onChange={handleToggleStage}
                        checked={isSelected}
                      />
                    </Tooltip>
                    <Typography
                      className={cx({
                        [classes.disabled]: disabled,
                      })}
                    >
                      {stage.name}
                    </Typography>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default JobsStagePicker;
