import React, { useState, useCallback, useEffect } from "react";
import * as Sentry from "@sentry/react";

import {
  Button,
  Typography,
  DialogActions,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  makeStyles,
} from "@material-ui/core";
import slackIconLg from "~/icons/slack-icon-lg.svg";
import {
  SlackIntegrationForCustomer_currentUserV2_organization_customer_integration_SlackIntegrationV2 as SlackIntegration,
  IntegrationEnum,
} from "~/schemaTypes";
import { useAuth0, Auth0Provider } from "~/react-auth0";
import Loading from "~/components/Loading/Loading";
import IntegrationModal from "../Integrations/IntegrationModal";

import {
  useSlackIntegration,
  useUpdateSlack,
  useSyncSlack,
} from "~/apollo-hooks/use-slack-integration";

type SlackIntegrationModalProps = {
  open: boolean;
  onClose: () => void;
};

const useSwitchStyles = makeStyles({
  root: {
    marginRight: 8,
  },
});

const useStyles = makeStyles(() => ({
  actions: {
    padding: "2rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  description: {
    marginBottom: "1.5rem",
  },
  checkbox: {
    marginLeft: "0.4rem",
    marginBottom: "0.8rem",
  },
  formAction: {
    width: "10rem",
  },
}));

const SlackIntegrationModal: React.FC<SlackIntegrationModalProps> = ({
  open,
  onClose,
}) => {
  const { data } = useSlackIntegration();
  const styles = useStyles();
  const integration = data?.currentUserV2?.organization.customer.integration as
    | SlackIntegration
    | null
    | undefined;
  const { syncSlack } = useSyncSlack();
  const { updateSlack, loading: updateSlackLoading } = useUpdateSlack();

  const [autopopulateInterviewer, setAutopopulateInterviewer] = useState(false);
  const [receiveNotifications, setReceiveNotifications] = useState(false);

  useEffect(() => {
    if (integration) {
      setAutopopulateInterviewer(!!integration.autopopulateInterviewer);
      setReceiveNotifications(!!integration.receiveNotifications);
    }
  }, [integration]);

  const { loginWithPopup, loading: auth0Loading } = useAuth0();

  const switchClasses = useSwitchStyles();

  useEffect(() => {
    const sync = async (): Promise<void> => {
      try {
        await syncSlack({
          variables: {
            typeInput: {
              type: IntegrationEnum.SLACK,
            },
          },
        });
      } catch (err) {
        Sentry.captureException(err);
      }
    };
    sync();
  }, [syncSlack]);

  const handleConnect = useCallback(async () => {
    const user = await loginWithPopup({
      connection: "slack",
    });
    if (user) {
      await updateSlack({
        variables: {
          input: {
            auth0SlackUserId: user.sub,
          },
          typeInput: {
            type: IntegrationEnum.SLACK,
          },
        },
      });
    }
  }, [loginWithPopup, updateSlack]);

  const handleUpdate = useCallback(async () => {
    await updateSlack({
      variables: {
        input: {
          autopopulateInterviewer,
          receiveNotifications,
        },
        typeInput: {
          type: IntegrationEnum.SLACK,
        },
      },
    });

    onClose();
  }, [autopopulateInterviewer, onClose, receiveNotifications, updateSlack]);

  return (
    <IntegrationModal
      title="Slack"
      logoSrc={slackIconLg}
      open={open}
      onClose={onClose}
      {...(integration?.connected
        ? {
            onSave: handleUpdate,
            disabled: false,
            updateSaving: updateSlackLoading,
          }
        : {})}
    >
      <Typography className={styles.description} variant="body1">
        The Guide Slack integration connects basic Interviewer information to
        automatically populate Interviewer names and photos. Additionally, you
        can receive messages from candidates who communicate via the in-Guide
        chat to a Slack channel.
      </Typography>
      {auth0Loading ? (
        <Loading />
      ) : (
        !!integration?.connected && (
          <FormControl component="fieldset">
            <FormGroup aria-label="position" row>
              <FormControlLabel
                className={styles.checkbox}
                control={
                  <Switch
                    className={switchClasses.root}
                    checked={autopopulateInterviewer}
                    onChange={(
                      _e: React.ChangeEvent<HTMLInputElement>,
                      value: boolean
                    ): void => {
                      setAutopopulateInterviewer(value);
                    }}
                    color="primary"
                  />
                }
                label="Autopopulate New Interviewers"
                labelPlacement="end"
              />
              <FormControlLabel
                value="end"
                className={styles.checkbox}
                control={
                  <Switch
                    className={switchClasses.root}
                    checked={receiveNotifications}
                    onChange={(
                      _e: React.ChangeEvent<HTMLInputElement>,
                      value: boolean
                    ): void => {
                      setReceiveNotifications(value);
                    }}
                    color="primary"
                  />
                }
                label="Receive Notifications On Slack"
                labelPlacement="end"
              />
            </FormGroup>
          </FormControl>
        )
      )}
      {!integration?.connected && updateSlackLoading && (
        <Loading inlineStyling />
      )}
      <DialogActions className={styles.actions}>
        {!integration?.connected && (
          <Button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              handleConnect();
            }}
          >
            <img
              alt="Add to Slack"
              height="40"
              width="139"
              src="https://platform.slack-edge.com/img/add_to_slack.png"
              srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
            />
          </Button>
        )}
      </DialogActions>
    </IntegrationModal>
  );
};

const Wrapped: React.FC<SlackIntegrationModalProps> = (props) => (
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_TENANT_DOMAIN!}
    timeout="1000"
    client_id={process.env.REACT_APP_AUTH0_GUIDES_CLIENT_ID!}
    connection="slack"
  >
    <SlackIntegrationModal {...props} />
  </Auth0Provider>
);

export default Wrapped;
