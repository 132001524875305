import React, { useContext, useState, useCallback } from "react";
import { ModuleEnum } from "@resource/common";
import { CompanyComponent } from "~/pages/GuidesHomepage/Settings/Company/types";

type InlineCompanyEditorContextProps = {
  showingCompanyComponents?: CompanyComponent[];
  setShowingCompanyComponents(type?: ModuleEnum): void;
};

export const InlineCompanyEditorContext = React.createContext<
  InlineCompanyEditorContextProps
>({
  showingCompanyComponents: undefined,
  setShowingCompanyComponents() {},
});

export const useInlineCompanyEditor = (): InlineCompanyEditorContextProps =>
  useContext<InlineCompanyEditorContextProps>(InlineCompanyEditorContext);

export const InlineCompanyEditorContextProvider: React.FC = ({ children }) => {
  const [showingCompanyComponents, setComponents] = useState<
    CompanyComponent[] | undefined
  >();

  const setShowingCompanyComponents = useCallback((type: ModuleEnum) => {
    switch (type) {
      case ModuleEnum.Values:
        setComponents([CompanyComponent.Values]);
        break;
      case ModuleEnum.Mission:
        setComponents([CompanyComponent.Mission]);
        break;
      case ModuleEnum.Location:
        setComponents([CompanyComponent.Location]);
        break;
      default:
        setComponents(undefined);
    }
  }, []);

  return (
    <InlineCompanyEditorContext.Provider
      value={{
        showingCompanyComponents,
        setShowingCompanyComponents,
      }}
    >
      {children}
    </InlineCompanyEditorContext.Provider>
  );
};
