/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GreenhouseIntegrationForCustomerHook
// ====================================================

export interface GreenhouseIntegrationForCustomerHook_currentUserV2_organization_customer_integration_SlackIntegrationV2 {
  __typename: "SlackIntegrationV2" | "CoderPadIntegration" | "ZoomIntegration";
  id: string;
  type: IntegrationEnum;
  connected: boolean;
}

export interface GreenhouseIntegrationForCustomerHook_currentUserV2_organization_customer_integration_GreenhouseIntegrationV2 {
  __typename: "GreenhouseIntegrationV2";
  id: string;
  type: IntegrationEnum;
  connected: boolean;
  apiKeySuffix: string | null;
  subdomain: string;
  maildropAddress: string;
}

export type GreenhouseIntegrationForCustomerHook_currentUserV2_organization_customer_integration = GreenhouseIntegrationForCustomerHook_currentUserV2_organization_customer_integration_SlackIntegrationV2 | GreenhouseIntegrationForCustomerHook_currentUserV2_organization_customer_integration_GreenhouseIntegrationV2;

export interface GreenhouseIntegrationForCustomerHook_currentUserV2_organization_customer {
  __typename: "Customer";
  id: string;
  integration: GreenhouseIntegrationForCustomerHook_currentUserV2_organization_customer_integration | null;
}

export interface GreenhouseIntegrationForCustomerHook_currentUserV2_organization {
  __typename: "Organization";
  id: string;
  customer: GreenhouseIntegrationForCustomerHook_currentUserV2_organization_customer;
}

export interface GreenhouseIntegrationForCustomerHook_currentUserV2 {
  __typename: "GuidesUser";
  id: string;
  organization: GreenhouseIntegrationForCustomerHook_currentUserV2_organization;
}

export interface GreenhouseIntegrationForCustomerHook {
  currentUserV2: GreenhouseIntegrationForCustomerHook_currentUserV2 | null;
}

export interface GreenhouseIntegrationForCustomerHookVariables {
  typeInput: IntegrationByTypeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SlackIntegrationForCustomer
// ====================================================

export interface SlackIntegrationForCustomer_currentUserV2_organization_customer_integration_CoderPadIntegration {
  __typename: "CoderPadIntegration" | "GreenhouseIntegrationV2" | "ZoomIntegration";
  id: string;
  type: IntegrationEnum;
  issues: string[];
  connected: boolean;
}

export interface SlackIntegrationForCustomer_currentUserV2_organization_customer_integration_SlackIntegrationV2_channels {
  __typename: "SlackChannel";
  id: string;
  name: string;
}

export interface SlackIntegrationForCustomer_currentUserV2_organization_customer_integration_SlackIntegrationV2 {
  __typename: "SlackIntegrationV2";
  id: string;
  type: IntegrationEnum;
  issues: string[];
  connected: boolean;
  auth0SlackUserId: string | null;
  notificationsChannelId: string | null;
  autopopulateInterviewer: boolean | null;
  receiveNotifications: boolean | null;
  channels: SlackIntegrationForCustomer_currentUserV2_organization_customer_integration_SlackIntegrationV2_channels[];
}

export type SlackIntegrationForCustomer_currentUserV2_organization_customer_integration = SlackIntegrationForCustomer_currentUserV2_organization_customer_integration_CoderPadIntegration | SlackIntegrationForCustomer_currentUserV2_organization_customer_integration_SlackIntegrationV2;

export interface SlackIntegrationForCustomer_currentUserV2_organization_customer {
  __typename: "Customer";
  id: string;
  integration: SlackIntegrationForCustomer_currentUserV2_organization_customer_integration | null;
}

export interface SlackIntegrationForCustomer_currentUserV2_organization {
  __typename: "Organization";
  id: string;
  customer: SlackIntegrationForCustomer_currentUserV2_organization_customer;
}

export interface SlackIntegrationForCustomer_currentUserV2 {
  __typename: "GuidesUser";
  id: string;
  organization: SlackIntegrationForCustomer_currentUserV2_organization;
}

export interface SlackIntegrationForCustomer {
  currentUserV2: SlackIntegrationForCustomer_currentUserV2 | null;
}

export interface SlackIntegrationForCustomerVariables {
  typeInput: IntegrationByTypeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SyncSlackOnSlackIntegrationsPageLoad
// ====================================================

export interface SyncSlackOnSlackIntegrationsPageLoad_syncSlack_customer_integration_CoderPadIntegration {
  __typename: "CoderPadIntegration" | "GreenhouseIntegrationV2" | "ZoomIntegration";
  id: string;
  type: IntegrationEnum;
  issues: string[];
  connected: boolean;
}

export interface SyncSlackOnSlackIntegrationsPageLoad_syncSlack_customer_integration_SlackIntegrationV2_channels {
  __typename: "SlackChannel";
  id: string;
  name: string;
}

export interface SyncSlackOnSlackIntegrationsPageLoad_syncSlack_customer_integration_SlackIntegrationV2 {
  __typename: "SlackIntegrationV2";
  id: string;
  type: IntegrationEnum;
  issues: string[];
  connected: boolean;
  auth0SlackUserId: string | null;
  notificationsChannelId: string | null;
  autopopulateInterviewer: boolean | null;
  receiveNotifications: boolean | null;
  channels: SyncSlackOnSlackIntegrationsPageLoad_syncSlack_customer_integration_SlackIntegrationV2_channels[];
}

export type SyncSlackOnSlackIntegrationsPageLoad_syncSlack_customer_integration = SyncSlackOnSlackIntegrationsPageLoad_syncSlack_customer_integration_CoderPadIntegration | SyncSlackOnSlackIntegrationsPageLoad_syncSlack_customer_integration_SlackIntegrationV2;

export interface SyncSlackOnSlackIntegrationsPageLoad_syncSlack_customer {
  __typename: "Customer";
  id: string;
  integration: SyncSlackOnSlackIntegrationsPageLoad_syncSlack_customer_integration | null;
}

export interface SyncSlackOnSlackIntegrationsPageLoad_syncSlack {
  __typename: "SyncSlackMutationResponse";
  success: boolean;
  customer: SyncSlackOnSlackIntegrationsPageLoad_syncSlack_customer | null;
}

export interface SyncSlackOnSlackIntegrationsPageLoad {
  syncSlack: SyncSlackOnSlackIntegrationsPageLoad_syncSlack;
}

export interface SyncSlackOnSlackIntegrationsPageLoadVariables {
  typeInput: IntegrationByTypeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateSlackIntegrationForCustomer
// ====================================================

export interface UpdateSlackIntegrationForCustomer_updateCustomerSlackIntegration_customer_integration_CoderPadIntegration {
  __typename: "CoderPadIntegration" | "GreenhouseIntegrationV2" | "ZoomIntegration";
  id: string;
  type: IntegrationEnum;
  issues: string[];
  connected: boolean;
}

export interface UpdateSlackIntegrationForCustomer_updateCustomerSlackIntegration_customer_integration_SlackIntegrationV2_channels {
  __typename: "SlackChannel";
  id: string;
  name: string;
}

export interface UpdateSlackIntegrationForCustomer_updateCustomerSlackIntegration_customer_integration_SlackIntegrationV2 {
  __typename: "SlackIntegrationV2";
  id: string;
  type: IntegrationEnum;
  issues: string[];
  connected: boolean;
  auth0SlackUserId: string | null;
  notificationsChannelId: string | null;
  autopopulateInterviewer: boolean | null;
  receiveNotifications: boolean | null;
  channels: UpdateSlackIntegrationForCustomer_updateCustomerSlackIntegration_customer_integration_SlackIntegrationV2_channels[];
}

export type UpdateSlackIntegrationForCustomer_updateCustomerSlackIntegration_customer_integration = UpdateSlackIntegrationForCustomer_updateCustomerSlackIntegration_customer_integration_CoderPadIntegration | UpdateSlackIntegrationForCustomer_updateCustomerSlackIntegration_customer_integration_SlackIntegrationV2;

export interface UpdateSlackIntegrationForCustomer_updateCustomerSlackIntegration_customer {
  __typename: "Customer";
  id: string;
  integration: UpdateSlackIntegrationForCustomer_updateCustomerSlackIntegration_customer_integration | null;
}

export interface UpdateSlackIntegrationForCustomer_updateCustomerSlackIntegration {
  __typename: "CustomerIntegrationMutationResponse";
  success: boolean;
  message: string;
  code: string;
  customer: UpdateSlackIntegrationForCustomer_updateCustomerSlackIntegration_customer | null;
}

export interface UpdateSlackIntegrationForCustomer {
  updateCustomerSlackIntegration: UpdateSlackIntegrationForCustomer_updateCustomerSlackIntegration;
}

export interface UpdateSlackIntegrationForCustomerVariables {
  input: UpdateCustomerSlackIntegrationInput;
  typeInput: IntegrationByTypeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: StoreLatestGoogleAuth
// ====================================================

export interface StoreLatestGoogleAuth_storeLatestGoogleAuth_user {
  __typename: "GuidesUser";
  id: string;
  canSendEmail: boolean;
}

export interface StoreLatestGoogleAuth_storeLatestGoogleAuth {
  __typename: "StoreLatestGoogleAuthMutationResponse";
  success: boolean;
  user: StoreLatestGoogleAuth_storeLatestGoogleAuth_user | null;
}

export interface StoreLatestGoogleAuth {
  storeLatestGoogleAuth: StoreLatestGoogleAuth_storeLatestGoogleAuth;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SubmitFeedbackForStage
// ====================================================

export interface SubmitFeedbackForStage_submitCandidateFeedback {
  __typename: "CandidateFeedback";
  id: string;
  data: GraphQL_Json;
}

export interface SubmitFeedbackForStage {
  submitCandidateFeedback: SubmitFeedbackForStage_submitCandidateFeedback;
}

export interface SubmitFeedbackForStageVariables {
  input: SubmitCandidateFeedbackInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CurrentUserSignedIn
// ====================================================

export interface CurrentUserSignedIn_currentUserV2 {
  __typename: "GuidesUser";
  id: string;
}

export interface CurrentUserSignedIn {
  currentUserV2: CurrentUserSignedIn_currentUserV2 | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CandidateForStream
// ====================================================

export interface CandidateForStream_guide_organization {
  __typename: "Organization";
  id: string;
  name: string;
}

export interface CandidateForStream_guide_candidate {
  __typename: "CandidateV2";
  id: string;
  name: string;
  streamToken: string | null;
}

export interface CandidateForStream_guide {
  __typename: "Guide";
  id: string;
  organization: CandidateForStream_guide_organization;
  candidate: CandidateForStream_guide_candidate;
}

export interface CandidateForStream {
  guide: CandidateForStream_guide | null;
}

export interface CandidateForStreamVariables {
  input: GuideFindInput;
  streamTokenInput: StreamTokenInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CurrentUserForStream
// ====================================================

export interface CurrentUserForStream_currentUserV2 {
  __typename: "GuidesUser";
  id: string;
  name: string;
  streamToken: string | null;
  photo: string | null;
}

export interface CurrentUserForStream {
  currentUserV2: CurrentUserForStream_currentUserV2 | null;
}

export interface CurrentUserForStreamVariables {
  streamTokenInput: StreamTokenInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetupGuide
// ====================================================

export interface SetupGuide_setupStreamChatForGuide {
  __typename: "SetupStreamChatForGuideMutationResponse";
  code: string;
  success: boolean;
  message: string;
}

export interface SetupGuide {
  setupStreamChatForGuide: SetupGuide_setupStreamChatForGuide;
}

export interface SetupGuideVariables {
  input: SetupStreamChatForGuideInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FakeMutationForEditModules
// ====================================================

export interface FakeMutationForEditModules_updateSimpleTextListInstallationForStageTemplate {
  __typename: "StageTemplate";
  id: string;
  name: string;
}

export interface FakeMutationForEditModules {
  updateSimpleTextListInstallationForStageTemplate: FakeMutationForEditModules_updateSimpleTextListInstallationForStageTemplate;
}

export interface FakeMutationForEditModulesVariables {
  input: UpdateSimpleTextListInstallationForStageTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: StageTemplateUpdateEmailTemplate
// ====================================================

export interface StageTemplateUpdateEmailTemplate_updateStageTemplate_emailTemplate {
  __typename: "EmailTemplateV2";
  id: string;
  name: string;
}

export interface StageTemplateUpdateEmailTemplate_updateStageTemplate {
  __typename: "StageTemplate";
  id: string;
  emailTemplate: StageTemplateUpdateEmailTemplate_updateStageTemplate_emailTemplate;
}

export interface StageTemplateUpdateEmailTemplate {
  updateStageTemplate: StageTemplateUpdateEmailTemplate_updateStageTemplate;
}

export interface StageTemplateUpdateEmailTemplateVariables {
  input: stageTemplateUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddStageTemplateModuleToStageTemplate
// ====================================================

export interface AddStageTemplateModuleToStageTemplate_createStageTemplateModuleInstallation_emailTemplate {
  __typename: "EmailTemplateV2";
  id: string;
  name: string;
}

export interface AddStageTemplateModuleToStageTemplate_createStageTemplateModuleInstallation_organization {
  __typename: "Organization";
  id: string;
  name: string;
}

export interface AddStageTemplateModuleToStageTemplate_createStageTemplateModuleInstallation_modules {
  __typename: "StageTemplateModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_Json;
}

export interface AddStageTemplateModuleToStageTemplate_createStageTemplateModuleInstallation {
  __typename: "StageTemplate";
  id: string;
  name: string;
  emailTemplate: AddStageTemplateModuleToStageTemplate_createStageTemplateModuleInstallation_emailTemplate;
  organization: AddStageTemplateModuleToStageTemplate_createStageTemplateModuleInstallation_organization | null;
  modules: AddStageTemplateModuleToStageTemplate_createStageTemplateModuleInstallation_modules[];
  createdBy: string;
  updatedAt: GraphQL_DateTime;
}

export interface AddStageTemplateModuleToStageTemplate {
  createStageTemplateModuleInstallation: AddStageTemplateModuleToStageTemplate_createStageTemplateModuleInstallation;
}

export interface AddStageTemplateModuleToStageTemplateVariables {
  input: CreateStageTemplateModuleInstallationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveStageTemplateModuleFromStageTemplate
// ====================================================

export interface RemoveStageTemplateModuleFromStageTemplate_deleteStageTemplateModuleInstallation_emailTemplate {
  __typename: "EmailTemplateV2";
  id: string;
  name: string;
}

export interface RemoveStageTemplateModuleFromStageTemplate_deleteStageTemplateModuleInstallation_organization {
  __typename: "Organization";
  id: string;
  name: string;
}

export interface RemoveStageTemplateModuleFromStageTemplate_deleteStageTemplateModuleInstallation_modules {
  __typename: "StageTemplateModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_Json;
}

export interface RemoveStageTemplateModuleFromStageTemplate_deleteStageTemplateModuleInstallation {
  __typename: "StageTemplate";
  id: string;
  name: string;
  emailTemplate: RemoveStageTemplateModuleFromStageTemplate_deleteStageTemplateModuleInstallation_emailTemplate;
  organization: RemoveStageTemplateModuleFromStageTemplate_deleteStageTemplateModuleInstallation_organization | null;
  modules: RemoveStageTemplateModuleFromStageTemplate_deleteStageTemplateModuleInstallation_modules[];
  createdBy: string;
  updatedAt: GraphQL_DateTime;
}

export interface RemoveStageTemplateModuleFromStageTemplate {
  deleteStageTemplateModuleInstallation: RemoveStageTemplateModuleFromStageTemplate_deleteStageTemplateModuleInstallation;
}

export interface RemoveStageTemplateModuleFromStageTemplateVariables {
  input: DeleteStageTemplateModuleInstallationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: stageTemplateUpdate
// ====================================================

export interface stageTemplateUpdate_updateStageTemplate {
  __typename: "StageTemplate";
  id: string;
  name: string;
}

export interface stageTemplateUpdate {
  updateStageTemplate: stageTemplateUpdate_updateStageTemplate;
}

export interface stageTemplateUpdateVariables {
  input: stageTemplateUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ReorderStageTemplateModuleInstallation
// ====================================================

export interface ReorderStageTemplateModuleInstallation_reOrderStageTemplateModuleInstallation_emailTemplate {
  __typename: "EmailTemplateV2";
  id: string;
  name: string;
}

export interface ReorderStageTemplateModuleInstallation_reOrderStageTemplateModuleInstallation_organization {
  __typename: "Organization";
  id: string;
  name: string;
}

export interface ReorderStageTemplateModuleInstallation_reOrderStageTemplateModuleInstallation_modules {
  __typename: "StageTemplateModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_Json;
}

export interface ReorderStageTemplateModuleInstallation_reOrderStageTemplateModuleInstallation {
  __typename: "StageTemplate";
  id: string;
  name: string;
  emailTemplate: ReorderStageTemplateModuleInstallation_reOrderStageTemplateModuleInstallation_emailTemplate;
  organization: ReorderStageTemplateModuleInstallation_reOrderStageTemplateModuleInstallation_organization | null;
  modules: ReorderStageTemplateModuleInstallation_reOrderStageTemplateModuleInstallation_modules[];
  createdBy: string;
  updatedAt: GraphQL_DateTime;
}

export interface ReorderStageTemplateModuleInstallation {
  reOrderStageTemplateModuleInstallation: ReorderStageTemplateModuleInstallation_reOrderStageTemplateModuleInstallation;
}

export interface ReorderStageTemplateModuleInstallationVariables {
  input: StageTemplateModuleUpdateInstallationReorderInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCultureForStageTemplateModuleInstallation
// ====================================================

export interface UpdateCultureForStageTemplateModuleInstallation_updateCultureInstallationForStageTemplate {
  __typename: "StageTemplate";
  id: string;
  name: string;
  updatedAt: GraphQL_DateTime;
}

export interface UpdateCultureForStageTemplateModuleInstallation {
  updateCultureInstallationForStageTemplate: UpdateCultureForStageTemplateModuleInstallation_updateCultureInstallationForStageTemplate;
}

export interface UpdateCultureForStageTemplateModuleInstallationVariables {
  input: UpdateCultureInstallationForStageTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateInterviewTeamForStageTemplateModuleInstallation
// ====================================================

export interface UpdateInterviewTeamForStageTemplateModuleInstallation_updateInterviewTeamInstallationForStageTemplate_stageTemplate {
  __typename: "StageTemplate";
  id: string;
  name: string;
  updatedAt: GraphQL_DateTime;
}

export interface UpdateInterviewTeamForStageTemplateModuleInstallation_updateInterviewTeamInstallationForStageTemplate {
  __typename: "UpdateInstallationForStageTemplateMutationResponse";
  success: boolean;
  stageTemplate: UpdateInterviewTeamForStageTemplateModuleInstallation_updateInterviewTeamInstallationForStageTemplate_stageTemplate | null;
}

export interface UpdateInterviewTeamForStageTemplateModuleInstallation {
  updateInterviewTeamInstallationForStageTemplate: UpdateInterviewTeamForStageTemplateModuleInstallation_updateInterviewTeamInstallationForStageTemplate;
}

export interface UpdateInterviewTeamForStageTemplateModuleInstallationVariables {
  input: UpdateInterviewTeamInstallationForStageTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateLinksInstallationForStageTemplate
// ====================================================

export interface UpdateLinksInstallationForStageTemplate_updateLinksInstallationForStageTemplate_stageTemplate {
  __typename: "StageTemplate";
  id: string;
  name: string;
  updatedAt: GraphQL_DateTime;
}

export interface UpdateLinksInstallationForStageTemplate_updateLinksInstallationForStageTemplate {
  __typename: "UpdateInstallationForStageTemplateMutationResponse";
  success: boolean;
  stageTemplate: UpdateLinksInstallationForStageTemplate_updateLinksInstallationForStageTemplate_stageTemplate | null;
}

export interface UpdateLinksInstallationForStageTemplate {
  updateLinksInstallationForStageTemplate: UpdateLinksInstallationForStageTemplate_updateLinksInstallationForStageTemplate;
}

export interface UpdateLinksInstallationForStageTemplateVariables {
  input: UpdateLinksInstallationForStageTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateLocationInstallationForStageTemplate
// ====================================================

export interface UpdateLocationInstallationForStageTemplate_updateLocationInstallationForStageTemplate {
  __typename: "UpdateLocationInstallationForStageTemplateMutationResponse";
  success: boolean;
}

export interface UpdateLocationInstallationForStageTemplate {
  updateLocationInstallationForStageTemplate: UpdateLocationInstallationForStageTemplate_updateLocationInstallationForStageTemplate;
}

export interface UpdateLocationInstallationForStageTemplateVariables {
  input: UpdateLocationInstallationForStageTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateLogisticsInstallationForStageTemplate
// ====================================================

export interface UpdateLogisticsInstallationForStageTemplate_updateLogisticsInstallationForStageTemplate {
  __typename: "StageTemplate";
  id: string;
  name: string;
  updatedAt: GraphQL_DateTime;
}

export interface UpdateLogisticsInstallationForStageTemplate {
  updateLogisticsInstallationForStageTemplate: UpdateLogisticsInstallationForStageTemplate_updateLogisticsInstallationForStageTemplate;
}

export interface UpdateLogisticsInstallationForStageTemplateVariables {
  input: UpdateLogisticsInstallationForStageTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MediaModuleUpdateFromEditModal
// ====================================================

export interface MediaModuleUpdateFromEditModal_updateMediaInstallationForStageTemplate {
  __typename: "StageTemplate";
  id: string;
  name: string;
}

export interface MediaModuleUpdateFromEditModal {
  updateMediaInstallationForStageTemplate: MediaModuleUpdateFromEditModal_updateMediaInstallationForStageTemplate;
}

export interface MediaModuleUpdateFromEditModalVariables {
  input: UpdateMediaInstallationForStageTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateMissionForStageTemplateModuleInstallation
// ====================================================

export interface UpdateMissionForStageTemplateModuleInstallation_updateMissionInstallationForStageTemplateV2_stageTemplate {
  __typename: "StageTemplate";
  id: string;
  name: string;
  updatedAt: GraphQL_DateTime;
}

export interface UpdateMissionForStageTemplateModuleInstallation_updateMissionInstallationForStageTemplateV2 {
  __typename: "UpdateInstallationForStageTemplateMutationResponse";
  success: boolean;
  stageTemplate: UpdateMissionForStageTemplateModuleInstallation_updateMissionInstallationForStageTemplateV2_stageTemplate | null;
}

export interface UpdateMissionForStageTemplateModuleInstallation {
  updateMissionInstallationForStageTemplateV2: UpdateMissionForStageTemplateModuleInstallation_updateMissionInstallationForStageTemplateV2;
}

export interface UpdateMissionForStageTemplateModuleInstallationVariables {
  input: UpdateMissionInstallationForStageTemplateV2Input;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateRichTextInstallationForStageTemplate
// ====================================================

export interface UpdateRichTextInstallationForStageTemplate_updateRichTextInstallationForStageTemplate_stageTemplate {
  __typename: "StageTemplate";
  id: string;
  name: string;
  updatedAt: GraphQL_DateTime;
}

export interface UpdateRichTextInstallationForStageTemplate_updateRichTextInstallationForStageTemplate {
  __typename: "UpdateInstallationForStageTemplateMutationResponse";
  success: boolean;
  stageTemplate: UpdateRichTextInstallationForStageTemplate_updateRichTextInstallationForStageTemplate_stageTemplate | null;
}

export interface UpdateRichTextInstallationForStageTemplate {
  updateRichTextInstallationForStageTemplate: UpdateRichTextInstallationForStageTemplate_updateRichTextInstallationForStageTemplate;
}

export interface UpdateRichTextInstallationForStageTemplateVariables {
  input: UpdateRichTextInstallationForStageTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateScheduleForStageTemplateModuleInstallation
// ====================================================

export interface UpdateScheduleForStageTemplateModuleInstallation_updateScheduleInstallationForStageTemplate_stageTemplate {
  __typename: "StageTemplate";
  id: string;
  name: string;
  updatedAt: GraphQL_DateTime;
}

export interface UpdateScheduleForStageTemplateModuleInstallation_updateScheduleInstallationForStageTemplate {
  __typename: "UpdateInstallationForStageTemplateMutationResponse";
  success: boolean;
  stageTemplate: UpdateScheduleForStageTemplateModuleInstallation_updateScheduleInstallationForStageTemplate_stageTemplate | null;
}

export interface UpdateScheduleForStageTemplateModuleInstallation {
  updateScheduleInstallationForStageTemplate: UpdateScheduleForStageTemplateModuleInstallation_updateScheduleInstallationForStageTemplate;
}

export interface UpdateScheduleForStageTemplateModuleInstallationVariables {
  input: UpdateScheduleInstallationForStageTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateSimpleTextListInstallationForStageTemplate
// ====================================================

export interface UpdateSimpleTextListInstallationForStageTemplate_updateSimpleTextListInstallationForStageTemplate {
  __typename: "StageTemplate";
  id: string;
  name: string;
  updatedAt: GraphQL_DateTime;
}

export interface UpdateSimpleTextListInstallationForStageTemplate {
  updateSimpleTextListInstallationForStageTemplate: UpdateSimpleTextListInstallationForStageTemplate_updateSimpleTextListInstallationForStageTemplate;
}

export interface UpdateSimpleTextListInstallationForStageTemplateVariables {
  input: UpdateSimpleTextListInstallationForStageTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateTimeForStageTemplateModuleInstallation
// ====================================================

export interface UpdateTimeForStageTemplateModuleInstallation_updateTimeInstallationForStageTemplate_stageTemplate {
  __typename: "StageTemplate";
  id: string;
  name: string;
  updatedAt: GraphQL_DateTime;
}

export interface UpdateTimeForStageTemplateModuleInstallation_updateTimeInstallationForStageTemplate {
  __typename: "UpdateInstallationForStageTemplateMutationResponse";
  success: boolean;
  stageTemplate: UpdateTimeForStageTemplateModuleInstallation_updateTimeInstallationForStageTemplate_stageTemplate | null;
}

export interface UpdateTimeForStageTemplateModuleInstallation {
  updateTimeInstallationForStageTemplate: UpdateTimeForStageTemplateModuleInstallation_updateTimeInstallationForStageTemplate;
}

export interface UpdateTimeForStageTemplateModuleInstallationVariables {
  input: UpdateTimeInstallationForStageTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateValuesForStageTemplateModuleInstallation
// ====================================================

export interface UpdateValuesForStageTemplateModuleInstallation_updateValuesInstallationForStageTemplate_stageTemplate {
  __typename: "StageTemplate";
  id: string;
  name: string;
  updatedAt: GraphQL_DateTime;
}

export interface UpdateValuesForStageTemplateModuleInstallation_updateValuesInstallationForStageTemplate {
  __typename: "UpdateInstallationForStageTemplateMutationResponse";
  success: boolean;
  stageTemplate: UpdateValuesForStageTemplateModuleInstallation_updateValuesInstallationForStageTemplate_stageTemplate | null;
}

export interface UpdateValuesForStageTemplateModuleInstallation {
  updateValuesInstallationForStageTemplate: UpdateValuesForStageTemplateModuleInstallation_updateValuesInstallationForStageTemplate;
}

export interface UpdateValuesForStageTemplateModuleInstallationVariables {
  input: UpdateValuesInstallationForStageTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: StoreGoogleAuth
// ====================================================

export interface StoreGoogleAuth_createOrUpdateUserWithGoogleAuth_user_organization_customer {
  __typename: "Customer";
  id: string;
  name: string | null;
}

export interface StoreGoogleAuth_createOrUpdateUserWithGoogleAuth_user_organization {
  __typename: "Organization";
  id: string;
  name: string;
  analyticsGroupId: string;
  customer: StoreGoogleAuth_createOrUpdateUserWithGoogleAuth_user_organization_customer;
}

export interface StoreGoogleAuth_createOrUpdateUserWithGoogleAuth_user {
  __typename: "GuidesUser";
  id: string;
  analyticsUserId: string;
  name: string;
  firstName: string;
  lastName: string;
  canSendEmail: boolean;
  onboardingComplete: boolean;
  organization: StoreGoogleAuth_createOrUpdateUserWithGoogleAuth_user_organization;
  roles: string[];
  primaryEmail: string | null;
  title: string;
  intercomHash: string;
  createdAt: GraphQL_DateTime;
}

export interface StoreGoogleAuth_createOrUpdateUserWithGoogleAuth {
  __typename: "CreateOrUpdateUserWithGoogleAuthMutationResponse";
  code: string;
  message: string;
  success: boolean;
  user: StoreGoogleAuth_createOrUpdateUserWithGoogleAuth_user;
}

export interface StoreGoogleAuth {
  createOrUpdateUserWithGoogleAuth: StoreGoogleAuth_createOrUpdateUserWithGoogleAuth;
}

export interface StoreGoogleAuthVariables {
  input: CreateOrUpdateUserWithGoogleAuthInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateOrganizationMission
// ====================================================

export interface UpdateOrganizationMission_updateOrganizationMission {
  __typename: "Organization";
  id: string;
  missionStatement: string;
}

export interface UpdateOrganizationMission {
  updateOrganizationMission: UpdateOrganizationMission_updateOrganizationMission;
}

export interface UpdateOrganizationMissionVariables {
  input: UpdateOrganizationMissionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCurrentOrganization
// ====================================================

export interface UpdateCurrentOrganization_updateCurrentOrganization_organization {
  __typename: "Organization";
  id: string;
  name: string;
  companyLogoUrl: string;
  missionStatement: string;
}

export interface UpdateCurrentOrganization_updateCurrentOrganization {
  __typename: "UpdateCurrentOrganizationMutationResponse";
  success: boolean;
  organization: UpdateCurrentOrganization_updateCurrentOrganization_organization | null;
}

export interface UpdateCurrentOrganization {
  updateCurrentOrganization: UpdateCurrentOrganization_updateCurrentOrganization;
}

export interface UpdateCurrentOrganizationVariables {
  input: UpdateCurrentOrganizationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UploadTeammateImage
// ====================================================

export interface UploadTeammateImage_uploadTeammateImage {
  __typename: "UploadTeammateImageResponse";
  url: string;
}

export interface UploadTeammateImage {
  uploadTeammateImage: UploadTeammateImage_uploadTeammateImage;
}

export interface UploadTeammateImageVariables {
  input: UploadTeammateImageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SyncGuideForCandidateJourney
// ====================================================

export interface SyncGuideForCandidateJourney_syncGuideWithATS {
  __typename: "Guide";
  id: string;
}

export interface SyncGuideForCandidateJourney {
  syncGuideWithATS: SyncGuideForCandidateJourney_syncGuideWithATS;
}

export interface SyncGuideForCandidateJourneyVariables {
  input: GuideSyncInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetGuideForCandidateJourney
// ====================================================

export interface GetGuideForCandidateJourney_guide_journeyMetadata_steps_StageChangeJourneyStep_stage_events_prominentInstructions {
  __typename: "ProminentInstruction";
  id: string;
  label: string;
  url: string | null;
}

export interface GetGuideForCandidateJourney_guide_journeyMetadata_steps_StageChangeJourneyStep_stage_events_interviewers {
  __typename: "InterviewerV2";
  id: string;
  biography: string;
  firstName: string;
  lastName: string;
  linkedInUrl: string;
  websiteUrls: string[];
  title: string;
  imageUrl: string;
  hasDefaultImage: boolean;
}

export interface GetGuideForCandidateJourney_guide_journeyMetadata_steps_StageChangeJourneyStep_stage_events {
  __typename: "EventV2";
  id: string;
  title: string;
  rawTitle: string;
  hidden: boolean;
  remoteInstructions: string | null;
  description: string | null;
  rawDescription: string;
  startTime: GraphQL_DateTime | null;
  endTime: GraphQL_DateTime | null;
  techScreenLink: string | null;
  zoomLink: string | null;
  placeholders: GraphQL_Json | null;
  prominentInstructions: GetGuideForCandidateJourney_guide_journeyMetadata_steps_StageChangeJourneyStep_stage_events_prominentInstructions[];
  interviewers: GetGuideForCandidateJourney_guide_journeyMetadata_steps_StageChangeJourneyStep_stage_events_interviewers[] | null;
}

export interface GetGuideForCandidateJourney_guide_journeyMetadata_steps_StageChangeJourneyStep_stage_stageTemplate {
  __typename: "StageTemplate";
  id: string;
  name: string;
}

export interface GetGuideForCandidateJourney_guide_journeyMetadata_steps_StageChangeJourneyStep_stage_emails {
  __typename: "EmailActivity";
  id: string;
}

export interface GetGuideForCandidateJourney_guide_journeyMetadata_steps_StageChangeJourneyStep_stage {
  __typename: "Stage";
  id: string;
  atsStageId: string | null;
  title: string;
  position: number;
  hidden: boolean;
  activatedAt: GraphQL_DateTime | null;
  activatedOverride: boolean | null;
  events: GetGuideForCandidateJourney_guide_journeyMetadata_steps_StageChangeJourneyStep_stage_events[];
  stageTemplate: GetGuideForCandidateJourney_guide_journeyMetadata_steps_StageChangeJourneyStep_stage_stageTemplate;
  emails: GetGuideForCandidateJourney_guide_journeyMetadata_steps_StageChangeJourneyStep_stage_emails[];
}

export interface GetGuideForCandidateJourney_guide_journeyMetadata_steps_StageChangeJourneyStep {
  __typename: "StageChangeJourneyStep";
  type: JourneyStepEnum;
  status: StepStatusEnum;
  stage: GetGuideForCandidateJourney_guide_journeyMetadata_steps_StageChangeJourneyStep_stage;
}

export interface GetGuideForCandidateJourney_guide_journeyMetadata_steps_NoteJourneyStep_stage_events_prominentInstructions {
  __typename: "ProminentInstruction";
  id: string;
  label: string;
  url: string | null;
}

export interface GetGuideForCandidateJourney_guide_journeyMetadata_steps_NoteJourneyStep_stage_events_interviewers {
  __typename: "InterviewerV2";
  id: string;
  biography: string;
  firstName: string;
  lastName: string;
  linkedInUrl: string;
  websiteUrls: string[];
  title: string;
  imageUrl: string;
  hasDefaultImage: boolean;
}

export interface GetGuideForCandidateJourney_guide_journeyMetadata_steps_NoteJourneyStep_stage_events {
  __typename: "EventV2";
  id: string;
  title: string;
  rawTitle: string;
  hidden: boolean;
  remoteInstructions: string | null;
  description: string | null;
  rawDescription: string;
  startTime: GraphQL_DateTime | null;
  endTime: GraphQL_DateTime | null;
  techScreenLink: string | null;
  zoomLink: string | null;
  placeholders: GraphQL_Json | null;
  prominentInstructions: GetGuideForCandidateJourney_guide_journeyMetadata_steps_NoteJourneyStep_stage_events_prominentInstructions[];
  interviewers: GetGuideForCandidateJourney_guide_journeyMetadata_steps_NoteJourneyStep_stage_events_interviewers[] | null;
}

export interface GetGuideForCandidateJourney_guide_journeyMetadata_steps_NoteJourneyStep_stage_stageTemplate {
  __typename: "StageTemplate";
  id: string;
  name: string;
}

export interface GetGuideForCandidateJourney_guide_journeyMetadata_steps_NoteJourneyStep_stage_emails {
  __typename: "EmailActivity";
  id: string;
}

export interface GetGuideForCandidateJourney_guide_journeyMetadata_steps_NoteJourneyStep_stage {
  __typename: "Stage";
  id: string;
  atsStageId: string | null;
  title: string;
  position: number;
  hidden: boolean;
  activatedAt: GraphQL_DateTime | null;
  activatedOverride: boolean | null;
  events: GetGuideForCandidateJourney_guide_journeyMetadata_steps_NoteJourneyStep_stage_events[];
  stageTemplate: GetGuideForCandidateJourney_guide_journeyMetadata_steps_NoteJourneyStep_stage_stageTemplate;
  emails: GetGuideForCandidateJourney_guide_journeyMetadata_steps_NoteJourneyStep_stage_emails[];
}

export interface GetGuideForCandidateJourney_guide_journeyMetadata_steps_NoteJourneyStep {
  __typename: "NoteJourneyStep";
  type: JourneyStepEnum;
  status: StepStatusEnum;
  stage: GetGuideForCandidateJourney_guide_journeyMetadata_steps_NoteJourneyStep_stage;
  text: string;
}

export interface GetGuideForCandidateJourney_guide_journeyMetadata_steps_ActionJourneyStep_stage_events_prominentInstructions {
  __typename: "ProminentInstruction";
  id: string;
  label: string;
  url: string | null;
}

export interface GetGuideForCandidateJourney_guide_journeyMetadata_steps_ActionJourneyStep_stage_events_interviewers {
  __typename: "InterviewerV2";
  id: string;
  biography: string;
  firstName: string;
  lastName: string;
  linkedInUrl: string;
  websiteUrls: string[];
  title: string;
  imageUrl: string;
  hasDefaultImage: boolean;
}

export interface GetGuideForCandidateJourney_guide_journeyMetadata_steps_ActionJourneyStep_stage_events {
  __typename: "EventV2";
  id: string;
  title: string;
  rawTitle: string;
  hidden: boolean;
  remoteInstructions: string | null;
  description: string | null;
  rawDescription: string;
  startTime: GraphQL_DateTime | null;
  endTime: GraphQL_DateTime | null;
  techScreenLink: string | null;
  zoomLink: string | null;
  placeholders: GraphQL_Json | null;
  prominentInstructions: GetGuideForCandidateJourney_guide_journeyMetadata_steps_ActionJourneyStep_stage_events_prominentInstructions[];
  interviewers: GetGuideForCandidateJourney_guide_journeyMetadata_steps_ActionJourneyStep_stage_events_interviewers[] | null;
}

export interface GetGuideForCandidateJourney_guide_journeyMetadata_steps_ActionJourneyStep_stage_stageTemplate {
  __typename: "StageTemplate";
  id: string;
  name: string;
}

export interface GetGuideForCandidateJourney_guide_journeyMetadata_steps_ActionJourneyStep_stage_emails {
  __typename: "EmailActivity";
  id: string;
}

export interface GetGuideForCandidateJourney_guide_journeyMetadata_steps_ActionJourneyStep_stage {
  __typename: "Stage";
  id: string;
  atsStageId: string | null;
  title: string;
  position: number;
  hidden: boolean;
  activatedAt: GraphQL_DateTime | null;
  activatedOverride: boolean | null;
  events: GetGuideForCandidateJourney_guide_journeyMetadata_steps_ActionJourneyStep_stage_events[];
  stageTemplate: GetGuideForCandidateJourney_guide_journeyMetadata_steps_ActionJourneyStep_stage_stageTemplate;
  emails: GetGuideForCandidateJourney_guide_journeyMetadata_steps_ActionJourneyStep_stage_emails[];
}

export interface GetGuideForCandidateJourney_guide_journeyMetadata_steps_ActionJourneyStep_data_emailTemplate {
  __typename: "EmailTemplateV2";
  id: string;
  name: string;
}

export interface GetGuideForCandidateJourney_guide_journeyMetadata_steps_ActionJourneyStep_data_sentMetadata {
  __typename: "SentMetadata";
  sentAt: GraphQL_DateTime;
}

export interface GetGuideForCandidateJourney_guide_journeyMetadata_steps_ActionJourneyStep_data {
  __typename: "CandidateEmailStepData";
  type: ActionJourneyStepEnum;
  cc: string[];
  bcc: string[];
  emailTemplate: GetGuideForCandidateJourney_guide_journeyMetadata_steps_ActionJourneyStep_data_emailTemplate;
  sentMetadata: GetGuideForCandidateJourney_guide_journeyMetadata_steps_ActionJourneyStep_data_sentMetadata | null;
}

export interface GetGuideForCandidateJourney_guide_journeyMetadata_steps_ActionJourneyStep {
  __typename: "ActionJourneyStep";
  type: JourneyStepEnum;
  status: StepStatusEnum;
  stage: GetGuideForCandidateJourney_guide_journeyMetadata_steps_ActionJourneyStep_stage;
  data: GetGuideForCandidateJourney_guide_journeyMetadata_steps_ActionJourneyStep_data;
}

export type GetGuideForCandidateJourney_guide_journeyMetadata_steps = GetGuideForCandidateJourney_guide_journeyMetadata_steps_StageChangeJourneyStep | GetGuideForCandidateJourney_guide_journeyMetadata_steps_NoteJourneyStep | GetGuideForCandidateJourney_guide_journeyMetadata_steps_ActionJourneyStep;

export interface GetGuideForCandidateJourney_guide_journeyMetadata {
  __typename: "JourneyMetadata";
  steps: GetGuideForCandidateJourney_guide_journeyMetadata_steps[];
}

export interface GetGuideForCandidateJourney_guide_currentVisibleStage_events_prominentInstructions {
  __typename: "ProminentInstruction";
  id: string;
  label: string;
  url: string | null;
}

export interface GetGuideForCandidateJourney_guide_currentVisibleStage_events_interviewers {
  __typename: "InterviewerV2";
  id: string;
  biography: string;
  firstName: string;
  lastName: string;
  linkedInUrl: string;
  websiteUrls: string[];
  title: string;
  imageUrl: string;
  hasDefaultImage: boolean;
}

export interface GetGuideForCandidateJourney_guide_currentVisibleStage_events {
  __typename: "EventV2";
  id: string;
  title: string;
  rawTitle: string;
  hidden: boolean;
  remoteInstructions: string | null;
  description: string | null;
  rawDescription: string;
  startTime: GraphQL_DateTime | null;
  endTime: GraphQL_DateTime | null;
  techScreenLink: string | null;
  zoomLink: string | null;
  placeholders: GraphQL_Json | null;
  prominentInstructions: GetGuideForCandidateJourney_guide_currentVisibleStage_events_prominentInstructions[];
  interviewers: GetGuideForCandidateJourney_guide_currentVisibleStage_events_interviewers[] | null;
}

export interface GetGuideForCandidateJourney_guide_currentVisibleStage_stageTemplate {
  __typename: "StageTemplate";
  id: string;
  name: string;
}

export interface GetGuideForCandidateJourney_guide_currentVisibleStage_emails {
  __typename: "EmailActivity";
  id: string;
}

export interface GetGuideForCandidateJourney_guide_currentVisibleStage {
  __typename: "Stage";
  id: string;
  atsStageId: string | null;
  title: string;
  position: number;
  hidden: boolean;
  activatedAt: GraphQL_DateTime | null;
  activatedOverride: boolean | null;
  events: GetGuideForCandidateJourney_guide_currentVisibleStage_events[];
  stageTemplate: GetGuideForCandidateJourney_guide_currentVisibleStage_stageTemplate;
  emails: GetGuideForCandidateJourney_guide_currentVisibleStage_emails[];
}

export interface GetGuideForCandidateJourney_guide_stages_events_prominentInstructions {
  __typename: "ProminentInstruction";
  id: string;
  label: string;
  url: string | null;
}

export interface GetGuideForCandidateJourney_guide_stages_events_interviewers {
  __typename: "InterviewerV2";
  id: string;
  biography: string;
  firstName: string;
  lastName: string;
  linkedInUrl: string;
  websiteUrls: string[];
  title: string;
  imageUrl: string;
  hasDefaultImage: boolean;
}

export interface GetGuideForCandidateJourney_guide_stages_events {
  __typename: "EventV2";
  id: string;
  title: string;
  rawTitle: string;
  hidden: boolean;
  remoteInstructions: string | null;
  description: string | null;
  rawDescription: string;
  startTime: GraphQL_DateTime | null;
  endTime: GraphQL_DateTime | null;
  techScreenLink: string | null;
  zoomLink: string | null;
  placeholders: GraphQL_Json | null;
  prominentInstructions: GetGuideForCandidateJourney_guide_stages_events_prominentInstructions[];
  interviewers: GetGuideForCandidateJourney_guide_stages_events_interviewers[] | null;
}

export interface GetGuideForCandidateJourney_guide_stages_stageTemplate {
  __typename: "StageTemplate";
  id: string;
  name: string;
}

export interface GetGuideForCandidateJourney_guide_stages_emails {
  __typename: "EmailActivity";
  id: string;
}

export interface GetGuideForCandidateJourney_guide_stages {
  __typename: "Stage";
  id: string;
  atsStageId: string | null;
  title: string;
  position: number;
  hidden: boolean;
  activatedAt: GraphQL_DateTime | null;
  activatedOverride: boolean | null;
  events: GetGuideForCandidateJourney_guide_stages_events[];
  stageTemplate: GetGuideForCandidateJourney_guide_stages_stageTemplate;
  emails: GetGuideForCandidateJourney_guide_stages_emails[];
}

export interface GetGuideForCandidateJourney_guide_candidate {
  __typename: "CandidateV2";
  id: string;
  emailAddresses: GraphQL_EmailAddress[];
  firstName: string;
  lastName: string;
  currentTimezone: string;
}

export interface GetGuideForCandidateJourney_guide_guideTemplate {
  __typename: "GuideTemplate";
  id: string;
}

export interface GetGuideForCandidateJourney_guide_jobRole {
  __typename: "JobRole";
  id: string;
  name: string;
}

export interface GetGuideForCandidateJourney_guide {
  __typename: "Guide";
  id: string;
  archivedAt: GraphQL_DateTime | null;
  atsUrl: string | null;
  journeyMetadata: GetGuideForCandidateJourney_guide_journeyMetadata;
  currentVisibleStage: GetGuideForCandidateJourney_guide_currentVisibleStage | null;
  stages: GetGuideForCandidateJourney_guide_stages[];
  candidate: GetGuideForCandidateJourney_guide_candidate;
  guideTemplate: GetGuideForCandidateJourney_guide_guideTemplate;
  jobRole: GetGuideForCandidateJourney_guide_jobRole;
  publicUrl: string;
}

export interface GetGuideForCandidateJourney {
  guide: GetGuideForCandidateJourney_guide | null;
}

export interface GetGuideForCandidateJourneyVariables {
  input: GuideFindInput;
  stagesInput?: GuideStagesInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchGuide
// ====================================================

export interface FetchGuide_guide_currentVisibleStage {
  __typename: "Stage";
  id: string;
  position: number;
  title: string;
}

export interface FetchGuide_guide_organization {
  __typename: "Organization";
  id: string;
  companyLogoUrl: string;
  name: string;
  theme: GraphQL_Json;
}

export interface FetchGuide_guide_stages {
  __typename: "Stage";
  id: string;
  position: number;
  title: string;
  hidden: boolean;
  needsFeedback: boolean;
}

export interface FetchGuide_guide {
  __typename: "Guide";
  id: string;
  atsUrl: string | null;
  chatEnabled: boolean;
  interviewProcessEnabled: boolean;
  currentVisibleStage: FetchGuide_guide_currentVisibleStage | null;
  organization: FetchGuide_guide_organization;
  stages: FetchGuide_guide_stages[];
}

export interface FetchGuide {
  guide: FetchGuide_guide | null;
}

export interface FetchGuideVariables {
  input: GuideFindInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddCandidateViewToGuideMutation
// ====================================================

export interface AddCandidateViewToGuideMutation_addCandidateViewToGuide_guide {
  __typename: "Guide";
  id: string;
  candidateOpens: GraphQL_DateTime[];
}

export interface AddCandidateViewToGuideMutation_addCandidateViewToGuide {
  __typename: "AddCandidateViewToGuideMutationResponse";
  message: string;
  code: string;
  success: boolean;
  guide: AddCandidateViewToGuideMutation_addCandidateViewToGuide_guide | null;
}

export interface AddCandidateViewToGuideMutation {
  addCandidateViewToGuide: AddCandidateViewToGuideMutation_addCandidateViewToGuide;
}

export interface AddCandidateViewToGuideMutationVariables {
  input: AddCandidateViewToGuideInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateRoleTitle
// ====================================================

export interface UpdateRoleTitle_updateGuide_jobRole {
  __typename: "JobRole";
  id: string;
  name: string;
}

export interface UpdateRoleTitle_updateGuide {
  __typename: "Guide";
  id: string;
  jobRole: UpdateRoleTitle_updateGuide_jobRole;
}

export interface UpdateRoleTitle {
  updateGuide: UpdateRoleTitle_updateGuide | null;
}

export interface UpdateRoleTitleVariables {
  input: GuideUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateEvent
// ====================================================

export interface UpdateEvent_updateEventV2 {
  __typename: "EventV2";
  id: string;
  title: string;
  description: string | null;
  remoteInstructions: string | null;
  techScreenLink: string | null;
  zoomLink: string | null;
}

export interface UpdateEvent {
  updateEventV2: UpdateEvent_updateEventV2 | null;
}

export interface UpdateEventVariables {
  input: EventV2UpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateEventTemplate
// ====================================================

export interface UpdateEventTemplate_eventTemplateUpdateForEvent {
  __typename: "EventTemplateV2";
  id: string;
  title: string | null;
  description: string | null;
}

export interface UpdateEventTemplate {
  eventTemplateUpdateForEvent: UpdateEventTemplate_eventTemplateUpdateForEvent;
}

export interface UpdateEventTemplateVariables {
  input: EventTemplateV2UpdateForEventInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchCustomerIntegrationsForInterview
// ====================================================

export interface FetchCustomerIntegrationsForInterview_currentUserV2_organization_customer {
  __typename: "Customer";
  id: string;
  coderPadEnabled: boolean | null;
  zoomEnabled: boolean | null;
}

export interface FetchCustomerIntegrationsForInterview_currentUserV2_organization {
  __typename: "Organization";
  id: string;
  customer: FetchCustomerIntegrationsForInterview_currentUserV2_organization_customer;
}

export interface FetchCustomerIntegrationsForInterview_currentUserV2 {
  __typename: "GuidesUser";
  id: string;
  organization: FetchCustomerIntegrationsForInterview_currentUserV2_organization;
}

export interface FetchCustomerIntegrationsForInterview {
  currentUserV2: FetchCustomerIntegrationsForInterview_currentUserV2 | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetEventForEditing
// ====================================================

export interface GetEventForEditing_event_interviewers {
  __typename: "InterviewerV2";
  id: string;
  biography: string;
  firstName: string;
  lastName: string;
  linkedInUrl: string;
  websiteUrls: string[];
  title: string;
  imageUrl: string;
}

export interface GetEventForEditing_event {
  __typename: "EventV2";
  id: string;
  title: string;
  description: string | null;
  remoteInstructions: string | null;
  techScreenLink: string | null;
  zoomLink: string | null;
  interviewers: GetEventForEditing_event_interviewers[] | null;
}

export interface GetEventForEditing {
  event: GetEventForEditing_event | null;
}

export interface GetEventForEditingVariables {
  input: EventQueryInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateInterview
// ====================================================

export interface UpdateInterview_updateInterviewerV2 {
  __typename: "InterviewerV2";
  id: string;
  biography: string;
  firstName: string;
  lastName: string;
  linkedInUrl: string;
  websiteUrls: string[];
  imageUrl: string;
  title: string;
}

export interface UpdateInterview {
  updateInterviewerV2: UpdateInterview_updateInterviewerV2 | null;
}

export interface UpdateInterviewVariables {
  input: InterviewerV2UpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetInterviewerForEditing
// ====================================================

export interface GetInterviewerForEditing_interviewer {
  __typename: "InterviewerV2";
  id: string;
  biography: string;
  firstName: string;
  lastName: string;
  linkedInUrl: string;
  websiteUrls: string[];
  title: string;
  imageUrl: string;
}

export interface GetInterviewerForEditing {
  interviewer: GetInterviewerForEditing_interviewer | null;
}

export interface GetInterviewerForEditingVariables {
  input: InterviewerQueryInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetGuideForGreenhouseCandidate
// ====================================================

export interface GetGuideForGreenhouseCandidate_guide_currentVisibleStage_events_interviewers {
  __typename: "InterviewerV2";
  id: string;
  biography: string;
  firstName: string;
  lastName: string;
  linkedInUrl: string;
  websiteUrls: string[];
  title: string;
  imageUrl: string;
}

export interface GetGuideForGreenhouseCandidate_guide_currentVisibleStage_events {
  __typename: "EventV2";
  id: string;
  title: string;
  hidden: boolean;
  description: string | null;
  startTime: GraphQL_DateTime | null;
  endTime: GraphQL_DateTime | null;
  techScreenLink: string | null;
  zoomLink: string | null;
  interviewers: GetGuideForGreenhouseCandidate_guide_currentVisibleStage_events_interviewers[] | null;
}

export interface GetGuideForGreenhouseCandidate_guide_currentVisibleStage_stageTemplate {
  __typename: "StageTemplate";
  id: string;
  name: string;
}

export interface GetGuideForGreenhouseCandidate_guide_currentVisibleStage_emails {
  __typename: "EmailActivity";
  id: string;
}

export interface GetGuideForGreenhouseCandidate_guide_currentVisibleStage {
  __typename: "Stage";
  id: string;
  atsStageId: string | null;
  title: string;
  position: number;
  hidden: boolean;
  events: GetGuideForGreenhouseCandidate_guide_currentVisibleStage_events[];
  stageTemplate: GetGuideForGreenhouseCandidate_guide_currentVisibleStage_stageTemplate;
  emails: GetGuideForGreenhouseCandidate_guide_currentVisibleStage_emails[];
}

export interface GetGuideForGreenhouseCandidate_guide_stages_events_interviewers {
  __typename: "InterviewerV2";
  id: string;
  biography: string;
  firstName: string;
  lastName: string;
  linkedInUrl: string;
  websiteUrls: string[];
  title: string;
  imageUrl: string;
}

export interface GetGuideForGreenhouseCandidate_guide_stages_events {
  __typename: "EventV2";
  id: string;
  title: string;
  hidden: boolean;
  description: string | null;
  startTime: GraphQL_DateTime | null;
  endTime: GraphQL_DateTime | null;
  techScreenLink: string | null;
  zoomLink: string | null;
  interviewers: GetGuideForGreenhouseCandidate_guide_stages_events_interviewers[] | null;
}

export interface GetGuideForGreenhouseCandidate_guide_stages_stageTemplate {
  __typename: "StageTemplate";
  id: string;
  name: string;
}

export interface GetGuideForGreenhouseCandidate_guide_stages_emails {
  __typename: "EmailActivity";
  id: string;
}

export interface GetGuideForGreenhouseCandidate_guide_stages {
  __typename: "Stage";
  id: string;
  atsStageId: string | null;
  title: string;
  position: number;
  hidden: boolean;
  events: GetGuideForGreenhouseCandidate_guide_stages_events[];
  stageTemplate: GetGuideForGreenhouseCandidate_guide_stages_stageTemplate;
  emails: GetGuideForGreenhouseCandidate_guide_stages_emails[];
}

export interface GetGuideForGreenhouseCandidate_guide_candidate {
  __typename: "CandidateV2";
  id: string;
  emailAddresses: GraphQL_EmailAddress[];
  firstName: string;
  lastName: string;
  currentTimezone: string;
}

export interface GetGuideForGreenhouseCandidate_guide_guideTemplate {
  __typename: "GuideTemplate";
  id: string;
}

export interface GetGuideForGreenhouseCandidate_guide_jobRole {
  __typename: "JobRole";
  id: string;
  name: string;
}

export interface GetGuideForGreenhouseCandidate_guide {
  __typename: "Guide";
  id: string;
  archivedAt: GraphQL_DateTime | null;
  atsUrl: string | null;
  currentVisibleStage: GetGuideForGreenhouseCandidate_guide_currentVisibleStage | null;
  stages: GetGuideForGreenhouseCandidate_guide_stages[];
  candidate: GetGuideForGreenhouseCandidate_guide_candidate;
  guideTemplate: GetGuideForGreenhouseCandidate_guide_guideTemplate;
  jobRole: GetGuideForGreenhouseCandidate_guide_jobRole;
  publicUrl: string;
}

export interface GetGuideForGreenhouseCandidate {
  guide: GetGuideForGreenhouseCandidate_guide | null;
}

export interface GetGuideForGreenhouseCandidateVariables {
  input: GuideFindInput;
  stagesInput?: GuideStagesInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SyncGuideForGreenhouseCandidate
// ====================================================

export interface SyncGuideForGreenhouseCandidate_syncGuideWithATS_currentVisibleStage_events_interviewers {
  __typename: "InterviewerV2";
  id: string;
  biography: string;
  firstName: string;
  lastName: string;
  linkedInUrl: string;
  websiteUrls: string[];
  title: string;
  imageUrl: string;
}

export interface SyncGuideForGreenhouseCandidate_syncGuideWithATS_currentVisibleStage_events {
  __typename: "EventV2";
  id: string;
  title: string;
  hidden: boolean;
  description: string | null;
  startTime: GraphQL_DateTime | null;
  endTime: GraphQL_DateTime | null;
  techScreenLink: string | null;
  zoomLink: string | null;
  interviewers: SyncGuideForGreenhouseCandidate_syncGuideWithATS_currentVisibleStage_events_interviewers[] | null;
}

export interface SyncGuideForGreenhouseCandidate_syncGuideWithATS_currentVisibleStage_stageTemplate {
  __typename: "StageTemplate";
  id: string;
  name: string;
}

export interface SyncGuideForGreenhouseCandidate_syncGuideWithATS_currentVisibleStage_emails {
  __typename: "EmailActivity";
  id: string;
}

export interface SyncGuideForGreenhouseCandidate_syncGuideWithATS_currentVisibleStage {
  __typename: "Stage";
  id: string;
  atsStageId: string | null;
  title: string;
  position: number;
  hidden: boolean;
  events: SyncGuideForGreenhouseCandidate_syncGuideWithATS_currentVisibleStage_events[];
  stageTemplate: SyncGuideForGreenhouseCandidate_syncGuideWithATS_currentVisibleStage_stageTemplate;
  emails: SyncGuideForGreenhouseCandidate_syncGuideWithATS_currentVisibleStage_emails[];
}

export interface SyncGuideForGreenhouseCandidate_syncGuideWithATS_stages_events_interviewers {
  __typename: "InterviewerV2";
  id: string;
  biography: string;
  firstName: string;
  lastName: string;
  linkedInUrl: string;
  websiteUrls: string[];
  title: string;
  imageUrl: string;
}

export interface SyncGuideForGreenhouseCandidate_syncGuideWithATS_stages_events {
  __typename: "EventV2";
  id: string;
  title: string;
  hidden: boolean;
  description: string | null;
  startTime: GraphQL_DateTime | null;
  endTime: GraphQL_DateTime | null;
  techScreenLink: string | null;
  zoomLink: string | null;
  interviewers: SyncGuideForGreenhouseCandidate_syncGuideWithATS_stages_events_interviewers[] | null;
}

export interface SyncGuideForGreenhouseCandidate_syncGuideWithATS_stages_stageTemplate {
  __typename: "StageTemplate";
  id: string;
  name: string;
}

export interface SyncGuideForGreenhouseCandidate_syncGuideWithATS_stages_emails {
  __typename: "EmailActivity";
  id: string;
}

export interface SyncGuideForGreenhouseCandidate_syncGuideWithATS_stages {
  __typename: "Stage";
  id: string;
  atsStageId: string | null;
  title: string;
  position: number;
  hidden: boolean;
  events: SyncGuideForGreenhouseCandidate_syncGuideWithATS_stages_events[];
  stageTemplate: SyncGuideForGreenhouseCandidate_syncGuideWithATS_stages_stageTemplate;
  emails: SyncGuideForGreenhouseCandidate_syncGuideWithATS_stages_emails[];
}

export interface SyncGuideForGreenhouseCandidate_syncGuideWithATS_candidate {
  __typename: "CandidateV2";
  id: string;
  emailAddresses: GraphQL_EmailAddress[];
  firstName: string;
  lastName: string;
  currentTimezone: string;
}

export interface SyncGuideForGreenhouseCandidate_syncGuideWithATS_guideTemplate {
  __typename: "GuideTemplate";
  id: string;
}

export interface SyncGuideForGreenhouseCandidate_syncGuideWithATS_jobRole {
  __typename: "JobRole";
  id: string;
  name: string;
}

export interface SyncGuideForGreenhouseCandidate_syncGuideWithATS {
  __typename: "Guide";
  id: string;
  archivedAt: GraphQL_DateTime | null;
  atsUrl: string | null;
  currentVisibleStage: SyncGuideForGreenhouseCandidate_syncGuideWithATS_currentVisibleStage | null;
  stages: SyncGuideForGreenhouseCandidate_syncGuideWithATS_stages[];
  candidate: SyncGuideForGreenhouseCandidate_syncGuideWithATS_candidate;
  guideTemplate: SyncGuideForGreenhouseCandidate_syncGuideWithATS_guideTemplate;
  jobRole: SyncGuideForGreenhouseCandidate_syncGuideWithATS_jobRole;
  publicUrl: string;
}

export interface SyncGuideForGreenhouseCandidate {
  syncGuideWithATS: SyncGuideForGreenhouseCandidate_syncGuideWithATS;
}

export interface SyncGuideForGreenhouseCandidateVariables {
  input: GuideSyncInput;
  stagesInput?: GuideStagesInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateStage
// ====================================================

export interface UpdateStage_updateStage_events_interviewers {
  __typename: "InterviewerV2";
  id: string;
  biography: string;
  firstName: string;
  lastName: string;
  linkedInUrl: string;
  websiteUrls: string[];
  title: string;
  imageUrl: string;
}

export interface UpdateStage_updateStage_events {
  __typename: "EventV2";
  id: string;
  title: string;
  hidden: boolean;
  description: string | null;
  startTime: GraphQL_DateTime | null;
  endTime: GraphQL_DateTime | null;
  techScreenLink: string | null;
  zoomLink: string | null;
  interviewers: UpdateStage_updateStage_events_interviewers[] | null;
}

export interface UpdateStage_updateStage_stageTemplate {
  __typename: "StageTemplate";
  id: string;
  name: string;
}

export interface UpdateStage_updateStage_emails {
  __typename: "EmailActivity";
  id: string;
}

export interface UpdateStage_updateStage {
  __typename: "Stage";
  id: string;
  atsStageId: string | null;
  title: string;
  position: number;
  hidden: boolean;
  events: UpdateStage_updateStage_events[];
  stageTemplate: UpdateStage_updateStage_stageTemplate;
  emails: UpdateStage_updateStage_emails[];
}

export interface UpdateStage {
  updateStage: UpdateStage_updateStage;
}

export interface UpdateStageVariables {
  input: UpdateStageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: HideEvent
// ====================================================

export interface HideEvent_updateEventV2 {
  __typename: "EventV2";
  id: string;
  hidden: boolean;
}

export interface HideEvent {
  updateEventV2: HideEvent_updateEventV2 | null;
}

export interface HideEventVariables {
  input: EventV2UpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateEventTemplateHidden
// ====================================================

export interface UpdateEventTemplateHidden_eventTemplateUpdateForEvent {
  __typename: "EventTemplateV2";
  id: string;
  hidden: boolean | null;
}

export interface UpdateEventTemplateHidden {
  eventTemplateUpdateForEvent: UpdateEventTemplateHidden_eventTemplateUpdateForEvent;
}

export interface UpdateEventTemplateHiddenVariables {
  input: EventTemplateV2UpdateForEventInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchSendGuideData
// ====================================================

export interface FetchSendGuideData_currentUserV2_organization_stageTemplates {
  __typename: "StageTemplate";
  id: string;
  name: string;
}

export interface FetchSendGuideData_currentUserV2_organization_locations {
  __typename: "Location";
  id: string;
  address: string;
  name: string;
}

export interface FetchSendGuideData_currentUserV2_organization_emailTemplates {
  __typename: "EmailTemplateV2";
  id: string;
  body: string;
  subject: string;
  name: string;
}

export interface FetchSendGuideData_currentUserV2_organization {
  __typename: "Organization";
  id: string;
  name: string;
  stageTemplates: FetchSendGuideData_currentUserV2_organization_stageTemplates[];
  locations: FetchSendGuideData_currentUserV2_organization_locations[];
  emailTemplates: FetchSendGuideData_currentUserV2_organization_emailTemplates[];
}

export interface FetchSendGuideData_currentUserV2 {
  __typename: "GuidesUser";
  id: string;
  firstName: string;
  lastName: string;
  primaryEmail: string | null;
  organization: FetchSendGuideData_currentUserV2_organization;
}

export interface FetchSendGuideData_candidate {
  __typename: "CandidateV2";
  id: string;
  firstName: string;
  recruiterEmailAddress: string | null;
  coordinatorEmailAddress: string | null;
  currentTimezone: string;
  emailAddresses: GraphQL_EmailAddress[];
}

export interface FetchSendGuideData_guide_currentVisibleStage_icalAttachedFiles {
  __typename: "AttachedFile";
  id: string;
  filename: string;
}

export interface FetchSendGuideData_guide_currentVisibleStage_sortedVisibleEvents {
  __typename: "EventV2";
  id: string;
  title: string;
  description: string | null;
  startTime: GraphQL_DateTime | null;
  endTime: GraphQL_DateTime | null;
  techScreenLink: string | null;
  zoomLink: string | null;
}

export interface FetchSendGuideData_guide_currentVisibleStage {
  __typename: "Stage";
  id: string;
  icalAttachedFiles: FetchSendGuideData_guide_currentVisibleStage_icalAttachedFiles[];
  sortedVisibleEvents: FetchSendGuideData_guide_currentVisibleStage_sortedVisibleEvents[];
}

export interface FetchSendGuideData_guide {
  __typename: "Guide";
  id: string;
  publicUrl: string;
  atsUrl: string | null;
  replacementData: GraphQL_Json;
  currentVisibleStage: FetchSendGuideData_guide_currentVisibleStage | null;
}

export interface FetchSendGuideData {
  currentUserV2: FetchSendGuideData_currentUserV2 | null;
  candidate: FetchSendGuideData_candidate;
  guide: FetchSendGuideData_guide | null;
}

export interface FetchSendGuideDataVariables {
  input: CandidateFindOneInput;
  guideInput: GuideFindInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendGuideEmail
// ====================================================

export interface SendGuideEmail_sendGuide {
  __typename: "EmailActivity";
  id: string;
}

export interface SendGuideEmail {
  sendGuide: SendGuideEmail_sendGuide;
}

export interface SendGuideEmailVariables {
  input: SendGuideInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchStageTemplatesForTemp
// ====================================================

export interface FetchStageTemplatesForTemp_currentUserV2_organization_stageTemplates {
  __typename: "StageTemplate";
  id: string;
  name: string;
}

export interface FetchStageTemplatesForTemp_currentUserV2_organization {
  __typename: "Organization";
  id: string;
  stageTemplates: FetchStageTemplatesForTemp_currentUserV2_organization_stageTemplates[];
}

export interface FetchStageTemplatesForTemp_currentUserV2 {
  __typename: "GuidesUser";
  id: string;
  organization: FetchStageTemplatesForTemp_currentUserV2_organization;
}

export interface FetchStageTemplatesForTemp {
  currentUserV2: FetchStageTemplatesForTemp_currentUserV2 | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindLocationModuleForCurrentStage
// ====================================================

export interface FindLocationModuleForCurrentStage_stage_modules {
  __typename: "StageModule";
  id: string;
  type: string;
  data: GraphQL_Json;
}

export interface FindLocationModuleForCurrentStage_stage {
  __typename: "Stage";
  id: string;
  modules: FindLocationModuleForCurrentStage_stage_modules[];
}

export interface FindLocationModuleForCurrentStage {
  stage: FindLocationModuleForCurrentStage_stage | null;
}

export interface FindLocationModuleForCurrentStageVariables {
  input: StageFindInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateStageTemplateForStage
// ====================================================

export interface UpdateStageTemplateForStage_updateStageTemplateForStage_modules {
  __typename: "StageModule";
  id: string;
  type: string;
  data: GraphQL_Json;
}

export interface UpdateStageTemplateForStage_updateStageTemplateForStage {
  __typename: "Stage";
  id: string;
  modules: UpdateStageTemplateForStage_updateStageTemplateForStage_modules[];
}

export interface UpdateStageTemplateForStage {
  updateStageTemplateForStage: UpdateStageTemplateForStage_updateStageTemplateForStage;
}

export interface UpdateStageTemplateForStageVariables {
  input: UpdateStageTemplateForStageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateRemoteInstructionsForStage
// ====================================================

export interface UpdateRemoteInstructionsForStage_updateLocationModuleInstallationForStage_modules {
  __typename: "StageModule";
  id: string;
  type: string;
  data: GraphQL_Json;
}

export interface UpdateRemoteInstructionsForStage_updateLocationModuleInstallationForStage {
  __typename: "Stage";
  id: string;
  modules: UpdateRemoteInstructionsForStage_updateLocationModuleInstallationForStage_modules[];
}

export interface UpdateRemoteInstructionsForStage {
  updateLocationModuleInstallationForStage: UpdateRemoteInstructionsForStage_updateLocationModuleInstallationForStage;
}

export interface UpdateRemoteInstructionsForStageVariables {
  input: UpdateLocationModuleInstallationForStageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ArchiveGuide
// ====================================================

export interface ArchiveGuide_archiveGuide {
  __typename: "ArchiveGuideMutationResponse";
  success: boolean;
}

export interface ArchiveGuide {
  archiveGuide: ArchiveGuide_archiveGuide | null;
}

export interface ArchiveGuideVariables {
  input: ArchiveGuideInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UnarchiveGuide
// ====================================================

export interface UnarchiveGuide_unarchiveGuide_guide {
  __typename: "Guide";
  id: string;
  archivedAt: GraphQL_DateTime | null;
}

export interface UnarchiveGuide_unarchiveGuide {
  __typename: "UnarchiveGuideMutationResponse";
  success: boolean;
  guide: UnarchiveGuide_unarchiveGuide_guide | null;
}

export interface UnarchiveGuide {
  unarchiveGuide: UnarchiveGuide_unarchiveGuide | null;
}

export interface UnarchiveGuideVariables {
  input: UnarchiveGuideInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FeedbackForOrganization
// ====================================================

export interface FeedbackForOrganization_currentUserV2_organization_candidateFeedbackStats_comments {
  __typename: "Comment";
  id: string;
  emoji: string;
  whatWentWell: string[] | null;
  whatCouldBeBetter: string[] | null;
  comment: string;
}

export interface FeedbackForOrganization_currentUserV2_organization_candidateFeedbackStats {
  __typename: "CandidateFeedbackStats";
  averageRating: number | null;
  whatWentWell: string[];
  whatCouldBeBetter: string[];
  comments: FeedbackForOrganization_currentUserV2_organization_candidateFeedbackStats_comments[];
}

export interface FeedbackForOrganization_currentUserV2_organization {
  __typename: "Organization";
  id: string;
  candidateFeedbackStats: FeedbackForOrganization_currentUserV2_organization_candidateFeedbackStats;
}

export interface FeedbackForOrganization_currentUserV2 {
  __typename: "GuidesUser";
  id: string;
  organization: FeedbackForOrganization_currentUserV2_organization;
}

export interface FeedbackForOrganization {
  currentUserV2: FeedbackForOrganization_currentUserV2 | null;
}

export interface FeedbackForOrganizationVariables {
  feedbackInput?: FeedbackForOrganizationInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchJobRolesForFeedback
// ====================================================

export interface FetchJobRolesForFeedback_currentUserV2_organization_jobRoles {
  __typename: "JobRole";
  id: string;
  name: string;
}

export interface FetchJobRolesForFeedback_currentUserV2_organization {
  __typename: "Organization";
  id: string;
  jobRoles: FetchJobRolesForFeedback_currentUserV2_organization_jobRoles[];
}

export interface FetchJobRolesForFeedback_currentUserV2 {
  __typename: "GuidesUser";
  _id: string;
  organization: FetchJobRolesForFeedback_currentUserV2_organization;
}

export interface FetchJobRolesForFeedback {
  currentUserV2: FetchJobRolesForFeedback_currentUserV2 | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchGuides
// ====================================================

export interface FetchGuides_currentUserV2_organization_guides_candidate {
  __typename: "CandidateV2";
  id: string;
  firstName: string;
  lastName: string;
}

export interface FetchGuides_currentUserV2_organization_guides_jobRole {
  __typename: "JobRole";
  id: string;
  name: string;
}

export interface FetchGuides_currentUserV2_organization_guides_currentStage {
  __typename: "Stage";
  id: string;
  date: GraphQL_DateTime | null;
  lastSentAt: GraphQL_DateTime | null;
}

export interface FetchGuides_currentUserV2_organization_guides {
  __typename: "Guide";
  id: string;
  archivedAt: GraphQL_DateTime | null;
  atsUrl: string | null;
  candidate: FetchGuides_currentUserV2_organization_guides_candidate;
  jobRole: FetchGuides_currentUserV2_organization_guides_jobRole;
  currentStage: FetchGuides_currentUserV2_organization_guides_currentStage | null;
  candidateOpens: GraphQL_DateTime[];
}

export interface FetchGuides_currentUserV2_organization {
  __typename: "Organization";
  id: string;
  guides: FetchGuides_currentUserV2_organization_guides[];
}

export interface FetchGuides_currentUserV2 {
  __typename: "GuidesUser";
  id: string;
  organization: FetchGuides_currentUserV2_organization;
}

export interface FetchGuides {
  currentUserV2: FetchGuides_currentUserV2 | null;
}

export interface FetchGuidesVariables {
  input: GuidesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuideForShareGuide
// ====================================================

export interface GuideForShareGuide_guide_candidate {
  __typename: "CandidateV2";
  id: string;
  firstName: string;
  lastName: string;
  emailAddresses: GraphQL_EmailAddress[];
  currentTimezone: string;
}

export interface GuideForShareGuide_guide_emails {
  __typename: "EmailActivity";
  id: string;
  createdAt: GraphQL_DateTime;
  sentTo: string[];
}

export interface GuideForShareGuide_guide_currentStage_stageTemplate_emailTemplate {
  __typename: "EmailTemplateV2";
  id: string;
  body: string;
  subject: string;
}

export interface GuideForShareGuide_guide_currentStage_stageTemplate {
  __typename: "StageTemplate";
  id: string;
  emailTemplate: GuideForShareGuide_guide_currentStage_stageTemplate_emailTemplate;
}

export interface GuideForShareGuide_guide_currentStage_events {
  __typename: "EventV2";
  id: string;
  startTime: GraphQL_DateTime | null;
  endTime: GraphQL_DateTime | null;
}

export interface GuideForShareGuide_guide_currentStage {
  __typename: "Stage";
  id: string;
  stageTemplate: GuideForShareGuide_guide_currentStage_stageTemplate;
  events: GuideForShareGuide_guide_currentStage_events[];
  hasEmailActivity: boolean;
}

export interface GuideForShareGuide_guide_organization {
  __typename: "Organization";
  id: string;
  name: string;
}

export interface GuideForShareGuide_guide {
  __typename: "Guide";
  id: string;
  publicUrl: string;
  candidate: GuideForShareGuide_guide_candidate;
  emails: GuideForShareGuide_guide_emails[];
  currentStage: GuideForShareGuide_guide_currentStage | null;
  organization: GuideForShareGuide_guide_organization;
}

export interface GuideForShareGuide_currentUserV2 {
  __typename: "GuidesUser";
  id: string;
  primaryEmail: string | null;
  firstName: string;
  lastName: string;
  canSendEmail: boolean;
}

export interface GuideForShareGuide {
  guide: GuideForShareGuide_guide | null;
  currentUserV2: GuideForShareGuide_currentUserV2 | null;
}

export interface GuideForShareGuideVariables {
  input: GuideFindInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendGuideFromShareModal
// ====================================================

export interface SendGuideFromShareModal_sendGuideV2_guide_currentStage {
  __typename: "Stage";
  id: string;
  lastSentAt: GraphQL_DateTime | null;
}

export interface SendGuideFromShareModal_sendGuideV2_guide_emails {
  __typename: "EmailActivity";
  id: string;
  createdAt: GraphQL_DateTime;
}

export interface SendGuideFromShareModal_sendGuideV2_guide {
  __typename: "Guide";
  id: string;
  currentStage: SendGuideFromShareModal_sendGuideV2_guide_currentStage | null;
  emails: SendGuideFromShareModal_sendGuideV2_guide_emails[];
}

export interface SendGuideFromShareModal_sendGuideV2 {
  __typename: "SendGuideMutationResponse";
  guide: SendGuideFromShareModal_sendGuideV2_guide | null;
}

export interface SendGuideFromShareModal {
  sendGuideV2: SendGuideFromShareModal_sendGuideV2;
}

export interface SendGuideFromShareModalVariables {
  input: SendGuideInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DisconnectCoderPadIntegrationForCustomer
// ====================================================

export interface DisconnectCoderPadIntegrationForCustomer_disconnectCustomerCoderPadIntegration_customer_integration_SlackIntegrationV2 {
  __typename: "SlackIntegrationV2" | "GreenhouseIntegrationV2" | "ZoomIntegration";
  id: string;
  type: IntegrationEnum;
  issues: string[];
  connected: boolean;
}

export interface DisconnectCoderPadIntegrationForCustomer_disconnectCustomerCoderPadIntegration_customer_integration_CoderPadIntegration {
  __typename: "CoderPadIntegration";
  id: string;
  type: IntegrationEnum;
  issues: string[];
  connected: boolean;
  apiKeySuffix: string | null;
}

export type DisconnectCoderPadIntegrationForCustomer_disconnectCustomerCoderPadIntegration_customer_integration = DisconnectCoderPadIntegrationForCustomer_disconnectCustomerCoderPadIntegration_customer_integration_SlackIntegrationV2 | DisconnectCoderPadIntegrationForCustomer_disconnectCustomerCoderPadIntegration_customer_integration_CoderPadIntegration;

export interface DisconnectCoderPadIntegrationForCustomer_disconnectCustomerCoderPadIntegration_customer {
  __typename: "Customer";
  id: string;
  integration: DisconnectCoderPadIntegrationForCustomer_disconnectCustomerCoderPadIntegration_customer_integration | null;
}

export interface DisconnectCoderPadIntegrationForCustomer_disconnectCustomerCoderPadIntegration {
  __typename: "CustomerIntegrationMutationResponse";
  success: boolean;
  message: string;
  code: string;
  customer: DisconnectCoderPadIntegrationForCustomer_disconnectCustomerCoderPadIntegration_customer | null;
}

export interface DisconnectCoderPadIntegrationForCustomer {
  disconnectCustomerCoderPadIntegration: DisconnectCoderPadIntegrationForCustomer_disconnectCustomerCoderPadIntegration;
}

export interface DisconnectCoderPadIntegrationForCustomerVariables {
  typeInput: IntegrationByTypeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CoderPadIntegrationForCustomer
// ====================================================

export interface CoderPadIntegrationForCustomer_currentUserV2_organization_customer_integration_SlackIntegrationV2 {
  __typename: "SlackIntegrationV2" | "GreenhouseIntegrationV2" | "ZoomIntegration";
  id: string;
  type: IntegrationEnum;
  issues: string[];
  connected: boolean;
}

export interface CoderPadIntegrationForCustomer_currentUserV2_organization_customer_integration_CoderPadIntegration {
  __typename: "CoderPadIntegration";
  id: string;
  type: IntegrationEnum;
  issues: string[];
  connected: boolean;
  apiKeySuffix: string | null;
}

export type CoderPadIntegrationForCustomer_currentUserV2_organization_customer_integration = CoderPadIntegrationForCustomer_currentUserV2_organization_customer_integration_SlackIntegrationV2 | CoderPadIntegrationForCustomer_currentUserV2_organization_customer_integration_CoderPadIntegration;

export interface CoderPadIntegrationForCustomer_currentUserV2_organization_customer {
  __typename: "Customer";
  id: string;
  integration: CoderPadIntegrationForCustomer_currentUserV2_organization_customer_integration | null;
}

export interface CoderPadIntegrationForCustomer_currentUserV2_organization {
  __typename: "Organization";
  id: string;
  customer: CoderPadIntegrationForCustomer_currentUserV2_organization_customer;
}

export interface CoderPadIntegrationForCustomer_currentUserV2 {
  __typename: "GuidesUser";
  id: string;
  organization: CoderPadIntegrationForCustomer_currentUserV2_organization;
}

export interface CoderPadIntegrationForCustomer {
  currentUserV2: CoderPadIntegrationForCustomer_currentUserV2 | null;
}

export interface CoderPadIntegrationForCustomerVariables {
  typeInput: IntegrationByTypeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCoderPadIntegrationForCustomer
// ====================================================

export interface UpdateCoderPadIntegrationForCustomer_updateCustomerCoderPadIntegration_customer_integration_SlackIntegrationV2 {
  __typename: "SlackIntegrationV2" | "GreenhouseIntegrationV2" | "ZoomIntegration";
  id: string;
  type: IntegrationEnum;
  issues: string[];
  connected: boolean;
}

export interface UpdateCoderPadIntegrationForCustomer_updateCustomerCoderPadIntegration_customer_integration_CoderPadIntegration {
  __typename: "CoderPadIntegration";
  id: string;
  type: IntegrationEnum;
  issues: string[];
  connected: boolean;
  apiKeySuffix: string | null;
}

export type UpdateCoderPadIntegrationForCustomer_updateCustomerCoderPadIntegration_customer_integration = UpdateCoderPadIntegrationForCustomer_updateCustomerCoderPadIntegration_customer_integration_SlackIntegrationV2 | UpdateCoderPadIntegrationForCustomer_updateCustomerCoderPadIntegration_customer_integration_CoderPadIntegration;

export interface UpdateCoderPadIntegrationForCustomer_updateCustomerCoderPadIntegration_customer {
  __typename: "Customer";
  id: string;
  integration: UpdateCoderPadIntegrationForCustomer_updateCustomerCoderPadIntegration_customer_integration | null;
}

export interface UpdateCoderPadIntegrationForCustomer_updateCustomerCoderPadIntegration {
  __typename: "CustomerIntegrationMutationResponse";
  success: boolean;
  message: string;
  code: string;
  customer: UpdateCoderPadIntegrationForCustomer_updateCustomerCoderPadIntegration_customer | null;
}

export interface UpdateCoderPadIntegrationForCustomer {
  updateCustomerCoderPadIntegration: UpdateCoderPadIntegrationForCustomer_updateCustomerCoderPadIntegration;
}

export interface UpdateCoderPadIntegrationForCustomerVariables {
  input: UpdateCustomerCoderPadIntegrationInput;
  typeInput: IntegrationByTypeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateOrganizationTheme
// ====================================================

export interface UpdateOrganizationTheme_updateOrganizationTheme {
  __typename: "Organization";
  id: string;
  theme: GraphQL_Json;
}

export interface UpdateOrganizationTheme {
  updateOrganizationTheme: UpdateOrganizationTheme_updateOrganizationTheme;
}

export interface UpdateOrganizationThemeVariables {
  input: UpdateOrganizationThemeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchCompanyData
// ====================================================

export interface FetchCompanyData_currentUserV2_organization_locations {
  __typename: "Location";
  id: string;
  name: string;
  address: string;
  coordinates: string[];
  isArchived: boolean;
}

export interface FetchCompanyData_currentUserV2_organization_values {
  __typename: "Value";
  id: string;
  title: string;
  description: string | null;
}

export interface FetchCompanyData_currentUserV2_organization_customLogistics {
  __typename: "CustomLogistic";
  id: string;
  emoji: string;
  title: string;
  description: string | null;
}

export interface FetchCompanyData_currentUserV2_organization {
  __typename: "Organization";
  id: string;
  name: string;
  locations: FetchCompanyData_currentUserV2_organization_locations[];
  missionStatement: string;
  theme: GraphQL_Json;
  companyLogoUrl: string;
  defaultAvatarImageUrl: string;
  values: FetchCompanyData_currentUserV2_organization_values[];
  customLogistics: FetchCompanyData_currentUserV2_organization_customLogistics[];
}

export interface FetchCompanyData_currentUserV2 {
  __typename: "GuidesUser";
  id: string;
  organization: FetchCompanyData_currentUserV2_organization;
}

export interface FetchCompanyData {
  currentUserV2: FetchCompanyData_currentUserV2 | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateLogisticForOrganization
// ====================================================

export interface CreateLogisticForOrganization_createLogisticForOrganization_organization_customLogistics {
  __typename: "CustomLogistic";
  id: string;
  emoji: string;
  title: string;
  description: string | null;
}

export interface CreateLogisticForOrganization_createLogisticForOrganization_organization {
  __typename: "Organization";
  id: string;
  customLogistics: CreateLogisticForOrganization_createLogisticForOrganization_organization_customLogistics[];
}

export interface CreateLogisticForOrganization_createLogisticForOrganization {
  __typename: "CreateLogisticForOrganizationMutationResponse";
  success: boolean;
  organization: CreateLogisticForOrganization_createLogisticForOrganization_organization | null;
}

export interface CreateLogisticForOrganization {
  createLogisticForOrganization: CreateLogisticForOrganization_createLogisticForOrganization;
}

export interface CreateLogisticForOrganizationVariables {
  input: CreateLogisticForOrganizationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateLogisticForOrganization
// ====================================================

export interface UpdateLogisticForOrganization_updateLogisticForOrganization_customLogistic {
  __typename: "CustomLogistic";
  id: string;
  emoji: string;
  title: string;
  description: string | null;
}

export interface UpdateLogisticForOrganization_updateLogisticForOrganization {
  __typename: "UpdateLogisticForOrganizationMutationResponse";
  success: boolean;
  customLogistic: UpdateLogisticForOrganization_updateLogisticForOrganization_customLogistic | null;
}

export interface UpdateLogisticForOrganization {
  updateLogisticForOrganization: UpdateLogisticForOrganization_updateLogisticForOrganization;
}

export interface UpdateLogisticForOrganizationVariables {
  input: UpdateLogisticForOrganizationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteLogisticForOrganization
// ====================================================

export interface DeleteLogisticForOrganization_deleteLogisticForOrganization_organization_customLogistics {
  __typename: "CustomLogistic";
  id: string;
  emoji: string;
  title: string;
  description: string | null;
}

export interface DeleteLogisticForOrganization_deleteLogisticForOrganization_organization {
  __typename: "Organization";
  id: string;
  customLogistics: DeleteLogisticForOrganization_deleteLogisticForOrganization_organization_customLogistics[];
}

export interface DeleteLogisticForOrganization_deleteLogisticForOrganization {
  __typename: "DeleteLogisticForOrganizationMutationResponse";
  success: boolean;
  organization: DeleteLogisticForOrganization_deleteLogisticForOrganization_organization | null;
}

export interface DeleteLogisticForOrganization {
  deleteLogisticForOrganization: DeleteLogisticForOrganization_deleteLogisticForOrganization;
}

export interface DeleteLogisticForOrganizationVariables {
  input: DeleteLogisticForOrganizationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateOrganizationLogo
// ====================================================

export interface UpdateOrganizationLogo_updateOrganizationLogo {
  __typename: "Organization";
  id: string;
  companyLogoUrl: string;
}

export interface UpdateOrganizationLogo {
  updateOrganizationLogo: UpdateOrganizationLogo_updateOrganizationLogo;
}

export interface UpdateOrganizationLogoVariables {
  input: UpdateOrganizationLogoInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateOrganizationInterviewerImage
// ====================================================

export interface UpdateOrganizationInterviewerImage_updateOrganizationInterviewerImage_organization {
  __typename: "Organization";
  id: string;
  defaultAvatarImageUrl: string;
}

export interface UpdateOrganizationInterviewerImage_updateOrganizationInterviewerImage {
  __typename: "UpdateOrganizationInterviewerImageMutationResponse";
  organization: UpdateOrganizationInterviewerImage_updateOrganizationInterviewerImage_organization | null;
}

export interface UpdateOrganizationInterviewerImage {
  updateOrganizationInterviewerImage: UpdateOrganizationInterviewerImage_updateOrganizationInterviewerImage;
}

export interface UpdateOrganizationInterviewerImageVariables {
  input: UpdateOrganizationInterviewerImageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LocationQueryForEditLocation
// ====================================================

export interface LocationQueryForEditLocation_location {
  __typename: "Location";
  id: string;
  name: string;
  arrivalInstructions: string;
  address: string;
  coordinates: string[];
}

export interface LocationQueryForEditLocation {
  location: LocationQueryForEditLocation_location | null;
}

export interface LocationQueryForEditLocationVariables {
  input: LocationFindInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LocationCreate
// ====================================================

export interface LocationCreate_locationCreateV2 {
  __typename: "Location";
  id: string;
  name: string;
  arrivalInstructions: string;
  address: string;
  coordinates: string[];
}

export interface LocationCreate {
  locationCreateV2: LocationCreate_locationCreateV2;
}

export interface LocationCreateVariables {
  input: LocationCreateInputV2;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LocationUpdate
// ====================================================

export interface LocationUpdate_locationUpdate {
  __typename: "Location";
  id: string;
  name: string;
  arrivalInstructions: string;
  address: string;
  coordinates: string[];
}

export interface LocationUpdate {
  locationUpdate: LocationUpdate_locationUpdate;
}

export interface LocationUpdateVariables {
  input: LocationUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ArchiveLocation
// ====================================================

export interface ArchiveLocation_archiveLocation_location {
  __typename: "Location";
  id: string;
  isArchived: boolean;
}

export interface ArchiveLocation_archiveLocation {
  __typename: "ArchiveLocationMutationResponse";
  success: boolean;
  location: ArchiveLocation_archiveLocation_location | null;
}

export interface ArchiveLocation {
  archiveLocation: ArchiveLocation_archiveLocation;
}

export interface ArchiveLocationVariables {
  input: ArchiveLocationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UnarchiveLocation
// ====================================================

export interface UnarchiveLocation_unarchiveLocation_location {
  __typename: "Location";
  id: string;
  name: string;
  arrivalInstructions: string;
  address: string;
  coordinates: string[];
}

export interface UnarchiveLocation_unarchiveLocation {
  __typename: "UnarchiveLocationMutationResponse";
  success: boolean;
  location: UnarchiveLocation_unarchiveLocation_location | null;
}

export interface UnarchiveLocation {
  unarchiveLocation: UnarchiveLocation_unarchiveLocation;
}

export interface UnarchiveLocationVariables {
  input: UnarchiveLocationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateValueForOrganization
// ====================================================

export interface CreateValueForOrganization_createValueForOrganization_values {
  __typename: "Value";
  id: string;
  title: string;
  description: string | null;
}

export interface CreateValueForOrganization_createValueForOrganization {
  __typename: "Organization";
  id: string;
  values: CreateValueForOrganization_createValueForOrganization_values[];
}

export interface CreateValueForOrganization {
  createValueForOrganization: CreateValueForOrganization_createValueForOrganization;
}

export interface CreateValueForOrganizationVariables {
  input: CreateValueForOrganizationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateValueForOrganization
// ====================================================

export interface UpdateValueForOrganization_updateValueForOrganization {
  __typename: "Value";
  id: string;
  title: string;
  description: string | null;
}

export interface UpdateValueForOrganization {
  updateValueForOrganization: UpdateValueForOrganization_updateValueForOrganization;
}

export interface UpdateValueForOrganizationVariables {
  input: UpdateValueForOrganizationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteValueForOrganization
// ====================================================

export interface DeleteValueForOrganization_deleteValueForOrganization_values {
  __typename: "Value";
  id: string;
  title: string;
  description: string | null;
}

export interface DeleteValueForOrganization_deleteValueForOrganization {
  __typename: "Organization";
  id: string;
  values: DeleteValueForOrganization_deleteValueForOrganization_values[];
}

export interface DeleteValueForOrganization {
  deleteValueForOrganization: DeleteValueForOrganization_deleteValueForOrganization;
}

export interface DeleteValueForOrganizationVariables {
  input: DeleteValueForOrganizationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GreenhouseIntegrationForCustomer
// ====================================================

export interface GreenhouseIntegrationForCustomer_currentUserV2_organization_customer_integration_SlackIntegrationV2 {
  __typename: "SlackIntegrationV2" | "CoderPadIntegration" | "ZoomIntegration";
  id: string;
  type: IntegrationEnum;
  connected: boolean;
}

export interface GreenhouseIntegrationForCustomer_currentUserV2_organization_customer_integration_GreenhouseIntegrationV2 {
  __typename: "GreenhouseIntegrationV2";
  id: string;
  type: IntegrationEnum;
  connected: boolean;
  apiKeySuffix: string | null;
  subdomain: string;
  maildropAddress: string;
}

export type GreenhouseIntegrationForCustomer_currentUserV2_organization_customer_integration = GreenhouseIntegrationForCustomer_currentUserV2_organization_customer_integration_SlackIntegrationV2 | GreenhouseIntegrationForCustomer_currentUserV2_organization_customer_integration_GreenhouseIntegrationV2;

export interface GreenhouseIntegrationForCustomer_currentUserV2_organization_customer {
  __typename: "Customer";
  id: string;
  integration: GreenhouseIntegrationForCustomer_currentUserV2_organization_customer_integration | null;
}

export interface GreenhouseIntegrationForCustomer_currentUserV2_organization {
  __typename: "Organization";
  id: string;
  customer: GreenhouseIntegrationForCustomer_currentUserV2_organization_customer;
}

export interface GreenhouseIntegrationForCustomer_currentUserV2 {
  __typename: "GuidesUser";
  id: string;
  organization: GreenhouseIntegrationForCustomer_currentUserV2_organization;
}

export interface GreenhouseIntegrationForCustomer {
  currentUserV2: GreenhouseIntegrationForCustomer_currentUserV2 | null;
}

export interface GreenhouseIntegrationForCustomerVariables {
  typeInput: IntegrationByTypeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateGreenhouseIntegrationForCustomer
// ====================================================

export interface UpdateGreenhouseIntegrationForCustomer_updateCustomerGreenhouseIntegration_customer_integration_SlackIntegrationV2 {
  __typename: "SlackIntegrationV2" | "CoderPadIntegration" | "ZoomIntegration";
  id: string;
  type: IntegrationEnum;
  connected: boolean;
}

export interface UpdateGreenhouseIntegrationForCustomer_updateCustomerGreenhouseIntegration_customer_integration_GreenhouseIntegrationV2 {
  __typename: "GreenhouseIntegrationV2";
  id: string;
  type: IntegrationEnum;
  connected: boolean;
  apiKeySuffix: string | null;
  subdomain: string;
  maildropAddress: string;
}

export type UpdateGreenhouseIntegrationForCustomer_updateCustomerGreenhouseIntegration_customer_integration = UpdateGreenhouseIntegrationForCustomer_updateCustomerGreenhouseIntegration_customer_integration_SlackIntegrationV2 | UpdateGreenhouseIntegrationForCustomer_updateCustomerGreenhouseIntegration_customer_integration_GreenhouseIntegrationV2;

export interface UpdateGreenhouseIntegrationForCustomer_updateCustomerGreenhouseIntegration_customer {
  __typename: "Customer";
  id: string;
  integration: UpdateGreenhouseIntegrationForCustomer_updateCustomerGreenhouseIntegration_customer_integration | null;
}

export interface UpdateGreenhouseIntegrationForCustomer_updateCustomerGreenhouseIntegration {
  __typename: "CustomerGreenhouseIntegrationMutationResponse";
  success: boolean;
  message: string;
  code: string;
  customer: UpdateGreenhouseIntegrationForCustomer_updateCustomerGreenhouseIntegration_customer | null;
}

export interface UpdateGreenhouseIntegrationForCustomer {
  updateCustomerGreenhouseIntegration: UpdateGreenhouseIntegrationForCustomer_updateCustomerGreenhouseIntegration;
}

export interface UpdateGreenhouseIntegrationForCustomerVariables {
  input: UpdateCustomerGreenhouseIntegrationInput;
  typeInput: IntegrationByTypeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DisconnectGreenhouseIntegrationForCustomer
// ====================================================

export interface DisconnectGreenhouseIntegrationForCustomer_disconnectCustomerGreenhouseIntegration_customer_integration_SlackIntegrationV2 {
  __typename: "SlackIntegrationV2" | "CoderPadIntegration" | "ZoomIntegration";
  id: string;
  type: IntegrationEnum;
  issues: string[];
  connected: boolean;
}

export interface DisconnectGreenhouseIntegrationForCustomer_disconnectCustomerGreenhouseIntegration_customer_integration_GreenhouseIntegrationV2 {
  __typename: "GreenhouseIntegrationV2";
  id: string;
  type: IntegrationEnum;
  issues: string[];
  connected: boolean;
  apiKeySuffix: string | null;
  subdomain: string;
  maildropAddress: string;
}

export type DisconnectGreenhouseIntegrationForCustomer_disconnectCustomerGreenhouseIntegration_customer_integration = DisconnectGreenhouseIntegrationForCustomer_disconnectCustomerGreenhouseIntegration_customer_integration_SlackIntegrationV2 | DisconnectGreenhouseIntegrationForCustomer_disconnectCustomerGreenhouseIntegration_customer_integration_GreenhouseIntegrationV2;

export interface DisconnectGreenhouseIntegrationForCustomer_disconnectCustomerGreenhouseIntegration_customer {
  __typename: "Customer";
  id: string;
  integration: DisconnectGreenhouseIntegrationForCustomer_disconnectCustomerGreenhouseIntegration_customer_integration | null;
}

export interface DisconnectGreenhouseIntegrationForCustomer_disconnectCustomerGreenhouseIntegration {
  __typename: "CustomerGreenhouseIntegrationMutationResponse";
  success: boolean;
  message: string;
  code: string;
  customer: DisconnectGreenhouseIntegrationForCustomer_disconnectCustomerGreenhouseIntegration_customer | null;
}

export interface DisconnectGreenhouseIntegrationForCustomer {
  disconnectCustomerGreenhouseIntegration: DisconnectGreenhouseIntegrationForCustomer_disconnectCustomerGreenhouseIntegration;
}

export interface DisconnectGreenhouseIntegrationForCustomerVariables {
  typeInput: IntegrationByTypeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GreenhouseIntegrationWebhooksConfigured
// ====================================================

export interface GreenhouseIntegrationWebhooksConfigured_currentUserV2_organization_customer {
  __typename: "Customer";
  id: string;
  greenhouseWebhooksConfigured: boolean;
}

export interface GreenhouseIntegrationWebhooksConfigured_currentUserV2_organization {
  __typename: "Organization";
  id: string;
  customer: GreenhouseIntegrationWebhooksConfigured_currentUserV2_organization_customer;
}

export interface GreenhouseIntegrationWebhooksConfigured_currentUserV2 {
  __typename: "GuidesUser";
  id: string;
  organization: GreenhouseIntegrationWebhooksConfigured_currentUserV2_organization;
}

export interface GreenhouseIntegrationWebhooksConfigured {
  currentUserV2: GreenhouseIntegrationWebhooksConfigured_currentUserV2 | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchAllCustomerIntegrations
// ====================================================

export interface FetchAllCustomerIntegrations_currentUserV2_organization_customer_integrations {
  __typename: "SlackIntegrationV2" | "CoderPadIntegration" | "GreenhouseIntegrationV2" | "ZoomIntegration";
  id: string;
  type: IntegrationEnum;
  issues: string[];
  connected: boolean;
}

export interface FetchAllCustomerIntegrations_currentUserV2_organization_customer {
  __typename: "Customer";
  id: string;
  integrations: FetchAllCustomerIntegrations_currentUserV2_organization_customer_integrations[];
}

export interface FetchAllCustomerIntegrations_currentUserV2_organization {
  __typename: "Organization";
  id: string;
  customer: FetchAllCustomerIntegrations_currentUserV2_organization_customer;
}

export interface FetchAllCustomerIntegrations_currentUserV2 {
  __typename: "GuidesUser";
  id: string;
  organization: FetchAllCustomerIntegrations_currentUserV2_organization;
}

export interface FetchAllCustomerIntegrations {
  currentUserV2: FetchAllCustomerIntegrations_currentUserV2 | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateInterviewerOnTeamPage
// ====================================================

export interface UpdateInterviewerOnTeamPage_updateInterviewerV2 {
  __typename: "InterviewerV2";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  linkedInUrl: string;
  websiteUrls: string[];
  imageUrl: string;
  biography: string;
  atsUserId: string | null;
  slackUserUserId: string | null;
}

export interface UpdateInterviewerOnTeamPage {
  updateInterviewerV2: UpdateInterviewerOnTeamPage_updateInterviewerV2 | null;
}

export interface UpdateInterviewerOnTeamPageVariables {
  input: InterviewerV2UpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteInterviewerOnTeamPage
// ====================================================

export interface DeleteInterviewerOnTeamPage_deleteInterviewerV2 {
  __typename: "DeleteInterviewerMutationResponse";
  code: string;
  success: boolean;
  message: string;
}

export interface DeleteInterviewerOnTeamPage {
  deleteInterviewerV2: DeleteInterviewerOnTeamPage_deleteInterviewerV2;
}

export interface DeleteInterviewerOnTeamPageVariables {
  input: InterviewerV2DeleteInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InterviewerForEdit
// ====================================================

export interface InterviewerForEdit_interviewer {
  __typename: "InterviewerV2";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  linkedInUrl: string;
  websiteUrls: string[];
  imageUrl: string;
  biography: string;
  atsUserId: string | null;
  slackUserUserId: string | null;
}

export interface InterviewerForEdit {
  interviewer: InterviewerForEdit_interviewer | null;
}

export interface InterviewerForEditVariables {
  input?: InterviewerQueryInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InterviewersForTeamPage
// ====================================================

export interface InterviewersForTeamPage_currentUserV2_organization_interviewers {
  __typename: "InterviewerV2";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  linkedInUrl: string;
  websiteUrls: string[];
  imageUrl: string;
  biography: string;
  atsUserId: string | null;
  slackUserUserId: string | null;
}

export interface InterviewersForTeamPage_currentUserV2_organization {
  __typename: "Organization";
  id: string;
  interviewers: InterviewersForTeamPage_currentUserV2_organization_interviewers[];
}

export interface InterviewersForTeamPage_currentUserV2 {
  __typename: "GuidesUser";
  id: string;
  organization: InterviewersForTeamPage_currentUserV2_organization;
}

export interface InterviewersForTeamPage {
  currentUserV2: InterviewersForTeamPage_currentUserV2 | null;
}

export interface InterviewersForTeamPageVariables {
  input?: InterviewersInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DisconnectSlackIntegrationForCustomer
// ====================================================

export interface DisconnectSlackIntegrationForCustomer_disconnectCustomerSlackIntegration_customer_integration_CoderPadIntegration {
  __typename: "CoderPadIntegration" | "GreenhouseIntegrationV2" | "ZoomIntegration";
  id: string;
  type: IntegrationEnum;
  issues: string[];
  connected: boolean;
}

export interface DisconnectSlackIntegrationForCustomer_disconnectCustomerSlackIntegration_customer_integration_SlackIntegrationV2_channels {
  __typename: "SlackChannel";
  id: string;
  name: string;
}

export interface DisconnectSlackIntegrationForCustomer_disconnectCustomerSlackIntegration_customer_integration_SlackIntegrationV2 {
  __typename: "SlackIntegrationV2";
  id: string;
  type: IntegrationEnum;
  issues: string[];
  connected: boolean;
  auth0SlackUserId: string | null;
  notificationsChannelId: string | null;
  autopopulateInterviewer: boolean | null;
  receiveNotifications: boolean | null;
  channels: DisconnectSlackIntegrationForCustomer_disconnectCustomerSlackIntegration_customer_integration_SlackIntegrationV2_channels[];
}

export type DisconnectSlackIntegrationForCustomer_disconnectCustomerSlackIntegration_customer_integration = DisconnectSlackIntegrationForCustomer_disconnectCustomerSlackIntegration_customer_integration_CoderPadIntegration | DisconnectSlackIntegrationForCustomer_disconnectCustomerSlackIntegration_customer_integration_SlackIntegrationV2;

export interface DisconnectSlackIntegrationForCustomer_disconnectCustomerSlackIntegration_customer {
  __typename: "Customer";
  id: string;
  integration: DisconnectSlackIntegrationForCustomer_disconnectCustomerSlackIntegration_customer_integration | null;
}

export interface DisconnectSlackIntegrationForCustomer_disconnectCustomerSlackIntegration {
  __typename: "CustomerIntegrationMutationResponse";
  success: boolean;
  message: string;
  code: string;
  customer: DisconnectSlackIntegrationForCustomer_disconnectCustomerSlackIntegration_customer | null;
}

export interface DisconnectSlackIntegrationForCustomer {
  disconnectCustomerSlackIntegration: DisconnectSlackIntegrationForCustomer_disconnectCustomerSlackIntegration;
}

export interface DisconnectSlackIntegrationForCustomerVariables {
  typeInput: IntegrationByTypeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DisconnectZoomIntegrationForCustomer
// ====================================================

export interface DisconnectZoomIntegrationForCustomer_disconnectCustomerZoomIntegration_customer_integration {
  __typename: "SlackIntegrationV2" | "CoderPadIntegration" | "GreenhouseIntegrationV2" | "ZoomIntegration";
  id: string;
  type: IntegrationEnum;
  issues: string[];
  connected: boolean;
}

export interface DisconnectZoomIntegrationForCustomer_disconnectCustomerZoomIntegration_customer {
  __typename: "Customer";
  id: string;
  integration: DisconnectZoomIntegrationForCustomer_disconnectCustomerZoomIntegration_customer_integration | null;
}

export interface DisconnectZoomIntegrationForCustomer_disconnectCustomerZoomIntegration {
  __typename: "CustomerIntegrationMutationResponse";
  success: boolean;
  message: string;
  code: string;
  customer: DisconnectZoomIntegrationForCustomer_disconnectCustomerZoomIntegration_customer | null;
}

export interface DisconnectZoomIntegrationForCustomer {
  disconnectCustomerZoomIntegration: DisconnectZoomIntegrationForCustomer_disconnectCustomerZoomIntegration;
}

export interface DisconnectZoomIntegrationForCustomerVariables {
  typeInput: IntegrationByTypeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ZoomIntegrationForCustomer
// ====================================================

export interface ZoomIntegrationForCustomer_currentUserV2_organization_customer_integration {
  __typename: "SlackIntegrationV2" | "CoderPadIntegration" | "GreenhouseIntegrationV2" | "ZoomIntegration";
  type: IntegrationEnum;
  connected: boolean;
}

export interface ZoomIntegrationForCustomer_currentUserV2_organization_customer {
  __typename: "Customer";
  id: string;
  integration: ZoomIntegrationForCustomer_currentUserV2_organization_customer_integration | null;
}

export interface ZoomIntegrationForCustomer_currentUserV2_organization {
  __typename: "Organization";
  id: string;
  customer: ZoomIntegrationForCustomer_currentUserV2_organization_customer;
}

export interface ZoomIntegrationForCustomer_currentUserV2 {
  __typename: "GuidesUser";
  id: string;
  organization: ZoomIntegrationForCustomer_currentUserV2_organization;
}

export interface ZoomIntegrationForCustomer {
  currentUserV2: ZoomIntegrationForCustomer_currentUserV2 | null;
}

export interface ZoomIntegrationForCustomerVariables {
  typeInput: IntegrationByTypeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateZoomIntegrationForCustomer
// ====================================================

export interface UpdateZoomIntegrationForCustomer_updateCustomerZoomIntegration_customer_integration {
  __typename: "SlackIntegrationV2" | "CoderPadIntegration" | "GreenhouseIntegrationV2" | "ZoomIntegration";
  type: IntegrationEnum;
  connected: boolean;
}

export interface UpdateZoomIntegrationForCustomer_updateCustomerZoomIntegration_customer {
  __typename: "Customer";
  id: string;
  integration: UpdateZoomIntegrationForCustomer_updateCustomerZoomIntegration_customer_integration | null;
}

export interface UpdateZoomIntegrationForCustomer_updateCustomerZoomIntegration {
  __typename: "CustomerIntegrationMutationResponse";
  success: boolean;
  message: string;
  code: string;
  customer: UpdateZoomIntegrationForCustomer_updateCustomerZoomIntegration_customer | null;
}

export interface UpdateZoomIntegrationForCustomer {
  updateCustomerZoomIntegration: UpdateZoomIntegrationForCustomer_updateCustomerZoomIntegration;
}

export interface UpdateZoomIntegrationForCustomerVariables {
  input: UpdateCustomerZoomIntegrationInput;
  typeInput: IntegrationByTypeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EmailTemplateQueryForModal
// ====================================================

export interface EmailTemplateQueryForModal_emailTemplate {
  __typename: "EmailTemplateV2";
  id: string;
  name: string;
  subject: string;
  body: string;
}

export interface EmailTemplateQueryForModal {
  emailTemplate: EmailTemplateQueryForModal_emailTemplate | null;
}

export interface EmailTemplateQueryForModalVariables {
  input: EmailTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateEmailTemplateFromEmailTemplatesPage
// ====================================================

export interface CreateEmailTemplateFromEmailTemplatesPage_createEmailTemplate_emailTemplate {
  __typename: "EmailTemplateV2";
  id: string;
  name: string;
  updatedAt: GraphQL_DateTime | null;
  createdBy: string;
}

export interface CreateEmailTemplateFromEmailTemplatesPage_createEmailTemplate {
  __typename: "CreateEmailTemplateMutationResponse";
  emailTemplate: CreateEmailTemplateFromEmailTemplatesPage_createEmailTemplate_emailTemplate | null;
}

export interface CreateEmailTemplateFromEmailTemplatesPage {
  createEmailTemplate: CreateEmailTemplateFromEmailTemplatesPage_createEmailTemplate;
}

export interface CreateEmailTemplateFromEmailTemplatesPageVariables {
  input: CreateEmailTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateEmailTemplateFromEmailTemplatesPage
// ====================================================

export interface UpdateEmailTemplateFromEmailTemplatesPage_updateEmailTemplate_emailTemplate {
  __typename: "EmailTemplateV2";
  id: string;
  name: string;
  subject: string;
  body: string;
  updatedAt: GraphQL_DateTime | null;
  createdBy: string;
}

export interface UpdateEmailTemplateFromEmailTemplatesPage_updateEmailTemplate {
  __typename: "UpdateEmailTemplateMutationResponse";
  emailTemplate: UpdateEmailTemplateFromEmailTemplatesPage_updateEmailTemplate_emailTemplate | null;
}

export interface UpdateEmailTemplateFromEmailTemplatesPage {
  updateEmailTemplate: UpdateEmailTemplateFromEmailTemplatesPage_updateEmailTemplate;
}

export interface UpdateEmailTemplateFromEmailTemplatesPageVariables {
  input: UpdateEmailTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ArchiveEmailTemplate
// ====================================================

export interface ArchiveEmailTemplate_archiveEmailTemplate {
  __typename: "ArchiveEmailTemplateMutationResponse";
  success: boolean;
}

export interface ArchiveEmailTemplate {
  archiveEmailTemplate: ArchiveEmailTemplate_archiveEmailTemplate;
}

export interface ArchiveEmailTemplateVariables {
  input: ArchiveEmailTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UnarchiveEmailTemplate
// ====================================================

export interface UnarchiveEmailTemplate_unarchiveEmailTemplate_emailTemplate {
  __typename: "EmailTemplateV2";
  id: string;
  name: string;
  updatedAt: GraphQL_DateTime | null;
  createdBy: string;
}

export interface UnarchiveEmailTemplate_unarchiveEmailTemplate {
  __typename: "UnarchiveEmailTemplateMutationResponse";
  success: boolean;
  emailTemplate: UnarchiveEmailTemplate_unarchiveEmailTemplate_emailTemplate | null;
}

export interface UnarchiveEmailTemplate {
  unarchiveEmailTemplate: UnarchiveEmailTemplate_unarchiveEmailTemplate;
}

export interface UnarchiveEmailTemplateVariables {
  input: UnarchiveEmailTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrgEmailTemplates
// ====================================================

export interface GetOrgEmailTemplates_currentUserV2_organization_emailTemplates {
  __typename: "EmailTemplateV2";
  id: string;
  name: string;
  updatedAt: GraphQL_DateTime | null;
  createdBy: string;
}

export interface GetOrgEmailTemplates_currentUserV2_organization {
  __typename: "Organization";
  id: string;
  emailTemplates: GetOrgEmailTemplates_currentUserV2_organization_emailTemplates[];
}

export interface GetOrgEmailTemplates_currentUserV2 {
  __typename: "GuidesUser";
  id: string;
  organization: GetOrgEmailTemplates_currentUserV2_organization;
}

export interface GetOrgEmailTemplates {
  currentUserV2: GetOrgEmailTemplates_currentUserV2 | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrgJobRoles
// ====================================================

export interface GetOrgJobRoles_currentUserV2_organization_jobRoles {
  __typename: "JobRole";
  id: string;
  name: string;
  atsJobId: string | null;
}

export interface GetOrgJobRoles_currentUserV2_organization {
  __typename: "Organization";
  id: string;
  jobRoles: GetOrgJobRoles_currentUserV2_organization_jobRoles[];
}

export interface GetOrgJobRoles_currentUserV2 {
  __typename: "GuidesUser";
  id: string;
  organization: GetOrgJobRoles_currentUserV2_organization;
}

export interface GetOrgJobRoles {
  currentUserV2: GetOrgJobRoles_currentUserV2 | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuideTemplateForEditing
// ====================================================

export interface GuideTemplateForEditing_guideTemplate_stageTemplateInstallations_afterSteps_TemplateNoteJourneyStep {
  __typename: "TemplateNoteJourneyStep";
  type: JourneyStepEnum;
  text: string;
}

export interface GuideTemplateForEditing_guideTemplate_stageTemplateInstallations_afterSteps_TemplateActionJourneyStep_data_emailTemplate {
  __typename: "EmailTemplateV2";
  id: string;
  name: string;
}

export interface GuideTemplateForEditing_guideTemplate_stageTemplateInstallations_afterSteps_TemplateActionJourneyStep_data {
  __typename: "TemplateCandidateEmailStepData";
  type: ActionJourneyStepEnum;
  cc: string[];
  bcc: string[];
  emailTemplate: GuideTemplateForEditing_guideTemplate_stageTemplateInstallations_afterSteps_TemplateActionJourneyStep_data_emailTemplate;
}

export interface GuideTemplateForEditing_guideTemplate_stageTemplateInstallations_afterSteps_TemplateActionJourneyStep {
  __typename: "TemplateActionJourneyStep";
  type: JourneyStepEnum;
  data: GuideTemplateForEditing_guideTemplate_stageTemplateInstallations_afterSteps_TemplateActionJourneyStep_data;
}

export type GuideTemplateForEditing_guideTemplate_stageTemplateInstallations_afterSteps = GuideTemplateForEditing_guideTemplate_stageTemplateInstallations_afterSteps_TemplateNoteJourneyStep | GuideTemplateForEditing_guideTemplate_stageTemplateInstallations_afterSteps_TemplateActionJourneyStep;

export interface GuideTemplateForEditing_guideTemplate_stageTemplateInstallations_stageTemplate_modules {
  __typename: "StageTemplateModule";
  id: string;
  type: string;
  data: GraphQL_Json;
}

export interface GuideTemplateForEditing_guideTemplate_stageTemplateInstallations_stageTemplate {
  __typename: "StageTemplate";
  id: string;
  name: string;
  modules: GuideTemplateForEditing_guideTemplate_stageTemplateInstallations_stageTemplate_modules[];
}

export interface GuideTemplateForEditing_guideTemplate_stageTemplateInstallations_eventTemplates {
  __typename: "EventTemplate";
  id: string;
  title: string;
  position: number;
  description: string;
  hidden: boolean;
  atsEventTemplateId: string;
  atsEventTemplateName: string;
}

export interface GuideTemplateForEditing_guideTemplate_stageTemplateInstallations {
  __typename: "StageTemplateInstallation";
  id: string;
  atsStageName: string | null;
  atsStageId: string | null;
  displayName: string;
  activatedAt: GraphQL_DateTime | null;
  hidden: boolean;
  afterSteps: GuideTemplateForEditing_guideTemplate_stageTemplateInstallations_afterSteps[];
  stageTemplate: GuideTemplateForEditing_guideTemplate_stageTemplateInstallations_stageTemplate;
  eventTemplates: GuideTemplateForEditing_guideTemplate_stageTemplateInstallations_eventTemplates[];
}

export interface GuideTemplateForEditing_guideTemplate_onRejectSteps_TemplateNoteJourneyStep {
  __typename: "TemplateNoteJourneyStep";
  type: JourneyStepEnum;
  text: string;
}

export interface GuideTemplateForEditing_guideTemplate_onRejectSteps_TemplateActionJourneyStep_data_emailTemplate {
  __typename: "EmailTemplateV2";
  id: string;
  name: string;
}

export interface GuideTemplateForEditing_guideTemplate_onRejectSteps_TemplateActionJourneyStep_data {
  __typename: "TemplateCandidateEmailStepData";
  type: ActionJourneyStepEnum;
  cc: string[];
  bcc: string[];
  emailTemplate: GuideTemplateForEditing_guideTemplate_onRejectSteps_TemplateActionJourneyStep_data_emailTemplate;
}

export interface GuideTemplateForEditing_guideTemplate_onRejectSteps_TemplateActionJourneyStep {
  __typename: "TemplateActionJourneyStep";
  type: JourneyStepEnum;
  data: GuideTemplateForEditing_guideTemplate_onRejectSteps_TemplateActionJourneyStep_data;
}

export type GuideTemplateForEditing_guideTemplate_onRejectSteps = GuideTemplateForEditing_guideTemplate_onRejectSteps_TemplateNoteJourneyStep | GuideTemplateForEditing_guideTemplate_onRejectSteps_TemplateActionJourneyStep;

export interface GuideTemplateForEditing_guideTemplate_jobRole_greenhouseJobStages_interviews {
  __typename: "GreenhouseIdAndName";
  id: string;
  name: string | null;
}

export interface GuideTemplateForEditing_guideTemplate_jobRole_greenhouseJobStages {
  __typename: "JobStage";
  id: string;
  name: string | null;
  interviews: GuideTemplateForEditing_guideTemplate_jobRole_greenhouseJobStages_interviews[];
}

export interface GuideTemplateForEditing_guideTemplate_jobRole {
  __typename: "JobRole";
  id: string;
  name: string;
  atsJobId: string | null;
  greenhouseJobStages: GuideTemplateForEditing_guideTemplate_jobRole_greenhouseJobStages[] | null;
}

export interface GuideTemplateForEditing_guideTemplate {
  __typename: "GuideTemplate";
  id: string;
  name: string;
  chatEnabled: boolean;
  interviewProcessEnabled: boolean;
  jobRoleNameOverride: string | null;
  stageTemplateInstallations: GuideTemplateForEditing_guideTemplate_stageTemplateInstallations[];
  onRejectSteps: GuideTemplateForEditing_guideTemplate_onRejectSteps[];
  jobRole: GuideTemplateForEditing_guideTemplate_jobRole | null;
}

export interface GuideTemplateForEditing_currentUserV2_organization_stageTemplates {
  __typename: "StageTemplate";
  id: string;
  name: string;
}

export interface GuideTemplateForEditing_currentUserV2_organization_emailTemplates {
  __typename: "EmailTemplateV2";
  id: string;
  name: string;
}

export interface GuideTemplateForEditing_currentUserV2_organization {
  __typename: "Organization";
  id: string;
  theme: GraphQL_Json;
  name: string;
  companyLogoUrl: string;
  stageTemplates: GuideTemplateForEditing_currentUserV2_organization_stageTemplates[];
  emailTemplates: GuideTemplateForEditing_currentUserV2_organization_emailTemplates[];
}

export interface GuideTemplateForEditing_currentUserV2 {
  __typename: "GuidesUser";
  id: string;
  organization: GuideTemplateForEditing_currentUserV2_organization;
}

export interface GuideTemplateForEditing {
  guideTemplate: GuideTemplateForEditing_guideTemplate | null;
  currentUserV2: GuideTemplateForEditing_currentUserV2 | null;
}

export interface GuideTemplateForEditingVariables {
  input: GuideTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateStageTemplateInstallation
// ====================================================

export interface UpdateStageTemplateInstallation_updateStageTemplateInstallation_stageTemplateInstallation_stageTemplate {
  __typename: "StageTemplate";
  id: string;
  name: string;
}

export interface UpdateStageTemplateInstallation_updateStageTemplateInstallation_stageTemplateInstallation {
  __typename: "StageTemplateInstallation";
  id: string;
  stageTemplate: UpdateStageTemplateInstallation_updateStageTemplateInstallation_stageTemplateInstallation_stageTemplate;
}

export interface UpdateStageTemplateInstallation_updateStageTemplateInstallation {
  __typename: "UpdateStageTemplateInstallationMutationResponse";
  success: boolean;
  stageTemplateInstallation: UpdateStageTemplateInstallation_updateStageTemplateInstallation_stageTemplateInstallation | null;
}

export interface UpdateStageTemplateInstallation {
  updateStageTemplateInstallation: UpdateStageTemplateInstallation_updateStageTemplateInstallation;
}

export interface UpdateStageTemplateInstallationVariables {
  input: UpdateStageTemplateInstallationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateGuideTemplate
// ====================================================

export interface UpdateGuideTemplate_updateGuideTemplate_guideTemplate {
  __typename: "GuideTemplate";
  id: string;
  chatEnabled: boolean;
  interviewProcessEnabled: boolean;
  jobRoleNameOverride: string | null;
}

export interface UpdateGuideTemplate_updateGuideTemplate {
  __typename: "UpdateGuideTemplateMutationResponse";
  success: boolean;
  guideTemplate: UpdateGuideTemplate_updateGuideTemplate_guideTemplate | null;
}

export interface UpdateGuideTemplate {
  updateGuideTemplate: UpdateGuideTemplate_updateGuideTemplate | null;
}

export interface UpdateGuideTemplateVariables {
  input: UpdateGuideTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrgGuideTemplateData
// ====================================================

export interface GetOrgGuideTemplateData_currentUserV2_organization_guideTemplates_jobRole {
  __typename: "JobRole";
  id: string;
  name: string;
  atsJobId: string | null;
}

export interface GetOrgGuideTemplateData_currentUserV2_organization_guideTemplates_createdBy {
  __typename: "GuidesUser";
  name: string;
}

export interface GetOrgGuideTemplateData_currentUserV2_organization_guideTemplates_stageTemplateInstallations {
  __typename: "StageTemplateInstallation";
  id: string;
  displayName: string;
}

export interface GetOrgGuideTemplateData_currentUserV2_organization_guideTemplates {
  __typename: "GuideTemplate";
  id: string;
  name: string;
  jobRole: GetOrgGuideTemplateData_currentUserV2_organization_guideTemplates_jobRole | null;
  updatedAt: GraphQL_DateTime;
  createdBy: GetOrgGuideTemplateData_currentUserV2_organization_guideTemplates_createdBy | null;
  stageTemplateInstallations: GetOrgGuideTemplateData_currentUserV2_organization_guideTemplates_stageTemplateInstallations[];
}

export interface GetOrgGuideTemplateData_currentUserV2_organization {
  __typename: "Organization";
  id: string;
  guideTemplates: GetOrgGuideTemplateData_currentUserV2_organization_guideTemplates[];
}

export interface GetOrgGuideTemplateData_currentUserV2 {
  __typename: "GuidesUser";
  id: string;
  organization: GetOrgGuideTemplateData_currentUserV2_organization;
}

export interface GetOrgGuideTemplateData {
  currentUserV2: GetOrgGuideTemplateData_currentUserV2 | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateGuideTemplateForRole
// ====================================================

export interface CreateGuideTemplateForRole_createGuideTemplateForRole_guideTemplate_jobRole {
  __typename: "JobRole";
  id: string;
  name: string;
  atsJobId: string | null;
}

export interface CreateGuideTemplateForRole_createGuideTemplateForRole_guideTemplate_createdBy {
  __typename: "GuidesUser";
  name: string;
}

export interface CreateGuideTemplateForRole_createGuideTemplateForRole_guideTemplate_stageTemplateInstallations {
  __typename: "StageTemplateInstallation";
  id: string;
  displayName: string;
}

export interface CreateGuideTemplateForRole_createGuideTemplateForRole_guideTemplate {
  __typename: "GuideTemplate";
  id: string;
  name: string;
  jobRole: CreateGuideTemplateForRole_createGuideTemplateForRole_guideTemplate_jobRole | null;
  updatedAt: GraphQL_DateTime;
  createdBy: CreateGuideTemplateForRole_createGuideTemplateForRole_guideTemplate_createdBy | null;
  stageTemplateInstallations: CreateGuideTemplateForRole_createGuideTemplateForRole_guideTemplate_stageTemplateInstallations[];
}

export interface CreateGuideTemplateForRole_createGuideTemplateForRole {
  __typename: "CreateGuideTemplateForRoleMutationResponse";
  success: boolean;
  guideTemplate: CreateGuideTemplateForRole_createGuideTemplateForRole_guideTemplate | null;
}

export interface CreateGuideTemplateForRole {
  createGuideTemplateForRole: CreateGuideTemplateForRole_createGuideTemplateForRole;
}

export interface CreateGuideTemplateForRoleVariables {
  input: CreateGuideTemplateForRoleInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SyncJobsFromForGuideTemplates
// ====================================================

export interface SyncJobsFromForGuideTemplates_syncATSJobs {
  __typename: "SyncJobsFromATSMutationResponse";
  success: boolean;
}

export interface SyncJobsFromForGuideTemplates {
  syncATSJobs: SyncJobsFromForGuideTemplates_syncATSJobs;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateStageTemplateInstallationForJourneyStep
// ====================================================

export interface UpdateStageTemplateInstallationForJourneyStep_updateStageTemplateInstallation_stageTemplateInstallation_stageTemplate {
  __typename: "StageTemplate";
  id: string;
  name: string;
}

export interface UpdateStageTemplateInstallationForJourneyStep_updateStageTemplateInstallation_stageTemplateInstallation {
  __typename: "StageTemplateInstallation";
  id: string;
  stageTemplate: UpdateStageTemplateInstallationForJourneyStep_updateStageTemplateInstallation_stageTemplateInstallation_stageTemplate;
}

export interface UpdateStageTemplateInstallationForJourneyStep_updateStageTemplateInstallation {
  __typename: "UpdateStageTemplateInstallationMutationResponse";
  success: boolean;
  stageTemplateInstallation: UpdateStageTemplateInstallationForJourneyStep_updateStageTemplateInstallation_stageTemplateInstallation | null;
}

export interface UpdateStageTemplateInstallationForJourneyStep {
  updateStageTemplateInstallation: UpdateStageTemplateInstallationForJourneyStep_updateStageTemplateInstallation;
}

export interface UpdateStageTemplateInstallationForJourneyStepVariables {
  input: UpdateStageTemplateInstallationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SyncATSJobsForJourneys
// ====================================================

export interface SyncATSJobsForJourneys_syncATSJobs {
  __typename: "SyncJobsFromATSMutationResponse";
  success: boolean;
}

export interface SyncATSJobsForJourneys {
  syncATSJobs: SyncATSJobsForJourneys_syncATSJobs;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrgStageTemplates
// ====================================================

export interface GetOrgStageTemplates_currentUserV2_organization_stageTemplates {
  __typename: "StageTemplate";
  id: string;
  name: string;
  updatedAt: GraphQL_DateTime;
  createdBy: string;
}

export interface GetOrgStageTemplates_currentUserV2_organization {
  __typename: "Organization";
  id: string;
  stageTemplates: GetOrgStageTemplates_currentUserV2_organization_stageTemplates[];
}

export interface GetOrgStageTemplates_currentUserV2 {
  __typename: "GuidesUser";
  id: string;
  organization: GetOrgStageTemplates_currentUserV2_organization;
}

export interface GetOrgStageTemplates {
  currentUserV2: GetOrgStageTemplates_currentUserV2 | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateStageTemplate
// ====================================================

export interface CreateStageTemplate_createStageTemplate_stageTemplate_modules {
  __typename: "StageTemplateModule";
  id: string;
  type: string;
  data: GraphQL_Json;
}

export interface CreateStageTemplate_createStageTemplate_stageTemplate {
  __typename: "StageTemplate";
  id: string;
  name: string;
  modules: CreateStageTemplate_createStageTemplate_stageTemplate_modules[];
  updatedAt: GraphQL_DateTime;
  createdBy: string;
}

export interface CreateStageTemplate_createStageTemplate {
  __typename: "CreateStageTemplateMutationResponse";
  stageTemplate: CreateStageTemplate_createStageTemplate_stageTemplate | null;
}

export interface CreateStageTemplate {
  createStageTemplate: CreateStageTemplate_createStageTemplate;
}

export interface CreateStageTemplateVariables {
  input: CreateStageTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ArchiveStageTemplate
// ====================================================

export interface ArchiveStageTemplate_archiveStageTemplate {
  __typename: "ArchiveStageTemplateMutationResponse";
  success: boolean;
}

export interface ArchiveStageTemplate {
  archiveStageTemplate: ArchiveStageTemplate_archiveStageTemplate;
}

export interface ArchiveStageTemplateVariables {
  input: ArchiveStageTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UnarchiveStageTemplate
// ====================================================

export interface UnarchiveStageTemplate_unarchiveStageTemplate_stageTemplate_modules {
  __typename: "StageTemplateModule";
  id: string;
  type: string;
  data: GraphQL_Json;
}

export interface UnarchiveStageTemplate_unarchiveStageTemplate_stageTemplate {
  __typename: "StageTemplate";
  id: string;
  name: string;
  modules: UnarchiveStageTemplate_unarchiveStageTemplate_stageTemplate_modules[];
  updatedAt: GraphQL_DateTime;
  createdBy: string;
}

export interface UnarchiveStageTemplate_unarchiveStageTemplate {
  __typename: "UnarchiveStageTemplateMutationResponse";
  success: boolean;
  stageTemplate: UnarchiveStageTemplate_unarchiveStageTemplate_stageTemplate | null;
}

export interface UnarchiveStageTemplate {
  unarchiveStageTemplate: UnarchiveStageTemplate_unarchiveStageTemplate;
}

export interface UnarchiveStageTemplateVariables {
  input: UnarchiveStageTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CurrentUser
// ====================================================

export interface CurrentUser_currentUserV2_organization_customer {
  __typename: "Customer";
  id: string;
  name: string | null;
}

export interface CurrentUser_currentUserV2_organization {
  __typename: "Organization";
  id: string;
  name: string;
  analyticsGroupId: string;
  customer: CurrentUser_currentUserV2_organization_customer;
}

export interface CurrentUser_currentUserV2 {
  __typename: "GuidesUser";
  id: string;
  analyticsUserId: string;
  name: string;
  firstName: string;
  lastName: string;
  canSendEmail: boolean;
  onboardingComplete: boolean;
  organization: CurrentUser_currentUserV2_organization;
  roles: string[];
  primaryEmail: string | null;
  title: string;
  intercomHash: string;
  createdAt: GraphQL_DateTime;
}

export interface CurrentUser {
  currentUserV2: CurrentUser_currentUserV2 | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LocationsQueryForEditLocationV2
// ====================================================

export interface LocationsQueryForEditLocationV2_currentUserV2_organization_locations {
  __typename: "Location";
  id: string;
  name: string;
  arrivalInstructions: string;
  coordinates: string[];
  address: string;
  isArchived: boolean;
}

export interface LocationsQueryForEditLocationV2_currentUserV2_organization {
  __typename: "Organization";
  id: string;
  locations: LocationsQueryForEditLocationV2_currentUserV2_organization_locations[];
}

export interface LocationsQueryForEditLocationV2_currentUserV2 {
  __typename: "GuidesUser";
  id: string;
  organization: LocationsQueryForEditLocationV2_currentUserV2_organization;
}

export interface LocationsQueryForEditLocationV2 {
  currentUserV2: LocationsQueryForEditLocationV2_currentUserV2 | null;
}

export interface LocationsQueryForEditLocationV2Variables {
  locationInput?: OrganizationLocationInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CurrentAvailableOrganizations
// ====================================================

export interface CurrentAvailableOrganizations_currentUserV2_organization {
  __typename: "Organization";
  id: string;
  name: string;
}

export interface CurrentAvailableOrganizations_currentUserV2_availableOrganizations {
  __typename: "Organization";
  id: string;
  name: string;
}

export interface CurrentAvailableOrganizations_currentUserV2 {
  __typename: "GuidesUser";
  id: string;
  organization: CurrentAvailableOrganizations_currentUserV2_organization;
  availableOrganizations: CurrentAvailableOrganizations_currentUserV2_availableOrganizations[];
}

export interface CurrentAvailableOrganizations {
  currentUserV2: CurrentAvailableOrganizations_currentUserV2 | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetLinkPreview
// ====================================================

export interface GetLinkPreview_getLinkPreview {
  __typename: "LinkPreview";
  title: string;
  description: string | null;
  favicon: string | null;
  image: string | null;
  url: string | null;
}

export interface GetLinkPreview {
  getLinkPreview: GetLinkPreview_getLinkPreview | null;
}

export interface GetLinkPreviewVariables {
  input: LinkPreviewInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: greenhouseCandidate
// ====================================================

export interface greenhouseCandidate {
  greenhouseCandidate: string | null;
}

export interface greenhouseCandidateVariables {
  where: GreenhouseCandidateWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: StageById
// ====================================================

export interface StageById_stage_guide_candidate {
  __typename: "CandidateV2";
  id: string;
}

export interface StageById_stage_guide {
  __typename: "Guide";
  id: string;
  chatEnabled: boolean;
  createdFromATS: boolean;
  candidate: StageById_stage_guide_candidate;
}

export interface StageById_stage_sortedVisibleEvents_prominentInstructions {
  __typename: "ProminentInstruction";
  id: string;
  label: string;
  url: string | null;
}

export interface StageById_stage_sortedVisibleEvents_interviewers {
  __typename: "InterviewerV2";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  linkedInUrl: string;
  websiteUrls: string[];
  imageUrl: string;
  biography: string;
}

export interface StageById_stage_sortedVisibleEvents {
  __typename: "EventV2";
  id: string;
  title: string;
  description: string | null;
  remoteInstructions: string | null;
  startTime: GraphQL_DateTime | null;
  endTime: GraphQL_DateTime | null;
  hidden: boolean;
  techScreenLink: string | null;
  zoomLink: string | null;
  prominentInstructions: StageById_stage_sortedVisibleEvents_prominentInstructions[];
  interviewers: StageById_stage_sortedVisibleEvents_interviewers[] | null;
}

export interface StageById_stage_modules {
  __typename: "StageModule";
  id: string;
  type: string;
  displayType: string;
  data: GraphQL_Json;
}

export interface StageById_stage {
  __typename: "Stage";
  id: string;
  guide: StageById_stage_guide;
  title: string;
  sortedVisibleEvents: StageById_stage_sortedVisibleEvents[];
  modules: StageById_stage_modules[];
}

export interface StageById {
  stage: StageById_stage | null;
}

export interface StageByIdVariables {
  input: StageFindInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: StageTemplateForEditing
// ====================================================

export interface StageTemplateForEditing_stageTemplate_emailTemplate {
  __typename: "EmailTemplateV2";
  id: string;
  name: string;
}

export interface StageTemplateForEditing_stageTemplate_organization {
  __typename: "Organization";
  id: string;
  name: string;
}

export interface StageTemplateForEditing_stageTemplate_modules {
  __typename: "StageTemplateModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_Json;
}

export interface StageTemplateForEditing_stageTemplate {
  __typename: "StageTemplate";
  id: string;
  name: string;
  emailTemplate: StageTemplateForEditing_stageTemplate_emailTemplate;
  organization: StageTemplateForEditing_stageTemplate_organization | null;
  modules: StageTemplateForEditing_stageTemplate_modules[];
  createdBy: string;
  updatedAt: GraphQL_DateTime;
}

export interface StageTemplateForEditing_currentUserV2_organization_emailTemplates {
  __typename: "EmailTemplateV2";
  id: string;
  name: string;
}

export interface StageTemplateForEditing_currentUserV2_organization {
  __typename: "Organization";
  id: string;
  emailTemplates: StageTemplateForEditing_currentUserV2_organization_emailTemplates[];
}

export interface StageTemplateForEditing_currentUserV2 {
  __typename: "GuidesUser";
  id: string;
  organization: StageTemplateForEditing_currentUserV2_organization;
}

export interface StageTemplateForEditing {
  stageTemplate: StageTemplateForEditing_stageTemplate | null;
  currentUserV2: StageTemplateForEditing_currentUserV2 | null;
}

export interface StageTemplateForEditingVariables {
  input: StageTemplateFindInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCandidateTimezone
// ====================================================

export interface UpdateCandidateTimezone_updateCandidate {
  __typename: "CandidateV2";
  id: string;
  currentTimezone: string;
}

export interface UpdateCandidateTimezone {
  updateCandidate: UpdateCandidateTimezone_updateCandidate;
}

export interface UpdateCandidateTimezoneVariables {
  input: UpdateCandidateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetGuideForCurrentStageId
// ====================================================

export interface GetGuideForCurrentStageId_guide_currentStage {
  __typename: "Stage";
  id: string;
}

export interface GetGuideForCurrentStageId_guide {
  __typename: "Guide";
  id: string;
  currentStage: GetGuideForCurrentStageId_guide_currentStage | null;
}

export interface GetGuideForCurrentStageId {
  guide: GetGuideForCurrentStageId_guide | null;
}

export interface GetGuideForCurrentStageIdVariables {
  input: GuideFindInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetGuideForEvent
// ====================================================

export interface GetGuideForEvent_guide_currentStage_events {
  __typename: "EventV2";
  id: string;
}

export interface GetGuideForEvent_guide_currentStage {
  __typename: "Stage";
  id: string;
  events: GetGuideForEvent_guide_currentStage_events[];
}

export interface GetGuideForEvent_guide {
  __typename: "Guide";
  id: string;
  currentStage: GetGuideForEvent_guide_currentStage | null;
}

export interface GetGuideForEvent {
  guide: GetGuideForEvent_guide | null;
}

export interface GetGuideForEventVariables {
  input: GuideFindInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: customerFragmentForGreenhouse
// ====================================================

export interface customerFragmentForGreenhouse_integration_SlackIntegrationV2 {
  __typename: "SlackIntegrationV2" | "CoderPadIntegration" | "ZoomIntegration";
  id: string;
  type: IntegrationEnum;
  connected: boolean;
}

export interface customerFragmentForGreenhouse_integration_GreenhouseIntegrationV2 {
  __typename: "GreenhouseIntegrationV2";
  id: string;
  type: IntegrationEnum;
  connected: boolean;
  apiKeySuffix: string | null;
  subdomain: string;
  maildropAddress: string;
}

export type customerFragmentForGreenhouse_integration = customerFragmentForGreenhouse_integration_SlackIntegrationV2 | customerFragmentForGreenhouse_integration_GreenhouseIntegrationV2;

export interface customerFragmentForGreenhouse {
  __typename: "Customer";
  id: string;
  integration: customerFragmentForGreenhouse_integration | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: customerFragmentForSlack
// ====================================================

export interface customerFragmentForSlack_integration_CoderPadIntegration {
  __typename: "CoderPadIntegration" | "GreenhouseIntegrationV2" | "ZoomIntegration";
  id: string;
  type: IntegrationEnum;
  issues: string[];
  connected: boolean;
}

export interface customerFragmentForSlack_integration_SlackIntegrationV2_channels {
  __typename: "SlackChannel";
  id: string;
  name: string;
}

export interface customerFragmentForSlack_integration_SlackIntegrationV2 {
  __typename: "SlackIntegrationV2";
  id: string;
  type: IntegrationEnum;
  issues: string[];
  connected: boolean;
  auth0SlackUserId: string | null;
  notificationsChannelId: string | null;
  autopopulateInterviewer: boolean | null;
  receiveNotifications: boolean | null;
  channels: customerFragmentForSlack_integration_SlackIntegrationV2_channels[];
}

export type customerFragmentForSlack_integration = customerFragmentForSlack_integration_CoderPadIntegration | customerFragmentForSlack_integration_SlackIntegrationV2;

export interface customerFragmentForSlack {
  __typename: "Customer";
  id: string;
  integration: customerFragmentForSlack_integration | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideFetchGuides
// ====================================================

export interface GuideFetchGuides_candidate {
  __typename: "CandidateV2";
  id: string;
  firstName: string;
  lastName: string;
}

export interface GuideFetchGuides_jobRole {
  __typename: "JobRole";
  id: string;
  name: string;
}

export interface GuideFetchGuides_currentStage {
  __typename: "Stage";
  id: string;
  date: GraphQL_DateTime | null;
  lastSentAt: GraphQL_DateTime | null;
}

export interface GuideFetchGuides {
  __typename: "Guide";
  id: string;
  archivedAt: GraphQL_DateTime | null;
  atsUrl: string | null;
  candidate: GuideFetchGuides_candidate;
  jobRole: GuideFetchGuides_jobRole;
  currentStage: GuideFetchGuides_currentStage | null;
  candidateOpens: GraphQL_DateTime[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: InterviewerDetailsFragment
// ====================================================

export interface InterviewerDetailsFragment {
  __typename: "InterviewerV2";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  linkedInUrl: string;
  websiteUrls: string[];
  imageUrl: string;
  biography: string;
  atsUserId: string | null;
  slackUserUserId: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: resourceCandidate
// ====================================================

export interface resourceCandidate {
  __typename: "ResourceCandidate";
  id: string;
  greenhouseCandidateId: string | null;
  firstName: string;
  lastName: string;
  name: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: stageForModuleEditing
// ====================================================

export interface stageForModuleEditing_modules {
  __typename: "StageModule";
  id: string;
  type: string;
  displayType: string;
  position: number;
  data: GraphQL_Json;
}

export interface stageForModuleEditing {
  __typename: "Stage";
  id: string;
  modules: stageForModuleEditing_modules[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: stageTemplateWithModules
// ====================================================

export interface stageTemplateWithModules_emailTemplate {
  __typename: "EmailTemplateV2";
  id: string;
  name: string;
}

export interface stageTemplateWithModules_organization {
  __typename: "Organization";
  id: string;
  name: string;
}

export interface stageTemplateWithModules_modules {
  __typename: "StageTemplateModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_Json;
}

export interface stageTemplateWithModules {
  __typename: "StageTemplate";
  id: string;
  name: string;
  emailTemplate: stageTemplateWithModules_emailTemplate;
  organization: stageTemplateWithModules_organization | null;
  modules: stageTemplateWithModules_modules[];
  createdBy: string;
  updatedAt: GraphQL_DateTime;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: stageWithModules
// ====================================================

export interface stageWithModules_modules {
  __typename: "StageModule";
  id: string;
  type: string;
  data: GraphQL_Json;
}

export interface stageWithModules {
  __typename: "Stage";
  id: string;
  modules: stageWithModules_modules[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: StageForCandidateJourney
// ====================================================

export interface StageForCandidateJourney_events_prominentInstructions {
  __typename: "ProminentInstruction";
  id: string;
  label: string;
  url: string | null;
}

export interface StageForCandidateJourney_events_interviewers {
  __typename: "InterviewerV2";
  id: string;
  biography: string;
  firstName: string;
  lastName: string;
  linkedInUrl: string;
  websiteUrls: string[];
  title: string;
  imageUrl: string;
  hasDefaultImage: boolean;
}

export interface StageForCandidateJourney_events {
  __typename: "EventV2";
  id: string;
  title: string;
  rawTitle: string;
  hidden: boolean;
  remoteInstructions: string | null;
  description: string | null;
  rawDescription: string;
  startTime: GraphQL_DateTime | null;
  endTime: GraphQL_DateTime | null;
  techScreenLink: string | null;
  zoomLink: string | null;
  placeholders: GraphQL_Json | null;
  prominentInstructions: StageForCandidateJourney_events_prominentInstructions[];
  interviewers: StageForCandidateJourney_events_interviewers[] | null;
}

export interface StageForCandidateJourney_stageTemplate {
  __typename: "StageTemplate";
  id: string;
  name: string;
}

export interface StageForCandidateJourney_emails {
  __typename: "EmailActivity";
  id: string;
}

export interface StageForCandidateJourney {
  __typename: "Stage";
  id: string;
  atsStageId: string | null;
  title: string;
  position: number;
  hidden: boolean;
  activatedAt: GraphQL_DateTime | null;
  activatedOverride: boolean | null;
  events: StageForCandidateJourney_events[];
  stageTemplate: StageForCandidateJourney_stageTemplate;
  emails: StageForCandidateJourney_emails[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: stage
// ====================================================

export interface stage_events_interviewers {
  __typename: "InterviewerV2";
  id: string;
  biography: string;
  firstName: string;
  lastName: string;
  linkedInUrl: string;
  websiteUrls: string[];
  title: string;
  imageUrl: string;
}

export interface stage_events {
  __typename: "EventV2";
  id: string;
  title: string;
  hidden: boolean;
  description: string | null;
  startTime: GraphQL_DateTime | null;
  endTime: GraphQL_DateTime | null;
  techScreenLink: string | null;
  zoomLink: string | null;
  interviewers: stage_events_interviewers[] | null;
}

export interface stage_stageTemplate {
  __typename: "StageTemplate";
  id: string;
  name: string;
}

export interface stage_emails {
  __typename: "EmailActivity";
  id: string;
}

export interface stage {
  __typename: "Stage";
  id: string;
  atsStageId: string | null;
  title: string;
  position: number;
  hidden: boolean;
  events: stage_events[];
  stageTemplate: stage_stageTemplate;
  emails: stage_emails[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: guideForGreenhouseCandidate
// ====================================================

export interface guideForGreenhouseCandidate_currentVisibleStage_events_interviewers {
  __typename: "InterviewerV2";
  id: string;
  biography: string;
  firstName: string;
  lastName: string;
  linkedInUrl: string;
  websiteUrls: string[];
  title: string;
  imageUrl: string;
}

export interface guideForGreenhouseCandidate_currentVisibleStage_events {
  __typename: "EventV2";
  id: string;
  title: string;
  hidden: boolean;
  description: string | null;
  startTime: GraphQL_DateTime | null;
  endTime: GraphQL_DateTime | null;
  techScreenLink: string | null;
  zoomLink: string | null;
  interviewers: guideForGreenhouseCandidate_currentVisibleStage_events_interviewers[] | null;
}

export interface guideForGreenhouseCandidate_currentVisibleStage_stageTemplate {
  __typename: "StageTemplate";
  id: string;
  name: string;
}

export interface guideForGreenhouseCandidate_currentVisibleStage_emails {
  __typename: "EmailActivity";
  id: string;
}

export interface guideForGreenhouseCandidate_currentVisibleStage {
  __typename: "Stage";
  id: string;
  atsStageId: string | null;
  title: string;
  position: number;
  hidden: boolean;
  events: guideForGreenhouseCandidate_currentVisibleStage_events[];
  stageTemplate: guideForGreenhouseCandidate_currentVisibleStage_stageTemplate;
  emails: guideForGreenhouseCandidate_currentVisibleStage_emails[];
}

export interface guideForGreenhouseCandidate_stages_events_interviewers {
  __typename: "InterviewerV2";
  id: string;
  biography: string;
  firstName: string;
  lastName: string;
  linkedInUrl: string;
  websiteUrls: string[];
  title: string;
  imageUrl: string;
}

export interface guideForGreenhouseCandidate_stages_events {
  __typename: "EventV2";
  id: string;
  title: string;
  hidden: boolean;
  description: string | null;
  startTime: GraphQL_DateTime | null;
  endTime: GraphQL_DateTime | null;
  techScreenLink: string | null;
  zoomLink: string | null;
  interviewers: guideForGreenhouseCandidate_stages_events_interviewers[] | null;
}

export interface guideForGreenhouseCandidate_stages_stageTemplate {
  __typename: "StageTemplate";
  id: string;
  name: string;
}

export interface guideForGreenhouseCandidate_stages_emails {
  __typename: "EmailActivity";
  id: string;
}

export interface guideForGreenhouseCandidate_stages {
  __typename: "Stage";
  id: string;
  atsStageId: string | null;
  title: string;
  position: number;
  hidden: boolean;
  events: guideForGreenhouseCandidate_stages_events[];
  stageTemplate: guideForGreenhouseCandidate_stages_stageTemplate;
  emails: guideForGreenhouseCandidate_stages_emails[];
}

export interface guideForGreenhouseCandidate_candidate {
  __typename: "CandidateV2";
  id: string;
  emailAddresses: GraphQL_EmailAddress[];
  firstName: string;
  lastName: string;
  currentTimezone: string;
}

export interface guideForGreenhouseCandidate_guideTemplate {
  __typename: "GuideTemplate";
  id: string;
}

export interface guideForGreenhouseCandidate_jobRole {
  __typename: "JobRole";
  id: string;
  name: string;
}

export interface guideForGreenhouseCandidate {
  __typename: "Guide";
  id: string;
  archivedAt: GraphQL_DateTime | null;
  atsUrl: string | null;
  currentVisibleStage: guideForGreenhouseCandidate_currentVisibleStage | null;
  stages: guideForGreenhouseCandidate_stages[];
  candidate: guideForGreenhouseCandidate_candidate;
  guideTemplate: guideForGreenhouseCandidate_guideTemplate;
  jobRole: guideForGreenhouseCandidate_jobRole;
  publicUrl: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideShareGuide
// ====================================================

export interface GuideShareGuide_candidate {
  __typename: "CandidateV2";
  id: string;
  firstName: string;
  lastName: string;
  emailAddresses: GraphQL_EmailAddress[];
  currentTimezone: string;
}

export interface GuideShareGuide_emails {
  __typename: "EmailActivity";
  id: string;
  createdAt: GraphQL_DateTime;
  sentTo: string[];
}

export interface GuideShareGuide_currentStage_stageTemplate_emailTemplate {
  __typename: "EmailTemplateV2";
  id: string;
  body: string;
  subject: string;
}

export interface GuideShareGuide_currentStage_stageTemplate {
  __typename: "StageTemplate";
  id: string;
  emailTemplate: GuideShareGuide_currentStage_stageTemplate_emailTemplate;
}

export interface GuideShareGuide_currentStage_events {
  __typename: "EventV2";
  id: string;
  startTime: GraphQL_DateTime | null;
  endTime: GraphQL_DateTime | null;
}

export interface GuideShareGuide_currentStage {
  __typename: "Stage";
  id: string;
  stageTemplate: GuideShareGuide_currentStage_stageTemplate;
  events: GuideShareGuide_currentStage_events[];
  hasEmailActivity: boolean;
}

export interface GuideShareGuide_organization {
  __typename: "Organization";
  id: string;
  name: string;
}

export interface GuideShareGuide {
  __typename: "Guide";
  id: string;
  publicUrl: string;
  candidate: GuideShareGuide_candidate;
  emails: GuideShareGuide_emails[];
  currentStage: GuideShareGuide_currentStage | null;
  organization: GuideShareGuide_organization;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideShareCurrentUser
// ====================================================

export interface GuideShareCurrentUser {
  __typename: "GuidesUser";
  id: string;
  primaryEmail: string | null;
  firstName: string;
  lastName: string;
  canSendEmail: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: customerFragmentForCoderPad
// ====================================================

export interface customerFragmentForCoderPad_integration_SlackIntegrationV2 {
  __typename: "SlackIntegrationV2" | "GreenhouseIntegrationV2" | "ZoomIntegration";
  id: string;
  type: IntegrationEnum;
  issues: string[];
  connected: boolean;
}

export interface customerFragmentForCoderPad_integration_CoderPadIntegration {
  __typename: "CoderPadIntegration";
  id: string;
  type: IntegrationEnum;
  issues: string[];
  connected: boolean;
  apiKeySuffix: string | null;
}

export type customerFragmentForCoderPad_integration = customerFragmentForCoderPad_integration_SlackIntegrationV2 | customerFragmentForCoderPad_integration_CoderPadIntegration;

export interface customerFragmentForCoderPad {
  __typename: "Customer";
  id: string;
  integration: customerFragmentForCoderPad_integration | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: locationForEditing
// ====================================================

export interface locationForEditing {
  __typename: "Location";
  id: string;
  name: string;
  arrivalInstructions: string;
  address: string;
  coordinates: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IntegrationsPageIntegration
// ====================================================

export interface IntegrationsPageIntegration {
  __typename: "SlackIntegrationV2" | "CoderPadIntegration" | "GreenhouseIntegrationV2" | "ZoomIntegration";
  id: string;
  type: IntegrationEnum;
  issues: string[];
  connected: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: customerFragmentForZoom
// ====================================================

export interface customerFragmentForZoom_integration {
  __typename: "SlackIntegrationV2" | "CoderPadIntegration" | "GreenhouseIntegrationV2" | "ZoomIntegration";
  id: string;
  type: IntegrationEnum;
  issues: string[];
  connected: boolean;
}

export interface customerFragmentForZoom {
  __typename: "Customer";
  id: string;
  integration: customerFragmentForZoom_integration | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ZoomModalFragment
// ====================================================

export interface ZoomModalFragment_integration {
  __typename: "SlackIntegrationV2" | "CoderPadIntegration" | "GreenhouseIntegrationV2" | "ZoomIntegration";
  type: IntegrationEnum;
  connected: boolean;
}

export interface ZoomModalFragment {
  __typename: "Customer";
  id: string;
  integration: ZoomModalFragment_integration | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: JourneyStepFragment
// ====================================================

export interface JourneyStepFragment_TemplateNoteJourneyStep {
  __typename: "TemplateNoteJourneyStep";
  type: JourneyStepEnum;
  text: string;
}

export interface JourneyStepFragment_TemplateActionJourneyStep_data_emailTemplate {
  __typename: "EmailTemplateV2";
  id: string;
  name: string;
}

export interface JourneyStepFragment_TemplateActionJourneyStep_data {
  __typename: "TemplateCandidateEmailStepData";
  type: ActionJourneyStepEnum;
  cc: string[];
  bcc: string[];
  emailTemplate: JourneyStepFragment_TemplateActionJourneyStep_data_emailTemplate;
}

export interface JourneyStepFragment_TemplateActionJourneyStep {
  __typename: "TemplateActionJourneyStep";
  type: JourneyStepEnum;
  data: JourneyStepFragment_TemplateActionJourneyStep_data;
}

export type JourneyStepFragment = JourneyStepFragment_TemplateNoteJourneyStep | JourneyStepFragment_TemplateActionJourneyStep;

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: StageTemplateInstallationFragment
// ====================================================

export interface StageTemplateInstallationFragment_afterSteps_TemplateNoteJourneyStep {
  __typename: "TemplateNoteJourneyStep";
  type: JourneyStepEnum;
  text: string;
}

export interface StageTemplateInstallationFragment_afterSteps_TemplateActionJourneyStep_data_emailTemplate {
  __typename: "EmailTemplateV2";
  id: string;
  name: string;
}

export interface StageTemplateInstallationFragment_afterSteps_TemplateActionJourneyStep_data {
  __typename: "TemplateCandidateEmailStepData";
  type: ActionJourneyStepEnum;
  cc: string[];
  bcc: string[];
  emailTemplate: StageTemplateInstallationFragment_afterSteps_TemplateActionJourneyStep_data_emailTemplate;
}

export interface StageTemplateInstallationFragment_afterSteps_TemplateActionJourneyStep {
  __typename: "TemplateActionJourneyStep";
  type: JourneyStepEnum;
  data: StageTemplateInstallationFragment_afterSteps_TemplateActionJourneyStep_data;
}

export type StageTemplateInstallationFragment_afterSteps = StageTemplateInstallationFragment_afterSteps_TemplateNoteJourneyStep | StageTemplateInstallationFragment_afterSteps_TemplateActionJourneyStep;

export interface StageTemplateInstallationFragment_stageTemplate_modules {
  __typename: "StageTemplateModule";
  id: string;
  type: string;
  data: GraphQL_Json;
}

export interface StageTemplateInstallationFragment_stageTemplate {
  __typename: "StageTemplate";
  id: string;
  name: string;
  modules: StageTemplateInstallationFragment_stageTemplate_modules[];
}

export interface StageTemplateInstallationFragment_eventTemplates {
  __typename: "EventTemplate";
  id: string;
  title: string;
  position: number;
  description: string;
  hidden: boolean;
  atsEventTemplateId: string;
  atsEventTemplateName: string;
}

export interface StageTemplateInstallationFragment {
  __typename: "StageTemplateInstallation";
  id: string;
  atsStageName: string | null;
  atsStageId: string | null;
  displayName: string;
  activatedAt: GraphQL_DateTime | null;
  hidden: boolean;
  afterSteps: StageTemplateInstallationFragment_afterSteps[];
  stageTemplate: StageTemplateInstallationFragment_stageTemplate;
  eventTemplates: StageTemplateInstallationFragment_eventTemplates[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideTemplateForList
// ====================================================

export interface GuideTemplateForList_jobRole {
  __typename: "JobRole";
  id: string;
  name: string;
  atsJobId: string | null;
}

export interface GuideTemplateForList_createdBy {
  __typename: "GuidesUser";
  name: string;
}

export interface GuideTemplateForList_stageTemplateInstallations {
  __typename: "StageTemplateInstallation";
  id: string;
  displayName: string;
}

export interface GuideTemplateForList {
  __typename: "GuideTemplate";
  id: string;
  name: string;
  jobRole: GuideTemplateForList_jobRole | null;
  updatedAt: GraphQL_DateTime;
  createdBy: GuideTemplateForList_createdBy | null;
  stageTemplateInstallations: GuideTemplateForList_stageTemplateInstallations[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CurrentUserLogin
// ====================================================

export interface CurrentUserLogin_organization_customer {
  __typename: "Customer";
  id: string;
  name: string | null;
}

export interface CurrentUserLogin_organization {
  __typename: "Organization";
  id: string;
  name: string;
  analyticsGroupId: string;
  customer: CurrentUserLogin_organization_customer;
}

export interface CurrentUserLogin {
  __typename: "GuidesUser";
  id: string;
  analyticsUserId: string;
  name: string;
  firstName: string;
  lastName: string;
  canSendEmail: boolean;
  onboardingComplete: boolean;
  organization: CurrentUserLogin_organization;
  roles: string[];
  primaryEmail: string | null;
  title: string;
  intercomHash: string;
  createdAt: GraphQL_DateTime;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ActionJourneyStepEnum {
  EMAILTOCANDIDATE = "EMAILTOCANDIDATE",
}

export enum IntegrationEnum {
  CODERPAD = "CODERPAD",
  GREENHOUSE = "GREENHOUSE",
  SLACK = "SLACK",
  ZOOM = "ZOOM",
}

export enum JourneyStepEnum {
  ACTION = "ACTION",
  NOTE = "NOTE",
  STAGECHANGE = "STAGECHANGE",
}

export enum ORDER_BY_DIRECTIONS {
  ASC = "ASC",
  DESC = "DESC",
}

export enum StepStatusEnum {
  COMPLETE = "COMPLETE",
  INCOMPLETE = "INCOMPLETE",
  PENDING = "PENDING",
}

export interface AddCandidateViewToGuideInput {
  guideId: string;
}

export interface ArchiveEmailTemplateInput {
  id: string;
}

export interface ArchiveGuideInput {
  id: string;
}

export interface ArchiveLocationInput {
  id: string;
}

export interface ArchiveStageTemplateInput {
  id: string;
}

export interface CandidateFindOneInput {
  id: string;
}

export interface CreateEmailTemplateInput {
  name: string;
  subject: string;
  body: string;
}

export interface CreateGuideTemplateForRoleInput {
  jobRoleId: string;
}

export interface CreateLogisticForOrganizationInput {
  emoji: string;
  title: string;
  description?: string | null;
}

export interface CreateOrUpdateUserWithGoogleAuthInput {
  auth0UserId: string;
}

export interface CreateStageTemplateInput {
  copyFromStageTemplateId?: string | null;
  name?: string | null;
  isOnboarding?: boolean | null;
}

export interface CreateStageTemplateModuleInstallationInput {
  stageTemplateId: string;
  stageModuleType: string;
  position?: number | null;
}

export interface CreateValueForOrganizationInput {
  title?: string | null;
  description?: string | null;
}

export interface DeleteLogisticForOrganizationInput {
  id: string;
}

export interface DeleteStageTemplateModuleInstallationInput {
  stageTemplateModuleInstallationId: string;
}

export interface DeleteValueForOrganizationInput {
  id: string;
}

export interface EmailTemplateInput {
  id?: string | null;
}

export interface EventQueryInput {
  id?: string | null;
}

export interface EventTemplateV2UpdateForEventInput {
  eventId: string;
  title?: string | null;
  description?: string | null;
  hidden?: boolean | null;
  addTechScreenLink?: boolean | null;
  addZoomLink?: boolean | null;
}

export interface EventV2UpdateInput {
  id: string;
  title?: string | null;
  description?: string | null;
  hidden?: boolean | null;
  startTime?: GraphQL_DateTime | null;
  endTime?: GraphQL_DateTime | null;
  interviewerIds?: string[] | null;
  remoteInstructions?: string | null;
}

export interface FeedbackForOrganizationInput {
  startTimeRange?: string | null;
  jobRoleId?: string | null;
}

export interface GreenhouseCandidateWhereUniqueInput {
  candidateId: GraphQL_ConstraintString;
  applicationId?: string | null;
}

export interface GuideFindInput {
  id?: string | null;
  customerSlug?: string | null;
  shortId?: string | null;
  atsApplicationId?: string | null;
  legacyOnsiteId?: string | null;
}

export interface GuideStagesInput {
  showHidden?: boolean | null;
}

export interface GuideSyncInput {
  atsApplicationId: string;
  candidateId: string;
}

export interface GuideTemplateInput {
  id: string;
}

export interface GuideUpdateInput {
  id: string;
  roleName?: string | null;
}

export interface GuidesInput {
  offset: number;
  limit: number;
  orderBy?: OrderByInput | null;
}

export interface IntegrationByTypeInput {
  type: IntegrationEnum;
}

export interface InterviewerFilterInput {
  name: string;
}

export interface InterviewerQueryInput {
  id?: string | null;
}

export interface InterviewerV2DeleteInput {
  id: string;
}

export interface InterviewerV2UpdateInput {
  id: string;
  title?: string | null;
  imageUrl?: string | null;
  linkedInUrl?: string | null;
  websiteUrls?: string[] | null;
  biography?: string | null;
  firstName?: string | null;
  lastName?: string | null;
}

export interface InterviewersInput {
  offset: number;
  limit: number;
  orderBy?: OrderByInput | null;
  filter?: InterviewerFilterInput | null;
}

export interface LinkInput {
  url: string;
  label: string;
  imageUrl?: string | null;
}

export interface LinkPreviewInput {
  url: string;
}

export interface LocationCreateInputV2 {
  name: string;
  address?: string | null;
  arrivalInstructions?: string | null;
  coordinates?: string | null;
}

export interface LocationFindInput {
  id?: string | null;
}

export interface LocationInput {
  id: string;
}

export interface LocationUpdateInput {
  id: string;
  name?: string | null;
  address?: string | null;
  arrivalInstructions?: string | null;
  coordinates?: string | null;
}

export interface OrderByInput {
  field: string;
  direction: ORDER_BY_DIRECTIONS;
}

export interface OrganizationLocationInput {
  isArchived?: boolean | null;
}

export interface SendGuideInput {
  body: string;
  subject: string;
  sendFromAddress: GraphQL_EmailAddress;
  sendToAddress: GraphQL_EmailAddress;
  guideId: string;
  stageId: string;
  sendToCc?: GraphQL_EmailAddress[] | null;
  sendToBcc?: GraphQL_EmailAddress[] | null;
  attachments?: string[] | null;
}

export interface SetupStreamChatForGuideInput {
  guideId: string;
}

export interface SimpleTextItem {
  primaryText: string;
  secondaryText: string;
}

export interface StageFindInput {
  id: string;
}

export interface StageTemplateFindInput {
  id?: string | null;
}

export interface StageTemplateModuleUpdateInstallationReorderInput {
  stageTemplateId: string;
  modules: stageTemplateModuleInstallationUpdate[];
}

export interface StreamTokenInput {
  guideId: string;
}

export interface SubmitCandidateFeedbackInput {
  stageId: string;
  data: GraphQL_Json;
}

export interface TemplateActionStepInput {
  emailToCandidate?: TemplateEmailToCandidateInput | null;
}

export interface TemplateEmailToCandidateInput {
  cc: string[];
  bcc: string[];
  emailTemplateId?: string | null;
}

export interface TemplateJourneyStepInput {
  actionStep?: TemplateActionStepInput | null;
  noteStep?: TemplateNoteStepInput | null;
}

export interface TemplateNoteStepInput {
  text: string;
}

export interface ThemeInput {
  primaryColor?: string | null;
  secondaryColor?: string | null;
}

export interface UnarchiveEmailTemplateInput {
  id: string;
}

export interface UnarchiveGuideInput {
  id: string;
}

export interface UnarchiveLocationInput {
  id: string;
}

export interface UnarchiveStageTemplateInput {
  id: string;
}

export interface UpdateCandidateInput {
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  currentTimezone?: string | null;
}

export interface UpdateCultureInstallationForStageTemplateInput {
  header?: string | null;
  stageTemplateId: string;
  companyTeamPhotoUrl: string;
  moduleId: string;
}

export interface UpdateCurrentOrganizationInput {
  theme?: ThemeInput | null;
  name?: string | null;
  missionStatement?: string | null;
  logoUrl?: string | null;
}

export interface UpdateCustomerCoderPadIntegrationInput {
  apiKey: string;
}

export interface UpdateCustomerGreenhouseIntegrationInput {
  apiKey?: string | null;
  maildropAddress?: string | null;
  subdomain?: string | null;
}

export interface UpdateCustomerSlackIntegrationInput {
  auth0SlackUserId?: string | null;
  notificationsChannelId?: string | null;
  autopopulateInterviewer?: boolean | null;
  receiveNotifications?: boolean | null;
}

export interface UpdateCustomerZoomIntegrationInput {
  auth0UserId: string;
}

export interface UpdateEmailTemplateInput {
  id: string;
  name?: string | null;
  subject?: string | null;
  body?: string | null;
}

export interface UpdateGuideTemplateInput {
  id: string;
  chatEnabled?: boolean | null;
  jobRoleNameOverride?: string | null;
  interviewProcessEnabled?: boolean | null;
}

export interface UpdateInterviewTeamInstallationForStageTemplateInput {
  stageTemplateId: string;
  moduleId: string;
  header?: string | null;
}

export interface UpdateLinksInstallationForStageTemplateInput {
  moduleId?: string | null;
  header?: string | null;
  stageTemplateId: string;
  links: LinkInput[];
}

export interface UpdateLocationInstallationForStageTemplateInput {
  moduleId?: string | null;
  header?: string | null;
  stageTemplateId: string;
  location: LocationInput;
}

export interface UpdateLocationModuleInstallationForStageInput {
  stageId: string;
  link: string;
}

export interface UpdateLogisticForOrganizationInput {
  id: string;
  emoji?: string | null;
  title?: string | null;
  description?: string | null;
}

export interface UpdateLogisticsInstallationForStageTemplateInput {
  moduleId?: string | null;
  header?: string | null;
  stageTemplateId: string;
  logisticIds: string[];
}

export interface UpdateMediaInstallationForStageTemplateInput {
  moduleId?: string | null;
  header?: string | null;
  stageTemplateId: string;
  mediaLink: string;
}

export interface UpdateMissionInstallationForStageTemplateV2Input {
  stageTemplateId: string;
  moduleId: string;
  header?: string | null;
}

export interface UpdateOrganizationInterviewerImageInput {
  url: string;
}

export interface UpdateOrganizationLogoInput {
  url: string;
}

export interface UpdateOrganizationMissionInput {
  missionStatement: string;
}

export interface UpdateOrganizationThemeInput {
  primaryColor?: string | null;
  secondaryColor?: string | null;
}

export interface UpdateRichTextInstallationForStageTemplateInput {
  stageTemplateId: string;
  moduleId: string;
  header?: string | null;
  rawHtml: string;
}

export interface UpdateScheduleInstallationForStageTemplateInput {
  stageTemplateId: string;
  moduleId: string;
  showInterviewers?: boolean | null;
  showUnscheduledInterviews?: boolean | null;
  header?: string | null;
}

export interface UpdateSimpleTextListInstallationForStageTemplateInput {
  stageTemplateId: string;
  moduleType: string;
  items: SimpleTextItem[];
}

export interface UpdateStageInput {
  id: string;
  title?: string | null;
  hidden?: boolean | null;
}

export interface UpdateStageTemplateForStageInput {
  stageId: string;
  stageTemplateId: string;
}

export interface UpdateStageTemplateInstallationInput {
  id: string;
  displayName?: string | null;
  hidden?: boolean | null;
  stageTemplateId?: string | null;
  beforeSteps?: TemplateJourneyStepInput[] | null;
  afterSteps?: TemplateJourneyStepInput[] | null;
}

export interface UpdateTimeInstallationForStageTemplateInput {
  stageTemplateId: string;
  moduleId: string;
  header?: string | null;
}

export interface UpdateValueForOrganizationInput {
  value: ValueInput;
}

export interface UpdateValuesInstallationForStageTemplateInput {
  stageTemplateId: string;
  moduleId: string;
  header?: string | null;
}

export interface UploadTeammateImageInput {
  file: GraphQL_Upload;
}

export interface ValueInput {
  id: string;
  title: string;
  description?: string | null;
}

export interface stageTemplateModuleInstallationUpdate {
  id: string;
  position: number;
  type: string;
}

export interface stageTemplateUpdateInput {
  id: string;
  name?: GraphQL_ConstraintString | null;
  emailTemplateId?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
