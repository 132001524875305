import _ from "lodash";
import DOMPurify from "dompurify";
import React, { FunctionComponent, useContext } from "react";
import moment from "moment";
import {
  Typography,
  Avatar,
  Dialog,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/pro-light-svg-icons";
import {
  faLinkedin,
  faGithub,
  faGitlab,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

import { Interviewer } from "~/types";
import { AnalyticsContext } from "~/analytics";

import styles from "./Event.module.scss";

type EventModalDetails = {
  startTime: string;
  endTime: string;
  title: string;
  interviewers: Interviewer[] | null;
  description: string | null;
};

type EventModalProps = {
  event: EventModalDetails;
  open: boolean;
  onClose(): void;
  showInterviewers?: boolean;
};

const EventModal: FunctionComponent<EventModalProps> = ({
  event,
  open,
  onClose,
  showInterviewers = true,
}) => {
  const { title, startTime, endTime, interviewers, description } = event;
  const analytics = useContext(AnalyticsContext);
  const startTimeText = moment(startTime).format("h:mm a");
  const endTimeText = moment(endTime).format("h:mm a");
  const sanitizedTitle = DOMPurify.sanitize(title, {
    ALLOWED_TAGS: [],
  });
  const sanitizedDescription = DOMPurify.sanitize(description || "");

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby={styles.title}
      aria-describedby={styles.description}
      fullWidth
    >
      <div className={styles.dialogContainer}>
        <Typography className={styles.title} variant="h3">
          <div
            dangerouslySetInnerHTML={{
              __html: sanitizedTitle,
            }}
          />
        </Typography>
        <Typography variant="body1" className={styles.time}>
          {`${startTimeText} - ${endTimeText}`}
        </Typography>
        <Typography className={styles.description} variant="body1">
          <div
            dangerouslySetInnerHTML={{
              __html: sanitizedDescription,
            }}
          />
        </Typography>
        {showInterviewers && !!(interviewers || []).length && (
          <div className={styles.interviewersContainer}>
            <Typography variant="h4">Interviewers</Typography>
            {/* Abitrarily grab the first 8, we show them all in the modal */}
            {_.slice(interviewers, 0, 8).map(
              ({
                id,
                imageUrl,
                firstName,
                lastName,
                websiteUrls,
                title: interviewerTitle,
                biography,
              }) => {
                return (
                  <Accordion
                    expanded
                    key={id}
                    style={{
                      boxShadow: "none",
                      borderTop: "solid 1px #f1f1f1",
                      border: "solid 1px #f1f1f1",
                      margin: "5px 0px",
                      borderRadius: "8px",
                    }}
                    onClick={() => {
                      analytics.track("Interviewer Panel Clicked", {
                        interviewerName: `${firstName} ${lastName}`,
                      });
                    }}
                  >
                    <AccordionSummary
                      className={styles.expansionHeader}
                      aria-controls={`${firstName}-${lastName}-content`}
                      id={`${firstName}-${lastName}-header`}
                    >
                      <Avatar
                        key={id}
                        src={imageUrl || undefined}
                        alt={`${firstName} ${lastName} Thumbnail`}
                      >
                        {`${firstName[0]}${lastName[0]}`}
                      </Avatar>
                      <Typography
                        className={styles.name}
                        variant="h6"
                      >{`${firstName} ${lastName}`}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className={styles.detailsCont}>
                        <div className={styles.title}>
                          <Typography variant="h6">
                            {interviewerTitle}
                          </Typography>
                          {_.map(websiteUrls, (url) => {
                            const isLinkedIn = _.includes(url, "linkedin.com");
                            let icon = faLink;
                            if (isLinkedIn) {
                              icon = faLinkedin;
                            } else if (_.includes(url, "github.com")) {
                              icon = faGithub;
                            } else if (_.includes(url, "gitlab.com")) {
                              icon = faGitlab;
                            } else if (_.includes(url, "twitter.com")) {
                              icon = faTwitter;
                            } else if (
                              _.includes(url, "youtube.com") ||
                              _.includes(url, "youtu.be")
                            ) {
                              icon = faYoutube;
                            }

                            return (
                              <IconButton
                                key={url}
                                className={styles.icon}
                                href={url}
                                target="_blank"
                                onClick={(): void => {
                                  analytics.track(
                                    `${
                                      isLinkedIn ? "LinkedIn" : "Website url"
                                    } Clicked`
                                  );
                                }}
                                rel="noopener noreferrer"
                              >
                                <FontAwesomeIcon icon={icon} />
                              </IconButton>
                            );
                          })}
                        </div>
                        <Typography variant="body1">{biography}</Typography>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                );
              }
            )}
          </div>
        )}
      </div>
    </Dialog>
  );
};
export default EventModal;
