import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
  Typography,
  makeStyles,
  MenuItem,
  Accordion,
  useTheme,
  AccordionSummary,
  AccordionDetails,
  TextField,
  IconButton,
} from "@material-ui/core";
import { useMutation } from "@apollo/client";
import {
  faBolt,
  faChevronDown,
  faChevronUp,
  faTrash,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UPDATE_STAGE_TEMPLATE_INSTALLATION } from "./StageChangeJourneyStep";
import {
  UpdateStageTemplateInstallationForJourneyStep,
  UpdateStageTemplateInstallationForJourneyStepVariables,
  TemplateEmailToCandidateInput,
  GuideTemplateForEditing_guideTemplate_stageTemplateInstallations_afterSteps_TemplateActionJourneyStep as ActionJourneyStepType,
  GuideTemplateForEditing_currentUserV2_organization_emailTemplates as EmailTemplate,
  ActionJourneyStepEnum,
} from "~/schemaTypes";
import EmailSelect from "~/components/EmailSelect";
import { ResourceCustomTheme } from "~/styles/config";
import useAnalytics from "~/react-hooks/useAnalytics";

import TimelineIndicator from "~/components/TimelineIndicator";

const useGlobalStyles = makeStyles((theme: ResourceCustomTheme) => ({
  root: {
    display: "flex",
    zIndex: 1,
    marginTop: 8,
    marginBottom: 4,
    marginLeft: 20,
    alignItems: "center",
  },
  card: {
    marginLeft: 10,
    borderColor: theme.colors.RoyalPurple(300),
    flexGrow: 1,
  },
  cardDetails: {
    display: "flex",
  },
  action: {
    flexShrink: 0,
  },
  cardSummary: {
    width: "100%",
    display: "block",
  },
  header: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  chevronIcon: {
    flexShrink: 0,
  },
  templatePicker: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
  },
  field: {},
  emailSelect: {
    marginBottom: theme.spacing(1),
  },
}));

type ActionJourneyStepProps = {
  step: ActionJourneyStepType;
  stageTemplateInstallationId: string;
  emailTemplates: EmailTemplate[];
  onUpdateEmailTemplate(emailTemplateId: string): void;
  onRemoveEmailTemplate(): void;
};

const ActionJourneyStep: React.FC<ActionJourneyStepProps> = ({
  step,
  stageTemplateInstallationId,
  emailTemplates,
  onRemoveEmailTemplate,
}) => {
  const [analytics] = useAnalytics();
  const classes = useGlobalStyles();
  const [expanded, setExpanded] = useState<boolean>(false);
  const theme = useTheme<ResourceCustomTheme>();
  const [ccEmails, setCcEmails] = useState<string[]>(step.data.cc);
  const [bccEmails, setBccEmails] = useState<string[]>(step.data.bcc);
  const [emailTemplateId, setEmailTemplateId] = useState<string>(
    step.data.emailTemplate.id
  );

  useEffect(() => {
    setCcEmails(step.data.cc);
    setBccEmails(step.data.bcc);
    setEmailTemplateId(step.data.emailTemplate.id);
  }, [step]);

  const [updateStageTemplateInstallation] = useMutation<
    UpdateStageTemplateInstallationForJourneyStep,
    UpdateStageTemplateInstallationForJourneyStepVariables
  >(UPDATE_STAGE_TEMPLATE_INSTALLATION, {
    refetchQueries: ["GetOrgGuideTemplateData", "GuideTemplateForEditing"],
  });

  const handleUpdate = async (
    args: Partial<TemplateEmailToCandidateInput>
  ): Promise<void> => {
    await updateStageTemplateInstallation({
      variables: {
        input: {
          id: stageTemplateInstallationId,
          afterSteps: [
            {
              actionStep: {
                emailToCandidate: {
                  cc: ccEmails,
                  bcc: bccEmails,
                  emailTemplateId,
                  ...args,
                },
              },
            },
          ],
        },
      },
    });
  };

  const handleUpdateEmailTemplateDefault = (newEmailTemplateId) => {
    setEmailTemplateId(newEmailTemplateId);
    handleUpdate({ emailTemplateId: newEmailTemplateId });
  };

  const handleUpdateEmailCCDefaults = (cc) => {
    analytics.track("Email Action CC Added");
    setCcEmails(cc);
    handleUpdate({ cc });
  };

  const handleUpdateEmailBCCDefaults = (bcc) => {
    analytics.track("Email Action BCC Added");
    setBccEmails(bcc);
    handleUpdate({ bcc });
  };

  let label;

  switch (step.data.type) {
    case ActionJourneyStepEnum.EMAILTOCANDIDATE:
      label = (
        <>
          Send{" "}
          <span style={{ textDecoration: "underline" }}>
            {step.data.emailTemplate.name}
          </span>{" "}
          Email
        </>
      );
      break;
    default:
      throw new Error("Unsupported Action Step type");
  }

  return (
    <div className={classes.root}>
      <TimelineIndicator
        icon={<FontAwesomeIcon icon={faBolt} size="sm" />}
        color={theme.colors.RoyalPurple(300)}
      />
      <Accordion
        className={classes.card}
        expanded={expanded}
        onChange={() => {
          setExpanded((prevExpanded) => !prevExpanded);
        }}
      >
        <AccordionSummary>
          <div className={classes.header}>
            <Typography variant="body1">{label}</Typography>
            <Typography variant="body1" className={classes.chevronIcon}>
              <FontAwesomeIcon
                icon={expanded ? faChevronUp : faChevronDown}
                size="1x"
              />
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.cardSummary}>
          <div className={classes.templatePicker}>
            <TextField
              className={classes.field}
              defaultValue={step.data.emailTemplate.id}
              select
              fullWidth
              label="Email Template"
              onChange={(e) => {
                analytics.track("Email Action Template Switched");
                handleUpdateEmailTemplateDefault(e.target.value);
              }}
              variant="outlined"
            >
              {_.map(emailTemplates, ({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </TextField>
            <IconButton
              className={classes.action}
              onClick={() => {
                analytics.track("Email Action Removed");
                onRemoveEmailTemplate();
              }}
            >
              <FontAwesomeIcon
                icon={faTrash}
                size="1x"
                color={theme.colors.ElectricRed()}
              />
            </IconButton>
          </div>
          <EmailSelect
            label="CC"
            className={classes.emailSelect}
            emails={ccEmails}
            onChange={handleUpdateEmailCCDefaults}
          />
          <EmailSelect
            label="BCC"
            emails={bccEmails}
            className={classes.emailSelect}
            onChange={handleUpdateEmailBCCDefaults}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ActionJourneyStep;
