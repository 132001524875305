import gql from "graphql-tag";

export default gql`
  mutation UpdateLinksInstallationForStageTemplate(
    $input: UpdateLinksInstallationForStageTemplateInput!
  ) {
    updateLinksInstallationForStageTemplate(input: $input) {
      success
      stageTemplate {
        id
        name
        updatedAt
      }
    }
  }
`;
