import React, { useCallback, useEffect } from "react";
import { Auth0Error } from "auth0-js";
import { Typography } from "@material-ui/core";
import { useHistory, useLocation } from "react-router";

import { useQuery } from "@apollo/client";
import { useFlashMessage } from "~/components/FlashMessage/FlashMessage";
import LoginButtonGroupGuidesAuth from "~/components/LoginButtonGroupGuidesAuth/LoginButtonGroupGuidesAuth";
import { ProviderEnum } from "~/utils/auth";
import logo from "~/assets/images/logos/guides.svg";
import emptyGuideImage from "~/assets/images/emptyGuideState.png";
import CURRENT_USER_QUERY from "~/queries/CURRENT_USER_QUERY";
import { LocationState } from "~/routing";
import inIframe from "~/utils/in-iframe";

import styles from "./Login.module.scss";
import { CurrentUser } from "~/schemaTypes";

type LoginProps = Record<string, never>;

const Login: React.FC<LoginProps> = () => {
  const { data: currentUser } = useQuery<CurrentUser>(CURRENT_USER_QUERY);
  const history = useHistory<LocationState>();
  const { state: { referrer } = { referrer: "" } } = useLocation<
    LocationState
  >();
  const { setContent } = useFlashMessage();

  // If at any point we have a currentUser, redirect to the referrer
  useEffect(() => {
    if (currentUser?.currentUserV2) {
      if (!referrer || referrer === "/login") {
        history.push("/");
      } else {
        history.push(referrer);
      }
    }
  }, [currentUser?.currentUserV2, history, referrer]);

  const onLogin = useCallback(
    (err?: Auth0Error): void => {
      if (err) {
        if (err.error_description === "customer.inactive") {
          setContent({
            content: err.error_description,
            severity: "error",
          });
        } else {
          setContent({
            content: "We could not login with your account.",
            severity: "error",
          });
        }
      } else if (inIframe()) {
        // If we're in an iframe, we'll be using loginWithPopup
        // and need to refresh the window post-login
        if (!referrer || referrer === "/login") {
          window.location.href = "/";
        } else {
          window.location.href = referrer;
        }
      }
    },
    [setContent, referrer]
  );

  return (
    <div className={styles.gridCont}>
      <header className={styles.header}>
        <img src={logo} alt="Resource logo" title="Resource logo" />
      </header>
      <div className={styles.sidebar}>
        <div className={styles.loginCont}>
          <Typography variant="h3">
            <span role="img" aria-label="Waving Hand">
              👋
            </span>{" "}
            Hey there
          </Typography>
          <Typography className={styles.bodyText} variant="body2">
            Welcome to Guide, we’re happy you’re here! To get started, just sign
            in{" "}
            <span role="img" aria-label="Hand Pointing Down">
              👇
            </span>
          </Typography>
          <LoginButtonGroupGuidesAuth
            onLogin={onLogin}
            providers={[ProviderEnum.Google]}
            usePopup={inIframe()}
          />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.gettingStarted}>
          <img src={emptyGuideImage} alt="guide example" />
        </div>
      </div>
    </div>
  );
};

export default Login;
