import React, { FunctionComponent, useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import { Typography } from "@material-ui/core";
import { sanitizeHtml } from "~/utils/";

import { AnalyticsContext } from "~/analytics";

import styles from "./ArrivalInstructions.module.scss";

type ArrivalInstructionsProps = {
  arrivalInstructions: string;
};

const ArrivalInstructions: FunctionComponent<ArrivalInstructionsProps> = ({
  arrivalInstructions,
}) => {
  const analytics = useContext(AnalyticsContext);
  const [shouldShowAll, setShouldShowAll] = useState(false);

  return (
    <div className={styles.arrivalInstructionsContainer}>
      <div
        className={!shouldShowAll ? styles.fade : styles.withoutFade}
        style={{
          maxHeight: !shouldShowAll ? "100px" : "600px",
        }}
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(arrivalInstructions),
        }}
      />
      <div className={styles.seeMoreWrapper}>
        <Typography
          className={styles.seeMore}
          onClick={() => {
            analytics.track("See More On Arrival Instructions Clicked");
            setShouldShowAll(!shouldShowAll);
          }}
          variant="body1"
        >
          {shouldShowAll ? "Show Less" : "Show More"}
          <FontAwesomeIcon
            className={styles.chevron}
            icon={faChevronUp}
            size="xs"
            style={{
              transform: shouldShowAll ? "rotate(0deg)" : "rotate(180deg)",
            }}
          />
        </Typography>
      </div>
    </div>
  );
};

export default ArrivalInstructions;
