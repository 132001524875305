import React, { FunctionComponent, useState, useEffect } from "react";
import { TextField } from "@material-ui/core";

import EDIT_MEDIA_MUTATION from "./EDIT_MEDIA_MUTATION";
import { EditModulesGlobalProps } from "../EditModuleContainer";

import styles from "./EditMedia.module.scss";

type EditMediaProps = {
  mediaLink: string;
  moduleId: string;
  header?: string;
};

const EditMedia: FunctionComponent<EditMediaProps & EditModulesGlobalProps> = ({
  mediaLink,
  header,
  moduleId,
  setHandleSaveMutation,
  setHandleSaveMutationInput,
}) => {
  const [mediaLinkOverride, setMediaLink] = useState<string>(mediaLink);
  const [headerOverride, setHeaderOverride] = useState<string>(header || "");

  useEffect(() => {
    setHandleSaveMutation(EDIT_MEDIA_MUTATION);
    setHandleSaveMutationInput({
      input: {
        mediaLink: mediaLinkOverride,
        header: headerOverride,
        moduleId,
      },
    });
  }, [
    setHandleSaveMutation,
    setHandleSaveMutationInput,
    headerOverride,
    mediaLinkOverride,
    moduleId,
  ]);

  return (
    <div className={styles.formContainer}>
      <TextField
        fullWidth
        value={headerOverride}
        onChange={(e) => setHeaderOverride(e.target.value)}
        style={{ marginBottom: 6 }}
        label="Header"
      />
      <TextField
        fullWidth
        variant="outlined"
        label="YouTube Url"
        value={mediaLink}
        placeholder="e.g. https://www.youtube.com/watch?v=oHg5SJYRHA0"
        onChange={(e): void => setMediaLink(e.target.value)}
      />
    </div>
  );
};
export default EditMedia;
