import React, { Suspense, useEffect } from "react";
import {
  Divider,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Button,
  createStyles,
  makeStyles,
  StyleRules,
  Theme,
} from "@material-ui/core";
import { useQuery, useMutation } from "@apollo/client";
import { retry, EMAIL_PLACEHOLDERS } from "@resource/common";
import { Controller, useForm } from "react-hook-form";
import gql from "graphql-tag";
import Loading from "~/components/Loading/Loading";
import DialogTitle from "~/components/Dialog/DialogTitle/DialogTitle";
import {
  CreateEmailTemplateFromEmailTemplatesPage,
  CreateEmailTemplateFromEmailTemplatesPageVariables,
  UpdateEmailTemplateFromEmailTemplatesPage,
  UpdateEmailTemplateFromEmailTemplatesPageVariables,
  EmailTemplateQueryForModal,
  EmailTemplateQueryForModalVariables,
} from "~/schemaTypes";

const RichTextEditor = React.lazy(() =>
  retry(() => import("~/components/RichTextEditor/RichTextEditor"))
);

const useStyles = makeStyles(
  (theme: Theme): StyleRules =>
    createStyles({
      actions: {
        marginTop: "5px",
        marginLeft: "375px",
        marginBottom: "15px",
        marginRight: theme.spacing(3),
        padding: 0,
      },
      dialogContent: {
        overflowY: "visible",
      },
    })
);

type EmailTemplateModalProps = {
  emailTemplateId: string | undefined;
  onClose: () => void;
  open: boolean;
};

const EMAIL_TEMPLATE_QUERY = gql`
  query EmailTemplateQueryForModal($input: EmailTemplateInput!) {
    emailTemplate(input: $input) {
      id
      name
      subject
      body
    }
  }
`;

const CREATE_EMAIL_TEMPLATE_MUTATION = gql`
  mutation CreateEmailTemplateFromEmailTemplatesPage(
    $input: CreateEmailTemplateInput!
  ) {
    createEmailTemplate(input: $input) {
      emailTemplate {
        id
        name
        updatedAt
        createdBy
      }
    }
  }
`;

const UPDATE_EMAIL_TEMPLATE_MUTATION = gql`
  mutation UpdateEmailTemplateFromEmailTemplatesPage(
    $input: UpdateEmailTemplateInput!
  ) {
    updateEmailTemplate(input: $input) {
      emailTemplate {
        id
        name
        subject
        body
        updatedAt
        createdBy
      }
    }
  }
`;

const EmailTemplateModal: React.FC<EmailTemplateModalProps> = ({
  open,
  onClose,
  emailTemplateId,
}) => {
  const { register, control, reset, errors, handleSubmit } = useForm();
  const classes = useStyles();

  const { data: emailTemplateData, loading } = useQuery<
    EmailTemplateQueryForModal,
    EmailTemplateQueryForModalVariables
  >(EMAIL_TEMPLATE_QUERY, {
    skip: !emailTemplateId,
    variables: {
      input: {
        id: emailTemplateId,
      },
    },
  });

  const [createEmailTemplate, { loading: creatingEmailTemplate }] = useMutation<
    CreateEmailTemplateFromEmailTemplatesPage,
    CreateEmailTemplateFromEmailTemplatesPageVariables
  >(CREATE_EMAIL_TEMPLATE_MUTATION);

  const [updateEmailTemplate, { loading: updatingEmailTemplate }] = useMutation<
    UpdateEmailTemplateFromEmailTemplatesPage,
    UpdateEmailTemplateFromEmailTemplatesPageVariables
  >(UPDATE_EMAIL_TEMPLATE_MUTATION);

  useEffect(() => {
    if (emailTemplateData?.emailTemplate) {
      const { name, subject, body } = emailTemplateData.emailTemplate;
      reset({
        name,
        subject,
        body,
      });
    }
  }, [emailTemplateData, reset]);

  const handleClose = () => {
    reset();
    onClose();
  };

  const onSubmit = async (data) => {
    if (emailTemplateId) {
      await updateEmailTemplate({
        variables: {
          input: {
            id: emailTemplateId,
            ...data,
          },
        },
      });
    } else {
      await createEmailTemplate({
        variables: {
          input: data,
        },
        refetchQueries: ["GetOrgEmailTemplates"],
      });
    }
    handleClose();
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth scroll="body">
      <DialogTitle onClose={handleClose}>
        <span aria-label="mail" role="img">
          {" "}
          ✉️{" "}
        </span>{" "}
        {emailTemplateId ? "Edit" : "Create"} an Email Template{" "}
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent className={classes.dialogContent}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <TextField
                inputRef={register({ required: true })}
                variant="outlined"
                margin="dense"
                name="name"
                label="Template Name"
                defaultValue=""
                error={!!errors.name}
                fullWidth
              />
            </Grid>
            <Suspense fallback={<Loading />}>
              <Grid item>
                <Controller
                  name="subject"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={(props) => (
                    <RichTextEditor
                      label="Subject"
                      toolbar="top"
                      formats={["placeholder"]}
                      value={props.value}
                      error={!!errors.subject}
                      onChange={props.onChange}
                      onBlur={props.onBlur}
                      placeholders={EMAIL_PLACEHOLDERS}
                      trimEmpty
                      toolbarFormats={{
                        placeholder: true,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Controller
                  name="body"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={(props) => (
                    <RichTextEditor
                      label="Body"
                      toolbar="top"
                      error={!!errors.body}
                      value={props.value}
                      onChange={props.onChange}
                      onBlur={props.onBlur}
                      placeholders={EMAIL_PLACEHOLDERS}
                      placeholder="Hello 👋"
                      trimEmpty
                    />
                  )}
                />
              </Grid>
            </Suspense>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            color="primary"
            variant="contained"
            size="large"
            type="submit"
            fullWidth
            disabled={creatingEmailTemplate || updatingEmailTemplate}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default EmailTemplateModal;
