import _ from "lodash";
import React, { useState, useEffect } from "react";
import cx from "classnames";
import { ModuleEnum } from "@resource/common";
import { EditModulesGlobalProps } from "../EditModuleContainer";
import EditGlobalInfoText from "../Shared/EditGlobalInfoText/EditGlobalInfoText";
import UPDATE_SIMPLE_TEXT_LIST_MODULE_MUTATION from "./UPDATE_SIMPLE_TEXT_LIST_MODULE_MUTATION";
import EditSimpleTextList from "~/components/EditSimpleTextList/EditSimpleTextList";

import styles from "./form.module.scss";

type DraftSimpleTextItem = {
  id?: string;
  primaryText?: string;
  secondaryText?: string;
};

type EditSimpleTextListProps = {
  items: DraftSimpleTextItem[];
  moduleType: string;
  config: {
    header: string;
    itemName: string;
    primaryLabel: string;
    secondaryLabel: string;
  };
};

const EditSimpleTextListModal: React.FC<
  EditSimpleTextListProps & EditModulesGlobalProps
> = ({
  setHandleSaveMutation,
  setHandleSaveMutationInput,
  items,
  config,
  moduleType,
}) => {
  const [updatedItems, setUpdatedItems] = useState<DraftSimpleTextItem[]>(
    items
  );

  setHandleSaveMutation(UPDATE_SIMPLE_TEXT_LIST_MODULE_MUTATION);

  useEffect(() => {
    setHandleSaveMutationInput({
      input: {
        moduleType,
        items: _.map(updatedItems, (i) =>
          _.pick(i, ["primaryText", "secondaryText"])
        ),
      },
    });
  }, [moduleType, setHandleSaveMutationInput, updatedItems]);

  return (
    <div className={cx(styles.formContainer)}>
      <div className={cx(styles.titleBar)}>
        {moduleType !== ModuleEnum.Links &&
          moduleType !== ModuleEnum.Expectations && <EditGlobalInfoText />}
      </div>
      <EditSimpleTextList
        items={updatedItems}
        setItems={setUpdatedItems}
        config={config}
      />
    </div>
  );
};

export default EditSimpleTextListModal;
