import _ from "lodash";
import React from "react";
import { TextField } from "@material-ui/core";
import Autocomplete, {
  AutocompleteRenderInputParams,
} from "@material-ui/lab/Autocomplete";
import { withStyles } from "@material-ui/core/styles";
import { useApolloClient } from "@apollo/client";
import {
  CurrentAvailableOrganizations_currentUserV2_organization as Organization,
  CurrentAvailableOrganizations_currentUserV2_availableOrganizations as AvailableOrganizations,
} from "~/schemaTypes";
import { currentOrganizationId } from "~/cache";

const LightAutocomplete = withStyles(() => ({
  input: {
    color: "white",
    underline: {
      color: "white",
    },
  },
  clearIndicatorDirty: {
    color: "white",
  },
  popupIndicator: {
    color: "white",
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
}))(Autocomplete);

type CompanySelectorProps = {
  light?: boolean;
  className?: string;
  textFieldProps?: Record<string, unknown>;
  availableOrganizations?: AvailableOrganizations[];
  organization?: Organization;
};

const CompanySelector: React.FC<CompanySelectorProps> = ({
  light = true,
  className,
  textFieldProps,
  availableOrganizations,
  organization,
}) => {
  const client = useApolloClient();

  const onChange = (e: React.ChangeEvent<unknown>, value: unknown): void => {
    const newOrganization = value as AvailableOrganizations;
    const organizationId = _.find(
      availableOrganizations,
      ({ name }) => name === newOrganization.name
    )?.id;
    if (organizationId && newOrganization?.name !== organization?.name) {
      currentOrganizationId(organizationId);
      try {
        localStorage.setItem(
          "__PersistedCurrentOrganizationIdStateKeyV2",
          organizationId
        );
      } catch (error) {
        console.info("Not writing persisted organization key");
        console.info(error);
      }
      client.resetStore();
    }
  };

  if (!availableOrganizations || availableOrganizations?.length <= 1) {
    return null;
  }

  const AutoCompleteComponent = light ? LightAutocomplete : Autocomplete;

  return (
    <header>
      <AutoCompleteComponent
        className={className}
        id="combo-box-demo"
        options={availableOrganizations}
        getOptionLabel={(option: unknown): string =>
          (option as Organization).name
        }
        getOptionSelected={(option: unknown, value: unknown) =>
          (option as Organization).id === (value as Organization).id
        }
        disableClearable
        openOnFocus
        value={organization as Organization}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            color="primary"
            variant="outlined"
            {...params}
            {...textFieldProps}
          />
        )}
        onChange={onChange}
      />
    </header>
  );
};

export default CompanySelector;
