import "regenerator-runtime/runtime";
import _ from "lodash";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { StylesProvider } from "@material-ui/core/styles";
import * as Sentry from "@sentry/react";
import * as moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
import { useMutation } from "@apollo/client";
import React from "react";
import { render } from "react-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { setup } from "@resource/client-ffs";
import gql from "graphql-tag";
import { EmailAddresses, DisallowedDomains } from "@resource/common";
import { Router } from "react-router";
import { createBrowserHistory, History } from "history";
import { Auth0Client } from "@auth0/auth0-spa-js";
import initializeAuth0Client from "~/utils/auth/initializeAuth0Client";

import Loading from "~/components/Loading/Loading";
import { resourceTheme } from "~/styles/config";
import "~/styles/base.scss";
import * as serviceWorker from "./serviceWorker";
import setupAnalytics from "./setup-analytics";
import { AnalyticsContext } from "./analytics";
import { Analytics } from "./types";
import "./setup-fontAwesome.js";
import "./setup-sentry";

import { Auth0Provider, AppState, useAuth0 } from "./react-auth0";
import AuthorizedApolloProvider from "./utils/auth/AuthorizedApolloProvider";
import { CurrentlyViewingGuideProvider } from "./utils/auth/CurrentlyViewingGuideProvider";

import { setFlashMessageContent } from "~/components/FlashMessage/FlashMessage";
import {
  CurrentUser,
  StoreGoogleAuth,
  StoreGoogleAuthVariables,
} from "~/schemaTypes";
import CURRENT_USER_QUERY, {
  CurrentUserFragment,
} from "~/queries/CURRENT_USER_QUERY";
import App from "./App";
import { HistoryContext } from "./HistoryContext";

momentDurationFormatSetup(moment);

if (window.location !== window.parent.location) {
  // eslint-disable-next-line no-underscore-dangle
  window._fs_is_outer_script = true;
}

const AppWrapper: React.FC = () => {
  const analytics: Analytics = setupAnalytics();
  const { loading } = useAuth0();
  return loading ? (
    <Loading />
  ) : (
    <AnalyticsContext.Provider value={analytics}>
      <App />
    </AnalyticsContext.Provider>
  );
};

const StoreGoogleAuthMutation = gql`
  mutation StoreGoogleAuth($input: CreateOrUpdateUserWithGoogleAuthInput!) {
    createOrUpdateUserWithGoogleAuth(input: $input) {
      code
      message
      success
      user {
        ...CurrentUserLogin
      }
    }
  }
  ${CurrentUserFragment}
`;

type AuthWrapperProps = {
  auth0Client: Auth0Client;
  history: History;
};

const auth0ClientInitOptions = {
  domain: process.env.REACT_APP_AUTH0_TENANT_DOMAIN!,
  client_id: process.env.REACT_APP_AUTH0_GUIDES_CLIENT_ID!,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  redirect_uri: window.location.origin,
};

const AuthWrapper: React.FC<AuthWrapperProps> = ({ auth0Client, history }) => {
  const [storeGoogleAuth] = useMutation<
    StoreGoogleAuth,
    StoreGoogleAuthVariables
  >(StoreGoogleAuthMutation);

  const createOrUpdateToken = async (): Promise<void> => {
    const token = await auth0Client.getTokenSilently();
    const auth0User = await auth0Client.getUser();
    if (!token) {
      return;
    }
    const customerDomain = EmailAddresses.parseOneAddress(auth0User.email)
      ?.domain;
    if (_.includes(DisallowedDomains, customerDomain)) {
      setFlashMessageContent({
        content: `Login with the ${customerDomain} domain is not allowed for Guide. Sign in with your G Suite account!`,
        severity: "error",
      });
      return;
    }

    try {
      await storeGoogleAuth({
        variables: {
          input: {
            auth0UserId: auth0User.sub,
          },
        },
        update: (cache, res) => {
          const latestUser = res.data?.createOrUpdateUserWithGoogleAuth.user;
          cache.writeQuery<CurrentUser>({
            query: CURRENT_USER_QUERY,
            data: {
              currentUserV2: latestUser || null,
            },
          });
        },
      });
    } catch (error_) {
      Sentry.captureException(error_);
    }
  };

  const onRedirectCallback = async (appState: AppState): Promise<void> => {
    await createOrUpdateToken();
    const redirectPath = appState.referrer || "/";
    history.replace(redirectPath);
  };

  return (
    <Auth0Provider
      timeout="1000"
      onRedirectCallback={onRedirectCallback}
      {...auth0ClientInitOptions}
    >
      <StylesProvider injectFirst>
        <ThemeProvider theme={resourceTheme}>
          <CssBaseline />
          <AppWrapper />
        </ThemeProvider>
      </StylesProvider>
    </Auth0Provider>
  );
};

(async (): Promise<void> => {
  let FlagProvider;
  let auth0Client;

  try {
    [FlagProvider, auth0Client] = await Promise.all([
      setup({
        clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_KEY || "",
        user: {
          anonymous: true,
        },
      }),
      initializeAuth0Client(auth0ClientInitOptions),
    ]);
  } catch (error) {
    // Stuff has gone REALLY wrong
    Sentry.captureException(error);
    render(<h1>Something went wrong.</h1>, document.getElementById("root"));
    return;
  }
  // Create our own history so that we can access it outside of react context
  const history = createBrowserHistory();

  render(
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <FlagProvider>
        <CurrentlyViewingGuideProvider>
          <AuthorizedApolloProvider auth0Client={auth0Client}>
            <HistoryContext.Provider value={history}>
              <Router history={history}>
                <AuthWrapper auth0Client={auth0Client} history={history} />
              </Router>
            </HistoryContext.Provider>
          </AuthorizedApolloProvider>
        </CurrentlyViewingGuideProvider>
      </FlagProvider>
    </MuiPickersUtilsProvider>,
    document.getElementById("root")
  );
})(); // If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
