import gql from "graphql-tag";

export default gql`
  query StageById($input: StageFindInput!) {
    stage(input: $input) {
      id
      guide {
        id
        chatEnabled
        createdFromATS
        candidate {
          id
        }
      }
      title
      sortedVisibleEvents {
        id
        title
        description
        remoteInstructions
        startTime
        endTime
        hidden
        techScreenLink
        zoomLink
        prominentInstructions {
          id
          label
          url
        }
        interviewers {
          id
          firstName
          lastName
          title
          linkedInUrl
          websiteUrls
          imageUrl
          biography
        }
      }
      modules {
        id
        type
        displayType
        data
      }
    }
  }
`;
