import gql from "graphql-tag";
import StageTemplateWithModulesFragment from "~/fragments/STAGE_TEMPLATE_WITH_MODULES_FRAGMENT";

export default gql`
  query StageTemplateForEditing($input: StageTemplateFindInput!) {
    stageTemplate(input: $input) {
      ...stageTemplateWithModules
    }
    currentUserV2 {
      id
      organization {
        id
        emailTemplates {
          id
          name
        }
      }
    }
  }
  ${StageTemplateWithModulesFragment}
`;
