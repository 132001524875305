import _ from "lodash";
import React, { FunctionComponent } from "react";
import { Typography, Fade } from "@material-ui/core";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobeAmericas } from "@fortawesome/pro-solid-svg-icons";

import { FeatureFlagEnum } from "@resource/common";
import { useFlags } from "@resource/client-ffs";
import Loading from "~/components/Loading/Loading";
import { FetchCompanyData } from "~/schemaTypes";
import styles from "./Company.module.scss";
import LocationsComponent from "./Locations/Locations";
import ImagesComponent from "./Images/Images";
import BrandComponent, { CompanyTheme } from "./Brand/Brand";
import MissionComponent from "./Mission/Mission";
import ValuesComponent from "./Values/Values";
import CustomLogisticsComponent from "./CustomLogistics/CustomLogistics";

type CompanyProps = Record<string, unknown>;

const COMPANY_PAGE_QUERY = gql`
  query FetchCompanyData {
    currentUserV2 {
      id
      organization {
        id
        name
        locations {
          id
          name
          address
          coordinates
          isArchived
        }
        missionStatement
        theme
        companyLogoUrl
        defaultAvatarImageUrl
        values {
          id
          title
          description
        }
        customLogistics {
          id
          emoji
          title
          description
        }
      }
    }
  }
`;

const Company: FunctionComponent<CompanyProps> = () => {
  const {
    [_.camelCase(FeatureFlagEnum.CustomLogistics)]: customLogisticsFlag,
  } = useFlags();
  const { data: companyData, loading: companyDataLoading, refetch } = useQuery<
    FetchCompanyData
  >(COMPANY_PAGE_QUERY);

  if (companyDataLoading) {
    return <Loading text="Loading your company data.." />;
  }

  if (!companyData?.currentUserV2) {
    return <Typography variant="h4">An unexpected error occurred.</Typography>;
  }

  const {
    companyLogoUrl,
    defaultAvatarImageUrl,
    locations,
    missionStatement,
    theme,
    values,
    customLogistics,
  } = companyData.currentUserV2.organization;

  return (
    <Fade in timeout={1000}>
      <div className={styles.companyContainer}>
        <div className={styles.headerCont}>
          <Typography className={styles.subtitleText} variant="body1">
            <FontAwesomeIcon
              style={{
                marginRight: "7px",
                fontSize: "24px",
              }}
              icon={faGlobeAmericas}
            />
            This carries through all guides, org-wide
          </Typography>
        </div>
        <div className={styles.editCont}>
          <LocationsComponent locations={locations} refetchCompany={refetch} />
          <ImagesComponent
            defaultAvatarImageUrl={defaultAvatarImageUrl}
            logoUrl={companyLogoUrl}
          />
          <BrandComponent theme={theme as CompanyTheme} />
          <MissionComponent missionStatement={missionStatement} />
          <ValuesComponent values={values} />
          {customLogisticsFlag && (
            <CustomLogisticsComponent customLogistics={customLogistics} />
          )}
        </div>
      </div>
    </Fade>
  );
};

export default Company;
