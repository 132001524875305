import React, { FunctionComponent } from "react";

import { Button, useTheme } from "@material-ui/core";
import { EditModulesGlobalProps } from "../EditModuleContainer";

import styles from "./EditInterviewScheduler.module.scss";

import Loading from "~/components/Loading/Loading";
import useNylas from "~/components/utils/useNylas";

type EditInterviewSchedulerProps = {
  pageEditToken: string;
};

const EditInterviewScheduler: FunctionComponent<
  EditInterviewSchedulerProps & EditModulesGlobalProps
> = ({ pageEditToken }) => {
  const { nylas } = useNylas();
  const theme = useTheme();

  if (!nylas) {
    return <Loading />;
  }

  return (
    <div className={styles.formContainer}>
      <Button
        onClick={() => {
          nylas.scheduler.show({
            auth: {
              // Account access_token with active calendar scope
              pageEditToken,
            },
            style: {
              // Style the schdule editor
              tintColor: theme.palette.primary,
              backgroundColor: "white",
            },
          });
        }}
      >
        Manage Availability Calendar
      </Button>
    </div>
  );
};

export default EditInterviewScheduler;
