import _ from "lodash";
import React, { useContext } from "react";
import cx from "classnames";
import { IconButton, Typography, TextField, Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { AnalyticsContext } from "~/analytics";

import styles from "./EditSimpleTextList.module.scss";

type DraftSimpleTextItem = {
  id?: string;
  primaryText?: string;
  secondaryText?: string;
};

type EditSimpleTextListProps = {
  items: DraftSimpleTextItem[];
  config: {
    itemName: string;
    primaryLabel: string;
    secondaryLabel: string;
  };
  setItems(items: DraftSimpleTextItem[]): void;
};

const EditSimpleTextList: React.FC<EditSimpleTextListProps> = ({
  items,
  setItems,
  config,
}) => {
  const analytics = useContext(AnalyticsContext);

  return (
    <div className={styles.formContainer}>
      {_.map(items, (item, idx) => (
        <React.Fragment key={item.id || idx}>
          <div className={cx(styles.header)}>
            <Typography variant="h6">
              {config.itemName} {idx + 1}
            </Typography>
            <IconButton
              aria-label="delete"
              onClick={() => {
                analytics.track(`Delete ${config.itemName} Clicked`);
                const filteredItems = _.filter(items, (i) => i !== item);
                setItems(filteredItems);
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </IconButton>
          </div>
          <TextField
            className={styles.primaryText}
            variant="outlined"
            aria-label={`${config.primaryLabel} Title`}
            placeholder={`${config.primaryLabel}`}
            value={items[idx].primaryText}
            onChange={(e: any): void => {
              const newItems = _.map(items, (existingItem, index) =>
                Number(index) === idx
                  ? {
                      ...existingItem,
                      primaryText: e.target.value,
                    }
                  : existingItem
              );
              setItems(newItems);
            }}
            onBlur={() => {
              analytics.track("Value Title Edited");
            }}
          />
          <TextField
            className={styles.secondaryText}
            variant="outlined"
            multiline
            rowsMax="10"
            aria-label={config.secondaryLabel}
            placeholder={`${config.secondaryLabel}...`}
            value={items[idx].secondaryText}
            onChange={(e) => {
              const newItems = _.map(items, (existingItem, index) =>
                Number(index) === idx
                  ? {
                      ...existingItem,
                      secondaryText: e.target.value,
                    }
                  : existingItem
              );
              setItems(newItems);
            }}
            onBlur={() => {
              analytics.track(`${config.itemName} Description Edited`);
            }}
          />
        </React.Fragment>
      ))}
      <Button
        className={cx(styles.addNew)}
        onClick={(): void => {
          analytics.track(`Add Another ${config.itemName} Clicked`);
          setItems(_.concat(items, {}));
        }}
      >
        <FontAwesomeIcon
          style={{
            marginRight: "10px",
          }}
          icon={faPlus}
          size="sm"
        />
        Add another {config.itemName.toLowerCase()}
      </Button>
    </div>
  );
};

export default EditSimpleTextList;
