const STATIC_MAPS_KEY = "AIzaSyAKMpmgv_hxR-9wY6w2PIH-7RDG5LAO_oQ";

export enum ImageSize {
  Landscape = "1600x300",
  Square = "800x800",
}

export enum ZoomLevel {
  Globe = "1",
  MidRange = "12",
  CloseRange = "16",
}

export const getStaticMapsUrl = (
  coordinates: string[],
  size: ImageSize,
  zoomLevel: ZoomLevel
): string => {
  if (coordinates.length) {
    return (
      "https://maps.googleapis.com/maps/api/staticmap?markers=color:red" +
      `%7C${coordinates}&center=${coordinates}` +
      `&zoom=${zoomLevel}&size=${size}&maptype=roadmap&key=${STATIC_MAPS_KEY}`
    );
  }
  return (
    "https://maps.googleapis.com/maps/api/staticmap?center=0,90" +
    `&zoom=${ZoomLevel.Globe}&size=${ImageSize.Landscape}&maptype=roadmap&key=${STATIC_MAPS_KEY}`
  );
};
