import React, { FunctionComponent, useState, useEffect } from "react";

import { TextField } from "@material-ui/core";
import { EditModulesGlobalProps } from "../EditModuleContainer";
import EDIT_VALUES_MUTATION from "./EDIT_VALUES_MUTATION";
import EditGlobalInfoText from "../Shared/EditGlobalInfoText/EditGlobalInfoText";

type EditValuesProps = {
  moduleId: string;
  header?: string;
};

const EditValues: FunctionComponent<
  EditValuesProps & EditModulesGlobalProps
> = ({
  setHandleSaveMutation,
  setHandleSaveMutationInput,
  moduleId,
  header,
}) => {
  const [headerOverride, setHeaderOverride] = useState<string>(
    header || "Values"
  );

  useEffect(() => {
    setHandleSaveMutation(EDIT_VALUES_MUTATION);
    setHandleSaveMutationInput({
      input: {
        moduleId,
        header: headerOverride,
      },
    });
  }, [
    headerOverride,
    moduleId,
    setHandleSaveMutation,
    setHandleSaveMutationInput,
  ]);

  return (
    <>
      <EditGlobalInfoText text="Head to Settings to edit your Values for all guides" />
      <TextField
        fullWidth
        value={headerOverride}
        onChange={(e) => setHeaderOverride(e.target.value)}
        style={{ marginBottom: 6, marginTop: 6 }}
        label="Header"
      />
    </>
  );
};

export default EditValues;
