import gql from "graphql-tag";

export default gql`
  query LocationsQueryForEditLocationV2(
    $locationInput: OrganizationLocationInput
  ) {
    currentUserV2 {
      id
      organization {
        id
        locations(input: $locationInput) {
          id
          name
          arrivalInstructions
          coordinates
          address
          isArchived
        }
      }
    }
  }
`;
