import gql from "graphql-tag";

export const CurrentUserFragment = gql`
  fragment CurrentUserLogin on GuidesUser {
    id
    analyticsUserId
    name
    firstName
    lastName
    canSendEmail
    onboardingComplete
    organization {
      id
      name
      analyticsGroupId
      customer {
        id
        name
      }
    }
    roles
    primaryEmail
    title
    intercomHash
    createdAt
  }
`;

export default gql`
  query CurrentUser {
    currentUserV2 {
      ...CurrentUserLogin
    }
  }
  ${CurrentUserFragment}
`;
