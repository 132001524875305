import React from "react";
import Typography from "@material-ui/core/Typography";

import { StageById_stage } from "~/schemaTypes";
import { AnalyticsContext, fakeAnalytics } from "~/analytics";
import Loading from "~/components/Loading/Loading";
import ModuleContainer from "~/components/modules/ModuleContainer/ModuleContainer";

import styles from "./GuidePresenter.module.scss";
import ErrorBoundary from "~/components/ErrorBoundary";

export type SampleGuide = {
  id: string;
  currentStage: StageById_stage;
  createdFromATS: boolean;
};

type GuidePresenterProps = {
  guide?: SampleGuide;
  loading: boolean;
  error?: unknown;
  TimelineComp?: React.FunctionComponent;
};

export const GuidePresenter: React.FC<GuidePresenterProps> = ({
  guide,
  loading,
  error,
  TimelineComp,
}) => {
  if (error) {
    return (
      <div>
        <Typography variant="h4">
          There was an error loading your Interview Guide stage
        </Typography>
      </div>
    );
  }

  return (
    <div className={styles.guideContainer}>
      {!loading ? (
        <AnalyticsContext.Provider value={fakeAnalytics}>
          {TimelineComp && (
            <>
              {guide?.currentStage?.modules?.map(
                ({ id, displayType, data: moduleData }) => (
                  <ErrorBoundary key={id} silent>
                    <ModuleContainer
                      key={id}
                      id={id}
                      displayType={displayType}
                      stageId={guide.currentStage?.id}
                      guideId={guide?.id}
                      createdFromATS={!!guide?.createdFromATS}
                      data={{
                        ...moduleData,
                      }}
                    />
                  </ErrorBoundary>
                )
              )}
            </>
          )}
          {TimelineComp && <TimelineComp />}
          <>
            {guide?.currentStage?.modules?.map(
              ({ id, displayType, data: moduleData }) => (
                <ErrorBoundary key={id} silent>
                  {TimelineComp ? (
                    <>
                      <ModuleContainer
                        key={id}
                        id={id}
                        displayType={displayType}
                        stageId={guide.currentStage?.id}
                        guideId={guide?.id}
                        createdFromATS={!!guide?.createdFromATS}
                        data={{
                          ...moduleData,
                        }}
                      />
                    </>
                  ) : (
                    <ModuleContainer
                      key={id}
                      id={id}
                      displayType={displayType}
                      stageId={guide.currentStage?.id}
                      createdFromATS={!!guide?.createdFromATS}
                      guideId={guide?.id}
                      data={{
                        ...moduleData,
                      }}
                    />
                  )}
                </ErrorBoundary>
              )
            )}
          </>
        </AnalyticsContext.Provider>
      ) : (
        <Loading />
      )}
    </div>
  );
};
