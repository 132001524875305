import gql from "graphql-tag";

export default gql`
  subscription CandidateFacingGuideRefresh($where: guide_bool_exp) @hasura {
    guide(where: $where) {
      id
      currentStage {
        id
      }
      stages {
        id
        activatedAt
        activatedOverride
      }
    }
  }
`;
