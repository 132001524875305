import gql from "graphql-tag";

const HasuraGetCustomerGreenhouseWebhooks = gql`
  subscription HasuraGetCustomerGreenhouseWebhooks($customerId: uuid) @hasura {
    customer(where: { id: { _eq: $customerId } }) {
      id
      greenhouse_webhooks {
        id
        eventName
        lastReceivedAt
      }
    }
  }
`;

export default HasuraGetCustomerGreenhouseWebhooks;
