import React, { FunctionComponent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import {
  faLongArrowDown,
  faSortAlt,
  faLongArrowUp,
  IconDefinition,
} from "@fortawesome/pro-light-svg-icons";
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
} from "@material-ui/core";
import { FeatureFlagEnum } from "@resource/common";
import { useFlags } from "@resource/client-ffs";
import { FetchGuides_currentUserV2_organization_guides as Guide } from "~/schemaTypes";
import { RowSortType } from "./Guides";

import styles from "./Guides.module.scss";

type GuidesTableHeaderProps = {
  disableSorting?: boolean;
  rowsSortState: RowSortType;
  setRowSortState: ({
    column,
    dataLocation,
    direction,
  }: {
    column: string;
    dataLocation: string | ((guide: Guide) => Date | undefined);
    direction: "asc" | "desc" | false;
  }) => void;
};

const TABLE_ROWS = [
  {
    title: "Candidate",
    columnType: "name",
    dataLocation: "candidate.firstName",
    width: "15%",
    sortable: true,
  },
  {
    title: "Opens",
    columnType: "openTracking",
    dataLocation: (guide: Guide) => guide.candidateOpens.length,
    width: "7.5%",
    sortable: true,
  },
  {
    title: "Interview Date",
    columnType: "date",
    dataLocation: (guide: Guide) => guide?.currentStage?.date,
    width: "12.5%",
    sortable: true,
  },
  {
    title: "Role",
    columnType: "role",
    dataLocation: "jobRole.name",
    width: "20%",
    sortable: true,
  },
  {
    title: "",
    columnType: "actions",
    dataLocation: "",
    width: "15%",
    sortable: false,
  },
  {
    title: "Status",
    columnType: "lastSent",
    dataLocation: "",
    width: "20%",
    sortable: false,
  },
];

const GuidesTableHeader: FunctionComponent<GuidesTableHeaderProps> = ({
  disableSorting,
  rowsSortState,
  setRowSortState,
}) => {
  const {
    [_.camelCase(
      FeatureFlagEnum.CandidateOpensActivityInTable
    )]: candidateOpensActivityInTableFlag,
  } = useFlags();
  const handleRowSort = (
    updatedColumn: string,
    dataLocation: string | ((guide: Guide) => Date | undefined)
  ): void => {
    const { column, direction } = rowsSortState;
    let updatedDirection;

    if (column === updatedColumn) {
      if (direction === false) {
        updatedDirection = "desc";
      } else if (direction === "desc") {
        updatedDirection = "asc";
      } else {
        updatedDirection = false;
      }
    } else {
      updatedDirection = "desc";
    }

    setRowSortState({
      column: updatedColumn,
      dataLocation,
      direction: updatedDirection as "asc" | "desc" | false,
    });
  };
  const { column, direction } = rowsSortState;

  return (
    <TableHead>
      <TableRow>
        {TABLE_ROWS.filter(({ columnType }) => {
          if (candidateOpensActivityInTableFlag) {
            return true;
          }
          if (columnType !== "openTracking") {
            return true;
          }
          return false;
        }).map((row, index) => {
          const { dataLocation, title, columnType, width, sortable } = row;

          let icon: IconDefinition;
          if (column !== columnType || !direction) {
            icon = faSortAlt;
          } else {
            icon = direction === "asc" ? faLongArrowUp : faLongArrowDown;
          }
          return (
            <TableCell
              key={index}
              style={{
                width:
                  !candidateOpensActivityInTableFlag && columnType !== "date"
                    ? "15%"
                    : width,
              }}
            >
              {sortable && !disableSorting ? (
                <TableSortLabel
                  active={column === columnType && !!direction}
                  direction={direction || "asc"}
                  onClick={(): void => handleRowSort(columnType, dataLocation)}
                  IconComponent={() => (
                    <FontAwesomeIcon className={styles.icon} icon={icon} />
                  )}
                >
                  {title}
                </TableSortLabel>
              ) : (
                title
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default GuidesTableHeader;
