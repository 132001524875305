import gql from "graphql-tag";

const EditStageTemplateInstallationForJourney = gql`
  mutation EditStageTemplateInstallationForJourney(
    $pk_columns: stage_template_installation_pk_columns_input!
    $_set: stage_template_installation_set_input
  ) @hasura {
    update_stage_template_installation_by_pk(
      pk_columns: $pk_columns
      _set: $_set
    ) {
      id
      activatedAt
      journeyMetadata
      displayName
      hidden
      stage_template {
        id
      }
    }
  }
`;

export default EditStageTemplateInstallationForJourney;
