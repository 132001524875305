import { StageById_stage_sortedVisibleEvents_interviewers as Interviewer } from "~/schemaTypes";

const austin: Interviewer = {
  id: "02252db3-41ac-47b8-8620-ebd8456cae11",
  firstName: "Austin",
  lastName: "Cooley",
  title: "CTO",
  linkedInUrl: "https://www.linkedin.com/adcooley",
  websiteUrls: [
    "https://www.linkedin.com/adcooley",
    "https://www.github.com/adcooley",
  ],
  imageUrl:
    "https://lh3.googleusercontent.com/a-/AAuE7mDRE1CErN1_9NVxfZDI_S4B2Q_Qn8q4pOLAI4n9",
  biography: "Startup CTO for 10 years",
  __typename: "InterviewerV2",
};
const kevin: Interviewer = {
  id: "04252204-23d5-4770-9733-34e8c40369a4",
  firstName: "Kevin",
  lastName: "Vissuet",
  title: "Product Engineer/ Data Scientist",
  linkedInUrl: "https://www.linkedin.com/in/vissuet/",
  websiteUrls: ["https://www.linkedin.com/in/vissuet/"],
  imageUrl:
    "https://lh3.googleusercontent.com/a-/AAuE7mA29TAeIwH0lfzjJ-WJiejn-R0c3RkMD1tj2Sb7",
  biography:
    "Data scientist with 2 amazing dogs. Fun fact - I was in a TV commercial once.",
  __typename: "InterviewerV2",
};

const alex: Interviewer = {
  id: "605554c3-f62e-4113-b0fe-6ba71bc43b5e",
  firstName: "Alex",
  lastName: "Yeagle",
  title: "AE",
  linkedInUrl: "https://www.linkedin.com/in/ayeagle/",
  websiteUrls: ["https://www.linkedin.com/in/ayeagle/"],
  imageUrl:
    "https://lh3.googleusercontent.com/a-/AAuE7mCKZTGa7ZHGJtIPomnxWR7iwt9SJwhy3JHXGBfe",
  biography:
    "Love making more human recruiting orgs. \n\nI also play on an 8-ball pool team and have a drone instagram - ask me about it!",
  __typename: "InterviewerV2",
};

const marcus: Interviewer = {
  id: "303105b5-7aec-4dbb-99f0-622ad4643b8f",
  firstName: "Marcus",
  lastName: "Lowe",
  title: "Product",
  linkedInUrl: "https://www.linkedin.com/in/the-marcus-lowe/",
  websiteUrls: ["https://www.linkedin.com/in/the-marcus-lowe/"],
  imageUrl:
    "https://storage.googleapis.com/resource-image-uploads-production/a9d41292-bb3e-46d1-8697-6ff2c43c6d10.jpeg",
  biography: "",
  __typename: "InterviewerV2",
};

export default ({
  firstName,
  lastName,
  linkedInUrl,
  websiteUrls,
  imageUrl,
  title,
  biography,
} = austin): Interviewer => ({
  id: `${firstName}fake`,
  __typename: "InterviewerV2",
  title,
  firstName,
  lastName,
  linkedInUrl,
  websiteUrls,
  imageUrl,
  biography,
});

export { austin, kevin, alex, marcus };
