import { gql } from "@apollo/client";

const HASURA_GET_JOB_ROLE_GREENHOUSE_DATA = gql`
  query GetJobRoleGreenhouseData($where: job_role_bool_exp) @hasura {
    job_role(where: $where) {
      id
      greenhouseData
      greenhouseJobStagesData
    }
  }
`;

export default HASURA_GET_JOB_ROLE_GREENHOUSE_DATA;
