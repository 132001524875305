import gql from "graphql-tag";
import { IntegrationsPageIntegrationFragment } from "~/pages/GuidesHomepage/Settings/Integrations/ALL_INTEGRATIONS_QUERY";

export default gql`
  fragment customerFragmentForSlack on Customer {
    id
    integration(input: $typeInput) {
      ...IntegrationsPageIntegration
      ... on SlackIntegrationV2 {
        auth0SlackUserId
        notificationsChannelId
        autopopulateInterviewer
        receiveNotifications
        channels {
          id
          name
        }
      }
    }
  }
  ${IntegrationsPageIntegrationFragment}
`;
