import _ from "lodash";
import React, { useState } from "react";
import { useMutation } from "@apollo/client";

import {
  MenuItem,
  FormControlLabel,
  Checkbox,
  Grid,
  Link,
  TextField,
  Typography,
  makeStyles,
  createStyles,
  CircularProgress,
} from "@material-ui/core";
import gql from "graphql-tag";
import greenhouseLogo from "~/icons/greenhouse-logo.svg";

import {
  UpdateStageTemplateInstallation,
  UpdateStageTemplateInstallationVariables,
  UpdateStageTemplateInstallationInput,
  GuideTemplateForEditing_currentUserV2_organization_stageTemplates as StageTemplate,
  GuideTemplateForEditing_guideTemplate_stageTemplateInstallations as StageTemplateInstallation,
} from "~/schemaTypes";
import { ResourceCustomTheme } from "~/styles/config";

const UPDATE_STAGE_TEMPLATE_INSTALLATION = gql`
  mutation UpdateStageTemplateInstallation(
    $input: UpdateStageTemplateInstallationInput!
  ) {
    updateStageTemplateInstallation(input: $input) {
      success
      stageTemplateInstallation {
        id
        stageTemplate {
          id
          name
        }
      }
    }
  }
`;

type GuideStageTemplateProps = {
  stageTemplateInstallation: StageTemplateInstallation;
  onPreviewStageTemplateInstallation: (
    stageTemplateInstallation: StageTemplateInstallation
  ) => void;
  stageTemplates: StageTemplate[];
  viewingStageTemplateInstallation?: StageTemplateInstallation;
};

const useStyles = makeStyles((theme: ResourceCustomTheme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(4),
    },
    greenhouseJobName: {
      marginLeft: "8px",
    },
    field: {
      marginTop: theme.spacing(2),
      width: "100%",
    },
    previewLink: {
      marginTop: 6,
    },
  })
);

const GuideStageTemplate: React.FC<GuideStageTemplateProps> = ({
  stageTemplateInstallation,
  onPreviewStageTemplateInstallation,
  stageTemplates,
  viewingStageTemplateInstallation,
}) => {
  const styles = useStyles();
  const [updateStageTemplateInstallation, { loading }] = useMutation<
    UpdateStageTemplateInstallation,
    UpdateStageTemplateInstallationVariables
  >(UPDATE_STAGE_TEMPLATE_INSTALLATION, {
    refetchQueries: ["GetOrgGuideTemplateData", "GuideTemplateForEditing"],
  });

  const [displayName, setDisplayName] = useState(
    stageTemplateInstallation.displayName
  );
  const { hidden } = stageTemplateInstallation;
  const stageTemplateId = stageTemplateInstallation.stageTemplate.id;

  const handlePreviewStageTemplate = () => {
    onPreviewStageTemplateInstallation(stageTemplateInstallation);
  };

  const handleSave = async (
    input: Omit<UpdateStageTemplateInstallationInput, "id">
  ) => {
    await updateStageTemplateInstallation({
      variables: {
        input: {
          id: stageTemplateInstallation.id,
          ...input,
        },
      },
    });
  };

  return (
    <Grid container className={styles.root}>
      <Grid item xs={12} md={6}>
        <Typography variant="body1">
          {stageTemplateInstallation.displayName}
        </Typography>
        <div>
          <img src={greenhouseLogo} alt="" width="16px" height="16px" />
          <Typography
            className={styles.greenhouseJobName}
            component="span"
            variant="subtitle2"
          >
            {stageTemplateInstallation.atsStageName} (
            {stageTemplateInstallation.atsStageId})
          </Typography>
        </div>
        {!stageTemplateInstallation.hidden && (
          <Typography className={styles.previewLink} variant="body1">
            {stageTemplateInstallation.id ===
            viewingStageTemplateInstallation?.id ? (
              <>Currently previewing</>
            ) : (
              <Link component="button" onClick={handlePreviewStageTemplate}>
                View in Preview
              </Link>
            )}
          </Typography>
        )}
        {loading && (
          <div>
            <CircularProgress />
          </div>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          className={styles.field}
          label="Override Stage Display Name"
          value={displayName}
          onBlur={() =>
            handleSave({
              displayName,
            })
          }
          onChange={(e) => {
            setDisplayName(e.target.value);
          }}
        />
        <TextField
          className={styles.field}
          defaultValue={stageTemplateId}
          select
          fullWidth
          label="Base Stage Template"
          onChange={(e) => {
            handleSave({
              stageTemplateId: e.target.value,
            });
          }}
          variant="outlined"
        >
          {_.map(stageTemplates, ({ id, name }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </TextField>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              defaultChecked={hidden}
              onChange={(e) =>
                handleSave({
                  hidden: e.target.checked,
                })
              }
            />
          }
          label="Hide this Stage from candidates"
        />
      </Grid>
    </Grid>
  );
};

export default GuideStageTemplate;
