import gql from "graphql-tag";

export default gql`
  mutation UpdateCultureForStageTemplateModuleInstallation(
    $input: UpdateCultureInstallationForStageTemplateInput!
  ) {
    updateCultureInstallationForStageTemplate(input: $input) {
      id
      name
      updatedAt
    }
  }
`;
