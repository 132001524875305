import React, { FunctionComponent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faGlobeAmericas } from "@fortawesome/pro-solid-svg-icons";
import { Typography } from "@material-ui/core";
import styles from "./EditGlobalInfoText.module.scss";

type EditGlobalInfoTextProps = {
  text?: string;
};

const EditGlobalInfoText: FunctionComponent<EditGlobalInfoTextProps> = ({
  text,
}) => (
  <div className={styles.globalEditContainer}>
    <Typography className={styles.text} variant="body1" color="primary">
      <FontAwesomeIcon className={styles.globeIcon} icon={faGlobeAmericas} />
      {text || "This carries through all guides, org-wide"}
    </Typography>
  </div>
);

export default EditGlobalInfoText;
