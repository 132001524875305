import React, { FunctionComponent, useState } from "react";
import * as Sentry from "@sentry/browser";
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  makeStyles,
} from "@material-ui/core";
import { ApolloQueryResult, gql, useMutation } from "@apollo/client";

import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModuleDisplayInfo from "~/components/utils/module-display-info";
import EditModuleContainer from "~/components/templateModulesEdit/EditModuleContainer";
import {
  StageTemplateForEditing,
  StageTemplateForEditingVariables,
} from "~/schemaTypes";

import useAnalytics from "~/react-hooks/useAnalytics";
import { useFlashMessage } from "~/components/FlashMessage/FlashMessage";
import DialogTitle from "~/components/Dialog/DialogTitle/DialogTitle";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DataType = Record<string, any>;

type EditableModule = {
  id: string;
  type: string;
  data: DataType;
};

type EditModuleModalProps = {
  stageTemplateId: string;
  selectedModule: EditableModule | null;
  editModuleOpen: boolean;
  setEditModuleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refetchStageTemplate: (
    variables?: StageTemplateForEditingVariables | undefined
  ) => Promise<ApolloQueryResult<StageTemplateForEditing>>;
};

const useStyles = makeStyles(() => ({
  dialogContent: {
    overflowY: "visible",
  },
}));

const EditModuleModal: FunctionComponent<EditModuleModalProps> = ({
  stageTemplateId,
  editModuleOpen,
  setEditModuleOpen,
  selectedModule,
  refetchStageTemplate,
}) => {
  const styles = useStyles();
  // This is needed otherwise useMutation will fail
  const fakeMutation = gql`
    mutation FakeMutationForEditModules(
      $input: UpdateSimpleTextListInstallationForStageTemplateInput!
    ) {
      updateSimpleTextListInstallationForStageTemplate(input: $input) {
        id
        name
      }
    }
  `;
  const [analytics] = useAnalytics();
  const [shouldSave, setShouldSave] = useState<(() => boolean) | undefined>();
  const [handleSaveMutation, setHandleSaveMutation] = useState(fakeMutation);
  const [handleSaveMutationInput, setHandleSaveMutationInput] = useState<{
    input: { [key: string]: unknown };
  }>();
  const [saving, setSaving] = useState(false);
  const [saveModuleMutation] = useMutation(handleSaveMutation, {
    variables: handleSaveMutationInput,
  });
  const { setContent } = useFlashMessage();

  const handleSave = async (): Promise<void> => {
    if (shouldSave && !shouldSave()) {
      return;
    }

    setSaving(true);
    try {
      const variables = handleSaveMutationInput;
      // We didn't properly set up the input mutation in the module component
      if (!variables) {
        throw new Error("Failed to set input mutation");
      }
      variables.input.stageTemplateId = stageTemplateId;
      await saveModuleMutation({
        variables,
      });
      await refetchStageTemplate();
      setEditModuleOpen(false);

      analytics.track("Edit Module Modal Save Clicked", {
        selectedModule: selectedModule?.type,
      });
    } catch (error) {
      Sentry.captureException(error);
      setContent({
        content:
          "There was an unexpected error saving your data. Please try again.",
        severity: "error",
      });
    } finally {
      setSaving(false);
    }
  };

  const handleClose = () => {
    setEditModuleOpen(false);

    analytics.track("Edit Module Modal Close Clicked", {
      selectedModule: selectedModule?.type,
    });
  };

  return (
    <Dialog
      open={editModuleOpen}
      fullWidth
      maxWidth="sm"
      aria-labelledby={`Edit ${selectedModule?.data?.header}`}
      onClose={handleClose}
    >
      <DialogTitle onClose={handleClose}>
        Edit {ModuleDisplayInfo[selectedModule?.type ?? ""]?.header}
      </DialogTitle>
      <Divider />
      <DialogContent className={styles.dialogContent}>
        <EditModuleContainer
          id={selectedModule?.id || ""}
          type={selectedModule?.type || ""}
          data={selectedModule?.data ?? {}}
          setShouldSave={setShouldSave}
          setHandleSaveMutation={setHandleSaveMutation}
          setHandleSaveMutationInput={setHandleSaveMutationInput}
          onClose={handleClose}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={(): void => {
            handleSave();
          }}
          color="primary"
          variant="contained"
        >
          {saving ? (
            <FontAwesomeIcon
              style={{
                marginRight: "5px",
              }}
              icon={faSpinnerThird}
              spin
            />
          ) : (
            ""
          )}
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default EditModuleModal;
