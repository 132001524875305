import _ from "lodash";
import React, { FunctionComponent, useMemo } from "react";
import cx from "classnames";
import { Typography, makeStyles } from "@material-ui/core";
import moment from "moment";

import { parseConferenceUrl, ConferenceServiceEnum } from "@resource/common";
import Event from "./Event/Event";

import styles from "./Schedule.module.scss";
import { StageById_stage_sortedVisibleEvents as StageEvent } from "~/schemaTypes";
import getEventDateGroups, {
  InterviewBreak,
} from "~/utils/get-event-date-groups";
import DescriptionDivider from "../Feedback/DescriptionDivider";
import { ResourceCustomTheme } from "~/styles/config";
import ProminentInstructionsCard from "../shared/ProminentInstructionsCard";

const useStyles = makeStyles((_theme: ResourceCustomTheme) => ({
  disabled: {
    opacity: 0.4,
  },
}));

type ScheduleProps = {
  stageId: string;
  showInterviewers?: boolean;
  events?: StageEvent[];
  header?: string;
};

const isConferenceUrl = (url: string) =>
  !!parseConferenceUrl(url, ConferenceServiceEnum.Zoom) ||
  !!parseConferenceUrl(url, ConferenceServiceEnum.Meet);

const Schedule: FunctionComponent<ScheduleProps> = ({
  showInterviewers = true,
  events,
  header,
}) => {
  const classes = useStyles();
  const dateGroups = getEventDateGroups<StageEvent>(events);

  const unifiedConferenceLink = useMemo(() => {
    const futureVisibleEvents = _.chain(dateGroups)
      .map("events")
      .flatten()
      .reject("hidden")
      .reject((visibleEvent) => moment(visibleEvent.endTime).isBefore(moment()))
      .value();

    const futureVisibleEventsWithConferenceLinks = _.filter(
      futureVisibleEvents,
      (event) => {
        if ("prominentInstructions" in event) {
          return !!_.find(event.prominentInstructions, (i) =>
            isConferenceUrl(i.url || "")
          );
        }
        return false;
      }
    );

    const groupedInstructions = _.chain(futureVisibleEventsWithConferenceLinks)
      .map("prominentInstructions")
      .flatten()
      .groupBy("url")
      .value();

    const conferenceLinks = _.chain(groupedInstructions)
      .keys()
      .filter(isConferenceUrl)
      .uniq()
      .value();

    // A single unique conference link is in use
    return conferenceLinks.length === 1 &&
      // More than one event has a conference link
      futureVisibleEventsWithConferenceLinks.length > 1
      ? conferenceLinks[0]
      : undefined;
  }, [dateGroups]);

  // If there are no shown events dateGroups will be of length 0
  if (dateGroups.length === 0) {
    return null;
  }
  return (
    <div className={styles.scheduleContainer}>
      <Typography variant="h4">{header ?? "Schedule"}</Typography>
      {unifiedConferenceLink && (
        <ProminentInstructionsCard
          label={unifiedConferenceLink}
          url={unifiedConferenceLink}
        />
      )}
      {dateGroups.map((dateGroup) => {
        const {
          day,
          startTime: dateGroupStartTime,
          events: dateGroupEvents,
        } = dateGroup;
        const dateGroupEventsWithoutHiddenEvents = _.filter(
          dateGroupEvents,
          (event) => !event?.hidden
        );
        const allEventsInPast = !_.reject(
          dateGroupEventsWithoutHiddenEvents,
          (nonHiddenEvent) => {
            return moment(nonHiddenEvent.endTime).isBefore(moment());
          }
        ).length;

        return (
          <div key={`${day}`}>
            <Typography
              variant="h6"
              className={cx({
                [classes.disabled]: allEventsInPast,
              })}
            >
              {moment(dateGroupStartTime).format("ddd MMM Do")}
            </Typography>
            {_.map(
              dateGroupEventsWithoutHiddenEvents,
              (eventOrBreak, index) => {
                if ("lengthMinutes" in eventOrBreak) {
                  const {
                    startTime,
                    endTime,
                    lengthMinutes,
                  } = eventOrBreak as InterviewBreak;
                  const startTimeText = moment(startTime).format("h:mm a");
                  const breakIsInPast = moment(endTime).isBefore(moment());
                  const breakLengthText = moment
                    .duration(lengthMinutes, "minutes")
                    .format("h [hr] m [min]", {
                      usePlural: false,
                      trim: "all",
                    });
                  return breakLengthText &&
                    startTimeText &&
                    breakLengthText.length > 0 &&
                    startTimeText.length > 0 &&
                    dateGroupEvents.length - 1 !== index ? (
                    <div
                      key={index}
                      className={cx(styles.eventBreak, {
                        [classes.disabled]: breakIsInPast,
                      })}
                    >
                      <DescriptionDivider
                        text={
                          <span>
                            <strong>{startTimeText}</strong>
                            &nbsp;&nbsp;
                            {breakLengthText} break
                          </span>
                        }
                      />
                    </div>
                  ) : null;
                }
                const event = {
                  ...eventOrBreak,
                  remoteInstructions: eventOrBreak.remoteInstructions ?? "",
                  techScreenLink: eventOrBreak.techScreenLink ?? "",
                  zoomLink: eventOrBreak.zoomLink ?? "",
                  interviewers: eventOrBreak.interviewers ?? [],
                  description: eventOrBreak.description ?? "",
                };
                return (
                  <React.Fragment key={eventOrBreak.id}>
                    {!eventOrBreak.hidden && (
                      <Event
                        event={event}
                        unifiedConferenceLink={unifiedConferenceLink}
                        showInterviewers={showInterviewers}
                      />
                    )}
                  </React.Fragment>
                );
              }
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Schedule;
