import _ from "lodash";
import React, { useContext } from "react";
import Dialog from "@material-ui/core/Dialog";
import {
  Divider,
  makeStyles,
  Grid,
  Typography,
  IconButton,
} from "@material-ui/core";
import {
  faLinkedin,
  faGithub,
  faGitlab,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faLink } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Interviewer as InterviewerType } from "~/types";
import { AnalyticsContext } from "~/analytics";

const useStyles = makeStyles((theme) => ({
  img: {
    [theme.breakpoints.down("sm")]: {
      maxHeight: "350px",
      maxWidth: "350px",
    },
    maxHeight: "250px",
    maxWidth: "250px",
    display: "block",
    overflow: "hidden",
    width: "100%",
    margin: "auto",
  },
  title: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
    cursor: "pointer",
    "& p": {
      marginRight: "0px",
      transition: "100ms ease margin",
    },
    "&:hover": {
      "& p": {
        marginLeft: "4px",
        marginRight: "4px",
        transition: "100ms ease margin",
      },
    },
  },
  text: {
    fontWeight: "bold",
  },
}));

type ShareGuideModalProps = {
  open: boolean;
  onClose: (...args: InterviewerType[]) => void;
  interviewer: InterviewerType;
};

const EditEventModal: React.FC<ShareGuideModalProps> = ({
  open,
  onClose,
  interviewer,
}) => {
  const classes = useStyles();
  const analytics = useContext(AnalyticsContext);

  const {
    firstName,
    lastName,
    imageUrl,
    overridenImageUrl,
    title,
    titleOverride,
    biography: bio,
    websiteUrls,
  } = interviewer;

  const displayTitle = titleOverride || title;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth scroll="body">
      <div style={{ padding: "24px" }}>
        <Grid
          container
          alignContent="center"
          justify="space-between"
          spacing={1}
        >
          <Grid xs={12} sm={5} item>
            <img
              className={classes.img}
              src={overridenImageUrl || imageUrl}
              alt={firstName}
            />
          </Grid>

          <Grid xs={12} sm={7} item container direction="column" spacing={1}>
            <Grid item>
              <Typography
                variant="h3"
                style={{ marginTop: "0px", marginBottom: "0px" }}
              >
                {`${firstName} ${lastName}`}
              </Typography>
            </Grid>

            <Grid item>
              <Typography style={{ color: "#79728C" }}>
                {displayTitle}
              </Typography>
            </Grid>

            <Grid item>
              {_.map(websiteUrls, (url: string) => {
                const isLinkedIn = _.includes(url, "linkedin.com");
                let icon = faLink;
                if (isLinkedIn) {
                  icon = faLinkedin;
                } else if (_.includes(url, "github.com")) {
                  icon = faGithub;
                } else if (_.includes(url, "gitlab.com")) {
                  icon = faGitlab;
                } else if (_.includes(url, "twitter.com")) {
                  icon = faTwitter;
                } else if (
                  _.includes(url, "youtube.com") ||
                  _.includes(url, "youtu.be")
                ) {
                  icon = faYoutube;
                }

                return (
                  <IconButton
                    key={url}
                    href={url}
                    target="_blank"
                    onClick={(): void => {
                      analytics.track(
                        `${isLinkedIn ? "LinkedIn" : "Website url"} Clicked`
                      );
                    }}
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={icon} />
                  </IconButton>
                );
              })}
            </Grid>

            {bio && (
              <>
                <Grid item>
                  <Divider style={{ marginTop: "4px", marginBottom: "4px" }} />
                </Grid>

                <Grid item>
                  <Typography>Bio. {bio}</Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
};

export default EditEventModal;
