export type GreenhouseIdAndName = {
  id: string;
  name: string | null;
};

export type GreenhouseJobStage = {
  id: string;
  name: string | null;
  jobId: string;
  interviews: GreenhouseIdAndName[];
};

export type JobRoleWithStages = {
  id: string;
  greenhouseJobStagesData: GreenhouseJobStage[] | null;
};

// eslint-disable-next-line no-shadow
export enum ActionEnum {
  SetInterviews = "SetInterviews",
  SetEdits = "SetEdits",
  Back = "Back",
  Next = "Next",
  Cancel = "Cancel",
  Finish = "Finish",
}

export type InterviewEditsStateType = {
  interviewIds: string[];
  numStages: number;
  interviewName: string;
  titleOverride: string;
  descriptionOverride: string;
  hiddenOverride: boolean;
  activeStepIndex: number;
};

export type InterviewEditsDispatchType = {
  type: ActionEnum;
  interviewSelection?: {
    ids: string[];
    name: string;
    numStages: number;
  };
  titleOverride?: string;
  descriptionOverride?: string;
  hiddenOverride?: boolean;
};
