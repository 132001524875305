import React, { FunctionComponent, useState } from "react";
import { Typography } from "@material-ui/core";
import { retry } from "@resource/common";
import useDebouncedMutation from "~/utils/useDebouncedMutation";
import { UPDATE_ORGANIZATION_MISSION_MUTATION } from "~/mutations";
import useAnalytics from "~/react-hooks/useAnalytics";
import styles from "./Mission.module.scss";

const RichTextEditor = React.lazy(() =>
  retry(() => import("~/components/RichTextEditor/RichTextEditor"))
);

type MissionProps = {
  missionStatement: string;
};

const Mission: FunctionComponent<MissionProps> = ({ missionStatement }) => {
  const [analytics] = useAnalytics();
  const [currentMissionStatement, setCurrentMissionStatement] = useState<
    string
  >(missionStatement);
  const [updateMissionDebounced] = useDebouncedMutation(
    UPDATE_ORGANIZATION_MISSION_MUTATION,
    {},
    1000
  );
  const [missionStatementChanged, setMissionStatementChanged] = useState(false);

  const handleMissionChange = (html: string): void => {
    if (missionStatementChanged) {
      analytics.track("Mission Statement Changed");
    }
    setMissionStatementChanged(true);
    setCurrentMissionStatement(html);
    updateMissionDebounced({
      variables: {
        input: {
          missionStatement: html,
        },
      },
    });
  };

  return (
    <div className={styles.missionContainer}>
      <Typography className={styles.text} variant="h3">
        <span role="img" aria-label="Sparkles">
          ✨
        </span>{" "}
        Mission
      </Typography>
      <div className={styles.missionCont}>
        <RichTextEditor
          value={currentMissionStatement}
          onChange={handleMissionChange}
          placeholder="Why do you do what you do? Connect with candidates who have the same mission-driven motivation."
        />
      </div>
    </div>
  );
};

export default Mission;
