import React from "react";
import DetailedFeedback, {
  SubmitDetailedFeedbackCallback,
} from "./DetailedFeedback/DetailedFeedback";

type WhatCouldBeBetterProps = {
  onSubmitDetailedFeedback: SubmitDetailedFeedbackCallback;
};

const WhatCouldBeBetterStep: React.FC<WhatCouldBeBetterProps> = ({
  onSubmitDetailedFeedback,
}) => (
  <DetailedFeedback
    onSubmitDetailedFeedback={onSubmitDetailedFeedback}
    title="What could be improved?"
  />
);

export default WhatCouldBeBetterStep;
