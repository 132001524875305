import React from "react";
import {
  DialogContent,
  Button,
  DialogActions,
  makeStyles,
  Typography,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import DOMPurify from "dompurify";
import { strings } from "@resource/common";
import { useMutation, useReactiveVar } from "@apollo/client";
import {
  BulkEditEventTemplatesForInterviewKit,
  BulkEditEventTemplatesForInterviewKitVariables,
} from "~/schemaTypesHasura";
import { useFlashMessage } from "~/components/FlashMessage/FlashMessage";
import HASURA_BULK_EDIT_EVENT_TEMPLATES_FOR_INTERVIEW_KIT from "./HASURA_BULK_EDIT_EVENT_TEMPLATES_FOR_INTERVIEW_KIT@hasura";
import { currentOrganizationId } from "~/cache";
import {
  InterviewEditsDispatchType,
  InterviewEditsStateType,
  ActionEnum,
} from "./types";
import ConfirmationButton from "~/components/ConfirmationButton/ConfirmationButton";
import { ResourceCustomTheme } from "~/styles/config";

const useStyles = makeStyles((theme: ResourceCustomTheme) => ({
  root: {},
  content: {
    textAlign: "center",
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  chip: {
    backgroundColor: theme.colors.RoyalPurple(100),
    color: theme.colors.RoyalPurple(),
    marginBottom: theme.spacing(2),
  },
  row: {
    borderBottom: 0,
  },
  templateName: {
    display: "flex",
    alignItems: "center",
  },
  labelCell: {
    fontWeight: "bold",
    textAlign: "right",
  },
  labelContent: {},
  emphasize: {
    color: theme.colors.RoyalPurple(),
  },
  subtext: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    color: theme.colors.MidnightBlue(300),
  },
  light: {
    color: theme.colors.MidnightBlue(200),
  },
  title: {
    whiteSpace: "break-spaces",
    wordBreak: "break-all",
    "& span[data-id]": theme.mixins.placeholderStyles(theme),
  },
  description: {
    whiteSpace: "break-spaces",
    wordBreak: "break-all",
    "& span[data-id]": theme.mixins.placeholderStyles(theme),
  },
}));

type ConfirmInterviewEditsProps = {
  state: InterviewEditsStateType;
  dispatch: React.Dispatch<InterviewEditsDispatchType>;
};

const ConfirmInterviewEdits: React.FC<ConfirmInterviewEditsProps> = ({
  state,
  dispatch,
}) => {
  const classes = useStyles();

  const currentOrgId = useReactiveVar(currentOrganizationId);

  const { setContent } = useFlashMessage();

  const [
    bulkEditEventTemplatesForInterviewKit,
    { loading: saving },
  ] = useMutation<
    BulkEditEventTemplatesForInterviewKit,
    BulkEditEventTemplatesForInterviewKitVariables
  >(HASURA_BULK_EDIT_EVENT_TEMPLATES_FOR_INTERVIEW_KIT);

  const onSubmit = async () => {
    if (!state.interviewIds) {
      console.log(
        "Submitting without a selected interview grouping should'nt be possible"
      );
      return;
    }

    const res = await bulkEditEventTemplatesForInterviewKit({
      variables: {
        where: {
          ...(currentOrgId
            ? {
                organizationId: {
                  _eq: currentOrgId,
                },
              }
            : {}),
          atsEventTemplateId: {
            _in: state.interviewIds,
          },
          atsEventTemplateName: {
            _eq: state.interviewName,
          },
        },
        interviewHidden: state.hiddenOverride,
        interviewTitle: state.titleOverride,
        interviewDescription: state.descriptionOverride,
      },
    });

    const numUpdated =
      res.data?.update_event_template_v2?.returning.length || 0;

    if (numUpdated > 0) {
      setContent({
        content: `🎉 Interview descriptions successfully updated for ${strings.pluralize(
          "interview",
          numUpdated
        )}`,
        autoHideDuration: 5000,
        severity: "success",
      });
    } else {
      setContent({
        content:
          "It looks like we couldn't update any Interview descriptions. Check your filters and try again.",
        autoHideDuration: 5000,
        severity: "error",
      });
    }

    dispatch({
      type: ActionEnum.Finish,
    });
  };

  const numSelectedInterviews = state.interviewIds.length;

  const sanitizedTitle = DOMPurify.sanitize(state.titleOverride, {
    ALLOWED_TAGS: ["span"],
  });
  const sanitizedDescription = DOMPurify.sanitize(state.descriptionOverride);

  return (
    <>
      <DialogContent dividers className={classes.content}>
        <Typography variant="h4">
          <span role="img" aria-label="Ruler">
            📏
          </span>{" "}
          Measure Twice, Cut Once{" "}
          <span role="img" aria-label="Scissors">
            ✂️
          </span>
        </Typography>
        <Typography variant="body1" className={classes.subtext}>
          You are about to apply changes to{" "}
          <span className={classes.emphasize}>
            {strings.pluralize("interview", numSelectedInterviews)}
          </span>{" "}
          across{" "}
          <span className={classes.emphasize}>
            {strings.pluralize("stages", state.numStages)}
          </span>
        </Typography>

        <Table>
          <TableBody>
            <TableRow className={classes.row}>
              <TableCell className={classes.labelCell}>Visibility:</TableCell>
              <TableCell className={classes.labelContent}>
                <Typography variant="body1">
                  {state.hiddenOverride ? "Hidden" : "Visible"}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow className={classes.row}>
              <TableCell className={classes.labelCell}>Title:</TableCell>
              <TableCell className={classes.labelContent}>
                <Typography variant="body1">
                  <span
                    className={classes.title}
                    dangerouslySetInnerHTML={{
                      __html: sanitizedTitle,
                    }}
                  />
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow className={classes.row}>
              <TableCell className={classes.labelCell}>Description:</TableCell>
              <TableCell className={classes.labelContent}>
                <Typography variant="body1">
                  <span
                    className={classes.description}
                    dangerouslySetInnerHTML={{
                      __html: sanitizedDescription,
                    }}
                  />
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={12} className={classes.actions}>
            {saving && <CircularProgress size="30px" />}
            <Button
              color="primary"
              variant="outlined"
              onClick={() =>
                dispatch({
                  type: ActionEnum.Back,
                })
              }
              style={{ marginLeft: 10 }}
            >
              Back
            </Button>
            <ConfirmationButton
              disableTimer
              color="primary"
              disabled={saving}
              variant="contained"
              onClick={() => {
                onSubmit();
              }}
              style={{ marginLeft: 10, minWidth: 240 }}
            >
              Apply Overrides to{" "}
              {strings.pluralize("interview", numSelectedInterviews)}
            </ConfirmationButton>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};

export default ConfirmInterviewEdits;
