import React, { FunctionComponent } from "react";
import { Stepper, Step, StepButton } from "@material-ui/core";
import steps from "./Step";

type TimelineProps = {
  activeStepNum: number;
  setActiveStep(step: number): void;
};

const Timeline: FunctionComponent<TimelineProps> = ({
  activeStepNum,
  setActiveStep,
}) => {
  const handleStep = (stepIndex: number) => (): void => {
    setActiveStep(stepIndex);
  };

  return (
    <div>
      <Stepper nonLinear activeStep={activeStepNum}>
        {steps.map(({ name, id, locked, hasCompletedStep }, index) => (
          <Step key={id} completed={hasCompletedStep}>
            {!locked ? (
              <StepButton onClick={handleStep(index)}>{name}</StepButton>
            ) : (
              <StepButton disabled>{name}</StepButton>
            )}
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default Timeline;
