import { useEffect } from "react";

const useNoIntercom = (): void => {
  useEffect(() => {
    const { Intercom } = window;
    if (Intercom) {
      Intercom("update", {
        hide_default_launcher: true,
      });
    }
    return (): void => {
      if (Intercom) {
        Intercom("update", {
          hide_default_launcher: false,
        });
      }
    };
  });
};

export default useNoIntercom;
