import { gql } from "@apollo/client";

const HASURA_GET_STAGE_TEMPLATE_CONTENT = gql`
  query GetStageTemplateContent($id: uuid) @hasura {
    stage_template(where: { id: { _eq: $id } }) {
      id
      name
      stage_template_module_installations {
        id
        stage_module {
          id
          type
        }
      }
    }
  }
`;

export default HASURA_GET_STAGE_TEMPLATE_CONTENT;
