import React, { useContext, useState, FunctionComponent } from "react";
import cx from "classnames";
import { Typography, Button, TextField } from "@material-ui/core";
import { AnalyticsContext } from "~/analytics";
import CategoryMultiSelection from "./CategoryMultiSelection";
import categories, { Category } from "./categories";
import feedbackStyles from "../../feedback.module.scss";
import styles from "./detailed-feedback.module.scss";

export type SubmitDetailedFeedbackCallback = (
  freeformFeedback: string,
  categories: Category["id"][]
) => void;

type DetailedFeedbackProps = {
  title: string;
  onSubmitDetailedFeedback: SubmitDetailedFeedbackCallback;
};

const DetailedFeedback: FunctionComponent<DetailedFeedbackProps> = ({
  title,
  onSubmitDetailedFeedback,
}) => {
  const [categoriesSelected, selectCategories] = useState<
    Array<Category["id"]>
  >([]);
  const [shouldShowFreeformFeedback, setShouldShowFreeformFeedback] = useState<
    boolean
  >(false);
  const [freeformFeedback, setFreeformFeedback] = useState<string>("");
  const addCategory = (category: string): void => {
    selectCategories([...categoriesSelected, category]);
    setShouldShowFreeformFeedback(true);
  };
  const analytics = useContext(AnalyticsContext);
  const removeCategory = (category: string): void =>
    selectCategories(categoriesSelected.filter((c) => c !== category));
  return (
    <>
      <Typography className={feedbackStyles.header} variant="h4">
        {title}
      </Typography>
      <CategoryMultiSelection
        categories={categories}
        addCategory={addCategory}
        removeCategory={removeCategory}
        categoriesSelected={categoriesSelected}
      />
      <div className={cx(styles.freeforContainer)}>
        {shouldShowFreeformFeedback && (
          <TextField
            variant="outlined"
            rowsMax={3}
            placeholder="Anything else would you like to share?"
            color="primary"
            multiline
            value={freeformFeedback}
            fullWidth
            onChange={(event): void => {
              setFreeformFeedback(event?.target?.value);
            }}
          />
        )}
      </div>
      <div className={cx(styles.buttonContainer)}>
        <Button
          color="primary"
          variant="contained"
          onClick={(): void => {
            onSubmitDetailedFeedback(freeformFeedback, categoriesSelected);
            analytics.track("Detailed Feedback Done Clicked", {
              numCategoriesSelected: categoriesSelected.length,
              freeformFeedbackWritten: freeformFeedback.length > 0,
            });
          }}
        >
          Done
        </Button>
      </div>
    </>
  );
};

export default DetailedFeedback;
