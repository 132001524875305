import React, { FunctionComponent, useContext } from "react";
import cx from "classnames";
import { Chip } from "@material-ui/core";
import { AnalyticsContext } from "~/analytics";
import { Category } from "./categories";

import styles from "./CategoryChip.module.scss";

type CategoryChipProps = {
  category: Category;
  isSelected: boolean;
  setSelected: (selected: boolean) => void;
};

const CategoryChip: FunctionComponent<CategoryChipProps> = ({
  category: { description, emoji, id },
  isSelected,
  setSelected,
}) => {
  const analytics = useContext(AnalyticsContext);
  return (
    <Chip
      className={cx(styles.chip, { [styles.selected]: !!isSelected })}
      variant="outlined"
      onClick={(): void => {
        setSelected(!isSelected);
        analytics.track("Detailed Feedback Category Clicked", {
          category: id,
        });
      }}
      label={`${emoji} ${description}`}
    />
  );
};
export default CategoryChip;
