/* eslint-disable import/prefer-default-export */
import { useQuery } from "@apollo/client";
import {
  CurrentAvailableOrganizations_currentUserV2_organization as Organization,
  CurrentAvailableOrganizations_currentUserV2_availableOrganizations as AvailableOrganization,
  CurrentAvailableOrganizations,
} from "~/schemaTypes";
import GET_AVAILABLE_ORGANIZATIONS_QUERY from "../../queries/GET_AVAILABLE_ORGANIZATIONS_QUERY";

export const useAvailableOrganizations = (): {
  currentOrganization?: Organization;
  availableOrganizations?: AvailableOrganization[];
} => {
  const { data, loading } = useQuery<CurrentAvailableOrganizations>(
    GET_AVAILABLE_ORGANIZATIONS_QUERY
  );

  const availableOrganizations =
    (!loading && data?.currentUserV2?.availableOrganizations) || [];
  const currentOrganization = data?.currentUserV2?.organization;

  return {
    currentOrganization,
    availableOrganizations,
  };
};
