import React, {
  useContext,
  useState,
  FunctionComponent,
  useEffect,
} from "react";
import { Typography } from "@material-ui/core";
import EmojiSelector from "./EmojiSelector";
import { AnalyticsContext } from "~/analytics";
import options, { Option } from "./options";

import feedbackStyles from "../../feedback.module.scss";

type EmojiStepProps = {
  setDescriptionText: React.Dispatch<React.SetStateAction<string | null>>;
  onOptionSelected: (option: Option) => void;
};

const EmojiStep: FunctionComponent<EmojiStepProps> = ({
  setDescriptionText,
  onOptionSelected,
}) => {
  const [optionHovered, setOptionHovered] = useState<Option | null>(null);
  const analytics = useContext(AnalyticsContext);
  useEffect(() => {
    setDescriptionText(optionHovered?.description || null);
  }, [optionHovered?.description, setDescriptionText]);
  return (
    <>
      <Typography className={feedbackStyles.header} variant="h4">
        How was your interview?
      </Typography>
      <EmojiSelector
        options={options}
        onHoverOption={(option: Option | null): void => {
          setOptionHovered(option);
        }}
        onSelectOption={(option: Option): void => {
          onOptionSelected(option);
          analytics.track("Feedback Emoji Rating Selected", {
            value: option.value,
          });
        }}
      />
    </>
  );
};

export default EmojiStep;
