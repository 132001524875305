import React, { FunctionComponent } from "react";
import { Typography } from "@material-ui/core";
import { sanitizeHtml } from "~/utils";

import styles from "./RichText.module.scss";

type RichTextProps = {
  rawHtml?: string;
  header?: string;
};

const RichText: FunctionComponent<RichTextProps> = ({ header, rawHtml }) => {
  if (!rawHtml) {
    return null;
  }

  return (
    <div className={styles.container}>
      <Typography variant="h4">{header ?? ""}</Typography>
      <div
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(rawHtml),
        }}
      />
    </div>
  );
};

export default RichText;
