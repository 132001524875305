import createAuth0Client, { Auth0Client } from "@auth0/auth0-spa-js";
import { Auth0ClientOptions } from "@auth0/auth0-spa-js/dist/typings/global";

const createClient = async (
  initOptions: Auth0ClientOptions
): Promise<Auth0Client> => {
  let auth0Client: Auth0Client;
  try {
    // Since this can fail, fallback to manually constructing an auth0 instance if
    // necessary
    auth0Client = await createAuth0Client(initOptions);
  } catch (error) {
    auth0Client = new Auth0Client(initOptions);
  }
  return auth0Client;
};

const initializeAuth0Client = async (
  initOptions: Auth0ClientOptions
): Promise<Auth0Client> => {
  let auth0Client: Auth0Client;
  try {
    auth0Client = await createClient(initOptions);
  } catch (error) {
    console.log(process.env.REACT_APP_APP_ENV);
    console.log(error.message);
    if (process.env.REACT_APP_APP_ENV === "acceptance") {
      // We should only ever have this happen in e2e tests, so it should be fine to
      // pretend that we never return undefined from this function
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return;
    }
    throw error;
  }
  try {
    // To support the Iframe use case, we need to manually check if the token is here, since
    // we can't properly use cookie handling
    await auth0Client.getTokenSilently();
  } catch (error) {
    if (
      error.error !== "login_required" &&
      error.error !== "consent_required"
    ) {
      throw error;
    }
  }
  // eslint-disable-next-line consistent-return
  return auth0Client;
};

export default initializeAuth0Client;
