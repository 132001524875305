import _ from "lodash";
import React, { useContext } from "react";
import cx from "classnames";
import {
  IconButton,
  Typography,
  TextField,
  Button,
  makeStyles,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { AnalyticsContext } from "~/analytics";

import { ResourceCustomTheme } from "~/styles/config";
import DropzoneWithPreview from "../Dropzone/DropzoneWithPreview";

const useStyles = makeStyles((theme: ResourceCustomTheme) => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },
  titleBar: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    marginTop: 0,
  },
  addNew: {
    marginTop: "10px !important",
    marginBottom: "20px !important",
  },
  header: {
    width: "100%",
    marginTop: 10,
    marginBottom: 5,
    paddingLeft: 5,
    display: "flex",
    alignContent: "center",
    justifyContent: "space-between",
    h6: {
      display: "flex",
      alignItems: "center",
      margin: 0,
    },
    button: {
      height: 38,
      width: 38,
      display: "flex",
      alignItems: "center",
      color: "#c4c4c4",
      fontSize: 15,
    },
    "button:hover": {
      color: theme.colors.MidnightBlue(),
    },
  },
  primaryText: {
    div: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    marginBottom: 20,
  },
  secondaryText: {
    div: {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
    marginBottom: 20,
  },
}));

export type DraftLinkItem = {
  id?: string;
  label?: string;
  url?: string;
  imageUrl?: string;
};

type EditLinksFormProps = {
  links: DraftLinkItem[];
  setLinks(items: DraftLinkItem[]): void;
};

const EditLinksForm: React.FC<EditLinksFormProps> = ({ links, setLinks }) => {
  const classes = useStyles();
  const analytics = useContext(AnalyticsContext);

  return (
    <div className={classes.formContainer}>
      {_.map(links, (item, idx) => (
        <React.Fragment key={item.id || idx}>
          <div className={cx(classes.header)}>
            <Typography variant="h6">Link {idx + 1}</Typography>
            <IconButton
              aria-label="delete"
              onClick={() => {
                analytics.track(`Delete Link Clicked`);
                const filteredLinks = _.filter(links, (i) => i !== item);
                setLinks(filteredLinks);
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </IconButton>
          </div>
          <TextField
            className={classes.primaryText}
            variant="outlined"
            aria-label="Label"
            placeholder="Eg. Our Blog"
            value={links[idx].label}
            onChange={(e: any): void => {
              const newItems = _.map(links, (existingItem, index) =>
                Number(index) === idx
                  ? {
                      ...existingItem,
                      label: e.target.value,
                    }
                  : existingItem
              );
              setLinks(newItems);
            }}
            onBlur={() => {
              analytics.track("Link Label Edited");
            }}
          />
          <TextField
            className={classes.secondaryText}
            variant="outlined"
            multiline
            rowsMax="10"
            aria-label="URL"
            placeholder="eg. http://yourcompany.com/blog"
            value={links[idx].url}
            onChange={(e) => {
              const newLinks = _.map(links, (existingItem, index) =>
                Number(index) === idx
                  ? {
                      ...existingItem,
                      url: e.target.value,
                    }
                  : existingItem
              );
              setLinks(newLinks);
            }}
            onBlur={() => {
              analytics.track("Link URL Edited");
            }}
          />

          <DropzoneWithPreview
            photoUrl={links[idx].imageUrl || ""}
            setPhotoUrl={(newImageUrl: string) => {
              const newLinks = _.map(links, (existingItem, index) =>
                Number(index) === idx
                  ? {
                      ...existingItem,
                      imageUrl: newImageUrl,
                    }
                  : existingItem
              );
              setLinks(newLinks);
            }}
          />
        </React.Fragment>
      ))}
      <Button
        className={cx(classes.addNew)}
        onClick={(): void => {
          analytics.track(`Add Another Link Clicked`);
          setLinks(_.concat(links, {}));
        }}
      >
        <FontAwesomeIcon
          style={{
            marginRight: "10px",
          }}
          icon={faPlus}
          size="sm"
        />
        Add another Link
      </Button>
    </div>
  );
};

export default EditLinksForm;
