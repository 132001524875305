import gql from "graphql-tag";

const CustomerFragmentForGreenhouse = gql`
  fragment customerFragmentForGreenhouse on Customer {
    id
    integration(input: $typeInput) {
      id
      type
      connected
      ... on GreenhouseIntegrationV2 {
        apiKeySuffix
        subdomain
        maildropAddress
      }
    }
  }
`;

export default CustomerFragmentForGreenhouse;
