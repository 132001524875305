import gql from "graphql-tag";

export default gql`
  mutation UpdateTimeForStageTemplateModuleInstallation(
    $input: UpdateTimeInstallationForStageTemplateInput!
  ) {
    updateTimeInstallationForStageTemplate(input: $input) {
      success
      stageTemplate {
        id
        name
        updatedAt
      }
    }
  }
`;
