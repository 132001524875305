import React, { useState } from "react";
import { strings } from "@resource/common";
import {
  DialogContent,
  Button,
  DialogActions,
  makeStyles,
  Chip,
  Grid,
} from "@material-ui/core";
import {
  InterviewEditsDispatchType,
  InterviewEditsStateType,
  ActionEnum,
} from "./types";
import { ResourceCustomTheme } from "~/styles/config";
import InterviewTemplate from "~/pages/GuidesHomepage/Templates/GuideTemplates/InterviewKit/InterviewTemplate";

const useStyles = makeStyles((theme: ResourceCustomTheme) => ({
  root: {},
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  textField: {
    marginTop: theme.spacing(1),
  },
  chip: {
    backgroundColor: theme.colors.RoyalPurple(100),
    color: theme.colors.RoyalPurple(),
    marginBottom: theme.spacing(2),
  },
}));

type EditInterviewsProps = {
  state: InterviewEditsStateType;
  dispatch: React.Dispatch<InterviewEditsDispatchType>;
};

const EditInterviews: React.FC<EditInterviewsProps> = ({ state, dispatch }) => {
  const classes = useStyles();
  const [title, setTitle] = useState(
    state.titleOverride || state.interviewName
  );
  const [hidden, setHidden] = useState(state.hiddenOverride ?? false);
  const [description, setDescription] = useState(state.descriptionOverride);

  return (
    <>
      <DialogContent dividers>
        <Chip
          className={classes.chip}
          color="primary"
          variant="default"
          label={
            <span>
              {`${strings.pluralize(
                "Interview",
                state.interviewIds.length ?? 0
              )} selected named `}
              <strong>{state.interviewName}</strong>
            </span>
          }
        />
        <InterviewTemplate
          atsInterviewName={state.interviewName}
          title={title}
          description={description}
          hidden={hidden}
          setTitle={setTitle}
          setDescription={setDescription}
          setHidden={setHidden}
        />
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={12} className={classes.actions}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                dispatch({
                  type: ActionEnum.Back,
                });
              }}
              style={{ marginLeft: 10 }}
            >
              Back
            </Button>
            <Button
              color="primary"
              type="submit"
              disabled={!title}
              variant="contained"
              onClick={() => {
                dispatch({
                  type: ActionEnum.SetEdits,
                  titleOverride: title,
                  hiddenOverride: hidden,
                  descriptionOverride: description,
                });
                dispatch({
                  type: ActionEnum.Next,
                });
              }}
              style={{ marginLeft: 10, minWidth: 240 }}
            >
              Confirm Edits to{" "}
              {strings.pluralize("interview", state.interviewIds.length ?? 0)}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};

export default EditInterviews;
