import React, { FunctionComponent } from "react";
import { Typography } from "@material-ui/core";

import styles from "./HeaderGreeting.module.scss";

type HeaderGreetingProps = {
  header: string;
  subheader: string;
};

const HeaderGreeting: FunctionComponent<HeaderGreetingProps> = ({
  header,
  subheader,
}) => (
  <div className={styles.headerGreetingContainer}>
    <Typography className={styles.greeting} variant="h3">
      {header}
    </Typography>
    <Typography className={styles.role} variant="h4">
      {subheader}
    </Typography>
  </div>
);

export default HeaderGreeting;
