import React from "react";
import * as Sentry from "@sentry/react";

type Props = {
  silent: boolean;
};

type ErrorState = {
  hasError: boolean;
};

export default class ErrorBoundary extends React.Component<Props, ErrorState> {
  static defaultProps = {
    silent: true,
  };

  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error: Error): ErrorState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, _errorInfo: React.ErrorInfo) {
    Sentry.captureException(error);
  }

  render(): React.ReactNode {
    const { hasError } = this.state;
    const { children, silent } = this.props;
    if (hasError) {
      if (silent) {
        return null;
      }
      return <h1>Something went wrong.</h1>;
    }

    return children;
  }
}
