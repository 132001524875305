import React, { useState } from "react";

import _ from "lodash";

import { faChevronDown } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Popper,
  TextField,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import { ColumnInstance } from "react-table";
import DoneIcon from "@material-ui/icons/Done";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) =>
  createStyles({
    popper: {
      border: "1px solid rgba(27,31,35,.15)",
      boxShadow: "0 3px 12px rgba(27,31,35,.15)",
      borderRadius: 3,
      width: 300,
      zIndex: 1,
      fontSize: 13,
      color: "#586069",
      backgroundColor: "#f6f8fa",
    },
    paper: {
      boxShadow: "none",
      margin: 0,
      color: "#586069",
      fontSize: 13,
    },
    option: {
      minHeight: "auto",
      alignItems: "flex-start",
      padding: 8,
      '&[aria-selected="true"]': {
        backgroundColor: "transparent",
      },
      '&[data-focus="true"]': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    popperDisablePortal: {
      position: "relative",
    },
  })
);

// eslint-disable-next-line @typescript-eslint/ban-types
type AutocompleteColumnFilterProps<D extends object> = {
  column: ColumnInstance<D> & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    allOptions: any[];
    sample?: string;
  };
};

const AutocompleteColumnFilter: React.FC<AutocompleteColumnFilterProps<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any
>> = ({ column: { allOptions, filterValue, setFilter, Header, sample } }) => {
  const styles = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event, reason) => {
    if (reason === "toggleInput") {
      return;
    }
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const sortedOptions = _.sortBy(allOptions);
  const options = _.chain(sortedOptions)
    .partition((option) => _.includes(filterValue, option))
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- Busted lodash types
    // @ts-ignore
    .flatten()
    .value();

  return (
    <>
      <Button onClick={handleClick}>
        <FontAwesomeIcon icon={faChevronDown} size="sm" />
        &nbsp;
        {Header}
      </Button>
      <Popper open={open} anchorEl={anchorEl} className={styles.popper}>
        <Autocomplete
          freeSolo
          open
          classes={{
            paper: styles.paper,
            option: styles.option,
            popperDisablePortal: styles.popperDisablePortal,
          }}
          multiple
          onClose={handleClose}
          noOptionsText={`No ${Header}s`}
          value={filterValue || []}
          onChange={(event, newValue) => {
            setFilter(newValue);
          }}
          disableClearable
          disableCloseOnSelect
          disablePortal
          options={options}
          renderTags={() => null}
          renderOption={(option, { selected }) => (
            <>
              <DoneIcon
                fontSize="small"
                style={{
                  visibility: selected ? "visible" : "hidden",
                  marginRight: "0.4rem",
                }}
              />
              {option}
            </>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              autoFocus
              label={`${Header}s`}
              type="search"
              placeholder={sample ? `e.g. ${sample}` : "Search"}
              variant="outlined"
              fullWidth
            />
          )}
        />
      </Popper>
    </>
  );
};

export default AutocompleteColumnFilter;
