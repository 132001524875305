import React, { useCallback, useState } from "react";

import _ from "lodash";
import { gql, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { Link, Typography } from "@material-ui/core";
import IntegrationCard from "../Integrations/IntegrationCard";
import { useFlashMessage } from "~/components/FlashMessage/FlashMessage";
import slackIconLg from "~/icons/slack-icon-lg.svg";
import SlackIntegrationModal from "./SlackIntegrationModal";

import CustomerWithSlackIntegrationFragment from "~/fragments/CUSTOMER_WITH_SLACK_INTEGRATION_FRAGMENT";
import {
  IntegrationEnum,
  CurrentUser,
  DisconnectSlackIntegrationForCustomer,
  FetchAllCustomerIntegrations_currentUserV2_organization_customer_integrations,
} from "~/schemaTypes";

import CURRENT_USER_QUERY from "~/queries/CURRENT_USER_QUERY";

type SlackIntegrationCardProps = {
  integration: FetchAllCustomerIntegrations_currentUserV2_organization_customer_integrations;
};

const DISCONNECT_SLACK_INTEGRATION_FOR_CUSTOMER = gql`
  mutation DisconnectSlackIntegrationForCustomer(
    $typeInput: IntegrationByTypeInput!
  ) {
    disconnectCustomerSlackIntegration {
      success
      message
      code
      customer {
        ...customerFragmentForSlack
      }
    }
  }
  ${CustomerWithSlackIntegrationFragment}
`;

const SlackIntegrationCard: React.FC<SlackIntegrationCardProps> = ({
  integration,
}) => {
  const { connected } = integration;
  const [open, setOpen] = useState(false);
  const { setContent } = useFlashMessage();
  const [disconnect, { loading: disconnectSaving }] = useMutation<
    DisconnectSlackIntegrationForCustomer
  >(DISCONNECT_SLACK_INTEGRATION_FOR_CUSTOMER);

  const onDisconnect = async () => {
    try {
      await disconnect({
        variables: {
          typeInput: {
            type: IntegrationEnum.SLACK,
          },
        },
        update: (cache) => {
          const currentUser = cache.readQuery<CurrentUser>({
            query: CURRENT_USER_QUERY,
          });

          if (currentUser) {
            _.set(currentUser, "currentUserV2.onboardingComplete", false);
            cache.writeQuery({
              query: CURRENT_USER_QUERY,
              data: currentUser,
            });
          }
        },
      });
    } catch (error) {
      setContent({
        content:
          "Something went wrong disconnecting your Greenhouse integration",
        severity: "error",
      });
      Sentry.captureException(error);
    }
  };

  const onConnect = () => setOpen(true);
  const onClose = useCallback(() => setOpen(false), [setOpen]);

  return (
    <>
      <IntegrationCard
        icon={slackIconLg}
        label="Slack"
        connected={connected}
        onDisconnect={onDisconnect}
        disconnectSaving={disconnectSaving}
        onConnect={onConnect}
      >
        {connected ? (
          <Typography>
            <Link
              underline="always"
              component="button"
              onClick={() => setOpen(true)}
            >
              Update
            </Link>
            &nbsp;Connection settings
          </Typography>
        ) : (
          <Typography>
            Connect Slack to automatically populate Interviewer names and
            photos. Additionally, you can receive messages from candidates who
            communicate via the in-Guide chat to a Slack channel.
          </Typography>
        )}
      </IntegrationCard>
      <SlackIntegrationModal open={open} onClose={onClose} />
    </>
  );
};

export default SlackIntegrationCard;
