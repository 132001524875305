import _ from "lodash";
import React from "react";
import urljoin from "url-join";
import { useSubscription, useQuery } from "@apollo/client";
import { GreenhouseWebhookActionEnum, FeatureFlagEnum } from "@resource/common";
import { Divider, Typography, Link, makeStyles } from "@material-ui/core";
import { useFlags } from "@resource/client-ffs";
import greenhouseIconLg from "~/icons/greenhouse-icon-lg.svg";
import IntegrationModal from "../Integrations/IntegrationModal";
import HASURA_GREENHOUSE_WEBHOOKS from "./HASURA_GREENHOUSE_WEBHOOKS@hasura";
import HASURA_GREENHOUSE_METADATA from "./HASURA_GREENHOUSE_METADATA@hasura";

import {
  HasuraGetCustomerGreenhouseMetadata,
  HasuraGetCustomerGreenhouseWebhooks,
  HasuraGetCustomerGreenhouseWebhooksVariables,
} from "~/schemaTypesHasura";

import GreenhouseWebhookRow from "./GreenhouseWebhookRow";

const LimitedWebhooks = [
  GreenhouseWebhookActionEnum.CandidateStageChange,
  GreenhouseWebhookActionEnum.UpdateCandidate,
  GreenhouseWebhookActionEnum.JobUpdated,
  GreenhouseWebhookActionEnum.ApplicationUpdated,
  GreenhouseWebhookActionEnum.DeleteCandidate,
  GreenhouseWebhookActionEnum.DeleteApplication,
  GreenhouseWebhookActionEnum.InterviewDeleted,
  GreenhouseWebhookActionEnum.JobInterviewStageDeleted,
  GreenhouseWebhookActionEnum.JobDeleted,
  GreenhouseWebhookActionEnum.JobCreated,
];

const AllWebhooks = [
  GreenhouseWebhookActionEnum.DeleteApplication,
  GreenhouseWebhookActionEnum.ApplicationUpdated,
  GreenhouseWebhookActionEnum.NewCandidateApplication,
  GreenhouseWebhookActionEnum.RejectCandidate,
  GreenhouseWebhookActionEnum.UnrejectCandidate,
  GreenhouseWebhookActionEnum.UpdateCandidate,
  GreenhouseWebhookActionEnum.DeleteCandidate,
  GreenhouseWebhookActionEnum.MergeCandidate,
  GreenhouseWebhookActionEnum.HireCandidate,
  GreenhouseWebhookActionEnum.CandidateStageChange,
  GreenhouseWebhookActionEnum.UnhireCandidate,
  GreenhouseWebhookActionEnum.JobCreated,
  GreenhouseWebhookActionEnum.JobDeleted,
  GreenhouseWebhookActionEnum.JobUpdated,
  GreenhouseWebhookActionEnum.JobApproved,
  GreenhouseWebhookActionEnum.JobPostCreated,
  GreenhouseWebhookActionEnum.JobPostUpdated,
  GreenhouseWebhookActionEnum.JobPostDeleted,
  GreenhouseWebhookActionEnum.JobInterviewStageDeleted,
  GreenhouseWebhookActionEnum.InterviewDeleted,
  GreenhouseWebhookActionEnum.ScorecardDeleted,
  GreenhouseWebhookActionEnum.NewProspectApplication,
  GreenhouseWebhookActionEnum.DepartmentDeleted,
  GreenhouseWebhookActionEnum.OfficeDeleted,
  GreenhouseWebhookActionEnum.OfferCreated,
  GreenhouseWebhookActionEnum.OfferUpdated,
  GreenhouseWebhookActionEnum.OfferDeleted,
];

const GreenhouseWebhookDisplayName = {
  [GreenhouseWebhookActionEnum.DeleteApplication]: "Delete Application",
  [GreenhouseWebhookActionEnum.ApplicationUpdated]: "Application Updated",
  [GreenhouseWebhookActionEnum.NewCandidateApplication]:
    "Candidate has submitted application",
  [GreenhouseWebhookActionEnum.RejectCandidate]:
    "Candidate or Prospect rejected",
  [GreenhouseWebhookActionEnum.UnrejectCandidate]:
    "Candidate or Prospect unrejected",
  [GreenhouseWebhookActionEnum.UpdateCandidate]:
    "Candidate or Prospect updated",
  [GreenhouseWebhookActionEnum.DeleteCandidate]: "Delete Candidate",
  [GreenhouseWebhookActionEnum.MergeCandidate]: "Merged Candidate",
  [GreenhouseWebhookActionEnum.HireCandidate]: "Candidate has been hired",
  [GreenhouseWebhookActionEnum.CandidateStageChange]:
    "Candidate has changed stage",
  [GreenhouseWebhookActionEnum.UnhireCandidate]: "Candidate has been unhired",
  [GreenhouseWebhookActionEnum.JobCreated]: "Job Created",
  [GreenhouseWebhookActionEnum.JobDeleted]: "Job Deleted",
  [GreenhouseWebhookActionEnum.JobUpdated]: "Job Updated",
  [GreenhouseWebhookActionEnum.JobApproved]: "Job Approved",
  [GreenhouseWebhookActionEnum.JobPostCreated]: "Job Post Created",
  [GreenhouseWebhookActionEnum.JobPostUpdated]: "Job Post Updated",
  [GreenhouseWebhookActionEnum.JobPostDeleted]: "Job Post Deleted",
  [GreenhouseWebhookActionEnum.JobInterviewStageDeleted]:
    "Job Interview Stage Deleted",
  [GreenhouseWebhookActionEnum.InterviewDeleted]: "Interview Deleted",
  [GreenhouseWebhookActionEnum.ScorecardDeleted]: "Scorecard Deleted",
  [GreenhouseWebhookActionEnum.NewProspectApplication]: "Prospect created",
  [GreenhouseWebhookActionEnum.DepartmentDeleted]: "Department Deleted",
  [GreenhouseWebhookActionEnum.OfficeDeleted]: "Office Deleted",
  [GreenhouseWebhookActionEnum.OfferCreated]: "Offer Created",
  [GreenhouseWebhookActionEnum.OfferUpdated]: "Offer Updated",
  [GreenhouseWebhookActionEnum.OfferDeleted]: "Offer Deleted",
};

const useStyles = makeStyles(() => ({
  webhooks: {
    marginBottom: "2rem",
  },
}));

type GreenhouseWebhooksModalProps = {
  open: boolean;
  onConnected?: () => void;
  onClose: () => void;
};

const GreenhouseWebhooksModal: React.FC<GreenhouseWebhooksModalProps> = ({
  open,
  onClose,
}) => {
  const styles = useStyles();
  const {
    [_.camelCase(
      FeatureFlagEnum.FullGreenhousePermissionsRequest
    )]: fullGreenhousePermissionsRequestFlag,
  } = useFlags();
  const { data: greenhouseData } = useQuery<
    HasuraGetCustomerGreenhouseMetadata
  >(HASURA_GREENHOUSE_METADATA);
  const customerId = greenhouseData?.customerGreenhouseMetadata?.customer?.id;
  const secretKey =
    greenhouseData?.customerGreenhouseMetadata?.webhooksSecretKey || "";
  const subdomain =
    greenhouseData?.customerGreenhouseMetadata?.subdomain || "app";
  const rootUrl = `https://${subdomain}.greenhouse.io`;

  const { data: webhookData } = useSubscription<
    HasuraGetCustomerGreenhouseWebhooks,
    HasuraGetCustomerGreenhouseWebhooksVariables
  >(HASURA_GREENHOUSE_WEBHOOKS, {
    variables: {
      customerId,
    },
    skip: !customerId,
  });

  const enabledWebhooks =
    _.first(webhookData?.customer)?.greenhouse_webhooks || [];

  const handleClose = () => {
    onClose();
  };

  const keyedWebhooks = _.keyBy(enabledWebhooks, "eventName");

  const webhooksToRequest = fullGreenhousePermissionsRequestFlag
    ? AllWebhooks
    : LimitedWebhooks;

  const webhooks = _(webhooksToRequest)
    .map((webhookType) => {
      const existingWebhook = _.get(keyedWebhooks, webhookType);
      const webhookDisplayName = GreenhouseWebhookDisplayName[webhookType];
      return {
        displayName: webhookDisplayName,
        eventName: webhookType,
        lastReceivedAt: existingWebhook?.lastReceivedAt,
        setup: !!existingWebhook,
        url: `${process.env.REACT_APP_WEBHOOKS_URL}?customerId=${customerId}`,
      };
    })
    .orderBy("eventName")
    .value();

  return (
    <IntegrationModal
      open={open}
      onClose={handleClose}
      title="Greenhouse"
      logoSrc={greenhouseIconLg}
    >
      <Typography variant="body1">
        Configure Webhooks so Guide can respond to changes in Greenhouse.
      </Typography>
      <br />
      <Typography>
        You must have developer permissions on your Greenhouse account in order
        to set up Greenhouse webhooks. This can take up to
        <strong> 10 minutes </strong>to set up.
      </Typography>
      <br />
      <Typography variant="body1">
        <strong>
          Follow the instructions below to connect Greenhouse webhooks to
          Resource:
        </strong>
      </Typography>
      <ol>
        <li>
          Navigate to the Greenhouse
          <Link
            href={urljoin(rootUrl, "web_hooks")}
            rel="noreferrer noopener"
            target="_blank"
          >
            {" "}
            web hook configuration page
          </Link>
        </li>
        <li>
          <Typography>For each of the following webhooks:</Typography>
          <ul>
            <li>Copy the webhook info below and paste into Greenhouse.</li>
            <li>
              In Greenhouse, click&nbsp;
              <strong>
                &ldquo;Create Web hook&rdquo;&nbsp;
                <i>
                  (Make sure &ldquo;disabled&rdquo; is set to &ldquo;No&rdquo;)
                </i>
                .
              </strong>
            </li>
            <li>
              Return to Guide, and the corresponding webhook should now be
              marked as working.
            </li>
          </ul>
        </li>
      </ol>
      <div className={styles.webhooks}>
        {_.map(webhooks, (webhook, index) => (
          <React.Fragment key={webhook.eventName}>
            {!!index && <Divider />}
            <GreenhouseWebhookRow webhook={webhook} secretKey={secretKey} />
          </React.Fragment>
        ))}
      </div>
    </IntegrationModal>
  );
};

export default GreenhouseWebhooksModal;
