import _ from "lodash";
import React, { FunctionComponent } from "react";
import {
  DragDropContext,
  Droppable,
  DroppableProvided,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from "react-beautiful-dnd";
import { ModuleEnum, NonListableModules } from "@resource/common";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { makeStyles, Button } from "@material-ui/core";
import EditModuleCard from "~/components/EditModuleCard/EditModuleCard";
import { GlobalEditModuleProps } from "~/components/types";

const useStyles = makeStyles({
  addButton: {
    height: "60px",
    borderRadius: "4px",
  },
  icon: {
    marginRight: "5px",
  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DataType = Record<string, any>;

export type ModuleForEditing = {
  id: string;
  type: ModuleEnum;
  data: DataType;
  position: number;
  canEdit: boolean;
};

type EditModuleListProps = {
  modules: ModuleForEditing[];
  moduleIdJustAdded: string | undefined;
  onModuleReorder(opts: { modules: ModuleForEditing[] }): Promise<void>;
  handleAddModuleButtonClick: () => void;
};

const EditModuleList: FunctionComponent<
  GlobalEditModuleProps & EditModuleListProps
> = ({
  setSelectedModule,
  setEditModuleOpen,
  removeModule,
  onModuleReorder,
  moduleIdJustAdded,
  modules,
  handleAddModuleButtonClick,
}) => {
  const classes = useStyles();
  const listableModules = _.filter(modules, ({ type }) => {
    return !NonListableModules.includes(type as ModuleEnum);
  });
  const sortedModules = _.sortBy(listableModules, "position");

  return (
    <>
      <DragDropContext
        onDragEnd={async (draggedItem) => {
          const { draggableId, destination } = draggedItem;

          if (destination) {
            const [sourceModule, updatedOrderableModules] = _.partition(
              sortedModules,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (module: any) => module.id === draggableId
            );
            updatedOrderableModules.splice(
              destination.index,
              0,
              sourceModule[0]
            );

            // Fire mutation to update module positions in db
            await onModuleReorder({
              modules: updatedOrderableModules,
            });
          }
        }}
      >
        <Droppable droppableId="stageModules">
          {(provided: DroppableProvided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {_.map(sortedModules, (module, index: number) => (
                <Draggable
                  key={module.id}
                  draggableId={module.id}
                  index={index}
                  isDragDisabled={false}
                >
                  {(
                    draggableProvided: DraggableProvided,
                    snapshot: DraggableStateSnapshot
                  ) => (
                    <div
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                      style={{
                        ...draggableProvided.draggableProps.style,
                      }}
                    >
                      <EditModuleCard
                        key={`edit-module-${module.id}`}
                        setSelectedModule={setSelectedModule}
                        setEditModuleOpen={setEditModuleOpen}
                        removeModule={removeModule}
                        isDraggable
                        highlighted={moduleIdJustAdded === module.id}
                        isBeingDragged={snapshot.isDragging}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...module}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button
        variant="outlined"
        fullWidth
        className={classes.addButton}
        color="primary"
        disableRipple
        onClick={handleAddModuleButtonClick}
      >
        <FontAwesomeIcon className={classes.icon} icon={faPlus} size="lg" />
        Add Module
      </Button>
    </>
  );
};

export default EditModuleList;
