import gql from "graphql-tag";

export default gql`
  fragment stageTemplateWithModules on StageTemplate {
    id
    name
    emailTemplate {
      id
      name
    }
    organization {
      id
      name
    }
    modules {
      id
      type
      position
      data
    }
    createdBy
    updatedAt
  }
`;
