import React, { useState } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";

import { Typography, TextField, makeStyles } from "@material-ui/core";
import coderpadIconLg from "~/icons/coderpad-icon-lg.svg";
import {
  CoderPadIntegrationForCustomer,
  UpdateCoderPadIntegrationForCustomer,
  UpdateCoderPadIntegrationForCustomerVariables,
  IntegrationEnum,
  CoderPadIntegrationForCustomer_currentUserV2_organization_customer_integration_CoderPadIntegration as CoderPadIntegration,
} from "~/schemaTypes";
import { IntegrationsPageIntegrationFragment } from "../Integrations/ALL_INTEGRATIONS_QUERY";

import IntegrationModal from "../Integrations/IntegrationModal";

const CustomerFragment = gql`
  fragment customerFragmentForCoderPad on Customer {
    id
    integration(input: $typeInput) {
      ...IntegrationsPageIntegration
      ... on CoderPadIntegration {
        apiKeySuffix
      }
    }
  }
  ${IntegrationsPageIntegrationFragment}
`;

const CODERPAD_INTEGRATION_FOR_CUSTOMER = gql`
  query CoderPadIntegrationForCustomer($typeInput: IntegrationByTypeInput!) {
    currentUserV2 {
      id
      organization {
        id
        customer {
          ...customerFragmentForCoderPad
        }
      }
    }
  }
  ${CustomerFragment}
`;

const UPDATE_CODERPAD_INTEGRATION_FOR_CUSTOMER = gql`
  mutation UpdateCoderPadIntegrationForCustomer(
    $input: UpdateCustomerCoderPadIntegrationInput!
    $typeInput: IntegrationByTypeInput!
  ) {
    updateCustomerCoderPadIntegration(input: $input) {
      success
      message
      code
      customer {
        ...customerFragmentForCoderPad
      }
    }
  }
  ${CustomerFragment}
`;

const useStyles = makeStyles(() => ({
  description: {
    marginBottom: "1.5rem",
  },
  formField: {
    marginTop: "2rem",
  },
}));

type CoderPadIntegrationModalProps = {
  open: boolean;
  onClose: () => void;
};

const CoderPadIntegrationModal: React.FC<CoderPadIntegrationModalProps> = ({
  open,
  onClose,
}) => {
  const styles = useStyles();
  const { data } = useQuery<CoderPadIntegrationForCustomer>(
    CODERPAD_INTEGRATION_FOR_CUSTOMER,
    {
      variables: {
        typeInput: {
          type: IntegrationEnum.CODERPAD,
        },
      },
    }
  );
  const integration = data?.currentUserV2?.organization.customer
    .integration as CoderPadIntegration | null;

  const [apiKey, setApiKey] = useState<string>("");
  const [update, { loading: updateSaving }] = useMutation<
    UpdateCoderPadIntegrationForCustomer,
    UpdateCoderPadIntegrationForCustomerVariables
  >(UPDATE_CODERPAD_INTEGRATION_FOR_CUSTOMER);

  const handleUpdate = async () => {
    try {
      if (!apiKey) {
        throw new Error("API Key not in state, this should never happen.");
      }
      await update({
        variables: {
          input: {
            apiKey,
          },
          typeInput: {
            type: IntegrationEnum.CODERPAD,
          },
        },
      });
    } catch (error) {
      Sentry.captureException(error);
      return;
    }

    onClose();
  };

  const apiKeySuffix = integration?.apiKeySuffix;
  const placeholder = apiKeySuffix
    ? `xxxxxxxxxxxxxxxxxxxx${apiKeySuffix}`
    : "ie: wrKa9jw3wuw90AZ2xpwiQ4BGu";

  return (
    <IntegrationModal
      open={open}
      onClose={onClose}
      onSave={handleUpdate}
      updateSaving={updateSaving}
      logoSrc={coderpadIconLg}
      title="CoderPad"
      disabled={!apiKey}
    >
      <Typography className={styles.description} variant="body1">
        The Guide CoderPad integration allows you to automatically generate a
        CoderPad link for any interviews that require one. Toggle on CoderPad
        links in the Interview Detail, and every time that interview is
        scheduled, the Guide will include a CoderPad link.
      </Typography>
      <TextField
        className={styles.formField}
        variant="outlined"
        label="CoderPad API Key"
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e): void => setApiKey(e.target.value)}
        placeholder={placeholder}
        fullWidth
        value={apiKey}
      />
    </IntegrationModal>
  );
};

export default CoderPadIntegrationModal;
