import _ from "lodash";
import React, { FunctionComponent, useState } from "react";
import { Typography, Card, Fab, CardActionArea } from "@material-ui/core";
import { LocationTypeEnum } from "@resource/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { FetchCompanyData_currentUserV2_organization_locations as LocationsType } from "~/schemaTypes";
import { getStaticMapsUrl, ImageSize, ZoomLevel } from "~/utils/image-utils";
import styles from "./Locations.module.scss";
import EditLocationModal from "./EditLocationModal/EditLocationModal";
import useAnalytics from "~/react-hooks/useAnalytics";

type LocationsProps = {
  locations: LocationsType[];
  refetchCompany(): void;
};

const Locations: FunctionComponent<LocationsProps> = ({
  locations,
  refetchCompany,
}) => {
  const [analytics] = useAnalytics();
  const [selectedLocationId, setSelectedLocationId] = useState<
    string | null | undefined
  >();

  return (
    <div className={styles.locationContainer}>
      <div className={styles.headerCont}>
        <Typography className={styles.text} variant="h3">
          <span role="img" aria-label="Location Pin">
            📍
          </span>{" "}
          Locations
        </Typography>
        <Fab
          onClick={(): void => {
            setSelectedLocationId(null);
            if (analytics) {
              analytics.track("Company Page New Location Button Clicked");
            }
          }}
          color="primary"
          size="small"
          aria-label="add value"
        >
          <FontAwesomeIcon icon={faPlus} size="lg" />
        </Fab>
      </div>
      <div className={styles.locationsFlexCont}>
        {locations.map(({ id, name, address, coordinates }) => (
          <Card
            key={name}
            className={styles.locationCard}
            elevation={1}
            onClick={(): void => {
              setSelectedLocationId(id);
              if (analytics) {
                analytics.track("Company Page Location Card Clicked");
              }
            }}
          >
            <CardActionArea
              className={styles.actionArea}
              style={{
                backgroundImage: `url(${getStaticMapsUrl(
                  coordinates,
                  ImageSize.Square,
                  ZoomLevel.MidRange
                )})`,
              }}
            >
              <div className={styles.overlay}>
                <Typography className={styles.text} variant="h4">
                  {name}
                </Typography>
                {address !== LocationTypeEnum.REMOTE && (
                  <Typography className={styles.address} variant="subtitle2">
                    {address}
                  </Typography>
                )}
              </div>
            </CardActionArea>
          </Card>
        ))}
      </div>
      {!_.isUndefined(selectedLocationId) && (
        <EditLocationModal
          activeLocationId={selectedLocationId}
          clearActiveLocationId={() => {
            setSelectedLocationId(undefined);
            refetchCompany();
          }}
        />
      )}
    </div>
  );
};
export default Locations;
