/* eslint-disable no-underscore-dangle */
import { useState, useEffect } from "react";

// Track globally so as to not readd the same script twice
const scriptUrls: any = {};

type DependentScriptReturnType = {
  isLoading: boolean;
  errorMsg?: string;
};
export default function useDependentScript(
  scriptSrc: string
): DependentScriptReturnType {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMsg, setErrorMsg] = useState<string | undefined>();

  useEffect(() => {
    const _handleLoad = (): void => {
      setIsLoading(false);
    };
    const _handleError = (err: ErrorEvent): void => {
      setErrorMsg(err.message);
    };

    if (!scriptUrls[scriptSrc]) {
      scriptUrls[scriptSrc] = true;
      const scriptNode = document.createElement("script");
      scriptNode.type = "text/javascript";
      scriptNode.src = scriptSrc;
      scriptNode.addEventListener("load", _handleLoad);
      scriptNode.addEventListener("error", _handleError);
      document.body.appendChild(scriptNode);
      console.log("scriptNode: ", scriptNode);

      return (): void => {
        scriptNode.removeEventListener("load", _handleLoad);
        scriptNode.removeEventListener("error", _handleError);
      };
    }

    return (): void => {};
  }, [scriptSrc]);

  return {
    isLoading,
    errorMsg,
  };
}
