import React, { FunctionComponent } from "react";

// import styles from './YouTube.module.scss';

type YouTubeProps = {
  videoId: string;
};

const YouTube: FunctionComponent<YouTubeProps> = ({ videoId }) => (
  <iframe
    title={videoId}
    width="100%"
    height="315"
    src={`https://www.youtube.com/embed/${videoId}`}
    frameBorder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  />
);
export default YouTube;
