import React, { useState, useEffect } from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";

import moment from "moment";
import { CardData } from "~/components/Masonry/Masonry";
import useTextSearch from "~/react-hooks/useTextSearch";

import Comments from "./Comments/Comments";
import SortBar, {
  FilterParams,
  FilterTimeRangeEnum,
  RolesEnum,
} from "./SortBar/SortBar";
import Summary from "./Summary/Summary";
import {
  FeedbackForOrganization,
  FeedbackForOrganizationVariables,
  FeedbackForOrganizationInput,
} from "../../../schemaTypes";

const FEEDBACK_FOR_ORGANIZATION = gql`
  query FeedbackForOrganization($feedbackInput: FeedbackForOrganizationInput) {
    currentUserV2 {
      id
      organization {
        id
        candidateFeedbackStats(input: $feedbackInput) {
          averageRating
          whatWentWell
          whatCouldBeBetter
          comments {
            id
            emoji
            whatWentWell
            whatCouldBeBetter
            comment
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    "&  > .header": {
      marginTop: "0",
    },
    "& > *": {
      marginBottom: "30px",
    },
  },
}));

type FeedbackProps = Record<string, never>;

const Feedback: React.FC<FeedbackProps> = () => {
  const classes = useStyles();
  const [getFeedback, { data, loading }] = useLazyQuery<
    FeedbackForOrganization,
    FeedbackForOrganizationVariables
  >(FEEDBACK_FOR_ORGANIZATION);
  const averageRating =
    data?.currentUserV2?.organization.candidateFeedbackStats.averageRating;
  const whatWentWell =
    data?.currentUserV2?.organization.candidateFeedbackStats.whatWentWell;
  const whatCouldBeBetter =
    data?.currentUserV2?.organization.candidateFeedbackStats.whatCouldBeBetter;
  const comments =
    data?.currentUserV2?.organization.candidateFeedbackStats.comments;

  const [filterParams, setFilterParams] = useState<FilterParams>({
    timeRange: FilterTimeRangeEnum.last1Month,
    roleId: RolesEnum.default,
  });
  const [searchParams, setSearchParams] = useState<string>("");
  const [filteredComments] = useTextSearch({
    queryString: searchParams,
    searchArray: comments || [],
    searchKey: "comment",
  });

  const [emptyStatement, setEmptyStatement] = useState<string>("");

  useEffect(() => {
    if (comments && comments.length === 0) {
      setEmptyStatement(
        "🤔 Looks like there isn't enough data. Try changing the filters."
      );
    } else if (filteredComments.length === 0) {
      setEmptyStatement("No comments match your search");
    }
  }, [filteredComments, comments]);

  useEffect(() => {
    const calculateTime = (selectedTime: FilterTimeRangeEnum): string => {
      switch (selectedTime) {
        case FilterTimeRangeEnum.last1Month:
          return moment().subtract(1, "month").toISOString();
        case FilterTimeRangeEnum.last3Months:
          return moment().subtract(3, "month").toISOString();
        case FilterTimeRangeEnum.last6Months:
          return moment().subtract(6, "month").toISOString();
        case FilterTimeRangeEnum.lastYear:
          return moment().subtract(12, "month").toISOString();
        default:
          return moment().subtract(3, "month").toISOString();
      }
    };

    const feedbackQueryInput: FeedbackForOrganizationInput = {
      startTimeRange: calculateTime(filterParams.timeRange),
    };

    if (filterParams.roleId !== RolesEnum.default) {
      feedbackQueryInput.jobRoleId = filterParams.roleId;
    }

    getFeedback({
      variables: {
        feedbackInput: {
          ...feedbackQueryInput,
        },
      },
    });
  }, [filterParams, getFeedback]);

  return (
    <div className={classes.root}>
      <Typography className="header" variant="h2">
        Candidate Feedback
      </Typography>
      <SortBar
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        stagesAvailable={[]}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
      />
      <Summary
        averageRating={averageRating}
        whatCouldBeBetter={whatCouldBeBetter || []}
        whatWentWell={whatWentWell || []}
        loading={loading}
      />
      <Comments
        comments={(filteredComments as CardData[]) || []}
        loading={loading}
        emptyStatement={emptyStatement}
      />
    </div>
  );
};

export default Feedback;
