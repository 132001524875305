import { useEffect, useState } from "react";
import { ApolloError, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { useClient } from "@resource/client-ffs";
import { currentOrganizationId } from "~/cache";

import {
  CurrentUser,
  CurrentUser_currentUserV2 as GuidesUser,
} from "~/schemaTypes";

import { Analytics } from "~/types";
import CURRENT_USER_QUERY from "~/queries/CURRENT_USER_QUERY";

const segmentIntegrations = (hash: string | null) => ({
  integrations:
    // eslint-disable-next-line no-process-env
    process.env.NODE_ENV === "development"
      ? {
          All: false,
          FullStory: false,
          Intercom: false,
        }
      : {
          Intercom: {
            user_hash: hash,
          },
        },
});

type UseCurrentUserReturnType = {
  currentUser?: GuidesUser | null;
  loading: boolean;
  error?: ApolloError;
};

export default (analytics: Analytics): UseCurrentUserReturnType => {
  const [launchDarklyLoading, setLaunchDarklyLoading] = useState(true);
  const flagsClient = useClient();

  const { data: currentUserData, loading, error } = useQuery<CurrentUser>(
    CURRENT_USER_QUERY
  );

  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
    }
  }, [error]);

  // Capture any LD Errors with Sentry
  useEffect(() => {
    const listener = (ldError: Error): void => {
      Sentry.captureException(ldError);
    };
    flagsClient.on("error", listener);
    return (): void => {
      flagsClient.off("error", listener);
    };
  }, [flagsClient]);

  useEffect(() => {
    const currentUser = currentUserData?.currentUserV2;
    if (currentUser) {
      const { intercomHash } = currentUser;
      const { name, primaryEmail, createdAt } = currentUser;
      const { analyticsGroupId, name: organizationName, id: organizationId } =
        currentUser.organization || {};
      analytics.identify(
        currentUser.analyticsUserId,
        {
          id: currentUser.id,
          name,
          email: primaryEmail,
          createdAt,
          customer: {
            id: analyticsGroupId,
            name: organizationName,
          },
        },
        segmentIntegrations(intercomHash)
      );

      if (analyticsGroupId && organizationName) {
        analytics.group(analyticsGroupId, {
          name: organizationName,
          type: "Company Name",
        });
      }

      if (organizationId && !currentOrganizationId()) {
        currentOrganizationId(organizationId);
      }

      if (currentUser.primaryEmail) {
        Sentry.setUser({
          email: currentUser.primaryEmail,
          id: currentUser.id,
        });
      }
      flagsClient.identifyUser(currentUser, undefined, () => {
        setLaunchDarklyLoading(false);
      });
    } else if (!loading) {
      setLaunchDarklyLoading(false);
    }
  }, [analytics, currentUserData, flagsClient, loading]);

  return {
    currentUser: currentUserData?.currentUserV2,
    loading: loading || launchDarklyLoading,
    error,
  };
};
