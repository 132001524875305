import gql from "graphql-tag";

import GUIDE_FETCH_GUIDES_FRAGMENT from "~/fragments/GUIDE_FETCH_GUIDES_FRAGMENT";

const FETCH_GUIDES_QUERY = gql`
  query FetchGuides($input: GuidesInput!) {
    currentUserV2 {
      id
      organization {
        id
        guides(input: $input) {
          ...GuideFetchGuides
        }
      }
    }
  }
  ${GUIDE_FETCH_GUIDES_FRAGMENT}
`;

export default FETCH_GUIDES_QUERY;
