import _ from "lodash";
import React, { useEffect, useState } from "react";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";
import { useParams, useLocation } from "react-router";

import {
  Switch,
  FormGroup,
  Hidden,
  FormControlLabel,
  Grid,
  Link,
  Breadcrumbs,
  Button,
  TextField,
  Divider,
  Typography,
  makeStyles,
  createStyles,
  CircularProgress,
} from "@material-ui/core";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { useFlags } from "@resource/client-ffs";
import { FeatureFlagEnum } from "@resource/common";
import ActivateStageTemplateInstallationModal from "./ActivateStageTemplateInstallationModal/ActivateStageTemplateInstallationModal";
import greenhouseLogo from "~/icons/greenhouse-logo.svg";
import StageChangeJourneyStep from "./StageChangeJourneyStep";
import GuideStageTemplate from "./GuideStageTemplate";
import {
  GuideTemplateForEditing,
  GuideTemplateForEditingVariables,
  GuideTemplateForEditing_guideTemplate_stageTemplateInstallations as StageTemplateInstallation,
} from "~/schemaTypes";
import Loading from "~/components/Loading/Loading";
import sharedStyles from "~/styles/EditorWithPreview.module.scss";
import GuideTemplateModulePresenter from "~/components/GuideTemplateModulePresenter/GuideTemplateModulePresenter";
import GUIDE_TEMPLATE_FOR_EDITING from "./GUIDE_TEMPLATE_FOR_EDITING";
import { ResourceCustomTheme } from "~/styles/config";
import useAnalytics from "~/react-hooks/useAnalytics";

const UPDATE_GUIDE_TEMPLATE = gql`
  mutation UpdateGuideTemplate($input: UpdateGuideTemplateInput!) {
    updateGuideTemplate(input: $input) {
      success
      guideTemplate {
        id
        chatEnabled
        interviewProcessEnabled
        jobRoleNameOverride
      }
    }
  }
`;

type GuideTemplateEditorProps = Record<string, never>;

type GuideTemplateUpdate = Partial<{
  chatEnabled: boolean;
  interviewProcessEnabled: boolean;
}>;

const useEditorStyles = makeStyles((theme: ResourceCustomTheme) =>
  createStyles({
    switchLabel: {
      margin: 0,
      marginBottom: theme.spacing(1),
    },
    switch: {
      marginRight: theme.spacing(1),
    },
    greenhouseJobName: {
      marginLeft: "8px",
    },
    configHeader: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(4),
    },
    updateOverrideSpinner: {
      margin: "8px",
    },
    centered: {
      display: "flex",
      rjustifyContent: "center",
      marginTop: 100,
    },
    templateForm: {
      marginTop: theme.spacing(3),
    },
    journey: {
      marginTop: theme.spacing(3),
      position: "relative",
      width: "100%",
    },
    timeline: {
      width: 1,
      position: "absolute",
      top: 24,
      bottom: 20,
      left: 45,
      zIndex: 0,
      backgroundColor: theme.colors.KarlGray(800),
    },
    journeyStep: {
      width: "100%",
      height: 60,
      border: "1px solid gray",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
      marginTop: 60,
    },
  })
);

const GuideTemplateEditor: React.FC<GuideTemplateEditorProps> = () => {
  const history = useHistory();
  const [analytics] = useAnalytics();
  const { uuid } = useParams<{ uuid: string }>();
  const classes = useEditorStyles();
  const location = useLocation();
  const [showAddStageModal, setShowAddStageModal] = useState(false);
  const {
    [_.camelCase(FeatureFlagEnum.CandidateJourney)]: candidateJourneyFlag,
    [_.camelCase(
      FeatureFlagEnum.SimplifiedGuideTemplateCreation
    )]: simplifiedGuideTemplateCreationFlag,
    [_.camelCase(FeatureFlagEnum.RoleJourneysPage)]: roleJourneysPageFlag,
  } = useFlags();
  const [
    previewStageTemplateInstallationId,
    setPreviewStageTemplateInstallationId,
  ] = useState<string | undefined>();
  const [jobRoleNameOverride, setJobRoleNameOverride] = useState("");

  const { data, loading, error } = useQuery<
    GuideTemplateForEditing,
    GuideTemplateForEditingVariables
  >(GUIDE_TEMPLATE_FOR_EDITING, {
    variables: {
      input: {
        id: uuid,
      },
    },
  });
  const organization = data?.currentUserV2?.organization;
  const guideTemplate = data?.guideTemplate;
  const jobStages = guideTemplate?.jobRole?.greenhouseJobStages || [];
  const activatedStageTemplateInstallations = _.filter(
    guideTemplate?.stageTemplateInstallations,
    (sti) => !!sti.activatedAt
  );

  const [
    updateGuideTemplate,
    { loading: updateGuideTemplateLoading },
  ] = useMutation(UPDATE_GUIDE_TEMPLATE, {
    refetchQueries: ["OrgGuideTemplateData"],
  });

  const stageTemplates = organization?.stageTemplates || [];
  const emailTemplates = organization?.emailTemplates || [];
  const visibleStageTemplateInstallations = _.reject(
    activatedStageTemplateInstallations,
    { hidden: true }
  );
  const viewingStageTemplateInstallation = _.find(
    visibleStageTemplateInstallations,
    {
      id: previewStageTemplateInstallationId,
    }
  );

  useEffect(() => {
    setJobRoleNameOverride(guideTemplate?.jobRoleNameOverride || "");
  }, [guideTemplate]);

  useEffect(() => {
    if (!viewingStageTemplateInstallation) {
      setPreviewStageTemplateInstallationId(
        visibleStageTemplateInstallations[0]?.id
      );
    }
  }, [visibleStageTemplateInstallations, viewingStageTemplateInstallation]);

  const onSelectPreviewStageTemplateInstallation = (
    stageTemplateInstallation: StageTemplateInstallation
  ) => {
    setPreviewStageTemplateInstallationId(stageTemplateInstallation.id);
  };
  const handleSave = async (
    { chatEnabled, interviewProcessEnabled }: GuideTemplateUpdate = {
      chatEnabled: undefined,
      interviewProcessEnabled: undefined,
    }
  ) => {
    await updateGuideTemplate({
      variables: {
        input: {
          id: uuid,
          jobRoleNameOverride,
          chatEnabled,
          interviewProcessEnabled,
        },
      },
    });
  };

  const chatEnabled = guideTemplate?.chatEnabled;
  const interviewProcessEnabled = guideTemplate?.interviewProcessEnabled;

  if (error) {
    history.replace(roleJourneysPageFlag ? "/journeys" : "/templates/guides");
  }

  if (loading && !data) {
    return (
      <Grid container>
        <Loading />
      </Grid>
    );
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6}>
          {!roleJourneysPageFlag && (
            <Breadcrumbs separator="›">
              <Link
                color="inherit"
                component={RouterLink}
                to={{
                  pathname: roleJourneysPageFlag
                    ? "/journeys"
                    : "/templates/guides",
                  state: { from: location },
                }}
              >
                Templates
              </Link>
              <Typography color="textPrimary">{guideTemplate?.name}</Typography>
            </Breadcrumbs>
          )}
          <Typography variant="h3">
            {guideTemplate?.jobRoleNameOverride || guideTemplate?.name}
          </Typography>
          <div>
            <img src={greenhouseLogo} alt="" width="16px" height="16px" />
            <Typography
              className={classes.greenhouseJobName}
              component="span"
              variant="subtitle2"
            >
              {guideTemplate?.jobRole?.name} ({guideTemplate?.jobRole?.atsJobId}
              )
            </Typography>
          </div>
          <Grid container className={classes.templateForm}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                onBlur={() => {
                  analytics.track("Job Role Name Overriden");
                  handleSave();
                }}
                value={jobRoleNameOverride}
                onChange={(e) => setJobRoleNameOverride(e.target.value)}
                label="Override Role Display Name"
              />
            </Grid>
            <Hidden smDown>
              <Grid item md={1}>
                {updateGuideTemplateLoading && (
                  <div className={classes.updateOverrideSpinner}>
                    <CircularProgress />
                  </div>
                )}
              </Grid>
            </Hidden>
            <Grid item xs={12} md={5}>
              <FormGroup>
                <FormControlLabel
                  className={classes.switchLabel}
                  control={
                    <Switch
                      className={classes.switch}
                      color="primary"
                      checked={chatEnabled}
                      name="chat"
                      onChange={(e) => {
                        analytics.track("Chat Toggled", {
                          enabled: e.target.checked,
                        });
                        handleSave({
                          chatEnabled: e.target.checked,
                        });
                      }}
                    />
                  }
                  label="Enable Chat with candidates"
                />
                <FormControlLabel
                  className={classes.switchLabel}
                  control={
                    <Switch
                      className={classes.switch}
                      color="primary"
                      name="interviewProcess"
                      checked={interviewProcessEnabled}
                      onChange={(e) => {
                        analytics.track("Interview Process Toggled", {
                          enabled: e.target.checked,
                        });
                        handleSave({
                          interviewProcessEnabled: e.target.checked,
                        });
                      }}
                    />
                  }
                  label="Show the Interview Process module"
                />
              </FormGroup>
              <Grid />
            </Grid>
          </Grid>

          {candidateJourneyFlag ? (
            <>
              <Typography variant="h4">Role Journey</Typography>
              <div className={classes.journey}>
                <div className={classes.timeline} />
                {_.map(activatedStageTemplateInstallations, (installation) => (
                  <StageChangeJourneyStep
                    key={installation.id}
                    onExpand={() => {
                      if (!installation.hidden) {
                        onSelectPreviewStageTemplateInstallation(installation);
                      }
                    }}
                    emailTemplates={emailTemplates}
                    stageTemplates={stageTemplates}
                    stageTemplateInstallation={installation}
                  />
                ))}
              </div>
              {simplifiedGuideTemplateCreationFlag &&
                jobStages.length >
                  (activatedStageTemplateInstallations.length ?? 0) && (
                  <div className={classes.buttonContainer}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => setShowAddStageModal(true)}
                    >
                      Add Stage to Journey
                    </Button>
                  </div>
                )}
            </>
          ) : (
            <>
              <Typography variant="h4">Stages Configuration</Typography>
              {_.map(
                activatedStageTemplateInstallations,
                (stageTemplateInstallation, index) => (
                  <React.Fragment key={stageTemplateInstallation.id}>
                    {index !== 0 && <Divider />}
                    <GuideStageTemplate
                      stageTemplateInstallation={stageTemplateInstallation}
                      stageTemplates={stageTemplates}
                      onPreviewStageTemplateInstallation={
                        onSelectPreviewStageTemplateInstallation
                      }
                      viewingStageTemplateInstallation={
                        viewingStageTemplateInstallation
                      }
                    />
                  </React.Fragment>
                )
              )}
            </>
          )}
        </Grid>
        <Grid item xs={12} md={6} className={sharedStyles.preview}>
          {(() => {
            if (
              loading ||
              (visibleStageTemplateInstallations.length &&
                !viewingStageTemplateInstallation)
            ) {
              return <Loading />;
            }

            return viewingStageTemplateInstallation && organization ? (
              <GuideTemplateModulePresenter
                onSelectInstallation={(installation) =>
                  setPreviewStageTemplateInstallationId(installation.id)
                }
                stageTemplateInstallations={visibleStageTemplateInstallations}
                organization={organization}
                viewingStageTemplateInstallation={
                  viewingStageTemplateInstallation
                }
                roleNameOverride={
                  guideTemplate?.jobRoleNameOverride ||
                  guideTemplate?.jobRole?.name ||
                  undefined
                }
                interviewProcessEnabled={
                  guideTemplate?.interviewProcessEnabled || undefined
                }
              />
            ) : (
              <div className={classes.centered} />
            );
          })()}
        </Grid>
      </Grid>
      <ActivateStageTemplateInstallationModal
        guideTemplateId={uuid}
        open={showAddStageModal}
        onClose={() => setShowAddStageModal(false)}
        onCancel={() => setShowAddStageModal(false)}
      />
    </>
  );
};

export default GuideTemplateEditor;
