import useDependentScript from "./useDependentScript";

type DependentScriptReturnType = {
  nylas?: any;
};

const NylasSchedulerScript =
  "https://schedule.nylas.com/schedule-editor/v1.0/schedule-editor.js";

export default function useNlyas(): DependentScriptReturnType {
  const { isLoading, errorMsg } = useDependentScript(NylasSchedulerScript);

  if (isLoading || errorMsg) {
    return {};
  }

  return {
    nylas: window.nylas,
  };
}
