import gql from "graphql-tag";

export default gql`
  mutation UpdateOrganizationMission($input: UpdateOrganizationMissionInput!) {
    updateOrganizationMission(input: $input) {
      id
      missionStatement
    }
  }
`;
