import { gql } from "@apollo/client";

export const HASURA_REORDER_STAGE_TEMPLATE_MODULE_INSTALLATIONS = gql`
  mutation HasuraReorderStageTemplateModuleInstallations(
    $moduleIds: [uuid!]!
    $stageTemplateId: uuid!
  ) @hasura {
    reorderStageTemplateModuleInstallations(
      modules: $moduleIds
      stageTemplateId: $stageTemplateId
    ) {
      stageTemplate {
        id
        name
        stage_template_module_installations {
          id
          position
        }
      }
    }
  }
`;
