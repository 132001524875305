import _ from "lodash";
import cx from "classnames";
import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Typography, Fade, Fab, Button } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import gql from "graphql-tag";
import { useFlags } from "@resource/client-ffs";
import { FeatureFlagEnum } from "@resource/common";
import queryString from "query-string";
import TemplateCard from "../TemplateCard/TemplateCard";
import Loading from "~/components/Loading/Loading";
import useAnalytics from "~/react-hooks/useAnalytics";

import styles from "../Templates.module.scss";
import EmailTemplateModal from "./EmailTemplateModal/EmailTemplateModal";
import GET_ORG_EMAIL_TEMPLATES from "./GET_ORG_EMAIL_TEMPLATES";
import {
  GetOrgEmailTemplates,
  ArchiveEmailTemplate,
  ArchiveEmailTemplateVariables,
  UnarchiveEmailTemplate,
  UnarchiveEmailTemplateVariables,
} from "~/schemaTypes";
import { TemplateLabelEnum } from "../TemplatesEnum";
import { useFlashMessage } from "~/components/FlashMessage/FlashMessage";

type EmailTemplatesProps = Record<string, unknown>;

const EmailTemplates: React.FC<EmailTemplatesProps> = () => {
  const location = useLocation();
  const { new: createNew } = queryString.parse(location.search);
  const triggerNewModal = createNew === "true";
  const history = useHistory();
  if (triggerNewModal) {
    history.replace(location.pathname);
  }

  const {
    [_.camelCase(
      FeatureFlagEnum.AllowEmailTemplateArchive
    )]: allowEmailTemplateArchiveFlag,
  } = useFlags();
  const { setContent } = useFlashMessage();
  const [analytics] = useAnalytics();
  const [open, setOpen] = useState<boolean>(triggerNewModal);
  const [selectedEmailTemplateId, setSelectedEmailTemplateId] = useState<
    string | undefined
  >();
  const {
    data: organizationData,
    loading: orgDataLoading,
    error: orgDataError,
  } = useQuery<GetOrgEmailTemplates>(GET_ORG_EMAIL_TEMPLATES);

  const [archiveEmailTemplateMutate] = useMutation<
    ArchiveEmailTemplate,
    ArchiveEmailTemplateVariables
  >(gql`
    mutation ArchiveEmailTemplate($input: ArchiveEmailTemplateInput!) {
      archiveEmailTemplate(input: $input) {
        success
      }
    }
  `);

  const [unarchiveEmailTemplateMutate] = useMutation<
    UnarchiveEmailTemplate,
    UnarchiveEmailTemplateVariables
  >(gql`
    mutation UnarchiveEmailTemplate($input: UnarchiveEmailTemplateInput!) {
      unarchiveEmailTemplate(input: $input) {
        success
        emailTemplate {
          id
          name
          updatedAt
          createdBy
        }
      }
    }
  `);

  const unarchiveEmailTemplate = async (id: string): Promise<void> => {
    try {
      await unarchiveEmailTemplateMutate({
        variables: {
          input: {
            id,
          },
        },
        update(cache, { data: newData }) {
          if (newData?.unarchiveEmailTemplate?.success) {
            const data = cache.readQuery<GetOrgEmailTemplates>({
              query: GET_ORG_EMAIL_TEMPLATES,
            });
            if (
              data?.currentUserV2 &&
              newData.unarchiveEmailTemplate.emailTemplate
            ) {
              data.currentUserV2.organization.emailTemplates.unshift(
                newData.unarchiveEmailTemplate.emailTemplate
              );
              cache.writeQuery({
                query: GET_ORG_EMAIL_TEMPLATES,
                data,
              });
            }
          }
        },
      });
      if (analytics) {
        analytics.track("Email Template Unarchived");
      }
      setContent({
        content: "Email Template unarchived",
        severity: "success",
      });
    } catch (err) {
      setContent({
        content: "Could not unarchive Email Template",
        severity: "error",
      });
    }
  };

  const archiveEmailTemplate = async (id: string): Promise<void> => {
    await archiveEmailTemplateMutate({
      variables: {
        input: {
          id,
        },
      },
      update(cache, { data: newData }) {
        if (newData?.archiveEmailTemplate?.success) {
          const data = cache.readQuery<GetOrgEmailTemplates>({
            query: GET_ORG_EMAIL_TEMPLATES,
          });
          if (data?.currentUserV2) {
            data.currentUserV2.organization.emailTemplates = _.reject(
              data.currentUserV2.organization.emailTemplates,
              { id }
            );
            cache.writeQuery({
              query: GET_ORG_EMAIL_TEMPLATES,
              data,
            });
          }
        }
      },
    });
    if (analytics) {
      analytics.track("Email Template Archived");
    }
    const action = (
      <Button
        color="inherit"
        size="small"
        onClick={() => unarchiveEmailTemplate(id)}
      >
        UNDO
      </Button>
    );
    setContent({
      action,
      content: "Email Template Archived",
      severity: "success",
    });
  };

  useEffect(() => {
    analytics.page("Email Templates");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (orgDataLoading) {
    return <Loading />;
  }

  if (orgDataError) {
    return (
      <Typography variant="h4">
        There was an unexpected error loading your email templates, please
        refresh the page or contact support if the problem persists.
      </Typography>
    );
  }

  return (
    <>
      <Fade in timeout={1000}>
        <div className={cx(styles.cont)}>
          <div className={styles.headerCont}>
            <Typography className={styles.headerText} variant="h2">
              Email Templates
            </Typography>
            <Fab
              color="primary"
              aria-label="add"
              size="medium"
              onClick={(): void => {
                setSelectedEmailTemplateId(undefined);
                setOpen(true);
              }}
            >
              <FontAwesomeIcon icon={faPlus} size="lg" />
            </Fab>
          </div>
          <div className={cx(styles.templatesContainer)}>
            {_.map(
              organizationData?.currentUserV2?.organization?.emailTemplates,
              ({ id, name, updatedAt, createdBy }) => (
                <TemplateCard
                  key={id}
                  id={id}
                  name={name}
                  updatedAt={updatedAt}
                  createdBy={createdBy}
                  onArchive={
                    allowEmailTemplateArchiveFlag
                      ? () => archiveEmailTemplate(id)
                      : undefined
                  }
                  onClick={() => {
                    setOpen(true);
                    setSelectedEmailTemplateId(id);
                  }}
                  templateLabel={TemplateLabelEnum.Email}
                />
              )
            )}
          </div>
        </div>
      </Fade>
      <EmailTemplateModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        emailTemplateId={selectedEmailTemplateId}
      />
    </>
  );
};

export default EmailTemplates;
