import React, { FunctionComponent, MouseEvent } from "react";
import {
  Typography,
  Card,
  Link,
  CardProps,
  LinkProps,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons";

import { getValidUrls } from "~/utils";

import styles from "./ConferenceLinkCard.module.scss";

// Combine Link and Card propTypes to avoid typescript error
function CardWithLinkBase<C extends React.ElementType>(
  props: LinkProps<C, { component?: C }> & CardProps
) {
  return (
    <Card
      component={Link}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}

type TechScreenLinkCardProps = {
  techScreenLink: string;
};

const TechScreenLinkCard: FunctionComponent<TechScreenLinkCardProps> = ({
  techScreenLink,
}) => {
  const [link] = getValidUrls(techScreenLink);

  return (
    <CardWithLinkBase
      className={styles.card}
      href={link || undefined}
      underline="none"
      target="_blank"
      rel="noopener noreferrer"
      style={{
        boxShadow: link ? undefined : "none",
        border: link ? undefined : "solid 1px #f1f1f1",
      }}
      onClick={(evt: MouseEvent) => evt.stopPropagation()}
    >
      <div className={styles.secondaryFlex}>
        <Typography variant="h3" className={styles.emoji}>
          <span role="img" aria-label="Laptop Image">
            💻
          </span>
        </Typography>
        <Typography variant="body1" noWrap>
          {techScreenLink}
        </Typography>
      </div>
      {link && (
        <FontAwesomeIcon
          className={styles.icon}
          icon={faChevronRight}
          size="1x"
        />
      )}
    </CardWithLinkBase>
  );
};

export default TechScreenLinkCard;
