import React, { FunctionComponent, useState, useContext } from "react";
import cx from "classnames";
import { Dialog, Card, Typography } from "@material-ui/core";

import { AnalyticsContext } from "~/analytics";

import styles from "./InfoCard.module.scss";

export type InfoCardProps = {
  icon?: string;
  title?: string;
  description?: string;
  header?: string;
};

const InfoCard: FunctionComponent<InfoCardProps> = ({
  icon,
  title,
  description,
  header,
}) => {
  const [open, setOpen] = useState(false);
  const analytics = useContext(AnalyticsContext);

  return (
    <>
      <Card
        className={cx(
          styles.card,
          !description ? styles.cardWithoutDescription : "",
          !icon ? styles.cardWithoutIcon : ""
        )}
        key={title}
        onClick={(): void => {
          setOpen(true);
          analytics.track("Card Clicked", {
            sectionHeader: header,
            cardTitle: title,
          });
        }}
      >
        {icon && (
          <Typography className={styles.cardIcon} variant="h3">
            {icon}
          </Typography>
        )}
        {title && (
          <Typography className={styles.cardTitle} variant="h5">
            {title}
          </Typography>
        )}
        {description && (
          <Typography className={styles.cardDescription} variant="subtitle1">
            {description}
          </Typography>
        )}
      </Card>
      <Dialog
        className={styles.cardDialogBackground}
        onClose={(): void => setOpen(false)}
        open={open}
        aria-labelledby="simple-dialog-title"
        fullWidth
      >
        <div className={styles.cardDialog}>
          {icon && (
            <Typography className={styles.cardIcon} variant="h2">
              {icon}
            </Typography>
          )}
          {title && (
            <Typography className={styles.cardTitle} variant="h4">
              {title}
            </Typography>
          )}
          {description && (
            <Typography className={styles.cardDescription} variant="subtitle1">
              {description}
            </Typography>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default InfoCard;
