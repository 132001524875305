import _ from "lodash";
import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import cx from "classnames";
import {
  MenuItem,
  FormControlLabel,
  Checkbox,
  TextField,
  Typography,
  makeStyles,
  createStyles,
  Accordion,
  AccordionSummary,
  IconButton,
  Menu,
  AccordionDetails,
} from "@material-ui/core";
import gql from "graphql-tag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faEyeSlash,
  faEllipsisV,
} from "@fortawesome/pro-light-svg-icons";
import { useFlags } from "@resource/client-ffs";
import { FeatureFlagEnum } from "@resource/common";
import DestructiveActionPrompt from "~/components/DestructiveActionPrompt";
import HASURA_UPDATE_STAGE_TEMPLATE_INSTALLATION from "./HASURA_UPDATE_STAGE_TEMPLATE_INSTALLATION@hasura";
import guideLogo from "~/assets/icons/logo-icon-dark.svg";
import {
  UpdateStageTemplateInstallationForJourneyStep,
  UpdateStageTemplateInstallationForJourneyStepVariables,
  GuideTemplateForEditing_guideTemplate_stageTemplateInstallations as StageTemplateInstallation,
  GuideTemplateForEditing_currentUserV2_organization_stageTemplates as StageTemplate,
  GuideTemplateForEditing_currentUserV2_organization_emailTemplates as EmailTemplate,
  GuideTemplateForEditing_guideTemplate_stageTemplateInstallations_afterSteps_TemplateNoteJourneyStep as NoteJourneyStepType,
  GuideTemplateForEditing_guideTemplate_stageTemplateInstallations_afterSteps_TemplateActionJourneyStep as ActionJourneyStepType,
  JourneyStepEnum,
} from "~/schemaTypes";
import {
  EditStageTemplateInstallationForJourney,
  EditStageTemplateInstallationForJourneyVariables,
} from "~/schemaTypesHasura";
import ActionJourneyStep from "./ActionJourneyStep";
import NoteJourneyStep from "./NoteJourneyStep";
import InterviewKit from "./InterviewKit/InterviewKit";
import useAnalytics from "~/react-hooks/useAnalytics";
import { ResourceCustomTheme } from "~/styles/config";
import TimelineSpacer from "./TimelineSpacer";

export const UPDATE_STAGE_TEMPLATE_INSTALLATION = gql`
  mutation UpdateStageTemplateInstallationForJourneyStep(
    $input: UpdateStageTemplateInstallationInput!
  ) {
    updateStageTemplateInstallation(input: $input) {
      success
      stageTemplateInstallation {
        id
        stageTemplate {
          id
          name
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme: ResourceCustomTheme) =>
  createStyles({
    root: {
      marginTop: 4,
      marginBottom: 4,
    },
    disabled: {
      "& *": {
        ...theme.mixins.disabledStyles,
      },
    },
    stageCard: {
      marginBottom: "4px !important",
    },
    greenhouseJobName: {
      marginLeft: "8px",
    },
    field: {
      marginTop: theme.spacing(2),
      flexGrow: 1,
      maxWidth: 300,
    },
    cardSummary: {
      width: "100%",
      display: "flex",
      alignItems: "center",
    },
    cardIcon: {
      display: "flex",
      marginRight: theme.spacing(1),
    },
    cardText: {
      flexGrow: 1,
    },
    cardDetails: {
      display: "block",
    },
    trashIcon: {
      color: theme.colors.ElectricRed(),
    },
    mainFieldWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    action: {
      flexShrink: 0,
    },
    metadata: {},
    menuIcon: {},
  })
);

type StageChangeJourneyStepProps = {
  stageTemplateInstallation: StageTemplateInstallation;
  stageTemplates: StageTemplate[];
  emailTemplates: EmailTemplate[];
  onExpand?(): void;
};

const StageChangeJourneyStep: React.FC<StageChangeJourneyStepProps> = ({
  stageTemplateInstallation,
  stageTemplates,
  emailTemplates,
  onExpand,
}) => {
  const [analytics] = useAnalytics();
  const classes = useStyles();
  const [confirmRemoveOpen, setConfirmRemoveOpen] = useState(false);
  const {
    [_.camelCase(FeatureFlagEnum.InterviewKit)]: interviewKitFlag,
    [_.camelCase(FeatureFlagEnum.HideStage)]: hideStageFlag,
  } = useFlags();
  const [expanded, setExpanded] = useState<boolean>(false);
  const [addStepLoading, setAddStepLoading] = useState<boolean>();
  const [
    actionMenuAnchorEl,
    setActionMenuAnchorEl,
  ] = useState<HTMLButtonElement | null>(null);

  const [hasuraUpdateStageTemplateInstallation] = useMutation<
    EditStageTemplateInstallationForJourney,
    EditStageTemplateInstallationForJourneyVariables
  >(HASURA_UPDATE_STAGE_TEMPLATE_INSTALLATION, {
    refetchQueries: ["GetOrgGuideTemplateData", "GuideTemplateForEditing"],
  });

  const [updateStageTemplateInstallation] = useMutation<
    UpdateStageTemplateInstallationForJourneyStep,
    UpdateStageTemplateInstallationForJourneyStepVariables
  >(UPDATE_STAGE_TEMPLATE_INSTALLATION, {
    refetchQueries: ["GetOrgGuideTemplateData", "GuideTemplateForEditing"],
  });

  const [displayName, setDisplayName] = useState(
    stageTemplateInstallation.displayName
  );
  const { hidden } = stageTemplateInstallation;
  const stageTemplateId = stageTemplateInstallation.stageTemplate.id;

  const handleSave = async (
    input: Partial<{
      displayName: string;
      stageTemplateId: string;
      hidden: boolean;
    }>
  ) => {
    await updateStageTemplateInstallation({
      variables: {
        input: {
          id: stageTemplateInstallation.id,
          ...input,
        },
      },
    });
  };
  const handleNewStep = async () => {
    setAddStepLoading(true);
    try {
      await updateStageTemplateInstallation({
        variables: {
          input: {
            id: stageTemplateInstallation.id,
            afterSteps: [
              {
                actionStep: {
                  emailToCandidate: {
                    cc: [],
                    bcc: [],
                  },
                },
              },
            ],
          },
        },
      });
    } finally {
      setAddStepLoading(false);
    }
  };

  const handleUpdateEmailTemplate = async (
    emailTemplateId: string
  ): Promise<void> => {
    await updateStageTemplateInstallation({
      variables: {
        input: {
          id: stageTemplateInstallation.id,
          afterSteps: [
            {
              actionStep: {
                emailToCandidate: {
                  cc: [],
                  bcc: [],
                  emailTemplateId,
                },
              },
            },
          ],
        },
      },
    });
  };

  const handleRemoveEmailTemplate = async () => {
    await updateStageTemplateInstallation({
      variables: {
        input: {
          id: stageTemplateInstallation.id,
          afterSteps: [],
        },
      },
    });
  };

  const onRemoveStage = async () => {
    await hasuraUpdateStageTemplateInstallation({
      variables: {
        pk_columns: {
          id: stageTemplateInstallation.id,
        },
        _set: {
          activatedAt: null,
        },
      },
    });
  };

  const hasEmailStep = !!_.find(stageTemplateInstallation?.afterSteps, {
    type: JourneyStepEnum.ACTION,
  });

  return (
    <div className={classes.root}>
      <Accordion
        className={classes.stageCard}
        expanded={expanded}
        onChange={() => {
          setExpanded((prevExpanded) => {
            if (!prevExpanded && onExpand) {
              onExpand();
            }
            return !prevExpanded;
          });
        }}
      >
        <AccordionSummary>
          <div
            className={cx(classes.cardSummary, {
              [classes.disabled]: stageTemplateInstallation.hidden,
            })}
          >
            <div className={classes.cardIcon}>
              {stageTemplateInstallation.hidden ? (
                <Typography variant="body1">
                  <FontAwesomeIcon icon={faEyeSlash} size="1x" />
                </Typography>
              ) : (
                <img src={guideLogo} alt="" width="16px" height="16px" />
              )}
            </div>
            <Typography className={classes.cardText} variant="body1">
              <span style={{ textDecoration: "underline" }}>
                {stageTemplateInstallation.hidden ? "Hide" : "Trigger"}
              </span>{" "}
              <span style={{ textDecoration: "underline" }}>
                {stageTemplateInstallation.displayName}
              </span>{" "}
              Stage
            </Typography>
            <Typography variant="body1">
              <FontAwesomeIcon
                icon={expanded ? faChevronUp : faChevronDown}
                size="1x"
              />
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.cardDetails}>
          <div className={classes.mainFieldWrapper}>
            <TextField
              className={classes.field}
              label="Override Stage Display Name"
              value={displayName}
              onBlur={() => {
                analytics.track("Stage Name Overriden");
                handleSave({
                  displayName,
                });
              }}
              onChange={(e) => {
                setDisplayName(e.target.value);
              }}
            />

            <IconButton
              className={classes.action}
              onClick={(event) => {
                setActionMenuAnchorEl(event.currentTarget);
              }}
            >
              <FontAwesomeIcon
                icon={faEllipsisV}
                size="1x"
                className={classes.menuIcon}
              />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={actionMenuAnchorEl}
              keepMounted
              open={Boolean(actionMenuAnchorEl)}
              onClose={() => {
                setActionMenuAnchorEl(null);
              }}
            >
              <MenuItem
                onClick={() => {
                  setConfirmRemoveOpen(true);
                  setActionMenuAnchorEl(null);
                }}
              >
                Remove stage from journey
              </MenuItem>
            </Menu>
          </div>
          <div className={classes.mainFieldWrapper}>
            <TextField
              className={classes.field}
              defaultValue={stageTemplateId}
              select
              fullWidth
              label="Base Stage Template"
              onChange={(e) => {
                analytics.track("Stage Template Switched");
                handleSave({
                  stageTemplateId: e.target.value,
                });
              }}
              variant="outlined"
            >
              {_.map(stageTemplates, ({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </TextField>
          </div>
          {hideStageFlag && (
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  defaultChecked={hidden}
                  onChange={(e) => {
                    analytics.track("Stage Visibility Toggled", {
                      hidden: e.target.checked,
                    });
                    handleSave({
                      hidden: e.target.checked,
                    });
                  }}
                />
              }
              label="Hide this Stage from candidates"
            />
          )}
          {interviewKitFlag &&
            (stageTemplateInstallation.eventTemplates.length ? (
              <InterviewKit
                interviews={stageTemplateInstallation.eventTemplates}
              />
            ) : (
              <Typography>No synced Interviews from Greenhouse</Typography>
            ))}
        </AccordionDetails>
      </Accordion>
      {_.map(stageTemplateInstallation?.afterSteps, (step, idx) => {
        switch (step.type) {
          case JourneyStepEnum.NOTE:
            return (
              <NoteJourneyStep
                key={`${step.type}-${idx}`}
                step={step as NoteJourneyStepType}
              />
            );
          case JourneyStepEnum.ACTION:
            return (
              <ActionJourneyStep
                key={`${step.type}-${idx}`}
                stageTemplateInstallationId={stageTemplateInstallation.id}
                step={step as ActionJourneyStepType}
                emailTemplates={emailTemplates}
                onUpdateEmailTemplate={(emailTemplateId: string): void => {
                  handleUpdateEmailTemplate(emailTemplateId);
                }}
                onRemoveEmailTemplate={(): void => {
                  handleRemoveEmailTemplate();
                }}
              />
            );
          default:
            throw new Error(`Unsupported journey step type: ${step.type}`);
        }
      })}
      <TimelineSpacer
        onAdd={() => {
          analytics.track("Email Action Added", {
            forStage: stageTemplateInstallation.displayName,
          });
          handleNewStep();
        }}
        addLabel={`Add an Email Action for ${stageTemplateInstallation.displayName}`}
        loading={addStepLoading}
        showAdd={!hasEmailStep}
      />
      <DestructiveActionPrompt
        open={confirmRemoveOpen}
        onAccept={onRemoveStage}
        onClose={() => setConfirmRemoveOpen(false)}
        bodyText="Removing this stage from your journey will remove it in all candidate guides for this role."
      />
    </div>
  );
};

export default StageChangeJourneyStep;
