import React, { FunctionComponent, MouseEvent } from "react";
import {
  Typography,
  Card,
  Link,
  CardProps,
  LinkProps,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons";
import zoomLogo from "~/icons/zoom.svg";
import hangoutsLogo from "~/icons/google-hangouts.svg";
import meetLogo from "~/icons/google-meet.svg";

import { getValidUrls } from "~/utils";

import styles from "./ConferenceLinkCard.module.scss";

// Combine Link and Card propTypes to avoid typescript error
function CardWithLinkBase<C extends React.ElementType>(
  props: LinkProps<C, { component?: C }> & CardProps
) {
  return (
    <Card
      component={Link}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}

type ConferenceLinkCardProps = {
  instructions: string;
  disabled?: boolean;
};

enum LinkType {
  Hangouts,
  Meet,
  Zoom,
  None,
}

const getLinkType = ({ link }: { link: string }): LinkType => {
  if (link.includes("zoom.us")) return LinkType.Zoom;
  if (link.includes("hangouts.google.com")) return LinkType.Hangouts;
  if (link.includes("meet.google.com")) return LinkType.Meet;
  return LinkType.None;
};

const LinkIcon: React.FC<{ link: string }> = ({ link }) => {
  const linkType = getLinkType({ link });
  switch (linkType) {
    case LinkType.Hangouts:
      return <img alt="" className={styles.linkIcon} src={hangoutsLogo} />;
    case LinkType.Zoom:
      return <img alt="" className={styles.linkIcon} src={zoomLogo} />;
    case LinkType.Meet:
      return <img alt="" className={styles.linkIcon} src={meetLogo} />;
    default:
      return (
        <span role="img" aria-label="Laptop Image">
          💻
        </span>
      );
  }
};

const ConferenceLinkCard: FunctionComponent<ConferenceLinkCardProps> = ({
  instructions,
  disabled = false,
}) => {
  const [link] = getValidUrls(instructions);

  return (
    <CardWithLinkBase
      className={styles.card}
      href={link || undefined}
      underline="none"
      target="_blank"
      rel="noopener noreferrer"
      style={{
        boxShadow: link ? undefined : "none",
        border: link ? undefined : "solid 1px #f1f1f1",
      }}
      onClick={(evt: MouseEvent) => {
        if (disabled) {
          evt.preventDefault();
        } else {
          evt.stopPropagation();
        }
      }}
    >
      <div className={styles.secondaryFlex}>
        <Typography variant="h3" className={styles.emoji}>
          {link ? (
            <LinkIcon link={link} />
          ) : (
            <span role="img" aria-label="Phone Image">
              ☎️
            </span>
          )}
        </Typography>
        <Typography variant="body1" noWrap>
          {instructions}
        </Typography>
      </div>
      {link && (
        <FontAwesomeIcon
          className={styles.icon}
          icon={faChevronRight}
          size="1x"
        />
      )}
    </CardWithLinkBase>
  );
};

export default ConferenceLinkCard;
