import { useState } from "react";
import { useMutation } from "@apollo/client";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UseDebouncedMutationReturn = [(newOptions: any) => Promise<any>, any];

const useDebouncedMutation = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  query: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any,
  timeout: number
): UseDebouncedMutationReturn => {
  const [timeoutRef, setTimeoutRef] = useState<NodeJS.Timer>();
  const [mutate, mutationData] = useMutation(query, options);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const debounced = (newOptions: any) =>
    new Promise((resolve) => {
      if (timeoutRef) {
        clearTimeout(timeoutRef);
      }
      setTimeoutRef(
        setTimeout(() => {
          resolve(mutate(newOptions));
        }, timeout)
      );
    });

  return [debounced, mutationData];
};

export default useDebouncedMutation;
