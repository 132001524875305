import _ from "lodash";
import React from "react";
import { TextField, Typography, makeStyles } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Controller, Control, FieldErrors } from "react-hook-form";
import emailValidator from "email-validator";
import { ErrorMessage } from "@hookform/error-message";
import { ResourceCustomTheme } from "~/styles/config";

const useStyles = makeStyles<ResourceCustomTheme>((_theme) => ({
  root: {},
}));

type EmailSelectProps = {
  control: Control;
  errors: FieldErrors;
  name: string;
  label?: string;
  maxEmails?: number;
  className?: string;
  defaultValues?: string[];
};

type EmailValue = {
  createdEmail?: string;
  email: string;
};

const EmailSelect: React.FC<EmailSelectProps> = ({
  errors,
  defaultValues,
  control,
  name,
  maxEmails,
  className = "",
  label = "Emails",
}) => {
  const classes = useStyles();

  return (
    <div className={className}>
      <Controller
        name={name}
        control={control}
        rules={{
          validate: (emails) => {
            return _.every(emails, ({ email }) => {
              return emailValidator.validate(email);
            });
          },
        }}
        render={(props) => (
          <Autocomplete<EmailValue, true, true, false>
            {...props}
            {...(maxEmails ? { limitTags: maxEmails } : {})}
            multiple
            defaultValue={_.map(defaultValues, (email) => ({ email }))}
            className={classes.input}
            options={[]}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  autoComplete="off"
                  label={label}
                  placeholder="Start typing to add an email"
                  variant="outlined"
                  fullWidth
                />
              );
            }}
            ChipProps={{
              color: "primary",
            }}
            getOptionLabel={(option) => option.email}
            renderOption={(option) => {
              return <span>{option.createdEmail || option.email}</span>;
            }}
            filterOptions={(options, params) => {
              if (params.inputValue !== "") {
                return [
                  {
                    createdEmail: params.inputValue,
                    email: `Add "${params.inputValue}"`,
                  },
                ];
              }

              return [];
            }}
            onChange={(_event, newValues) => {
              const isCreated = (v: EmailValue) => !!v.createdEmail;
              const createdEmail = _.find(newValues, isCreated)?.createdEmail;
              if (createdEmail) {
                props.onChange(
                  _.chain(newValues)
                    .reject(isCreated)
                    .concat({
                      email: createdEmail,
                    })
                    .value()
                );
              } else {
                props.onChange(newValues);
              }

              return null;
            }}
          />
        )}
      />
      <Typography variant="subtitle1" color="error">
        <ErrorMessage
          errors={errors}
          name={name}
          message="Please enter valid email addresses"
        />
      </Typography>
    </div>
  );
};

export default EmailSelect;
