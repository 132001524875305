import gql from "graphql-tag";

const BulkEditStageTemplateInstallations = gql`
  mutation BulkEditStageTemplateInstallations(
    $where: stage_template_installation_bool_exp!
    $_set: stage_template_installation_set_input
  ) @hasura {
    update_stage_template_installation(where: $where, _set: $_set) {
      returning {
        id
        activatedAt
        journeyMetadata
        displayName
        hidden
        stage_template {
          id
        }
      }
    }
  }
`;

export default BulkEditStageTemplateInstallations;
