import React, {
  useState,
  FunctionComponent,
  useContext,
  useEffect,
} from "react";
import cx from "classnames";
import { Card, Typography } from "@material-ui/core";
import { useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import {
  EmojiStep,
  WhatWentWellStep,
  WhatCouldBeBetterStep,
  ThankYouStep,
  FeedbackStepEnum,
} from "./steps";
import { Option } from "./steps/EmojiStep/options";
import { AnalyticsContext } from "~/analytics";
import DescriptionDivider from "./DescriptionDivider";
import {
  CurrentUserSignedIn,
  SubmitFeedbackForStage,
  SubmitFeedbackForStageVariables,
} from "~/schemaTypes";
import styles from "./feedback.module.scss";

type ControllerProps = {
  stageId: string;
  dismissFeedback(): void;
  isDemo?: boolean;
  isSample?: boolean;
};

const Controller: FunctionComponent<ControllerProps> = ({
  dismissFeedback,
  isDemo = false,
  isSample = false,
  stageId,
}) => {
  const analytics = useContext(AnalyticsContext);
  useEffect(() => {
    analytics.track("Controller Module Loaded");
  });
  const [currentStep, setCurrentStep] = useState<FeedbackStepEnum>(
    FeedbackStepEnum.EmojiStep
  );
  const [selectedEmoji, setSelectedEmoji] = useState<string | undefined>();
  const [descriptionText, setDescriptionText] = useState<string | null>(null);

  const [submitFeedback] = useMutation<
    SubmitFeedbackForStage,
    SubmitFeedbackForStageVariables
  >(
    gql`
      mutation SubmitFeedbackForStage($input: SubmitCandidateFeedbackInput!) {
        submitCandidateFeedback(input: $input) {
          id
          data
        }
      }
    `,
    {
      refetchQueries: ["FetchGuide"],
    }
  );
  const { data: { currentUserV2 = null } = {}, loading } = useQuery<
    CurrentUserSignedIn
  >(gql`
    query CurrentUserSignedIn {
      currentUserV2 {
        id
      }
    }
  `);
  const isSignedIn = !!(!loading && currentUserV2 && currentUserV2.id);
  const finish = async (
    freeform: string,
    categories: string[]
  ): Promise<void> => {
    try {
      if (!isSignedIn && !isDemo) {
        await submitFeedback({
          variables: {
            input: {
              stageId,
              data: {
                emoji: selectedEmoji,
                freeform,
                categories,
              },
            },
          },
        });
      }
      setCurrentStep(FeedbackStepEnum.ThankYouStep);
    } catch (err) {
      console.log("err: ", err);
    }
  };

  return (
    <Card
      className={cx(styles.container, {
        [styles.inGuide]: isSample,
      })}
    >
      {((): React.ReactNode | null => {
        switch (currentStep) {
          case FeedbackStepEnum.EmojiStep:
            return (
              <EmojiStep
                setDescriptionText={setDescriptionText}
                onOptionSelected={(option: Option): void => {
                  setSelectedEmoji(option.emoji);
                  setDescriptionText(null);
                  setCurrentStep(
                    option.value >= 2
                      ? FeedbackStepEnum.WhatWentWellStep
                      : FeedbackStepEnum.WhatCouldBeBetterStep
                  );
                }}
              />
            );
          case FeedbackStepEnum.WhatWentWellStep:
            return (
              <WhatWentWellStep
                onSubmitDetailedFeedback={(freeform, categories): void => {
                  finish(freeform, categories);
                }}
              />
            );
          case FeedbackStepEnum.WhatCouldBeBetterStep:
            return (
              <WhatCouldBeBetterStep
                onSubmitDetailedFeedback={(freeform, categories): void => {
                  finish(freeform, categories);
                }}
              />
            );
          case FeedbackStepEnum.ThankYouStep:
            return (
              <ThankYouStep
                onClose={
                  isSample
                    ? (): void => {
                        setCurrentStep(FeedbackStepEnum.EmojiStep);
                      }
                    : dismissFeedback
                }
                isSignedIn={isSignedIn}
              />
            );
          default:
            return null;
        }
      })()}
      {currentStep !== FeedbackStepEnum.ThankYouStep && (
        <>
          <DescriptionDivider text={descriptionText} />
          <Typography variant="subtitle2" className={styles.anonymousText}>
            Don’t worry - your feedback is anonymous and safe with us. It is
            aggregated to protect your identity and won’t be shared until after
            the hiring decision.
          </Typography>
        </>
      )}
    </Card>
  );
};

export default Controller;
