import _ from "lodash";
import React from "react";
import {
  Typography,
  Tooltip,
  makeStyles,
  Table,
  TableCell,
  TableRow,
  TableBody,
} from "@material-ui/core";
import { useQuery } from "@apollo/client";
import StageTemplateContentPreview from "~/components/StageTemplateContentPreview";
import {
  GetStageTemplateContent,
  GetStageTemplateContentVariables,
  GetEmailTemplateContent,
  GetEmailTemplateContentVariables,
} from "~/schemaTypesHasura";
import { ResourceCustomTheme } from "~/styles/config";
import HASURA_GET_STAGE_TEMPLATE_CONTENT from "./HASURA_GET_STAGE_TEMPLATE_CONTENT@hasura";
import HASURA_GET_EMAIL_TEMPLATE_CONTENT from "./HASURA_GET_EMAIL_TEMPLATE_CONTENT@hasura";

import { StageEditsStateType, BulkStageEditMode } from "./types";

const useStyles = makeStyles((theme: ResourceCustomTheme) => ({
  root: {
    textAlign: "center",
  },
  table: {
    width: 600,
    margin: `${theme.spacing(3)}px auto`,
  },
  row: {
    borderBottom: 0,
  },
  templateName: {
    display: "flex",
    alignItems: "center",
  },
  labelCell: {
    width: 260,
    textAlign: "right",
  },
  labelContent: {},
  previewIcon: {
    marginLeft: 4,
  },
  context: {
    width: "100%",
    textAlign: "center",
    maxWidth: 440,
    margin: "0px auto",
  },
  emphasize: {
    color: theme.colors.RoyalPurple(),
  },
  subtext: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    color: theme.colors.MidnightBlue(300),
  },
  light: {
    color: theme.colors.MidnightBlue(200),
  },
}));

const EmailActionPreview: React.FC<{
  cc: string[];
  bcc: string[];
  name: string;
  mode: BulkStageEditMode;
}> = ({ cc, bcc, name, mode }) => {
  const classes = useStyles();

  return (
    <TableRow className={classes.row}>
      <TableCell className={classes.labelCell}>
        {mode === BulkStageEditMode.Edit
          ? "Editing Email Action to"
          : "Email Action"}
        :
      </TableCell>
      <TableCell className={classes.labelContent}>
        <Typography variant="body1">
          <strong>{name}</strong>&nbsp;Template
        </Typography>
        {!!cc.length && (
          <Typography variant="subtitle2">CC: {_.join(cc, ", ")}</Typography>
        )}
        {!!bcc.length && (
          <Typography variant="subtitle2">BCC: {_.join(bcc, ", ")}</Typography>
        )}
      </TableCell>
    </TableRow>
  );
};

const StageTemplatePreview: React.FC<{
  name: string;
  installations: {
    id: string;
    stage_module: {
      type: string;
    };
  }[];
  mode: BulkStageEditMode;
}> = ({ name, installations, mode }) => {
  const classes = useStyles();
  return (
    <TableRow className={classes.row}>
      <TableCell className={classes.labelCell}>
        {mode === BulkStageEditMode.Edit
          ? "Editing Stage Template to"
          : "Stage Template"}
        :
      </TableCell>
      <TableCell className={classes.labelContent}>
        <Tooltip
          title={<StageTemplateContentPreview installations={installations} />}
        >
          <Typography variant="body1" className={classes.templateName}>
            <strong>{name}</strong>&nbsp;Template
          </Typography>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

const ConfirmStageEdits: React.FC<{
  data: StageEditsStateType;
  mode: BulkStageEditMode;
}> = ({ data, mode }) => {
  const classes = useStyles();

  const stageTemplateId = data.stageTemplate?.id;
  const { data: stageTemplateData } = useQuery<
    GetStageTemplateContent,
    GetStageTemplateContentVariables
  >(HASURA_GET_STAGE_TEMPLATE_CONTENT, {
    variables: { id: stageTemplateId },
    skip: !stageTemplateId,
  });

  const stageTemplate = _.first(stageTemplateData?.stage_template);
  const installations = stageTemplate?.stage_template_module_installations;
  const stageTemplateName = stageTemplate?.name;

  const emailTemplateId = data.emailAction?.id;
  const { data: emailTemplateData } = useQuery<
    GetEmailTemplateContent,
    GetEmailTemplateContentVariables
  >(HASURA_GET_EMAIL_TEMPLATE_CONTENT, {
    variables: { id: emailTemplateId },
    skip: !emailTemplateId,
  });

  const emailTemplateName = _.first(emailTemplateData?.email_template_v2)?.name;

  return (
    <div className={classes.root}>
      <Table className={classes.table}>
        <TableBody>
          {mode === BulkStageEditMode.Remove && (
            <TableRow className={classes.row}>
              <TableCell className={classes.labelCell}>Action:</TableCell>
              <TableCell className={classes.labelContent}>
                <Typography variant="body1">
                  <strong>Remove stages from candidate journeys</strong>
                </Typography>
              </TableCell>
            </TableRow>
          )}
          {_.map(_.keys(data), (key) => {
            let rowComponent;
            switch (key) {
              case "stageTemplate":
                rowComponent = data.stageTemplate ? (
                  <StageTemplatePreview
                    mode={mode}
                    name={stageTemplateName || ""}
                    installations={installations || []}
                  />
                ) : null;
                break;
              case "emailAction":
                rowComponent = data.emailAction ? (
                  <EmailActionPreview
                    mode={mode}
                    cc={data.emailAction.cc}
                    bcc={data.emailAction.bcc}
                    name={emailTemplateName || ""}
                  />
                ) : null;
                break;
              case "stageNameOverride":
                rowComponent = data.stageNameOverride ? (
                  <TableRow className={classes.row}>
                    <TableCell className={classes.labelCell}>
                      {mode === BulkStageEditMode.Edit
                        ? "Editing Stage display name to"
                        : "Stage display name"}
                      :
                    </TableCell>
                    <TableCell className={classes.labelContent}>
                      <Typography variant="body1">
                        <strong>{data.stageNameOverride.displayName}</strong>
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : null;
                break;
              default:
                break;
            }

            return <>{rowComponent}</>;
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default ConfirmStageEdits;
