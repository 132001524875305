import gql from "graphql-tag";

const UpdateEventTemplate = gql`
  mutation UpdateEventTemplateForInterviewKit(
    $eventTemplateId: uuid!
    $eventHidden: Boolean
    $eventTitle: String
    $eventDescription: String
  ) @hasura {
    update_event_template_by_pk(
      pk_columns: { id: $eventTemplateId }
      _set: {
        hidden: $eventHidden
        title: $eventTitle
        description: $eventDescription
      }
    ) {
      id
      hidden
      title
      description
    }
  }
`;

export default UpdateEventTemplate;
