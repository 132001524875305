import React, { FunctionComponent, useState } from "react";
import {
  Typography,
  Card,
  Dialog,
  Button,
  TextField,
  Divider,
  useTheme,
  Fab,
  makeStyles,
} from "@material-ui/core";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import _ from "lodash";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import {
  UpdateLogisticForOrganization,
  UpdateLogisticForOrganizationVariables,
  DeleteLogisticForOrganization,
  DeleteLogisticForOrganizationVariables,
  CreateLogisticForOrganization,
  CreateLogisticForOrganizationVariables,
  FetchCompanyData_currentUserV2_organization_customLogistics as CustomLogistic,
} from "~/schemaTypes";
import useAnalytics from "~/react-hooks/useAnalytics";
import DialogTitle from "~/components/Dialog/DialogTitle/DialogTitle";

const useStyles = makeStyles({
  root: {},
  logisticContainer: {},
  logisticCardsCont: {
    marginTop: 20,
    display: "flex",
    flexWrap: "wrap",
  },
  logisticCard: {
    marginBottom: 20,
    width: 200,
    height: 300,
    padding: 20,
    cursor: "pointer",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginRight: 20,
    "&": {
      position: "relative",
    },
    "&:after": {
      content: '""',
      position: "absolute",
      height: 60,
      width: "100%",
      bottom: 0,
      left: 0,
      borderRadius: 5,
      background:
        "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 50%, #ffffff 100%)",
    },
  },
  cardHeaderText: {
    marginTop: 0,
  },
  description: {
    overflowWrap: "anywhere",
  },
  headerCont: {
    display: "flex",
  },
  text: {
    margin: 0,
    marginRight: 20,
  },
  dialogCont: {
    padding: 30,
    display: "flex",
    flexDirection: "column",
    "& > *:not(:last-child)": {
      marginBottom: 30,
    },
  },
  headerText: {
    marginTop: 0,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    "button:not(:last-child)": {
      marginRight: 10,
    },
    button: {
      minWidth: 180,
    },
  },
});

const CREATE_LOGISTIC_MUTATION = gql`
  mutation CreateLogisticForOrganization(
    $input: CreateLogisticForOrganizationInput!
  ) {
    createLogisticForOrganization(input: $input) {
      success
      organization {
        id
        customLogistics {
          id
          emoji
          title
          description
        }
      }
    }
  }
`;

const UPDATE_CUSTOM_LOGISTIC_MUTATION = gql`
  mutation UpdateLogisticForOrganization(
    $input: UpdateLogisticForOrganizationInput!
  ) {
    updateLogisticForOrganization(input: $input) {
      success
      customLogistic {
        id
        emoji
        title
        description
      }
    }
  }
`;

const DELETE_CUSTOM_LOGISTIC_MUTATION = gql`
  mutation DeleteLogisticForOrganization(
    $input: DeleteLogisticForOrganizationInput!
  ) {
    deleteLogisticForOrganization(input: $input) {
      success
      organization {
        id
        customLogistics {
          id
          emoji
          title
          description
        }
      }
    }
  }
`;

type CustomLogisticsProps = {
  customLogistics: CustomLogistic[];
};

type NewLogistic = {
  id: string;
  emoji: string;
  title: string;
  description: string;
};

const CustomLogistics: FunctionComponent<CustomLogisticsProps> = ({
  customLogistics,
}) => {
  const classes = useStyles();
  const [analytics] = useAnalytics();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [logistic, setLogistic] = useState<NewLogistic>({
    id: "",
    emoji: "",
    title: "",
    description: "",
  });
  const [createLogistic] = useMutation<
    CreateLogisticForOrganization,
    CreateLogisticForOrganizationVariables
  >(CREATE_LOGISTIC_MUTATION);
  const [updateLogistic] = useMutation<
    UpdateLogisticForOrganization,
    UpdateLogisticForOrganizationVariables
  >(UPDATE_CUSTOM_LOGISTIC_MUTATION);
  const [deleteLogistic] = useMutation<
    DeleteLogisticForOrganization,
    DeleteLogisticForOrganizationVariables
  >(DELETE_CUSTOM_LOGISTIC_MUTATION);

  const handleLogisticCardClick = ({
    id,
    emoji,
    title,
    description,
  }: {
    id: string;
    emoji: string;
    title: string;
    description: string | null;
  }): void => {
    if (analytics) {
      analytics.track("Company Page Logistic Card Clicked");
    }
    setLogistic({
      id,
      emoji,
      title,
      description: description || "",
    });
    setOpen(true);
  };

  const handleAddLogisticClick = (): void => {
    setLogistic({
      id: "",
      emoji: "",
      title: "",
      description: "",
    });
    setOpen(true);
  };

  const handleAddLogistic = async (): Promise<void> => {
    createLogistic({
      variables: {
        input: {
          emoji: logistic.emoji,
          title: logistic.title,
          description: logistic.description,
        },
      },
      optimisticResponse: {
        createLogisticForOrganization: {
          __typename: "CreateLogisticForOrganizationMutationResponse",
          success: true,
          organization: {
            __typename: "Organization",
            id: "",
            customLogistics: [
              ...customLogistics,
              {
                __typename: "CustomLogistic",
                id: "temp-id",
                emoji: logistic.emoji,
                title: logistic.title,
                description: logistic.description,
              },
            ],
          },
        },
      },
    });
    setOpen(false);
    if (analytics) {
      analytics.track("Company Page Logistic Card Created");
    }
  };

  const handleDeleteLogistic = async (): Promise<void> => {
    if (logistic.id) {
      deleteLogistic({
        variables: {
          input: {
            id: logistic.id,
          },
        },
        optimisticResponse: {
          deleteLogisticForOrganization: {
            __typename: "DeleteLogisticForOrganizationMutationResponse",
            success: true,
            organization: {
              __typename: "Organization",
              id: "",
              customLogistics: [
                ..._.filter(customLogistics, ({ id }) => id === logistic.id),
              ],
            },
          },
        },
      });
      setOpen(false);
      if (analytics) {
        analytics.track("Company Page Logistic Card Deleted");
      }
    }
  };

  const handleUpdateLogistic = async (): Promise<void> => {
    if (logistic.id && logistic.title) {
      updateLogistic({
        variables: {
          input: {
            id: logistic.id,
            emoji: logistic.emoji,
            title: logistic.title,
            description: logistic.description,
          },
        },
        optimisticResponse: {
          updateLogisticForOrganization: {
            __typename: "UpdateLogisticForOrganizationMutationResponse",
            success: true,
            customLogistic: {
              __typename: "CustomLogistic",
              id: logistic.id,
              emoji: logistic.emoji,
              title: logistic.title,
              description: logistic.description,
            },
          },
        },
      });
      setOpen(false);
      if (analytics) {
        analytics.track("Company Page Logistic Card Updated");
      }
    }
  };

  return (
    <div className={classes.logisticContainer}>
      <div className={classes.headerCont}>
        <Typography className={classes.text} variant="h3">
          <span role="img" aria-label="Pray Hands">
            ✅
          </span>{" "}
          Logistics
        </Typography>
        <Fab
          onClick={(): void => {
            handleAddLogisticClick();
          }}
          color="primary"
          size="small"
          aria-label="add logistic"
        >
          <FontAwesomeIcon icon={faPlus} size="lg" />
        </Fab>
      </div>
      <div className={classes.logisticCardsCont}>
        {customLogistics.map(({ id, emoji, title, description }) => (
          <Card
            key={id}
            className={classes.logisticCard}
            onClick={(): void => {
              handleLogisticCardClick({
                id,
                emoji,
                title,
                description,
              });
            }}
          >
            <Typography className={classes.cardHeaderText} variant="h2">
              {emoji}
            </Typography>
            <Typography className={classes.cardHeaderText} variant="h4">
              {title}
            </Typography>
            <Typography className={classes.description} variant="body1">
              {description}
            </Typography>
          </Card>
        ))}
      </div>
      <Dialog open={open} fullWidth onClose={(): void => setOpen(false)}>
        <DialogTitle onClose={(): void => setOpen(false)}>
          {logistic.id ? "Update a Logistic" : "Create a Logistic"}
        </DialogTitle>
        <Divider />
        <div className={classes.dialogCont}>
          <TextField
            label="Emoji"
            variant="outlined"
            placeholder="🐶"
            value={logistic.emoji}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
              const { value: emoji } = e.target;
              setLogistic((prevLogistic) => ({
                ...prevLogistic,
                emoji,
              }));
            }}
            fullWidth
          />
          <TextField
            label="Title"
            variant="outlined"
            placeholder="Dog Friendly!"
            value={logistic.title}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
              const { value: title } = e.target;
              setLogistic((prevLogistic) => ({
                ...prevLogistic,
                title,
              }));
            }}
            fullWidth
          />
          <TextField
            variant="outlined"
            label="Description"
            placeholder="We love your dog, as long as they are friendly ❤️"
            value={logistic.description}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
              const { value: description } = e.target;
              setLogistic((prevLogistic) => ({
                ...prevLogistic,
                description,
              }));
            }}
            multiline
            fullWidth
          />
          <div className={classes.buttonContainer}>
            {logistic.id && (
              <Button
                variant="outlined"
                size="large"
                style={{
                  color: theme.palette.error.main,
                }}
                onClick={(): void => {
                  handleDeleteLogistic();
                }}
              >
                Delete
              </Button>
            )}
            <Button
              disabled={!logistic.title || !logistic.emoji}
              variant="contained"
              color="primary"
              size="large"
              onClick={(): void => {
                if (logistic.id) {
                  handleUpdateLogistic();
                } else {
                  handleAddLogistic();
                }
              }}
            >
              {logistic.id ? "Update" : "Create"}
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default CustomLogistics;
