import gql from "graphql-tag";

const BulkEditEventTemplatesForInterviewKit = gql`
  mutation BulkEditEventTemplatesForInterviewKit(
    $where: event_template_v2_bool_exp!
    $interviewHidden: Boolean
    $interviewTitle: String
    $interviewDescription: String
  ) @hasura {
    update_event_template_v2(
      where: $where
      _set: {
        hidden: $interviewHidden
        title: $interviewTitle
        description: $interviewDescription
      }
    ) {
      returning {
        id
        atsEventTemplateId
        atsEventTemplateName
        hidden
        title
        description
      }
    }
  }
`;

export default BulkEditEventTemplatesForInterviewKit;
