import React, { useEffect, useState } from "react";
import _ from "lodash";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { strings } from "@resource/common";
import { makeStyles, Fade, Typography } from "@material-ui/core";
import Loading from "~/components/Loading/Loading";
import InterviewerForm from "../InterviewerForm";
import DialogTitle from "~/components/Dialog/DialogTitle/DialogTitle";
import { Interviewer } from "../InterviewerType";
import EditGlobalInfoText from "~/components/templateModulesEdit/Shared/EditGlobalInfoText/EditGlobalInfoText";
import {
  InterviewerForEditVariables,
  InterviewerForEdit,
  UpdateInterviewerOnTeamPageVariables,
  UpdateInterviewerOnTeamPage,
  DeleteInterviewerOnTeamPage,
  DeleteInterviewerOnTeamPageVariables,
  InterviewersForTeamPage,
} from "~/schemaTypes";
import INTERVIEWER_FRAGMENT from "~/fragments/INTERVIEWER_FRAGMENT";
import { ResourceCustomTheme } from "~/styles/config";

import { INTERVIEWER_QUERY, INTERVIEWERS_QUERY } from "../queries";

const UPDATE_INTERVIEWER_MUTATION = gql`
  mutation UpdateInterviewerOnTeamPage($input: InterviewerV2UpdateInput!) {
    updateInterviewerV2(input: $input) {
      ...InterviewerDetailsFragment
    }
  }
  ${INTERVIEWER_FRAGMENT}
`;

const DELETE_INTERVIEWER_MUTATION = gql`
  mutation DeleteInterviewerOnTeamPage($input: InterviewerV2DeleteInput!) {
    deleteInterviewerV2(input: $input) {
      code
      success
      message
    }
  }
`;

const useStyles = makeStyles((theme: ResourceCustomTheme) => ({
  root: {
    "& .dialogControls": {
      justifyContent: "center",
      "& .controlButton": {
        width: "218px",
        marginBottom: "10px",
      },
      "& .confirmDeleteButton": {
        background: theme.colors.BrickRed,
        color: theme.colors.White,
      },
      "& .deleteButton": {
        color: theme.colors.BrickRed,
        borderColor: theme.colors.BrickRed,
      },
    },
    "& .helperText": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "-20px",
      height: "50px",
    },
  },
}));

type InterviewerProps = {
  open: boolean;
  interviewerId: string;
  close: () => void;
  canDelete?: boolean;
  showGlobalEditAlert?: boolean;
  refetchQueries?: string[];
};

const EditInterviewerModal: React.FC<InterviewerProps> = ({
  open,
  close,
  interviewerId,
  canDelete = true,
  showGlobalEditAlert = false,
  refetchQueries,
}) => {
  const classes = useStyles();
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [interviewer, setInterviewer] = useState<Interviewer>({
    name: "",
    imageUrl: "",
    linkedInUrl: "",
    websiteUrls: [],
    biography: "",
    title: "",
  });

  const [updateInterviewer] = useMutation<
    UpdateInterviewerOnTeamPage,
    UpdateInterviewerOnTeamPageVariables
  >(UPDATE_INTERVIEWER_MUTATION, { ...{ refetchQueries } });
  const [deleteInterviewer] = useMutation<
    DeleteInterviewerOnTeamPage,
    DeleteInterviewerOnTeamPageVariables
  >(DELETE_INTERVIEWER_MUTATION, {
    update(cache, { data }) {
      if (data?.deleteInterviewerV2.success) {
        const interviewerCacheData = cache.readQuery<InterviewersForTeamPage>({
          query: INTERVIEWERS_QUERY,
        });
        if (interviewerCacheData?.currentUserV2?.organization.interviewers) {
          const interviewers = _.filter(
            interviewerCacheData?.currentUserV2?.organization.interviewers,
            (interviewerFromCache) => interviewerId !== interviewerFromCache.id
          );
          const newData = _.set(
            interviewerCacheData,
            "currentUserV2.organization.interviewers",
            interviewers
          );
          cache.writeQuery<InterviewersForTeamPage>({
            query: INTERVIEWERS_QUERY,
            data: newData,
          });
        }
      }
    },
  });
  const { data: interviewerData, loading: interviewerLoading } = useQuery<
    InterviewerForEdit,
    InterviewerForEditVariables
  >(INTERVIEWER_QUERY, {
    variables: {
      input: {
        id: interviewerId,
      },
    },
    skip: !interviewerId,
  });

  useEffect(() => {
    if (interviewerData?.interviewer) {
      const {
        firstName,
        lastName,
        biography,
        title,
        linkedInUrl,
        websiteUrls,
        imageUrl,
      } = interviewerData.interviewer;
      setInterviewer({
        name: `${firstName} ${lastName}`,
        biography,
        title,
        linkedInUrl,
        websiteUrls,
        imageUrl,
      });
    }
  }, [interviewerData, interviewerData?.interviewer]);

  const { firstName, lastName } = strings.splitName(interviewer.name);

  const handleClose = (): void => {
    setInterviewer({
      name: "",
      imageUrl: "",
      linkedInUrl: "",
      websiteUrls: [],
      biography: "",
      title: "",
    });
    close();
  };

  const handleSubmit = async (): Promise<void> => {
    await updateInterviewer({
      variables: {
        input: {
          id: interviewerId,
          firstName,
          lastName,
          biography: interviewer.biography,
          title: interviewer.title,
          linkedInUrl: interviewer.linkedInUrl,
          websiteUrls: interviewer.websiteUrls,
          imageUrl: interviewer.imageUrl,
        },
      },
    });
    handleClose();
  };

  const handleConfirm = async (): Promise<void> => {
    await deleteInterviewer({
      variables: {
        input: {
          id: interviewerId,
        },
      },
    });
    handleClose();
    setConfirmDelete(false);
  };

  return (
    <Dialog
      className={classes.root}
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle onClose={close}>
        {strings.possessive(firstName)} Interviewer Profile
      </DialogTitle>
      {interviewerLoading ? (
        <DialogContent>
          <Loading />
        </DialogContent>
      ) : (
        <>
          <DialogContent>
            {showGlobalEditAlert && <EditGlobalInfoText />}
            <InterviewerForm
              interviewer={interviewer}
              setInterviewer={setInterviewer}
            />
          </DialogContent>
          <DialogActions className="dialogControls">
            {confirmDelete ? (
              <>
                <Fade in timeout={1000}>
                  <Button
                    className="controlButton"
                    onClick={(): void => setConfirmDelete(false)}
                    color="primary"
                    variant="outlined"
                    size="large"
                  >
                    Cancel
                  </Button>
                </Fade>
                <Fade in timeout={1000}>
                  <Button
                    className="controlButton confirmDeleteButton"
                    onClick={handleConfirm}
                    color="primary"
                    variant="contained"
                    size="large"
                  >
                    Confirm Delete
                  </Button>
                </Fade>
              </>
            ) : (
              <>
                {canDelete && (
                  <Fade in timeout={1000}>
                    <Button
                      className="controlButton deleteButton"
                      onClick={(): void => setConfirmDelete(true)}
                      variant="outlined"
                      size="large"
                    >
                      Delete
                    </Button>
                  </Fade>
                )}
                <Fade in timeout={1000}>
                  <Button
                    className="controlButton"
                    onClick={handleSubmit}
                    color="primary"
                    variant="contained"
                    size="large"
                    disabled={!interviewer.name}
                  >
                    Save
                  </Button>
                </Fade>
              </>
            )}
          </DialogActions>
        </>
      )}
      {confirmDelete && (
        <Fade in timeout={500}>
          <div className="helperText">
            <Typography variant="subtitle2">
              Deleting an interviewer will remove them from all active & past
              guides.
            </Typography>
          </div>
        </Fade>
      )}
    </Dialog>
  );
};

export default EditInterviewerModal;
