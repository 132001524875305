import React, { useState } from "react";

import { gql, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";
import IntegrationCard from "../Integrations/IntegrationCard";
import { useFlashMessage } from "~/components/FlashMessage/FlashMessage";
import ZoomIntegrationModal from "./ZoomIntegrationModal";

import { IntegrationsPageIntegrationFragment } from "../Integrations/ALL_INTEGRATIONS_QUERY";
import {
  IntegrationEnum,
  DisconnectZoomIntegrationForCustomer,
  FetchAllCustomerIntegrations_currentUserV2_organization_customer_integrations,
} from "~/schemaTypes";

import zoomIconLg from "~/icons/zoom-icon-lg.svg";

const CustomerFragment = gql`
  fragment customerFragmentForZoom on Customer {
    id
    integration(input: $typeInput) {
      ...IntegrationsPageIntegration
    }
  }
  ${IntegrationsPageIntegrationFragment}
`;

const DISCONNECT_ZOOM_INTEGRATION_FOR_CUSTOMER = gql`
  mutation DisconnectZoomIntegrationForCustomer(
    $typeInput: IntegrationByTypeInput!
  ) {
    disconnectCustomerZoomIntegration {
      success
      message
      code
      customer {
        ...customerFragmentForZoom
      }
    }
  }
  ${CustomerFragment}
`;

type ZoomIntegrationCardProps = {
  integration: FetchAllCustomerIntegrations_currentUserV2_organization_customer_integrations;
};

const ZoomIntegrationCard: React.FC<ZoomIntegrationCardProps> = ({
  integration,
}) => {
  const [open, setOpen] = useState(false);
  const { setContent } = useFlashMessage();
  const [disconnect, { loading: disconnectSaving }] = useMutation<
    DisconnectZoomIntegrationForCustomer
  >(DISCONNECT_ZOOM_INTEGRATION_FOR_CUSTOMER);

  const onDisconnect = async () => {
    try {
      await disconnect({
        variables: {
          typeInput: {
            type: IntegrationEnum.ZOOM,
          },
        },
      });
    } catch (error) {
      setContent({
        content: "Something went wrong disconnecting your Zoom integration",
        severity: "error",
      });
      Sentry.captureException(error);
    }
  };

  const onConnect = () => setOpen(true);
  const onClose = () => setOpen(false);

  return (
    <>
      <IntegrationCard
        icon={zoomIconLg}
        label="Zoom"
        connected={integration.connected}
        onDisconnect={onDisconnect}
        disconnectSaving={disconnectSaving}
        onConnect={onConnect}
      >
        Guide can automatically create Zoom Links for your scheduled interviews
      </IntegrationCard>
      <ZoomIntegrationModal open={open} onClose={onClose} />
    </>
  );
};

export default ZoomIntegrationCard;
