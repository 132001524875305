import React, { FunctionComponent, MouseEvent } from "react";
import {
  Typography,
  Card,
  Link,
  makeStyles,
  CardProps,
  LinkProps,
} from "@material-ui/core";
import isUrl from "is-url";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons";
import zoomLogo from "~/icons/zoom.svg";
import hangoutsLogo from "~/icons/google-hangouts.svg";
import meetLogo from "~/icons/google-meet.svg";

import { ResourceCustomTheme } from "~/styles/config";

const phoneRegex = /^.*[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}.*$/im;

const useStyles = makeStyles<ResourceCustomTheme>((_theme) => ({
  root: {
    marginTop: 20,
    width: "100%",
    padding: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxSizing: "border-box",
  },
  secondaryFlex: {
    maxWidth: "95%",
    display: "flex",
    alignItems: "center",
  },
  emoji: {
    display: "flex",
    margin: 0,
    marginRight: 24,
  },
  icon: {
    marginLeft: 20,
    color: "#000",
  },
  linkIcon: {
    fontSize: 32,
    maxWidth: 36,
    maxHeight: 40,
    verticalAlign: "middle",
  },
}));

// Combine Link and Card propTypes to avoid typescript error
function CardWithLinkBase<C extends React.ElementType>(
  props: LinkProps<C, { component?: C }> & CardProps
) {
  return (
    <Card
      component={Link}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}

type ProminentInstructionsCardProps = {
  label: string;
  url: string | null;
};

enum InstructionsType {
  Hangouts,
  Meet,
  Zoom,
  CoderPad,
  HackerRank,
  PhoneNumber,
  URL,
  None,
}

export const getType = ({
  url,
  label,
}: ProminentInstructionsCardProps): InstructionsType => {
  if (url?.includes("zoom.us")) return InstructionsType.Zoom;
  if (url?.includes("hangouts.google.com")) return InstructionsType.Hangouts;
  if (url?.includes("meet.google.com")) return InstructionsType.Meet;
  if (url?.includes("coderpad.io")) return InstructionsType.CoderPad;
  if (url?.includes("hackerrank")) return InstructionsType.HackerRank;
  if (label.match(phoneRegex)?.length) return InstructionsType.PhoneNumber;
  if (isUrl(label)) return InstructionsType.URL;
  return InstructionsType.None;
};

export const ProminentInstructionsIcon: React.FC<{
  type: InstructionsType;
  className?: string;
}> = ({ type, className = "" }) => {
  switch (type) {
    case InstructionsType.Hangouts:
      return <img alt="" className={className} src={hangoutsLogo} />;
    case InstructionsType.Zoom:
      return <img alt="" className={className} src={zoomLogo} />;
    case InstructionsType.Meet:
      return <img alt="" className={className} src={meetLogo} />;
    case InstructionsType.CoderPad:
    case InstructionsType.HackerRank:
      return (
        <span className={className} role="img" aria-label="Laptop Image">
          💻
        </span>
      );
    case InstructionsType.PhoneNumber:
      return (
        <span className={className} role="img" aria-label="Phone Image">
          ☎️
        </span>
      );
    case InstructionsType.URL:
      return (
        <span className={className} role="img" aria-label="Hyperlink Image">
          🔗
        </span>
      );
    default:
      return (
        <span className={className} role="img" aria-label="Exclamation Image">
          ❗️
        </span>
      );
  }
};

type LabelProps = {
  label: string;
  type: InstructionsType;
};

export const getLabel = ({ label, type }) => {
  switch (type) {
    case InstructionsType.Zoom:
      return "Zoom Meeting";
    case InstructionsType.Meet:
      return "Google Meet Link";
    case InstructionsType.CoderPad:
      return "CoderPad Link";
    case InstructionsType.HackerRank:
      return "HackerRank Link";
    default:
      return label;
  }
};

const ProminentInstructionsCard: FunctionComponent<ProminentInstructionsCardProps> = ({
  label,
  url,
}) => {
  const classes = useStyles();
  const linkify = isUrl(url || "");
  const type = getType({ label, url });

  return (
    <CardWithLinkBase
      className={classes.root}
      href={url ?? undefined}
      underline="none"
      target="_blank"
      rel="noopener noreferrer"
      style={{
        boxShadow: linkify ? undefined : "none",
        border: linkify ? undefined : "solid 1px #f1f1f1",
      }}
      onClick={(evt: MouseEvent) => {
        if (linkify) {
          evt.stopPropagation();
        }
      }}
    >
      <div className={classes.secondaryFlex}>
        <Typography variant="h3" className={classes.emoji}>
          <ProminentInstructionsIcon type={type} className={classes.linkIcon} />
        </Typography>
        <Typography variant="body1" noWrap>
          {getLabel({ label, type })}
        </Typography>
      </div>
      {linkify && (
        <FontAwesomeIcon
          className={classes.icon}
          icon={faChevronRight}
          size="1x"
        />
      )}
    </CardWithLinkBase>
  );
};

export default ProminentInstructionsCard;
