import gql from "graphql-tag";

const GUIDE_FETCH_GUIDES_FRAGMENT = gql`
  fragment GuideFetchGuides on Guide {
    id
    archivedAt
    atsUrl
    candidate {
      id
      firstName
      lastName
    }
    jobRole {
      id
      name
    }
    currentStage {
      id
      date
      lastSentAt
    }
    candidateOpens
  }
`;

export default GUIDE_FETCH_GUIDES_FRAGMENT;
