import React, { FunctionComponent, useState } from "react";
import { Link } from "react-router-dom";
import { Typography, Card, CardActionArea } from "@material-ui/core";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import _ from "lodash";
import cx from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "~/components/Loading/Loading";
import styles from "./SelectLocation.module.scss";
import { getStaticMapsUrl, ZoomLevel, ImageSize } from "~/utils/image-utils";
import useOrgLocations from "../utils/useOrgLocations";

type SelectLocationProps = {
  selectedLocationId?: string;
  onAddLocation(): void;
  onSelectLocation(locationId: string): void;
};

const SelectLocation: FunctionComponent<SelectLocationProps> = ({
  selectedLocationId,
  onAddLocation,
  onSelectLocation,
}) => {
  const [initialLocationId] = useState<string | undefined>(selectedLocationId);
  const { locations: orgLocations, locationsLoading } = useOrgLocations();

  const locations = _(orgLocations)
    .filter((location) => {
      return !location.isArchived || location.id === initialLocationId;
    })
    .sortBy((location) => [location.id !== initialLocationId, location.name])
    .value();

  return locationsLoading ? (
    <Loading />
  ) : (
    <div className={styles.locationsFlexCont}>
      {_.map(locations, ({ id, name, coordinates, isArchived }) => (
        <Card
          key={name}
          className={cx(styles.locationCard, {
            [styles.selectedLocation]: selectedLocationId === id,
            [styles.archivedLocation]: isArchived,
          })}
          elevation={1}
          onClick={(): void => {
            onSelectLocation(id);
          }}
        >
          <CardActionArea
            className={styles.actionArea}
            style={{
              backgroundImage: `url(${getStaticMapsUrl(
                coordinates,
                ImageSize.Square,
                ZoomLevel.MidRange
              )})`,
            }}
          >
            <Typography className={styles.text} variant="h4">
              {name}{" "}
              {isArchived ? (
                <>
                  <br />
                  <em>Archived</em>
                </>
              ) : (
                ""
              )}
            </Typography>
          </CardActionArea>
        </Card>
      ))}
      {/* New Location Card */}
      <Card
        key="new-location-card"
        className={cx(styles.locationCard, styles.newLocationCard)}
        elevation={1}
      >
        <CardActionArea className={styles.actionArea}>
          <Link
            onClick={(e: React.MouseEvent): void => {
              e.preventDefault();
              onAddLocation();
            }}
            to="/company"
          >
            <Typography variant="h4" className={styles.text}>
              <FontAwesomeIcon icon={faPlus} />
            </Typography>
            <Typography variant="h4" className={styles.text}>
              Add New Location
            </Typography>
          </Link>
        </CardActionArea>
      </Card>
    </div>
  );
};

export default SelectLocation;
