import moment from "moment";
import { v4 as uuid } from "uuid";
import {
  StageById_stage_sortedVisibleEvents as Event,
  StageById_stage_sortedVisibleEvents_interviewers as Interviewer,
} from "~/schemaTypes";

type SimpleStage = {
  position: number;
  name: string;
};

const fakeStartOfDay = moment().add(1, "day").startOf("day");

export const defaultCandidateName = "Candidate";
export const defaultRoleName = "Role";
export const defaultRemoteInstructions = "I'll call you at 867-5309";
export const defaultInterviewDate = moment().add(1, "day");
export const defaultInterviewStartTime = moment().startOf("h").add(1, "day");
export const defaultDuration = moment.duration(30, "minutes");
export const defaultCompanyName = "ACME";

export const defaultLogistics = [
  {
    id: uuid(),
    logisticGroupId: uuid(),
    icon: "🎧",
    title: "Find a Quiet Spot",
    description:
      "Make sure the background noise isn’t too high so we can focus on our conversation - not your neighbor's coffee date",
  },
  {
    id: uuid(),
    logisticGroupId: uuid(),
    icon: "🎥",
    title: "Video Prep",
    description:
      "Dress is casual, but be prepared to have your video on. We recommend changing out of your PJs!",
  },
  {
    id: uuid(),
    logisticGroupId: uuid(),
    icon: "📶",
    title: "Wifi Connectivity",
    description:
      "Make sure you have a stable connection so we can focus on our conversation!",
  },
];

export const defaultInterviewProcessStages: SimpleStage[] = [
  {
    position: 0,
    name: "Phone Screen",
  },
  {
    position: 1,
    name: "Hiring Manager",
  },
  {
    position: 2,
    name: "Onsite",
  },
  {
    position: 3,
    name: "Offer",
  },
];

export const defaultInterviewers: Interviewer[] = [
  {
    id: "02252db3-41ac-47b8-8620-ebd8456cae11",
    firstName: "Laurie",
    lastName: "Bream",
    title: "Managing Partner",
    linkedInUrl: "",
    websiteUrls: [],
    biography:
      "Laurie Bream joined Raviga as a Managing Partner in 2010, focusing on the energy and financial sectors. Before joining Raviga, Laurie had over 15 years of investing experience in both the U.S. and abroad. Laurie earned her Bachelor of Science degree in economics from the Massachusetts Institute of Technology, and her M.B.A. from the Wharton School of Business. When her schedule permits, Laurie relaxes by engaging in activities which she finds enjoyable.",
    imageUrl:
      "https://storage.googleapis.com/resource-interview/5d1f58d2-ee3d-491a-b12b-b5406ce8e0ee.png",
    __typename: "InterviewerV2",
  },
  {
    id: "02252db3-41ac-47b8-8620-ebd8456cae12",
    firstName: "Jian",
    lastName: "Yang",
    title: "Application Developer",
    linkedInUrl: "https://www.linkedin.com/in/jian-yang-2015a4121/",
    websiteUrls: ["https://www.linkedin.com/in/jian-yang-2015a4121/"],
    biography: "",
    imageUrl:
      "https://storage.googleapis.com/resource-interview/5205bb90-acf1-42f4-8fab-64ccb0cc6187.png",
    __typename: "InterviewerV2",
  },
];

export const defaultEvents: Event[] = [
  {
    id: "9b8ebeb2-7ae6-401b-9172-511b8ed2be6f",
    title: "Welcome + Agenda",
    description: "",
    startTime: fakeStartOfDay.add(8, "hours").toISOString(),
    endTime: fakeStartOfDay.add(1, "hours").toISOString(),
    remoteInstructions: "https://zoom.com/123455",
    techScreenLink: "",
    zoomLink: "",
    prominentInstructions: [],
    hidden: false,
    interviewers: [defaultInterviewers[0]],
    __typename: "EventV2",
  },
  {
    id: "9b8ebeb2-7ae6-401b-9172-511b8ed2be6g",
    title: "Culture Fit",
    description: "",
    startTime: fakeStartOfDay.toISOString(),
    remoteInstructions: "",
    techScreenLink: "",
    zoomLink: "",
    prominentInstructions: [],
    endTime: fakeStartOfDay.add(1, "hours").toISOString(),
    hidden: false,
    interviewers: [defaultInterviewers[1]],
    __typename: "EventV2",
  },
];

export const defaultCustomDetails = `
<div><br></div>
<div><strong>9:00 am</strong>			👋 Welcome &amp; Overview</div>
<div><br></div>
<div><strong>10:00 am</strong>		👩&zwj;💻 Technical Skills</div>
<div><br></div>
<div><strong>10:30 am</strong> 		✨ Values Alignment</div>
<div><br></div>
<div><strong>11:00 am</strong>			👨&zwj;👩&zwj;👧&zwj;👧 Teamwork &amp; Collaboration</div>
<div><br></div>
`;
