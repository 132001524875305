import React from "react";
import {
  DialogContent,
  Button,
  DialogActions,
  makeStyles,
  Grid,
} from "@material-ui/core";
import { GetGuideTemplatesForBulkEdit_guide_template as GuideTemplate } from "~/schemaTypesHasura";
import {
  StageEditsDispatchType,
  StageEditsStateType,
  ActionEnum,
  BulkStageEditMode,
} from "./types";
import { ResourceCustomTheme } from "~/styles/config";
import JobsStagePicker from "./JobsStagePicker";

const useStyles = makeStyles((theme: ResourceCustomTheme) => ({
  root: {},
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  chip: {
    backgroundColor: theme.colors.RoyalPurple(100),
    color: theme.colors.RoyalPurple(),
    marginBottom: theme.spacing(2),
  },
}));

type SelectStagesProps = {
  state: StageEditsStateType;
  dispatch: React.Dispatch<StageEditsDispatchType>;
  guideTemplates: GuideTemplate[];
  mode: BulkStageEditMode;
};

const SelectStages: React.FC<SelectStagesProps> = ({
  mode,
  state,
  dispatch,
  guideTemplates,
}) => {
  const classes = useStyles();

  return (
    <>
      <DialogContent dividers>
        <JobsStagePicker
          mode={mode}
          guideTemplates={guideTemplates}
          selectedStageIds={state.stageIds}
          setSelectedStageIds={(stageIds) =>
            dispatch({
              type: ActionEnum.SetStages,
              stageIds,
            })
          }
        />
      </DialogContent>

      <DialogActions>
        <Grid container>
          <Grid item xs={12} className={classes.actions}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                dispatch({ type: ActionEnum.Cancel });
              }}
              style={{ marginLeft: 10 }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              type="submit"
              disabled={!state.stageIds.length}
              variant="contained"
              onClick={() => {
                dispatch({
                  type: ActionEnum.Next,
                });
              }}
              style={{ marginLeft: 10 }}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};

export default SelectStages;
