import _ from "lodash";
import React, { FunctionComponent, useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Fade, Table, TableBody, Typography, Card } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import { useFlags } from "@resource/client-ffs";

import { FeatureFlagEnum } from "@resource/common";
import Loading from "~/components/Loading/Loading";
import useAnalytics from "~/react-hooks/useAnalytics";
import emptyGuideImage from "~/assets/images/emptyGuideState.png";
import GuideRowItem from "./GuideRowItem";
import GuidesTableHeader from "./GuidesTableHeader";
import {
  FetchGuides,
  FetchGuidesVariables,
  FetchGuides_currentUserV2_organization_guides as Guide,
} from "~/schemaTypes";
import {
  GuidesList,
  GuidesListVariables,
  GuidesList_get_current_user_v2_currentOrganization_guides as HasuraGuide,
} from "~/schemaTypesHasura";
import FETCH_GUIDES_QUERY from "./FETCH_GUIDES_QUERY";
import FETCH_HASURA_GUIDES_QUERY from "./HASURA_GUIDES_QUERY@hasura";
import styles from "./Guides.module.scss";

const GUIDE_LIMIT = 20;

// TODO: Wire up these functions to the actual components
type GuidesProps = {
  preview?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

export type RowSortType = {
  column: string;
  dataLocation: string | ((guide: Guide) => Date | undefined);
  direction: "asc" | "desc" | false;
};

const Guides: FunctionComponent<GuidesProps> = ({ preview }) => {
  const {
    [_.camelCase(FeatureFlagEnum.HasuraGuideList)]: hasuraGuideListFlag,
  } = useFlags();
  const [analytics] = useAnalytics();
  const [allGuidesFetched, setAllGuidesFetched] = useState(false);

  useEffect(() => {
    analytics.page("Viewed Guide Homepage Page");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEditGuide = (guide: Guide | HasuraGuide): void => {
    if (guide.atsUrl) {
      window.open(guide.atsUrl);
    }
  };

  const [rowsSortState, setRowSortState] = useState<RowSortType>({
    column: "",
    dataLocation: "updatedAt",
    direction: "desc",
  });

  useEffect(() => {
    setAllGuidesFetched(false);
  }, [rowsSortState]);

  const { data: fetchGuidesData, loading: fetchGuidesLoading } = useQuery<
    FetchGuides,
    FetchGuidesVariables
  >(FETCH_GUIDES_QUERY, {
    skip: hasuraGuideListFlag,
    variables: {
      input: {
        offset: 0, // DO NOT CHANGE THIS WITHOUT ALSO UPDATING THE CACHE UPDATE IN addGuideModal.tsx
        limit: 1000, // DO NOT CHANGE THIS WITHOUT ALSO UPDATING THE CACHE UPDATE IN addGuideModal.tsx
      },
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let hasuraOrderBy: any;
  const hasuraDirection =
    rowsSortState.direction === "desc" ? "desc_nulls_last" : "asc_nulls_last";
  switch (rowsSortState.column) {
    case "name":
      hasuraOrderBy = {
        candidate: {
          firstName: hasuraDirection,
        },
      };
      break;
    case "openTracking":
      hasuraOrderBy = {
        candidateOpens: hasuraDirection,
      };
      break;
    case "role":
      hasuraOrderBy = {
        jobRole: {
          name: hasuraDirection,
        },
      };
      break;
    case "date":
      hasuraOrderBy = {
        currentStage: {
          date: hasuraDirection,
        },
      };
      break;
    default:
      hasuraOrderBy = {
        updatedAt: hasuraDirection,
      };
  }

  const {
    data: hasuraGuidesData,
    fetchMore,
    loading: hasuraGuidesLoading,
  } = useQuery<GuidesList, GuidesListVariables>(FETCH_HASURA_GUIDES_QUERY, {
    skip: !hasuraGuideListFlag,
    variables: {
      guidesLimit: GUIDE_LIMIT,
      guidesOffset: 0,
      guidesOrderBy: hasuraOrderBy,
    },
  });
  let guidesLoading: boolean;
  let guides: (Guide | HasuraGuide)[] = [];
  if (hasuraGuideListFlag) {
    guides =
      hasuraGuidesData?.get_current_user_v2?.[0]?.currentOrganization?.guides ||
      [];
    guidesLoading = hasuraGuidesLoading;
  } else {
    guides = fetchGuidesData?.currentUserV2?.organization.guides || [];
    guidesLoading = fetchGuidesLoading;
  }
  const { dataLocation, direction } = rowsSortState;
  const sortedGuides = hasuraGuideListFlag
    ? guides
    : _.orderBy(guides, [dataLocation], [direction]);

  const handleInfiniteScroll = () => {
    return fetchMore({
      variables: {
        guidesLimit: GUIDE_LIMIT,
        guidesOffset: guides.length,
        guidesOrderBy: hasuraOrderBy,
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      updateQuery: (prev, { fetchMoreResult }: any) => {
        if (!fetchMoreResult) {
          return prev;
        }
        const newObject = _.mergeWith(
          {},
          prev,
          fetchMoreResult,
          (objValue, srcValue, objKey) => {
            if (
              _.isArray(objValue) &&
              !["get_current_user_v2"].includes(objKey)
            ) {
              return objValue.concat(srcValue);
            }
            return undefined;
          }
        );
        const newGuides =
          fetchMoreResult?.get_current_user_v2?.[0]?.currentOrganization
            ?.guides || [];
        // If we didn't get a full page, we're done
        if (newGuides.length !== GUIDE_LIMIT) {
          setAllGuidesFetched(true);
        }
        return newObject;
      },
    });
  };

  return (
    <Fade in timeout={1000}>
      <div className={styles.guidesCont}>
        <div className={styles.headerCont}>
          <Typography className={styles.headerText} variant="h2">
            Activity
          </Typography>
        </div>
        <InfiniteScroll
          dataLength={guides.length}
          hasMore={!allGuidesFetched}
          loader={<Loading />}
          next={handleInfiniteScroll}
        >
          {guidesLoading ? (
            <Loading />
          ) : (
            <>
              {guides.length ? (
                <Card className={styles.tableCont}>
                  <Table className={styles.table}>
                    <GuidesTableHeader
                      rowsSortState={rowsSortState}
                      setRowSortState={setRowSortState}
                    />
                    <TableBody className={styles.tableBody}>
                      {_.map(sortedGuides, (guide: Guide | HasuraGuide) => {
                        const { id } = guide;

                        return (
                          <GuideRowItem
                            key={id!}
                            guide={guide}
                            edit={
                              guide.atsUrl
                                ? (): void => onEditGuide(guide)
                                : undefined
                            }
                            preview={preview}
                          />
                        );
                      })}
                    </TableBody>
                  </Table>
                </Card>
              ) : (
                <div className={styles.gettingStarted}>
                  <Typography variant="h3">No Guides sent yet!</Typography>
                  <Typography variant="h4">
                    Create one to{" "}
                    <span role="img" aria-label="star struck face">
                      🤩
                    </span>{" "}
                    your next candidate.
                  </Typography>
                  <img src={emptyGuideImage} alt="guide example" />
                </div>
              )}
            </>
          )}
        </InfiniteScroll>
      </div>
    </Fade>
  );
};

export default Guides;
