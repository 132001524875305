import React, { FunctionComponent } from "react";
import { Category } from "./categories";
import CategoryChip from "./CategoryChip";

type CategoryMultiSelectionProps = {
  categories: Array<Category>;
  addCategory: (category: string) => void;
  removeCategory: (category: string) => void;
  categoriesSelected: Array<Category["id"]>;
};

const CategoryMultiSelection: FunctionComponent<CategoryMultiSelectionProps> = ({
  categories,
  categoriesSelected,
  addCategory,
  removeCategory,
}) => (
  <div>
    {categories.map((category) => (
      <CategoryChip
        category={category}
        key={category.id}
        isSelected={categoriesSelected.includes(category.id)}
        setSelected={(selected: boolean): void => {
          if (selected) {
            addCategory(category.id);
          } else {
            removeCategory(category.id);
          }
        }}
      />
    ))}
  </div>
);

export default CategoryMultiSelection;
