import React, { FunctionComponent, useState } from "react";
import { Typography, TextField, InputAdornment } from "@material-ui/core";
import gql from "graphql-tag";

import useDebouncedMutation from "~/utils/useDebouncedMutation";

import styles from "./Brand.module.scss";

const UPDATE_ORGANIZATION_THEME_MUTATION = gql`
  mutation UpdateOrganizationTheme($input: UpdateOrganizationThemeInput!) {
    updateOrganizationTheme(input: $input) {
      id
      theme
    }
  }
`;

export type CompanyTheme = {
  primaryColor: string;
  secondaryColor: string;
};

type BrandProps = {
  theme: CompanyTheme;
};

const Brand: FunctionComponent<BrandProps> = ({ theme }) => {
  const [primaryColor, setPrimaryColor] = useState<string>(
    theme.primaryColor.slice(1)
  );
  const [updateThemeDebounced] = useDebouncedMutation(
    UPDATE_ORGANIZATION_THEME_MUTATION,
    {},
    1000
  );

  const handleColorChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setPrimaryColor(e.target.value);
    updateThemeDebounced({
      variables: {
        input: {
          primaryColor: `#${e.target.value}`,
        },
      },
    });
  };
  return (
    <div className={styles.brandContainer}>
      <Typography className={styles.text} variant="h3">
        <span role="img" aria-label="Paint Pallette">
          🎨
        </span>{" "}
        Brand Color
      </Typography>
      <div className={styles.brandColorInputFlex}>
        <div
          className={styles.primaryColorPreview}
          style={{
            backgroundColor: `#${primaryColor}`,
          }}
        />
        <TextField
          className={styles.primaryColorTextField}
          InputProps={{
            startAdornment: <InputAdornment position="start">#</InputAdornment>,
          }}
          variant="outlined"
          value={primaryColor}
          onChange={handleColorChange}
        />
      </div>
    </div>
  );
};

export default Brand;
