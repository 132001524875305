import { useState, useEffect, useCallback } from "react";

function useWindowSize(): {
  width: number | undefined;
  height: number | undefined;
} {
  const isClient = typeof window === "object";

  const getSize = useCallback((): {
    width: number | undefined;
    height: number | undefined;
  } => {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }, [isClient]);

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return () => null;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isClient, getSize]); // Empty array ensures that effect is only run on mount and unmount
  return windowSize;
}

export default useWindowSize;
