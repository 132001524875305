import DOMPurify from "dompurify";

export default (html: string): string => {
  // Always properly set rel and noopener for links with target
  DOMPurify.addHook("afterSanitizeAttributes", (node: Element) => {
    // set all elements owning target to target=_blank
    if ("target" in node) {
      (node as Element).setAttribute("target", "_blank");
      (node as Element).setAttribute("rel", "noopener");
    }
  });
  return DOMPurify.sanitize(html, {
    ADD_ATTR: ["target"],
  });
};
