import React from "react";
import { Card } from "@material-ui/core";

import styles from "./ModulePresenter.module.scss";
import Loading from "~/components/Loading/Loading";
import ModuleContainer from "~/components/modules/ModuleContainer/ModuleContainer";
import { StageModule } from "~/utils/module-generators/types";
import { SampleGuideHeader } from "../ModulePresenterHeader/ModulePresenterHeader";

type ModulePresenterProps = {
  modules: StageModule[];
  loading?: boolean;
  stageId?: string;
  guideId?: string;
  createdFromATS?: boolean;
  ModulePresenterHeader?: React.ReactNode | null;
};

const ModulePresenter: React.FC<ModulePresenterProps> = ({
  modules,
  guideId = "fake-guide-id",
  stageId = "fake-stage-id",
  createdFromATS = false,
  loading = false,
  ModulePresenterHeader = <SampleGuideHeader />,
}) => {
  if (loading) {
    return <Loading text="Building your guide..." />;
  }

  return (
    <div className={styles.moduleCont}>
      {modules.length > 0 ? (
        <>
          {ModulePresenterHeader}
          <Card className={styles.card}>
            {modules.map((module, index) => (
              <ModuleContainer
                key={`${module.id}-${index}`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...{
                  ...module,
                  guideId,
                  createdFromATS,
                  stageId,
                }}
              />
            ))}
          </Card>
        </>
      ) : null}
    </div>
  );
};

export default ModulePresenter;
