import _ from "lodash";
import React, { FunctionComponent } from "react";
import { Stepper, Step, StepButton, StepLabel } from "@material-ui/core";

import useAnalytics from "~/react-hooks/useAnalytics";
import { GuideTemplateForEditing_guideTemplate_stageTemplateInstallations as StageTemplateInstallation } from "~/schemaTypes";

type StageStepperProps = {
  onSelectInstallation(installation: StageTemplateInstallation): void;
  stageTemplateInstallations: StageTemplateInstallation[];
  selectedStageTemplateInstallation: StageTemplateInstallation;
};

const StageTemplateInstallationStepper: FunctionComponent<StageStepperProps> = ({
  onSelectInstallation,
  selectedStageTemplateInstallation,
  stageTemplateInstallations,
}) => {
  const [analytics] = useAnalytics();
  const selectedStageIndex = _.findIndex(stageTemplateInstallations, {
    id: selectedStageTemplateInstallation.id,
  });
  return (
    <Stepper activeStep={selectedStageIndex} nonLinear>
      {_.map(
        _.filter(stageTemplateInstallations, { hidden: false }),
        (installation, index: number) => (
          <Step key={installation.id} completed={index <= selectedStageIndex}>
            <StepButton
              onClick={(): void => {
                onSelectInstallation(installation);
                if (analytics) {
                  analytics.track(
                    "Guide Template Sample Interview Process Stage Clicked"
                  );
                }
              }}
            >
              <StepLabel>
                {installation.displayName || installation.stageTemplate.name}
              </StepLabel>
            </StepButton>
          </Step>
        )
      )}
    </Stepper>
  );
};

export default StageTemplateInstallationStepper;
