import gql from "graphql-tag";

export default gql`
  mutation MediaModuleUpdateFromEditModal(
    $input: UpdateMediaInstallationForStageTemplateInput!
  ) {
    updateMediaInstallationForStageTemplate(input: $input) {
      id
      name
    }
  }
`;
