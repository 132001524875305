import gql from "graphql-tag";

export default gql`
  subscription StageRefresh($where: stage_bool_exp) @hasura {
    stage(where: $where) {
      id
      hidden
      hiddenOverride
      title
      titleOverride
      events {
        id
        hidden
        title
        description
        descriptionOverride
        hiddenOverride
        titleOverride
        startTime
        endTime
        event_module_installations {
          id
          data
        }
      }
      stage_template {
        id
        stage_template_module_installations {
          id
          position
          data
        }
      }
    }
  }
`;
