import React, { FunctionComponent } from "react";
import { CircularProgress, Typography } from "@material-ui/core";
import cx from "classnames";

import styles from "./Loading.module.scss";

type LoadingProps = {
  text?: string;
  inlineStyling?: boolean;
  style?: Record<any, any>;
};

const Loading: FunctionComponent<LoadingProps> = ({
  text,
  inlineStyling = false,
  style,
}) => (
  <div
    className={cx(styles.container, {
      [styles.defaultHeight]: !inlineStyling,
    })}
    style={style}
  >
    {text && (
      <Typography
        className={cx({
          [styles.text]: !inlineStyling,
        })}
        variant="body1"
      >
        {text}
      </Typography>
    )}
    <CircularProgress />
  </div>
);

export default Loading;
