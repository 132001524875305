import React from "react";

import {
  Button,
  DialogActions,
  DialogContent,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";

import Dialog from "~/components/Dialog/Dialog";

type IntegrationModalProps = {
  logoSrc: string;
  title: string;
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
};

type IntegrationModalPropsWithSave = IntegrationModalProps & {
  onSave: () => void;
  updateSaving: boolean;
  disabled: boolean;
};

type Possible = IntegrationModalProps | IntegrationModalPropsWithSave;

const useStyles = makeStyles(() => ({
  content: {
    padding: "2rem",
  },
  actions: {
    padding: "2rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  saveButton: {
    width: "10rem",
  },
  saveIcon: {
    marginRight: "0.4rem",
  },
  headerContainer: {
    justifyContent: "center",
    marginTop: "1.25rem",
    marginBottom: "1.25rem",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
}));

const IntegrationModal: React.FC<Possible> = (props) => {
  const { children, title, open, logoSrc, onClose } = props;
  let onSave;
  let updateSaving;
  let disabled;
  if ("onSave" in props) {
    ({ onSave, updateSaving, disabled } = props);
  }
  const styles = useStyles();
  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={onClose}>
      <DialogContent className={styles.content}>
        <div className={styles.headerContainer}>
          <img alt={`${title} Logo`} src={logoSrc} />
          <Typography variant="h4">{title}</Typography>
        </div>
        {children}
      </DialogContent>
      {onSave && (
        <DialogActions className={styles.actions}>
          <Button
            className={styles.saveButton}
            onClick={onSave}
            disabled={updateSaving || disabled}
            color="primary"
            variant="contained"
          >
            {updateSaving && (
              <FontAwesomeIcon
                className={styles.saveIcon}
                icon={faSpinnerThird}
                spin
              />
            )}
            Save
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default IntegrationModal;
