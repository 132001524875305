import React, { useState } from "react";

import _ from "lodash";
import { gql, useQuery, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { Typography, Link } from "@material-ui/core";
import IntegrationCard from "../Integrations/IntegrationCard";
import { IntegrationsPageIntegrationFragment } from "../Integrations/ALL_INTEGRATIONS_QUERY";
import { useFlashMessage } from "~/components/FlashMessage/FlashMessage";
import GreenhouseApiModal from "./GreenhouseApiModal";
import GreenhouseWebhooksModal from "./GreenhouseWebhooksModal";

import {
  IntegrationEnum,
  CurrentUser,
  DisconnectGreenhouseIntegrationForCustomer,
  FetchAllCustomerIntegrations_currentUserV2_organization_customer_integrations,
} from "~/schemaTypes";

import CURRENT_USER_QUERY from "~/queries/CURRENT_USER_QUERY";
import greenhouseIconLg from "~/icons/greenhouse-icon-lg.svg";

const DISCONNECT_GREENHOUSE_INTEGRATION_FOR_CUSTOMER = gql`
  mutation DisconnectGreenhouseIntegrationForCustomer(
    $typeInput: IntegrationByTypeInput!
  ) {
    disconnectCustomerGreenhouseIntegration {
      success
      message
      code
      customer {
        id
        integration(input: $typeInput) {
          ...IntegrationsPageIntegration
          ... on GreenhouseIntegrationV2 {
            apiKeySuffix
            subdomain
            maildropAddress
          }
        }
      }
    }
  }
  ${IntegrationsPageIntegrationFragment}
`;

enum GreenhouseModalStates {
  WEBHOOKS,
  API,
  NONE,
}

type GreenhouseIntegrationCardProps = {
  integration: FetchAllCustomerIntegrations_currentUserV2_organization_customer_integrations;
};

const GreenhouseIntegrationCard: React.FC<GreenhouseIntegrationCardProps> = ({
  integration,
}) => {
  const { loading, data } = useQuery(gql`
    query GreenhouseIntegrationWebhooksConfigured {
      currentUserV2 {
        id
        organization {
          id
          customer {
            id
            greenhouseWebhooksConfigured
          }
        }
      }
    }
  `);

  const { connected } = integration;
  const [openGreenhouseModal, setOpenGreenhouseModal] = useState<
    GreenhouseModalStates
  >(GreenhouseModalStates.NONE);
  const { setContent } = useFlashMessage();
  const [disconnect, { loading: disconnectSaving }] = useMutation<
    DisconnectGreenhouseIntegrationForCustomer
  >(DISCONNECT_GREENHOUSE_INTEGRATION_FOR_CUSTOMER);

  const onDisconnect = async () => {
    try {
      await disconnect({
        variables: {
          typeInput: {
            type: IntegrationEnum.GREENHOUSE,
          },
        },
        update: (cache) => {
          const currentUser = cache.readQuery<CurrentUser>({
            query: CURRENT_USER_QUERY,
          });

          if (currentUser) {
            _.set(currentUser, "currentUserV2.onboardingComplete", false);
            cache.writeQuery({
              query: CURRENT_USER_QUERY,
              data: currentUser,
            });
          }
        },
      });
    } catch (error) {
      setContent({
        content:
          "Something went wrong disconnecting your Greenhouse integration",
        severity: "error",
      });
      Sentry.captureException(error);
    }
  };

  const onConnect = () => {
    setOpenGreenhouseModal(GreenhouseModalStates.API);
  };

  const hasIssue =
    integration.connected &&
    !loading &&
    !data?.currentUserV2?.organization?.customer?.greenhouseWebhooksConfigured;

  return (
    <>
      <IntegrationCard
        icon={greenhouseIconLg}
        hasIssue={hasIssue}
        label="Greenhouse"
        connected={connected}
        onDisconnect={onDisconnect}
        disconnectSaving={disconnectSaving}
        onConnect={onConnect}
      >
        {connected ? (
          <>
            <Typography>
              <Link
                underline="always"
                component="button"
                onClick={() =>
                  setOpenGreenhouseModal(GreenhouseModalStates.API)
                }
              >
                Update
              </Link>
              &nbsp;Harvest API Key and general settings
            </Typography>
            <Typography>
              <Link
                underline="always"
                component="button"
                onClick={() =>
                  setOpenGreenhouseModal(GreenhouseModalStates.WEBHOOKS)
                }
              >
                Configure
              </Link>
              &nbsp;Webhooks for realtime updates
            </Typography>
          </>
        ) : (
          <Typography>Connect Greenhouse to use Guides</Typography>
        )}
      </IntegrationCard>
      <GreenhouseApiModal
        open={openGreenhouseModal === GreenhouseModalStates.API}
        onClose={() => setOpenGreenhouseModal(GreenhouseModalStates.NONE)}
      />
      <GreenhouseWebhooksModal
        open={openGreenhouseModal === GreenhouseModalStates.WEBHOOKS}
        onClose={() => setOpenGreenhouseModal(GreenhouseModalStates.NONE)}
      />
    </>
  );
};

export default GreenhouseIntegrationCard;
