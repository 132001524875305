import gql from "graphql-tag";

const JourneyStepFragment = gql`
  fragment JourneyStepFragment on TemplateJourneyStep {
    type
    ... on TemplateNoteJourneyStep {
      text
    }
    ... on TemplateActionJourneyStep {
      data {
        type
        ... on TemplateCandidateEmailStepData {
          cc
          bcc
          emailTemplate {
            id
            name
          }
        }
      }
    }
  }
`;

const StageTemplateInstallationFragment = gql`
  fragment StageTemplateInstallationFragment on StageTemplateInstallation {
    id
    atsStageName
    atsStageId
    displayName
    activatedAt
    hidden
    afterSteps {
      ...JourneyStepFragment
    }
    stageTemplate {
      id
      name
      modules {
        id
        type
        data
      }
    }
    eventTemplates {
      id
      title
      position
      description
      hidden
      atsEventTemplateId
      atsEventTemplateName
    }
  }
  ${JourneyStepFragment}
`;

export default gql`
  query GuideTemplateForEditing($input: GuideTemplateInput!) {
    guideTemplate(input: $input) {
      id
      name
      chatEnabled
      interviewProcessEnabled
      jobRoleNameOverride
      stageTemplateInstallations {
        ...StageTemplateInstallationFragment
      }
      onRejectSteps {
        ...JourneyStepFragment
      }
      jobRole {
        id
        name
        atsJobId
        greenhouseJobStages {
          id
          name
          interviews {
            id
            name
          }
        }
      }
    }
    currentUserV2 {
      id
      organization {
        id
        theme
        name
        companyLogoUrl
        stageTemplates {
          id
          name
        }
        emailTemplates {
          id
          name
        }
      }
    }
  }
  ${StageTemplateInstallationFragment}
  ${JourneyStepFragment}
`;
