/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { makeStyles } from "@material-ui/core";
import cx from "classnames";

type DotsType = {
  activeStep: number;
  steps: number;
  onClick: (idx: number) => void;
};

const useStyles = makeStyles({
  root: {
    height: "8px",
    width: "8px",
    margin: "2px",
    cursor: "pointer",
    borderRadius: "50%",
    transition: "300ms ease background-color",
  },
  unselectedDot: {
    backgroundColor: "#F1EDFF",
  },
  selectedDot: {
    backgroundColor: "#7348FF",
  },
});

const Dots = ({ activeStep, steps, onClick }: DotsType) => {
  const classes = useStyles();
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {[...new Array(steps)].map((_value: undefined, idx) => {
        const active = idx === activeStep;
        return (
          <div
            className={cx(classes.root, {
              [classes.unselectedDot]: !active,
              [classes.selectedDot]: active,
            })}
            onClick={(): void => onClick(idx)}
          />
        );
      })}
    </div>
  );
};

export default Dots;
