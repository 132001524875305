import moment from "moment";

type getScheduleGoogleCalendarLink = {
  location?: string;
  startTime: string;
  endTime: string;
  guideUrl: string;
  companyName: string;
  eventTitle?: string;
  eventDescription?: string;
};

type getScheduleGoogleCalendarLinkFunc = (
  args: getScheduleGoogleCalendarLink
) => string;

const getScheduleGoogleCalendarLink: getScheduleGoogleCalendarLinkFunc = ({
  location,
  startTime,
  endTime,
  guideUrl,
  companyName,
  eventTitle,
  eventDescription,
}) => {
  const params = {
    action: "TEMPLATE",
    text: eventTitle || `Interview at ${companyName}`,
    details:
      eventDescription || `Interview with ${companyName}.\n\n${guideUrl}`,
    location: location || "",
    dates: `${moment(startTime).utc().format("YYYYMMDDTHHmmss")}Z/${moment(
      endTime
    )
      .utc()
      .format("YYYYMMDDTHHmmss")}Z`,
  };
  const queryString = Object.keys(params)
    .map(
      (key) =>
        `${encodeURIComponent(key)}=${encodeURIComponent((params as any)[key])}`
    )
    .join("&");

  return `https://www.google.com/calendar/render?${queryString}`;
};

export default getScheduleGoogleCalendarLink;
