import cx from "classnames";
import React from "react";
import moment from "moment";
import { Card, IconButton, Typography, Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import logo from "~/assets/images/logo.svg";

import styles from "./TemplateCard.module.scss";
import useAnalytics from "~/react-hooks/useAnalytics";

type TemplateCardProps = {
  id: string;
  name: string;
  updatedAt: string;
  onArchive?: () => Promise<void>;
  archiveTooltip?: string;
  createdBy?: string;
  onClick?: () => void;
  templateLabel: string;
};

type DefaultCreatedByProps = {
  createdBy?: string;
};

const DefaultCreatedBy: React.FunctionComponent<DefaultCreatedByProps> = ({
  createdBy,
}) => (
  <div className={cx(styles.createdBy)}>
    <Typography variant="subtitle1">Created by</Typography>
    {createdBy ? (
      <Typography variant="subtitle1">&nbsp;User</Typography>
    ) : (
      <div className={cx(styles.logoContainer)}>
        <img
          className={cx(styles.logo)}
          src={logo}
          alt="Resource logo"
          title="Resource logo"
        />
        <Typography variant="body1" className={cx(styles.company)}>
          &nbsp;Resource
        </Typography>
      </div>
    )}
  </div>
);

const TemplateCard: React.FunctionComponent<TemplateCardProps> = ({
  id,
  name,
  onArchive,
  archiveTooltip,
  updatedAt,
  createdBy,
  onClick,
  templateLabel,
}) => {
  const [analytics] = useAnalytics();
  const updatedText = moment(updatedAt).fromNow();

  const handleClick = (): void => {
    analytics.track(`${templateLabel} Template Clicked`, {
      templateId: id,
    });

    if (onClick) {
      onClick();
    }
  };

  return (
    <Card className={styles.templateCard} onClick={handleClick}>
      <div className={styles.cardButton}>
        <div className={styles.titleContainer}>
          <Typography variant="h4">{name}</Typography>
          {onArchive && (
            <Tooltip placement="top" title={archiveTooltip || ""}>
              <IconButton
                className={styles.controlsButton}
                onClick={(e) => {
                  e.stopPropagation();
                  return onArchive();
                }}
              >
                <FontAwesomeIcon
                  className={cx(styles.controlsIcon, styles.delete)}
                  icon={faTrash}
                />
              </IconButton>
            </Tooltip>
          )}
        </div>
        <div className={cx(styles.info)}>
          <DefaultCreatedBy createdBy={createdBy} />
          <Typography variant="subtitle1">Updated {updatedText}</Typography>
        </div>
      </div>
    </Card>
  );
};

export default TemplateCard;
