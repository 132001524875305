import React, { useState } from "react";
import { makeStyles, Button, Menu, MenuItem } from "@material-ui/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/pro-light-svg-icons";
import { ResourceCustomTheme } from "~/styles/config";

const useStyles = makeStyles((theme: ResourceCustomTheme) => ({
  root: {},
  menuAnchor: {
    borderRadius: 4,
    padding: "3px 8px",
    marginBottom: theme.spacing(1),
    marginTop: "0.6rem",
  },
  menuAnchorText: {
    display: "flex",
    alignItems: "center",
  },
  labelStyles: {
    fontSize: "1rem",
  },
  endIcon: {
    marginLeft: 0,
    fontSize: "1rem",
    "& svg": {
      height: "2rem",
    },
  },
}));

// eslint-disable-next-line no-shadow
export enum MenuOption {
  EditStages = 0,
  EditInterviewKit = 1,
  AddStages = 2,
  RemoveStages = 3,
}

type BulkEditMenuProps = {
  disabled?: boolean;
  onSelect(menuOption: MenuOption): void;
};

const BulkEditMenu: React.FC<BulkEditMenuProps> = ({
  disabled = false,
  onSelect,
}) => {
  const styles = useStyles();
  const [
    filterMenuAnchorEl,
    setFilterMenuAnchorEl,
  ] = useState<HTMLButtonElement | null>(null);

  return (
    <div className={styles.root}>
      <Button
        disabled={disabled}
        className={styles.menuAnchor}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={(event) => {
          setFilterMenuAnchorEl(event.currentTarget);
        }}
        classes={{
          label: styles.labelStyles,
          endIcon: styles.endIcon,
        }}
        endIcon={<FontAwesomeIcon icon={faEllipsisV} size="lg" fixedWidth />}
      >
        Bulk Actions{" "}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={filterMenuAnchorEl}
        keepMounted
        open={Boolean(filterMenuAnchorEl)}
        onClose={() => {
          setFilterMenuAnchorEl(null);
        }}
      >
        <MenuItem
          onClick={() => {
            onSelect(MenuOption.EditStages);
            setFilterMenuAnchorEl(null);
          }}
        >
          Edit Stages
        </MenuItem>
        <MenuItem
          onClick={() => {
            onSelect(MenuOption.AddStages);
            setFilterMenuAnchorEl(null);
          }}
        >
          Add Stages
        </MenuItem>
        <MenuItem
          onClick={() => {
            onSelect(MenuOption.RemoveStages);
            setFilterMenuAnchorEl(null);
          }}
        >
          Remove Stages
        </MenuItem>
        <MenuItem
          onClick={() => {
            onSelect(MenuOption.EditInterviewKit);
            setFilterMenuAnchorEl(null);
          }}
        >
          Edit Interview Descriptions
        </MenuItem>
      </Menu>
    </div>
  );
};

export default BulkEditMenu;
