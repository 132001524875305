import React, { FunctionComponent, useState } from "react";
import {
  Typography,
  Card,
  Dialog,
  Button,
  TextField,
  Divider,
  useTheme,
  Fab,
} from "@material-ui/core";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import _ from "lodash";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import {
  FetchCompanyData_currentUserV2_organization_values as OrganizationValue,
  UpdateValueForOrganization,
  UpdateValueForOrganizationVariables,
  DeleteValueForOrganization,
  DeleteValueForOrganizationVariables,
  CreateValueForOrganization,
  CreateValueForOrganizationVariables,
} from "~/schemaTypes";
import useAnalytics from "~/react-hooks/useAnalytics";
import DialogTitle from "~/components/Dialog/DialogTitle/DialogTitle";

import styles from "./Values.module.scss";

const CREATE_VALUE_MUTATION = gql`
  mutation CreateValueForOrganization(
    $input: CreateValueForOrganizationInput!
  ) {
    createValueForOrganization(input: $input) {
      id
      values {
        id
        title
        description
      }
    }
  }
`;

const UPDATE_VALUE_MUTATION = gql`
  mutation UpdateValueForOrganization(
    $input: UpdateValueForOrganizationInput!
  ) {
    updateValueForOrganization(input: $input) {
      id
      title
      description
    }
  }
`;

const DELETE_VALUE_MUTATION = gql`
  mutation DeleteValueForOrganization(
    $input: DeleteValueForOrganizationInput!
  ) {
    deleteValueForOrganization(input: $input) {
      id
      values {
        id
        title
        description
      }
    }
  }
`;

type ValuesProps = {
  values: OrganizationValue[];
};

const Values: FunctionComponent<ValuesProps> = ({ values }) => {
  const [analytics] = useAnalytics();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState({
    id: "",
    title: "",
    description: "",
  });
  const [createValue] = useMutation<
    CreateValueForOrganization,
    CreateValueForOrganizationVariables
  >(CREATE_VALUE_MUTATION);
  const [updateValue] = useMutation<
    UpdateValueForOrganization,
    UpdateValueForOrganizationVariables
  >(UPDATE_VALUE_MUTATION);
  const [deleteValue] = useMutation<
    DeleteValueForOrganization,
    DeleteValueForOrganizationVariables
  >(DELETE_VALUE_MUTATION);

  const handleValueCardClick = ({
    id,
    title,
    description,
  }: {
    id: string;
    title: string;
    description: string | null;
  }): void => {
    if (analytics) {
      analytics.track("Company Page Value Card Clicked");
    }
    setValue({
      id,
      title,
      description: description || "",
    });
    setOpen(true);
  };

  const handleAddValueClick = (): void => {
    setValue({
      id: "",
      title: "New Value",
      description: "",
    });
    setOpen(true);
  };

  const handleAddValue = async (): Promise<void> => {
    createValue({
      variables: {
        input: {
          description: value.description,
          title: value.title,
        },
      },
      optimisticResponse: {
        createValueForOrganization: {
          __typename: "Organization",
          id: "",
          values: [
            ...values,
            {
              __typename: "Value",
              ...value,
              id: "temp-id",
            },
          ],
        },
      },
    });
    setOpen(false);
    if (analytics) {
      analytics.track("Company Page Value Card Created");
    }
  };

  const handleDeleteValue = async (): Promise<void> => {
    if (value.id) {
      deleteValue({
        variables: {
          input: {
            id: value.id,
          },
        },
        optimisticResponse: {
          deleteValueForOrganization: {
            __typename: "Organization",
            id: "",
            values: [..._.filter(values, ({ id }) => id === value.id)],
          },
        },
      });
      setOpen(false);
      if (analytics) {
        analytics.track("Company Page Value Card Deleted");
      }
    }
  };

  const handleUpdateValue = async (): Promise<void> => {
    if (value.id && value.title) {
      updateValue({
        variables: {
          input: {
            value,
          },
        },
        optimisticResponse: {
          updateValueForOrganization: {
            __typename: "Value",
            ...value,
          },
        },
      });
      setOpen(false);
      if (analytics) {
        analytics.track("Company Page Value Card Updated");
      }
    }
  };

  return (
    <div className={styles.valuesContainer}>
      <div className={styles.headerCont}>
        <Typography className={styles.text} variant="h3">
          <span role="img" aria-label="Pray Hands">
            🙏
          </span>{" "}
          Values
        </Typography>
        <Fab
          onClick={(): void => {
            handleAddValueClick();
          }}
          color="primary"
          size="small"
          aria-label="add value"
        >
          <FontAwesomeIcon icon={faPlus} size="lg" />
        </Fab>
      </div>
      <div className={styles.valueCardsCont}>
        {values.map(({ id, title, description }) => (
          <Card
            key={id}
            className={styles.valueCard}
            onClick={(): void => {
              handleValueCardClick({
                id,
                title,
                description,
              });
            }}
          >
            <Typography className={styles.headerText} variant="h4">
              {title}
            </Typography>
            <Typography className={styles.description} variant="body1">
              {description}
            </Typography>
          </Card>
        ))}
      </div>
      <Dialog open={open} fullWidth onClose={(): void => setOpen(false)}>
        <DialogTitle onClose={(): void => setOpen(false)}>
          {value.id ? "Update a Value" : "Create a Value"}
        </DialogTitle>
        <Divider />
        <div className={styles.dialogCont}>
          <TextField
            label="Title"
            variant="outlined"
            placeholder="Transparency"
            value={value.title}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
              const { value: title } = e.target;
              setValue((prevValue) => ({
                ...prevValue,
                title,
              }));
            }}
            fullWidth
          />
          <TextField
            variant="outlined"
            placeholder="Why is this your value? Attract like minded candidates who share your values."
            value={value.description}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
              const { value: description } = e.target;
              setValue((prevValue) => ({
                ...prevValue,
                description,
              }));
            }}
            multiline
            fullWidth
          />
          <div className={styles.buttonContainer}>
            {value.id && (
              <Button
                variant="outlined"
                size="large"
                style={{
                  color: theme.palette.error.main,
                }}
                onClick={(): void => {
                  handleDeleteValue();
                }}
              >
                Delete
              </Button>
            )}
            <Button
              disabled={!value.title}
              variant="contained"
              color="primary"
              size="large"
              onClick={(): void => {
                if (value.id) {
                  handleUpdateValue();
                } else {
                  handleAddValue();
                }
              }}
            >
              {value.id ? "Update" : "Create"}
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default Values;
