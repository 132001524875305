import React, { useCallback } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";

import {
  Button,
  Typography,
  DialogActions,
  makeStyles,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import zoomIconLg from "~/icons/zoom-icon-lg.svg";
import {
  ZoomIntegrationForCustomer,
  UpdateZoomIntegrationForCustomer,
  UpdateZoomIntegrationForCustomerVariables,
  IntegrationEnum,
  ZoomIntegrationForCustomer_currentUserV2_organization_customer_integration as ZoomIntegration,
} from "~/schemaTypes";
import { useAuth0, Auth0Provider } from "~/react-auth0";
import Loading from "~/components/Loading/Loading";
import IntegrationModal from "../Integrations/IntegrationModal";

const CustomerFragment = gql`
  fragment ZoomModalFragment on Customer {
    id
    integration(input: $typeInput) {
      type
      connected
    }
  }
`;

const ZOOM_INTEGRATION_FOR_CUSTOMER = gql`
  query ZoomIntegrationForCustomer($typeInput: IntegrationByTypeInput!) {
    currentUserV2 {
      id
      organization {
        id
        customer {
          ...ZoomModalFragment
        }
      }
    }
  }
  ${CustomerFragment}
`;

const UPDATE_ZOOM_INTEGRATION_FOR_CUSTOMER = gql`
  mutation UpdateZoomIntegrationForCustomer(
    $input: UpdateCustomerZoomIntegrationInput!
    $typeInput: IntegrationByTypeInput!
  ) {
    updateCustomerZoomIntegration(input: $input) {
      success
      message
      code
      customer {
        ...ZoomModalFragment
      }
    }
  }
  ${CustomerFragment}
`;

const useStyles = makeStyles(() => ({
  actions: {
    padding: "2rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  description: {
    marginTop: "2rem",
    marginBottom: "1.5rem",
  },
  checkbox: {
    marginLeft: "0.4rem",
    marginBottom: "0.8rem",
  },
  formAction: {
    width: "10rem",
  },
}));

type ZoomIntegrationModalProps = {
  open: boolean;
  onClose: () => void;
};

const ZoomIntegrationModal: React.FC<ZoomIntegrationModalProps> = ({
  open,
  onClose,
}) => {
  const styles = useStyles();
  const { data } = useQuery<ZoomIntegrationForCustomer>(
    ZOOM_INTEGRATION_FOR_CUSTOMER,
    {
      variables: {
        typeInput: {
          type: IntegrationEnum.ZOOM,
        },
      },
    }
  );
  const integration = data?.currentUserV2?.organization.customer
    .integration as ZoomIntegration | null;

  const [update, { loading: updateSaving }] = useMutation<
    UpdateZoomIntegrationForCustomer,
    UpdateZoomIntegrationForCustomerVariables
  >(UPDATE_ZOOM_INTEGRATION_FOR_CUSTOMER);
  const { loginWithPopup, loading: auth0Loading } = useAuth0();

  const handleUpdate = useCallback(async () => {
    const newUser = await loginWithPopup({
      connection: "zoom",
      access_type: "offline",
    });
    if (!newUser) {
      throw new Error("Unable to retrieve user after login with Zoom");
    }

    await update({
      variables: {
        input: {
          auth0UserId: newUser.sub,
        },
        typeInput: {
          type: IntegrationEnum.ZOOM,
        },
      },
    });

    onClose();
  }, [onClose, loginWithPopup, update]);

  return (
    <IntegrationModal
      open={open}
      onClose={onClose}
      title="Zoom"
      logoSrc={zoomIconLg}
    >
      {auth0Loading ? (
        <Loading />
      ) : (
        <>
          <Typography className={styles.description} variant="body1">
            The Guide Zoom integration allows you to automatically generate a
            Zoom link for any interviews that require one. Toggle on Zoom links
            in the Interview Detail, and every time that interview is scheduled,
            the Guide will include a Zoom link.
          </Typography>
          <DialogActions className={styles.actions}>
            <Auth0Provider
              domain={process.env.REACT_APP_AUTH0_TENANT_DOMAIN!}
              timeout="1000"
              client_id={process.env.REACT_APP_AUTH0_GUIDES_CLIENT_ID!}
              connection="zoom"
              redirect_uri={window.location.origin}
            >
              <Button
                className={styles.formAction}
                onClick={handleUpdate}
                disabled={updateSaving}
                color="primary"
                variant="contained"
              >
                {updateSaving ? (
                  <FontAwesomeIcon
                    style={{
                      marginRight: "5px",
                    }}
                    icon={faSpinnerThird}
                    spin
                  />
                ) : (
                  ""
                )}
                {integration?.connected
                  ? "Re-connect your Zoom Account"
                  : "Connect your Zoom Account"}
              </Button>
            </Auth0Provider>
          </DialogActions>
        </>
      )}
    </IntegrationModal>
  );
};

export default ZoomIntegrationModal;
