/* eslint-disable import/prefer-default-export */
import { useQuery } from "@apollo/client";
import {
  StageTemplateForEditing_stageTemplate as StageTemplate,
  StageTemplateForEditing,
  StageTemplateForEditingVariables,
} from "~/schemaTypes";
import STAGE_TEMPLATE_FOR_EDITING_QUERY from "~/queries/STAGE_TEMPLATE_FOR_EDITING_QUERY";

type useStageTemplateReturnType = {
  loading: boolean;
  stageTemplate?: StageTemplate;
};

export const useStageTemplate = ({
  stageTemplateId,
}: {
  stageTemplateId?: string;
}): useStageTemplateReturnType => {
  const { data: stageTemplateData, loading: stageTemplateLoading } = useQuery<
    StageTemplateForEditing,
    StageTemplateForEditingVariables
  >(STAGE_TEMPLATE_FOR_EDITING_QUERY, {
    variables: {
      input: {
        id: stageTemplateId,
      },
    },
  });
  const stageTemplate = stageTemplateData?.stageTemplate;

  return {
    loading: stageTemplateLoading,
    stageTemplate: stageTemplate || undefined,
  };
};
