import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";
import {
  Chip,
  Grid,
  Typography,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import { FeatureFlagEnum, strings } from "@resource/common";
import { useFlags } from "@resource/client-ffs";
import BulkEditMenu, { MenuOption } from "./BulkEdit/BulkEditMenu";
import HASURA_GET_GUIDE_TEMPLATES from "./HASURA_GUIDE_TEMPLATES@hasura";
import { ResourceCustomTheme } from "~/styles/config";
import BulkEditInterviewKitsModal from "./BulkEdit/EditInterviewKits/BulkEditInterviewKitsModal";
import BulkEditStageTemplateInstallationsModal from "./BulkEdit/EditStageTemplateInstallations/BulkEditStageTemplateInstallationsModal";
import { BulkStageEditMode } from "./BulkEdit/EditStageTemplateInstallations/types";

import {
  GetOrganizationRoleJourneys,
  GetOrganizationRoleJourneysVariables,
} from "~/schemaTypesHasura";
import usePrevious from "~/react-hooks/usePrevious";

import JourneyTable from "./JourneyTable";

const useStyles = makeStyles((theme: ResourceCustomTheme) =>
  createStyles({
    light: {
      color: theme.colors.MidnightBlue(200),
    },
    headerCont: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    listItemName: {
      flex: 2,
    },
    listItemTemplates: {
      flex: 3,
    },
    greenhouseJobName: {
      marginLeft: "8px",
    },
    header: {
      [theme.breakpoints.down("xs")]: {
        textAlign: "center",
      },
    },
    menu: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      textAlign: "right",
      marginTop: 20,
    },
    selectedRoleJourneys: {
      marginRight: "0.4rem",
    },
  })
);

const SYNC_JOBS_FROM_ATS_MUTATION = gql`
  mutation SyncATSJobsForJourneys {
    syncATSJobs {
      success
    }
  }
`;

type RoleJourneysProps = Record<string, never>;

const baseGuideTemplateWhere = {
  job_role: {
    _and: [
      {
        greenhouseJobStagesData: {
          _is_null: false,
        },
      },
      {
        greenhouseData: {
          _contains: {
            confidential: false,
          },
        },
      },
      {
        _or: [
          {
            greenhouseData: {
              _contains: {
                status: "draft",
              },
            },
          },
          {
            greenhouseData: {
              _contains: {
                status: "open",
              },
            },
          },
        ],
      },
    ],
  },
};

const RoleJourneys: React.FC<RoleJourneysProps> = () => {
  const styles = useStyles();
  const [editInterviewKitsModalOpen, setEditInterviewKitsModalOpen] = useState(
    false
  );
  const [
    editInterviewKitsModalMounted,
    setEditInterviewKitsModalMounted,
  ] = useState(false);
  const [editStagesModalMode, setEditStagesModalMode] = useState<
    BulkStageEditMode
  >();
  const [editStagesModalMounted, setEditStagesModalMounted] = useState(false);
  const [selectedGuideTemplateIds, setSelectedGuideTemplateIds] = useState<
    string[]
  >([]);

  const {
    [_.camelCase(
      FeatureFlagEnum.BulkEditInterviewKits
    )]: bulkEditInterviewKitsFlag,
  } = useFlags();
  const { data, loading, refetch, variables } = useQuery<
    GetOrganizationRoleJourneys,
    GetOrganizationRoleJourneysVariables
  >(HASURA_GET_GUIDE_TEMPLATES, {
    notifyOnNetworkStatusChange: true,
    variables: {
      guideTemplatesOrderBy: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        updatedAt: "desc",
      },
      guideTemplatesWhere: baseGuideTemplateWhere,
    },
  });
  const previousData = usePrevious(data);

  const [syncATSJobs] = useMutation(SYNC_JOBS_FROM_ATS_MUTATION, {
    refetchQueries: ["GetOrganizationRoleJourneys"],
  });

  useEffect(() => {
    const sync = async () => {
      await syncATSJobs();
    };
    sync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFetchData = useCallback(
    async ({ departments, offices, search }) => {
      const searchFilter = search
        ? {
            _or: [
              { name: { _ilike: `%${search}%` } },
              { job_role: { atsJobId: { _ilike: `%${search}%` } } },
              {
                job_role: {
                  greenhouseData: {
                    _contains: { requisition_id: search },
                  },
                },
              },
            ],
          }
        : {};

      const departmentFilter = departments?.length
        ? {
            _or: _.map(departments, (department) => ({
              job_role: {
                greenhouseData: {
                  _contains: {
                    departments: [{ name: department }],
                  },
                },
              },
            })),
          }
        : {};

      const officeFilter = offices?.length
        ? {
            _or: _.map(offices, (office) => ({
              job_role: {
                greenhouseData: {
                  _contains: {
                    offices: [{ name: office }],
                  },
                },
              },
            })),
          }
        : {};

      const andClause = _.reject(
        [searchFilter, departmentFilter, officeFilter],
        _.isEmpty
      );
      const guideTemplatesWhere = andClause.length
        ? {
            _and: [baseGuideTemplateWhere, ...andClause],
          }
        : baseGuideTemplateWhere;

      const shouldRefetch = !_.isEqual(
        guideTemplatesWhere,
        variables?.guideTemplatesWhere
      );

      if (shouldRefetch) {
        await refetch({
          guideTemplatesWhere,
        });
      }
    },
    [refetch, variables]
  );

  const currentData = data ?? previousData;

  const guideTemplates = useMemo(
    () =>
      _(
        currentData?.get_current_user_v2?.[0]?.currentOrganization
          ?.guide_templates
      )
        .filter("job_role.atsJobId")
        .orderBy(["updatedAt"], ["desc"])
        .value(),
    [currentData]
  );
  const departments = useMemo(
    () =>
      _.chain(
        currentData?.get_current_user_v2?.[0]?.currentOrganization?.job_roles
      )
        .flatMap("department")
        .compact()
        .uniq()
        .value(),
    [currentData]
  );
  const offices = useMemo(
    () =>
      _.chain(
        currentData?.get_current_user_v2?.[0]?.currentOrganization?.job_roles
      )
        .flatMap("office")
        .compact()
        .uniq()
        .value(),
    [currentData]
  );

  const handleSelectionChange = useCallback(
    (newSelectedGuideTemplateIds: string[]) => {
      if (!_.isEqual(selectedGuideTemplateIds, newSelectedGuideTemplateIds)) {
        setSelectedGuideTemplateIds(newSelectedGuideTemplateIds);
      }
    },
    [selectedGuideTemplateIds, setSelectedGuideTemplateIds]
  );

  const selectedGuideTemplates = _.filter(guideTemplates, ({ id }) => {
    return _.includes(selectedGuideTemplateIds, id);
  });

  return (
    <>
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          justify="space-between"
        >
          <Grid item xs={12} sm={8} className={styles.header}>
            <Typography variant="h2">Role Journeys</Typography>
          </Grid>
          <Grid item xs={12} sm={4} className={styles.menu}>
            {bulkEditInterviewKitsFlag && (
              <>
                {selectedGuideTemplates.length ? (
                  <Chip
                    className={styles.selectedRoleJourneys}
                    color="primary"
                    label={`${strings.pluralize(
                      "Role Journey",
                      selectedGuideTemplates.length
                    )} Selected`}
                  />
                ) : null}

                <BulkEditMenu
                  disabled={!selectedGuideTemplates.length}
                  onSelect={(menuOption) => {
                    switch (menuOption) {
                      case MenuOption.EditStages:
                        setEditStagesModalMode(BulkStageEditMode.Edit);
                        setEditStagesModalMounted(true);
                        break;
                      case MenuOption.AddStages:
                        setEditStagesModalMode(BulkStageEditMode.Add);
                        setEditStagesModalMounted(true);
                        break;
                      case MenuOption.RemoveStages:
                        setEditStagesModalMode(BulkStageEditMode.Remove);
                        setEditStagesModalMounted(true);
                        break;
                      case MenuOption.EditInterviewKit:
                        setEditInterviewKitsModalOpen(true);
                        setEditInterviewKitsModalMounted(true);
                        break;
                      default:
                        break;
                    }
                  }}
                />
              </>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <JourneyTable
            loading={loading}
            guideTemplates={guideTemplates}
            departments={departments}
            offices={offices}
            onSelectionChange={handleSelectionChange}
            onFetchData={onFetchData}
          />
        </Grid>
      </Grid>
      {editInterviewKitsModalMounted && (
        <BulkEditInterviewKitsModal
          open={editInterviewKitsModalOpen}
          onCancel={() => setEditInterviewKitsModalOpen(false)}
          onClose={() => setEditInterviewKitsModalOpen(false)}
          onExited={() => {
            setEditInterviewKitsModalMounted(false);
          }}
          jobRoleIds={_.map(selectedGuideTemplates, "job_role.id")}
        />
      )}{" "}
      {editStagesModalMounted && (
        <BulkEditStageTemplateInstallationsModal
          open={!_.isUndefined(editStagesModalMode)}
          mode={editStagesModalMode}
          onCancel={() => setEditStagesModalMode(undefined)}
          onClose={() => setEditStagesModalMode(undefined)}
          onExited={() => {
            setEditStagesModalMounted(false);
          }}
          guideTemplateIds={_.map(selectedGuideTemplates, "id")}
        />
      )}
    </>
  );
};

export default RoleJourneys;
