import React, { FunctionComponent, useState, useEffect } from "react";

import { TextField } from "@material-ui/core";
import { EditModulesGlobalProps } from "../EditModuleContainer";
import EDIT_TIME_MUTATION from "./EDIT_TIME_MUTATION";

type EditTimeProps = {
  moduleId: string;
  header?: string;
};

const EditTime: FunctionComponent<EditTimeProps & EditModulesGlobalProps> = ({
  setHandleSaveMutation,
  setHandleSaveMutationInput,
  moduleId,
  header,
}) => {
  const [headerOverride, setHeaderOverride] = useState<string>(
    header || "Time"
  );

  useEffect(() => {
    setHandleSaveMutation(EDIT_TIME_MUTATION);
    setHandleSaveMutationInput({
      input: {
        moduleId,
        header: headerOverride,
      },
    });
  }, [
    headerOverride,
    moduleId,
    setHandleSaveMutation,
    setHandleSaveMutationInput,
  ]);

  return (
    <TextField
      fullWidth
      value={headerOverride}
      onChange={(e) => setHeaderOverride(e.target.value)}
      style={{ marginBottom: 6 }}
      label="Header"
    />
  );
};

export default EditTime;
