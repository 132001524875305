import gql from "graphql-tag";
import INTERVIEWER_FRAGMENT from "~/fragments/INTERVIEWER_FRAGMENT";

export const INTERVIEWER_QUERY = gql`
  query InterviewerForEdit($input: InterviewerQueryInput) {
    interviewer(input: $input) {
      ...InterviewerDetailsFragment
    }
  }
  ${INTERVIEWER_FRAGMENT}
`;

export const INTERVIEWERS_QUERY = gql`
  query InterviewersForTeamPage($input: InterviewersInput) {
    currentUserV2 {
      id
      organization {
        id
        interviewers(input: $input) {
          ...InterviewerDetailsFragment
        }
      }
    }
  }
  ${INTERVIEWER_FRAGMENT}
`;
