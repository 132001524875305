import gql from "graphql-tag";

export default gql`
  mutation UpdateMissionForStageTemplateModuleInstallation(
    $input: UpdateMissionInstallationForStageTemplateV2Input!
  ) {
    updateMissionInstallationForStageTemplateV2(input: $input) {
      success
      stageTemplate {
        id
        name
        updatedAt
      }
    }
  }
`;
