const useTextSearch = ({
  queryString,
  searchArray,
  searchKey,
}: {
  queryString: string;
  searchArray: Record<string, string | any>[];
  searchKey: string;
}): [Record<string, string | any>[]] => {
  const filteredArray = searchArray.filter((object) =>
    object[searchKey].toLowerCase().includes(queryString.toLowerCase())
  );
  return [filteredArray];
};

export default useTextSearch;
