import React from "react";
import {
  Avatar,
  Grid,
  Typography,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  makeStyles,
  Button,
} from "@material-ui/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faSpinnerThird,
} from "@fortawesome/pro-light-svg-icons";
import { ResourceCustomTheme } from "~/styles/config";
import ConfirmationButton from "~/components/ConfirmationButton/ConfirmationButton";

const useStyles = makeStyles<ResourceCustomTheme, { hasIssue: boolean }>(
  (theme) => ({
    root: ({ hasIssue }) => ({
      height: "20rem",
      position: "relative",
      padding: "0.5rem",
      width: "20rem",
      ...(hasIssue
        ? {
            borderColor: theme.colors.BananaYellow,
            position: "relative",
            overflow: "inherit",
            "&:after": {
              animation: "3s infinite linear",
              position: "absolute",
              content: '""',
              top: "-0.4rem",
              right: "-0.4rem",
              height: "0.8rem",
              width: "0.8rem",
              borderRadius: "50%",
              background: theme.colors.BananaYellow,
            },
          }
        : {}),
    }),
    actions: {
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
    },
    installed: {
      color: theme.colors.MarineGreen,
    },
    installedText: {
      padding: "0.5rem",
    },
    installedIcon: {
      paddingRight: "0.25rem",
    },
    disconnect: {
      textAlign: "center",
    },
  })
);

type CardProps = {
  icon: string;
  connected: boolean;
  label: string;
  children: React.ReactNode;
  onDisconnect: () => void;
  onConnect: () => Promise<void> | void;
  hasIssue?: boolean;
  disconnectSaving: boolean;
};

const IntegrationCard: React.FC<CardProps> = ({
  icon,
  label,
  children,
  connected,
  onDisconnect,
  onConnect,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  hasIssue = false,
  disconnectSaving,
}) => {
  const styles = useStyles({
    hasIssue: false,
  });

  return (
    <Card className={styles.root} variant="outlined">
      <CardHeader avatar={<Avatar src={icon} />} title={label} />
      <CardContent>{children}</CardContent>
      <CardActions classes={{ root: styles.actions }}>
        <Grid container justify="center" spacing={2}>
          {connected ? (
            <>
              <Grid item xs={6} className={styles.installed}>
                <Typography color="inherit" className={styles.installedText}>
                  <FontAwesomeIcon
                    className={styles.installedIcon}
                    icon={faCheckCircle}
                  />
                  <span>Installed</span>
                </Typography>
              </Grid>
              <Grid item xs={6} className={styles.disconnect}>
                <ConfirmationButton
                  onClick={onDisconnect}
                  disabled={disconnectSaving}
                  color="secondary"
                >
                  {disconnectSaving && (
                    <FontAwesomeIcon
                      style={{
                        marginRight: "5px",
                      }}
                      icon={faSpinnerThird}
                      spin
                    />
                  )}
                  Disconnect
                </ConfirmationButton>
              </Grid>
            </>
          ) : (
            <Grid item xs={4} className={styles.installed}>
              <Button
                color="primary"
                fullWidth
                variant="outlined"
                onClick={onConnect}
              >
                Connect
              </Button>
            </Grid>
          )}
        </Grid>
      </CardActions>
    </Card>
  );
};

export default IntegrationCard;
