import gql from "graphql-tag";

export default gql`
  query GetOrgStageTemplates {
    currentUserV2 {
      id
      organization {
        id
        stageTemplates {
          id
          name
          updatedAt
          createdBy
        }
      }
    }
  }
`;
