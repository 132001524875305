import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import {
  SlackIntegrationForCustomer,
  SlackIntegrationForCustomerVariables,
  IntegrationEnum,
  UpdateSlackIntegrationForCustomer,
  UpdateSlackIntegrationForCustomerVariables,
  SyncSlackOnSlackIntegrationsPageLoad,
} from "~/schemaTypes";
import CustomerWithSlackIntegrationFragment from "~/fragments/CUSTOMER_WITH_SLACK_INTEGRATION_FRAGMENT";

const SLACK_INTEGRATION_FOR_CUSTOMER = gql`
  query SlackIntegrationForCustomer($typeInput: IntegrationByTypeInput!) {
    currentUserV2 {
      id
      organization {
        id
        customer {
          ...customerFragmentForSlack
        }
      }
    }
  }
  ${CustomerWithSlackIntegrationFragment}
`;

export const useSlackIntegration = () => {
  const { data, loading, refetch } = useQuery<
    SlackIntegrationForCustomer,
    SlackIntegrationForCustomerVariables
  >(SLACK_INTEGRATION_FOR_CUSTOMER, {
    variables: {
      typeInput: {
        type: IntegrationEnum.SLACK,
      },
    },
  });

  return {
    data,
    loading,
    refetch,
  };
};

const SYNC_SLACK_MUTATION = gql`
  mutation SyncSlackOnSlackIntegrationsPageLoad(
    $typeInput: IntegrationByTypeInput!
  ) {
    syncSlack {
      success
      customer {
        ...customerFragmentForSlack
      }
    }
  }
  ${CustomerWithSlackIntegrationFragment}
`;

export const useSyncSlack = () => {
  const [syncSlack, { loading }] = useMutation<
    SyncSlackOnSlackIntegrationsPageLoad
  >(SYNC_SLACK_MUTATION);

  return { syncSlack, loading };
};

const UPDATE_SLACK_INTEGRATION_FOR_CUSTOMER = gql`
  mutation UpdateSlackIntegrationForCustomer(
    $input: UpdateCustomerSlackIntegrationInput!
    $typeInput: IntegrationByTypeInput!
  ) {
    updateCustomerSlackIntegration(input: $input) {
      success
      message
      code
      customer {
        ...customerFragmentForSlack
      }
    }
  }
  ${CustomerWithSlackIntegrationFragment}
`;

export const useUpdateSlack = () => {
  const [updateSlack, { loading }] = useMutation<
    UpdateSlackIntegrationForCustomer,
    UpdateSlackIntegrationForCustomerVariables
  >(UPDATE_SLACK_INTEGRATION_FOR_CUSTOMER);

  return { updateSlack, loading };
};
