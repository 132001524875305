import _ from "lodash";
import DOMPurify from "dompurify";
import React, { FunctionComponent, useState, useContext } from "react";
import moment from "moment";
import cx from "classnames";
import { Typography, Avatar, makeStyles } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons";

import { useFlags } from "@resource/client-ffs";
import { FeatureFlagEnum } from "@resource/common";
import { AnalyticsContext } from "~/analytics";
import { getValidUrls } from "~/utils";

import { StageById_stage_sortedVisibleEvents as Event } from "~/schemaTypes";

import ConferenceLinkCard from "~/components/modules/shared/ConferenceLinkCard";
import TechScreenLinkCard from "~/components/modules/shared/TechScreenLinkCard";
import EventModal from "./EventModal";

import { ResourceCustomTheme } from "~/styles/config";
import ProminentInstructionsCard from "../../shared/ProminentInstructionsCard";

const useStyles = makeStyles((_theme: ResourceCustomTheme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginTop: 5,
    padding: 16,
    border: "solid 1px #f1f1f1",
    borderRadius: 8,
    cursor: "pointer",
    "&:hover": {
      border: "solid 1px rgb(204, 204, 204)",
    },
  },
  timeAndInterviwers: {
    display: "flex",
    justifyContent: "space-between",
  },
  timeAndTitle: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    wordBreak: "break-all",
  },
  time: {
    lineHeight: "16px",
  },
  titleAndIcon: {
    display: "flex",
    alignItems: "center",
  },
  titleIcon: {
    marginTop: 4,
    marginLeft: 5,
    height: 10,
  },
  interviewers: {
    display: "flex",
    marginLeft: 16,
  },
  interviewerAvatar: {
    position: "relative",
    marginLeft: "-15px",
    border: "solid 2px white",
  },
  disabled: {
    opacity: 0.4,
    "&:hover": {
      border: "solid 1px #f1f1f1",
    },
  },
}));

type EventProps = {
  event: Event;
  showInterviewers?: boolean;
  unifiedConferenceLink?: string;
};

const Event: FunctionComponent<EventProps> = ({
  event,
  showInterviewers = true,
  unifiedConferenceLink,
}) => {
  const {
    title,
    startTime,
    endTime,
    remoteInstructions,
    techScreenLink,
    prominentInstructions,
    zoomLink,
    interviewers,
    description,
  } = event;

  const [openDialog, setOpenDialog] = useState(false);
  const {
    [_.camelCase(
      FeatureFlagEnum.ProminentInstructionsEventModule
    )]: prominentInstructionsEnabled,
  } = useFlags();
  const classes = useStyles();
  const analytics = useContext(AnalyticsContext);
  const startTimeText = moment(startTime).format("h:mm a");
  const eventInPast = moment(endTime).isBefore(moment());
  const urlsInDesc = description ? getValidUrls(description) : [];
  // We don't show HTML in the card title
  const sanitizedTitle = DOMPurify.sanitize(title, { ALLOWED_TAGS: [] });
  const finalProminentInstructions = _.reject(prominentInstructions, {
    url: unifiedConferenceLink,
  });

  return (
    <>
      <div
        tabIndex={0}
        role="button"
        className={cx(classes.root, {
          [classes.disabled]: eventInPast,
        })}
        onClick={() => {
          setOpenDialog(true);
          analytics.track("Event Clicked", {
            eventTitle: title,
          });
        }}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === "Enter") {
            setOpenDialog(true);
            analytics.track("Event Clicked", {
              eventTitle: title,
            });
          }
        }}
      >
        <div className={classes.timeAndInterviwers}>
          <div className={classes.timeAndTitle}>
            <Typography className={classes.time} variant="subtitle1">
              {startTimeText}
            </Typography>
            <div className={classes.titleAndIcon}>
              <Typography
                dangerouslySetInnerHTML={{
                  __html: sanitizedTitle,
                }}
                variant="body1"
                className={classes.title}
              />
              <FontAwesomeIcon
                className={classes.titleIcon}
                icon={faChevronRight}
                size="xs"
              />
            </div>
          </div>
          {showInterviewers && (
            <div className={classes.interviewers}>
              {(interviewers || []).map(
                ({ id, imageUrl, firstName, lastName }) => (
                  <Avatar
                    key={id}
                    className={classes.interviewerAvatar}
                    src={imageUrl || undefined}
                    alt={`${firstName} ${lastName} Thumbnail`}
                  >
                    {`${firstName[0]}${lastName[0]}`}
                  </Avatar>
                )
              )}
            </div>
          )}
        </div>
        {prominentInstructionsEnabled &&
          !eventInPast &&
          _.map(finalProminentInstructions, (i) => (
            <ProminentInstructionsCard key={i.id} label={i.label} url={i.url} />
          ))}
        {!prominentInstructionsEnabled &&
          (zoomLink || remoteInstructions || !!urlsInDesc.length) && (
            <ConferenceLinkCard
              disabled={eventInPast}
              instructions={zoomLink || remoteInstructions || urlsInDesc[0]}
            />
          )}
        {!prominentInstructionsEnabled && techScreenLink && (
          <TechScreenLinkCard techScreenLink={techScreenLink} />
        )}
      </div>
      <EventModal
        open={openDialog}
        event={event}
        onClose={() => setOpenDialog(false)}
      />
    </>
  );
};
export default Event;
