/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from "lodash";
import * as Sentry from "@sentry/react";
import React, { FunctionComponent, useState, useCallback } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { faArrowLeft, faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Link } from "react-router-dom";
import {
  Typography,
  Dialog,
  Card,
  CardActionArea,
  Divider,
  Fade,
  Tooltip,
  Button,
  Switch,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFlags } from "@resource/client-ffs";
import {
  FeatureFlagEnum,
  RemovableModules,
  ModuleEnum,
  SingleUsePerStageModules,
  NonListableModules as DefaultNonListableModules,
  TempFixedModulePositionEnum,
} from "@resource/common";

import EmailTemplateSelect from "./EmailTemplateSelect/EmailTemplateSelect";
import Loading from "~/components/Loading/Loading";
import {
  StageTemplateForEditing as StageTemplateInterface,
  StageTemplateForEditingVariables,
  AddStageTemplateModuleToStageTemplate,
  AddStageTemplateModuleToStageTemplateVariables,
  RemoveStageTemplateModuleFromStageTemplate,
  RemoveStageTemplateModuleFromStageTemplateVariables,
  ReorderStageTemplateModuleInstallation,
  ReorderStageTemplateModuleInstallationVariables,
  StageTemplateForEditing_stageTemplate_modules as StageTemplateModule,
} from "~/schemaTypes";
import {
  HasuraReorderStageTemplateModuleInstallations,
  HasuraReorderStageTemplateModuleInstallationsVariables,
} from "~/schemaTypesHasura";
import EditableTextField from "~/components/EditableTextField/EditableTextField";
import useAnalytics from "~/react-hooks/useAnalytics";
import StageTemplateWithModulesFragment from "~/fragments/STAGE_TEMPLATE_WITH_MODULES_FRAGMENT";
import DialogTitle from "~/components/Dialog/DialogTitle/DialogTitle";
import { useFlashMessage } from "~/components/FlashMessage/FlashMessage";

import EditModuleModal from "./EditModuleModal/EditModuleModal";
import styles from "./StageTemplateEditor.module.scss";
import STAGE_TEMPLATE_FOR_EDITING_QUERY from "~/queries/STAGE_TEMPLATE_FOR_EDITING_QUERY";
import { HASURA_REORDER_STAGE_TEMPLATE_MODULE_INSTALLATIONS } from "./HASURA_MUTATIONS@hasura";
import Company from "~/pages/GuidesHomepage/Settings/Company/Company";
import { useInlineCompanyEditor } from "~/pages/utils/InlineCompanyEditorContextProvider";
import EditModuleList, {
  ModuleForEditing,
} from "../EditModuleList/EditModuleList";
import ModuleDisplayInfo from "~/components/utils/module-display-info";

type DataType = Record<string, any>;

const AddStageTemplateModuleMutation = gql`
  mutation AddStageTemplateModuleToStageTemplate(
    $input: CreateStageTemplateModuleInstallationInput!
  ) {
    createStageTemplateModuleInstallation(input: $input) {
      ...stageTemplateWithModules
    }
  }
  ${StageTemplateWithModulesFragment}
`;

const RemoveStageTemplateModuleMutation = gql`
  mutation RemoveStageTemplateModuleFromStageTemplate(
    $input: DeleteStageTemplateModuleInstallationInput!
  ) {
    deleteStageTemplateModuleInstallation(input: $input) {
      ...stageTemplateWithModules
    }
  }
  ${StageTemplateWithModulesFragment}
`;

const STAGE_TEMPLATE_MUTATION = gql`
  mutation stageTemplateUpdate($input: stageTemplateUpdateInput!) {
    updateStageTemplate(input: $input) {
      id
      name
    }
  }
`;

export const STAGE_TEMPLATE_MODULE_REORDER_MUTATION = gql`
  mutation ReorderStageTemplateModuleInstallation(
    $input: StageTemplateModuleUpdateInstallationReorderInput!
  ) {
    reOrderStageTemplateModuleInstallation(input: $input) {
      ...stageTemplateWithModules
    }
  }
  ${StageTemplateWithModulesFragment}
`;

const UneditableModuleTypes = [
  ModuleEnum.CandidatePostInterviewFeedback,
  ModuleEnum.GreetingHeader,
];

type StageTemplateEditorProps = {
  stageTemplateId?: string;
};

const StageTemplateEditor: FunctionComponent<StageTemplateEditorProps> = ({
  stageTemplateId,
}) => {
  const [analytics] = useAnalytics();
  const [selectedModule, setSelectedModule] = useState<{
    id: string;
    type: ModuleEnum;
    data: DataType;
  } | null>(null);
  const [editModuleOpen, setEditModuleOpen] = useState(false);
  const { setContent } = useFlashMessage();
  const [addModuleOpened, setAddModuleOpened] = useState<boolean>(false);
  const [moduleIdJustAdded, setModuleIdJustAdded] = useState<
    string | undefined
  >();
  const {
    setShowingCompanyComponents,
    showingCompanyComponents,
  } = useInlineCompanyEditor();
  const largeSizeMatch = useMediaQuery("(min-width:1400px)");

  const {
    [_.camelCase(
      FeatureFlagEnum.InterviewSchedulerOnGuides
    )]: interviewSchedulerOnGuidesFlag,
    [_.camelCase(
      FeatureFlagEnum.GuideTemplatesEditor
    )]: guideTemplatesEditorFlag,
    [_.camelCase(
      FeatureFlagEnum.AllowRemoveAllStageTemplateModules
    )]: allowRemoveAllStageTemplateModulesFlag,
    [_.camelCase(
      FeatureFlagEnum.HasuraReorderModules
    )]: hasuraReorderModulesFlag,
  } = useFlags();

  const UnavailableModules = [
    ...(guideTemplatesEditorFlag ? [ModuleEnum.Chat] : []),
    ...(interviewSchedulerOnGuidesFlag ? [] : [ModuleEnum.InterviewScheduler]),
  ];

  const NonListableModules = _.difference(
    DefaultNonListableModules,
    UnavailableModules
  );

  const ValidModuleTypes = _(ModuleEnum)
    .keys()
    .without("PhoneScreenHeader", "Expectations")
    .value();

  const AddableModules: ModuleEnum[] = allowRemoveAllStageTemplateModulesFlag
    ? (_.difference(ValidModuleTypes, UnavailableModules) as ModuleEnum[])
    : (_.difference(
        RemovableModules,
        UnavailableModules,
        NonListableModules
      ) as ModuleEnum[]);

  const [addModule, { loading: addModuleLoading }] = useMutation<
    AddStageTemplateModuleToStageTemplate,
    AddStageTemplateModuleToStageTemplateVariables
  >(AddStageTemplateModuleMutation, {
    update(cache, { data: newData }) {
      const data = cache.readQuery<
        StageTemplateInterface,
        StageTemplateForEditingVariables
      >({
        query: STAGE_TEMPLATE_FOR_EDITING_QUERY,
        variables: {
          input: {
            id: stageTemplateId,
          },
        },
      });
      if (data && newData?.createStageTemplateModuleInstallation) {
        cache.writeQuery<
          StageTemplateInterface,
          StageTemplateForEditingVariables
        >({
          query: STAGE_TEMPLATE_FOR_EDITING_QUERY,
          data: {
            ...data,
            stageTemplate: newData.createStageTemplateModuleInstallation,
          },
        });
      }
    },
  });

  const [removeModule] = useMutation<
    RemoveStageTemplateModuleFromStageTemplate,
    RemoveStageTemplateModuleFromStageTemplateVariables
  >(RemoveStageTemplateModuleMutation);

  const [reorderModules] = useMutation<
    ReorderStageTemplateModuleInstallation,
    ReorderStageTemplateModuleInstallationVariables
  >(STAGE_TEMPLATE_MODULE_REORDER_MUTATION);

  const [hasuraReorderModules, { loading: hasuraReorderLoading }] = useMutation<
    HasuraReorderStageTemplateModuleInstallations,
    HasuraReorderStageTemplateModuleInstallationsVariables
  >(HASURA_REORDER_STAGE_TEMPLATE_MODULE_INSTALLATIONS);

  const {
    data: stageTemplateData,
    loading: stageTemplateLoading,
    refetch: refetchStageTemplate,
  } = useQuery<StageTemplateInterface, StageTemplateForEditingVariables>(
    STAGE_TEMPLATE_FOR_EDITING_QUERY,
    {
      variables: {
        input: {
          id: stageTemplateId,
        },
      },
      onCompleted: (data) => {
        analytics.page("Edit Stage Template", {
          stageTemplateId: data?.stageTemplate?.id,
        });
      },
    }
  );

  const stageTemplate = stageTemplateData?.stageTemplate;
  const emailTemplate = stageTemplate?.emailTemplate;
  const allEmailTemplates =
    stageTemplateData?.currentUserV2?.organization?.emailTemplates || [];

  const handleAddModule = async (moduleType: ModuleEnum): Promise<void> => {
    analytics.track("Edit Stage Template: Clicked Module to Add", {
      moduleType,
    });

    const existingListableModules = _.reject(
      stageTemplate?.modules,
      ({ type }) => _.includes(NonListableModules, type)
    );

    if (!stageTemplate || !existingListableModules) {
      return;
    }

    const isFixedModule = (type: ModuleEnum) => {
      return _.find(
        NonListableModules,
        (nonListableType: string) => nonListableType === type
      );
    };

    try {
      const startPosition = isFixedModule(moduleType)
        ? TempFixedModulePositionEnum[moduleType]
        : existingListableModules.length;
      const result = await addModule({
        variables: {
          input: {
            stageTemplateId: stageTemplate.id,
            stageModuleType: moduleType,
            position: startPosition,
          },
        },
      });
      const latestModules =
        result.data?.createStageTemplateModuleInstallation.modules;
      const newModule = _.differenceBy(
        latestModules,
        stageTemplate?.modules,
        "id"
      )[0];
      setModuleIdJustAdded(newModule.id);
      setTimeout(() => setModuleIdJustAdded(undefined), 3000);
      setAddModuleOpened(false);
    } catch (err) {
      setContent({
        content: "Something went wrong adding that module!",
        severity: "error",
      });
    }
  };

  const handleRemoveModule = useCallback(
    async (id: string, type: string): Promise<void> => {
      analytics.track("Edit Stage Template: Remove Module Button Clicked", {
        moduleType: type,
      });

      const modules = stageTemplate?.modules;

      if (!stageTemplate || !modules) {
        return;
      }
      try {
        await removeModule({
          variables: {
            input: {
              stageTemplateModuleInstallationId: id,
            },
          },
        });
      } catch (err) {
        setContent({
          content: "Something went wrong removing that module!",
          severity: "error",
        });
      }
    },
    [analytics, removeModule, setContent, stageTemplate]
  );

  const handleReorderModules = useCallback(
    async ({ modules: updatedModules }: { modules: ModuleForEditing[] }) => {
      const existingNonListableModules = _.filter(
        stageTemplate?.modules,
        ({ type }) => _.includes(NonListableModules, type)
      );

      if (!stageTemplate) {
        return;
      }

      if (hasuraReorderModulesFlag) {
        await hasuraReorderModules({
          variables: {
            stageTemplateId: stageTemplate.id,
            moduleIds: _.map(updatedModules, "id"),
          },
          refetchQueries: ["StageTemplateForEditing"],
          awaitRefetchQueries: true,
        });
      } else {
        try {
          await reorderModules({
            variables: {
              input: {
                stageTemplateId: stageTemplate.id,
                modules: updatedModules.map(
                  (module: ModuleForEditing, index: number) => ({
                    id: module.id,
                    type: module.type,
                    position: index,
                  })
                ),
              },
            },
            optimisticResponse: {
              reOrderStageTemplateModuleInstallation: {
                ...stageTemplate,
                modules: [
                  ...updatedModules.map<StageTemplateModule>(
                    (module: ModuleForEditing, index: number) => ({
                      ...module,
                      __typename: "StageTemplateModule",
                      position: index,
                    })
                  ),
                  ...existingNonListableModules.map<StageTemplateModule>(
                    (module) => ({
                      ...module,
                      __typename: "StageTemplateModule",
                    })
                  ),
                ],
              },
            },
          });
        } catch (error) {
          Sentry.captureException(error);
          refetchStageTemplate();
        }
      }
    },
    [
      NonListableModules,
      reorderModules,
      refetchStageTemplate,
      stageTemplate,
      hasuraReorderModules,
      hasuraReorderModulesFlag,
    ]
  );

  const availableModuleTypes = _.difference(
    AddableModules,
    NonListableModules,
    _.reject(
      SingleUsePerStageModules,
      (type) =>
        !_.find(stageTemplate?.modules, {
          type,
        })
    )
  );

  const handleAddModuleButtonClick = useCallback(() => {
    analytics.track("Edit Stage Template: Add Module Button Clicked");
    setAddModuleOpened(true);
  }, [analytics]);

  const handleNonListableModulesChange = (
    newValue: boolean,
    moduleType: ModuleEnum
  ) => {
    if (stageTemplate) {
      const module = stageTemplate.modules.find(
        ({ type }) => moduleType === type
      );
      if (newValue === true) {
        handleAddModule(moduleType);
      } else if (module) {
        handleRemoveModule(module.id, moduleType);
      }
    }
  };

  if (stageTemplateLoading) {
    return <Loading />;
  }

  if (!stageTemplate) {
    return <p> Something went wrong here. </p>;
  }

  const { id, name } = stageTemplate;

  const modules = _.map(stageTemplate.modules, (module) => {
    const type = module.type as ModuleEnum;
    const canEdit = !UneditableModuleTypes.includes(type);
    return {
      ...module,
      type,
      canEdit,
    };
  });

  return (
    <Fade in timeout={1000}>
      <>
        <div className={styles.stageTemplateEditorContainer}>
          {showingCompanyComponents?.length ? (
            <>
              <Button
                className={styles.backButton}
                onClick={(): void => {
                  if (analytics) {
                    analytics.track(
                      "Back to Template Editor Onboarding Clicked"
                    );
                  }
                  setShowingCompanyComponents(undefined);
                }}
                variant="text"
              >
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  size="1x"
                  style={{ marginRight: "5px" }}
                />
                Back to Guide Editor
              </Button>
              <Company showingComponents={showingCompanyComponents} />
            </>
          ) : (
            <>
              <Button
                className={styles.backButton}
                component={Link}
                onClick={(): void => {
                  if (analytics) {
                    analytics.track("Back to Stage Templates Clicked");
                  }
                }}
                to={
                  guideTemplatesEditorFlag ? "/templates/stages" : "/templates"
                }
                variant="text"
              >
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  size="1x"
                  style={{ marginRight: "5px" }}
                />
                {guideTemplatesEditorFlag
                  ? "Back to All Stage Templates"
                  : "Back to All Guide Templates"}
              </Button>
              <div className={styles.headerContainer}>
                <div className={styles.templateName}>
                  {stageTemplate.createdBy ? (
                    <EditableTextField
                      defaultText={name}
                      mutation={STAGE_TEMPLATE_MUTATION}
                      width={`${largeSizeMatch ? "500" : "300"}px`}
                      generateMutationVariables={(
                        value
                      ): Record<string, unknown> => ({
                        input: {
                          id,
                          name: value,
                        },
                      })}
                      typographyVariant="h3"
                    />
                  ) : (
                    <Typography variant="h2">{name}</Typography>
                  )}
                </div>
              </div>
              <div className={styles.stageTemplateConfigSettings}>
                {!guideTemplatesEditorFlag && allEmailTemplates.length > 1 && (
                  <EmailTemplateSelect
                    stageTemplateId={stageTemplate?.id}
                    allEmailTemplates={allEmailTemplates}
                    emailTemplateId={emailTemplate?.id}
                  />
                )}
                <div className={styles.nonListableModulesConfig}>
                  {NonListableModules.map((type) => {
                    const value = !!_.find(stageTemplate?.modules, { type });
                    return (
                      <div
                        key={`${type}-switch`}
                        className={styles.nonListableModule}
                      >
                        <Switch
                          className={styles.moduleSwitch}
                          checked={value}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ): void => {
                            handleNonListableModulesChange(
                              e.target.checked,
                              type
                            );
                          }}
                        />
                        <Typography variant="body1">
                          {ModuleDisplayInfo[type]?.header}
                        </Typography>
                        <Tooltip
                          placement="right"
                          title={ModuleDisplayInfo[type]?.description}
                        >
                          <div className={styles.nonListableInfoIcon}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                          </div>
                        </Tooltip>
                      </div>
                    );
                  })}
                </div>
              </div>
              {hasuraReorderLoading ? (
                <Loading />
              ) : (
                <EditModuleList
                  setSelectedModule={setSelectedModule}
                  setEditModuleOpen={setEditModuleOpen}
                  removeModule={handleRemoveModule}
                  onModuleReorder={handleReorderModules}
                  moduleIdJustAdded={moduleIdJustAdded}
                  modules={modules as ModuleForEditing[]}
                  handleAddModuleButtonClick={handleAddModuleButtonClick}
                />
              )}
            </>
          )}
        </div>
        {selectedModule && (
          <EditModuleModal
            refetchStageTemplate={refetchStageTemplate}
            stageTemplateId={stageTemplate.id}
            editModuleOpen={editModuleOpen}
            selectedModule={selectedModule}
            setEditModuleOpen={setEditModuleOpen}
          />
        )}
        <Dialog
          open={addModuleOpened}
          onClose={(): void => setAddModuleOpened(false)}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle onClose={(): void => setAddModuleOpened(false)}>
            Add a Section
          </DialogTitle>
          <Divider />
          <div className={styles.modalContainer}>
            <div className={styles.addModulesModalContainer}>
              {_.map(availableModuleTypes, (moduleType) => (
                <Card key={moduleType} className={styles.addModulesCard}>
                  <CardActionArea
                    className={styles.actionArea}
                    disabled={addModuleLoading || !addModuleOpened}
                    onClick={(): void => {
                      handleAddModule(moduleType);
                    }}
                  >
                    <Typography className={styles.headerText} variant="h4">
                      {ModuleDisplayInfo[moduleType]?.emoji}
                      &nbsp;
                      {ModuleDisplayInfo[moduleType]?.header}
                    </Typography>
                    <Typography variant="body1">
                      {ModuleDisplayInfo[moduleType]?.description}
                    </Typography>
                  </CardActionArea>
                </Card>
              ))}
            </div>
          </div>
        </Dialog>
      </>
    </Fade>
  );
};

export default StageTemplateEditor;
