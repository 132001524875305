import _ from "lodash";
import { makeVar } from "@apollo/client";
import { InMemoryCache } from "@apollo/client/cache";

import introspectionQueryResultData from "./utils/auth/fragmentTypes.json";

let initialCurrentOrganizationId: string | null = null;
try {
  initialCurrentOrganizationId = localStorage.getItem(
    "__PersistedCurrentOrganizationIdStateKeyV2"
  );
} catch (error) {
  console.info("Not reading persisted organization key");
  console.info(error);
}
export const currentOrganizationId = makeVar<string | null>(
  initialCurrentOrganizationId || null
);

export const cache = new InMemoryCache({
  possibleTypes: _.reduce(
    // eslint-disable-next-line no-underscore-dangle
    introspectionQueryResultData.__schema.types,
    (acc, type) => {
      acc[type.name] = _.map(type.possibleTypes, "name");
      return acc;
    },
    {}
  ),
  typePolicies: {
    Query: {
      fields: {
        currentOrganizationId: {
          read() {
            return currentOrganizationId();
          },
        },
      },
    },
  },
});
