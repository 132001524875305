import React from "react";
import { Typography, makeStyles, useTheme } from "@material-ui/core";
import cx from "classnames";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ResourceCustomTheme } from "~/styles/config";
import TimelineIndicator from "~/components/TimelineIndicator";
import Loading from "~/components/Loading/Loading";

const useStyles = makeStyles((_theme: ResourceCustomTheme) => ({
  root: {
    display: "flex",
    width: "100%",
    zIndex: 1,
    alignItems: "center",
    cursor: "pointer",
    marginLeft: 20,
  },
  showingAdd: {
    height: 48,
  },
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    alignSelf: "stretch",
  },
}));

type TimelineSpacerProps = {
  showAdd?: boolean;
  onAdd: () => void;
  addLabel?: string;
  loading?: boolean;
};

const TimelineSpacer: React.FC<TimelineSpacerProps> = ({
  onAdd,
  showAdd = true,
  addLabel = "Add an Action",
  loading = false,
}) => {
  const classes = useStyles();
  const theme = useTheme<ResourceCustomTheme>();

  return (
    <div
      className={cx(classes.root, {
        [classes.showingAdd]: showAdd,
      })}
      onClick={() => {
        if (!loading) {
          onAdd();
        }
      }}
    >
      {showAdd && (
        <div className={classes.container}>
          <TimelineIndicator
            icon={<FontAwesomeIcon icon={faPlus} size="sm" />}
            color={theme.colors.CrystalBlue()}
          />
          <Typography variant="subtitle2">{addLabel}</Typography>
          {loading && <Loading />}
        </div>
      )}
    </div>
  );
};

export default TimelineSpacer;
