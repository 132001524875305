import { useQuery } from "@apollo/client";
import { LocationsQueryForEditLocationV2 } from "~/schemaTypes";
import FIND_ORG_LOCATIONS_QUERY from "~/queries/FIND_ORG_LOCATIONS_QUERY";

const useOrgLocations = () => {
  const { data: locationsData, loading: locationsLoading } = useQuery<
    LocationsQueryForEditLocationV2
  >(FIND_ORG_LOCATIONS_QUERY, {
    variables: {
      locationInput: {
        isArchived: true,
      },
    },
  });

  return {
    locations: locationsData?.currentUserV2?.organization.locations,
    locationsLoading,
  };
};

export default useOrgLocations;
