import React, { FunctionComponent, useState, useEffect } from "react";

import { TextField } from "@material-ui/core";
import { EditModulesGlobalProps } from "../EditModuleContainer";
import EDIT_CULTURE_MUTATION from "./EDIT_CULTURE_MUTATION";
import DropzoneWithPreview from "../../Dropzone/DropzoneWithPreview";

import styles from "./EditCulture.module.scss";

type EditCultureProps = {
  companyTeamPhotoUrl: string;
  moduleId: string;
  header?: string;
};

const EditCulture: FunctionComponent<
  EditCultureProps & EditModulesGlobalProps
> = ({
  setHandleSaveMutation,
  setHandleSaveMutationInput,
  companyTeamPhotoUrl = "",
  moduleId,
  header,
}) => {
  const [companyTeamPhotoUrlOverride, setCompanyTeamPhotoUrl] = useState<
    string
  >(companyTeamPhotoUrl);
  const [headerOverride, setHeaderOverride] = useState<string>(header || "");

  useEffect(() => {
    setHandleSaveMutation(EDIT_CULTURE_MUTATION);
    setHandleSaveMutationInput({
      input: {
        moduleId,
        companyTeamPhotoUrl: companyTeamPhotoUrlOverride,
        header: headerOverride,
      },
    });
  }, [
    headerOverride,
    companyTeamPhotoUrlOverride,
    moduleId,
    setHandleSaveMutation,
    setHandleSaveMutationInput,
  ]);

  return (
    <div className={styles.formContainer}>
      <TextField
        fullWidth
        value={headerOverride}
        onChange={(e) => setHeaderOverride(e.target.value)}
        style={{ marginBottom: 6 }}
        label="Header"
      />
      <DropzoneWithPreview
        photoUrl={companyTeamPhotoUrlOverride}
        setPhotoUrl={setCompanyTeamPhotoUrl}
      />
    </div>
  );
};

export default EditCulture;
