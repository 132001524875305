import React from "react";
import DetailedFeedback, {
  SubmitDetailedFeedbackCallback,
} from "./DetailedFeedback/DetailedFeedback";

type WhatWentWellProps = {
  onSubmitDetailedFeedback: SubmitDetailedFeedbackCallback;
};

const WhatWentWell: React.FC<WhatWentWellProps> = ({
  onSubmitDetailedFeedback,
}) => (
  <DetailedFeedback
    onSubmitDetailedFeedback={onSubmitDetailedFeedback}
    title="What went well?"
  />
);
export default WhatWentWell;
