import React, { FunctionComponent, useState, useEffect } from "react";

import { TextField } from "@material-ui/core";
import { EditModulesGlobalProps } from "../EditModuleContainer";
import EDIT_SCHEDULE_MUTATION from "./EDIT_SCHEDULE_MUTATION";

type EditScheduleProps = {
  moduleId: string;
  header?: string;
};

const EditSchedule: FunctionComponent<
  EditScheduleProps & EditModulesGlobalProps
> = ({
  setHandleSaveMutation,
  setHandleSaveMutationInput,
  moduleId,
  header,
}) => {
  const [headerOverride, setHeaderOverride] = useState<string>(
    header || "Schedule"
  );

  useEffect(() => {
    setHandleSaveMutation(EDIT_SCHEDULE_MUTATION);
    setHandleSaveMutationInput({
      input: {
        moduleId,
        header: headerOverride,
      },
    });
  }, [
    headerOverride,
    moduleId,
    setHandleSaveMutation,
    setHandleSaveMutationInput,
  ]);

  return (
    <TextField
      fullWidth
      value={headerOverride}
      onChange={(e) => setHeaderOverride(e.target.value)}
      style={{ marginBottom: 6 }}
      label="Header"
    />
  );
};

export default EditSchedule;
