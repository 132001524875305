import React, { FunctionComponent } from "react";
import gql from "graphql-tag";
import { Typography } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import DropzoneWithPreview from "~/components/Dropzone/DropzoneWithPreview";
import {
  UpdateOrganizationInterviewerImage,
  UpdateOrganizationInterviewerImageVariables,
  UpdateOrganizationLogo,
  UpdateOrganizationLogoVariables,
} from "~/schemaTypes";

import styles from "./Images.module.scss";

type ImagesProps = {
  defaultAvatarImageUrl: string;
  logoUrl: string;
};

const UPDATE_ORGANIZATION_LOGO_MUTATION = gql`
  mutation UpdateOrganizationLogo($input: UpdateOrganizationLogoInput!) {
    updateOrganizationLogo(input: $input) {
      id
      companyLogoUrl
    }
  }
`;

const UPDATE_ORGANIZATION_INTERVIEWER_IMAGE_MUTATION = gql`
  mutation UpdateOrganizationInterviewerImage(
    $input: UpdateOrganizationInterviewerImageInput!
  ) {
    updateOrganizationInterviewerImage(input: $input) {
      organization {
        id
        defaultAvatarImageUrl
      }
    }
  }
`;

const Images: FunctionComponent<ImagesProps> = ({
  logoUrl,
  defaultAvatarImageUrl,
}) => {
  const [updateLogo] = useMutation<
    UpdateOrganizationLogo,
    UpdateOrganizationLogoVariables
  >(UPDATE_ORGANIZATION_LOGO_MUTATION);

  const [updateInterviewerImage] = useMutation<
    UpdateOrganizationInterviewerImage,
    UpdateOrganizationInterviewerImageVariables
  >(UPDATE_ORGANIZATION_INTERVIEWER_IMAGE_MUTATION);

  const updateCompanyLogo = (newUrl: string): void => {
    updateLogo({
      variables: {
        input: {
          url: newUrl,
        },
      },
      optimisticResponse: {
        updateOrganizationLogo: {
          __typename: "Organization",
          id: "",
          companyLogoUrl: newUrl,
        },
      },
    });
  };

  const updateOrganizationInterviewerImage = (newUrl: string) => {
    updateInterviewerImage({
      variables: {
        input: {
          url: newUrl,
        },
      },
    });
  };

  return (
    <div className={styles.logoContainer}>
      <div className={styles.updateLogoCont}>
        <Typography className={styles.text} variant="h3">
          Logo
        </Typography>
        <DropzoneWithPreview
          photoUrl={logoUrl || ""}
          setPhotoUrl={updateCompanyLogo}
          previewStyle="contain"
        />
      </div>

      <div className={styles.updateInterviewerCont}>
        <Typography className={styles.text} variant="h3">
          Default Interviewer Image
        </Typography>
        <DropzoneWithPreview
          photoUrl={defaultAvatarImageUrl || ""}
          setPhotoUrl={updateOrganizationInterviewerImage}
          previewStyle="contain"
        />
      </div>
    </div>
  );
};

export default Images;
