import React from "react";

import {
  Paper,
  Grid,
  TextField,
  Typography,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { ResourceCustomTheme } from "~/styles/config";

const WebhookCopyRow = ({ label, value }) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={5}>
        {label}
      </Grid>
      <Grid item xs={12} md={7}>
        <TextField
          size="small"
          fullWidth
          inputProps={{
            readOnly: true,
          }}
          defaultValue={value}
        />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles<ResourceCustomTheme>((theme) =>
  createStyles({
    root: {
      padding: "1rem",
    },
    waiting: {
      marginLeft: "0.4rem",
      color: theme.colors.KarlGray(700),
    },
    waitingContainer: {
      display: "flex",
    },
    copyRows: {
      marginTop: "0.8rem",
    },
    spinner: {
      marginLeft: "0.4rem",
    },
    installedText: {
      color: theme.colors.MarineGreen,
    },
    installedIcon: {
      marginLeft: "0.4rem",
    },
  })
);

type WebhookRowType = {
  displayName: string;
  setup: boolean;
  url: string;
  lastReceivedAt?: Date;
};

type GreenhouseWebhookProps = {
  secretKey: string;
  webhook: WebhookRowType;
};

const GreenhouseWebhook: React.FC<GreenhouseWebhookProps> = ({
  secretKey,
  webhook,
}) => {
  const styles = useStyles();

  return (
    <Paper className={styles.root} variant="outlined">
      <Grid container>
        <Grid item xs={12} md={6}>
          <Typography>{webhook.displayName}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          {webhook.setup && (
            <Typography className={styles.installedText}>
              Installed
              <FontAwesomeIcon
                className={styles.installedIcon}
                icon={webhook.lastReceivedAt ? faCheckCircle : faInfoCircle}
              />
            </Typography>
          )}
        </Grid>
      </Grid>
      {!webhook.setup && (
        <div className={styles.copyRows}>
          <WebhookCopyRow
            label="Name"
            value={`Guide - ${webhook.displayName}`}
          />
          <WebhookCopyRow label="When" value={webhook.displayName} />
          <WebhookCopyRow label="Endpoint URL" value={webhook.url} />
          <WebhookCopyRow label="Secret Key" value={secretKey} />
          <WebhookCopyRow
            label="Error recipient email"
            value="error@resource.io"
          />
        </div>
      )}
    </Paper>
  );
};

export default GreenhouseWebhook;
