import React, { FunctionComponent, useContext } from "react";
import { Typography, Chip, Avatar } from "@material-ui/core";

import styles from "./InfoChips.module.scss";
import { AnalyticsContext } from "~/analytics";

type ChipInfo = {
  icon?: string;
  iconImageUrl?: string;
  label: string;
  link?: string;
};

type InfoChipsProps = {
  header?: string;
  description?: string;
  infoArray: ChipInfo[];
};

const InfoChips: FunctionComponent<InfoChipsProps> = ({
  header,
  description,
  infoArray,
}) => {
  const analytics = useContext(AnalyticsContext);

  return (
    <div className={styles.chipsInfoContainer}>
      {header && (
        <Typography className={styles.header} variant="h4">
          {header}
        </Typography>
      )}
      {description && (
        <Typography className={styles.description} variant="body1">
          {description}
        </Typography>
      )}
      <div className={styles.chipsContainer}>
        {infoArray.map(({ icon, iconImageUrl, link, label }) => (
          <React.Fragment key={label}>
            {iconImageUrl ? (
              <Chip
                className={styles.chip}
                avatar={<Avatar alt={`${label} Avatar`} src={iconImageUrl} />}
                variant="outlined"
                label={label}
                component={link ? "a" : "div"}
                href={link}
                target={link ? "_blank" : ""}
                clickable={!!link}
                onClick={() => {
                  if (link) {
                    analytics.track("Chip Clicked", {
                      sectionHeader: header,
                      label,
                      url: link,
                    });
                  }
                }}
              />
            ) : (
              <Chip
                className={styles.chip}
                label={`${icon || ""} ${label}`}
                variant="outlined"
                component={link ? "a" : "div"}
                href={link}
                target={link ? "_blank" : ""}
                clickable={!!link}
                onClick={() => {
                  if (link) {
                    analytics.track("Chip Clicked", {
                      sectionHeader: header,
                      label,
                      url: link,
                    });
                  }
                }}
              />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default InfoChips;
