import React, { ReactNode } from "react";
import cx from "classnames";
import { Typography, makeStyles } from "@material-ui/core";

import { ResourceCustomTheme } from "~/styles/config";

const useStyles = makeStyles((theme: ResourceCustomTheme) => ({
  disabled: {
    "& *": {
      ...theme.mixins.disabledStyles,
    },
  },
  timelineIndicator: {
    zIndex: 1,
    marginLeft: 13,
    flexShrink: 0,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    width: 26,
    height: 34,
    marginRight: 10,
    backgroundColor: "white",
  },
  timelineIconCircle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 26,
    height: 26,
    borderRadius: 13,
  },
  timelineIcon: {
    color: "white",
    margin: 0,
    fontSize: 18,
  },
  clickable: {
    cursor: "pointer",
  },
}));

type TimelineIndicatorProps = {
  icon: ReactNode;
  disabled?: boolean;
  color: string;
  animate?: boolean;
  onClick?(): void;
};

const TimelineIndicator: React.FC<TimelineIndicatorProps> = ({
  disabled = false,
  icon,
  color,
  onClick,
}) => {
  const classes = useStyles();
  return (
    <div
      className={cx(classes.timelineIndicator, {
        [classes.disabled]: disabled,
        [classes.clickable]: !!onClick,
      })}
      onClick={onClick}
    >
      <div
        className={classes.timelineIconCircle}
        style={{
          backgroundColor: color,
        }}
      >
        <Typography className={classes.timelineIcon} variant="body1">
          {icon}
        </Typography>
      </div>
    </div>
  );
};

export default TimelineIndicator;
