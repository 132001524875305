/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

const useStyles = makeStyles(() => ({
  imgContainer: {
    height: "148px",
    display: "flex",
  },
  img: {
    maxHeight: "100%",
    display: "block",
    maxWidth: "100%",
    overflow: "hidden",
    margin: "auto",
    cursor: "pointer",
    borderRadius: "4px",
  },
  title: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
    cursor: "pointer",
    "& p": {
      marginRight: "0px",
      transition: "100ms ease margin",
    },
    "&:hover": {
      "& p": {
        marginLeft: "4px",
        marginRight: "4px",
        transition: "100ms ease margin",
      },
    },
  },
  text: {
    fontWeight: "bold",
  },
}));

type InterviewerProps = {
  id: string;
  firstName: string;
  imageUrl: string;
  onClick: () => void;
};

const Interviewer = ({
  id,
  firstName,
  imageUrl,
  onClick,
}: InterviewerProps) => {
  const classes = useStyles();
  return (
    <div className={classes.img} key={id}>
      <div className={classes.imgContainer}>
        <img
          className={classes.img}
          src={imageUrl}
          alt={firstName}
          onClick={onClick}
        />
      </div>
      <div className={classes.title}>
        <Typography className={classes.text} onClick={onClick}>
          {firstName}
        </Typography>
        <KeyboardArrowRightIcon />
      </div>
    </div>
  );
};

export default Interviewer;
