import _ from "lodash";
import moment from "moment";

export type InterviewBreak = {
  startTime: string;
  lengthMinutes: number;
  endTime: string;
  hidden?: boolean;
};

type DateGroup<T> = {
  day?: string;
  startTime: string;
  endTime: string;
  events: (T | InterviewBreak)[];
};

interface Event {
  startTime: string;
  endTime: string;
  hidden: boolean;
}

export default <T extends Event>(
  allEvents: T[] = [],
  showHidden = false
): DateGroup<T>[] => {
  const events = showHidden ? allEvents : _.filter(allEvents, (e) => !e.hidden);
  const eventsWithDay = events.map((event) => {
    const newEvent = {
      day: moment(event.startTime).format("dddd Do MMM YYYY"),
      ...event,
    };
    return newEvent;
  });
  const groupedByDay = _(eventsWithDay)
    .groupBy(({ day }) => day)
    .value();
  const newDateGroups: DateGroup<T>[] = [];
  for (let i = 0; i < _.keys(groupedByDay).length; i += 1) {
    const key = _.keys(groupedByDay)[i];
    const sortedEvents = groupedByDay[key].sort((dateA, dateB) => {
      const { startTime: stateTimeA } = dateA;
      const { startTime: stateTimeB } = dateB;
      if (moment(stateTimeA).isAfter(stateTimeB)) {
        return 1;
      }
      if (moment(stateTimeB).isAfter(stateTimeA)) {
        return -1;
      }
      return 0;
    });
    const eventsWithBreaks: (InterviewBreak | T)[] = [];
    _.forEach(sortedEvents, (event, index) => {
      const prevEventEnd = sortedEvents[index - 1]?.endTime;
      if (prevEventEnd) {
        const minutesApart = moment(event.startTime).diff(
          moment(prevEventEnd),
          "minutes"
        );
        if (minutesApart > 0) {
          eventsWithBreaks.push({
            startTime: prevEventEnd,
            endTime: event.startTime,
            lengthMinutes: minutesApart,
          });
        }
      }

      eventsWithBreaks.push(event);
    });

    if (sortedEvents.length > 0) {
      newDateGroups.push({
        day: key,
        startTime: eventsWithBreaks[0].startTime,
        endTime: _.last(eventsWithBreaks)?.endTime || "",
        events: eventsWithBreaks,
      });
    }
  }
  return newDateGroups.sort((dateA: DateGroup<T>, dateB: DateGroup<T>) => {
    const { startTime: stateTimeA } = dateA;
    const { startTime: stateTimeB } = dateB;
    if (moment(stateTimeA).isAfter(stateTimeB)) {
      return 1;
    }
    if (moment(stateTimeB).isAfter(stateTimeA)) {
      return -1;
    }
    return 0;
  });
};
