import React from "react";
import {
  makeStyles,
  TextField,
  MenuItem,
  InputAdornment,
} from "@material-ui/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-light-svg-icons";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { FetchJobRolesForFeedback } from "../../../../schemaTypes";
import { ResourceCustomTheme } from "~/styles/config";

const FETCH_JOB_ROLES_FOR_FEEDBACK = gql`
  query FetchJobRolesForFeedback {
    currentUserV2 {
      _id
      organization {
        id
        jobRoles {
          id
          name
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme: ResourceCustomTheme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    "& > .filter": {
      display: "flex",
      "& > *": {
        width: "200px",
        "&:not(:last-child)": {
          marginRight: "8px",
        },
      },
    },
    "& > .search": {
      display: "flex",
      "& > .searchInput": {
        width: "300px",
      },
      "& .searchIcon": {
        color: theme.colors.MidnightBlue(200),
      },
    },
  },
}));

export enum RolesEnum {
  default = "all",
}

export enum FilterTimeRangeEnum {
  "last1Month" = "Last Month",
  "last3Months" = "Last 3 Months",
  "last6Months" = "Last 6 Months",
  "lastYear" = "Last Year",
}

export type FilterParams = {
  timeRange: FilterTimeRangeEnum;
  roleId: string;
};

type SortBarProps = {
  filterParams: FilterParams;
  setFilterParams: React.Dispatch<React.SetStateAction<FilterParams>>;
  stagesAvailable: string[];
  searchParams: string;
  setSearchParams: React.Dispatch<React.SetStateAction<string>>;
};

const SortBar: React.FC<SortBarProps> = ({
  filterParams,
  setFilterParams,
  searchParams,
  setSearchParams,
}) => {
  const classes = useStyles();
  const { timeRange, roleId: selectedRoleId } = filterParams;
  const { data: jobRoleData } = useQuery<FetchJobRolesForFeedback>(
    FETCH_JOB_ROLES_FOR_FEEDBACK
  );

  const handleFilterChange = (
    key: string,
    value: FilterTimeRangeEnum | string
  ): void =>
    setFilterParams({
      ...filterParams,
      [key]: value,
    });

  const jobRoles = jobRoleData?.currentUserV2?.organization.jobRoles || [];

  return (
    <div className={classes.root}>
      <div className="filter">
        <TextField
          select
          value={timeRange}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            handleFilterChange("timeRange", e.target.value)
          }
          label="Date"
        >
          <MenuItem value={FilterTimeRangeEnum.last1Month}>
            {FilterTimeRangeEnum.last1Month}
          </MenuItem>
          <MenuItem value={FilterTimeRangeEnum.last3Months}>
            {FilterTimeRangeEnum.last3Months}
          </MenuItem>
          <MenuItem value={FilterTimeRangeEnum.last6Months}>
            {FilterTimeRangeEnum.last6Months}
          </MenuItem>
          <MenuItem value={FilterTimeRangeEnum.lastYear}>
            {FilterTimeRangeEnum.lastYear}
          </MenuItem>
        </TextField>
        <TextField
          select
          value={selectedRoleId}
          label="Role"
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            handleFilterChange("roleId", e.target.value)
          }
        >
          <MenuItem value={RolesEnum.default}>All Roles</MenuItem>
          {jobRoles.map(({ id, name }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div className="search">
        <TextField
          className="searchInput"
          placeholder="Search"
          type="text"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon
                  className="searchIcon"
                  icon={faSearch}
                  size="lg"
                />
              </InputAdornment>
            ),
          }}
          value={searchParams}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            setSearchParams(e.target.value)
          }
        />
      </div>
    </div>
  );
};

export default SortBar;
