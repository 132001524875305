import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery } from "@apollo/client";
import { Grid, Slide, TextField } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import SlackInterviewersHelper from "~/components/SlackInterviewersHelper/SlackInterviewersHelper";

import useDebounce from "~/react-hooks/useDebounce";
import usePrevious from "~/react-hooks/usePrevious";
import Loading from "~/components/Loading/Loading";
import InterviewerCard from "./InterviewerCard/InterviewerCard";
import EditInterviewerModal from "./EditInterviewerModal/EditInterviewerModal";
import {
  InterviewersForTeamPage,
  SlackIntegrationForCustomer_currentUserV2_organization_customer_integration_SlackIntegrationV2 as SlackIntegration,
} from "~/schemaTypes";
import { INTERVIEWERS_QUERY } from "./queries";
import { useSlackIntegration } from "~/apollo-hooks/use-slack-integration";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    marginTop: "10px",
  },
  searchBar: {},
  slackHelperCont: {
    marginTop: "40px",
    display: "flex",
    justifyContent: "center",
  },
  container: {
    // HACK: We force this size so that we always have scrollbars
    height: "80vh",
  },
});

const INTERVIEWERS_LIMIT = 25;

const Interviewers: React.FC = () => {
  const { data: integrationData } = useSlackIntegration();
  const integration = integrationData?.currentUserV2?.organization.customer
    .integration as SlackIntegration | null | undefined;

  const [editInterviewerId, setEditInterviewerId] = useState("");
  const [filterField, setFilterField] = useState<string>("");
  const [allInterviewersFetched, setAllInterviewersFetched] = useState(false);

  const classes = useStyles();

  const FIRST_PAGE_LIMIT = INTERVIEWERS_LIMIT;
  const { data, loading, fetchMore, refetch } = useQuery<
    InterviewersForTeamPage
  >(INTERVIEWERS_QUERY, {
    variables: {
      input: {
        offset: 0,
        limit: FIRST_PAGE_LIMIT,
        orderBy: {
          field: "firstName",
          direction: "ASC",
        },
      },
    },
  });

  const debouncedFilter = useDebounce(filterField, 500);
  const previousDebouncedFilter = usePrevious(debouncedFilter);
  const interviewers = useMemo(
    () => data?.currentUserV2?.organization?.interviewers || [],
    [data]
  );

  useEffect(() => {
    // If we didn't get a full page, we're done
    if (!loading && interviewers.length < FIRST_PAGE_LIMIT) {
      setAllInterviewersFetched(true);
    }
  }, [interviewers, loading, FIRST_PAGE_LIMIT]);

  useEffect(() => {
    const search = async () => {
      setAllInterviewersFetched(false);
      await refetch({
        input: {
          offset: 0,
          limit: INTERVIEWERS_LIMIT,
          orderBy: {
            field: "firstName",
            direction: "ASC",
          },
          filter: {
            name: debouncedFilter,
          },
        },
      });
    };
    if (
      !_.isUndefined(previousDebouncedFilter) &&
      previousDebouncedFilter !== debouncedFilter
    ) {
      search();
    }
  }, [
    debouncedFilter,
    previousDebouncedFilter,
    refetch,
    setAllInterviewersFetched,
  ]);

  const rows = _.map(
    interviewers,
    ({
      id,
      firstName,
      lastName,
      title,
      linkedInUrl,
      imageUrl,
      biography,
      atsUserId,
      slackUserUserId,
    }) => ({
      id,
      name: `${firstName} ${lastName}`,
      title,
      linkedInUrl,
      imageUrl,
      biography,
      atsUserId,
      slackUserUserId,
    })
  );

  const handleInfiniteScroll = () => {
    return fetchMore({
      variables: {
        input: {
          offset: rows.length,
          limit: INTERVIEWERS_LIMIT,
          orderBy: {
            field: "firstName",
            direction: "ASC",
          },
          filter: {
            name: debouncedFilter,
          },
        },
      },
      updateQuery: (
        prev,
        {
          fetchMoreResult,
        }: { fetchMoreResult?: InterviewersForTeamPage | undefined }
      ) => {
        if (!fetchMoreResult) {
          setAllInterviewersFetched(true);
          return prev;
        }
        const newObject = _.mergeWith(
          {},
          prev,
          fetchMoreResult,
          (objValue, srcValue) => {
            if (_.isArray(objValue)) {
              return objValue.concat(srcValue);
            }
            return undefined;
          }
        );
        // If we didn't get a full page, we're done
        if (
          fetchMoreResult.currentUserV2?.organization.interviewers.length !==
          INTERVIEWERS_LIMIT
        ) {
          setAllInterviewersFetched(true);
        }
        return newObject;
      },
    });
  };

  return (
    <div className={classes.container}>
      <TextField
        fullWidth
        label="Search people"
        variant="outlined"
        value={filterField}
        onChange={(e: React.ChangeEvent<{ value: string }>) => {
          setFilterField(e.target.value);
        }}
        disabled={loading}
      />
      {loading ? (
        <Loading />
      ) : (
        <div className={classes.root}>
          <InfiniteScroll
            dataLength={rows.length}
            style={{ overflow: "hidden" }}
            hasMore={!allInterviewersFetched}
            loader={<Loading />}
            next={handleInfiniteScroll}
          >
            <Grid container spacing={3}>
              {_.map(rows, (interviewer) => (
                <Slide direction="up" key={interviewer.id} in>
                  <Grid
                    item
                    onClick={() => {
                      setEditInterviewerId(interviewer.id);
                    }}
                  >
                    <InterviewerCard {...interviewer} />
                  </Grid>
                </Slide>
              ))}
            </Grid>
          </InfiniteScroll>
          {_.isBoolean(integration?.connected) && !integration?.connected && (
            <div className={classes.slackHelperCont}>
              <SlackInterviewersHelper onConnected={refetch} />
            </div>
          )}
          <EditInterviewerModal
            open={!!editInterviewerId}
            close={() => setEditInterviewerId("")}
            interviewerId={editInterviewerId}
          />
        </div>
      )}
    </div>
  );
};

export default Interviewers;
