import _ from "lodash";
import cx from "classnames";
import React, { FunctionComponent } from "react";
import { Typography } from "@material-ui/core";

import LinkItem from "./LinkItem/LinkItem";

import styles from "./Links.module.scss";

export const ALLOWED_LINK_MEDIA = ["image", "logo", "video"];

// This is used to drive analytics but it definitely belongs in a central constants file
// with the other event names
export const LINKED_CLICKED = "Linked Clicked";
export const MORE_INFO = "More Info";

type Link = {
  id: string;
  url: string;
  label?: string;
  imageUrl?: string;
};

type LinksProps = {
  defaultImage?: string;
  links: Link[];
  header?: string;
};

const Links: FunctionComponent<LinksProps> = ({
  defaultImage,
  links,
  header,
}) =>
  links.length ? (
    <div className={styles.linksContainer}>
      <Typography variant="h4">{header ?? MORE_INFO}</Typography>
      <div className={cx(styles.listItems)}>
        {_.map(links, ({ label, url, imageUrl }) => (
          <LinkItem
            key={url}
            defaultImageUrl={defaultImage || ""}
            label={label}
            url={url}
            imageUrl={imageUrl}
          />
        ))}
      </div>
    </div>
  ) : null;

export default Links;
