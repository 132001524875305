import React, { useState } from "react";
import { Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PulsatingTooltip from "~/components/PulsatingTooltip/PulsatingTooltip";

const useStyles = makeStyles({
  cont: {
    width: "100%",
    borderRadius: "2px",
    marginBottom: "-4px",
    backgroundColor: "#006aff",
    display: "flex",
    justifyContent: "space-between",
    padding: "10px",
    alignItems: "center",
  },
  label: {
    color: "white",
    fontSize: "16px",
    fontWeight: "normal",
    marginTop: "4px",
    marginBottom: "6px",
    marginLeft: "6px",
  },
  btnText: {
    color: "white",
    fontSize: "16px",
    fontWeight: "bold",
    marginTop: "4px",
    paddingRight: "4px",
    paddingLeft: "4px",
  },
});

export const SampleGuideHeader: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.cont}>
      <div>
        <Typography variant="subtitle1" className={classes.label}>
          Preview
        </Typography>
      </div>
      <div />
    </div>
  );
};

type GuidePreviewHeaderProps = {
  onSendGuide?: () => void;
  showSendGuideTooltip?: boolean;
};

export const GuidePreviewHeader: React.FC<GuidePreviewHeaderProps> = ({
  onSendGuide,
  showSendGuideTooltip = false,
}) => {
  const classes = useStyles();
  const [sendGuideTooltipOpen, setSendGuideTooltipOpen] = useState<boolean>(
    showSendGuideTooltip
  );

  return (
    <div className={classes.cont}>
      <PulsatingTooltip
        open={sendGuideTooltipOpen}
        title="Make any last edits then send your guide!"
        placement="bottom"
        onClose={(): void => setSendGuideTooltipOpen(false)}
        onOpen={(): void => setSendGuideTooltipOpen(true)}
      >
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          className={classes.label}
          onClick={onSendGuide}
          variant="text"
        >
          <Typography variant="h5" className={classes.btnText}>
            Send Guide&nbsp;
            <span role="img" aria-label="right">
              👉
            </span>
          </Typography>
        </Button>
      </PulsatingTooltip>
      <div />
    </div>
  );
};
