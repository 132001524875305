import React from "react";
import { Typography, makeStyles, useTheme } from "@material-ui/core";

import { ResourceCustomTheme } from "~/styles/config";
import { GuideTemplateForEditing_guideTemplate_stageTemplateInstallations_afterSteps_TemplateNoteJourneyStep as NoteJourneyStepType } from "~/schemaTypes";
import TimelineIndicator from "~/components/TimelineIndicator";

const useGlobalStyles = makeStyles((theme: ResourceCustomTheme) => ({
  root: {
    display: "flex",
    marginBottom: theme.spacing(2),
    marginLeft: 20,
    alignItems: "center",
  },
  textContainer: {
    marginLeft: 10,
    flexGrow: 1,
  },
}));

type NoteJourneyStepProps = {
  step: NoteJourneyStepType;
};

const NoteJourneyStep: React.FC<NoteJourneyStepProps> = ({ step }) => {
  const classes = useGlobalStyles();
  const theme = useTheme<ResourceCustomTheme>();

  return (
    <div className={classes.root}>
      <TimelineIndicator
        icon={<strong>?</strong>}
        color={theme.colors.BananaYellow}
      />
      <div className={classes.textContainer}>
        <Typography variant="body1">
          <strong>{step.text}</strong>
        </Typography>
      </div>
    </div>
  );
};

export default NoteJourneyStep;
