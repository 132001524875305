import React, { useState, useEffect } from "react";

import {
  StyleRules,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import EditRichTextEditor from "~/components/EditRichTextEditor/EditRichTextEditor";

import { EditModulesGlobalProps } from "../EditModuleContainer";
import EDIT_RICH_TEXT_MUTATION from "./EDIT_RICH_TEXT_MUTATION";

type EditRichTextProps = {
  moduleId: string;
  rawHtml: string;
  header: string;
};

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      padding: (): string => `${theme.spacing(1)} ${theme.spacing(3)} 0`,
    },
    input: {
      marginTop: "10px",
      marginBottom: "10px",
    },
  });
const useStyles = makeStyles(styles);

const EditRichText: React.FC<
  EditRichTextProps & Omit<EditModulesGlobalProps, "stageId">
> = ({
  setHandleSaveMutation,
  setHandleSaveMutationInput,
  moduleId,
  header,
  rawHtml: initialRawHtml,
}) => {
  const [rawHtml, setRawHtml] = useState<string>(initialRawHtml);
  const [headerOverride, setHeaderOverride] = useState(header);
  setHandleSaveMutation(EDIT_RICH_TEXT_MUTATION);

  useEffect(() => {
    setHandleSaveMutationInput({
      input: {
        header: headerOverride,
        moduleId,
        rawHtml,
      },
    });
  }, [moduleId, rawHtml, setHandleSaveMutationInput, headerOverride]);

  const classes = useStyles();

  return (
    <form className={classes.root}>
      <TextField
        fullWidth
        value={headerOverride}
        onChange={(e) => setHeaderOverride(e.target.value)}
        style={{ marginBottom: 6 }}
        label="Header"
      />
      <EditRichTextEditor text={rawHtml} setText={setRawHtml} />
    </form>
  );
};

export default EditRichText;
