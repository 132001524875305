import _ from "lodash";
import React from "react";
import { TextField, useMediaQuery, Grid, useTheme } from "@material-ui/core";
import { strings } from "@resource/common";

import DropzoneWithPreview from "~/components/Dropzone/DropzoneWithPreview";
import { Interviewer } from "./InterviewerType";

type InterviewerFormProps = {
  interviewer: Interviewer;
  setInterviewer: React.Dispatch<React.SetStateAction<Interviewer>>;
};

const InterviewerForm: React.FC<InterviewerFormProps> = ({
  interviewer,
  setInterviewer,
}) => {
  const theme = useTheme();
  const wide = useMediaQuery(theme.breakpoints.up("sm"));
  const { imageUrl, name, title, websiteUrls, biography } = interviewer;
  const { firstName } = strings.splitName(name);

  const setInterviewerImageUrl = (newUrl: string) => {
    setInterviewer((prevInterviewer) => ({
      ...prevInterviewer,
      imageUrl: newUrl,
    }));
  };
  const setInterviewerName = (newName: string) => {
    setInterviewer((prevInterviewer) => ({
      ...prevInterviewer,
      name: newName,
    }));
  };
  const setInterviewerTitle = (newTitle: string) => {
    setInterviewer((prevInterviewer) => ({
      ...prevInterviewer,
      title: newTitle,
    }));
  };
  const setInterviewerWebsiteUrl = (
    position: number,
    newWebsiteUrl: string
  ) => {
    setInterviewer((prevInterviewer) => {
      const newWebsiteUrls = _.clone(prevInterviewer.websiteUrls);
      if (newWebsiteUrl) {
        _.set(newWebsiteUrls, `[${position}]`, newWebsiteUrl);
      } else {
        newWebsiteUrls.splice(position, 1);
      }
      return {
        ...prevInterviewer,
        websiteUrls: newWebsiteUrls,
      };
    });
  };
  const setInterviewerBiography = (newBiography: string) => {
    setInterviewer((prevInterviewer) => ({
      ...prevInterviewer,
      biography: newBiography,
    }));
  };

  return (
    <Grid container direction="row" justify="center">
      <Grid item xs={12} sm={6}>
        <DropzoneWithPreview
          direction={wide ? "column" : "row"}
          photoUrl={imageUrl}
          setPhotoUrl={setInterviewerImageUrl}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          margin="dense"
          id="fullName"
          label="Full Name"
          fullWidth
          required
          value={name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setInterviewerName(e.target.value)
          }
        />
        <TextField
          variant="outlined"
          margin="dense"
          id="title"
          label="Title"
          fullWidth
          multiline
          value={title}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setInterviewerTitle(e.target.value)
          }
        />
        {_.map(_.range(0, websiteUrls.length + 1), (index: number) => {
          return (
            <TextField
              key={`websiteUrl-${index}`}
              variant="outlined"
              margin="dense"
              id={`websiteUrl-${index}`}
              label={`Website URL ${index ? index + 1 : ""}`}
              fullWidth
              value={websiteUrls[index] || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setInterviewerWebsiteUrl(index, e.target.value)
              }
            />
          );
        })}
        <TextField
          variant="outlined"
          margin="dense"
          id="bio"
          label={`${strings.possessive(firstName)} Bio`}
          fullWidth
          multiline
          rows="8"
          value={biography}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setInterviewerBiography(e.target.value)
          }
        />
      </Grid>
    </Grid>
  );
};

export default InterviewerForm;
