import React, { FunctionComponent, useState } from "react";
import { Typography, Link } from "@material-ui/core";

import {
  faCloudUpload,
  faImagePolaroid,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation } from "@apollo/client";

import cx from "classnames";
import Dropzone from "./Dropzone";

import styles from "./DropzoneWithPreview.module.scss";
import UPLOAD_TEAMMATE_IMAGE_MUTATION from "../../mutations/UPLOAD_TEAMMATE_IMAGE_MUTATION";
import Loading from "~/components/Loading/Loading";
import {
  UploadTeammateImage,
  UploadTeammateImageVariables,
} from "~/schemaTypes";

type DropzoneWithPreviewProps = {
  photoUrl: string;
  setPhotoUrl: (x: string) => void;
  previewStyle?: "cover" | "contain";
  direction?: "row" | "column";
};

const DropzoneWithPreview: FunctionComponent<DropzoneWithPreviewProps> = ({
  photoUrl,
  setPhotoUrl,
  previewStyle = "cover",
  direction = "row",
}) => {
  const isVertical = direction === "column";
  const [uploadTeammateImage, { loading = false }] = useMutation<
    UploadTeammateImage,
    UploadTeammateImageVariables
  >(UPLOAD_TEAMMATE_IMAGE_MUTATION);
  const [error, setError] = useState("");

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onUpload = async (files: any, rejectedFiles: any) => {
    setError("");
    if (rejectedFiles.length > 1) {
      setError("You may only upload one file at a time");
      return;
    }

    if (rejectedFiles.length) {
      setError("You may only upload images less than 4 megabyte");
      return;
    }
    try {
      const { data } = await uploadTeammateImage({
        variables: { input: { file: files[0] } },
      });
      const newUrl = data?.uploadTeammateImage?.url;
      if (newUrl) {
        setPhotoUrl(newUrl);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const InnerComponent = () => {
    if (loading) return <Loading />;
    if (error) {
      return (
        <div className={styles.dropzoneComponent}>
          <Typography style={{ color: "red" }}> {error} </Typography>
        </div>
      );
    }
    return (
      <div className={styles.dropzoneComponent}>
        <>
          <FontAwesomeIcon
            icon={faCloudUpload}
            size="3x"
            className={styles.dropzoneIcon}
          />

          {isVertical ? (
            <Typography className={styles.dropzoneText}>
              &nbsp; Drop photo or
              <Link> browse </Link>
            </Typography>
          ) : (
            <Typography className={styles.dropzoneText}>
              {" "}
              To update, drop your photo or <Link> browse </Link>
            </Typography>
          )}
        </>
      </div>
    );
  };

  return (
    <div
      className={cx({
        [styles.formContainer]: !isVertical,
      })}
    >
      <div
        className={cx({
          [styles.previewContainer]: !isVertical,
          [styles.verticalPreviewContainer]: isVertical,
        })}
      >
        {photoUrl === "" ? (
          <FontAwesomeIcon
            className={styles.icon}
            icon={faImagePolaroid}
            size="3x"
          />
        ) : (
          <div
            className={styles.preview}
            style={{
              backgroundImage: `url(${photoUrl})`,
              backgroundSize: previewStyle,
            }}
          />
        )}
      </div>

      <Dropzone
        rootProps={{}}
        className={cx({
          [styles.dropzone]: !isVertical,
          [styles.verticalDropzone]: isVertical,
          [styles.dragError]: error,
        })}
        dragActiveClassName={styles.dragActive}
        InnerComponent={InnerComponent}
        onUpload={onUpload}
        multiple={false}
        maxSize={4000000}
        accept="image/*"
      />
    </div>
  );
};
export default DropzoneWithPreview;
