import isUrl from "is-url";

type ParseGreenhouseUrlResponse = {
  applicationId: string;
  candidateId: string;
};

export const parseGreenhouseUrl = (
  possibleUrl: string
): ParseGreenhouseUrlResponse => {
  if (isUrl(possibleUrl)) {
    const url = new URL(possibleUrl);
    const { host, pathname } = url;

    if (host.includes("greenhouse.io")) {
      const candidateId = pathname.split("/people/")[1];
      const applicationId = url.searchParams.get("application_id") || "";

      return {
        applicationId,
        candidateId,
      };
    }
  }
  return {
    applicationId: "",
    candidateId: "",
  };
};

export const isValidGreenhouseUrl = (possibleUrl: string): boolean => {
  const { applicationId, candidateId } = parseGreenhouseUrl(possibleUrl);
  return !!(applicationId && candidateId);
};

type GenerateGreenhouseIframeUrlType = {
  applicationId: string;
  candidateId: string;
};

export const generateGreenhouseIframeUrl = ({
  applicationId,
  candidateId,
}: GenerateGreenhouseIframeUrlType): string => {
  return applicationId && candidateId
    ? `/greenhouse-candidate/${candidateId}/application/${applicationId}`
    : "";
};
