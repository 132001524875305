import React, { FunctionComponent, useContext } from "react";
import cx from "classnames";
import { Typography } from "@material-ui/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { AnalyticsContext } from "~/analytics";
import DescriptionDivider from "../DescriptionDivider";

import feedbackStyles from "../feedback.module.scss";
import styles from "./thank-you-step.module.scss";

type ThankYouProps = {
  onClose?: () => void;
  isSignedIn?: boolean;
};

const feedbackForOtherCompaniesLink =
  "https://docs.google.com/forms/d/e/1FAIpQLSf03PAZPb4vPxF0HnzQdX_fqL6Fzp-043aurYBVndFUPNd0fg/viewform?usp=sf_link";

const ThankYou: FunctionComponent<ThankYouProps> = ({
  onClose = () => {},
  isSignedIn = false,
}) => {
  const analytics = useContext(AnalyticsContext);
  return (
    <>
      <div className={styles.header}>
        <Typography
          className={cx(feedbackStyles.header, styles.stepTitle)}
          variant="h4"
        >
          Thank you for your feedback!{" "}
          <span role="img" aria-label="thanks">
            🙏
          </span>
        </Typography>
        <FontAwesomeIcon
          className={styles.closeIcon}
          icon={faTimes}
          size="lg"
          onClick={(): void => onClose()}
        />
      </div>
      <Typography variant="body1">
        Sharing your experience helps improve the interview process for future
        candidates like you.
      </Typography>
      {isSignedIn ? (
        <>
          <DescriptionDivider />
          <Typography
            variant="subtitle2"
            className={feedbackStyles.anonymousText}
          >
            The candidate will see everything above this line{" "}
            <span role="img" aria-label="arrow up">
              ⬆️
            </span>
          </Typography>
          <Typography
            variant="subtitle2"
            className={feedbackStyles.anonymousText}
          >
            We detected that you are signed in, so we did not submit your
            feedback. This candidate will still be able to submit feedback and
            this feedback will not factor into your metrics{" "}
            <span role="img" aria-label="sparkle">
              ✨
            </span>
          </Typography>
        </>
      ) : (
        <>
          <DescriptionDivider />
          <Typography variant="body1">
            <span className={styles.feedbackForOtherCompaniesValueProp}>
              Interviewing elsewhere?
            </span>
            <br />
            <a
              onClick={(): void => {
                analytics.track("Give Feedback To Other Companies");
              }}
              className={styles.feedbackForOtherCompaniesCTA}
              rel="noopener noreferrer"
              href={feedbackForOtherCompaniesLink}
              target="_blank"
            >
              Share anonymous feedback with other companies{" "}
              <span role="img" aria-label="pointing right">
                👉
              </span>
            </a>
          </Typography>
        </>
      )}
    </>
  );
};

export default ThankYou;
