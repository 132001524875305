import gql from "graphql-tag";

const HasuraGetCustomerGreenhouseMetadata = gql`
  query HasuraGetCustomerGreenhouseMetadata @hasura {
    customerGreenhouseMetadata {
      connected
      webhooksSecretKey
      apiKeySuffix
      maildropAddress
      subdomain
      customer {
        id
      }
    }
  }
`;

export default HasuraGetCustomerGreenhouseMetadata;
