import isUrl from "is-url";
import React, { FunctionComponent } from "react";
import getYoutubeID from "get-youtube-id";

import { Typography } from "@material-ui/core";
import YouTube from "./YouTube/YouTube";
import Image from "./Image/Image";

import styles from "./Media.module.scss";

type MediaProps = {
  mediaLink: string;
  mediaAlt?: string;
  header?: string;
};

const Media: FunctionComponent<MediaProps> = ({
  header,
  mediaLink,
  mediaAlt,
}) => {
  // Only render this component if the url is valid
  if (!isUrl(mediaLink)) {
    return null;
  }

  const videoId = getYoutubeID(mediaLink);

  return (
    <div className={styles.mediaContainer}>
      {header && <Typography variant="h4">{header}</Typography>}
      {videoId && <YouTube videoId={videoId} />}
      {mediaLink && !videoId && (
        <Image imageUrl={mediaLink} imageAlt={mediaAlt} />
      )}
    </div>
  );
};
export default Media;
