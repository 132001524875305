import moment from "moment";
import { v4 as uuid } from "uuid";
import { austin, kevin } from "./generate-interviewer";
import { StageById_stage_sortedVisibleEvents as Event } from "~/schemaTypes";
import { SampleGuide } from "../../pages/Guide/GuidePresenter/GuidePresenter";
import generateEvent from "./generate-event";

const FakeGuideIdForTesting = process.env.REACT_APP_DEMO_GUIDE_ID!;

type generateGuideArgs = {
  candidateName?: string;
  stageName?: string;
  companyName?: string;
  candidateRole?: string;
  events?: Event[];
  remoteInstructions?: string;
  includeChat?: boolean;
};

const generateGuide = ({
  candidateName = "Kevin",
  stageName = "Phone Screen",
  candidateRole = "Software Engineer",
  companyName = "Resource",
  events = [
    generateEvent({
      title: "Welcome + Agenda",
      startTime: moment().add(1, "day").toDate(),
      interviewers: [austin],
    }),
    generateEvent({
      title: "Culture Fit",
      startTime: moment().add(1, "day").add(30, "minutes").toDate(),
      interviewers: [kevin],
    }),
  ],
  remoteInstructions,
  includeChat = true,
}: generateGuideArgs): SampleGuide => {
  const chatModule: SampleGuide["currentStage"]["modules"][0] = {
    __typename: "StageModule",
    id: uuid(),
    type: "Chat",
    displayType: "Chat",
    data: {},
  };
  return {
    id: FakeGuideIdForTesting,
    createdFromATS: true,
    currentStage: {
      __typename: "Stage",
      id: uuid(),
      guide: {
        __typename: "Guide",
        id: FakeGuideIdForTesting,
        chatEnabled: includeChat,
        createdFromATS: true,
        candidate: {
          __typename: "CandidateV2",
          id: uuid(),
        },
      },
      title: stageName,
      sortedVisibleEvents: events,
      modules: [
        {
          __typename: "StageModule",
          id: uuid(),
          type: "CandidatePostInterviewFeedback",
          displayType: "CandidatePostInterviewFeedback",
          data: {
            ...(events.length > 0
              ? {
                  lastEventEndTime: events[events.length - 1].endTime,
                  previouslySubmittedFeedback: false,
                }
              : {
                  lastEventEndTime: null,
                  previouslySubmittedFeedback: true,
                }),
            isDemo: true,
          },
        },
        {
          __typename: "StageModule",
          id: uuid(),
          type: "GreetingHeader",
          displayType: "GreetingHeader",
          data: {
            firstName: candidateName,
            role: candidateRole,
          },
        },
        {
          __typename: "StageModule",
          id: uuid(),
          type: "Time",
          displayType: "Time",
          data: {
            companyName,
            firstName: candidateName,
            role: candidateRole,
            remote: !!remoteInstructions,
            ...(remoteInstructions ? { remoteInstructions } : {}),
            events,
          },
        },
        {
          __typename: "StageModule",
          id: uuid(),
          type: "Location",
          displayType: "Location",
          data: {
            remote: !!remoteInstructions,
            ...(remoteInstructions
              ? { remoteInstructions }
              : {
                  address: "383 Tehama St, San Francisco, CA 94103, USA",
                  coordinates: ["37.781433", "-122.4038643"],
                  arrivalInstructions:
                    "<div>We're an 8 minute walk from the Powell Muni/Bart station, and about a block away from the 4th Street 45, 30, 8, 8AX and 8BX bus stops.</div><div><br /></div><div>When you arrive, knock on the gate outside and someone will let you in! The doorbell doesn't work :)</div><div><br /></div><div>If you arrive early, you can grab a great coffee at:</div><div><br /></div><ul><li>Contraband (260 5th St.)</li><li>Les Gourmands (280 5th St.)</li><li>Blue Bottle (66 Mint St.)</li></ul>",
                }),
          },
        },
        {
          __typename: "StageModule",
          id: uuid(),
          type: "Schedule",
          displayType: "Schedule",
          data: {
            events,
          },
        },
        {
          __typename: "StageModule",
          id: uuid(),
          type: "Logistics",
          displayType: "InfoCards",
          data: {
            header: "Logistics",
            infoArray: remoteInstructions
              ? [
                  {
                    icon: "🎧",
                    title: "Find a Quiet Spot",
                    description:
                      "Make sure the background noise isn’t too high so we can focus on our conversation - not your neighbor's coffee date",
                  },
                  {
                    icon: "📶",
                    title: "Wifi Connectivity",
                    description:
                      "Make sure you have a stable connection so we can focus on our conversation!",
                  },
                ]
              : [
                  {
                    icon: "🚻",
                    title: "Gender Neutral Bathrooms",
                    description:
                      "We have bathroom accommodations for everyone 😊",
                  },
                  {
                    icon: "🖋",
                    title: "Pens, Paper, Whiteboard Supplied",
                    description: "Just bring your ideas and enthusiasm!",
                  },
                  {
                    icon: "🤔",
                    title: "Prepare Your Questions",
                    description: "We want you to interview us as well!",
                  },
                  {
                    icon: "🚲",
                    title: "Bike Room",
                    description:
                      "If you're biking, park in our office's bike room.",
                  },
                  {
                    icon: "🥗",
                    title: "Lunch Provided",
                    description:
                      "We'll provide lunch as an opportunity to get to know the team better!",
                  },
                  {
                    icon: "👚",
                    title: "Casual",
                    description:
                      "Most people wear jeans, so wear whatever makes you feel comfortable!",
                  },
                  {
                    icon: "☕️",
                    title: "Drinks and Snacks",
                    description:
                      "We’ve got drinks and snacks if you get hungry throughout the day.",
                  },
                  {
                    icon: "👩‍💻",
                    title: "Bring Your Laptop",
                    description: "If you don't have one, one will be provided.",
                  },
                  {
                    icon: "🚘",
                    title: "Parking Reimbursed",
                    description:
                      "  We'll reimburse your parking, so make sure to save your receipt!",
                  },
                ],
          },
        },
        {
          __typename: "StageModule",
          id: uuid(),
          type: "TeamPhoto",
          displayType: "Media",
          data: {
            mediaLink:
              "https://storage.googleapis.com/customer-assets/ezgif.com-gif-maker.gif",
          },
        },
        {
          __typename: "StageModule",
          id: uuid(),
          type: "Mission",
          displayType: "Mission",
          data: {
            header: "Our Mission",
            description:
              "We believe the recruiting process should be human, enlightening and fun - not a transaction. We're building an entirely new way for companies and candidates to build relationships. And that's just the beginning.",
          },
        },
        {
          __typename: "StageModule",
          id: uuid(),
          type: "Values",
          displayType: "InfoCards",
          data: {
            header: "Our Values",
            infoArray: [
              {
                id: uuid(),
                title: "Be a resource",
                description:
                  "Offer help. Connect others. Overdeliver. We believe in going above and beyond for those around us. If not for so many wonderful souls along our own journey, we'd be far less fortunate. ",
              },
              {
                id: uuid(),
                title: "Be resourceful",
                description:
                  "Be scrappy. Take initiative. Find a way. We're David in a world of Goliaths. That's the fun part about this company-building thing. We get to band together and make the impossible happen with only desperate passion and creativity.",
              },
              {
                id: uuid(),
                title: "Optimize for growth",
                description:
                  "Be a student. Stay humble. Never settle. Potential over credentials; aptitude over pedigree. We believe the most impactful skill to learn in a complex and evolving world is the ability to learn itself. Learn to learn, and you're set for life",
              },
              {
                id: uuid(),
                title: "Think long term",
                description:
                  "Be patient. Invest in relationships. Learn everyday. You can't build a long term future on short term thinking. We believe in envisioning the future we want to exist and reverse engineering the path there. Working towards ideals; thinking without constraints. That's our secret recipe.",
              },
              {
                id: uuid(),
                title: "Enjoy the journey",
                description:
                  "Life is short. Be yourself. Love what you do. Don’t take life too seriously. Ask questions, challenge norms, break rules, giggle, experiment, love more, never settle. We're building the car we want to drive through life in, without holding on too tightly to the destination.",
              },
            ],
          },
        },
        {
          __typename: "StageModule",
          id: uuid(),
          type: "Links",
          displayType: "Links",
          data: {
            defaultImage: "https://www.resource.io/images/brand/color/logo.svg",
            links: [
              {
                id: uuid(),
                url: "https://www.resource.io/company",
                label: "Company Website",
              },
              {
                id: uuid(),
                url:
                  "https://angel.co/company/resource-io/jobs/190489-senior-fullstack-engineer",
                label: "Job Description",
              },
            ],
          },
        },
        {
          __typename: "StageModule",
          id: uuid(),
          type: "CandidatePostInterviewFeedback",
          displayType: "CandidatePostInterviewFeedback",
          data: {
            lastEventEndTime: moment().subtract(1, "day").toDate(),
            previouslySubmittedFeedback: false,
          },
        },
        ...(includeChat ? [chatModule] : []),
      ],
    },
  };
};
export default generateGuide;
