import _ from "lodash";
import cx from "classnames";
import React, { useState, useCallback, useEffect } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";

import {
  Typography,
  TextField,
  TextFieldProps,
  Link,
  makeStyles,
} from "@material-ui/core";
import AutoComplete from "@material-ui/lab/Autocomplete";
import CURRENT_USER_QUERY from "~/queries/CURRENT_USER_QUERY";
import greenhouseIconLg from "~/icons/greenhouse-icon-lg.svg";
import { useFlashMessage } from "~/components/FlashMessage/FlashMessage";
import {
  GreenhouseIntegrationForCustomer,
  UpdateGreenhouseIntegrationForCustomer,
  UpdateGreenhouseIntegrationForCustomerVariables,
  IntegrationEnum,
  GreenhouseIntegrationForCustomerVariables,
  GreenhouseIntegrationForCustomer_currentUserV2_organization_customer_integration_GreenhouseIntegrationV2 as GreenhouseIntegration,
  CurrentUser,
} from "~/schemaTypes";
import IntegrationModal from "../Integrations/IntegrationModal";
import CustomerFragmentForGreenhouse from "~/fragments/CUSTOMER_WITH_GREENHOUSE_INTEGRATION_FRAGMENT";

const useStyles = makeStyles({
  root: {},
  inlineFormField: {
    display: "flex",
    alignItems: "center",
  },
  description: {
    marginBottom: "1.5rem",
  },
  subdomain: {
    width: 300,
    paddingRight: 4,
    paddingLeft: 4,
  },
  subdomainHelpers: {
    paddingTop: 20,
  },
  formField: {
    marginTop: "2rem",
  },
});

const GREENHOUSE_INTEGRATION_FOR_CUSTOMER = gql`
  query GreenhouseIntegrationForCustomer($typeInput: IntegrationByTypeInput!) {
    currentUserV2 {
      id
      organization {
        id
        customer {
          ...customerFragmentForGreenhouse
        }
      }
    }
  }
  ${CustomerFragmentForGreenhouse}
`;

const UPDATE_GREENHOUSE_INTEGRATION_FOR_CUSTOMER = gql`
  mutation UpdateGreenhouseIntegrationForCustomer(
    $input: UpdateCustomerGreenhouseIntegrationInput!
    $typeInput: IntegrationByTypeInput!
  ) {
    updateCustomerGreenhouseIntegration(input: $input) {
      success
      message
      code
      customer {
        ...customerFragmentForGreenhouse
      }
    }
  }
  ${CustomerFragmentForGreenhouse}
`;

type GreenhouseApiModalProps = {
  open: boolean;
  onConnected?: () => void;
  onClose: () => void;
};

const GreenhouseApiModal: React.FC<GreenhouseApiModalProps> = ({
  open,
  onConnected,
  onClose,
}) => {
  const styles = useStyles();
  const { data } = useQuery<
    GreenhouseIntegrationForCustomer,
    GreenhouseIntegrationForCustomerVariables
  >(GREENHOUSE_INTEGRATION_FOR_CUSTOMER, {
    variables: {
      typeInput: {
        type: IntegrationEnum.GREENHOUSE,
      },
    },
  });
  const integration = data?.currentUserV2?.organization.customer
    .integration as GreenhouseIntegration | null;
  const apiKeySuffix = integration?.apiKeySuffix;

  const [apiKey, setApiKey] = useState<string>("");
  const [subdomain, setSubdomain] = useState<string>("app");
  const [maildropAddress, setMaildropAddress] = useState<string>(
    "maildrop@rose.greenhouse.io"
  );
  const [update, { loading: updateSaving }] = useMutation<
    UpdateGreenhouseIntegrationForCustomer,
    UpdateGreenhouseIntegrationForCustomerVariables
  >(UPDATE_GREENHOUSE_INTEGRATION_FOR_CUSTOMER);

  useEffect(() => {
    if (integration) {
      setMaildropAddress(integration.maildropAddress);
      setSubdomain(integration.subdomain);
    }
  }, [integration]);

  const { setContent } = useFlashMessage();

  const handleUpdate = useCallback(async () => {
    try {
      await update({
        variables: {
          input: {
            ...(apiKey ? { apiKey } : {}),
            ...{ subdomain },
            maildropAddress,
          },
          typeInput: {
            type: IntegrationEnum.GREENHOUSE,
          },
        },
        refetchQueries: ["FetchAllCustomerIntegrations"],
        update: (cache) => {
          const currentUser = cache.readQuery<CurrentUser>({
            query: CURRENT_USER_QUERY,
          });

          if (currentUser) {
            _.set(currentUser, "currentUserV2.onboardingComplete", true);
            cache.writeQuery({
              query: CURRENT_USER_QUERY,
              data: currentUser,
            });
          }
        },
      });
      // Clear the apiKey in the form after save, it will get replaced with the
      // apiKeySuffix from the server
      setApiKey("");
    } catch (error) {
      setContent({
        content: "Something went wrong updating your Greenhouse integration",
        severity: "error",
      });
      Sentry.captureException(error);
      return;
    }
    if (onConnected) onConnected();
    onClose();
  }, [
    apiKey,
    subdomain,
    onClose,
    maildropAddress,
    setContent,
    update,
    onConnected,
  ]);

  const disabled = !maildropAddress || (!apiKey && !apiKeySuffix);
  const placeholder = apiKeySuffix
    ? `xxxxxxxxxxxxxxxxxxxx${apiKeySuffix}`
    : "ie: wrKa9jw3wuw90AZ2xpwiQ4BGu";

  return (
    <IntegrationModal
      open={open}
      onClose={onClose}
      title="Greenhouse"
      logoSrc={greenhouseIconLg}
      onSave={handleUpdate}
      disabled={disabled}
      updateSaving={updateSaving}
    >
      <Typography className={styles.description} variant="body1">
        The Guide Greenhouse integration allows you to automatically add
        schedules, interviewers and stages to your Guide.
      </Typography>
      <Typography className={styles.description} variant="body1">
        See our{" "}
        <Link
          href="http://support.resource.io/en/articles/3655208-guide-enabling-the-greenhouse-integration"
          target="_blank"
        >
          Setup Instructions
        </Link>{" "}
        for help creating a Harvest API key, including the list of{" "}
        <Link
          href="http://support.resource.io/en/articles/3655208-guide-enabling-the-greenhouse-integration#resource-only-needs-access-to-the-following-items-to-sync-guides"
          target="_blank"
        >
          permissions we require
        </Link>
        .
      </Typography>
      <TextField
        className={styles.formField}
        variant="outlined"
        label="Harvest API Key"
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e) => setApiKey(e.target.value)}
        placeholder={placeholder}
        fullWidth
        value={apiKey}
      />
      <div className={styles.inlineFormField}>
        <Typography variant="subtitle2" className={styles.subdomainHelpers}>
          https://
        </Typography>
        <TextField
          className={cx(styles.formField, styles.subdomain)}
          variant="outlined"
          label="Greenhouse (SSO) Subdomain"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e): void => setSubdomain(e.target.value)}
          placeholder="e.g. yourcompany"
          fullWidth
          value={subdomain}
        />
        <Typography variant="subtitle2" className={styles.subdomainHelpers}>
          .greenhouse.io
        </Typography>
      </div>
      <AutoComplete
        className={styles.formField}
        options={[
          "maildrop@rose.greenhouse.io",
          "maildrop@fern.greenhouse.io",
          "maildrop@ivy.greenhouse.io",
        ]}
        value={maildropAddress}
        onChange={(_e: React.ChangeEvent<unknown>, value: string | null) => {
          if (value) {
            setMaildropAddress(value);
          }
        }}
        renderInput={(params: TextFieldProps) => (
          <TextField
            {...params}
            label="Greenhouse Maildrop Address"
            placeholder="eg. maildrop@rose.greenhouse.io"
            variant="outlined"
            fullWidth
          />
        )}
      />
    </IntegrationModal>
  );
};

export default GreenhouseApiModal;
