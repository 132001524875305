import gql from "graphql-tag";

export const IntegrationsPageIntegrationFragment = gql`
  fragment IntegrationsPageIntegration on Integration {
    id
    type
    issues
    connected
  }
`;

const ALL_INTEGRATIONS_QUERY = gql`
  query FetchAllCustomerIntegrations {
    currentUserV2 {
      id
      organization {
        id
        customer {
          id
          integrations {
            ...IntegrationsPageIntegration
          }
        }
      }
    }
  }
  ${IntegrationsPageIntegrationFragment}
`;

export default ALL_INTEGRATIONS_QUERY;
