import gql from "graphql-tag";

export default gql`
  query CurrentAvailableOrganizations {
    currentUserV2 {
      id
      organization {
        id
        name
      }
      availableOrganizations {
        id
        name
      }
    }
  }
`;
