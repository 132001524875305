import React, { useState } from "react";

import { useMutation } from "@apollo/client";
import {
  Button,
  DialogActions,
  DialogContent,
  makeStyles,
} from "@material-ui/core";
import Dialog from "~/components/Dialog/Dialog";
import DestructiveActionPrompt from "~/components/DestructiveActionPrompt";

import { GuideTemplateForEditing_guideTemplate_stageTemplateInstallations_eventTemplates as EventTemplate } from "~/schemaTypes";
import useAnalytics from "~/react-hooks/useAnalytics";
import UPDATE_EVENT_TEMPLATE from "./UPDATE_EVENT_TEMPLATE@hasura";
import InterviewTemplate from "./InterviewTemplate";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    overflowY: "visible",
  },
  dialogActions: {
    marginTop: "5px",
    marginLeft: "375px",
    marginBottom: "15px",
    marginRight: theme.spacing(3),
    padding: 0,
  },
}));

type InterviewTemplateModalProps = {
  open: boolean;
  onClose(): void;
  interview: EventTemplate;
};

const InterviewTemplateModal: React.FC<InterviewTemplateModalProps> = ({
  open,
  onClose,
  interview,
}) => {
  const [alertOpen, setAlertOpen] = useState(false);
  const styles = useStyles();

  const [analytics] = useAnalytics();
  const [title, setTitle] = useState(interview.title);
  const [description, setDescription] = useState(interview.description);
  const [hidden, setHidden] = useState(interview.hidden);
  const [updateTemplate, { loading }] = useMutation(UPDATE_EVENT_TEMPLATE, {
    awaitRefetchQueries: true,
    refetchQueries: ["GuideTemplateForEditing"],
  });
  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    analytics.track("EventTemplate edited");
    await updateTemplate({
      variables: {
        eventTemplateId: interview.id,
        eventHidden: hidden,
        eventTitle: title,
        eventDescription: description,
      },
    });
    onClose();
  };

  const handleClose = () => {
    if (
      title !== interview.title ||
      description !== interview.description ||
      hidden !== interview.hidden
    ) {
      setAlertOpen(true);
      return;
    }
    onClose();
  };

  const alertOnAccept = () => {
    setAlertOpen(false);
    onClose();
  };

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        title="Interview Descriptions"
        onClose={handleClose}
      >
        <form onSubmit={onSubmit}>
          <DialogContent className={styles.dialogContent}>
            <InterviewTemplate
              atsInterviewName={interview.atsEventTemplateName}
              title={title}
              description={description}
              hidden={hidden}
              setTitle={setTitle}
              setDescription={setDescription}
              setHidden={setHidden}
            />
          </DialogContent>
          <DialogActions className={styles.dialogActions}>
            <Button
              color="secondary"
              size="large"
              variant="outlined"
              onClick={onClose}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              size="large"
              variant="contained"
              type="submit"
              disabled={loading}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <DestructiveActionPrompt
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        onAccept={alertOnAccept}
        titleLabel="Unsaved Changes"
        bodyText="You have unsaved changes. Are you sure you want to discard them?"
        confirmLabel="Discard changes"
      />
    </>
  );
};

export default InterviewTemplateModal;
