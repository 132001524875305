import getUrls from "get-urls";

export default (text: string): string[] => {
  const urls = getUrls(text, {
    // Allow urls without https:// or www prefix to be recognized
    requireSchemeOrWww: false,
  });

  return [...urls];
};
