import React, { useState } from "react";
import _ from "lodash";
import {
  Typography,
  makeStyles,
  Card,
  ButtonGroup,
  Button,
  Chip,
  Fade,
} from "@material-ui/core";
import FeedbackCategories from "~/components/modules/Feedback/steps/DetailedFeedback/categories";

const useStyles = makeStyles(() => ({
  root: {
    transition: "height .5s",
    padding: "30px",
    "&:not(:last-child)": {
      marginBottom: "10px",
    },
    "& > *:not(:last-child)": {
      marginBottom: "15px",
    },
    "& .buttonContainer": {
      display: "flex",
      justifyContent: "center",
    },
    "& > .emoji, .loadingEmoji": {
      marginTop: "0",
      textAlign: "center",
    },
    "& > .categorySection": {
      marginBottom: "10px",
      textAlign: "center",
      "& > .categoryHeader": {
        marginBottom: "15px",
      },
      "& > .chipsCont": {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        "& > * ": {
          marginBottom: "10px",
          marginRight: "10px",
        },
      },
    },
  },
}));

type MasonryCardProps = {
  id: string;
  emoji: string;
  whatCouldBeBetter?: string[];
  whatWentWell?: string[];
  comment: string;
};

const MasonryCard: React.FC<MasonryCardProps> = ({
  id,
  emoji,
  whatCouldBeBetter,
  whatWentWell,
  comment,
}) => {
  const classes = useStyles();
  const [tab, setTab] = useState<0 | 1>(comment ? 0 : 1);

  return (
    <Card className={classes.root} elevation={1}>
      <Typography className="emoji" variant="h3">
        <span role="img" aria-label="Rating Emoji">
          {emoji}
        </span>
      </Typography>
      <ButtonGroup className="buttonContainer" size="small">
        <Button
          color={tab === 0 ? "primary" : "default"}
          onClick={(): void => setTab(0)}
          disabled={!comment}
        >
          Comment
        </Button>
        <Button
          color={tab === 1 ? "primary" : "default"}
          onClick={(): void => setTab(1)}
          disabled={
            whatCouldBeBetter?.length === 0 || whatWentWell?.length === 0
          }
        >
          Details
        </Button>
      </ButtonGroup>
      {tab === 0 && (
        <Fade in timeout={300}>
          <Typography variant="subtitle1">{comment}</Typography>
        </Fade>
      )}
      {tab === 1 && (
        <Fade in timeout={300}>
          <div className="categorySection">
            <Typography className="categoryHeader" variant="subtitle2">
              {whatCouldBeBetter &&
                whatCouldBeBetter?.length !== 0 &&
                "What Could Be Better"}
              {whatWentWell && whatWentWell?.length !== 0 && "What Went Well"}
            </Typography>
            <div className="chipsCont">
              {whatWentWell &&
                whatWentWell.map((category) => {
                  const categoryDisplay = _.find(FeedbackCategories, [
                    "id",
                    category,
                  ]);
                  if (categoryDisplay?.emoji && categoryDisplay?.description) {
                    return (
                      <Chip
                        key={id + category}
                        clickable={false}
                        variant="outlined"
                        label={`${categoryDisplay?.emoji} ${categoryDisplay?.description}`}
                      />
                    );
                  }
                  return null;
                })}
              {whatCouldBeBetter &&
                whatCouldBeBetter.map((category) => {
                  const categoryDisplay = _.find(FeedbackCategories, [
                    "id",
                    category,
                  ]);
                  if (categoryDisplay?.emoji && categoryDisplay?.description) {
                    return (
                      <Chip
                        key={id + category}
                        clickable={false}
                        variant="outlined"
                        label={`${categoryDisplay?.emoji} ${categoryDisplay?.description}`}
                      />
                    );
                  }
                  return null;
                })}
            </div>
          </div>
        </Fade>
      )}
    </Card>
  );
};

export default MasonryCard;
