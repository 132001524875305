import _ from "lodash";
import React, { useState, useEffect, useContext } from "react";
import * as Sentry from "@sentry/react";
import { Auth0Client } from "@auth0/auth0-spa-js";
import {
  Auth0ClientOptions,
  PopupLoginOptions,
  RedirectLoginOptions,
} from "@auth0/auth0-spa-js/dist/typings/global";
import initializeAuth0Client from "~/utils/auth/initializeAuth0Client";

const DEFAULT_REDIRECT_CALLBACK = (): void =>
  window.history.replaceState({}, document.title, window.location.pathname);

export type AppState = {
  referrer?: string;
};

type Auth0UserProfile = {
  sub: string;
  [key: string]: any;
};

type Auth0ProviderProps = Auth0ClientOptions & {
  onRedirectCallback?(appState: AppState): void;
  children: React.ReactElement;
};

type Auth0ContextProps = {
  isAuthenticated: boolean;
  user?: Auth0UserProfile;
  loading: boolean;
  popupOpen: boolean;
  loginWithPopup(params: any): Promise<Auth0UserProfile | undefined>;
  handleRedirectCallback(): Promise<void>;
  getIdTokenClaims(...args: any[]): any;
  loginWithRedirect(...args: any[]): any;
  getTokenSilently(...args: any[]): any;
  getTokenWithPopup(...args: any[]): any;
  logout(...args: any[]): any;
};

export const Auth0Context = React.createContext<Auth0ContextProps>({
  isAuthenticated: false,
  loading: false,
  popupOpen: false,
  loginWithPopup: () => Promise.resolve(undefined),
  handleRedirectCallback: () => Promise.resolve(),
  getIdTokenClaims: () => {},
  loginWithRedirect: () => {},
  getTokenSilently: () => {},
  getTokenWithPopup: () => {},
  logout: () => {},
});

export const useAuth0 = () => useContext(Auth0Context);

export const Auth0Provider: React.FC<Auth0ProviderProps> = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<Auth0UserProfile>();
  const [auth0Client, setAuth0] = useState<Auth0Client>();
  const [loading, setLoading] = useState<boolean>(true);
  const [popupOpen, setPopupOpen] = useState<boolean>(false);

  useEffect(() => {
    const initAuth0 = async (): Promise<void> => {
      const auth0FromHook = await initializeAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      if (
        window.location.search.includes("code=") &&
        window.location.search.includes("state=")
      ) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const newIsAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(newIsAuthenticated);

      if (newIsAuthenticated) {
        const newUser = await auth0FromHook.getUser();
        setUser(newUser);
      }

      setLoading(false);
    };
    initAuth0().catch((err) => {
      let errorToReport = err;
      if (!_.isError(err)) {
        errorToReport = new Error(err.error);
        _.assign(errorToReport, err);
      }
      Sentry.captureException(errorToReport);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginWithRedirect = async (
    params: RedirectLoginOptions = {}
  ): Promise<void> => {
    if (!auth0Client) {
      return;
    }
    try {
      await auth0Client.loginWithRedirect(params);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const loginWithPopup = async (
    params: PopupLoginOptions = {}
  ): Promise<Auth0UserProfile | undefined> => {
    if (!auth0Client) {
      return undefined;
    }
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setPopupOpen(false);
    }

    // There's a bug with getUser where it falls back the default scope and audience,
    // which causes it to not properly look up a user here if we've passed extra scope params.
    // For now we work around this by passing the explicit args to getUser.
    // See: https://github.com/auth0/auth0-spa-js/issues/321
    const newUser = params.scope
      ? await auth0Client.getUser({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          audience: auth0Client.options.audience,
          scope: params.scope,
        })
      : await auth0Client.getUser();

    setUser(newUser);
    setIsAuthenticated(true);
    return newUser;
  };

  const handleRedirectCallback = async (): Promise<void> => {
    if (!auth0Client) {
      return;
    }
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const newUser = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(newUser);
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getTokenSilently: (...p: any[]) => auth0Client?.getTokenSilently(...p),
        getIdTokenClaims: (...p: any[]) => auth0Client?.getIdTokenClaims(...p),
        loginWithRedirect,
        getTokenWithPopup: (...p: any[]) =>
          auth0Client?.getTokenWithPopup(...p),
        logout: (...p: any[]) => auth0Client?.logout(...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
