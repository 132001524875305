import React, { FunctionComponent, useContext } from "react";
import { Typography, Button, useTheme } from "@material-ui/core";

import { AnalyticsContext } from "~/analytics";
import getScheduleGoogleCalendarLink from "~/utils/getScheduleGoogleCalenderLink";

import styles from "./PhoneScreenHeader.module.scss";

type PhoneScreenHeaderProps = {
  firstName: string;
  startTime: string;
  endTime: string;
  companyName: string;
  guideUrl: string;
};

const PhoneScreenHeader: FunctionComponent<PhoneScreenHeaderProps> = ({
  firstName,
  startTime,
  endTime,
  companyName,
  guideUrl,
}) => {
  const theme = useTheme();
  const analytics = useContext(AnalyticsContext);

  return (
    <div className={styles.PhoneScreenHeaderContainer}>
      <Typography variant="h4">
        {firstName}, your phone interview is scheduled ✅
      </Typography>
      <div className={styles.linksContainer}>
        <Button
          className={styles.button}
          style={{
            color: theme.palette.primary.main,
          }}
          color="primary"
          rel="noopener noreferrer"
          target="_blank"
          href={getScheduleGoogleCalendarLink({
            startTime,
            endTime,
            guideUrl,
            companyName,
            eventTitle: `Phone Screen with ${companyName}`,
            eventDescription: `Phone Screen with ${companyName}.\n\n ${guideUrl}`,
          })}
          onClick={() =>
            analytics.track("Add to Calendar Link Clicked", {
              companyName,
              guideUrl,
            })
          }
        >
          Add to Calendar
        </Button>
      </div>
    </div>
  );
};

export default PhoneScreenHeader;
