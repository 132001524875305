import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { Typography } from "@material-ui/core";
import SlackIcon from "~/icons/slack-new-logo.svg";
import GHIcon from "~/icons/greenhouse-icon-lg.svg";
import { ResourceCustomTheme } from "~/styles/config";

const useEditStyles = makeStyles((theme: ResourceCustomTheme) => ({
  "@keyframes grow-and-shrink": {
    "0%": {},
    "50%": {
      transform: "scale(1.3)",
    },
    "100%": {
      transform: "scale(1.0)",
    },
  },
  root: {
    width: 250,
  },
  media: {
    margin: 0,
    padding: 0,
    height: 250,
  },
  content: {
    marginTop: "0px",
    height: 100,
    position: "relative",
    "& .sourceIcon": {
      top: "-21.5px",
      right: "15px",
      background: theme.colors.White,
      position: "absolute",
      borderRadius: "50%",
      overflow: "hidden",
      height: "43px",
      width: "43px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: `${theme.colors.KarlGray()} 1px solid`,
      padding: "4px",
      "& > img": {
        height: "35px",
        width: "35px",
        overflow: "hidden",
      },
    },
  },
  name: {
    marginTop: "4px",
    marginBottom: "0px",
  },
  title: {
    marginTop: "10px",
    marginBottom: "0px",
  },
  warning: {
    border: "1px solid #FFD000",
    position: "relative",
    overflow: "inherit",
    "&:after": {
      animation: "3s $grow-and-shrink infinite linear",
      position: "absolute",
      content: '""',
      top: -7,
      right: -7,
      height: "14px",
      width: "14px",
      borderRadius: "50%",
      background: "#FFD000",
    },
  },
}));

type InterviewerCardProps = {
  name: string;
  imageUrl: string;
  title: string;
  linkedInUrl: string;
  biography: string;
  slackUserUserId?: string | null;
  atsUserId?: string | null;
};

const InterviewerCard: React.FC<InterviewerCardProps> = ({
  name,
  imageUrl,
  title,
  linkedInUrl,
  biography,
  slackUserUserId,
  atsUserId,
}) => {
  const classes = useEditStyles();
  const isFilledOutCompletely =
    !!name && !!imageUrl && !!title && !!linkedInUrl && !!biography;

  return (
    <Card
      className={[
        classes.root,
        !isFilledOutCompletely ? classes.warning : "",
      ].join(" ")}
    >
      <CardActionArea>
        <CardMedia
          className={classes.media}
          component="img"
          alt={name}
          height="250"
          image={imageUrl}
          title={name}
        />
        <CardContent className={classes.content}>
          {(slackUserUserId || atsUserId) && (
            <div className="sourceIcon">
              {slackUserUserId && <img src={SlackIcon} alt="Slack Icon" />}
              {atsUserId && <img src={GHIcon} alt="Greenhouse Icon" />}
            </div>
          )}
          <Typography className={classes.name} variant="h4" noWrap>
            {name}
          </Typography>
          <Typography className={classes.title} variant="body1" noWrap>
            {title}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default InterviewerCard;
