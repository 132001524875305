import _ from "lodash";
import React, { useState } from "react";
import { TextField, Typography, makeStyles } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import emailValidator from "email-validator";
import { ResourceCustomTheme } from "~/styles/config";

const useStyles = makeStyles<ResourceCustomTheme>((_theme) => ({
  root: {},
}));

type EmailSelectProps = {
  label?: string;
  emails: string[];
  maxEmails?: number;
  className?: string;
  onChange(emails: string[]): void;
};

type EmailValue = {
  createdEmail?: string;
  email: string;
};
const EmailSelect: React.FC<EmailSelectProps> = ({
  emails,
  maxEmails,
  onChange,
  className = "",
  label = "Emails",
}) => {
  const classes = useStyles();
  const selectedValues = _.map(emails, (e) => ({ email: e }));
  const [error, setError] = useState<string>("");

  return (
    <div className={className}>
      <Autocomplete<EmailValue, true, true, false>
        multiple
        {...(maxEmails ? { limitTags: maxEmails } : {})}
        className={classes.input}
        value={selectedValues}
        options={[]}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              autoComplete="off"
              label={label}
              placeholder={emails.length ? "" : "Start typing to add an email"}
              variant="outlined"
              fullWidth
            />
          );
        }}
        ChipProps={{
          color: "primary",
        }}
        getOptionLabel={(option) => option.email}
        filterOptions={(options, params) => {
          if (params.inputValue !== "") {
            return [
              {
                createdEmail: params.inputValue,
                email: `Add "${params.inputValue}"`,
              },
            ];
          }

          return [];
        }}
        onChange={(_event, newValues) => {
          const isCreated = (v: EmailValue) => !!v.createdEmail;
          const createdEmail = _.find(newValues, isCreated)?.createdEmail;
          if (createdEmail) {
            if (!emailValidator.validate(createdEmail)) {
              setError("Please enter a valid email address.");
              return undefined;
            }
            onChange(
              _.chain(newValues)
                .reject(isCreated)
                .map("email")
                .concat(createdEmail.toLowerCase())
                .value()
            );
          } else {
            onChange(_.map(newValues, "email"));
          }

          setError("");
          return undefined;
        }}
      />
      {error && (
        <Typography variant="subtitle1" color="error">
          {error}
        </Typography>
      )}
    </div>
  );
};

export default EmailSelect;
