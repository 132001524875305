import gql from "graphql-tag";

export default gql`
  mutation AddGreenhouseStageTrigger(
    $input: InsertGreenhouseStageTriggerInput!
  ) @hasura {
    addGreenhouseStageTrigger(input: $input) {
      success
      stageTemplateInstallation {
        id
      }
    }
  }
`;
