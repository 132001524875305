import { createCanvas } from "canvas";
import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { faCheck, faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextField, useTheme } from "@material-ui/core";

import { CSSProperties } from "@material-ui/core/styles/withStyles";
import useDebouncedMutation from "~/utils/useDebouncedMutation";
import { resourceMidnightBlue, resourceSuccessGreen } from "~/styles/config";

import styles from "./EditableTextField.module.scss";

type EditableTextFieldProps = {
  debounceTime?: number;
  defaultText?: string;
  mutation: any;
  preventEventPropagation?: boolean;
  typographyVariant?: string;
  showConfirmation?: boolean;
  width?: string;
  generateMutationVariables(value: string): Record<string, unknown>;
};

// Leverage the Canvas API to accurately calculate the length of a text given
// its font size and family
const canvas = createCanvas(200, 200);
const ctx = canvas.getContext("2d");

const EditableTextField: React.FunctionComponent<EditableTextFieldProps> = ({
  debounceTime = 250,
  defaultText = "",
  mutation,
  generateMutationVariables,
  preventEventPropagation,
  showConfirmation,
  typographyVariant = "h4",
  width,
}) => {
  const [text, setText] = useState(defaultText);
  const [showSuccess, setShowSuccess] = useState(false);
  const [runMutation, { loading }] = useDebouncedMutation(
    mutation,
    undefined,
    debounceTime
  );
  const shouldShowIcon = loading || showSuccess;
  const handleSetText = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const updatedText = event.target.value;
    setText(updatedText);

    if (!updatedText) {
      return;
    }

    try {
      await runMutation({
        variables: generateMutationVariables(updatedText),
      });

      if (showConfirmation) {
        setShowSuccess(true);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };
  const theme = useTheme();
  const inputStyles = (theme.typography as any)[
    typographyVariant
  ] as CSSProperties;
  const { fontSize, fontFamily } = inputStyles;
  ctx.font = `${fontSize} ${fontFamily}`;
  const { width: inputWidth } = ctx.measureText(text);

  useEffect(() => {
    if (showSuccess) {
      const timer = setTimeout(() => setShowSuccess(false), 2000);
      return () => clearTimeout(timer);
    }

    return undefined;
  }, [showSuccess]);

  return (
    <div className={styles.editTextFieldContainer} style={{ maxWidth: width }}>
      <TextField
        error={!text}
        variant="standard"
        onClick={(event): void => {
          if (preventEventPropagation) {
            event.stopPropagation();
          }
        }}
        value={text}
        onChange={handleSetText}
        style={
          text
            ? {
                width: inputWidth + 15,
                maxWidth: "100%",
              }
            : {
                width: Math.max(inputWidth + 15, 135),
                maxWidth: "100%",
              }
        }
        inputProps={{ style: inputStyles }}
        helperText={text ? "" : "Name can not be empty"}
        required
      />
      {shouldShowIcon && (
        <FontAwesomeIcon
          icon={loading ? faSpinnerThird : faCheck}
          style={{
            color: loading ? resourceMidnightBlue : resourceSuccessGreen,
            marginLeft: "10px",
          }}
          size="2x"
          spin={loading}
        />
      )}
    </div>
  );
};

export default EditableTextField;
