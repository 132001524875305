import _ from "lodash";
import React, {
  FunctionComponent,
  useContext,
  useState,
  useEffect,
} from "react";
import { Tooltip, Fab, ThemeProvider } from "@material-ui/core";
import { match as Match, useHistory } from "react-router-dom";
import * as Sentry from "@sentry/react";
import queryString from "query-string";
import { useQuery, useMutation, useSubscription } from "@apollo/client";
import gql from "graphql-tag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/pro-solid-svg-icons";
import { useFlags } from "@resource/client-ffs";
import { FeatureFlagEnum, retry } from "@resource/common";
import { useCurrentlyViewingGuide } from "~/utils/auth/CurrentlyViewingGuideProvider";
import GUIDE_REFRESH_SUBSCRIPTION from "./GUIDE_REFRESH_SUBSCRIPTION@hasura";
import Loading from "~/components/Loading/Loading";
import CompanyLogo from "~/components/CompanyLogo";
import MadeWithLove from "~/components/MadeWithLove/MadeWithLove";
import CURRENT_USER_QUERY from "~/queries/CURRENT_USER_QUERY";
import { AnalyticsContext } from "~/analytics";
import {
  CandidateFacingGuideRefresh,
  CandidateFacingGuideRefreshVariables,
} from "~/schemaTypesHasura";
import {
  CurrentUser,
  FetchGuide,
  FetchGuideVariables,
  AddCandidateViewToGuideMutation,
  AddCandidateViewToGuideMutationVariables,
} from "~/schemaTypes";
import { themeCreator, resourceRed } from "~/styles/config";
import Stage from "./Stage/Stage";
import StageStepper from "./StageStepper/StageStepper";
import styles from "./Guide.module.scss";
import { useAuth0 } from "~/react-auth0";

const StreamChat = React.lazy(() =>
  retry(() => import("~/components/modules/StreamChat/StreamChat"))
);

const FETCH_GUIDE_QUERY = gql`
  query FetchGuide($input: GuideFindInput!) {
    guide(input: $input) {
      id
      atsUrl
      chatEnabled
      interviewProcessEnabled
      currentVisibleStage {
        id
        position
        title
      }
      organization {
        id
        companyLogoUrl
        name
        theme
      }
      stages {
        id
        position
        title
        hidden
        needsFeedback
      }
    }
  }
`;

const ADD_CANDIDATE_VIEW_TO_GUIDE_MUTATION = gql`
  mutation AddCandidateViewToGuideMutation(
    $input: AddCandidateViewToGuideInput!
  ) {
    addCandidateViewToGuide(input: $input) {
      message
      code
      success
      guide {
        id
        candidateOpens
      }
    }
  }
`;

type GuideProps = {
  match:
    | Match<{
        guideShortId: string;
        customerSlug: string;
      }>
    | Match<{
        guideId: string;
      }>;
  location: {
    search: string;
  };
};

const Guide: FunctionComponent<GuideProps> = ({ match, location }) => {
  let customerSlug: string | null;
  let guideId: string | null;
  let guideShortId: string | null;
  if ("guideId" in match.params) {
    ({ guideId } = match.params);
    customerSlug = null;
    guideShortId = null;
  } else {
    ({ customerSlug, guideShortId } = match.params);
    guideId = null;
  }
  const {
    [_.camelCase(FeatureFlagEnum.GuideLevelChat)]: guideLevelChatFlag,
    [_.camelCase(
      FeatureFlagEnum.GuideLevelInterviewProcess
    )]: guideLevelInterviewProcessFlag,
    [_.camelCase(FeatureFlagEnum.GuideLiveRefresh)]: guideLiveRefreshFlag,
  } = useFlags();
  const {
    currentlyViewingGuideId,
    setCurrentlyViewingGuideId,
  } = useCurrentlyViewingGuide();
  const { isAuthenticated } = useAuth0();
  const history = useHistory();
  const { stageId: stageIdOrArray } = queryString.parse(location.search);
  const stageId = _.isArray(stageIdOrArray)
    ? _.first(stageIdOrArray)
    : stageIdOrArray;
  const { Intercom } = window;
  const analytics = useContext(AnalyticsContext);

  const [addCandidateView] = useMutation<
    AddCandidateViewToGuideMutation,
    AddCandidateViewToGuideMutationVariables
  >(ADD_CANDIDATE_VIEW_TO_GUIDE_MUTATION);

  const { data: currentUserData } = useQuery<CurrentUser>(CURRENT_USER_QUERY);

  useEffect(() => {
    if (Intercom) {
      if (currentUserData?.currentUserV2) {
        Intercom("update", { hide_default_launcher: false });
      } else {
        Intercom("update", { hide_default_launcher: true });
      }
    }
  });

  const { data: guideData, loading, error, refetch } = useQuery<
    FetchGuide,
    FetchGuideVariables
  >(FETCH_GUIDE_QUERY, {
    variables: {
      input: _.pickBy(
        guideId
          ? {
              id: guideId,
            }
          : {
              customerSlug,
              shortId: guideShortId,
            }
      ),
    },
    onCompleted: ({ guide }) => {
      analytics.page("Guide", {
        companyName: guide?.organization.name || "Unknown",
        guideId: guide?.id,
      });
      if (guide?.id && !isAuthenticated) {
        addCandidateView({ variables: { input: { guideId: guide.id } } });
      }
      if (!guide && customerSlug && guideShortId) {
        history.replace("/not-found");
      }
    },
  });
  const fetchedGuideId = guideData?.guide?.id;
  const guideDataLoading =
    currentlyViewingGuideId !== fetchedGuideId || loading;
  useEffect(() => {
    // Set the currently viewing guideId in our Context provider
    // so that it can be added to all outbound requests for anonymous viewers
    if (fetchedGuideId && fetchedGuideId !== currentlyViewingGuideId) {
      setCurrentlyViewingGuideId(fetchedGuideId);
    }
  }, [fetchedGuideId, currentlyViewingGuideId, setCurrentlyViewingGuideId]);

  if (error) {
    Sentry.captureException(error);
    const hasNotFoundError = _.find(error.graphQLErrors, (graphQLError) => {
      return graphQLError.extensions?.code === "NOT_FOUND";
    });
    if (hasNotFoundError) {
      history.replace("/not-found");
    }
  }

  const { data: guideRefreshData } = useSubscription<
    CandidateFacingGuideRefresh,
    CandidateFacingGuideRefreshVariables
  >(GUIDE_REFRESH_SUBSCRIPTION, {
    variables: {
      where: {
        id: {
          _eq: currentlyViewingGuideId,
        },
      },
    },
    skip: !currentlyViewingGuideId || !guideLiveRefreshFlag,
  });

  useEffect(() => {
    // Upon any change to the current stage, refecth the guide data
    if (guideLiveRefreshFlag && guideRefreshData) {
      refetch();
    }
  }, [refetch, guideLiveRefreshFlag, guideRefreshData]);

  const currentStageId = stageId || guideData?.guide?.currentVisibleStage?.id;
  const guide = guideData?.guide;
  const stages = guide?.stages || [];
  const theme = guide?.organization.theme;

  const [selectedStageId, setSelectedStageId] = useState(currentStageId);

  useEffect(() => {
    if (currentStageId) {
      setSelectedStageId(currentStageId);
    }
  }, [currentStageId]);

  if (!guideId) {
    guideId = guide?.id || null;
  }

  const companyName = guide?.organization.name || null;

  const hasMultipleStages = stages.length;
  const showStageStepper =
    currentStageId &&
    selectedStageId &&
    hasMultipleStages &&
    (guideLevelInterviewProcessFlag
      ? guide?.interviewProcessEnabled
      : !stageId);

  return (
    <div className={styles.container}>
      {guideDataLoading ? (
        <Loading />
      ) : (
        <>
          {currentUserData?.currentUserV2 && (
            <div className={styles.editButtonContainer}>
              <Tooltip title="Edit Guide" placement="left">
                <Fab
                  className={styles.editButton}
                  component="a"
                  href={guide?.atsUrl || ""}
                  target="_blank"
                  onClick={(): void => {
                    analytics.track("Edit Button Clicked");
                  }}
                >
                  <FontAwesomeIcon
                    className={styles.editIcon}
                    icon={faPen}
                    size="lg"
                  />
                </Fab>
              </Tooltip>
            </div>
          )}
          <div className={styles.guideContainer}>
            <CompanyLogo
              companyName={companyName}
              companyLogoUrl={
                guideData?.guide?.organization.companyLogoUrl || null
              }
            />
            {showStageStepper && (
              <ThemeProvider
                theme={themeCreator({
                  primaryColorHex:
                    theme && theme.primaryColor !== "#ffffff"
                      ? theme.primaryColor
                      : "#79728C",
                  secondaryColorHex:
                    theme && theme.secondaryColor !== "#ffffff"
                      ? theme.secondaryColor
                      : resourceRed,
                })}
              >
                <StageStepper
                  currentStageId={currentStageId!}
                  selectedStageId={selectedStageId!}
                  onSelectStage={(newStageId) => setSelectedStageId(newStageId)}
                  stages={stages}
                />
              </ThemeProvider>
            )}
            {guideId && selectedStageId && (
              <Stage
                guideId={guideId}
                stageId={selectedStageId}
                companyName={companyName}
                companyDataLoading={false}
              />
            )}
            {guideLevelChatFlag && guideId && guide?.chatEnabled && (
              <StreamChat guideId={guideId} />
            )}
          </div>
          <div className={styles.madeWithLove}>
            <MadeWithLove />
          </div>
        </>
      )}
    </div>
  );
};
export default Guide;
