import { withStyles, Theme, Tooltip } from "@material-ui/core";
import { resourceGrey } from "~/styles/config";

const PulsatingTooltip = withStyles((theme: Theme) => ({
  "@keyframes pulsateBorder": {
    "0%": { borderColor: resourceGrey },
    "50%": { borderColor: theme.palette.primary.main },
    "100%": { borderColor: resourceGrey },
  },
  tooltip: {
    border: `2px solid ${resourceGrey}`,
    animation: "$pulsateBorder 2s infinite",
    fontSize: "14px",
  },
}))(Tooltip);

export default PulsatingTooltip;
