import _ from "lodash";
import React from "react";
import cx from "classnames";
import {
  Chip,
  Typography,
  makeStyles,
  Card,
  Fade,
  Tooltip,
} from "@material-ui/core";

import { FeedbackRatingEnum, FeedbackRatingNumeric } from "@resource/common";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons";
import chipStyles from "~/components/modules/Feedback/steps/DetailedFeedback/CategoryChip.module.scss";
import allCategories, {
  Category,
} from "~/components/modules/Feedback/steps/DetailedFeedback/categories";
import Loading from "~/components/Loading/Loading";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "stretch",
    minHeight: "200px",
  },
  categoryContent: {
    marginTop: "20px",
  },
  ratingContent: {
    marginTop: "45px",
  },
  summaryCard: {
    padding: "30px",
    width: "32.7%",
    textAlign: "center",
  },
  summaryTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px",
    lineHeight: "20px",
    "& .helpIcon": {
      marginLeft: "5px",
      height: "16px",
      width: "16px",
    },
  },
  noData: {
    marginTop: "30px",
  },
  emojiRating: {
    marginRight: "15px",
    fontSize: "48px",
  },
  emojiText: {
    verticalAlign: "super",
  },
  emojiTooltip: {
    marginTop: "-15px",
  },
}));

type SummaryProps = {
  averageRating: number | null | undefined;
  whatWentWell: string[];
  whatCouldBeBetter: string[];
  loading: boolean;
};

const FeedbackRatingLookup = _.invert(FeedbackRatingNumeric);

const FeedbackCopy = {
  [FeedbackRatingEnum.Great]: "It went great!",
  [FeedbackRatingEnum.Good]: "It went good!",
  [FeedbackRatingEnum.Okay]: "It was okay",
  [FeedbackRatingEnum.Bad]: "It needs work",
  [FeedbackRatingEnum.Terrible]: "It really needs work",
};

type CategoryContentProps = {
  categories: Category[];
};

const NoData: React.FC = () => {
  const classes = useStyles();
  return (
    <Typography className={classes.noData}>
      Looks like there isn&apos;t enough data. Try changing the filters.
    </Typography>
  );
};

const CategoryContent: React.FC<CategoryContentProps> = ({ categories }) => {
  const classes = useStyles();
  return categories.length ? (
    <div className={classes.categoryContent}>
      {_.map(categories, ({ emoji, description }) => (
        <Chip
          key={description}
          className={cx(chipStyles.chip)}
          clickable={false}
          variant="outlined"
          label={`${emoji} ${description}`}
        />
      ))}
    </div>
  ) : (
    <NoData />
  );
};

const Summary: React.FC<SummaryProps> = ({
  averageRating,
  whatWentWell,
  whatCouldBeBetter,
  loading,
}) => {
  const classes = useStyles();
  const emojiRating = averageRating
    ? (FeedbackRatingLookup[_.round(averageRating)] as FeedbackRatingEnum)
    : null;
  const numericRating = averageRating ? _.round(averageRating, 1) + 3 : null;

  const whatWentWellCategories = _.filter(allCategories, (category) => {
    return whatWentWell.includes(category.id);
  });
  const whatCouldBeBetterCategories = _.filter(allCategories, (category) => {
    return whatCouldBeBetter.includes(category.id);
  });

  if (loading) {
    return <Loading inlineStyling style={{ height: "200px" }} />;
  }

  return (
    <Fade in timeout={300}>
      <div className={classes.root}>
        <Card className={classes.summaryCard}>
          <Tooltip title="😠 = 1 🙁 = 2 😐 = 3 🙂 = 4 😃 = 5">
            <Typography className={classes.summaryTitle} variant="subtitle2">
              Average Rating
              <FontAwesomeIcon className="helpIcon" icon={faQuestionCircle} />
            </Typography>
          </Tooltip>
          {emojiRating && numericRating ? (
            <Typography className={classes.ratingContent} variant="h4">
              <Tooltip
                PopperProps={{
                  className: classes.emojiTooltip,
                }}
                title={`${numericRating} / 5`}
              >
                <span className={classes.emojiRating}>{emojiRating}</span>
              </Tooltip>
              <span className={classes.emojiText}>
                {FeedbackCopy[emojiRating]}
              </span>
            </Typography>
          ) : (
            <NoData />
          )}
        </Card>
        <Card className={classes.summaryCard}>
          <Typography className={classes.summaryTitle} variant="subtitle2">
            What went well
          </Typography>
          <CategoryContent categories={whatWentWellCategories} />
        </Card>
        <Card className={classes.summaryCard}>
          <Typography className={classes.summaryTitle} variant="subtitle2">
            What could be improved
          </Typography>
          <CategoryContent categories={whatCouldBeBetterCategories} />
        </Card>
      </div>
    </Fade>
  );
};

export default Summary;
