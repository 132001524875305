import { gql } from "@apollo/client";

const HASURA_GET_GUIDE_TEMPLATES = gql`
  query GetOrganizationRoleJourneys(
    $guideTemplatesLimit: Int
    $guideTemplatesOffset: Int
    $guideTemplatesOrderBy: guide_template_order_by!
    $guideTemplatesWhere: guide_template_bool_exp
    $currentOrganizationId: uuid
  ) @hasura {
    currentOrganizationId @client @export(as: "currentOrganizationId")
    get_current_user_v2(
      args: { user_current_organization_id: $currentOrganizationId }
    ) {
      id
      currentOrganization {
        id
        guide_templates(
          limit: $guideTemplatesLimit
          offset: $guideTemplatesOffset
          order_by: [$guideTemplatesOrderBy]
          where: $guideTemplatesWhere
        ) {
          id
          name
          job_role {
            id
            name
            atsJobId
            department: greenhouseData(path: "$.departments.[0].name")
            office: greenhouseData(path: "$.offices.[0].name")
            reqId: greenhouseData(path: "$.requisition_id")
          }
          updatedAt
          guides_aggregate(where: { email_activities: {} }) {
            aggregate {
              count
            }
          }
          stage_template_installations_aggregate {
            aggregate {
              count
            }
            nodes {
              id
              activatedAt
            }
          }
        }
        job_roles {
          id
          department: greenhouseData(path: "$.departments.[0].name")
          office: greenhouseData(path: "$.offices.[0].name")
        }
      }
    }
  }
`;

export default HASURA_GET_GUIDE_TEMPLATES;
