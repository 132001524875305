import moment from "moment";
import { v4 as uuid } from "uuid";

import {
  StageById_stage_sortedVisibleEvents as Event,
  StageById_stage_sortedVisibleEvents_interviewers as Interviewer,
} from "~/schemaTypes";
import { austin } from "./generate-interviewer";

export default ({
  title = "Motivation + Growth",
  description = "",
  startTime,
  endTime,
  interviewers = [austin],
  hidden = false,
}: {
  startTime?: Date;
  endTime?: Date;
  description?: string;
  hidden?: boolean;
  title?: string;
  interviewers?: Interviewer[];
}): Event => {
  const startTimeValue = (startTime
    ? moment(startTime)
    : moment().add(1, "day")
  ).valueOf();
  const endTimeValue = moment(
    endTime || moment(startTimeValue).add(30, "minutes")
  ).valueOf();
  return {
    id: uuid(),
    title,
    description,
    remoteInstructions: "",
    techScreenLink: "",
    zoomLink: "",
    startTime: startTimeValue,
    endTime: endTimeValue,
    interviewers,
    prominentInstructions: [],
    hidden,
    __typename: "EventV2",
  };
};
