import React, { FunctionComponent, useContext, useEffect } from "react";
import _ from "lodash";
import { Typography } from "@material-ui/core";
import { useQuery, useSubscription } from "@apollo/client";
import { ModuleEnum, FeatureFlagEnum } from "@resource/common";

import { useFlags } from "@resource/client-ffs";
import ErrorBoundary from "~/components/ErrorBoundary";
import STAGE_REFRESH_SUBSCRIPTION from "./STAGE_REFRESH_SUBSCRIPTION@hasura";
import Loading from "~/components/Loading/Loading";
import { StageRefresh, StageRefreshVariables } from "~/schemaTypesHasura";
import ModuleContainer from "~/components/modules/ModuleContainer/ModuleContainer";
import { AnalyticsContext } from "~/analytics";
import {
  StageById,
  StageByIdVariables,
  StageById_stage_modules,
} from "~/schemaTypes";

import styles from "./Stage.module.scss";
import STAGE_BY_ID_QUERY from "~/queries/STAGE_BY_ID_QUERY";

interface StageProps {
  stageId: string;
  guideId: string;
  companyName: string | null;
  companyDataLoading: boolean;
}

const Stage: FunctionComponent<StageProps> = ({
  guideId,
  stageId,
  companyName,
  companyDataLoading,
}) => {
  const analytics = useContext(AnalyticsContext);
  const {
    [_.camelCase(FeatureFlagEnum.GuideLevelChat)]: guideLevelChatFlag,
    [_.camelCase(FeatureFlagEnum.GuideLiveRefresh)]: guideLiveRefreshFlag,
  } = useFlags();

  useEffect(() => {
    if (!companyDataLoading) {
      analytics.page("Stage", {
        companyName: companyName || "Unknown",
        guideId,
        stageId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analytics, companyDataLoading, guideId, stageId]);

  const { data: stageData, loading: stageLoading, error, refetch } = useQuery<
    StageById,
    StageByIdVariables
  >(STAGE_BY_ID_QUERY, {
    variables: {
      input: {
        id: stageId,
      },
    },
  });
  const stage = stageData?.stage;

  const { data: stageRefreshData } = useSubscription<
    StageRefresh,
    StageRefreshVariables
  >(STAGE_REFRESH_SUBSCRIPTION, {
    variables: {
      where: {
        id: {
          _eq: stage?.id,
        },
      },
    },
    skip: !stage || !guideLiveRefreshFlag,
  });

  useEffect(() => {
    // Upon any change to the current stage or Guide Template installations,
    // refecth the guide data
    if (guideLiveRefreshFlag && stageRefreshData) {
      refetch();
    }
  }, [stageRefreshData, refetch, guideLiveRefreshFlag]);

  if (error) {
    return (
      <div>
        <Typography variant="h4">
          There was an error loading your Interview Guide stage
        </Typography>
      </div>
    );
  }

  // Disable chat module if guide level is configured
  const modules = _.reject<StageById_stage_modules>(
    stage?.modules,
    ({ displayType }) => {
      return !!(
        guideLevelChatFlag &&
        displayType === ModuleEnum.Chat &&
        !stage?.guide.chatEnabled
      );
    }
  );

  return (
    <div className={styles.stageContainer}>
      {!stageLoading ? (
        _.map(modules, ({ id, displayType, data: moduleData }) => (
          <ErrorBoundary key={id} silent>
            <ModuleContainer
              key={id}
              id={id}
              displayType={displayType}
              stageId={stageId}
              guideId={guideId}
              createdFromATS={!!stage?.guide.createdFromATS}
              data={{
                ...moduleData,
                events: stage?.sortedVisibleEvents || [],
              }}
            />
          </ErrorBoundary>
        ))
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Stage;
