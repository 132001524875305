import React, { FunctionComponent } from "react";
import cx from "classnames";
import moment from "moment-timezone";
import { Typography, Link } from "@material-ui/core";

import { getScheduleGoogleCalendarLink } from "~/utils";

import styles from "./Time.module.scss";
import { StageById_stage_sortedVisibleEvents as StageEvent } from "~/schemaTypes";
import getEventDateGroups from "~/utils/get-event-date-groups";

type TimeProps = {
  address?: string;
  remote: boolean;
  remoteInstructions?: string;
  companyName: string;
  guideUrl: string;
  events: StageEvent[];
  header?: string;
};

const Time: FunctionComponent<TimeProps> = ({
  address,
  remote,
  remoteInstructions,
  companyName,
  guideUrl,
  events,
  header,
}) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const dateGroups = getEventDateGroups(events);

  // If there are no shown events dateGroups will be of length 0
  if (dateGroups.length === 0) {
    return null;
  }

  return (
    <div className={styles.timeContainer}>
      <Typography variant="h4">{header ?? "Time"}</Typography>
      <div className={styles.times}>
        {dateGroups.map((dateGroup, idx) => {
          const { endTime, startTime } = dateGroup;
          const location = remote ? remoteInstructions : address;
          const url = getScheduleGoogleCalendarLink({
            location,
            startTime,
            endTime,
            guideUrl,
            companyName,
          });
          const isPastDate = moment().isAfter(startTime);
          const shouldBlurDate = isPastDate && dateGroups.length - 1 !== idx;

          const interviewDate = moment(startTime).format("ddd MMM Do");
          const startTimeText = moment.tz(startTime, timezone).format("h:mm a");
          const endTimeText = moment.tz(endTime, timezone).format("h:mm a");
          const timeZoneText = moment.tz(endTime, timezone).format("z");

          return (
            <div
              key={`${endTime}-${startTime}`}
              className={cx({
                [styles.pastDate]: !!shouldBlurDate,
                [styles.date]: !isPastDate,
              })}
            >
              <div className={cx(styles.dateTime)}>
                <Typography variant="body1">
                  {interviewDate}
                  <br />
                  {startTimeText} - {endTimeText} ({timeZoneText})
                </Typography>
              </div>
              {!isPastDate ? (
                <Link
                  color="inherit"
                  href={url}
                  rel="noopener noreferrer"
                  target="_blank"
                  onClick={(): void =>
                    window.analytics.track("Add to Calendar Clicked", {
                      startTime,
                      endTime,
                    })
                  }
                  className={cx(styles.addToCalendar)}
                >
                  Add to calendar
                </Link>
              ) : (
                <div className={cx(styles.addToCalendar)}>Add to calendar</div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Time;
