import gql from "graphql-tag";

export default gql`
  query GetLinkPreview($input: LinkPreviewInput!) {
    getLinkPreview(input: $input) {
      title
      description
      favicon
      image
      url
    }
  }
`;
