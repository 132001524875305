import cx from "classnames";
import gql from "graphql-tag";
import React, { FunctionComponent } from "react";
import { useMutation } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArchive, faTrash } from "@fortawesome/pro-light-svg-icons";
import { Button, IconButton, Tooltip } from "@material-ui/core";
import { useFlashMessage } from "~/components/FlashMessage/FlashMessage";

import useAnalytics from "~/react-hooks/useAnalytics";
import styles from "../Guides/Guides.module.scss";

type ArchiveGuideButtonProps = {
  guideId: string;
  isArchived: boolean;
};

const ArchiveGuideButton: FunctionComponent<ArchiveGuideButtonProps> = ({
  guideId,
  isArchived,
}) => {
  const { setContent } = useFlashMessage();
  const [analytics] = useAnalytics();
  const [archiveGuideMutate] = useMutation(gql`
    mutation ArchiveGuide($input: ArchiveGuideInput!) {
      archiveGuide(input: $input) {
        success
      }
    }
  `);

  const [unarchiveGuideMutate] = useMutation(gql`
    mutation UnarchiveGuide($input: UnarchiveGuideInput!) {
      unarchiveGuide(input: $input) {
        success
        guide {
          id
          archivedAt
        }
      }
    }
  `);

  const unarchiveGuide = async (): Promise<void> => {
    try {
      await unarchiveGuideMutate({
        variables: {
          input: {
            id: guideId,
          },
        },
        refetchQueries: ["CandidateList", "FetchGuides"],
      });
      if (analytics) {
        analytics.track("Guide Unarchived");
      }
      setContent({
        content: "Guide unarchived",
        severity: "success",
      });
    } catch (err) {
      setContent({
        content: "Could not unarchive guide",
        severity: "error",
      });
    }
  };

  const archiveGuide = async (): Promise<void> => {
    await archiveGuideMutate({
      variables: {
        input: {
          id: guideId,
        },
      },
      refetchQueries: ["CandidateList", "FetchGuides"],
    });
    if (analytics) {
      analytics.track("Guide Archived");
    }
    const action = (
      <Button color="inherit" size="small" onClick={() => unarchiveGuide()}>
        UNDO
      </Button>
    );
    setContent({
      action,
      content: "Guide Archived",
      severity: "success",
    });
  };

  const onClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (isArchived) {
      unarchiveGuide();
    } else {
      archiveGuide();
    }
  };

  const title = isArchived
    ? "Unarchiving this guide will make it visible to candidates"
    : "Archiving this guide will hide it from candidates";

  return (
    <Tooltip placement="top" title={title}>
      <IconButton
        color="primary"
        className={styles.controlsButton}
        onClick={onClick}
      >
        <FontAwesomeIcon
          className={cx(styles.controlsIcon, styles.delete)}
          icon={isArchived ? faArchive : faTrash}
        />
      </IconButton>
    </Tooltip>
  );
};

export default ArchiveGuideButton;
