import React, { FunctionComponent } from "react";
import { Typography } from "@material-ui/core";

import InfoCard, { InfoCardProps } from "./InfoCard/InfoCard";

import styles from "./InfoCards.module.scss";

type InfoCardsProps = {
  header?: string;
  description?: string;
  infoArray: InfoCardProps[];
};

const InfoCards: FunctionComponent<InfoCardsProps> = ({
  header,
  description,
  infoArray,
}) =>
  infoArray.length ? (
    <div className={styles.cardsInfoContainer}>
      {header && (
        <Typography className={styles.header} variant="h4">
          {header}
        </Typography>
      )}
      {description && (
        <Typography className={styles.description} variant="body1">
          {description}
        </Typography>
      )}
      <div className={styles.cardsContainer}>
        {infoArray.map(({ icon, title, description: cardDescription }) => (
          <InfoCard
            key={title}
            header={header}
            icon={icon}
            title={title}
            description={cardDescription}
          />
        ))}
      </div>
    </div>
  ) : null;

export default InfoCards;
