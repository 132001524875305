import React, { FunctionComponent, useState, useEffect } from "react";
import gql from "graphql-tag";
import { ModuleEnum } from "@resource/common";
import { TextField } from "@material-ui/core";
import { EditModulesGlobalProps } from "../EditModuleContainer";
import styles from "./EditLocation.module.scss";
import EditGlobalInfoText from "../Shared/EditGlobalInfoText/EditGlobalInfoText";
import { useInlineCompanyEditor } from "~/pages/utils/InlineCompanyEditorContextProvider";
import SelectLocation from "../../SelectLocation/SelectLocation";

const UpdateLocationInstallationForStageTemplate = gql`
  mutation UpdateLocationInstallationForStageTemplate(
    $input: UpdateLocationInstallationForStageTemplateInput!
  ) {
    updateLocationInstallationForStageTemplate(input: $input) {
      success
    }
  }
`;

type EditLocationProps = {
  locationId: string;
  moduleId: string;
  header?: string;
  onClose(): void;
};

const EditLocation: FunctionComponent<
  EditLocationProps & EditModulesGlobalProps
> = ({
  setHandleSaveMutation,
  setHandleSaveMutationInput,
  locationId: initialLocationId,
  header,
  moduleId,
  onClose,
}) => {
  const [selectedLocationId, setSelectedLocationId] = useState<string>(
    initialLocationId
  );
  const [headerOverride, setHeaderOverride] = useState<string>(
    header || "Location"
  );
  useEffect(() => {
    setHandleSaveMutation(UpdateLocationInstallationForStageTemplate);
    setHandleSaveMutationInput({
      input: {
        location: {
          id: selectedLocationId,
        },
        moduleId,
        header: headerOverride,
      },
    });
  }, [
    setHandleSaveMutation,
    setHandleSaveMutationInput,
    moduleId,
    headerOverride,
    selectedLocationId,
  ]);

  const { setShowingCompanyComponents } = useInlineCompanyEditor();

  return (
    <div className={styles.locationsModalCont}>
      <TextField
        fullWidth
        value={headerOverride}
        onChange={(e) => setHeaderOverride(e.target.value)}
        style={{ marginBottom: 6 }}
        label="Header"
      />
      <div className={styles.headerTextCont}>
        <EditGlobalInfoText text="This update will apply to all candidates previously sent this Guide" />
      </div>
      <SelectLocation
        selectedLocationId={selectedLocationId}
        onAddLocation={(): void => {
          setShowingCompanyComponents(ModuleEnum.Location);
          onClose();
        }}
        onSelectLocation={(locationId): void => {
          setSelectedLocationId(locationId);
        }}
      />
    </div>
  );
};

export default EditLocation;
