import React, { FunctionComponent, useState } from "react";
import { useParams } from "react-router";
import useNoIntercom from "~/react-hooks/useNoIntercom.ts";

import Timeline from "./Timeline";
import steps from "./Step";
import { GuidePresenter } from "../Guide/GuidePresenter/GuidePresenter";

import styles from "./CompanyDemo.module.scss";

type CompanyDemoProps = {
  displayName?: string;
};

export const CompanyDemo: FunctionComponent<CompanyDemoProps> = ({
  displayName = "Candice",
}) => {
  const [activeStepNum, setActiveStep] = useState(
    steps.filter(({ hasCompletedStep }) => hasCompletedStep).length - 1
  );

  const TimelineComp: FunctionComponent = () => (
    <Timeline activeStepNum={activeStepNum} setActiveStep={setActiveStep} />
  );

  return (
    <div className={styles.container}>
      <GuidePresenter
        guide={steps[activeStepNum].guide(displayName)}
        error=""
        loading={false}
        TimelineComp={TimelineComp}
      />
    </div>
  );
};

const CompanyDemoWithCandidateName = () => {
  const { displayName = "Candice" } = useParams<{ displayName: string }>();
  useNoIntercom();
  return <CompanyDemo displayName={displayName} />;
};

export default CompanyDemoWithCandidateName;
