import _ from "lodash";
import React, { FunctionComponent, useState, useEffect } from "react";
import { Dialog, Divider, Button } from "@material-ui/core";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";
import DialogTitle from "~/components/Dialog/DialogTitle/DialogTitle";
import useAnalytics from "~/react-hooks/useAnalytics";
import styles from "./EditLocationModal.module.scss";
import LocationForm, {
  EditLocationFormState,
} from "~/components/LocationForm/LocationForm";
import Loading from "~/components/Loading/Loading";
import { useFlashMessage } from "~/components/FlashMessage/FlashMessage";
import {
  ArchiveLocation,
  ArchiveLocationVariables,
  LocationQueryForEditLocation,
  LocationQueryForEditLocationVariables,
  LocationsQueryForEditLocationV2,
  LocationsQueryForEditLocationV2Variables,
  LocationCreate,
  LocationCreateVariables,
  LocationUpdate,
  LocationUpdateVariables,
  UnarchiveLocation,
  UnarchiveLocationVariables,
} from "~/schemaTypes";
import FIND_ORG_LOCATIONS_QUERY from "~/queries/FIND_ORG_LOCATIONS_QUERY";

type EditLocationModalProps = {
  activeLocationId: string | null;
  clearActiveLocationId(): void;
};

const LocationFragment = gql`
  fragment locationForEditing on Location {
    id
    name
    arrivalInstructions
    address
    coordinates
  }
`;

const FindLocationQuery = gql`
  query LocationQueryForEditLocation($input: LocationFindInput!) {
    location(input: $input) {
      ...locationForEditing
    }
  }
  ${LocationFragment}
`;

const CreateLocationMutation = gql`
  mutation LocationCreate($input: LocationCreateInputV2!) {
    locationCreateV2(input: $input) {
      ...locationForEditing
    }
  }
  ${LocationFragment}
`;

const UpdateLocationMutation = gql`
  mutation LocationUpdate($input: LocationUpdateInput!) {
    locationUpdate(input: $input) {
      ...locationForEditing
    }
  }
  ${LocationFragment}
`;

const EditLocationModal: FunctionComponent<EditLocationModalProps> = ({
  activeLocationId,
  clearActiveLocationId,
}) => {
  const [open, setOpen] = useState<boolean>(true);
  const [formState, setFormState] = useState<EditLocationFormState>({
    address: "",
    coordinates: "",
    name: "",
    arrivalInstructionsQuill: "",
  });

  const [createLocation, { loading: createLoading }] = useMutation<
    LocationCreate,
    LocationCreateVariables
  >(CreateLocationMutation, {
    update(cache, { data: newData }) {
      const newLocation = newData?.locationCreateV2;
      if (newLocation) {
        cache.writeQuery({
          data: {
            location: newLocation,
          },
          query: FindLocationQuery,
          variables: {
            input: {
              id: newLocation.id,
            },
          },
        });
        let existingData;
        try {
          existingData = cache.readQuery<
            LocationsQueryForEditLocationV2,
            LocationsQueryForEditLocationV2Variables
          >({
            query: FIND_ORG_LOCATIONS_QUERY,
            variables: {
              locationInput: {
                isArchived: true,
              },
            },
          });
        } catch (error) {
          // No data, bail
          return;
        }
        if (existingData) {
          existingData?.currentUserV2?.organization.locations.unshift({
            ...newLocation,
            isArchived: false,
          });
          cache.writeQuery<
            LocationsQueryForEditLocationV2,
            LocationsQueryForEditLocationV2Variables
          >({
            query: FIND_ORG_LOCATIONS_QUERY,
            variables: {
              locationInput: {
                isArchived: true,
              },
            },
            data: existingData,
          });
        }
      }
    },
  });
  const [updateLocation, { loading: updateLoading }] = useMutation<
    LocationUpdate,
    LocationUpdateVariables
  >(UpdateLocationMutation);

  const { setContent } = useFlashMessage();
  const [analytics] = useAnalytics();

  const { data: locationData, loading: locationLoading } = useQuery<
    LocationQueryForEditLocation,
    LocationQueryForEditLocationVariables
  >(FindLocationQuery, {
    variables: {
      input: {
        id: activeLocationId,
      },
    },
  });

  const [archiveLocationMutate] = useMutation<
    ArchiveLocation,
    ArchiveLocationVariables
  >(gql`
    mutation ArchiveLocation($input: ArchiveLocationInput!) {
      archiveLocation(input: $input) {
        success
        location {
          id
          isArchived
        }
      }
    }
  `);

  const [unarchiveLocationMutate] = useMutation<
    UnarchiveLocation,
    UnarchiveLocationVariables
  >(gql`
    mutation UnarchiveLocation($input: UnarchiveLocationInput!) {
      unarchiveLocation(input: $input) {
        success
        location {
          ...locationForEditing
        }
      }
    }
    ${LocationFragment}
  `);

  const unarchiveLocation = async (id: string): Promise<void> => {
    await unarchiveLocationMutate({
      variables: {
        input: {
          id,
        },
      },
      refetchQueries: ["FetchCompanyData"],
    });
    setContent({
      content: "Location unarchived",
      severity: "success",
    });
  };

  const handleArchiveLocation = async (): Promise<void> => {
    const id = activeLocationId;
    if (!id) {
      throw new Error("No valid id to unarchive");
    }
    await archiveLocationMutate({
      variables: {
        input: {
          id,
        },
      },
    });
    const action = (
      <Button
        color="inherit"
        size="small"
        onClick={() => unarchiveLocation(id)}
      >
        UNDO
      </Button>
    );
    setContent({
      action,
      content: "Location Archived",
      severity: "success",
    });
    setOpen(false);
  };

  const handleCreateLocation = async (): Promise<void> => {
    if (!formState || !formState.name) {
      setContent({
        content: "Something went wrong creating your location.",
        severity: "error",
      });
      return;
    }
    const input = {
      ..._.omit(formState, "arrivalInstructionsQuill"),
      name: formState.name,
      arrivalInstructions: formState.arrivalInstructionsQuill,
    };
    try {
      await createLocation({
        variables: {
          input,
        },
      });
      setOpen(false);
      setContent({
        content: "Successfully created your location.",
        severity: "success",
      });
      if (analytics) {
        analytics.track("Company Page Location Created");
      }
    } catch (err) {
      setContent({
        content: "Something went wrong creating your location.",
        severity: "error",
      });
    }
  };

  const handleUpdateLocation = async (): Promise<void> => {
    if (!formState || !activeLocationId) {
      setContent({
        content: "Something went wrong updating your location.",
        severity: "error",
      });
      return;
    }
    const input = {
      id: activeLocationId,
      ..._.omit(formState, "arrivalInstructionsQuill"),
      name: formState.name,
      arrivalInstructions: formState.arrivalInstructionsQuill,
    };
    try {
      await updateLocation({
        variables: {
          input,
        },
      });
      setOpen(false);
      setContent({
        content: "Successfully updated your location.",
        severity: "success",
      });
      if (analytics) {
        analytics.track("Company Page Location updated", {
          locationId: activeLocationId,
        });
      }
    } catch (err) {
      setContent({
        content: "Something went wrong updating your location.",
        severity: "error",
      });
    }
  };

  useEffect(() => {
    const selectedLocation = locationData?.location;
    if (selectedLocation) {
      setFormState({
        arrivalInstructionsQuill: selectedLocation.arrivalInstructions,
        coordinates: selectedLocation.coordinates.join(","),
        address: selectedLocation.address,
        name: selectedLocation.name,
      });
    } else {
      setFormState({
        arrivalInstructionsQuill: "",
        coordinates: "",
        address: "",
        name: "",
      });
    }
  }, [activeLocationId, locationData?.location]);

  return (
    <Dialog
      open={open}
      fullWidth
      onExited={() => {
        clearActiveLocationId();
      }}
      onClose={(): void => setOpen(false)}
    >
      {locationLoading ? (
        <Loading />
      ) : (
        <>
          <DialogTitle onClose={(): void => setOpen(false)}>
            {activeLocationId ? "Update Location" : "Add a Location"}
          </DialogTitle>
          <Divider />
          <div className={styles.dialogCont}>
            {formState && (
              <LocationForm
                formState={formState}
                setFormState={(updatedFormState): void => {
                  setFormState(updatedFormState);
                }}
              />
            )}
            <div className={styles.buttonContainer}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                disabled={createLoading || updateLoading}
                onClick={(): void => {
                  if (activeLocationId) {
                    handleUpdateLocation();
                  } else {
                    handleCreateLocation();
                  }
                }}
              >
                {activeLocationId ? "Update" : "Create"}
              </Button>
              {activeLocationId && (
                <Button
                  variant="outlined"
                  color="secondary"
                  size="large"
                  disabled={updateLoading}
                  onClick={handleArchiveLocation}
                >
                  Archive
                </Button>
              )}
            </div>
          </div>
        </>
      )}
    </Dialog>
  );
};

export default EditLocationModal;
