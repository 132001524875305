export type GreenhouseJobStage = {
  id: string;
  name: string | null;
  jobId: string;
};

// eslint-disable-next-line no-shadow
export enum ActionEnum {
  SetStages = "SetStages",
  SetEdits = "SetEdits",
  Back = "Back",
  Next = "Next",
  Cancel = "Cancel",
  Finish = "Finish",
}

export type StageEdits = {
  stageTemplate?: {
    id: string;
  };
  emailAction?: {
    id: string;
    cc: string[];
    bcc: string[];
  };
  stageNameOverride?: {
    displayName: string;
  };
};

export type StageEditsStateType = StageEdits & {
  stageIds: string[];
  activeStepIndex: number;
};

export type StageEditsDispatchType = {
  type: ActionEnum;
  stageIds?: string[];
  edits?: StageEdits;
};

// eslint-disable-next-line no-shadow
export enum BulkStageEditMode {
  Add = "Add",
  Edit = "Edit",
  Remove = "Remove",
}
