import React from "react";
import _ from "lodash";
import {
  Typography,
  makeStyles,
  Card,
  useTheme,
  useMediaQuery,
  Slide,
  Fade,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

import { ResourceCustomTheme } from "~/styles/config";

import MasonryCard from "./MasonryCard/MasonryCard";

type UseStyleProps = {
  empty: boolean;
};

const useStyles = makeStyles((theme: ResourceCustomTheme) => ({
  root: ({ empty }: UseStyleProps): Record<any, any> => ({
    marginBottom: "20px",
    position: "relative",
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    [empty ? "overflowY" : ""]: "hidden",
    [empty ? "maxHeight" : ""]: "400px",
    "& > .col": {
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "49.7%",
      },
      [theme.breakpoints.up("md")]: {
        width: "32.7%",
      },
      [theme.breakpoints.up("lg")]: {
        width: "24.7%",
      },
      [theme.breakpoints.up("xl")]: {
        width: "19.7%",
      },
    },
    "& .loadingCard": {
      padding: "30px",
      "&:not(:last-child)": {
        marginBottom: "10px",
      },
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& > .loadingTextBar": {
        marginBottom: "10px",
      },
    },
    "& > .emptyOverlay": {
      ...theme.mixins.containerStyles(theme),
      position: "absolute",
      height: "100%",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      background:
        "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.9976365546218487) 50%, rgba(255,255,255,0) 100%)",
    },
  }),
}));

type LoadingCard = {
  id: string;
  numberOfRows: number;
};

export type CardData = {
  id: string;
  emoji: string;
  whatCouldBeBetter?: string[];
  whatWentWell?: string[];
  comment: string;
};

type MasonryProps = {
  cardsData: CardData[];
  loading?: boolean;
  emptyStatement: string;
};

const Masonry: React.FC<MasonryProps> = ({
  cardsData,
  loading = false,
  emptyStatement,
}) => {
  const empty = loading ? false : cardsData.length === 0;
  const theme = useTheme<ResourceCustomTheme>();
  const isSmall = useMediaQuery(theme.breakpoints.up("sm"));
  const isMed = useMediaQuery(theme.breakpoints.up("md"));
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));
  const isXl = useMediaQuery(theme.breakpoints.up("xl"));
  const classes = useStyles({ empty });

  const calculateColumns = (): string[] => {
    if (isXl) {
      return ["col-1", "col-2", "col-3", "col-4", "col-5"];
    }
    if (isLg) {
      return ["col-1", "col-2", "col-3", "col-4"];
    }
    if (isMed) {
      return ["col-1", "col-2", "col-3"];
    }
    if (isSmall) {
      return ["col-1", "col-2"];
    }
    return ["col-1"];
  };
  const columns = calculateColumns();

  const generateLoadingData = (): LoadingCard[] => {
    const loadingData: { id: string; numberOfRows: number }[] = [];
    for (let i = 0; i < 30; i += 1) {
      loadingData.push({
        id: `loadingCard-${i}`,
        numberOfRows: _.random(2, 8),
      });
    }
    return loadingData;
  };

  return (
    <div className={classes.root}>
      {columns.map((key, colIndex) => (
        <div className="col" key={key}>
          {loading || empty
            ? generateLoadingData()
                .filter(
                  (_v, cardIndex) => cardIndex % columns.length === colIndex
                )
                .map(({ id, numberOfRows }, cardIndex) => (
                  <Slide in timeout={500 + cardIndex * 100} direction="up">
                    <Card className="loadingCard" key={id} elevation={1}>
                      <Skeleton
                        className="loadingEmoji"
                        variant="circle"
                        width={35}
                        height={35}
                        animation={empty ? false : "pulse"}
                      />
                      {_.times(numberOfRows, (index) => (
                        <Skeleton
                          // eslint-disable-next-line react/no-array-index-key
                          key={`${empty}-${cardIndex}-${index}`}
                          className="loadingTextBar"
                          variant="rect"
                          width="100%"
                          height={10}
                          animation={empty ? false : "pulse"}
                        />
                      ))}
                    </Card>
                  </Slide>
                ))
            : cardsData
                .filter(
                  (_v, cardIndex) => cardIndex % columns.length === colIndex
                )
                .map(
                  (
                    { id, emoji, comment, whatCouldBeBetter, whatWentWell },
                    cardIndex
                  ) => {
                    return (
                      <Fade key={id} in timeout={300 + cardIndex * 250}>
                        <MasonryCard
                          id={id}
                          emoji={emoji}
                          comment={comment}
                          whatCouldBeBetter={whatCouldBeBetter}
                          whatWentWell={whatWentWell}
                        />
                      </Fade>
                    );
                  }
                )}
        </div>
      ))}
      {empty && (
        <Fade in timeout={500}>
          <div className="emptyOverlay">
            <Typography variant="h3">{emptyStatement}</Typography>
          </div>
        </Fade>
      )}
    </div>
  );
};

export default Masonry;
