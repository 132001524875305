import React from "react";
import { makeStyles } from "@material-ui/core";

import { ResourceCustomTheme } from "~/styles/config";
import Masonry, { CardData } from "~/components/Masonry/Masonry";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme: ResourceCustomTheme) => ({
  root: {
    "& > .header": {
      marginTop: "0px",
      marginBottom: "10px",
    },
  },
}));

type CommentsProps = {
  comments: CardData[];
  loading: boolean;
  emptyStatement: string;
};

const Comments: React.FC<CommentsProps> = ({
  comments,
  loading,
  emptyStatement,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Masonry
        cardsData={comments}
        loading={loading}
        emptyStatement={emptyStatement}
      />
    </div>
  );
};

export default Comments;
