import _ from "lodash";
import React, { useState } from "react";
import {
  DialogContent,
  Button,
  DialogActions,
  makeStyles,
  TextField,
  Grid,
  Typography,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { faPlus, faMinus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage } from "@hookform/error-message";
import EmailTemplatePicker from "~/components/EmailTemplatePicker/EmailTemplatePicker";
import StageTemplatePicker from "~/components/StageTemplatePicker/StageTemplatePicker";
import FormEmailSelect from "~/components/FormEmailSelect";
import {
  StageEditsDispatchType,
  StageEditsStateType,
  ActionEnum,
  BulkStageEditMode,
} from "./types";
import { ResourceCustomTheme } from "~/styles/config";

type JssStyle = Record<string, string | number>;

type UseStyleProps = {
  activated: boolean;
};

type FormFields = {
  stageTemplate: boolean;
  emailAction: boolean;
  stageNameOverride: boolean;
};

type FormData = {
  stageTemplateId?: string;
  emailTemplateId?: string;
  emailActionCC?: { email: string }[];
  emailActionBCC?: { email: string }[];
  stageNameOverride?: string;
};

const useFormFieldStyles = makeStyles((theme: ResourceCustomTheme) => ({
  root: ({ activated }: UseStyleProps): JssStyle => ({
    marginBottom: activated ? theme.spacing(3) : theme.spacing(1),
  }),
  activate: {
    display: "flex",
    cursor: "pointer",
    "&:focus": {
      outline: "none",
    },
  },
  toggleIcon: {
    marginRight: theme.spacing(1),
  },
  formField: {
    marginLeft: theme.spacing(2),
  },
  font: {
    color: theme.colors.MidnightBlue(300),
  },
}));

type OptionalFormFieldProps = {
  label: string;
  activated: boolean;
  onToggle(): void;
};

const OptionalFormField: React.FC<OptionalFormFieldProps> = ({
  activated,
  label,
  onToggle,
  children,
}) => {
  const classes = useFormFieldStyles({ activated });
  return (
    <div className={classes.root}>
      <div
        role="button"
        tabIndex={0}
        onKeyUp={onToggle}
        className={classes.activate}
        onClick={onToggle}
      >
        <Typography variant="body1" className={classes.font}>
          <FontAwesomeIcon
            size="1x"
            icon={activated ? faMinus : faPlus}
            className={classes.toggleIcon}
          />{" "}
          {label}
        </Typography>
      </div>
      {activated && <div className={classes.formField}>{children}</div>}
    </div>
  );
};

const useStyles = makeStyles((theme: ResourceCustomTheme) => ({
  root: {},
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  emailField: {
    marginBottom: theme.spacing(1),
  },
  textField: {
    marginTop: theme.spacing(1),
  },
}));

type EditStagesProps = {
  mode: BulkStageEditMode;
  state: StageEditsStateType;
  dispatch: React.Dispatch<StageEditsDispatchType>;
};

const EditStages: React.FC<EditStagesProps> = ({ state, dispatch, mode }) => {
  const classes = useStyles();
  const { control, errors, handleSubmit, watch } = useForm<FormData>({
    defaultValues: {
      stageTemplateId: state.stageTemplate?.id ?? "",
      emailTemplateId: state.emailAction?.id ?? "",
      emailActionCC: _.map(state.emailAction?.cc, (email) => ({
        email,
      })),
      emailActionBCC: _.map(state.emailAction?.bcc, (email) => ({
        email,
      })),
      stageNameOverride: state.stageNameOverride?.displayName ?? "",
    },
  });
  const formData = watch();
  const currentEmailTemplateId = formData.emailTemplateId;
  const currentStageTemplateId = formData.stageTemplateId;

  const [activatedFormFields, setActivatedFormFields] = useState<FormFields>({
    stageTemplate: mode === BulkStageEditMode.Add || !!state.stageTemplate,
    emailAction: !!state.emailAction,
    stageNameOverride: !!state.stageNameOverride,
  });

  const toggleFormField = (fieldType) => () => {
    if (mode === BulkStageEditMode.Add && fieldType === "stageTemplate") {
      return;
    }
    setActivatedFormFields((prev) => ({
      ...prev,
      [fieldType]: !prev[fieldType],
    }));
  };

  const onSubmit = (data) => {
    dispatch({
      type: ActionEnum.SetEdits,
      edits: {
        ...(activatedFormFields.stageTemplate && data.stageTemplateId
          ? {
              stageTemplate: {
                id: data.stageTemplateId,
              },
            }
          : { stageTemplate: undefined }),
        ...(activatedFormFields.emailAction && data.emailTemplateId
          ? {
              emailAction: {
                id: data.emailTemplateId,
                cc: _.map(data.emailActionCC, "email"),
                bcc: _.map(data.emailActionBCC, "email"),
              },
            }
          : { emailAction: undefined }),
        ...(activatedFormFields.stageNameOverride && data.stageNameOverride
          ? {
              stageNameOverride: {
                displayName: data.stageNameOverride,
              },
            }
          : { stageNameOverride: undefined }),
      },
    });
    dispatch({
      type: ActionEnum.Next,
    });
  };

  return (
    <>
      <DialogContent dividers>
        <form>
          <OptionalFormField
            activated={activatedFormFields.stageTemplate}
            label="Apply Stage Template"
            onToggle={toggleFormField("stageTemplate")}
          >
            <StageTemplatePicker
              currentValue={currentStageTemplateId}
              control={control}
              errors={errors}
            />
          </OptionalFormField>
          <OptionalFormField
            activated={activatedFormFields.emailAction}
            label="Apply Email Action"
            onToggle={toggleFormField("emailAction")}
          >
            <EmailTemplatePicker
              currentValue={currentEmailTemplateId}
              control={control}
              errors={errors}
            />
            <FormEmailSelect
              defaultValues={state.emailAction?.cc}
              className={classes.emailField}
              label="CC"
              control={control}
              name="emailActionCC"
              errors={errors}
            />
            <FormEmailSelect
              defaultValues={state.emailAction?.bcc}
              className={classes.emailField}
              label="BCC"
              control={control}
              name="emailActionBCC"
              errors={errors}
            />
          </OptionalFormField>
          <OptionalFormField
            activated={activatedFormFields.stageNameOverride}
            label="Set Stage Display Name"
            onToggle={toggleFormField("stageNameOverride")}
          >
            <Controller
              name="stageNameOverride"
              render={(props) => (
                <TextField
                  {...props}
                  className={classes.textField}
                  error={!!errors.stageNameOverride}
                  label="Stage Display Name"
                  variant="outlined"
                />
              )}
              rules={{ required: true }}
              control={control}
            />
            <Typography variant="subtitle1" color="error">
              <ErrorMessage
                errors={errors}
                name="stageNameOverride"
                message="Please enter a display name for the Stage"
              />
            </Typography>
          </OptionalFormField>
        </form>
      </DialogContent>

      <DialogActions>
        <Grid container>
          <Grid item xs={12} className={classes.actions}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                dispatch({ type: ActionEnum.Back });
              }}
              style={{ marginLeft: 10 }}
            >
              Back
            </Button>
            <Button
              color="primary"
              type="submit"
              disabled={
                !activatedFormFields.emailAction &&
                !activatedFormFields.stageTemplate &&
                !activatedFormFields.stageNameOverride
              }
              variant="contained"
              onClick={() => {
                handleSubmit(onSubmit)();
              }}
              style={{ marginLeft: 10 }}
            >
              Review
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};

export default EditStages;
