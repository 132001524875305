import gql from "graphql-tag";

const FETCH_GUIDES_QUERY = gql`
  query GuidesList(
    $guidesLimit: Int!
    $guidesOffset: Int!
    $guidesOrderBy: guide_activity_order_by!
    $currentOrganizationId: uuid
  ) @hasura {
    currentOrganizationId @client @export(as: "currentOrganizationId")
    get_current_user_v2(
      args: { user_current_organization_id: $currentOrganizationId }
    ) {
      id
      currentOrganization {
        id
        guides(
          limit: $guidesLimit
          offset: $guidesOffset
          order_by: [$guidesOrderBy]
        ) {
          id
          archivedAt
          atsUrl
          candidate {
            id
            firstName
            lastName
          }
          jobRole {
            id
            name
          }
          currentStage {
            id
            date
            lastSentAt
          }
          candidateOpens
        }
      }
    }
  }
`;

export default FETCH_GUIDES_QUERY;
