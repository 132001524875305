import moment from "moment";
import { SampleGuide } from "../Guide/GuidePresenter/GuidePresenter";
import {
  austin,
  kevin,
  alex,
  marcus,
} from "~/utils/generators/generate-interviewer";
import generateEvent from "~/utils/generators/generate-event";
import generateGuide from "~/utils/generators/generate-guide";

export type Step = {
  name: string;
  id: string;
  hasCompletedStep: boolean;
  locked?: boolean;
  guide(candidateName: string): SampleGuide;
};

const steps: Step[] = [
  {
    name: "Phone Screen",
    id: "phone",
    hasCompletedStep: true,
    guide: (candidateName): any =>
      generateGuide({
        includeChat: false,
        remoteInstructions: "You will receive a call from (678) 999 - 8212",
        candidateName,
        stageName: "Onsite",
        events: [
          generateEvent({
            title: "Background and Company Overview",
            description: "",
            startTime: moment().subtract(2, "day").startOf("hour").toDate(),
            interviewers: [kevin],
          }),
        ],
      }),
  },
  {
    name: "Manager Screen",
    id: "hms",
    hasCompletedStep: true,
    guide: (candidateName): any =>
      generateGuide({
        includeChat: false,
        remoteInstructions: "You will receive a call from 867-5309",
        candidateName,
        stageName: "Onsite",
        events: [
          generateEvent({
            title: "Career Chat",
            description:
              "We will talk about why you want to join our team at Resource",
            startTime: moment().subtract(1, "day").startOf("hour").toDate(),
            interviewers: [austin],
          }),
        ],
      }),
  },
  {
    name: "Onsite",
    id: "onsite",
    hasCompletedStep: true,
    guide: (candidateName): any =>
      generateGuide({
        includeChat: false,
        candidateName,
        stageName: "Onsite",
        events: [
          generateEvent({
            title: "Leadership + Collaboration",
            description:
              "A time to review your working strengths + weaknesses and analyze how they would function in a team environment. There will be some situational problem solving. ",
            startTime: moment().startOf("hour").add(1, "day").toDate(),
            interviewers: [austin],
          }),
          generateEvent({
            title: "Motivation + Growth",
            description:
              "We'll explore why you'd like to work at Resource, your personal and career goals, and how they could be amplified with our team.",
            startTime: moment()
              .startOf("hour")
              .add(1, "day")
              .add(30, "minutes")
              .toDate(),
            interviewers: [kevin],
          }),
          generateEvent({
            title: "Technical Exercise",
            description:
              "In this interview, we'll cover some of the following topics: Substring matching, data structure design, sorting in Java, working with APIs. ",
            startTime: moment()
              .startOf("hour")
              .add(1, "day")
              .add(60, "minutes")
              .toDate(),
            interviewers: [alex],
          }),
          generateEvent({
            title: "Post-lunch walk",
            description:
              "We'll decompress and review the days events with a nice walk around the Yerba Buena Gardens.\n\nThis is a great time to ask any remaining questions :)",
            startTime: moment()
              .startOf("hour")
              .add(1, "day")
              .add(90, "minutes")
              .toDate(),
            interviewers: [marcus],
          }),
          generateEvent({
            title: "Team Lunch",
            description:
              "Meet the rest of the team over some free food! Make sure to place your order with Alex before 11am on the day of your onsite.",
            startTime: moment()
              .startOf("hour")
              .add(1, "day")
              .add(120, "minutes")
              .toDate(),
            interviewers: [austin, alex],
          }),
          generateEvent({
            title: "Welcome + Agenda",
            description:
              "Meet at Contraband across the street from the office. We'll grab a coffee and chat about the days events!",
            startTime: moment()
              .startOf("hour")
              .add(1, "day")
              .add(150, "minutes")
              .toDate(),
            interviewers: [austin],
          }),
        ],
      }),
  },
  {
    name: "Offer",
    id: "offer",
    hasCompletedStep: false,
    locked: true,
    guide: (): any => {},
  },
];

export default steps;
