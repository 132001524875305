import React, { useEffect, useState } from "react";
import { Button, ButtonProps } from "@material-ui/core";

type ConfirmationButtonProps = ButtonProps & {
  disableTimer?: boolean;
};

const ConfirmationButton: React.FC<ConfirmationButtonProps> = ({
  disableTimer = false,
  onClick,
  children,
  ...props
}) => {
  const [clicked, setClicked] = useState(false);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (clicked && onClick) {
      onClick(event);
    } else {
      setClicked(true);
    }
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (clicked && !disableTimer) {
      timeout = setTimeout(() => {
        setClicked(false);
      }, 2000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  });

  return (
    <Button
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      onClick={handleClick}
    >
      {clicked ? "Are you sure?" : children}
    </Button>
  );
};

export default ConfirmationButton;
