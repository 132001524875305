import gql from "graphql-tag";

export default gql`
  mutation UpdateRichTextInstallationForStageTemplate(
    $input: UpdateRichTextInstallationForStageTemplateInput!
  ) {
    updateRichTextInstallationForStageTemplate(input: $input) {
      success
      stageTemplate {
        id
        name
        updatedAt
      }
    }
  }
`;
