import cx from "classnames";
import React, { FunctionComponent } from "react";
import { useQuery } from "@apollo/client";
import CURRENT_USER_QUERY from "~/queries/CURRENT_USER_QUERY";
import { CurrentUser } from "~/schemaTypes";
import OrganizationSwitcher from "~/components/OrganizationSwitcher/OrganizationSwitcher";
import Loading from "~/components/Loading/Loading";
import Sidebar from "./Sidebar/Sidebar";
import styles from "./Layout.module.scss";
import { useAvailableOrganizations } from "./use-available-organizations";

type LayoutProps = {
  children: React.ReactNode;
};

const Layout: FunctionComponent<LayoutProps> = ({ children }) => {
  const { data: currentUserData, loading: currentUserLoading } = useQuery<
    CurrentUser
  >(CURRENT_USER_QUERY);
  const currentUser = currentUserData?.currentUserV2;
  const {
    currentOrganization,
    availableOrganizations,
  } = useAvailableOrganizations();
  const showHeader = (availableOrganizations?.length || 0) > 1;
  return (
    <div className={styles.gridCont}>
      <div className={styles.sidebar}>
        <Sidebar currentUser={currentUser} />
      </div>
      {!currentUserLoading ? (
        <div className={styles.mainCont}>
          {showHeader && (
            <OrganizationSwitcher
              availableOrganizations={availableOrganizations}
              organization={currentOrganization}
              className={styles.customerSwitcher}
              textFieldProps={{
                className: styles.customerSwitcherTextField,
              }}
              light={false}
            />
          )}
          <div
            className={cx(styles.contentCont, {
              [styles.fullHeight]: !showHeader,
            })}
          >
            {children}
          </div>
          <footer className={styles.footer} />
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};
export default Layout;
