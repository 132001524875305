import _ from "lodash";
import React, { FunctionComponent } from "react";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link as ReactRouterLink } from "react-router-dom";
import { faExternalLinkAlt, faPencil } from "@fortawesome/pro-light-svg-icons";
import {
  TableRow,
  TableCell,
  Link,
  Typography,
  Tooltip,
  makeStyles,
} from "@material-ui/core";
import { FeatureFlagEnum } from "@resource/common";
import { useFlags } from "@resource/client-ffs";
import useAnalytics from "~/react-hooks/useAnalytics";
import { FetchGuides_currentUserV2_organization_guides as Guide } from "~/schemaTypes";
import { GuidesList_get_current_user_v2_currentOrganization_guides as HasuraGuide } from "~/schemaTypesHasura";
import styles from "./Guides.module.scss";
import BasePathEnum from "~/enums/BasePathEnum";
import CandidateViewChart from "./CandidateViewChart/CandidateViewChart";
import ArchiveGuideButton from "../ArchiveGuideButton/ArchiveGuideButton";

const useStyles = makeStyles({
  candidateViewChartTooltip: {
    width: "400px",
    marginTop: "-20px",
    marginRight: "-60px",
  },
});

type GuideRowItemProps = {
  guide: Guide | HasuraGuide;
  edit?: ((guide: Guide | HasuraGuide) => void) | (() => void);
  preview?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

const GuideRowItem: FunctionComponent<GuideRowItemProps> = ({
  guide,
  edit,
}) => {
  const classes = useStyles();
  const {
    [_.camelCase(FeatureFlagEnum.AllowGuideArchive)]: allowGuideArchiveFlag,
    [_.camelCase(
      FeatureFlagEnum.CandidateOpensActivityInTable
    )]: candidateOpensActivityInTableFlag,
  } = useFlags();
  const [analytics] = useAnalytics();
  const {
    candidate,
    jobRole,
    currentStage: possibleCurrentStage,
    id: guideId,
  } = guide;
  const currentStage = _.isArray(possibleCurrentStage)
    ? _.first(possibleCurrentStage)
    : possibleCurrentStage;
  const firstName = candidate?.firstName;
  const lastName = candidate?.lastName;
  const lastSentAt = currentStage?.lastSentAt;
  const candidateOpens = guide?.candidateOpens || [];
  const name = `${firstName} ${lastName}`;
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const interviewDate = currentStage?.date
    ? moment.tz(currentStage?.date, timezone)
    : "";

  const onEditClick = (e: React.MouseEvent): void => {
    analytics.track("Edit Guide Clicked", {
      guideId,
    });
    if (edit) {
      edit(guide);
      e.preventDefault();
    }
    e.stopPropagation();
  };

  return (
    <TableRow className={styles.guideItemRow} onClick={onEditClick}>
      <TableCell>
        <Typography>{guide?.archivedAt ? <em>{name}</em> : name}</Typography>{" "}
      </TableCell>
      {candidateOpensActivityInTableFlag && (
        <Tooltip
          PopperProps={{
            className: classes.candidateViewChartTooltip,
          }}
          title={<CandidateViewChart candidateOpens={candidateOpens} />}
        >
          <TableCell>
            <Typography>{candidateOpens.length}</Typography>
          </TableCell>
        </Tooltip>
      )}
      <TableCell>
        <Typography>
          {" "}
          {interviewDate && interviewDate.format("MMM D")}{" "}
        </Typography>{" "}
      </TableCell>
      <TableCell>
        <Typography> {jobRole?.name} </Typography>{" "}
      </TableCell>
      <TableCell className={styles.linksCell}>
        <div className={styles.hoverIcons}>
          <Link onClick={onEditClick} color="primary" className={styles.link}>
            <FontAwesomeIcon
              className={styles.rowIcon}
              icon={faPencil}
              size="lg"
              fixedWidth
            />
            <Typography color="primary" className={styles.text}>
              {" "}
              Edit{" "}
            </Typography>
          </Link>
          <Link
            color="primary"
            className={styles.link}
            component={ReactRouterLink}
            target="_blank"
            rel="noopener noreferrer"
            to={`${BasePathEnum.Guide}/${guideId}`}
            onClick={(e: React.MouseEvent): void => {
              analytics.track("View Guide Clicked", {
                guideId,
              });
              e.stopPropagation();
            }}
          >
            <FontAwesomeIcon
              className={styles.rowIcon}
              icon={faExternalLinkAlt}
              size="lg"
              fixedWidth
            />
            <Typography color="primary" className={styles.text}>
              {" "}
              Preview{" "}
            </Typography>
          </Link>
        </div>
      </TableCell>
      <TableCell>
        <div style={{ display: "flex" }}>
          <Typography className={styles.sent} variant="body1">
            {lastSentAt && (
              <>
                {allowGuideArchiveFlag && (
                  <ArchiveGuideButton
                    isArchived={guide?.archivedAt}
                    guideId={guideId!}
                  />
                )}
                <span>Sent {moment(lastSentAt).format("MMM D, YYYY")}</span>
              </>
            )}
          </Typography>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default GuideRowItem;
