import _ from "lodash";
import React from "react";
import { Paper, Typography, makeStyles, Card } from "@material-ui/core";

import { ResourceCustomTheme } from "~/styles/config";
import ModuleDisplayInfo from "~/components/utils/module-display-info";

const useStyles = makeStyles((theme: ResourceCustomTheme) => ({
  root: { width: 240, backgroundColor: "white" },
  moduleCard: {
    backgroundColor: theme.colors.MidnightBlue(100),
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  header: {
    marginTop: theme.spacing(1),
  },
  label: {},
}));

type StageTemplateContentPreviewProps = {
  installations: {
    id: string;
    stage_module: {
      type: string;
    };
  }[];
};

const StageTemplateContentPreview: React.FC<StageTemplateContentPreviewProps> = ({
  installations,
}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root} elevation={2}>
      <Typography variant="subtitle2" className={classes.header}>
        Stage Template Preview
      </Typography>
      {_.map(
        _.slice(installations, 0, 5),
        ({ id, stage_module: { type: moduleType } }) => (
          <Card key={id} className={classes.moduleCard} elevation={1}>
            <Typography variant="body1" className={classes.label}>
              {ModuleDisplayInfo[moduleType]?.emoji}
              &nbsp;
              {ModuleDisplayInfo[moduleType]?.header}
            </Typography>
          </Card>
        )
      )}
      {installations.length > 5 && (
        <Card key="overflow" className={classes.moduleCard} elevation={1}>
          <Typography variant="body1" className={classes.label}>
            + {installations.length - 5} more
          </Typography>
        </Card>
      )}
    </Paper>
  );
};

export default StageTemplateContentPreview;
