/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import _ from "lodash";
import { RoleEnum } from "@resource/common";
import { CurrentUser_currentUserV2 as Auth } from "~/schemaTypes";
import InvalidPermissions from "./InvalidPermissions";

type UserProps = {
  auth?: Auth | null;
};

const StaffRoute: React.FC<UserProps & RouteProps> = ({ auth, ...rest }) => {
  if (!auth?.id) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { referrer: rest.location?.pathname },
        }}
      />
    );
  }
  if (!_.includes(auth?.roles, RoleEnum.Staff)) {
    return <InvalidPermissions {...rest} />;
  }

  return <Route {...rest} />;
};

export default StaffRoute;
