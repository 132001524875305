import React, { FunctionComponent, useContext } from "react";
import { Typography, Link } from "@material-ui/core";
import ArrivalInstructions from "./ArrivalInstructions/ArrivalInstructions";

import { AnalyticsContext } from "~/analytics";

import styles from "./Location.module.scss";
import ConferenceLinkCard from "../shared/ConferenceLinkCard";
import { getStaticMapsUrl, ImageSize, ZoomLevel } from "~/utils/image-utils";

type LocationProps = {
  remote?: boolean;
  remoteInstructions?: string;
  address?: string;
  coordinates?: string[];
  arrivalInstructions?: string;
  header?: string;
};

const Location: FunctionComponent<LocationProps> = ({
  remote,
  remoteInstructions,
  address,
  coordinates,
  arrivalInstructions,
  header,
}) => {
  const analytics = useContext(AnalyticsContext);
  if (remote) {
    return remoteInstructions ? (
      <>
        <div className={styles.addressContainer}>
          <ConferenceLinkCard instructions={remoteInstructions} />
        </div>
        {arrivalInstructions && (
          <ArrivalInstructions arrivalInstructions={arrivalInstructions} />
        )}
      </>
    ) : null;
  }

  return (
    <div className={styles.addressContainer}>
      <Typography variant="h4">{header ?? "Address"}</Typography>
      <Typography variant="body1">{address}</Typography>
      <div className={styles.links}>
        <Link
          className={styles.link}
          color="inherit"
          href={`https://maps.google.com/?q=${address}`}
          target="_blank"
          onClick={(): void =>
            analytics.track("Get Directions Clicked", { address })
          }
        >
          Get directions
        </Link>
      </div>
      {arrivalInstructions && (
        <ArrivalInstructions arrivalInstructions={arrivalInstructions} />
      )}
      {address && (
        <a
          href={`https://maps.google.com/?q=${address}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(): void =>
            analytics?.track("Map Clicked", { address, coordinates })
          }
        >
          <img
            className={styles.map}
            alt="Google Maps Location"
            src={getStaticMapsUrl(
              coordinates || [],
              ImageSize.Landscape,
              ZoomLevel.CloseRange
            )}
          />
        </a>
      )}
    </div>
  );
};

export default Location;
