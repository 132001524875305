/* eslint-disable import/prefer-default-export */
import moment, { Moment, Duration } from "moment";
import generateEvent from "../generators/generate-event";
import { StageById_stage_sortedVisibleEvents as Event } from "~/schemaTypes";

type HiddenEventArgs = {
  interviewDate?: Moment;
  interviewDuration?: Duration;
  interviewStartTime?: Moment;
};

const fakeStartOfDay = moment().add(1, "day").startOf("day");

export const generateFakeEventForNoAts = ({
  interviewDate,
  interviewDuration,
  interviewStartTime,
}: HiddenEventArgs): Event => {
  const duration =
    interviewDuration && interviewDuration.asMilliseconds() > 0
      ? interviewDuration
      : moment.duration(30, "minutes");

  const startTime =
    interviewDate && interviewStartTime
      ? interviewDate
          .clone()
          .set("hour", interviewStartTime.hour())
          .set("minute", interviewStartTime.minute())
      : fakeStartOfDay.add(8, "hours");
  const endTime = startTime.clone().add(moment.duration(duration));

  return generateEvent({
    startTime: startTime.toDate(),
    endTime: endTime.toDate(),
    interviewers: [],
  });
};
