import React, { Suspense } from "react";
import _ from "lodash";
import { Redirect, Route, Switch } from "react-router-dom";
import { retry, FeatureFlagEnum } from "@resource/common";
import { useFlags } from "@resource/client-ffs";

import useCurrentUser from "./utils/auth/useCurrentUser";
import useAnalytics from "~/react-hooks/useAnalytics";
import { FlashMessage } from "~/components/FlashMessage/FlashMessage";

import NotFound from "./components/common/NotFound";
import UnexpectedError from "~/components/common/UnexpectedError";
import PrivateRoute from "./components/common/PrivateRoute";
import StaffRoute from "./components/common/StaffRoute";
import Loading from "~/components/Loading/Loading";
import InvalidPermissions from "./components/common/InvalidPermissions";
import BasePathEnum from "./enums/BasePathEnum";
import EmailTemplates from "./pages/GuidesHomepage/Templates/EmailTemplates/EmailTemplates";
import CompanyDemo from "./pages/CompanyDemo/CompanyDemo";
import GuideTemplateEditor from "./pages/GuidesHomepage/Templates/GuideTemplates/GuideTemplateEditor";
import Guide from "./pages/Guide/Guide";
import Login from "./pages/Login/Login";
import Interviewers from "./pages/GuidesHomepage/Settings/Interviewers/InterviewersInSideBar";
import Layout from "./pages/GuidesHomepage/Layout";
import Guides from "./pages/GuidesHomepage/Guides/Guides";
import StageTemplates from "./pages/GuidesHomepage/Templates/StageTemplates/StageTemplates";
import RoleJourneys from "./pages/GuidesHomepage/Templates/RoleJourneys/RoleJourneys";
import Settings from "./pages/GuidesHomepage/Settings/Settings";
import CarbonDioxide from "./pages/CarbonDioxide/CarbonDioxide";
import StageTemplateEditorContainer from "./pages/GuidesHomepage/Templates/StageTemplateEditorContainer/StageTemplateEditorContainer";
import Feedback from "./pages/GuidesHomepage/Feedback/Feedback";
import { UUID_REGEX, SLUG_REGEX, SHORT_ID_REGEX } from "./routing";

const GuideEditor = React.lazy(() =>
  retry(() => import("./pages/GuideEditor"))
);
const Templates = React.lazy(() =>
  retry(() => import("./pages/GuidesHomepage/Templates/Templates"))
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PropsType = Record<string, any>;

const PrivateLayoutRoute = (props: PropsType) => {
  return (
    <Layout>
      <PrivateRoute {...props} />
    </Layout>
  );
};

const StaffLayoutRoute = (props: PropsType) => {
  return (
    <Layout>
      <StaffRoute {...props} />
    </Layout>
  );
};

const App: React.FC = () => {
  const [analytics] = useAnalytics();
  const { currentUser, loading, error } = useCurrentUser(analytics);
  const {
    [_.camelCase(
      FeatureFlagEnum.GuideTemplatesEditor
    )]: guideTemplatesEditorFlag,
  } = useFlags();
  if (error) {
    return <UnexpectedError />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <Suspense fallback={<Loading />}>
      <div>
        <FlashMessage />
      </div>
      <Switch>
        <Route
          path="/:url*"
          exact
          strict
          render={(props) => <Redirect to={`${props.location.pathname}/`} />}
        />
        <PrivateLayoutRoute
          auth={currentUser}
          path={BasePathEnum.Home}
          exact
          component={Guides}
        />
        <Route path="/demo/:onsiteId/:displayName" component={CompanyDemo} />
        <PrivateRoute
          auth={currentUser}
          path="/greenhouse-candidate/:candidateId/application/:applicationId"
          component={GuideEditor}
        />
        <Route path={`/guide/:guideId(${UUID_REGEX})`} component={Guide} />
        <PrivateLayoutRoute
          auth={currentUser}
          path={`${BasePathEnum.Templates}/:stageTemplateId(${UUID_REGEX})`}
          component={StageTemplateEditorContainer}
          exact
        />
        <PrivateLayoutRoute
          auth={currentUser}
          path={BasePathEnum.Templates}
          component={guideTemplatesEditorFlag ? Templates : StageTemplates}
        />
        <PrivateLayoutRoute
          auth={currentUser}
          path={`/journeys/:uuid(${UUID_REGEX})/`}
          component={GuideTemplateEditor}
        />
        <PrivateLayoutRoute
          auth={currentUser}
          path="/journeys"
          component={RoleJourneys}
        />
        <PrivateLayoutRoute
          auth={currentUser}
          path={BasePathEnum.EmailTemplates}
          component={EmailTemplates}
        />
        <PrivateLayoutRoute
          auth={currentUser}
          path={BasePathEnum.Settings}
          component={Settings}
        />
        <PrivateLayoutRoute
          auth={currentUser}
          path={BasePathEnum.Feedback}
          component={Feedback}
        />
        <StaffLayoutRoute
          auth={currentUser}
          path={BasePathEnum.CO2}
          exact
          component={CarbonDioxide}
        />

        <PrivateLayoutRoute
          auth={currentUser}
          path={BasePathEnum.Interviewers}
          component={Interviewers}
          exact
        />
        <Redirect from="/company" to="/settings/company" />
        <Route path="/not-found" component={NotFound} />
        <Route path="/error" component={UnexpectedError} />
        <Route path="/invalid-permissions" component={InvalidPermissions} />
        <Route path="/login" component={Login} />
        <Route
          sensitive
          path={`/:customerSlug(${SLUG_REGEX})/:guideShortId(${SHORT_ID_REGEX})/:name`}
          component={Guide}
        />
        <Redirect from="*" to="/not-found" />
      </Switch>
    </Suspense>
  );
};

export default App;
