import _ from "lodash";
import { Moment, Duration } from "moment";
import {
  LinkItemResolved,
  ValueItemResolved,
  LogisticItemResolved,
  ModuleDisplayTypeEnum,
  strings,
} from "@resource/common";
import { StageModule } from "./types";
import {
  defaultRemoteInstructions,
  defaultCandidateName,
  defaultRoleName,
  defaultCompanyName,
  defaultDuration,
  defaultInterviewStartTime,
  defaultInterviewDate,
  defaultEvents,
  defaultLogistics,
  defaultInterviewers,
} from "./defaults";
import {
  StageById_stage_sortedVisibleEvents as Event,
  StageById_stage_sortedVisibleEvents_interviewers as Interviewer,
} from "~/schemaTypes";
import { generateFakeEventForNoAts } from "./generate-fake-event-for-no-ats";

export const generateGreeting = ({
  candidateFirstName = strings.splitName(defaultCandidateName).firstName,
  header = `Hi ${candidateFirstName}`,
  subheader = defaultRoleName,
}: {
  candidateFirstName?: string;
  header?: string;
  subheader?: string;
}): StageModule => ({
  id: ModuleDisplayTypeEnum.GreetingHeader,
  displayType: ModuleDisplayTypeEnum.GreetingHeader,
  data: {
    candidateFirstName,
    header,
    subheader,
  },
});

export const generateSchedule = ({
  events = defaultEvents,
  header,
}: {
  events?: Event[];
  header?: string;
}): StageModule => ({
  id: ModuleDisplayTypeEnum.Schedule,
  displayType: ModuleDisplayTypeEnum.Schedule,
  data: {
    events,
    header,
  },
});

export const generateLocation = ({
  remote = true,
  remoteInstructions = defaultRemoteInstructions,
  arrivalInstructions,
  address,
  coordinates,
  header,
}: {
  remote?: boolean;
  remoteInstructions?: string;
  address?: string;
  arrivalInstructions?: string;
  coordinates?: string[];
  header?: string;
}): StageModule => ({
  id: ModuleDisplayTypeEnum.Location,
  displayType: ModuleDisplayTypeEnum.Location,
  data: {
    header,
    remote,
    remoteInstructions,
    arrivalInstructions,
    address,
    coordinates,
  },
});

export const generateMedia = ({
  mediaLink,
  header,
}: {
  mediaLink: string;
  header?: string;
}): StageModule => ({
  id: ModuleDisplayTypeEnum.Media,
  displayType: ModuleDisplayTypeEnum.Media,
  data: {
    mediaLink,
    header,
  },
});

type TimeArgs = {
  remoteInstructions?: string;
  companyName?: string;
  events?: Event[];
  interviewDuration?: Duration;
  interviewDate?: Moment;
  interviewStartTime?: Moment;
  header?: string;
};

export const generateTime = ({
  remoteInstructions,
  companyName = defaultCompanyName,
  interviewDuration = defaultDuration,
  interviewDate = defaultInterviewDate,
  interviewStartTime = defaultInterviewStartTime,
  events,
  header,
}: TimeArgs): StageModule => {
  let finalEvents = _.cloneDeep(events);
  if (!finalEvents || finalEvents.length === 0) {
    const hiddenEvent = generateFakeEventForNoAts({
      interviewDuration,
      interviewDate,
      interviewStartTime,
    });
    finalEvents = [hiddenEvent];
  }

  return {
    id: ModuleDisplayTypeEnum.Time,
    displayType: ModuleDisplayTypeEnum.Time,
    data: {
      remote: true,
      remoteInstructions,
      companyName,
      guideUrl: "https://guide.resource.io/fake-guide-url",
      events: finalEvents,
      header,
    },
  };
};

export const generateLogistics = ({
  logistics = defaultLogistics,
  header = "Logistics",
}: {
  logistics?: LogisticItemResolved[];
  header?: string;
}): StageModule => ({
  id: ModuleDisplayTypeEnum.InfoCards,
  displayType: ModuleDisplayTypeEnum.InfoCards,
  data: {
    header,
    infoArray: logistics,
  },
});

export const generateMission = ({
  missionStatement,
  header,
}: {
  missionStatement?: string;
  header?: string;
}): StageModule => ({
  id: ModuleDisplayTypeEnum.Mission,
  displayType: ModuleDisplayTypeEnum.Mission,
  data: {
    header: header ?? "Our Mission",
    description: missionStatement,
  },
});

export const generateLinks = ({
  links,
  defaultImage,
  header,
}: {
  links?: LinkItemResolved[];
  defaultImage?: string;
  header?: string;
}): StageModule => ({
  id: ModuleDisplayTypeEnum.Links,
  displayType: ModuleDisplayTypeEnum.Links,
  data: {
    defaultImage,
    links,
    header,
  },
});

export const generateInterviewTeam = ({
  interviewers = defaultInterviewers,
  header,
}: {
  interviewers?: Interviewer[];
  header?: string;
}): StageModule => ({
  id: ModuleDisplayTypeEnum.InterviewTeam,
  displayType: ModuleDisplayTypeEnum.InterviewTeam,
  data: {
    interviewers,
    header,
  },
});

type SimpleStage = {
  position: number;
  name: string;
};

export const generateCandidateInterviewFeedback = ({
  previouslySubmittedFeedback = false,
  isSample = true,
}: {
  previouslySubmittedFeedback?: boolean;
  isSample?: boolean;
}): StageModule => ({
  id: ModuleDisplayTypeEnum.CandidatePostInterviewFeedback,
  displayType: ModuleDisplayTypeEnum.CandidatePostInterviewFeedback,
  data: {
    previouslySubmittedFeedback,
    isSample,
  },
});

export const generateValues = ({
  infoArray,
  header,
}: {
  infoArray?: ValueItemResolved[];
  header?: string;
}): StageModule => ({
  id: "Values",
  displayType: ModuleDisplayTypeEnum.InfoCards,
  data: {
    header: header ?? "Values",
    infoArray,
  },
});

export const generateRichText = ({
  rawHtml = "",
  header,
}: {
  rawHtml?: string;
  header?: string;
}): StageModule => ({
  id: ModuleDisplayTypeEnum.RichText,
  displayType: ModuleDisplayTypeEnum.RichText,
  data: {
    rawHtml,
    header,
  },
});
