/* eslint-disable react/jsx-props-no-spreading */
import _ from "lodash";
import React, { FunctionComponent } from "react";

import { DocumentNode } from "graphql";
import { ModuleEnum, FeatureFlagEnum } from "@resource/common";
import { useFlags } from "@resource/client-ffs";
import EditLogistics from "./EditLogistics/EditLogistics";
import EditRichText from "./EditRichText/EditRichText";
import EditTime from "./EditTime/EditTime";
import EditInterviewTeam from "./EditInterviewTeam/EditInterviewTeam";
import EditSchedule from "./EditSchedule/EditSchedule";
import EditLocation from "./EditLocation/EditLocation";
import EditMission from "./EditMission/EditMission";
import EditSimpleTextListModal from "./EditSimpleTextListModal/EditSimpleTextListModal";
import EditCulture from "./EditCulture/EditCulture";
import EditValues from "./EditValues/EditValues";
import EditMedia from "./EditMedia/EditMedia";
import EditInterviewScheduler from "./EditInterviewScheduler/EditInterviewScheduler";
import EditLinks from "./EditLinks/EditLinks";

type SaveMutationInput = {
  input: Record<string, unknown>;
};

export type EditModulesGlobalProps = {
  setShouldSave?: React.Dispatch<
    React.SetStateAction<(() => boolean) | undefined>
  >;
  setHandleSaveMutation: React.Dispatch<React.SetStateAction<DocumentNode>>;
  setHandleSaveMutationInput: React.Dispatch<
    React.SetStateAction<SaveMutationInput | undefined> | undefined
  >;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DataType = Record<string, any>;

type EditModuleContainerProps = {
  id: string;
  type: string;

  data: DataType;
  onClose(): void;
};

const EditModuleContainer: FunctionComponent<
  EditModuleContainerProps & EditModulesGlobalProps
> = ({
  id,
  type,
  data,
  setShouldSave,
  setHandleSaveMutation,
  setHandleSaveMutationInput,
  onClose,
}) => {
  const {
    [_.camelCase(FeatureFlagEnum.LinksWithImages)]: linksWithImagesFlag,
  } = useFlags();
  let editModule;

  switch (type) {
    case ModuleEnum.TeamPhoto:
      editModule = (
        <EditCulture
          key={id}
          moduleId={id}
          header={data.header}
          setHandleSaveMutation={setHandleSaveMutation}
          setHandleSaveMutationInput={setHandleSaveMutationInput}
          companyTeamPhotoUrl={data.photoUrl}
        />
      );
      break;
    case ModuleEnum.RichText:
      editModule = (
        <EditRichText
          key={id}
          moduleId={id}
          header={data.header}
          setHandleSaveMutation={setHandleSaveMutation}
          setHandleSaveMutationInput={setHandleSaveMutationInput}
          rawHtml={data.rawHtml}
        />
      );
      break;
    case ModuleEnum.Time:
      editModule = (
        <EditTime
          key={id}
          moduleId={id}
          header={data.header}
          setHandleSaveMutation={setHandleSaveMutation}
          setHandleSaveMutationInput={setHandleSaveMutationInput}
        />
      );
      break;
    case ModuleEnum.Mission:
      editModule = (
        <EditMission
          key={id}
          moduleId={id}
          header={data.header}
          setHandleSaveMutation={setHandleSaveMutation}
          setHandleSaveMutationInput={setHandleSaveMutationInput}
        />
      );
      break;
    case ModuleEnum.Values:
      editModule = (
        <EditValues
          key={id}
          moduleId={id}
          header={data.header}
          setHandleSaveMutation={setHandleSaveMutation}
          setHandleSaveMutationInput={setHandleSaveMutationInput}
        />
      );
      break;
    case ModuleEnum.InterviewTeam:
      editModule = (
        <EditInterviewTeam
          key={id}
          moduleId={id}
          header={data.header}
          setHandleSaveMutation={setHandleSaveMutation}
          setHandleSaveMutationInput={setHandleSaveMutationInput}
        />
      );
      break;
    case ModuleEnum.Schedule:
      editModule = (
        <EditSchedule
          key={id}
          moduleId={id}
          header={data.header}
          setHandleSaveMutation={setHandleSaveMutation}
          setHandleSaveMutationInput={setHandleSaveMutationInput}
        />
      );
      break;
    case ModuleEnum.Media:
      editModule = (
        <EditMedia
          key={id}
          moduleId={id}
          header={data.header}
          setHandleSaveMutation={setHandleSaveMutation}
          setHandleSaveMutationInput={setHandleSaveMutationInput}
          mediaLink={data.mediaLink}
        />
      );
      break;
    case ModuleEnum.Logistics:
      editModule = (
        <EditLogistics
          key={id}
          moduleId={id}
          header={data.header}
          setHandleSaveMutation={setHandleSaveMutation}
          setHandleSaveMutationInput={setHandleSaveMutationInput}
          availableLogisticGroups={data.availableLogisticGroups}
          customLogisticGroups={data.customLogisticGroups}
          logistics={data.logistics}
        />
      );
      break;
    case ModuleEnum.Location:
      editModule = (
        <EditLocation
          key={id}
          moduleId={id}
          header={data.header}
          setHandleSaveMutation={setHandleSaveMutation}
          setHandleSaveMutationInput={setHandleSaveMutationInput}
          onClose={onClose}
          locationId={data.locationId}
          {...data}
        />
      );
      break;
    case ModuleEnum.Expectations:
      editModule = (
        <EditSimpleTextListModal
          key={id}
          setHandleSaveMutation={setHandleSaveMutation}
          setHandleSaveMutationInput={setHandleSaveMutationInput}
          items={data.items}
          moduleType={type}
          config={{
            header: "Expectations",
            itemName: "Expectation",
            primaryLabel: "Expectation title",
            secondaryLabel: "Description",
          }}
        />
      );
      break;
    case ModuleEnum.Links:
      if (linksWithImagesFlag) {
        editModule = (
          <EditLinks
            key={id}
            moduleId={id}
            header={data.header}
            setShouldSave={setShouldSave}
            setHandleSaveMutation={setHandleSaveMutation}
            setHandleSaveMutationInput={setHandleSaveMutationInput}
            links={
              data.links ||
              _.map(data.items, ({ primaryText, secondaryText }) => ({
                url: secondaryText,
                label: primaryText,
              }))
            }
          />
        );
      } else {
        editModule = (
          <EditSimpleTextListModal
            key={id}
            setHandleSaveMutation={setHandleSaveMutation}
            setHandleSaveMutationInput={setHandleSaveMutationInput}
            items={data.items}
            moduleType={type}
            config={{
              header: "Additional Info",
              itemName: "Link",
              primaryLabel: "Link title",
              secondaryLabel: "Link URL",
            }}
          />
        );
      }
      break;
    case ModuleEnum.InterviewScheduler:
      editModule = (
        <EditInterviewScheduler
          key={id}
          setHandleSaveMutation={setHandleSaveMutation}
          setHandleSaveMutationInput={setHandleSaveMutationInput}
          pageEditToken={data.pageEditToken}
        />
      );
      break;

    default:
      editModule = null;
      break;
  }

  return editModule;
};

export default EditModuleContainer;
