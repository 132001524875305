import cx from "classnames";
import React, { FunctionComponent, useContext } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { useQuery } from "@apollo/client";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@material-ui/core";

import GET_LINK_PREVIEW_QUERY from "~/queries/GET_LINK_PREVIEW_QUERY";
import { GetLinkPreview, GetLinkPreviewVariables } from "~/schemaTypes";
import { AnalyticsContext } from "~/analytics";

import styles from "../Links.module.scss";

const LinkLoading = () => (
  <div className={cx(styles.loading)}>
    <Skeleton variant="rect" width={245} height={140} />
    <Skeleton variant="text" />
    <Skeleton variant="text" width="60%" />
  </div>
);

type LinkCardProps = {
  imageUrl: string | undefined | null;
  url: string;
  defaultImageUrl: string;
  label: string;
  forceMobileStyles?: boolean;
};

/**
 * Displays an image, title and url for a given link. Clicking on it opens the link
 * in a new tab and handles sending the "Link Clicked" analytics.
 *
 * @params {Object} props - view protypes below
 *
 * @returns {React.Component} the LinkCard Component.
 */
export const LinkCard: FunctionComponent<LinkCardProps> = ({
  imageUrl,
  defaultImageUrl,
  label,
  url,
}) => {
  const analytics = useContext(AnalyticsContext);
  const finalImageUrl = imageUrl || defaultImageUrl;

  return (
    <Card
      className={cx(styles.listItem)}
      onClick={() => analytics.track("Linked Clicked", { label, url })}
    >
      <CardActionArea href={url} target="_blank" rel="noopener noreferrer">
        <CardMedia
          image={finalImageUrl}
          title={`${label} Preview`}
          className={cx({
            [styles.listItemImage]: !!imageUrl,
            [styles.defaultImage]: !imageUrl,
          })}
        />
        <CardContent>
          <Typography variant="h6" noWrap>
            {label}
          </Typography>
          <Typography className={cx(styles.url)} noWrap>
            {url}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

type LinkItemProps = {
  url: string;
  imageUrl?: string;
  defaultImageUrl: string;
  label: string | undefined | null;
  forceMobileStyles?: boolean;
};

const LinkItem: FunctionComponent<LinkItemProps> = ({
  defaultImageUrl,
  imageUrl,
  label,
  url,
}) => {
  const { data, loading } = useQuery<GetLinkPreview, GetLinkPreviewVariables>(
    GET_LINK_PREVIEW_QUERY,
    {
      variables: { input: { url } },
      skip: !!imageUrl,
    }
  );

  return imageUrl || !loading ? (
    <LinkCard
      defaultImageUrl={defaultImageUrl}
      imageUrl={imageUrl || data?.getLinkPreview?.image}
      url={url}
      label={label || data?.getLinkPreview?.title || url}
    />
  ) : (
    <LinkLoading />
  );
};

export default LinkItem;
