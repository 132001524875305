import _ from "lodash";
import React, { useReducer, Reducer, useMemo } from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { useQuery } from "@apollo/client";
import { strings } from "@resource/common";
import ProgressStepper from "../ProgressStepper";
import Dialog from "~/components/Dialog/Dialog";
import {
  GetGuideTemplatesForBulkEdit,
  GetGuideTemplatesForBulkEditVariables,
} from "~/schemaTypesHasura";
import DialogTitle from "~/components/Dialog/DialogTitle/DialogTitle";
import { ResourceCustomTheme } from "~/styles/config";
import Loading from "~/components/Loading/Loading";

import HASURA_GET_GUIDE_TEMPLATES_FOR_BULK_EDIT from "./HASURA_GET_GUIDE_TEMPLATES_FOR_BULK_EDIT@hasura";
import {
  StageEditsStateType,
  StageEditsDispatchType,
  ActionEnum,
  BulkStageEditMode,
} from "./types";
import SelectStages from "./SelectStages";
import EditStages from "./EditStages";
import ConfirmEdits from "./ConfirmEdits";

// eslint-disable-next-line no-shadow
enum ProgressStepEnum {
  SetStages = 0,
  SetEdits = 1,
  // eslint-disable-next-line no-shadow
  ConfirmEdits = 2,
}

const useStyles = makeStyles((theme: ResourceCustomTheme) => ({
  root: {},
  titleContainer: {
    textAlign: "center",
  },
  emphasize: {
    color: theme.colors.RoyalPurple(),
  },
  contextLabel: {
    marginBottom: theme.spacing(2),
  },
}));

type BulkEditStageTemplateInstallationsModalProps = {
  guideTemplateIds: string[];
  open: boolean;
  onCancel: () => void;
  onClose: () => void;
  onExited: () => void;
  mode?: BulkStageEditMode;
};

const BulkEditStageTemplateInstallationsModal: React.FC<BulkEditStageTemplateInstallationsModalProps> = ({
  guideTemplateIds,
  mode = BulkStageEditMode.Edit,
  open,
  onCancel,
  onExited,
  onClose,
}) => {
  const classes = useStyles();

  const reducer = (
    state: StageEditsStateType,
    action: StageEditsDispatchType
  ): StageEditsStateType => {
    switch (action.type) {
      case ActionEnum.SetStages:
        return {
          ...state,
          stageIds: action.stageIds ?? [],
        };
      case ActionEnum.SetEdits:
        return {
          ...state,
          ...(action.edits ?? {}),
        };
      case ActionEnum.Next:
        return {
          ...state,
          activeStepIndex: state.activeStepIndex + 1,
        };
      case ActionEnum.Back:
        return {
          ...state,
          activeStepIndex: state.activeStepIndex - 1,
        };
      case ActionEnum.Cancel:
        onCancel();
        return state;
      case ActionEnum.Finish:
        onClose();
        return state;
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer<
    Reducer<StageEditsStateType, StageEditsDispatchType>
  >(reducer, {
    stageIds: [],
    activeStepIndex: 0,
  });

  const { data: guideTemplateData, loading } = useQuery<
    GetGuideTemplatesForBulkEdit,
    GetGuideTemplatesForBulkEditVariables
  >(HASURA_GET_GUIDE_TEMPLATES_FOR_BULK_EDIT, {
    variables: {
      guideTemplateIds,
    },
  });

  const guideTemplates = useMemo(
    () => guideTemplateData?.guide_template || [],
    [guideTemplateData]
  );

  let steps: ProgressStepEnum[] = [];
  switch (mode) {
    case BulkStageEditMode.Add:
      steps = [
        ProgressStepEnum.SetStages,
        ProgressStepEnum.SetEdits,
        ProgressStepEnum.ConfirmEdits,
      ];
      break;
    case BulkStageEditMode.Edit:
      steps = [
        ProgressStepEnum.SetStages,
        ProgressStepEnum.SetEdits,
        ProgressStepEnum.ConfirmEdits,
      ];
      break;
    case BulkStageEditMode.Remove:
      steps = [ProgressStepEnum.SetStages, ProgressStepEnum.ConfirmEdits];
      break;

    default:
      break;
  }

  const stepLabelForIndex = (idx) => {
    switch (mode) {
      case BulkStageEditMode.Add:
        return ["Select Stages", "Configure Stages", "Confirm"][idx];
      case BulkStageEditMode.Edit:
        return ["Select Stages", "Edit Stages", "Confirm"][idx];
      case BulkStageEditMode.Remove:
        return ["Select Stages", "Confirm"][idx];
      default:
        return "";
    }
  };

  const numSelectedGuideTemplates = useMemo(() => {
    return _.filter(guideTemplates, (guideTemplate) => {
      return !!_.find(
        guideTemplate.job_role?.greenhouseJobStagesData,
        ({ id }) => _.includes(state.stageIds, id)
      );
    }).length;
  }, [guideTemplates, state.stageIds]);

  let verb = "";
  switch (mode) {
    case BulkStageEditMode.Add:
      verb = "adding";
      break;
    case BulkStageEditMode.Edit:
      verb = "editing";
      break;
    case BulkStageEditMode.Remove:
      verb = "removing";
      break;
    default:
  }

  let selectionCopy = "";
  if (steps[state.activeStepIndex] === ProgressStepEnum.ConfirmEdits) {
    selectionCopy = `You are ${
      mode === BulkStageEditMode.Edit ? "applying the following edits to" : verb
    }`;
  } else {
    selectionCopy = `You are ${verb}`;
  }

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      onExited={onExited}
      maxWidth="md"
      scroll="paper"
      title={`${mode} Stages`}
      fullWidth
      className={classes.root}
    >
      {loading ? (
        <Loading />
      ) : (
        <>
          <DialogTitle onClose={onCancel}>
            <div className={classes.titleContainer}>
              <ProgressStepper
                steps={_.map(steps, (_s, idx) => stepLabelForIndex(idx))}
                activeStep={state.activeStepIndex}
              />

              <Typography className={classes.contextLabel} variant="h4">
                {state.stageIds.length ? (
                  <>
                    {selectionCopy}{" "}
                    <span className={classes.emphasize}>
                      {strings.pluralize("stage", state.stageIds.length)}
                    </span>{" "}
                    within{" "}
                    <span className={classes.emphasize}>
                      {strings.pluralize("journey", numSelectedGuideTemplates)}
                    </span>
                  </>
                ) : (
                  <>
                    You selected{" "}
                    <span className={classes.emphasize}>
                      {strings.pluralize("journey", guideTemplates.length)}
                    </span>
                  </>
                )}
              </Typography>
            </div>
          </DialogTitle>
          {(() => {
            let ToRender;
            switch (steps[state.activeStepIndex]) {
              case ProgressStepEnum.SetStages:
                ToRender = (
                  <SelectStages
                    mode={mode}
                    state={state}
                    dispatch={dispatch}
                    guideTemplates={guideTemplates}
                  />
                );
                break;
              case ProgressStepEnum.SetEdits:
                ToRender = (
                  <EditStages mode={mode} state={state} dispatch={dispatch} />
                );
                break;
              case ProgressStepEnum.ConfirmEdits:
                ToRender = (
                  <ConfirmEdits
                    mode={mode}
                    state={state}
                    dispatch={dispatch}
                    guideTemplates={guideTemplates}
                  />
                );
                break;

              default:
                break;
            }
            return <>{ToRender}</>;
          })()}
        </>
      )}
    </Dialog>
  );
};

export default BulkEditStageTemplateInstallationsModal;
