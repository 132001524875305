import React, { FunctionComponent, useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import styles from "./InterviewTeam.module.scss";
import Interviewer from "./Interviewer/Interviewer";
import InterviewerModal from "./InterviewerModal/InterviewerModal";
import useWindow from "~/react-hooks/useWindow";
import Dots from "~/components/Dots/Dots";
import { InterviewerType } from "./types";

type InterviewTeamProps = {
  header?: string;
  stageId: string;
  interviewers: InterviewerType[];
};

const useStyles = makeStyles(() => ({
  interviewersContainer: {
    marginTop: "5px",
    margin: "0px",
    width: "100%",
    flexGrow: 1,
    overflowX: "hidden",
    overflowY: "hidden",
  },
}));

const InterviewTeam: FunctionComponent<InterviewTeamProps> = ({
  interviewers,
  header,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [interviewerSelected, setInterviewerSelected] = useState<
    InterviewerType | undefined
  >();
  const [pages, setPages] = useState<number>(0);

  const { width = 0 } = useWindow();

  const interviewersPerPage = width < theme.breakpoints.values.sm ? 1 : 6;

  useEffect(() => {
    setPages((prevState) => {
      const newState = Math.ceil(interviewers.length / interviewersPerPage);
      if (prevState !== newState) {
        setActiveStep(0);
      }
      return newState;
    });
  }, [interviewers, interviewersPerPage]);

  if (!interviewers.length) return null;

  const handleStepChange = (step: number): void => {
    setActiveStep(step);
  };

  const onInterviewerClick = (interviewer: InterviewerType): void => {
    setInterviewerSelected(interviewer);
  };

  const pageContent = [...new Array(pages)].map((_value, idx) =>
    interviewers.slice(
      interviewersPerPage * idx,
      interviewersPerPage * (idx + 1)
    )
  );

  return (
    <>
      <div className={styles.container}>
        <Typography variant="h4">
          {header ?? (
            <>
              <span role="img" aria-label="wave">
                👋
              </span>{" "}
              Meet Your Interview Team
            </>
          )}
        </Typography>
        <SwipeableViews
          axis="x"
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {pageContent.map((interviewersOnPage, swipeIdx) => (
            <div className={classes.interviewersContainer} key={swipeIdx}>
              <Grid container spacing={2}>
                {interviewersOnPage.map((interviewer) => {
                  const {
                    id,
                    firstName,
                    imageUrl,
                    overridenImageUrl,
                  } = interviewer;
                  return (
                    <Grid item key={id} xs={12} sm={4}>
                      <Interviewer
                        id={id}
                        firstName={firstName}
                        imageUrl={overridenImageUrl || imageUrl}
                        onClick={(): void => {
                          onInterviewerClick(interviewer);
                        }}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          ))}
        </SwipeableViews>
        {pages !== 1 && (
          <Dots
            steps={pages}
            activeStep={activeStep}
            onClick={(idx: number): void => {
              setActiveStep(idx);
            }}
          />
        )}
      </div>
      {!!interviewerSelected && (
        <InterviewerModal
          open={!!interviewerSelected}
          onClose={(): void => {
            setInterviewerSelected(undefined);
          }}
          interviewer={interviewerSelected}
        />
      )}
    </>
  );
};

export default InterviewTeam;
